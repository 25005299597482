import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import theme from '@rainagency/rain-one-core/dist/theme';

export const useStyles = makeStyles(() =>
    createStyles({
        card: {
            height: '425px',
            width: '700px'
        },
        icon: {
            height: '20px',
            width: '20px',
            color: 'primary',
            size: 'small'
        },
        iconNotFavorited: {
            color: theme.palette.text.secondary
        },
        appCell: {
            textTransform: 'capitalize'
        },
        favoriteCell: {
            fontWeight: 'bold'
        },
        divider: {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1)
        },
        link: {
            textDecoration: 'none'
        },
        itemCount: {
            color: theme.palette.error.main,
            marginRight: theme.spacing(1)
        },
        errorButton: {
            color: theme.palette.error.main
        },
        footerRow: {
            alignContent: 'right',
            textAlign: 'right',
            paddingTop: theme.spacing(2),
            '&:last-child td, &:last-child th': { border: 0 }
        },
        spinner: {
            size: 15,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(1)
        }
    })
);
