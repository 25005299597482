import HomeCard from './HomeCard';
import {
    R1AuthorizedComponent,
    RainOnePermission
} from '@rainagency/rain-one-core';
import { useStyles } from './MediaCard.styles';
import CardItem from './CardItem';
import {
    CsiFileDetails,
    RainOneApp,
    useCsiSummaryDetailsQuery,
    useCubeStatusQuery,
    useVendorsMissingFromCsiQuery
} from '../generated/graphql';
import GetCurrentConfig, {
    Config
} from '@rainagency/rain-one-core/dist/Config';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import theme from '@rainagency/rain-one-core/dist/theme';
import { useMemo } from 'react';
import { CsiVendorInfo } from '@rainagency/rain-one-core/dist/generated/graphql';

export default function FinOpsCard() {
    const classes = useStyles();
    const config = GetCurrentConfig(false, RainOneApp.Finops);

    // const {
    //     data: cubeStatusData,
    //     loading: cubeStatusLoading,
    //     error: cubeStatusError
    // } = useCubeStatusQuery({
    //     notifyOnNetworkStatusChange: true
    // });

    // const cubeCount: number | null = useMemo(
    //     () => {
    //         if (cubeStatusData?.journalsMissingFromTheCube) {
    //             console.log('Setting count')
    //             return Array.from(new Set(cubeStatusData?.journalsMissingFromTheCube.map(item => item.journalBatchNumber))).length;
    //         }
    //         return null;
    //     }
    //     , [cubeStatusData]
    // );

    return (
        <HomeCard
            title="RainONE FinOps"
            className={classes.mediaCard}
            rainOnePermissions={[RainOnePermission.SendCsiPaymentFile,RainOnePermission.ViewCsiSummaryDetails]}
            icon={AccountBalanceIcon}
        >
            <div style={{ paddingBottom: theme.spacing(2) }}>
            <R1AuthorizedComponent
            rainOnePermission={RainOnePermission.SendCsiPaymentFile}>
                <CsiFilesCardItem />
                 </R1AuthorizedComponent>
                <R1AuthorizedComponent
                    rainOnePermission={RainOnePermission.ViewCsiSummaryDetails}
                >
                    <CsiDetailCardItem />
                </R1AuthorizedComponent>
                {/* <CardItem
                    message={`Journal${cubeCount === 1 ? '' : 's'} from previous months missing from the Cube`}
                    path={`${config.RedirectUrl}CubeStatus`}
                    isLoading={cubeStatusLoading}
                    error={cubeStatusError}
                    loadingMessage={'Loading Outstanding Cube Journals...'}
                    count={cubeCount}
                /> */}
            </div>
        </HomeCard>
    );
}

function CsiFilesCardItem() {
    const config = GetCurrentConfig(false, RainOneApp.Finops);
    const {
        data: csiPaymentsData,
        loading: csiPaymentsLoading,
        error: csiPaymentsError
    } = useCsiSummaryDetailsQuery({
        notifyOnNetworkStatusChange: true
    });

    const csiFilesCount: number | null = useMemo(
        () =>
            (csiPaymentsData?.csiSummaryDetails ?? ([] as CsiFileDetails[]))
                ?.length ?? null,
        [csiPaymentsData]
    );

    return (
        <CardItem
            message={`Pending CSI Payment${csiFilesCount === 1 ? '' : 's'}`}
            path={`${config.RedirectUrl}CsiPaymentsExport`}
            isLoading={csiPaymentsLoading}
            error={csiPaymentsError}
            loadingMessage={'Loading CSI Payments Count...'}
            count={csiFilesCount}
        />
    );
}

function CsiDetailCardItem() {
    const config = GetCurrentConfig(false, RainOneApp.Finops);

    const {
        data: csiVendorsData,
        loading: csiVendorsLoading,
        error: csiVendorsError
    } = useVendorsMissingFromCsiQuery({
        notifyOnNetworkStatusChange: true
    });

    const csiVendorsCount: number | null = useMemo(
        () =>
            (csiVendorsData?.vendorsMissingFromCSI ?? ([] as CsiVendorInfo[]))
                ?.length ?? null,
        [csiVendorsData]
    );

    return (
        <CardItem
            message={`CSI Vendor Update${csiVendorsCount === 1 ? '' : 's'}`}
            path={`${config.RedirectUrl}CsiExport`}
            isLoading={csiVendorsLoading}
            error={csiVendorsError}
            loadingMessage={'Loading CSI Vendor Updates Count...'}
            count={csiVendorsCount}
        />
    );
}
