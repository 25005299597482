import { CatchPhrase } from '../generated/graphql';
import R1BodyDescriptionInfoAdornment from './R1BodyDescriptionInfoAdornment';
import theme from '@rainagency/rain-one-core/dist/theme';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { R1PermContext, useR1Apollo } from '@rainagency/rain-one-core';
import { useContext } from 'react';
import { DELETE_CATCH_PHRASE, GET_CATCH_PHRASES } from '../graphql/queries';

export interface CatchPhraseIconsProps {
    catchPhrase: CatchPhrase;
    setCatchPhrase: (newValue: CatchPhrase | null) => void;
    setIsCatchPhraseDialogOpen: (isOpen: boolean) => void;
}

export default function CatchPhraseIcons({
    catchPhrase,
    setCatchPhrase,
    setIsCatchPhraseDialogOpen
}: CatchPhraseIconsProps) {
    const { simpleItemDelete, createToast } = useR1Apollo(GET_CATCH_PHRASES);
    const rainOnePersonId =
        useContext(R1PermContext).getUserDetails()?.rainOnePersonId;

    const deleteCatchPhrase = () => {
        simpleItemDelete(catchPhrase, DELETE_CATCH_PHRASE);
        setCatchPhrase(null);
        createToast('Catch phrase deleted', 'info');
    };

    return (
        <div
            style={{
                display: 'flex',
                gap: theme.spacing(0.5),
                alignItems: 'center'
            }}
        >
            <R1BodyDescriptionInfoAdornment
                message={`Contributed by ${catchPhrase.rainOnePerson?.displayName}`}
                utc={catchPhrase.createdUTC}
            />
            <Tooltip title="Contribute a RainONE catch phrase">
                <IconButton
                    style={{
                        height: '18px',
                        width: '18px'
                    }}
                    onClick={() => setIsCatchPhraseDialogOpen(true)}
                >
                    <AddIcon
                        style={{
                            color: theme.palette.text.secondary,
                            fontSize: 'initial',
                            height: '16px',
                            width: '16px'
                        }}
                    />
                </IconButton>
            </Tooltip>
            {rainOnePersonId === catchPhrase?.rainOnePersonId && (
                <Tooltip title="Delete this catch phrase">
                    <IconButton
                        style={{
                            height: '18px',
                            width: '18px'
                        }}
                        onClick={deleteCatchPhrase}
                    >
                        <DeleteOutlineIcon
                            style={{
                                color: theme.palette.text.secondary,
                                fontSize: 'initial',
                                height: '16px',
                                width: '16px'
                            }}
                        />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
}
