import {
    Card,
    CardContent,
    Typography,
    CardHeader,
    SvgIconTypeMap,
    SvgIcon
} from '@mui/material';
import {
    R1AuthorizedComponent,
    RainOnePermission
} from '@rainagency/rain-one-core';
import { useStyles } from './HomeCard.styles';
import theme from '@rainagency/rain-one-core/dist/theme';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface HomeCardProps {
    title: string;
    children: any;
    rainOnePermission?: RainOnePermission | undefined;
    rainOnePermissions?: RainOnePermission[];
    icon: OverridableComponent<SvgIconTypeMap>;
    className?: string | undefined;
}

export default function HomeCard(props: HomeCardProps) {
    const { title, children, rainOnePermission, rainOnePermissions, className, icon } = props;
    const classes = useStyles();
    const homeCard = (
        <Card
            variant="outlined"
            style={{ paddingBottom: theme.spacing(1) }}
            className={className}
        >
            <CardHeader
                title={
                    <Typography
                        className={classes.title}
                        variant="h6"
                        component="h6"
                    >
                        {title}
                    </Typography>
                }
                action={
                    <div style={{ padding: theme.spacing(1, 3, 0, 0) }}>
                        <SvgIcon
                            style={{ color: theme.palette.primary.main }}
                            component={icon}
                        />
                    </div>
                }
            />
            <CardContent>{children}</CardContent>
        </Card>
    );

    return <R1AuthorizedComponent rainOnePermission={rainOnePermission} rainOnePermissions={rainOnePermissions}>
                {homeCard}
            </R1AuthorizedComponent>;
}
