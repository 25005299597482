import { CircularProgress } from '@mui/material';
import { useStyles } from './CardItem.styles';

export interface CardItemProgressSpinnerProps {
    loading: boolean;
}

export default function CardItemProgressSpinner(
    props: CardItemProgressSpinnerProps
) {
    const { loading } = props;
    const classes = useStyles();

    if (!loading) {
        return null;
    }

    return <CircularProgress size={18} className={classes.spinner} />;
}
