import React from 'react';
import {
    R1AuthorizedComponent,
    R1Body,
    RainOnePermission
} from '@rainagency/rain-one-core';
import MediaCard from './components/MediaCard';
import TimeCard from './components/TimeCard';
import FavoritesCard from './components/FavoritesCard';
import { useEffect, useState } from 'react';
import { CatchPhrase, useGetCatchPhrasesQuery } from './generated/graphql';
import { R1Theme as theme } from '@rainagency/rain-one-core';
import CatchPhraseIcons from './components/CatchPhraseIcons';
import CatchPhraseEntry from './components/CatchPhraseEntry';
import AdminCard from './components/AdminCard';
import FinOpsCard from './components/FinOpsCard';

const breadcrumb = [
    {
        label: 'RainONE Home',
        link: '/'
    }
];

export default function Home() {
    const flexContainer = {
        display: 'flex',
        gap: theme.spacing(3),
        flexWrap: 'wrap',
        alignContent: 'start'
    };
    const [catchPhrase, setCatchPhrase] = useState<CatchPhrase | null>(null);
    const [isCatchPhraseDialogOpen, setIsCatchPhraseDialogOpen] =
        useState<boolean>(false);
    const { data } = useGetCatchPhrasesQuery();

    useEffect(() => {
        if (data?.catchPhrases && !catchPhrase) {
            const catchPhrases = data.catchPhrases as CatchPhrase[];

            if (catchPhrases.length > 0) {
                const newCatchPhrase =
                    catchPhrases[
                        Math.floor((Math.random() * catchPhrases.length) | 0)
                    ];
                setCatchPhrase(newCatchPhrase);
            }
        }
    }, [catchPhrase, data?.catchPhrases]);

    return (
        <R1Body
            title="RainONE Home"
            showBodyBackground={true}
            showRainDrops={true}
            description={catchPhrase?.phrase}
            breadcrumb={breadcrumb}
            descriptionEndAdornment={
                catchPhrase && (
                    <CatchPhraseIcons
                        catchPhrase={catchPhrase}
                        setCatchPhrase={setCatchPhrase}
                        setIsCatchPhraseDialogOpen={setIsCatchPhraseDialogOpen}
                    />
                )
            }
        >
            <div
                style={{
                    display: 'flex',
                    gap: theme.spacing(3),
                    flexWrap: 'wrap',
                    alignContent: 'start'
                }}
            >
                <FavoritesCard />
                <TimeCard />
                <MediaCard />
                <AdminCard />
                <FinOpsCard />
            </div>
            <CatchPhraseEntry
                isCatchPhraseDialogOpen={isCatchPhraseDialogOpen}
                setIsCatchPhraseDialogOpen={setIsCatchPhraseDialogOpen}
                setCatchPhrase={setCatchPhrase}
            />
        </R1Body>
    );
}
