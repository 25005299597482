// a react functional component that logs out of microsoft's msal and then redirects to the application root

import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import {useIsAuthenticated} from '@azure/msal-react';

export const Logout = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogout = () => {
    console.log('handleLogout inProgress: ', inProgress, isAuthenticated )
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      console.log('logging out');
      instance.logoutRedirect().catch((e) => { console.log('Logout error: ', e); });
    }
  }

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    null
  );
}

export default Logout;