import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import theme from '@rainagency/rain-one-core/dist/theme';

export const useStyles = makeStyles(() =>
    createStyles({
        timeCard: {
            width: '337px'
        },
        divider: {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1)
        }
    })
);
