import { Route } from 'react-router-dom';
import Home from './Home';
import { R1AlertViewer } from '@rainagency/rain-one-core';
import { Fragment } from 'react';
import Logout from './Logout';


const RoutesDef = (
    <Fragment>
        <Route path="/alertViewer" element={<R1AlertViewer />} />
        <Route path="/logout" element={<Logout />} />
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
    </Fragment>
)

export default RoutesDef;
