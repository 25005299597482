import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import HomeCard from './HomeCard';
import { useStyles } from './FavoritesCard.styles';
import {
    PageFavorite,
    RainOneApp,
    usePageFavoritesQuery
} from '../generated/graphql';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import WebIcon from '@mui/icons-material/Web';
import GetCurrentConfig from '@rainagency/rain-one-core/dist/Config';
import CardItemProgressSpinner from './CardItemProgressSpinner';
import { useState } from 'react';

const rowsPerPage: number = 5;

export default function FavoritesCard() {
    const classes = useStyles();
    const [page, setPage] = useState(0);

    const { data, error } = usePageFavoritesQuery({
        variables: {
            rainOneApp: RainOneApp.Client
        },
        fetchPolicy: 'cache-first'
    });

    function getFavoritePath(pageFavorite: PageFavorite): string {
        const config = GetCurrentConfig(false, pageFavorite.rainOneApp);
        return `${config.RedirectUrl}${pageFavorite.inAppPath.substring(1)}`;
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const favoritesCount = data?.pageFavorites?.length ?? 0;
    const emptyRows =
        favoritesCount === 0
            ? rowsPerPage - 1 // The 1 is for the "No Favorites Yet" row that we write.
            : Math.max(0, (1 + page) * rowsPerPage - favoritesCount);

    return (
        <HomeCard
            className={classes.card}
            title="RainONE Client Favorites"
            icon={WebIcon}
        >
            <TableContainer>
                <Table
                    size="small"
                    aria-label="favorites table"
                    component={(props) => <Paper elevation={0} {...props} />}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FavoriteBorderIcon
                                    className={classes.iconNotFavorited}
                                />
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Page</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {error ? (
                            <TableRow key="error">
                                <TableCell component="th" scope="row">
                                    <ErrorOutlineIcon color="error" />
                                </TableCell>
                                <TableCell>Failed to load favorites</TableCell>
                                <TableCell>Error</TableCell>
                                <TableCell align="right">
                                    <Button
                                        disabled={true}
                                        endIcon={<ArrowForwardIcon />}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : data ? (
                            data.pageFavorites
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((favorite) => (
                                    <TableRow key={favorite.id}>
                                        <TableCell component="th" scope="row">
                                            <FavoriteIcon color="primary" />
                                        </TableCell>
                                        <TableCell
                                            className={classes.favoriteCell}
                                        >
                                            {favorite.favoriteTitle}
                                        </TableCell>
                                        <TableCell className={classes.appCell}>
                                            {favorite.pageType.toLocaleLowerCase()}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button
                                                color="primary"
                                                endIcon={<ArrowForwardIcon />}
                                                href={getFavoritePath(favorite)}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow key="loading">
                                <TableCell component="th" scope="row">
                                    <CardItemProgressSpinner loading={true} />
                                </TableCell>
                                <TableCell>Loading Favorites...</TableCell>
                                <TableCell>Home</TableCell>
                                <TableCell align="right">
                                    <Button
                                        disabled={true}
                                        endIcon={<ArrowForwardIcon />}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                        {data && data.pageFavorites.length === 0 && (
                            <TableRow key="noFavorites">
                                <TableCell component="th" scope="row">
                                    <SentimentVeryDissatisfiedIcon color="primary" />
                                </TableCell>
                                <TableCell className={classes.favoriteCell}>
                                    No Favorites Yet!
                                </TableCell>
                                <TableCell className={classes.appCell}>
                                    Home
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        color="primary"
                                        disabled={true}
                                        endIcon={<ArrowForwardIcon />}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                        {data && emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={4} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow className={classes.footerRow}>
                            <TableCell colSpan={4}>
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[5]}
                                    count={data?.pageFavorites.length ?? 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </HomeCard>
    );
}
