import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import theme from '@rainagency/rain-one-core/dist/theme';
import { styled } from '@mui/styles';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        maxHeight: '500px',
        padding: theme.spacing(1)
    }
}));
