import { gql } from '@apollo/client';

export const TIMESHEET_QUERY = gql`
    query timeSheetQuery {
        timeSheetsWaitingForApproval {
            userDisplayName
            firstDayOfWeek
        }
    }
`;

export const CUBE_STATUS = gql`
    query CubeStatus {
        journalsMissingFromTheCube {
            monthDescription
            accountingDate
            journalDescription
            journalBatchNumber
            isPreviousMonth
            journalNumber
        }
    }
`;

export const MEDIA_CARD_COUNTS = gql`
    query getMediaCardCounts {
        outstandingUserInvoices {
            invoiceID
        }
        activeUserCampaigns {
            crossChannelCampaignId
        }
        activeUserInsertionOrders {
            insertionOrderId
        }
    }
`;

export const PAGE_FAVORITE_FRAGMENT = gql`
    fragment pageFavoriteFields on PageFavorite {
        id
        inAppPath
        rainOneApp
        title
        caption
        pageType
        favoriteTitle
        rainOnePersonId
    }
`;

export const PAGE_FAVORITES = gql`
    ${PAGE_FAVORITE_FRAGMENT}
    query PageFavorites($rainOneApp: RainOneApp!) {
        pageFavorites(rainOneApp: $rainOneApp) {
            ...pageFavoriteFields
        }
    }
`;

export const ACTION_ITEMS = gql`
    query ActionItems {
        actionItems {
            actionItemSubscriptionId
            actionItemType
            actionItemId
            isHighPriority
            isHumanClearable
            description
            isInactive
            inAppPath
            id
            linkName
            rainOnePersonId
            actionUrl
            rainOneApp
            hideLink
            createdDateUTC
            __typename
        }
    }
`;

export const CATCH_PHRASE_FIELDS = gql`
    fragment catchPhraseFields on CatchPhrase {
        catchPhraseId
        phrase
        rainOnePersonId
        createdUTC
        rainOnePerson {
            displayName
        }
    }
`;

export const GET_CATCH_PHRASES = gql`
    ${CATCH_PHRASE_FIELDS}
    query getCatchPhrases {
        catchPhrases {
            ...catchPhraseFields
        }
    }
`;

export const ADD_CATCH_PHRASE = gql`
    ${CATCH_PHRASE_FIELDS}
    mutation addCatchPhrase($phrase: String!) {
        addCatchPhrase(phrase: $phrase) {
            ...catchPhraseFields
        }
    }
`;

export const DELETE_CATCH_PHRASE = gql`
    mutation deleteCatchPhrase($id: Int!) {
        removeCachePhrase(id: $id)
    }
`;

export const DATORAMA_IMPORT_FIELDS = gql`
    fragment datoramaImportFields on DigitalSpendInputFileForLookup {
        digitalSpendInputFileID
        fileReferenceID
        filePath
        clientID
        trackerClientName
        dataGeneratedUTC
        importDateUTC
        importUserDescription
        mediaStartDate
        mediaEndDate
        isMostRecentFile
        hasErrors
        digitalAnalystAppUserDescription
        digitalSpendInputsCount
        digitalSpendInputsScheduleCount
        jobInstanceDetails {
            message
        }
    }
`;

export const GET_DATORAMA_IMPORT_STATUSES = gql`
    ${DATORAMA_IMPORT_FIELDS}
    query getDatoramaImportStatuses(
        $activeClientsOnly: Boolean
        $clientId: Int
        $mostRecentImportOnly: Boolean
        $importStartDate: DateTime
        $importEndDate: DateTime
        $digitalAnalystAppUserID: Int
        $filesWithErrorsOnly: Boolean
        $digitalSpendInputFileID: Int
    ) {
        digitalSpendImportsForLookup(
            activeClientsOnly: $activeClientsOnly
            clientID: $clientId
            mostRecentImportOnly: $mostRecentImportOnly
            importStartDate: $importStartDate
            importEndDate: $importEndDate
            digitalAnalystAppUserID: $digitalAnalystAppUserID
            filesWithErrorsOnly: $filesWithErrorsOnly
            digitalSpendInputFileID: $digitalSpendInputFileID
        ) {
            ...datoramaImportFields
        }
    }
`;

export const GET_RAIN_ONE_PEOPLE_FOR_DISPLAY = gql`
    query getRainOnePeopleForDisplay($rainOnePersonIds: [Int!]) {
        rainOnePeople_ForDisplay(rainOnePersonIds: $rainOnePersonIds) {
            accountSetupErrorMessages
        }
    }
`;

export const CSI_PAYMENT_DETAILS = gql`
    query csiSummaryDetails {
        csiSummaryDetails {
            uploadDateTimeUTC
            fileName
            details {
                invoiceCount
                dataAreaId
                amount
                paymentType
            }
        }
    }
`;

export const VENDOR_MISSING_FROM_CSI = gql`
    query vendorsMissingFromCSI($includeChecks: Boolean) {
        vendorsMissingFromCSI(includeChecks: $includeChecks) {
            achInfoExists
            vendorChangeDescription
            vendorId
            vendorName
            vendorGroup
        }
    }
`;

export const TIMESHEETS_IN_PURGATORY_COUNT = gql`
    query GetTimesheetsInPurgatoryCount {
        timesheetsInPurgatoryCount
    }
`;
export const TIMESHEETS_WAITING_FOR_APPROVAL_COUNT = gql`
    query GetTimeSheetsWaitingForApprovalCount {
        timeSheetsWaitingForApprovalCount
    }
`;

export const GET_TIMESHEET_HOME_CARD_COUNT = gql`
    query getTimesheetHomeCardCount(
        $timeSheetHomeCardCountType: TimeSheetHomeCardCountType!
    ) {
        timesheetsHomeCardCount(
            timeSheetHomeCardCountType: $timeSheetHomeCardCountType
        )
    }
`;
