import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { getR1MsalInstance, RainOneApp, setupLogging } from '@rainagency/rain-one-core';
import App from './App';

//const container = document.getElementById('root')
setupLogging(RainOneApp.Home, '0.1.0', '20240105.1');
const root = ReactDOM.createRoot(document.getElementById('root')!);
const msalInstance = getR1MsalInstance(RainOneApp.Home);
root.render(
    <React.StrictMode>
        <App pca={msalInstance} />
    </React.StrictMode>
);