import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import theme from '@rainagency/rain-one-core/dist/theme';

export const useStyles = makeStyles(() =>
    createStyles({
        cardItem: {
            padding: theme.spacing(1),
            verticalAlign: 'center',
            color: theme.palette.text.primary,
            display: 'grid',
            gap: theme.spacing(1),
            gridTemplateColumns: 'auto 1fr auto'
        },
        itemCount: {
            color: theme.palette.error.main,
            marginRight: theme.spacing(1)
        },
        errorButton: {
            color: theme.palette.error.main
        },
        button: {
            color: theme.palette.text.primary
        },
        iconButton: {
            color: theme.palette.primary.main
        },
        spinner: {
            size: 15,
            color: theme.palette.text.secondary,
            padding: theme.spacing(1)
        }
    })
);
