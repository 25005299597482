import { R1AppWrapper, RainOneApp } from '@rainagency/rain-one-core';
import HomeProvider from './contexts/HomeProvider';
import Routes from './Routes';

const App = ({ pca }: any) => {

    const newMenu = {
        home: {
            basePath: '',
            label: 'Home',
            menu: []
        }
    };

    return (
        <R1AppWrapper
            menu={newMenu}
            rainOneApp={RainOneApp.Home}
            useLocalGraphQlServerOnDev={true}
            pca={pca}
            appRoutesFragment={Routes as any}
            provider={HomeProvider}
            appVersion="0.1.0"
            buildNumber="20240105.1"
        />
    );
};

export default App;
