import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@mui/material';
import { useR1Apollo } from '@rainagency/rain-one-core';
import { useState } from 'react';
import { CatchPhrase } from '../generated/graphql';
import { ADD_CATCH_PHRASE, GET_CATCH_PHRASES } from '../graphql/queries';

export interface CatchPhraseEntryProps {
    isCatchPhraseDialogOpen: boolean;
    setIsCatchPhraseDialogOpen: (isOpen: boolean) => void;
    setCatchPhrase: (catchPhrase: CatchPhrase | null) => void;
}

export default function CatchPhraseEntry({
    isCatchPhraseDialogOpen,
    setIsCatchPhraseDialogOpen,
    setCatchPhrase
}: CatchPhraseEntryProps) {
    const [newPhrase, setNewPhrase] = useState<string>('');
    const { simpleItemUpdate, createToast } = useR1Apollo(GET_CATCH_PHRASES);

    function closeCatchPhraseDialog() {
        setIsCatchPhraseDialogOpen(false);
    }

    function addCatchPhrase() {
        if (newPhrase && newPhrase.trim() !== '') {
            simpleItemUpdate(
                false,
                ADD_CATCH_PHRASE,
                { phrase: newPhrase },
                true
            )
                .then((value) => {
                    const newCatchPhrase: CatchPhrase =
                        value.data.addCatchPhrase;

                    if (newCatchPhrase) {
                        setCatchPhrase(newCatchPhrase);
                    }

                    createToast('Catch phrase added.', 'success');
                })
                .finally(() => {
                    closeCatchPhraseDialog();
                });
        }
    }

    return (
        <Dialog open={isCatchPhraseDialogOpen} onClose={closeCatchPhraseDialog}>
            <DialogTitle>Add a Catch Phrase</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    What ultra-clever catch phrase would you like to contribute
                    to RainONE?
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="email"
                    fullWidth
                    variant="standard"
                    onBlur={(event) => setNewPhrase(event.target.value ?? '')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeCatchPhraseDialog}>Cancel</Button>
                <Button onClick={addCatchPhrase}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}
