import { Button, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStyles } from './CardItem.styles';
import CardItemProgressSpinner from './CardItemProgressSpinner';
import { ApolloError } from '@apollo/client';
import { Fragment } from 'react';

export interface CardItemProps {
    message: string;
    path: string;
    error?: ApolloError | undefined;
    isLoading?: boolean | undefined;
    loadingMessage?: string | undefined;
    count?: number | null | undefined;
}

export default function CardItem(props: CardItemProps) {
    const { path, message, loadingMessage, count, isLoading, error } = props;
    const classes = useStyles();

    return (
        <Fragment>
            {isLoading ? (
                <div className={classes.cardItem}>
                    <Button href={path}>{loadingMessage}</Button>
                    <div />
                    <CardItemProgressSpinner loading={isLoading} />
                </div>
            ) : error ? (
                <div className={classes.cardItem}>
                    <Button
                        href={path}
                        className={classes.errorButton}
                    >{`Failed to load ${message}`}</Button>
                    <IconButton
                        href={path}
                        className={classes.errorButton}
                        size="small"
                    >
                        <ErrorOutlineIcon />
                    </IconButton>
                </div>
            ) : (
                <div className={classes.cardItem}>
                    <Button className={classes.button} href={path}>
                        {count !== null && count !== undefined && (
                            <span className={classes.itemCount}>
                                {count ?? 0}
                            </span>
                        )}
                        {message}
                    </Button>
                    <div />
                    <IconButton
                        className={classes.iconButton}
                        href={path}
                        size="small"
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </div>
            )}
        </Fragment>
    );
}
