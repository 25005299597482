import { Fragment } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import { HtmlTooltip } from './HtmlTooltip';
import TimeAgo from 'react-timeago';

export interface R1BodyDescriptionInfoAdornmentProps {
    message: string;
    utc: Date;
}

export default function R1BodyDescriptionInfoAdornment({
    message,
    utc
}: R1BodyDescriptionInfoAdornmentProps) {
    return (
        <HtmlTooltip
            title={
                <Fragment>
                    <Typography variant="subtitle2" color="textSecondary">
                        {message}
                    </Typography>
                    <TimeAgo
                        style={{ color: 'textSecondary' }}
                        date={new Date(utc as Date).toLocaleString()}
                    />
                </Fragment>
            }
        >
            <InfoOutlinedIcon
                style={{
                    fontSize: 'initial'
                }}
            />
        </HtmlTooltip>
    );
}
