import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  Date: any;
  DateTime: any;
  Decimal: any;
  Long: any;
  TimeSpan: any;
};

export type AxBrandDim = {
  __typename?: 'AXBrandDim';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AxClientDim = {
  __typename?: 'AXClientDim';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AxDepartmentDim = {
  __typename?: 'AXDepartmentDim';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AxInitiativeDim = {
  __typename?: 'AXInitiativeDim';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AxMediaMonth = {
  __typename?: 'AXMediaMonth';
  dateRange: Scalars['String'];
  displayName: Scalars['String'];
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  syntheticMediaMonth: Scalars['Boolean'];
  value: Scalars['String'];
};

export type AxProductDim = {
  __typename?: 'AXProductDim';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AxProjectDim = {
  __typename?: 'AXProjectDim';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AxPurposeDim = {
  __typename?: 'AXPurposeDim';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AccountingBatch = {
  __typename?: 'AccountingBatch';
  accountingBatchId: Scalars['Int'];
  accountingBatchLineLinks?: Maybe<Array<Maybe<AccountingBatchLineLink>>>;
  accountingBatchTypeLookup?: Maybe<Lookup>;
  accountingBatchTypeLookupId: Scalars['Int'];
  accountingLines?: Maybe<Array<Maybe<AccountingLine>>>;
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  associatedAccountingBatch?: Maybe<AccountingBatch>;
  associatedAccountingBatchId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  inverseAssociatedAccountingBatch?: Maybe<Array<Maybe<AccountingBatch>>>;
  isDeleted: Scalars['Boolean'];
  isFake: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  overrideExportDate?: Maybe<Scalars['Date']>;
};

export type AccountingBatchFilterInput = {
  accountingBatchId?: InputMaybe<IntOperationFilterInput>;
  accountingBatchLineLinks?: InputMaybe<ListFilterInputTypeOfAccountingBatchLineLinkFilterInput>;
  accountingBatchTypeLookup?: InputMaybe<LookupFilterInput>;
  accountingBatchTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  accountingLines?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  and?: InputMaybe<Array<AccountingBatchFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  associatedAccountingBatch?: InputMaybe<AccountingBatchFilterInput>;
  associatedAccountingBatchId?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  inverseAssociatedAccountingBatch?: InputMaybe<ListFilterInputTypeOfAccountingBatchFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isFake?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AccountingBatchFilterInput>>;
  overrideExportDate?: InputMaybe<DateOperationFilterInput>;
};

export type AccountingBatchLineLink = {
  __typename?: 'AccountingBatchLineLink';
  accountingBatch?: Maybe<AccountingBatch>;
  accountingBatchId: Scalars['Int'];
  accountingBatchLineLinkId: Scalars['Int'];
  accountingLine?: Maybe<AccountingLine>;
  accountingLineId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  isDeleted: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  subTypeLookup?: Maybe<Lookup>;
  subTypeLookupId?: Maybe<Scalars['Int']>;
};

export type AccountingBatchLineLinkFilterInput = {
  accountingBatch?: InputMaybe<AccountingBatchFilterInput>;
  accountingBatchId?: InputMaybe<IntOperationFilterInput>;
  accountingBatchLineLinkId?: InputMaybe<IntOperationFilterInput>;
  accountingLine?: InputMaybe<AccountingLineFilterInput>;
  accountingLineId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AccountingBatchLineLinkFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AccountingBatchLineLinkFilterInput>>;
  subTypeLookup?: InputMaybe<LookupFilterInput>;
  subTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AccountingLine = {
  __typename?: 'AccountingLine';
  accountingBatchLineLinks?: Maybe<Array<Maybe<AccountingBatchLineLink>>>;
  accountingLineId: Scalars['Int'];
  accountingLineNonPrepaySchedules?: Maybe<Array<Maybe<AccountingLineNonPrepaySchedule>>>;
  appLocation?: Maybe<AppLocation>;
  appLocationId: Scalars['Int'];
  axclientCode?: Maybe<Scalars['String']>;
  axdocumentDate?: Maybe<Scalars['String']>;
  axitemCode?: Maybe<Scalars['String']>;
  axmonth?: Maybe<Scalars['String']>;
  axprojectCode?: Maybe<Scalars['String']>;
  axvendorCode?: Maybe<Scalars['String']>;
  billableLeads?: Maybe<Scalars['Int']>;
  buyType?: Maybe<Scalars['String']>;
  buyTypeId?: Maybe<Scalars['Int']>;
  buyTypeNavigation?: Maybe<Lookup>;
  clientName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costAmount?: Maybe<Scalars['Decimal']>;
  costDescription?: Maybe<Scalars['String']>;
  cpl?: Maybe<Scalars['Decimal']>;
  creditMemoInvoice?: Maybe<Invoice>;
  creditMemoInvoiceId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  inverseRefAccountingLine?: Maybe<Array<Maybe<AccountingLine>>>;
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['Int']>;
  isClientPaying: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isFee: Scalars['Boolean'];
  isPartnership: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  monthDefinitionLookup?: Maybe<Lookup>;
  monthDefinitionLookupId?: Maybe<Scalars['Int']>;
  payableLeads?: Maybe<Scalars['Int']>;
  paymentFileReference?: Maybe<FileReference>;
  paymentFileReferenceId?: Maybe<Scalars['Int']>;
  payout?: Maybe<Scalars['Decimal']>;
  recordId?: Maybe<Scalars['Int']>;
  recordTableName?: Maybe<Scalars['String']>;
  refAccountingBatch?: Maybe<AccountingBatch>;
  refAccountingBatchId?: Maybe<Scalars['Int']>;
  refAccountingLine?: Maybe<AccountingLine>;
  refAccountingLineId?: Maybe<Scalars['Int']>;
  revenueAmount?: Maybe<Scalars['Decimal']>;
  revenueDescription?: Maybe<Scalars['String']>;
  scenario?: Maybe<Scalars['String']>;
  schedule?: Maybe<Schedule>;
  scheduleId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  skuNavigation?: Maybe<Sku>;
  skuid?: Maybe<Scalars['Int']>;
  subTypeLookup?: Maybe<Lookup>;
  subTypeLookupId: Scalars['Int'];
  weekOf?: Maybe<Scalars['Date']>;
};

export type AccountingLineFilterInput = {
  accountingBatchLineLinks?: InputMaybe<ListFilterInputTypeOfAccountingBatchLineLinkFilterInput>;
  accountingLineId?: InputMaybe<IntOperationFilterInput>;
  accountingLineNonPrepaySchedules?: InputMaybe<ListFilterInputTypeOfAccountingLineNonPrepayScheduleFilterInput>;
  and?: InputMaybe<Array<AccountingLineFilterInput>>;
  appLocation?: InputMaybe<AppLocationFilterInput>;
  appLocationId?: InputMaybe<IntOperationFilterInput>;
  axclientCode?: InputMaybe<StringOperationFilterInput>;
  axdocumentDate?: InputMaybe<StringOperationFilterInput>;
  axitemCode?: InputMaybe<StringOperationFilterInput>;
  axmonth?: InputMaybe<StringOperationFilterInput>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  billableLeads?: InputMaybe<IntOperationFilterInput>;
  buyType?: InputMaybe<StringOperationFilterInput>;
  buyTypeId?: InputMaybe<IntOperationFilterInput>;
  buyTypeNavigation?: InputMaybe<LookupFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costAmount?: InputMaybe<DecimalOperationFilterInput>;
  costDescription?: InputMaybe<StringOperationFilterInput>;
  cpl?: InputMaybe<DecimalOperationFilterInput>;
  creditMemoInvoice?: InputMaybe<InvoiceFilterInput>;
  creditMemoInvoiceId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  inverseRefAccountingLine?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  invoice?: InputMaybe<InvoiceFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  isClientPaying?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isFee?: InputMaybe<BooleanOperationFilterInput>;
  isPartnership?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  monthDefinitionLookup?: InputMaybe<LookupFilterInput>;
  monthDefinitionLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AccountingLineFilterInput>>;
  payableLeads?: InputMaybe<IntOperationFilterInput>;
  paymentFileReference?: InputMaybe<FileReferenceFilterInput>;
  paymentFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  payout?: InputMaybe<DecimalOperationFilterInput>;
  recordId?: InputMaybe<IntOperationFilterInput>;
  recordTableName?: InputMaybe<StringOperationFilterInput>;
  refAccountingBatch?: InputMaybe<AccountingBatchFilterInput>;
  refAccountingBatchId?: InputMaybe<IntOperationFilterInput>;
  refAccountingLine?: InputMaybe<AccountingLineFilterInput>;
  refAccountingLineId?: InputMaybe<IntOperationFilterInput>;
  revenueAmount?: InputMaybe<DecimalOperationFilterInput>;
  revenueDescription?: InputMaybe<StringOperationFilterInput>;
  scenario?: InputMaybe<StringOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  serviceId?: InputMaybe<IntOperationFilterInput>;
  sku?: InputMaybe<StringOperationFilterInput>;
  skuNavigation?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  subTypeLookup?: InputMaybe<LookupFilterInput>;
  subTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  weekOf?: InputMaybe<DateOperationFilterInput>;
};

export type AccountingLineNonPrepaySchedule = {
  __typename?: 'AccountingLineNonPrepaySchedule';
  accountingLine?: Maybe<AccountingLine>;
  accountingLineId: Scalars['Int'];
  accountingLineNonPrepaySchedulesId: Scalars['Int'];
  amountToChargeClient?: Maybe<Scalars['Decimal']>;
  amountToPayStation?: Maybe<Scalars['Decimal']>;
  clearedAds?: Maybe<Scalars['Int']>;
  clientClearedMultiplier?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costAdjustment?: Maybe<Scalars['Decimal']>;
  estimatedAds?: Maybe<Scalars['Int']>;
  isCleared?: Maybe<Scalars['Decimal']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  revenueAdjustment?: Maybe<Scalars['Decimal']>;
  schedule?: Maybe<Schedule>;
  scheduleId: Scalars['Int'];
  vendorClearedMultiplier?: Maybe<Scalars['Decimal']>;
};

export type AccountingLineNonPrepayScheduleFilterInput = {
  accountingLine?: InputMaybe<AccountingLineFilterInput>;
  accountingLineId?: InputMaybe<IntOperationFilterInput>;
  accountingLineNonPrepaySchedulesId?: InputMaybe<IntOperationFilterInput>;
  amountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  amountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<AccountingLineNonPrepayScheduleFilterInput>>;
  clearedAds?: InputMaybe<IntOperationFilterInput>;
  clientClearedMultiplier?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costAdjustment?: InputMaybe<DecimalOperationFilterInput>;
  estimatedAds?: InputMaybe<IntOperationFilterInput>;
  isCleared?: InputMaybe<DecimalOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AccountingLineNonPrepayScheduleFilterInput>>;
  revenueAdjustment?: InputMaybe<DecimalOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  vendorClearedMultiplier?: InputMaybe<DecimalOperationFilterInput>;
};

export type AccountingLock = {
  __typename?: 'AccountingLock';
  accountingLockId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lockDate: Scalars['Date'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
};

export type AccountingLockFilterInput = {
  accountingLockId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AccountingLockFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lockDate?: InputMaybe<DateOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AccountingLockFilterInput>>;
};

export enum ActionItemEmailPreference {
  /** Daily */
  Daily = 'DAILY',
  /** Weekly - Mondays */
  FirstDayOfWeek = 'FIRST_DAY_OF_WEEK',
  /** Weekly - Fridays */
  LastDayOfWeek = 'LAST_DAY_OF_WEEK',
  /** Weekly - Mondays and Fridays */
  StartAndEndOfWeek = 'START_AND_END_OF_WEEK'
}

export enum ActionItemType {
  ApproveEmailPictures = 'APPROVE_EMAIL_PICTURES',
  ApproveTimeTransfers = 'APPROVE_TIME_TRANSFERS',
  CompleteTimeTransfers = 'COMPLETE_TIME_TRANSFERS',
  CsiVendorExport = 'CSI_VENDOR_EXPORT',
  D365SetupError = 'D365_SETUP_ERROR',
  DataBreakageTimeAlert = 'DATA_BREAKAGE_TIME_ALERT',
  EmployeeMissingManager = 'EMPLOYEE_MISSING_MANAGER',
  GeneralAdminError = 'GENERAL_ADMIN_ERROR',
  InvoiceAutoApproved = 'INVOICE_AUTO_APPROVED',
  InvoiceAwaitingApproval = 'INVOICE_AWAITING_APPROVAL',
  LateTimesheet = 'LATE_TIMESHEET',
  ManagerTimesheetApproval = 'MANAGER_TIMESHEET_APPROVAL',
  MediaAuthorizationApproved = 'MEDIA_AUTHORIZATION_APPROVED',
  MediaAuthorizationAwaitingClientServiceApproval = 'MEDIA_AUTHORIZATION_AWAITING_CLIENT_SERVICE_APPROVAL',
  MediaAuthorizationAwaitingFinanceApproval = 'MEDIA_AUTHORIZATION_AWAITING_FINANCE_APPROVAL',
  MissingBudget = 'MISSING_BUDGET',
  MissingDigitalMediaSupervisor = 'MISSING_DIGITAL_MEDIA_SUPERVISOR',
  MissingInvoiceApprover = 'MISSING_INVOICE_APPROVER',
  PaymentProofUploadStatus = 'PAYMENT_PROOF_UPLOAD_STATUS',
  PermissionRequest = 'PERMISSION_REQUEST',
  PftClientApproved = 'PFT_CLIENT_APPROVED',
  PftDepartmentEstimateNeeded = 'PFT_DEPARTMENT_ESTIMATE_NEEDED',
  PftInternalWorkflowApproval = 'PFT_INTERNAL_WORKFLOW_APPROVAL',
  PftSendEstimateToClient = 'PFT_SEND_ESTIMATE_TO_CLIENT',
  PftSetupNeeded = 'PFT_SETUP_NEEDED',
  PftUploadClientApproval = 'PFT_UPLOAD_CLIENT_APPROVAL',
  ReturnedTimesheet = 'RETURNED_TIMESHEET'
}

export type ActiveMetricRestriction = {
  __typename?: 'ActiveMetricRestriction';
  calculateSize: Scalars['Int'];
  clone?: Maybe<ActiveMetricRestriction>;
  hasMetricName: Scalars['Boolean'];
  metricName?: Maybe<Scalars['String']>;
  restrictedMetricTypes?: Maybe<Array<RestrictedMetricType>>;
};

export type ActivityCategory_ForDisplay = {
  __typename?: 'ActivityCategory_ForDisplay';
  activityCategoryId: Scalars['Int'];
  activityId: Scalars['Int'];
  activityName: Scalars['String'];
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  id: Scalars['Int'];
};

export type Activity_ForDisplay = {
  __typename?: 'Activity_ForDisplay';
  activityId: Scalars['Int'];
  activityName: Scalars['String'];
  id: Scalars['Int'];
};

export type Ad = {
  __typename?: 'Ad';
  abbreviation?: Maybe<Scalars['String']>;
  adCampaigns?: Maybe<Array<Maybe<AdCampaign>>>;
  adEndDate?: Maybe<Scalars['DateTime']>;
  adId: Scalars['Int'];
  agencyLookup?: Maybe<Lookup>;
  agencyLookupId?: Maybe<Scalars['Int']>;
  autoScheduleTrafficMediaChanges?: Maybe<Array<Maybe<AutoScheduleTrafficMediaChange>>>;
  availProposalDetails?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  axtypeLookup?: Maybe<Lookup>;
  axtypeLookupId: Scalars['Int'];
  brokerVendor?: Maybe<Vendor>;
  brokerVendorId?: Maybe<Scalars['Int']>;
  campaignStatusLookup?: Maybe<Lookup>;
  campaignStatusLookupId: Scalars['Int'];
  clientApprovals?: Maybe<Array<Maybe<ClientApproval>>>;
  comments?: Maybe<Scalars['String']>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  creative?: Maybe<Creative>;
  creativeAgencyLookup?: Maybe<Lookup>;
  creativeAgencyLookupId?: Maybe<Scalars['Int']>;
  creativeId?: Maybe<Scalars['Int']>;
  currentUniqueIsciincrement?: Maybe<Scalars['Int']>;
  dubHouseContact?: Maybe<Contact>;
  dubHouseContactId?: Maybe<Scalars['Int']>;
  dubHouseMasterNumber?: Maybe<Scalars['String']>;
  dubHouseVendor?: Maybe<Vendor>;
  dubHouseVendorId?: Maybe<Scalars['Int']>;
  duplicationCostEstimateAds?: Maybe<Array<Maybe<DuplicationCostEstimateAd>>>;
  duplicationCostEstimateDetails?: Maybe<Array<Maybe<DuplicationCostEstimateDetail>>>;
  excludeFromDailyCallProjections: Scalars['Boolean'];
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  hddub: Scalars['Boolean'];
  hdisci?: Maybe<Scalars['String']>;
  highDefDubHouseMasterNumber?: Maybe<Scalars['String']>;
  isCenterCutSafe: Scalars['Boolean'];
  isClosedCaptionRequired: Scalars['Boolean'];
  isCrossCompany?: Maybe<Scalars['Boolean']>;
  isHdmaster: Scalars['Boolean'];
  isLiveRead?: Maybe<Scalars['Boolean']>;
  isPlaceHolder: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  isVanitySpot: Scalars['Boolean'];
  isci?: Maybe<Scalars['String']>;
  languageLookup?: Maybe<Lookup>;
  languageLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  mailerFormatLookup?: Maybe<Lookup>;
  mailerFormatLookupId?: Maybe<Scalars['Int']>;
  mediaFileReference?: Maybe<FileReference>;
  mediaFileReferenceId?: Maybe<Scalars['Int']>;
  mediaScheduleAds?: Maybe<Array<Maybe<MediaScheduleAd>>>;
  mediaScheduleDetailAdOverrides?: Maybe<Array<Maybe<MediaScheduleDetailAdOverride>>>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  nativeDuplication: Scalars['Boolean'];
  newCampaignAnnouncementPiassociations?: Maybe<Array<Maybe<NewCampaignAnnouncementPiassociation>>>;
  newCampaignAssociations?: Maybe<Array<Maybe<NewCampaignAssociation>>>;
  offer?: Maybe<Scalars['String']>;
  offerTypeLookup?: Maybe<Lookup>;
  offerTypeLookupId: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productMediaOutletPirates?: Maybe<Array<Maybe<ProductMediaOutletPirate>>>;
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
  revisionRequests?: Maybe<Array<Maybe<RevisionRequest>>>;
  scriptAds?: Maybe<Array<Maybe<ScriptAd>>>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
  startDate?: Maybe<Scalars['DateTime']>;
  substantiationDeckLink?: Maybe<Scalars['String']>;
  suppressStandardDefinitionDuplication: Scalars['Boolean'];
  surveys?: Maybe<Array<Maybe<Survey>>>;
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId?: Maybe<Scalars['Int']>;
  teleCode?: Maybe<Scalars['String']>;
  title?: Maybe<Title>;
  titleId?: Maybe<Scalars['Int']>;
  trackingCodeAvailCustomizeAds?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodeAvailIntendedAds?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodesNotYetAvailable: Scalars['Boolean'];
  trackingServiceLines?: Maybe<Array<Maybe<TrackingServiceLine>>>;
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  vanityTfn?: Maybe<Scalars['String']>;
  veilEncodingIdentifier?: Maybe<Scalars['String']>;
  veilEncodingLookup?: Maybe<Lookup>;
  veilEncodingLookupId: Scalars['Int'];
  version?: Maybe<Scalars['String']>;
  viewLinkUrl?: Maybe<Scalars['String']>;
  webSite?: Maybe<Scalars['String']>;
};

export type AdCampaign = {
  __typename?: 'AdCampaign';
  ad?: Maybe<Ad>;
  adCampaignId: Scalars['Int'];
  adId: Scalars['Int'];
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type AdCampaignFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adCampaignId?: InputMaybe<IntOperationFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AdCampaignFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AdCampaignFilterInput>>;
};

export type AdFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  adCampaigns?: InputMaybe<ListFilterInputTypeOfAdCampaignFilterInput>;
  adEndDate?: InputMaybe<DateTimeOperationFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  agencyLookup?: InputMaybe<LookupFilterInput>;
  agencyLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AdFilterInput>>;
  autoScheduleTrafficMediaChanges?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficMediaChangeFilterInput>;
  availProposalDetails?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  axtypeLookup?: InputMaybe<LookupFilterInput>;
  axtypeLookupId?: InputMaybe<IntOperationFilterInput>;
  brokerVendor?: InputMaybe<VendorFilterInput>;
  brokerVendorId?: InputMaybe<IntOperationFilterInput>;
  campaignStatusLookup?: InputMaybe<LookupFilterInput>;
  campaignStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  clientApprovals?: InputMaybe<ListFilterInputTypeOfClientApprovalFilterInput>;
  comments?: InputMaybe<StringOperationFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  creative?: InputMaybe<CreativeFilterInput>;
  creativeAgencyLookup?: InputMaybe<LookupFilterInput>;
  creativeAgencyLookupId?: InputMaybe<IntOperationFilterInput>;
  creativeId?: InputMaybe<IntOperationFilterInput>;
  currentUniqueIsciincrement?: InputMaybe<IntOperationFilterInput>;
  dubHouseContact?: InputMaybe<ContactFilterInput>;
  dubHouseContactId?: InputMaybe<IntOperationFilterInput>;
  dubHouseMasterNumber?: InputMaybe<StringOperationFilterInput>;
  dubHouseVendor?: InputMaybe<VendorFilterInput>;
  dubHouseVendorId?: InputMaybe<IntOperationFilterInput>;
  duplicationCostEstimateAds?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateAdFilterInput>;
  duplicationCostEstimateDetails?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateDetailFilterInput>;
  excludeFromDailyCallProjections?: InputMaybe<BooleanOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  hddub?: InputMaybe<BooleanOperationFilterInput>;
  hdisci?: InputMaybe<StringOperationFilterInput>;
  highDefDubHouseMasterNumber?: InputMaybe<StringOperationFilterInput>;
  isCenterCutSafe?: InputMaybe<BooleanOperationFilterInput>;
  isClosedCaptionRequired?: InputMaybe<BooleanOperationFilterInput>;
  isCrossCompany?: InputMaybe<BooleanOperationFilterInput>;
  isHdmaster?: InputMaybe<BooleanOperationFilterInput>;
  isLiveRead?: InputMaybe<BooleanOperationFilterInput>;
  isPlaceHolder?: InputMaybe<BooleanOperationFilterInput>;
  isSystem?: InputMaybe<BooleanOperationFilterInput>;
  isVanitySpot?: InputMaybe<BooleanOperationFilterInput>;
  isci?: InputMaybe<StringOperationFilterInput>;
  languageLookup?: InputMaybe<LookupFilterInput>;
  languageLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  mailerFormatLookup?: InputMaybe<LookupFilterInput>;
  mailerFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaFileReference?: InputMaybe<FileReferenceFilterInput>;
  mediaFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleAds?: InputMaybe<ListFilterInputTypeOfMediaScheduleAdFilterInput>;
  mediaScheduleDetailAdOverrides?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailAdOverrideFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nativeDuplication?: InputMaybe<BooleanOperationFilterInput>;
  newCampaignAnnouncementPiassociations?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiassociationFilterInput>;
  newCampaignAssociations?: InputMaybe<ListFilterInputTypeOfNewCampaignAssociationFilterInput>;
  offer?: InputMaybe<StringOperationFilterInput>;
  offerTypeLookup?: InputMaybe<LookupFilterInput>;
  offerTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AdFilterInput>>;
  perInquiryCosts?: InputMaybe<ListFilterInputTypeOfPerInquiryCostFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productMediaOutletPirates?: InputMaybe<ListFilterInputTypeOfProductMediaOutletPirateFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  revisionRequests?: InputMaybe<ListFilterInputTypeOfRevisionRequestFilterInput>;
  scriptAds?: InputMaybe<ListFilterInputTypeOfScriptAdFilterInput>;
  standardsAndPracticesApprovals?: InputMaybe<ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  substantiationDeckLink?: InputMaybe<StringOperationFilterInput>;
  suppressStandardDefinitionDuplication?: InputMaybe<BooleanOperationFilterInput>;
  surveys?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
  teleCode?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<TitleFilterInput>;
  titleId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvailCustomizeAds?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodeAvailIntendedAds?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodesNotYetAvailable?: InputMaybe<BooleanOperationFilterInput>;
  trackingServiceLines?: InputMaybe<ListFilterInputTypeOfTrackingServiceLineFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  vanityTfn?: InputMaybe<StringOperationFilterInput>;
  veilEncodingIdentifier?: InputMaybe<StringOperationFilterInput>;
  veilEncodingLookup?: InputMaybe<LookupFilterInput>;
  veilEncodingLookupId?: InputMaybe<IntOperationFilterInput>;
  version?: InputMaybe<StringOperationFilterInput>;
  viewLinkUrl?: InputMaybe<StringOperationFilterInput>;
  webSite?: InputMaybe<StringOperationFilterInput>;
};

export type AdInstance = {
  __typename?: 'AdInstance';
  adInstanceDetails?: Maybe<Array<Maybe<AdInstanceDetail>>>;
  adInstanceId: Scalars['Int'];
  adInstanceStatusLookup?: Maybe<Lookup>;
  adInstanceStatusLookupId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  createUtc?: Maybe<Scalars['DateTime']>;
  date: Scalars['Date'];
  estimatedValue?: Maybe<Scalars['Decimal']>;
  internalComment?: Maybe<Scalars['String']>;
  isCreatedByProcessor: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  matchType?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  postLogRate?: Maybe<Scalars['Decimal']>;
  prelog?: Maybe<Prelog>;
  prelogId?: Maybe<Scalars['Int']>;
  ratingAdInstances?: Maybe<Array<Maybe<RatingAdInstance>>>;
  rentrakRatingAdInstances?: Maybe<Array<Maybe<RentrakRatingAdInstance>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  roviProgramId?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['TimeSpan']>;
  uniqueAdSchedule?: Maybe<UniqueAdSchedule>;
  uniqueAdScheduleId: Scalars['Int'];
  utc?: Maybe<Scalars['DateTime']>;
  waveCastAdInstances?: Maybe<Array<Maybe<WaveCastAdInstance>>>;
};

export type AdInstanceDetail = {
  __typename?: 'AdInstanceDetail';
  adInstance?: Maybe<AdInstance>;
  adInstanceDetailId: Scalars['Int'];
  adInstanceId?: Maybe<Scalars['Int']>;
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  clicks?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  constituentInstances?: Maybe<Scalars['Int']>;
  detailPercent?: Maybe<Scalars['Decimal']>;
  impressions?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  prelog?: Maybe<Prelog>;
  prelogId?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Result>>>;
  theoreticalGrossAmount?: Maybe<Scalars['Decimal']>;
  totalImpressions?: Maybe<Scalars['Int']>;
  utc: Scalars['DateTime'];
};

export type AdInstanceDetailFilterInput = {
  adInstance?: InputMaybe<AdInstanceFilterInput>;
  adInstanceDetailId?: InputMaybe<IntOperationFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AdInstanceDetailFilterInput>>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  clicks?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  constituentInstances?: InputMaybe<IntOperationFilterInput>;
  detailPercent?: InputMaybe<DecimalOperationFilterInput>;
  impressions?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AdInstanceDetailFilterInput>>;
  prelog?: InputMaybe<PrelogFilterInput>;
  prelogId?: InputMaybe<IntOperationFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  theoreticalGrossAmount?: InputMaybe<DecimalOperationFilterInput>;
  totalImpressions?: InputMaybe<IntOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type AdInstanceFilterInput = {
  adInstanceDetails?: InputMaybe<ListFilterInputTypeOfAdInstanceDetailFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  adInstanceStatusLookup?: InputMaybe<LookupFilterInput>;
  adInstanceStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AdInstanceFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  estimatedValue?: InputMaybe<DecimalOperationFilterInput>;
  internalComment?: InputMaybe<StringOperationFilterInput>;
  isCreatedByProcessor?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  matchType?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AdInstanceFilterInput>>;
  postLogRate?: InputMaybe<DecimalOperationFilterInput>;
  prelog?: InputMaybe<PrelogFilterInput>;
  prelogId?: InputMaybe<IntOperationFilterInput>;
  ratingAdInstances?: InputMaybe<ListFilterInputTypeOfRatingAdInstanceFilterInput>;
  rentrakRatingAdInstances?: InputMaybe<ListFilterInputTypeOfRentrakRatingAdInstanceFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
  time?: InputMaybe<TimeSpanOperationFilterInput>;
  uniqueAdSchedule?: InputMaybe<UniqueAdScheduleFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
  waveCastAdInstances?: InputMaybe<ListFilterInputTypeOfWaveCastAdInstanceFilterInput>;
};

export type AdInstanceImport = {
  __typename?: 'AdInstanceImport';
  adInstanceImportFileImports?: Maybe<Array<Maybe<AdInstanceImportFileImport>>>;
  adInstanceImportId: Scalars['Int'];
  adInstanceImportTargetDemos?: Maybe<Array<Maybe<AdInstanceImportTargetDemo>>>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  commissionRate?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createdUtc: Scalars['DateTime'];
  dateDefinitionLookup?: Maybe<Lookup>;
  dateDefinitionLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  rateTypeLookup?: Maybe<Lookup>;
  rateTypeLookupId: Scalars['Int'];
  ratingImportModeLookup?: Maybe<Lookup>;
  ratingImportModeLookupId: Scalars['Int'];
  timeZoneInfo?: Maybe<TimeZoneInfo>;
  timeZoneInfoId?: Maybe<Scalars['Int']>;
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeId?: Maybe<Scalars['Int']>;
};

export type AdInstanceImportFileImport = {
  __typename?: 'AdInstanceImportFileImport';
  adInstanceImport?: Maybe<AdInstanceImport>;
  adInstanceImportFileImportId: Scalars['Int'];
  adInstanceImportId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  duplicatedRowsRemovedCount?: Maybe<Scalars['Int']>;
  duplicatedTotalRateRemoved?: Maybe<Scalars['Decimal']>;
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type AdInstanceImportFileImportFilterInput = {
  adInstanceImport?: InputMaybe<AdInstanceImportFilterInput>;
  adInstanceImportFileImportId?: InputMaybe<IntOperationFilterInput>;
  adInstanceImportId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AdInstanceImportFileImportFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  duplicatedRowsRemovedCount?: InputMaybe<IntOperationFilterInput>;
  duplicatedTotalRateRemoved?: InputMaybe<DecimalOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AdInstanceImportFileImportFilterInput>>;
};

export type AdInstanceImportFilterInput = {
  adInstanceImportFileImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFileImportFilterInput>;
  adInstanceImportId?: InputMaybe<IntOperationFilterInput>;
  adInstanceImportTargetDemos?: InputMaybe<ListFilterInputTypeOfAdInstanceImportTargetDemoFilterInput>;
  and?: InputMaybe<Array<AdInstanceImportFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  commissionRate?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createdUtc?: InputMaybe<DateTimeOperationFilterInput>;
  dateDefinitionLookup?: InputMaybe<LookupFilterInput>;
  dateDefinitionLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AdInstanceImportFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  rateTypeLookup?: InputMaybe<LookupFilterInput>;
  rateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  ratingImportModeLookup?: InputMaybe<LookupFilterInput>;
  ratingImportModeLookupId?: InputMaybe<IntOperationFilterInput>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoFilterInput>;
  timeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
};

export type AdInstanceImportTargetDemo = {
  __typename?: 'AdInstanceImportTargetDemo';
  adInstanceImport?: Maybe<AdInstanceImport>;
  adInstanceImportId: Scalars['Int'];
  adInstanceImportTargetDemoId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId: Scalars['Int'];
  priorityOrder: Scalars['Int'];
};

export type AdInstanceImportTargetDemoFilterInput = {
  adInstanceImport?: InputMaybe<AdInstanceImportFilterInput>;
  adInstanceImportId?: InputMaybe<IntOperationFilterInput>;
  adInstanceImportTargetDemoId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AdInstanceImportTargetDemoFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AdInstanceImportTargetDemoFilterInput>>;
  priorityOrder?: InputMaybe<IntOperationFilterInput>;
};

export type AdSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  adEndDate?: InputMaybe<SortEnumType>;
  adId?: InputMaybe<SortEnumType>;
  agencyLookup?: InputMaybe<LookupSortInput>;
  agencyLookupId?: InputMaybe<SortEnumType>;
  axtypeLookup?: InputMaybe<LookupSortInput>;
  axtypeLookupId?: InputMaybe<SortEnumType>;
  brokerVendor?: InputMaybe<VendorSortInput>;
  brokerVendorId?: InputMaybe<SortEnumType>;
  campaignStatusLookup?: InputMaybe<LookupSortInput>;
  campaignStatusLookupId?: InputMaybe<SortEnumType>;
  comments?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  creative?: InputMaybe<CreativeSortInput>;
  creativeAgencyLookup?: InputMaybe<LookupSortInput>;
  creativeAgencyLookupId?: InputMaybe<SortEnumType>;
  creativeId?: InputMaybe<SortEnumType>;
  currentUniqueIsciincrement?: InputMaybe<SortEnumType>;
  dubHouseContact?: InputMaybe<ContactSortInput>;
  dubHouseContactId?: InputMaybe<SortEnumType>;
  dubHouseMasterNumber?: InputMaybe<SortEnumType>;
  dubHouseVendor?: InputMaybe<VendorSortInput>;
  dubHouseVendorId?: InputMaybe<SortEnumType>;
  excludeFromDailyCallProjections?: InputMaybe<SortEnumType>;
  fileReference?: InputMaybe<FileReferenceSortInput>;
  fileReferenceId?: InputMaybe<SortEnumType>;
  hddub?: InputMaybe<SortEnumType>;
  hdisci?: InputMaybe<SortEnumType>;
  highDefDubHouseMasterNumber?: InputMaybe<SortEnumType>;
  isCenterCutSafe?: InputMaybe<SortEnumType>;
  isClosedCaptionRequired?: InputMaybe<SortEnumType>;
  isCrossCompany?: InputMaybe<SortEnumType>;
  isHdmaster?: InputMaybe<SortEnumType>;
  isLiveRead?: InputMaybe<SortEnumType>;
  isPlaceHolder?: InputMaybe<SortEnumType>;
  isSystem?: InputMaybe<SortEnumType>;
  isVanitySpot?: InputMaybe<SortEnumType>;
  isci?: InputMaybe<SortEnumType>;
  languageLookup?: InputMaybe<LookupSortInput>;
  languageLookupId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mailerFormatLookup?: InputMaybe<LookupSortInput>;
  mailerFormatLookupId?: InputMaybe<SortEnumType>;
  mediaFileReference?: InputMaybe<FileReferenceSortInput>;
  mediaFileReferenceId?: InputMaybe<SortEnumType>;
  mediaType?: InputMaybe<MediaTypeSortInput>;
  mediaTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nativeDuplication?: InputMaybe<SortEnumType>;
  offer?: InputMaybe<SortEnumType>;
  offerTypeLookup?: InputMaybe<LookupSortInput>;
  offerTypeLookupId?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  region?: InputMaybe<RegionSortInput>;
  regionId?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  substantiationDeckLink?: InputMaybe<SortEnumType>;
  suppressStandardDefinitionDuplication?: InputMaybe<SortEnumType>;
  tapeLengthLookup?: InputMaybe<LookupSortInput>;
  tapeLengthLookupId?: InputMaybe<SortEnumType>;
  teleCode?: InputMaybe<SortEnumType>;
  title?: InputMaybe<TitleSortInput>;
  titleId?: InputMaybe<SortEnumType>;
  trackingCodesNotYetAvailable?: InputMaybe<SortEnumType>;
  vanityTfn?: InputMaybe<SortEnumType>;
  veilEncodingIdentifier?: InputMaybe<SortEnumType>;
  veilEncodingLookup?: InputMaybe<LookupSortInput>;
  veilEncodingLookupId?: InputMaybe<SortEnumType>;
  version?: InputMaybe<SortEnumType>;
  viewLinkUrl?: InputMaybe<SortEnumType>;
  webSite?: InputMaybe<SortEnumType>;
};

export type AdUniqueIsci = {
  __typename?: 'AdUniqueIsci';
  adId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  isci?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type AdUniqueIsciFilterInput = {
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AdUniqueIsciFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isci?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AdUniqueIsciFilterInput>>;
};

export type AddClientRoleError = ArgumentError | RainError;

export type AddClientRoleFunctionalRoleError = ArgumentError | RainError;

export type AddClientRoleFunctionalRoleInput = {
  clientRoleId: Scalars['Int'];
  functionalRole: FunctionalRole;
};

export type AddClientRoleFunctionalRolePayload = {
  __typename?: 'AddClientRoleFunctionalRolePayload';
  clientRoleFunctionalRole?: Maybe<ClientRoleFunctionalRole>;
  errors?: Maybe<Array<AddClientRoleFunctionalRoleError>>;
};

export type AddClientRoleInput = {
  companyCode: Scalars['String'];
  departmentGroupId: Scalars['Int'];
  name: Scalars['String'];
};

export type AddClientRolePayload = {
  __typename?: 'AddClientRolePayload';
  clientRole_ForDisplay?: Maybe<ClientRole_ForDisplay>;
  errors?: Maybe<Array<AddClientRoleError>>;
};

export type AddClientRolePermissionError = ArgumentError | RainError;

export type AddClientRolePermissionInput = {
  clientRoleId: Scalars['Int'];
  rainOnePermission: RainOnePermission;
};

export type AddClientRolePermissionPayload = {
  __typename?: 'AddClientRolePermissionPayload';
  clientRolePermission?: Maybe<ClientRolePermission>;
  errors?: Maybe<Array<AddClientRolePermissionError>>;
};

export type AddDepartmentGroupError = ArgumentError | RainError;

export type AddDepartmentGroupFunctionalRoleError = ArgumentError | RainError;

export type AddDepartmentGroupFunctionalRoleInput = {
  departmentGroupId: Scalars['Int'];
  functionalRole: FunctionalRole;
};

export type AddDepartmentGroupFunctionalRolePayload = {
  __typename?: 'AddDepartmentGroupFunctionalRolePayload';
  departmentGroupFunctionalRole?: Maybe<DepartmentGroupFunctionalRole>;
  errors?: Maybe<Array<AddDepartmentGroupFunctionalRoleError>>;
};

export type AddDepartmentGroupInput = {
  companyCode: Scalars['String'];
  departmentGroupName: Scalars['String'];
  departmentGroupType: DepartmentGroupType;
};

export type AddDepartmentGroupPayload = {
  __typename?: 'AddDepartmentGroupPayload';
  departmentGroup_ForDisplay?: Maybe<DepartmentGroup_ForDisplay>;
  errors?: Maybe<Array<AddDepartmentGroupError>>;
};

export type AddDepartmentGroupPermissionError = ArgumentError | RainError;

export type AddDepartmentGroupPermissionInput = {
  departmentGroupId: Scalars['Int'];
  rainOnePermission: RainOnePermission;
};

export type AddDepartmentGroupPermissionPayload = {
  __typename?: 'AddDepartmentGroupPermissionPayload';
  departmentGroupPermission?: Maybe<DepartmentGroupPermission>;
  errors?: Maybe<Array<AddDepartmentGroupPermissionError>>;
};

export type AddDepartmentToDepartmentGroupError = ArgumentError | RainError;

export type AddDepartmentToDepartmentGroupInput = {
  departmentGroupId: Scalars['Int'];
  departmentId: Scalars['Int'];
};

export type AddDepartmentToDepartmentGroupPayload = {
  __typename?: 'AddDepartmentToDepartmentGroupPayload';
  department_DepartmentGroup_ForDisplay?: Maybe<Department_DepartmentGroup_ForDisplay>;
  errors?: Maybe<Array<AddDepartmentToDepartmentGroupError>>;
};

export type AddFunctionalRoleToPersonError = ArgumentError | RainError;

export type AddFunctionalRoleToPersonInput = {
  functionalRoleId: Scalars['Int'];
  rainOnePersonId: Scalars['Int'];
};

export type AddFunctionalRoleToPersonPayload = {
  __typename?: 'AddFunctionalRoleToPersonPayload';
  errors?: Maybe<Array<AddFunctionalRoleToPersonError>>;
  personFunctionalRole_ForDisplay?: Maybe<PersonFunctionalRole_ForDisplay>;
};

export type AddOrUpdateCategoryTermGroupTypeMappingError = ArgumentError | RainError;

export type AddOrUpdateCategoryTermGroupTypeMappingInput = {
  categoryCode: Scalars['String'];
  clientTransactionTermGroupType: ClientTransactionTermGroupType;
};

export type AddOrUpdateCategoryTermGroupTypeMappingPayload = {
  __typename?: 'AddOrUpdateCategoryTermGroupTypeMappingPayload';
  categoryTermGroupTypeMapping_ForDisplay?: Maybe<CategoryTermGroupTypeMapping_ForDisplay>;
  errors?: Maybe<Array<AddOrUpdateCategoryTermGroupTypeMappingError>>;
};

export type AddOrUpdateNoteError = ArgumentError | RainError;

export type AddOrUpdateNoteInput = {
  key: Scalars['String'];
  noteId: Scalars['Int'];
  noteTxt: Scalars['String'];
  noteType: NoteType;
  parentKey?: InputMaybe<Scalars['String']>;
};

export type AddOrUpdateNotePayload = {
  __typename?: 'AddOrUpdateNotePayload';
  errors?: Maybe<Array<AddOrUpdateNoteError>>;
  note_ForDisplay?: Maybe<Note_ForDisplay>;
};

export type AddPerson_ClientError = ArgumentError | RainError;

export type AddPerson_ClientInput = {
  brandcode?: InputMaybe<Scalars['String']>;
  clientCode: Scalars['String'];
  clientRoleId: Scalars['Int'];
  rainOnePersonId: Scalars['Int'];
};

export type AddPerson_ClientPayload = {
  __typename?: 'AddPerson_ClientPayload';
  errors?: Maybe<Array<AddPerson_ClientError>>;
  person_Client_ForDisplay?: Maybe<Person_Client_ForDisplay>;
};

export type AddProjectEstimateDepartmentError = ArgumentError | RainError;

export type AddProjectEstimateDepartmentInput = {
  departmentId: Scalars['Int'];
  projectEstimateVersionId: Scalars['Int'];
};

export type AddProjectEstimateDepartmentPayload = {
  __typename?: 'AddProjectEstimateDepartmentPayload';
  errors?: Maybe<Array<AddProjectEstimateDepartmentError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type AddProjectEstimateVersionFileError = ArgumentError | RainError;

export type AddProjectEstimateVersionFileInput = {
  fileLink: Scalars['String'];
  projectEstimateFileType: ProjectEstimateFileType;
  projectEstimateVersionId: Scalars['Int'];
};

export type AddProjectEstimateVersionFilePayload = {
  __typename?: 'AddProjectEstimateVersionFilePayload';
  errors?: Maybe<Array<AddProjectEstimateVersionFileError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type AddRoleDepartmentError = ArgumentError | RainError;

export type AddRoleDepartmentInput = {
  departmentId: Scalars['Int'];
  roleId: Scalars['Int'];
};

export type AddRoleDepartmentPayload = {
  __typename?: 'AddRoleDepartmentPayload';
  errors?: Maybe<Array<AddRoleDepartmentError>>;
  roleDepartment_ForDisplay?: Maybe<RoleDepartment_ForDisplay>;
};

export type AddTimeValidationProjCategoryOverrideError = ArgumentError | RainError;

export type AddTimeValidationProjCategoryOverrideInput = {
  categoryId: Scalars['String'];
  projDataAreaId: Scalars['String'];
  projid: Scalars['String'];
};

export type AddTimeValidationProjCategoryOverridePayload = {
  __typename?: 'AddTimeValidationProjCategoryOverridePayload';
  errors?: Maybe<Array<AddTimeValidationProjCategoryOverrideError>>;
  timesheetValidationProjCategoryOverride_ForDisplay?: Maybe<TimesheetValidationProjCategoryOverride_ForDisplay>;
};

export type AddTimesheetDelegateError = ArgumentError | RainError;

export type AddTimesheetDelegateInput = {
  delegateType: DelegateType;
  delegatedToPersonId: Scalars['Int'];
  forPersonId: Scalars['Int'];
};

export type AddTimesheetDelegatePayload = {
  __typename?: 'AddTimesheetDelegatePayload';
  errors?: Maybe<Array<AddTimesheetDelegateError>>;
  timesheetDelegate_ForDisplay?: Maybe<TimesheetDelegate_ForDisplay>;
};

export type AddTimesheetValidationAdditionalCompanyError = ArgumentError | RainError;

export type AddTimesheetValidationAdditionalCompanyInput = {
  dataAreaId: Scalars['String'];
  personId: Scalars['Int'];
};

export type AddTimesheetValidationAdditionalCompanyPayload = {
  __typename?: 'AddTimesheetValidationAdditionalCompanyPayload';
  errors?: Maybe<Array<AddTimesheetValidationAdditionalCompanyError>>;
  timesheetValidationAdditionalCompany_ForDisplay?: Maybe<TimesheetValidationAdditionalCompany_ForDisplay>;
};

export type AddTimesheetValidationAdditionalDepartmentGroupError = ArgumentError | RainError;

export type AddTimesheetValidationAdditionalDepartmentGroupInput = {
  departmentGroupId: Scalars['Int'];
  personId: Scalars['Int'];
};

export type AddTimesheetValidationAdditionalDepartmentGroupPayload = {
  __typename?: 'AddTimesheetValidationAdditionalDepartmentGroupPayload';
  errors?: Maybe<Array<AddTimesheetValidationAdditionalDepartmentGroupError>>;
  timesheetValidationAdditionalDepartmentGroup_ForDisplay?: Maybe<TimesheetValidationAdditionalDepartmentGroup_ForDisplay>;
};

export type AddTimesheetValidationPersonAdditionalCategoryError = ArgumentError | RainError;

export type AddTimesheetValidationPersonAdditionalCategoryInput = {
  categoryId: Scalars['String'];
  personId: Scalars['Int'];
};

export type AddTimesheetValidationPersonAdditionalCategoryPayload = {
  __typename?: 'AddTimesheetValidationPersonAdditionalCategoryPayload';
  errors?: Maybe<Array<AddTimesheetValidationPersonAdditionalCategoryError>>;
  timesheetValidationPersonAdditionalCategory_ForDisplay?: Maybe<TimesheetValidationPersonAdditionalCategory_ForDisplay>;
};

export type AddTimesheetValidationProjResourceOverrideError = ArgumentError | RainError;

export type AddTimesheetValidationProjResourceOverrideInput = {
  personId: Scalars['Int'];
  projDataAreaId: Scalars['String'];
  projid: Scalars['String'];
};

export type AddTimesheetValidationProjResourceOverridePayload = {
  __typename?: 'AddTimesheetValidationProjResourceOverridePayload';
  errors?: Maybe<Array<AddTimesheetValidationProjResourceOverrideError>>;
  timesheetValidationProjResourceOverride_ForDisplay?: Maybe<TimesheetValidationProjResourceOverride_ForDisplay>;
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressId: Scalars['Int'];
  appLocations?: Maybe<Array<Maybe<AppLocation>>>;
  appUsers?: Maybe<Array<Maybe<AppUser>>>;
  attention?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientMailingAddresses?: Maybe<Array<Maybe<Client>>>;
  clientShippingAddresses?: Maybe<Array<Maybe<Client>>>;
  comment?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  country?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletAddresses?: Maybe<Array<Maybe<MediaOutletAddress>>>;
  oldid?: Maybe<Scalars['Int']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  oldtableName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  vendors?: Maybe<Array<Maybe<Vendor>>>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AddressFilterInput>>;
  appLocations?: InputMaybe<ListFilterInputTypeOfAppLocationFilterInput>;
  appUsers?: InputMaybe<ListFilterInputTypeOfAppUserFilterInput>;
  attention?: InputMaybe<StringOperationFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  clientMailingAddresses?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  clientShippingAddresses?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  contacts?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletAddresses?: InputMaybe<ListFilterInputTypeOfMediaOutletAddressFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  state?: InputMaybe<StringOperationFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  vendors?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type AddressSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  addressId?: InputMaybe<SortEnumType>;
  attention?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  oldnaturalKey?: InputMaybe<SortEnumType>;
  oldtableName?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type Alias = {
  __typename?: 'Alias';
  aliasId: Scalars['Int'];
  aliasType?: Maybe<AliasType>;
  aliasTypeId: Scalars['Int'];
  appView?: Maybe<AppView>;
  appViewId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type AliasFilterInput = {
  aliasId?: InputMaybe<IntOperationFilterInput>;
  aliasType?: InputMaybe<AliasTypeFilterInput>;
  aliasTypeId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AliasFilterInput>>;
  appView?: InputMaybe<AppViewFilterInput>;
  appViewId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AliasFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type AliasSortInput = {
  aliasId?: InputMaybe<SortEnumType>;
  aliasType?: InputMaybe<AliasTypeSortInput>;
  aliasTypeId?: InputMaybe<SortEnumType>;
  appView?: InputMaybe<AppViewSortInput>;
  appViewId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type AliasType = {
  __typename?: 'AliasType';
  aliasTypeId: Scalars['Int'];
  aliases?: Maybe<Array<Maybe<Alias>>>;
  appView?: Maybe<AppView>;
  appViewId: Scalars['Int'];
  display?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type AliasTypeFilterInput = {
  aliasTypeId?: InputMaybe<IntOperationFilterInput>;
  aliases?: InputMaybe<ListFilterInputTypeOfAliasFilterInput>;
  and?: InputMaybe<Array<AliasTypeFilterInput>>;
  appView?: InputMaybe<AppViewFilterInput>;
  appViewId?: InputMaybe<IntOperationFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AliasTypeFilterInput>>;
};

export type AliasTypeSortInput = {
  aliasTypeId?: InputMaybe<SortEnumType>;
  appView?: InputMaybe<AppViewSortInput>;
  appViewId?: InputMaybe<SortEnumType>;
  display?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AppField = {
  __typename?: 'AppField';
  abbreviation?: Maybe<Scalars['String']>;
  advanced: Scalars['Boolean'];
  aggregateDefinition?: Maybe<Scalars['String']>;
  aggregateFormatLookup?: Maybe<Lookup>;
  aggregateFormatLookupId?: Maybe<Scalars['Int']>;
  aggregateTypeLookup?: Maybe<Lookup>;
  aggregateTypeLookupId: Scalars['Int'];
  appFieldId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  appViewFields?: Maybe<Array<Maybe<AppViewField>>>;
  associatedIdappField?: Maybe<AppField>;
  associatedIdappFieldId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dataTypeLookup?: Maybe<Lookup>;
  dataTypeLookupId: Scalars['Int'];
  defaultWidth: Scalars['Int'];
  definition?: Maybe<Scalars['String']>;
  definitionTypeLookup?: Maybe<Lookup>;
  definitionTypeLookupId: Scalars['Int'];
  displayName?: Maybe<Scalars['String']>;
  formatLookup?: Maybe<Lookup>;
  formatLookupId: Scalars['Int'];
  glanceReportElementBoxGroupAppFields?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementBoxGroupSortAppFields?: Maybe<Array<Maybe<GlanceReportElement>>>;
  inverseAssociatedIdappField?: Maybe<Array<Maybe<AppField>>>;
  label?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  orderByDefinition?: Maybe<Scalars['String']>;
  reportExportFormatFieldDenominatorAppFields?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldNumeratorAppFields?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatStyles?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportPresetGroupSorts?: Maybe<Array<Maybe<ReportPresetGroupSort>>>;
  tempTableConsolidateDefinition?: Maybe<Scalars['String']>;
  tempTableConsolidateTypeLookup?: Maybe<Lookup>;
  tempTableConsolidateTypeLookupId: Scalars['Int'];
  useDashboardSimpleSumming?: Maybe<Scalars['Boolean']>;
};

export type AppFieldFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  advanced?: InputMaybe<BooleanOperationFilterInput>;
  aggregateDefinition?: InputMaybe<StringOperationFilterInput>;
  aggregateFormatLookup?: InputMaybe<LookupFilterInput>;
  aggregateFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  aggregateTypeLookup?: InputMaybe<LookupFilterInput>;
  aggregateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AppFieldFilterInput>>;
  appFieldId?: InputMaybe<IntOperationFilterInput>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appViewFields?: InputMaybe<ListFilterInputTypeOfAppViewFieldFilterInput>;
  associatedIdappField?: InputMaybe<AppFieldFilterInput>;
  associatedIdappFieldId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dataTypeLookup?: InputMaybe<LookupFilterInput>;
  dataTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  defaultWidth?: InputMaybe<IntOperationFilterInput>;
  definition?: InputMaybe<StringOperationFilterInput>;
  definitionTypeLookup?: InputMaybe<LookupFilterInput>;
  definitionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  formatLookup?: InputMaybe<LookupFilterInput>;
  formatLookupId?: InputMaybe<IntOperationFilterInput>;
  glanceReportElementBoxGroupAppFields?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementBoxGroupSortAppFields?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  inverseAssociatedIdappField?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppFieldFilterInput>>;
  orderByDefinition?: InputMaybe<StringOperationFilterInput>;
  reportExportFormatFieldDenominatorAppFields?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldNumeratorAppFields?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatStyles?: InputMaybe<ListFilterInputTypeOfReportExportFormatStyleFilterInput>;
  reportPresetGroupSorts?: InputMaybe<ListFilterInputTypeOfReportPresetGroupSortFilterInput>;
  tempTableConsolidateDefinition?: InputMaybe<StringOperationFilterInput>;
  tempTableConsolidateTypeLookup?: InputMaybe<LookupFilterInput>;
  tempTableConsolidateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  useDashboardSimpleSumming?: InputMaybe<BooleanOperationFilterInput>;
};

export type AppFilter = {
  __typename?: 'AppFilter';
  appFilterId: Scalars['Int'];
  appFilterOperators?: Maybe<Array<Maybe<AppFilterOperator>>>;
  appViewFilterRecords?: Maybe<Array<Maybe<AppViewFilterRecord>>>;
  control?: Maybe<Scalars['String']>;
  includeInEditorTypeLookup?: Maybe<Lookup>;
  includeInEditorTypeLookupId: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reportPresetFilterRecords?: Maybe<Array<Maybe<ReportPresetFilterRecord>>>;
  updateOnNewSiblingValue: Scalars['Boolean'];
};

export type AppFilterFilterInput = {
  and?: InputMaybe<Array<AppFilterFilterInput>>;
  appFilterId?: InputMaybe<IntOperationFilterInput>;
  appFilterOperators?: InputMaybe<ListFilterInputTypeOfAppFilterOperatorFilterInput>;
  appViewFilterRecords?: InputMaybe<ListFilterInputTypeOfAppViewFilterRecordFilterInput>;
  control?: InputMaybe<StringOperationFilterInput>;
  includeInEditorTypeLookup?: InputMaybe<LookupFilterInput>;
  includeInEditorTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mode?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppFilterFilterInput>>;
  reportPresetFilterRecords?: InputMaybe<ListFilterInputTypeOfReportPresetFilterRecordFilterInput>;
  updateOnNewSiblingValue?: InputMaybe<BooleanOperationFilterInput>;
};

export type AppFilterOperator = {
  __typename?: 'AppFilterOperator';
  appFilter?: Maybe<AppFilter>;
  appFilterId: Scalars['Int'];
  appFilterOperatorId: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  operatorTypeLookup?: Maybe<Lookup>;
  operatorTypeLookupId: Scalars['Int'];
  whereClauseSql?: Maybe<Scalars['String']>;
};

export type AppFilterOperatorFilterInput = {
  and?: InputMaybe<Array<AppFilterOperatorFilterInput>>;
  appFilter?: InputMaybe<AppFilterFilterInput>;
  appFilterId?: InputMaybe<IntOperationFilterInput>;
  appFilterOperatorId?: InputMaybe<IntOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  operatorTypeLookup?: InputMaybe<LookupFilterInput>;
  operatorTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AppFilterOperatorFilterInput>>;
  whereClauseSql?: InputMaybe<StringOperationFilterInput>;
};

export type AppGrid = {
  __typename?: 'AppGrid';
  appGridColumnUserSettings?: Maybe<Array<Maybe<AppGridColumnUserSetting>>>;
  appGridId: Scalars['Int'];
  fullyQualifiedControlName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type AppGridColumn = {
  __typename?: 'AppGridColumn';
  appGridColumnId: Scalars['Int'];
  appGridColumnUserSettings?: Maybe<Array<Maybe<AppGridColumnUserSetting>>>;
  columnName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type AppGridColumnFilterInput = {
  and?: InputMaybe<Array<AppGridColumnFilterInput>>;
  appGridColumnId?: InputMaybe<IntOperationFilterInput>;
  appGridColumnUserSettings?: InputMaybe<ListFilterInputTypeOfAppGridColumnUserSettingFilterInput>;
  columnName?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppGridColumnFilterInput>>;
};

export type AppGridColumnUserSetting = {
  __typename?: 'AppGridColumnUserSetting';
  appGrid?: Maybe<AppGrid>;
  appGridColumn?: Maybe<AppGridColumn>;
  appGridColumnId: Scalars['Int'];
  appGridColumnUserSettingId: Scalars['Int'];
  appGridId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  displayIndex: Scalars['Int'];
  isVisible?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  reportExportFormat?: Maybe<ReportExportFormat>;
  reportExportFormatId?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['Int']>;
  sortIndex?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type AppGridColumnUserSettingFilterInput = {
  and?: InputMaybe<Array<AppGridColumnUserSettingFilterInput>>;
  appGrid?: InputMaybe<AppGridFilterInput>;
  appGridColumn?: InputMaybe<AppGridColumnFilterInput>;
  appGridColumnId?: InputMaybe<IntOperationFilterInput>;
  appGridColumnUserSettingId?: InputMaybe<IntOperationFilterInput>;
  appGridId?: InputMaybe<IntOperationFilterInput>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  displayIndex?: InputMaybe<IntOperationFilterInput>;
  isVisible?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppGridColumnUserSettingFilterInput>>;
  reportExportFormat?: InputMaybe<ReportExportFormatFilterInput>;
  reportExportFormatId?: InputMaybe<IntOperationFilterInput>;
  sortDirection?: InputMaybe<IntOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  width?: InputMaybe<IntOperationFilterInput>;
};

export type AppGridFilterInput = {
  and?: InputMaybe<Array<AppGridFilterInput>>;
  appGridColumnUserSettings?: InputMaybe<ListFilterInputTypeOfAppGridColumnUserSettingFilterInput>;
  appGridId?: InputMaybe<IntOperationFilterInput>;
  fullyQualifiedControlName?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppGridFilterInput>>;
};

export type AppLocation = {
  __typename?: 'AppLocation';
  accountingLines?: Maybe<Array<Maybe<AccountingLine>>>;
  address?: Maybe<Address>;
  addressId: Scalars['Int'];
  appLocationId: Scalars['Int'];
  appUsers?: Maybe<Array<Maybe<AppUser>>>;
  clientAccountingAppLocations?: Maybe<Array<Maybe<Client>>>;
  clientAppLocations?: Maybe<Array<Maybe<Client>>>;
  clientTrafficAppLocations?: Maybe<Array<Maybe<Client>>>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mainFax?: Maybe<Scalars['String']>;
  mainPhone?: Maybe<Scalars['String']>;
  oldappLocationId: Scalars['Int'];
  phonePrefix?: Maybe<Scalars['String']>;
  timeZoneInfo?: Maybe<TimeZoneInfo>;
  timeZoneInfoId: Scalars['Int'];
};

export type AppLocationFilterInput = {
  accountingLines?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AppLocationFilterInput>>;
  appLocationId?: InputMaybe<IntOperationFilterInput>;
  appUsers?: InputMaybe<ListFilterInputTypeOfAppUserFilterInput>;
  clientAccountingAppLocations?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  clientAppLocations?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  clientTrafficAppLocations?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mainFax?: InputMaybe<StringOperationFilterInput>;
  mainPhone?: InputMaybe<StringOperationFilterInput>;
  oldappLocationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AppLocationFilterInput>>;
  phonePrefix?: InputMaybe<StringOperationFilterInput>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoFilterInput>;
  timeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
};

export type AppLocationSortInput = {
  address?: InputMaybe<AddressSortInput>;
  addressId?: InputMaybe<SortEnumType>;
  appLocationId?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mainFax?: InputMaybe<SortEnumType>;
  mainPhone?: InputMaybe<SortEnumType>;
  oldappLocationId?: InputMaybe<SortEnumType>;
  phonePrefix?: InputMaybe<SortEnumType>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoSortInput>;
  timeZoneInfoId?: InputMaybe<SortEnumType>;
};

export type AppLogEntry = {
  __typename?: 'AppLogEntry';
  appLogEntryDetails?: Maybe<Array<Maybe<AppLogEntryDetail>>>;
  appLogEntryId: Scalars['Int'];
  appLogEntryTypeLookup?: Maybe<Lookup>;
  appLogEntryTypeLookupId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  appVersion?: Maybe<Scalars['String']>;
  completedOnUtc?: Maybe<Scalars['DateTime']>;
  extendedInfo?: Maybe<Scalars['String']>;
  isIncomplete?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  loggedOnUtc: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  screenName?: Maybe<Scalars['String']>;
  userControl?: Maybe<Scalars['String']>;
};

export type AppLogEntryDetail = {
  __typename?: 'AppLogEntryDetail';
  appLogEntry?: Maybe<AppLogEntry>;
  appLogEntryDetailId: Scalars['Int'];
  appLogEntryId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  loggedOnUtc: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  stepName?: Maybe<Scalars['String']>;
};

export type AppLogEntryDetailFilterInput = {
  and?: InputMaybe<Array<AppLogEntryDetailFilterInput>>;
  appLogEntry?: InputMaybe<AppLogEntryFilterInput>;
  appLogEntryDetailId?: InputMaybe<IntOperationFilterInput>;
  appLogEntryId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  loggedOnUtc?: InputMaybe<DateTimeOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppLogEntryDetailFilterInput>>;
  stepName?: InputMaybe<StringOperationFilterInput>;
};

export type AppLogEntryFilterInput = {
  and?: InputMaybe<Array<AppLogEntryFilterInput>>;
  appLogEntryDetails?: InputMaybe<ListFilterInputTypeOfAppLogEntryDetailFilterInput>;
  appLogEntryId?: InputMaybe<IntOperationFilterInput>;
  appLogEntryTypeLookup?: InputMaybe<LookupFilterInput>;
  appLogEntryTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appVersion?: InputMaybe<StringOperationFilterInput>;
  completedOnUtc?: InputMaybe<DateTimeOperationFilterInput>;
  extendedInfo?: InputMaybe<StringOperationFilterInput>;
  isIncomplete?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  loggedOnUtc?: InputMaybe<DateTimeOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppLogEntryFilterInput>>;
  screenName?: InputMaybe<StringOperationFilterInput>;
  userControl?: InputMaybe<StringOperationFilterInput>;
};

export type AppOffline = {
  __typename?: 'AppOffline';
  allowedAdminEmails: Array<Scalars['String']>;
  areAnyAppsOffline: Scalars['Boolean'];
  onlineApps: Array<RainOneApp>;
};

export type AppQueryLogEntry = {
  __typename?: 'AppQueryLogEntry';
  appQueryLogEntryId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  contextName?: Maybe<Scalars['String']>;
  endUtc: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  numberOfRecords: Scalars['Int'];
  queryMethodName?: Maybe<Scalars['String']>;
  startUtc: Scalars['DateTime'];
};

export type AppQueryLogEntryFilterInput = {
  and?: InputMaybe<Array<AppQueryLogEntryFilterInput>>;
  appQueryLogEntryId?: InputMaybe<IntOperationFilterInput>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  contextName?: InputMaybe<StringOperationFilterInput>;
  endUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  numberOfRecords?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AppQueryLogEntryFilterInput>>;
  queryMethodName?: InputMaybe<StringOperationFilterInput>;
  startUtc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type AppRibbonButton = {
  __typename?: 'AppRibbonButton';
  appRibbonButtonId: Scalars['Int'];
  appRibbonGroup?: Maybe<AppRibbonGroup>;
  appRibbonGroupId: Scalars['Int'];
  appScreen?: Maybe<AppScreen>;
  appScreenId: Scalars['Int'];
  commandName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  ribbonButtonIconImage?: Maybe<Scalars['String']>;
  ribbonButtonText?: Maybe<Scalars['String']>;
  ribbonShortcutText?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
};

export type AppRibbonButtonFilterInput = {
  and?: InputMaybe<Array<AppRibbonButtonFilterInput>>;
  appRibbonButtonId?: InputMaybe<IntOperationFilterInput>;
  appRibbonGroup?: InputMaybe<AppRibbonGroupFilterInput>;
  appRibbonGroupId?: InputMaybe<IntOperationFilterInput>;
  appScreen?: InputMaybe<AppScreenFilterInput>;
  appScreenId?: InputMaybe<IntOperationFilterInput>;
  commandName?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppRibbonButtonFilterInput>>;
  ribbonButtonIconImage?: InputMaybe<StringOperationFilterInput>;
  ribbonButtonText?: InputMaybe<StringOperationFilterInput>;
  ribbonShortcutText?: InputMaybe<StringOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type AppRibbonGroup = {
  __typename?: 'AppRibbonGroup';
  appRibbonButtons?: Maybe<Array<Maybe<AppRibbonButton>>>;
  appRibbonGroupId: Scalars['Int'];
  appRibbonTab?: Maybe<AppRibbonTab>;
  appRibbonTabId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  sort: Scalars['Int'];
};

export type AppRibbonGroupFilterInput = {
  and?: InputMaybe<Array<AppRibbonGroupFilterInput>>;
  appRibbonButtons?: InputMaybe<ListFilterInputTypeOfAppRibbonButtonFilterInput>;
  appRibbonGroupId?: InputMaybe<IntOperationFilterInput>;
  appRibbonTab?: InputMaybe<AppRibbonTabFilterInput>;
  appRibbonTabId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  header?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppRibbonGroupFilterInput>>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type AppRibbonTab = {
  __typename?: 'AppRibbonTab';
  appRibbonGroups?: Maybe<Array<Maybe<AppRibbonGroup>>>;
  appRibbonTabId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['String']>;
  isAdminOnly: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  sort: Scalars['Int'];
};

export type AppRibbonTabFilterInput = {
  and?: InputMaybe<Array<AppRibbonTabFilterInput>>;
  appRibbonGroups?: InputMaybe<ListFilterInputTypeOfAppRibbonGroupFilterInput>;
  appRibbonTabId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  header?: InputMaybe<StringOperationFilterInput>;
  isAdminOnly?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppRibbonTabFilterInput>>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type AppScreen = {
  __typename?: 'AppScreen';
  allowScroll: Scalars['Boolean'];
  appRibbonButtons?: Maybe<Array<Maybe<AppRibbonButton>>>;
  appScreenId: Scalars['Int'];
  appUserRibbonShortcuts?: Maybe<Array<Maybe<AppUserRibbonShortcut>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  formDeleteMethodName?: Maybe<Scalars['String']>;
  formGetMethodName?: Maybe<Scalars['String']>;
  formSaveMethodName?: Maybe<Scalars['String']>;
  initialPositionLookup?: Maybe<Lookup>;
  initialPositionLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  ribbonButtonIconImage?: Maybe<Scalars['String']>;
  ribbonButtonText?: Maybe<Scalars['String']>;
  ribbonShortcutText?: Maybe<Scalars['String']>;
  screenName?: Maybe<Scalars['String']>;
  tabTypeLookup?: Maybe<Lookup>;
  tabTypeLookupId: Scalars['Int'];
  userControl?: Maybe<Scalars['String']>;
  wikiUrl?: Maybe<Scalars['String']>;
};

export type AppScreenFilterInput = {
  allowScroll?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<AppScreenFilterInput>>;
  appRibbonButtons?: InputMaybe<ListFilterInputTypeOfAppRibbonButtonFilterInput>;
  appScreenId?: InputMaybe<IntOperationFilterInput>;
  appUserRibbonShortcuts?: InputMaybe<ListFilterInputTypeOfAppUserRibbonShortcutFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  formDeleteMethodName?: InputMaybe<StringOperationFilterInput>;
  formGetMethodName?: InputMaybe<StringOperationFilterInput>;
  formSaveMethodName?: InputMaybe<StringOperationFilterInput>;
  initialPositionLookup?: InputMaybe<LookupFilterInput>;
  initialPositionLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppScreenFilterInput>>;
  ribbonButtonIconImage?: InputMaybe<StringOperationFilterInput>;
  ribbonButtonText?: InputMaybe<StringOperationFilterInput>;
  ribbonShortcutText?: InputMaybe<StringOperationFilterInput>;
  screenName?: InputMaybe<StringOperationFilterInput>;
  tabTypeLookup?: InputMaybe<LookupFilterInput>;
  tabTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  userControl?: InputMaybe<StringOperationFilterInput>;
  wikiUrl?: InputMaybe<StringOperationFilterInput>;
};

export type AppUser = {
  __typename?: 'AppUser';
  accountingBatches?: Maybe<Array<Maybe<AccountingBatch>>>;
  accountingLocks?: Maybe<Array<Maybe<AccountingLock>>>;
  active: Scalars['Boolean'];
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstances?: Maybe<Array<Maybe<AdInstance>>>;
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']>;
  alertsLastPullUtc?: Maybe<Scalars['DateTime']>;
  appFields?: Maybe<Array<Maybe<AppField>>>;
  appGridColumnUserSettings?: Maybe<Array<Maybe<AppGridColumnUserSetting>>>;
  appLocation?: Maybe<AppLocation>;
  appLocationCode: Scalars['String'];
  appLocationID?: Maybe<Scalars['Int']>;
  appLocationId: Scalars['Int'];
  appLogEntries?: Maybe<Array<Maybe<AppLogEntry>>>;
  appQueryLogEntries?: Maybe<Array<Maybe<AppQueryLogEntry>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserCompanies?: Maybe<Array<Maybe<AppUserCompany>>>;
  appUserFavoriteReportPresets?: Maybe<Array<Maybe<AppUserFavoriteReportPreset>>>;
  appUserID: Scalars['Int'];
  appUserId: Scalars['Int'];
  appUserReportPresets?: Maybe<Array<Maybe<AppUserReportPreset>>>;
  appUserRibbonShortcuts?: Maybe<Array<Maybe<AppUserRibbonShortcut>>>;
  appUserRoles?: Maybe<Array<Maybe<AppUserRole>>>;
  appUserSettings?: Maybe<Array<Maybe<AppUserSetting>>>;
  autoReports?: Maybe<Array<Maybe<AutoReport>>>;
  autoScheduleBuyerAppUsers?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleCreateAppUsers?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoSources?: Maybe<Array<Maybe<AutoSource>>>;
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  availProposalDetails?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  availProposals?: Maybe<Array<Maybe<AvailProposal>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  budgetBuyerSplits?: Maybe<Array<Maybe<BudgetBuyerSplit>>>;
  buyerActive: Scalars['Boolean'];
  buyerAssistantAppUser?: Maybe<AppUser>;
  buyerAssistantAppUserId?: Maybe<Scalars['Int']>;
  campaignLocalBudgetAssignments?: Maybe<Array<Maybe<CampaignLocalBudgetAssignment>>>;
  cellPhoneNumber?: Maybe<Scalars['String']>;
  clientMediaOutlets?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  crossChannelCampaignVersions?: Maybe<Array<Maybe<CrossChannelCampaignVersion>>>;
  crossChannelCampaigns?: Maybe<Array<Maybe<CrossChannelCampaign>>>;
  defaultCompanyID?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  dmainfoBuyers?: Maybe<Array<Maybe<DmainfoBuyer>>>;
  dubOrderTransmissions?: Maybe<Array<Maybe<DubOrderTransmission>>>;
  duplicationCostEstimates?: Maybe<Array<Maybe<DuplicationCostEstimate>>>;
  efax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
  files?: Maybe<Array<Maybe<File>>>;
  firstName?: Maybe<Scalars['String']>;
  fullLogin?: Maybe<Scalars['String']>;
  gareports?: Maybe<Array<Maybe<Gareport>>>;
  glanceReportElements?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReports?: Maybe<Array<Maybe<GlanceReport>>>;
  initials?: Maybe<Scalars['String']>;
  insertionOrders?: Maybe<Array<Maybe<InsertionOrder>>>;
  inverseBuyerAssistantAppUser?: Maybe<Array<Maybe<AppUser>>>;
  invoiceAppUsers?: Maybe<Array<Maybe<Invoice>>>;
  invoiceApprovedByAppUsers?: Maybe<Array<Maybe<Invoice>>>;
  invoiceAssignedApproverAppUsers?: Maybe<Array<Maybe<Invoice>>>;
  isInRole: Scalars['Boolean'];
  jobAppUsers?: Maybe<Array<Maybe<JobAppUser>>>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingLockBilledByAppUsers?: Maybe<Array<Maybe<LeadBillingLock>>>;
  leadBillingLockCreateAppUsers?: Maybe<Array<Maybe<LeadBillingLock>>>;
  login?: Maybe<Scalars['String']>;
  mediaAuthorizationClientServicesApproverAppUsers?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaAuthorizationCreateAppUsers?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaAuthorizationFinanceApproverAppUsers?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutletRoles?: Maybe<Array<Maybe<MediaOutletRole>>>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  mediaScheduleBookedByAppUsers?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaScheduleCreatedByAppUsers?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTransmissionNotifications?: Maybe<Array<Maybe<MediaTransmissionNotification>>>;
  mediaTransmissions?: Maybe<Array<Maybe<MediaTransmission>>>;
  newCampaignAnnouncementCreateAppUsers?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementDuplicationMasterSetupSenderAppUsers?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementPiAppUsers?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncementPiBuyerAppUsers?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncementPiLfassistantAppUsers?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncementPiSfassistantAppUsers?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncementSenderAppUsers?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  oldappUserId?: Maybe<Scalars['Int']>;
  oldbuyerAssistantAppUserId?: Maybe<Scalars['Int']>;
  oldbuyerId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<Package>>>;
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  phone?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['Boolean']>;
  prelogEstimates?: Maybe<Array<Maybe<PrelogEstimate>>>;
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  pwUserID?: Maybe<Scalars['Int']>;
  ratings?: Maybe<Array<Maybe<Rating>>>;
  reportExportFormatFields?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormats?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportPresets?: Maybe<Array<Maybe<ReportPreset>>>;
  resultDetails?: Maybe<Array<Maybe<ResultDetail>>>;
  resultManualEntryPresets?: Maybe<Array<Maybe<ResultManualEntryPreset>>>;
  revisionGroups?: Maybe<Array<Maybe<RevisionGroup>>>;
  scheduleCostAdjustments?: Maybe<Array<Maybe<ScheduleCostAdjustment>>>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  snapshots?: Maybe<Array<Maybe<Snapshot>>>;
  sqllogin?: Maybe<Scalars['String']>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
  taskAssignedAppUsers?: Maybe<Array<Maybe<Task>>>;
  taskCreateAppUsers?: Maybe<Array<Maybe<Task>>>;
  taskHistories?: Maybe<Array<Maybe<TaskHistory>>>;
  titles?: Maybe<Array<Maybe<Title>>>;
  tmpInvoices?: Maybe<Array<Maybe<TmpInvoice>>>;
  topLineMediaPlans?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  trackingCodeAvailCreateAppUsers?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodeAvailCustomizeAppUsers?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodeAvailSenderAppUsers?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodeAvailTestAppUsers?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodeImports?: Maybe<Array<Maybe<TrackingCodeImport>>>;
  trafficSheets?: Maybe<Array<Maybe<TrafficSheet>>>;
  uniqueAdAssignerAppUsers?: Maybe<Array<Maybe<UniqueAd>>>;
  uniqueAdDeactivateAppUsers?: Maybe<Array<Maybe<UniqueAd>>>;
  uniqueAdRequestorAppUsers?: Maybe<Array<Maybe<UniqueAd>>>;
  uniqueAdTestAppUsers?: Maybe<Array<Maybe<UniqueAd>>>;
  waveCastModels?: Maybe<Array<Maybe<WaveCastModel>>>;
  waveCastWaves?: Maybe<Array<Maybe<WaveCastWave>>>;
};


export type AppUserIsInRoleArgs = {
  appUserRole: AppUserRoleEnum;
};

export type AppUserClient = {
  __typename?: 'AppUserClient';
  appUser?: Maybe<AppUser>;
  appUserClientId: Scalars['Int'];
  appUserId: Scalars['Int'];
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  clientRoleLookup?: Maybe<Lookup>;
  clientRoleLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
};

export type AppUserClientFilterInput = {
  and?: InputMaybe<Array<AppUserClientFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserClientId?: InputMaybe<IntOperationFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientRoleLookup?: InputMaybe<LookupFilterInput>;
  clientRoleLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AppUserClientFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
};

export type AppUserClientForLookup = {
  __typename?: 'AppUserClientForLookup';
  active: Scalars['Boolean'];
  anyPropertyValuesChanged: Scalars['Boolean'];
  appUserClientID: Scalars['Int'];
  appUserDescription?: Maybe<Scalars['String']>;
  appUserID: Scalars['Int'];
  clientID: Scalars['Int'];
  clientRole?: Maybe<ClientRoleEnum>;
  clientRoleLookupID: Scalars['Int'];
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  effectiveDate: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaOutletID?: Maybe<Scalars['Int']>;
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaTypeID?: Maybe<Scalars['Int']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  productID?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
};


export type AppUserClientForLookupPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type AppUserClientInput = {
  appUserActive: Scalars['Boolean'];
  appUserClientId?: InputMaybe<Scalars['Int']>;
  appUserDescription?: InputMaybe<Scalars['String']>;
  appUserId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  clientRoleLookupDisplay?: InputMaybe<Scalars['String']>;
  clientRoleLookupId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  mediaOutletName?: InputMaybe<Scalars['String']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
  mediaTypeName?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  productName?: InputMaybe<Scalars['String']>;
};

export type AppUserClient_ForMaintenance = {
  __typename?: 'AppUserClient_ForMaintenance';
  appUser?: Maybe<AppUser>;
  appUserActive: Scalars['Boolean'];
  appUserClientId: Scalars['Int'];
  appUserDescription?: Maybe<Scalars['String']>;
  appUserId: Scalars['Int'];
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  clientRoleLookup?: Maybe<Lookup>;
  clientRoleLookupDisplay?: Maybe<Scalars['String']>;
  clientRoleLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  mediaTypeName?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
};

export type AppUserClient_ForMaintenanceFilterInput = {
  and?: InputMaybe<Array<AppUserClient_ForMaintenanceFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserActive?: InputMaybe<BooleanOperationFilterInput>;
  appUserClientId?: InputMaybe<IntOperationFilterInput>;
  appUserDescription?: InputMaybe<StringOperationFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientRoleLookup?: InputMaybe<LookupFilterInput>;
  clientRoleLookupDisplay?: InputMaybe<StringOperationFilterInput>;
  clientRoleLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletName?: InputMaybe<StringOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaTypeName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppUserClient_ForMaintenanceFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
};

export type AppUserCompany = {
  __typename?: 'AppUserCompany';
  appUser?: Maybe<AppUser>;
  appUserCompanyId: Scalars['Int'];
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type AppUserCompanyFilterInput = {
  and?: InputMaybe<Array<AppUserCompanyFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserCompanyId?: InputMaybe<IntOperationFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppUserCompanyFilterInput>>;
};

export type AppUserFavoriteReportPreset = {
  __typename?: 'AppUserFavoriteReportPreset';
  appUser?: Maybe<AppUser>;
  appUserFavoriteReportPresetId: Scalars['Int'];
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  report?: Maybe<Report>;
  reportId: Scalars['Int'];
  reportPreset?: Maybe<ReportPreset>;
  reportPresetId: Scalars['Int'];
};

export type AppUserFavoriteReportPresetFilterInput = {
  and?: InputMaybe<Array<AppUserFavoriteReportPresetFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserFavoriteReportPresetId?: InputMaybe<IntOperationFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppUserFavoriteReportPresetFilterInput>>;
  report?: InputMaybe<ReportFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
};

export type AppUserFilterInput = {
  accountingBatches?: InputMaybe<ListFilterInputTypeOfAccountingBatchFilterInput>;
  accountingLocks?: InputMaybe<ListFilterInputTypeOfAccountingLockFilterInput>;
  active?: InputMaybe<BooleanOperationFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstances?: InputMaybe<ListFilterInputTypeOfAdInstanceFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  alertsLastPullUtc?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<AppUserFilterInput>>;
  appFields?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appGridColumnUserSettings?: InputMaybe<ListFilterInputTypeOfAppGridColumnUserSettingFilterInput>;
  appLocation?: InputMaybe<AppLocationFilterInput>;
  appLocationCode?: InputMaybe<StringOperationFilterInput>;
  appLocationID?: InputMaybe<IntOperationFilterInput>;
  appLocationId?: InputMaybe<IntOperationFilterInput>;
  appLogEntries?: InputMaybe<ListFilterInputTypeOfAppLogEntryFilterInput>;
  appQueryLogEntries?: InputMaybe<ListFilterInputTypeOfAppQueryLogEntryFilterInput>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  appUserCompanies?: InputMaybe<ListFilterInputTypeOfAppUserCompanyFilterInput>;
  appUserFavoriteReportPresets?: InputMaybe<ListFilterInputTypeOfAppUserFavoriteReportPresetFilterInput>;
  appUserID?: InputMaybe<IntOperationFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appUserReportPresets?: InputMaybe<ListFilterInputTypeOfAppUserReportPresetFilterInput>;
  appUserRibbonShortcuts?: InputMaybe<ListFilterInputTypeOfAppUserRibbonShortcutFilterInput>;
  appUserRoles?: InputMaybe<ListFilterInputTypeOfAppUserRoleFilterInput>;
  appUserSettings?: InputMaybe<ListFilterInputTypeOfAppUserSettingFilterInput>;
  autoReports?: InputMaybe<ListFilterInputTypeOfAutoReportFilterInput>;
  autoScheduleBuyerAppUsers?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleCreateAppUsers?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoSources?: InputMaybe<ListFilterInputTypeOfAutoSourceFilterInput>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  availProposalDetails?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  availProposals?: InputMaybe<ListFilterInputTypeOfAvailProposalFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  budgetBuyerSplits?: InputMaybe<ListFilterInputTypeOfBudgetBuyerSplitFilterInput>;
  buyerActive?: InputMaybe<BooleanOperationFilterInput>;
  buyerAssistantAppUser?: InputMaybe<AppUserFilterInput>;
  buyerAssistantAppUserId?: InputMaybe<IntOperationFilterInput>;
  campaignLocalBudgetAssignments?: InputMaybe<ListFilterInputTypeOfCampaignLocalBudgetAssignmentFilterInput>;
  cellPhoneNumber?: InputMaybe<StringOperationFilterInput>;
  clientMediaOutlets?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  crossChannelCampaignVersions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignVersionFilterInput>;
  crossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFilterInput>;
  defaultCompanyID?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dmainfoBuyers?: InputMaybe<ListFilterInputTypeOfDmainfoBuyerFilterInput>;
  dubOrderTransmissions?: InputMaybe<ListFilterInputTypeOfDubOrderTransmissionFilterInput>;
  duplicationCostEstimates?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateFilterInput>;
  efax?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  fileImports?: InputMaybe<ListFilterInputTypeOfFileImportFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFileFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullLogin?: InputMaybe<StringOperationFilterInput>;
  gareports?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  glanceReportElements?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReports?: InputMaybe<ListFilterInputTypeOfGlanceReportFilterInput>;
  initials?: InputMaybe<StringOperationFilterInput>;
  insertionOrders?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  inverseBuyerAssistantAppUser?: InputMaybe<ListFilterInputTypeOfAppUserFilterInput>;
  invoiceAppUsers?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  invoiceApprovedByAppUsers?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  invoiceAssignedApproverAppUsers?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  jobAppUsers?: InputMaybe<ListFilterInputTypeOfJobAppUserFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingLockBilledByAppUsers?: InputMaybe<ListFilterInputTypeOfLeadBillingLockFilterInput>;
  leadBillingLockCreateAppUsers?: InputMaybe<ListFilterInputTypeOfLeadBillingLockFilterInput>;
  login?: InputMaybe<StringOperationFilterInput>;
  mediaAuthorizationClientServicesApproverAppUsers?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaAuthorizationCreateAppUsers?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaAuthorizationFinanceApproverAppUsers?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfMediaOutletFileReferenceFilterInput>;
  mediaOutletRoles?: InputMaybe<ListFilterInputTypeOfMediaOutletRoleFilterInput>;
  mediaPlans?: InputMaybe<ListFilterInputTypeOfMediaPlanFilterInput>;
  mediaScheduleBookedByAppUsers?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaScheduleCreatedByAppUsers?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaTransmissionNotifications?: InputMaybe<ListFilterInputTypeOfMediaTransmissionNotificationFilterInput>;
  mediaTransmissions?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  newCampaignAnnouncementCreateAppUsers?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementDuplicationMasterSetupSenderAppUsers?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementPiAppUsers?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementPiBuyerAppUsers?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementPiLfassistantAppUsers?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementPiSfassistantAppUsers?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementSenderAppUsers?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  notes?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  oldappUserId?: InputMaybe<IntOperationFilterInput>;
  oldbuyerAssistantAppUserId?: InputMaybe<IntOperationFilterInput>;
  oldbuyerId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AppUserFilterInput>>;
  packages?: InputMaybe<ListFilterInputTypeOfPackageFilterInput>;
  perInquiryCosts?: InputMaybe<ListFilterInputTypeOfPerInquiryCostFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  placeholder?: InputMaybe<BooleanOperationFilterInput>;
  prelogEstimates?: InputMaybe<ListFilterInputTypeOfPrelogEstimateFilterInput>;
  prelogs?: InputMaybe<ListFilterInputTypeOfPrelogFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  pwUserID?: InputMaybe<IntOperationFilterInput>;
  ratings?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  reportExportFormatFields?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormats?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportPresets?: InputMaybe<ListFilterInputTypeOfReportPresetFilterInput>;
  resultDetails?: InputMaybe<ListFilterInputTypeOfResultDetailFilterInput>;
  resultManualEntryPresets?: InputMaybe<ListFilterInputTypeOfResultManualEntryPresetFilterInput>;
  revisionGroups?: InputMaybe<ListFilterInputTypeOfRevisionGroupFilterInput>;
  scheduleCostAdjustments?: InputMaybe<ListFilterInputTypeOfScheduleCostAdjustmentFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  snapshots?: InputMaybe<ListFilterInputTypeOfSnapshotFilterInput>;
  sqllogin?: InputMaybe<StringOperationFilterInput>;
  standardsAndPracticesApprovals?: InputMaybe<ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput>;
  taskAssignedAppUsers?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskCreateAppUsers?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskHistories?: InputMaybe<ListFilterInputTypeOfTaskHistoryFilterInput>;
  titles?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  tmpInvoices?: InputMaybe<ListFilterInputTypeOfTmpInvoiceFilterInput>;
  topLineMediaPlans?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  trackingCodeAvailCreateAppUsers?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodeAvailCustomizeAppUsers?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodeAvailSenderAppUsers?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodeAvailTestAppUsers?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodeImports?: InputMaybe<ListFilterInputTypeOfTrackingCodeImportFilterInput>;
  trafficSheets?: InputMaybe<ListFilterInputTypeOfTrafficSheetFilterInput>;
  uniqueAdAssignerAppUsers?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  uniqueAdDeactivateAppUsers?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  uniqueAdRequestorAppUsers?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  uniqueAdTestAppUsers?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  waveCastModels?: InputMaybe<ListFilterInputTypeOfWaveCastModelFilterInput>;
  waveCastWaves?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
};

export type AppUserReportPreset = {
  __typename?: 'AppUserReportPreset';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  appUserReportPresetId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  report?: Maybe<Report>;
  reportId: Scalars['Int'];
  reportPreset?: Maybe<ReportPreset>;
  reportPresetId: Scalars['Int'];
};

export type AppUserReportPresetFilterInput = {
  and?: InputMaybe<Array<AppUserReportPresetFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appUserReportPresetId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppUserReportPresetFilterInput>>;
  report?: InputMaybe<ReportFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
};

export type AppUserRibbonShortcut = {
  __typename?: 'AppUserRibbonShortcut';
  appScreen?: Maybe<AppScreen>;
  appScreenId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  appUserRibbonShortcutId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  sort: Scalars['Int'];
};

export type AppUserRibbonShortcutFilterInput = {
  and?: InputMaybe<Array<AppUserRibbonShortcutFilterInput>>;
  appScreen?: InputMaybe<AppScreenFilterInput>;
  appScreenId?: InputMaybe<IntOperationFilterInput>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appUserRibbonShortcutId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppUserRibbonShortcutFilterInput>>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type AppUserRole = {
  __typename?: 'AppUserRole';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  appUserRoleId: Scalars['Int'];
  dmainfoBuyers?: Maybe<Array<Maybe<DmainfoBuyer>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  roleLookup?: Maybe<Lookup>;
  roleLookupId: Scalars['Int'];
};

export enum AppUserRoleEnum {
  Ac = 'AC',
  AccountingSupervisor = 'ACCOUNTING_SUPERVISOR',
  Actg = 'ACTG',
  Admin = 'ADMIN',
  Ae = 'AE',
  AffidavitReportsReadOnly = 'AFFIDAVIT_REPORTS_READ_ONLY',
  An = 'AN',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  AssociateProjectManager = 'ASSOCIATE_PROJECT_MANAGER',
  Ba = 'BA',
  Bdn = 'BDN',
  Bu = 'BU',
  Cac = 'CAC',
  Cbu = 'CBU',
  Com = 'COM',
  Controller = 'CONTROLLER',
  Cs = 'CS',
  Cspc = 'CSPC',
  Cust = 'CUST',
  DataQualitySpecialist = 'DATA_QUALITY_SPECIALIST',
  DigitalAccounting = 'DIGITAL_ACCOUNTING',
  DigitalAccountingManager = 'DIGITAL_ACCOUNTING_MANAGER',
  DigitalAnalyst = 'DIGITAL_ANALYST',
  DigitalMediaDirector = 'DIGITAL_MEDIA_DIRECTOR',
  DigitalMediaManager = 'DIGITAL_MEDIA_MANAGER',
  DigitalMediaTeamOwner = 'DIGITAL_MEDIA_TEAM_OWNER',
  DigitalProjectManager = 'DIGITAL_PROJECT_MANAGER',
  Dis = 'DIS',
  Dub = 'DUB',
  DuplicationEditor = 'DUPLICATION_EDITOR',
  Fq = 'FQ',
  Iac = 'IAC',
  Iad = 'IAD',
  Iae = 'IAE',
  Ias = 'IAS',
  InformationTechnology = 'INFORMATION_TECHNOLOGY',
  Int = 'INT',
  InvalidAppUserRole = 'INVALID_APP_USER_ROLE',
  Ma = 'MA',
  Mc = 'MC',
  Mg = 'MG',
  MiscellaneousFeesAuthorized = 'MISCELLANEOUS_FEES_AUTHORIZED',
  Nu = 'NU',
  Op = 'OP',
  Pab = 'PAB',
  Pl = 'PL',
  ProjectManager = 'PROJECT_MANAGER',
  Pu = 'PU',
  San = 'SAN',
  SeniorProjectManager = 'SENIOR_PROJECT_MANAGER',
  Spl = 'SPL',
  Sst = 'SST',
  St = 'ST',
  StaffAccountant = 'STAFF_ACCOUNTANT',
  VendorCodeCoordinator = 'VENDOR_CODE_COORDINATOR',
  VideoDistributionClientServices = 'VIDEO_DISTRIBUTION_CLIENT_SERVICES'
}

export type AppUserRoleFilterInput = {
  and?: InputMaybe<Array<AppUserRoleFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appUserRoleId?: InputMaybe<IntOperationFilterInput>;
  dmainfoBuyers?: InputMaybe<ListFilterInputTypeOfDmainfoBuyerFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AppUserRoleFilterInput>>;
  roleLookup?: InputMaybe<LookupFilterInput>;
  roleLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AppUserSetting = {
  __typename?: 'AppUserSetting';
  appSettingLookup?: Maybe<Lookup>;
  appSettingLookupId: Scalars['Int'];
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  appUserSettingId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type AppUserSettingFilterInput = {
  and?: InputMaybe<Array<AppUserSettingFilterInput>>;
  appSettingLookup?: InputMaybe<LookupFilterInput>;
  appSettingLookupId?: InputMaybe<IntOperationFilterInput>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appUserSettingId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppUserSettingFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type AppUserSortInput = {
  active?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  addressId?: InputMaybe<SortEnumType>;
  alertsLastPullUtc?: InputMaybe<SortEnumType>;
  appLocation?: InputMaybe<AppLocationSortInput>;
  appLocationCode?: InputMaybe<SortEnumType>;
  appLocationID?: InputMaybe<SortEnumType>;
  appLocationId?: InputMaybe<SortEnumType>;
  appUserID?: InputMaybe<SortEnumType>;
  appUserId?: InputMaybe<SortEnumType>;
  buyerActive?: InputMaybe<SortEnumType>;
  buyerAssistantAppUser?: InputMaybe<AppUserSortInput>;
  buyerAssistantAppUserId?: InputMaybe<SortEnumType>;
  cellPhoneNumber?: InputMaybe<SortEnumType>;
  defaultCompanyID?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  efax?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullLogin?: InputMaybe<SortEnumType>;
  initials?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  login?: InputMaybe<SortEnumType>;
  oldappUserId?: InputMaybe<SortEnumType>;
  oldbuyerAssistantAppUserId?: InputMaybe<SortEnumType>;
  oldbuyerId?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  placeholder?: InputMaybe<SortEnumType>;
  pwUserID?: InputMaybe<SortEnumType>;
  sqllogin?: InputMaybe<SortEnumType>;
};

export type AppView = {
  __typename?: 'AppView';
  aliasTypes?: Maybe<Array<Maybe<AliasType>>>;
  aliases?: Maybe<Array<Maybe<Alias>>>;
  appViewFields?: Maybe<Array<Maybe<AppViewField>>>;
  appViewFilterRecords?: Maybe<Array<Maybe<AppViewFilterRecord>>>;
  appViewId: Scalars['Int'];
  dataSourceTypeLookup?: Maybe<Lookup>;
  dataSourceTypeLookupId: Scalars['Int'];
  databaseId?: Maybe<Scalars['Int']>;
  dbname?: Maybe<Scalars['String']>;
  isUpdatable: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  reports?: Maybe<Array<Maybe<Report>>>;
  targetDashboardDataSourceAppViewId?: Maybe<Scalars['Int']>;
  useForceOrderSqlQueryHint: Scalars['Boolean'];
};

export type AppViewField = {
  __typename?: 'AppViewField';
  appField?: Maybe<AppField>;
  appFieldId: Scalars['Int'];
  appView?: Maybe<AppView>;
  appViewFieldId: Scalars['Int'];
  appViewId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  reportEditFields?: Maybe<Array<Maybe<ReportEditField>>>;
  requiredTypeLookup?: Maybe<Lookup>;
  requiredTypeLookupId: Scalars['Int'];
};

export type AppViewFieldFilterInput = {
  and?: InputMaybe<Array<AppViewFieldFilterInput>>;
  appField?: InputMaybe<AppFieldFilterInput>;
  appFieldId?: InputMaybe<IntOperationFilterInput>;
  appView?: InputMaybe<AppViewFilterInput>;
  appViewFieldId?: InputMaybe<IntOperationFilterInput>;
  appViewId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppViewFieldFilterInput>>;
  reportEditFields?: InputMaybe<ListFilterInputTypeOfReportEditFieldFilterInput>;
  requiredTypeLookup?: InputMaybe<LookupFilterInput>;
  requiredTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AppViewFilterInput = {
  aliasTypes?: InputMaybe<ListFilterInputTypeOfAliasTypeFilterInput>;
  aliases?: InputMaybe<ListFilterInputTypeOfAliasFilterInput>;
  and?: InputMaybe<Array<AppViewFilterInput>>;
  appViewFields?: InputMaybe<ListFilterInputTypeOfAppViewFieldFilterInput>;
  appViewFilterRecords?: InputMaybe<ListFilterInputTypeOfAppViewFilterRecordFilterInput>;
  appViewId?: InputMaybe<IntOperationFilterInput>;
  dataSourceTypeLookup?: InputMaybe<LookupFilterInput>;
  dataSourceTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  databaseId?: InputMaybe<IntOperationFilterInput>;
  dbname?: InputMaybe<StringOperationFilterInput>;
  isUpdatable?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppViewFilterInput>>;
  reports?: InputMaybe<ListFilterInputTypeOfReportFilterInput>;
  targetDashboardDataSourceAppViewId?: InputMaybe<IntOperationFilterInput>;
  useForceOrderSqlQueryHint?: InputMaybe<BooleanOperationFilterInput>;
};

export type AppViewFilterRecord = {
  __typename?: 'AppViewFilterRecord';
  appFilter?: Maybe<AppFilter>;
  appFilterId: Scalars['Int'];
  appView?: Maybe<AppView>;
  appViewFilterId: Scalars['Int'];
  appViewId: Scalars['Int'];
  isRequired: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type AppViewFilterRecordFilterInput = {
  and?: InputMaybe<Array<AppViewFilterRecordFilterInput>>;
  appFilter?: InputMaybe<AppFilterFilterInput>;
  appFilterId?: InputMaybe<IntOperationFilterInput>;
  appView?: InputMaybe<AppViewFilterInput>;
  appViewFilterId?: InputMaybe<IntOperationFilterInput>;
  appViewId?: InputMaybe<IntOperationFilterInput>;
  isRequired?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AppViewFilterRecordFilterInput>>;
};

export type AppViewSortInput = {
  appViewId?: InputMaybe<SortEnumType>;
  dataSourceTypeLookup?: InputMaybe<LookupSortInput>;
  dataSourceTypeLookupId?: InputMaybe<SortEnumType>;
  databaseId?: InputMaybe<SortEnumType>;
  dbname?: InputMaybe<SortEnumType>;
  isUpdatable?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  targetDashboardDataSourceAppViewId?: InputMaybe<SortEnumType>;
  useForceOrderSqlQueryHint?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type ArgumentError = Error & {
  __typename?: 'ArgumentError';
  message: Scalars['String'];
  paramName?: Maybe<Scalars['String']>;
};

export type Audience = {
  __typename?: 'Audience';
  audienceId: Scalars['Int'];
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type AudienceFilterInput = {
  and?: InputMaybe<Array<AudienceFilterInput>>;
  audienceId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AudienceFilterInput>>;
};

export type AudienceSortInput = {
  audienceId?: InputMaybe<SortEnumType>;
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AutoReport = {
  __typename?: 'AutoReport';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  autoReportId: Scalars['Int'];
  autoReportItems?: Maybe<Array<Maybe<AutoReportItem>>>;
  combineIntoSingleFile: Scalars['Boolean'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createUtc: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  reportDestinationOverride?: Maybe<Scalars['String']>;
};

export type AutoReportFilterInput = {
  and?: InputMaybe<Array<AutoReportFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  autoReportId?: InputMaybe<IntOperationFilterInput>;
  autoReportItems?: InputMaybe<ListFilterInputTypeOfAutoReportItemFilterInput>;
  combineIntoSingleFile?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AutoReportFilterInput>>;
  reportDestinationOverride?: InputMaybe<StringOperationFilterInput>;
};

export type AutoReportItem = {
  __typename?: 'AutoReportItem';
  autoReport?: Maybe<AutoReport>;
  autoReportId: Scalars['Int'];
  autoReportItemId: Scalars['Int'];
  autoReportTypeLookup?: Maybe<Lookup>;
  autoReportTypeLookupId: Scalars['Int'];
  glanceReport?: Maybe<GlanceReport>;
  glanceReportId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  reportPreset?: Maybe<ReportPreset>;
  reportPresetId?: Maybe<Scalars['Int']>;
  showDetails?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
};

export type AutoReportItemFilterInput = {
  and?: InputMaybe<Array<AutoReportItemFilterInput>>;
  autoReport?: InputMaybe<AutoReportFilterInput>;
  autoReportId?: InputMaybe<IntOperationFilterInput>;
  autoReportItemId?: InputMaybe<IntOperationFilterInput>;
  autoReportTypeLookup?: InputMaybe<LookupFilterInput>;
  autoReportTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  glanceReport?: InputMaybe<GlanceReportFilterInput>;
  glanceReportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AutoReportItemFilterInput>>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
  showDetails?: InputMaybe<BooleanOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type AutoSchedule = {
  __typename?: 'AutoSchedule';
  allowableDays?: Maybe<Scalars['String']>;
  autoScheduleId: Scalars['Int'];
  autoScheduleTraffics?: Maybe<Array<Maybe<AutoScheduleTraffic>>>;
  booking?: Maybe<Booking>;
  bookingId?: Maybe<Scalars['Int']>;
  buyTypeLookup?: Maybe<Lookup>;
  buyTypeLookupId?: Maybe<Scalars['Int']>;
  buyerAppUser?: Maybe<AppUser>;
  buyerAppUserId?: Maybe<Scalars['Int']>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createUtc?: Maybe<Scalars['DateTime']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endTime?: Maybe<Scalars['TimeSpan']>;
  instanceCreationLookup?: Maybe<Lookup>;
  instanceCreationLookupId?: Maybe<Scalars['Int']>;
  internalComment?: Maybe<Scalars['String']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  oldId?: Maybe<Scalars['Int']>;
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  piamountToChargeClient?: Maybe<Scalars['Decimal']>;
  piamountToPayStation?: Maybe<Scalars['Decimal']>;
  pibasedOnLookup?: Maybe<Lookup>;
  pibasedOnLookupId?: Maybe<Scalars['Int']>;
  rateTypeLookup?: Maybe<Lookup>;
  rateTypeLookupId?: Maybe<Scalars['Int']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  startTime?: Maybe<Scalars['TimeSpan']>;
  statusLookup?: Maybe<Lookup>;
  statusLookupId?: Maybe<Scalars['Int']>;
};

export type AutoScheduleFilterInput = {
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AutoScheduleFilterInput>>;
  autoScheduleId?: InputMaybe<IntOperationFilterInput>;
  autoScheduleTraffics?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficFilterInput>;
  booking?: InputMaybe<BookingFilterInput>;
  bookingId?: InputMaybe<IntOperationFilterInput>;
  buyTypeLookup?: InputMaybe<LookupFilterInput>;
  buyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  buyerAppUser?: InputMaybe<AppUserFilterInput>;
  buyerAppUserId?: InputMaybe<IntOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  instanceCreationLookup?: InputMaybe<LookupFilterInput>;
  instanceCreationLookupId?: InputMaybe<IntOperationFilterInput>;
  internalComment?: InputMaybe<StringOperationFilterInput>;
  isInactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  oldId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AutoScheduleFilterInput>>;
  perInquiryCosts?: InputMaybe<ListFilterInputTypeOfPerInquiryCostFilterInput>;
  piamountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  piamountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  pibasedOnLookup?: InputMaybe<LookupFilterInput>;
  pibasedOnLookupId?: InputMaybe<IntOperationFilterInput>;
  rateTypeLookup?: InputMaybe<LookupFilterInput>;
  rateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  statusLookup?: InputMaybe<LookupFilterInput>;
  statusLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AutoScheduleTraffic = {
  __typename?: 'AutoScheduleTraffic';
  autoSchedule?: Maybe<AutoSchedule>;
  autoScheduleId?: Maybe<Scalars['Int']>;
  autoScheduleTrafficDetails?: Maybe<Array<Maybe<AutoScheduleTrafficDetail>>>;
  autoScheduleTrafficId: Scalars['Int'];
  autoScheduleTrafficMediaChanges?: Maybe<Array<Maybe<AutoScheduleTrafficMediaChange>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  migrationUniqueAdId?: Maybe<Scalars['Int']>;
  trafficActionLookup?: Maybe<Lookup>;
  trafficActionLookupId: Scalars['Int'];
};

export type AutoScheduleTrafficDetail = {
  __typename?: 'AutoScheduleTrafficDetail';
  autoScheduleTraffic?: Maybe<AutoScheduleTraffic>;
  autoScheduleTrafficDetailId: Scalars['Int'];
  autoScheduleTrafficId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  splitPercent: Scalars['Decimal'];
  uniqueAd?: Maybe<UniqueAd>;
  uniqueAdId?: Maybe<Scalars['Int']>;
};

export type AutoScheduleTrafficDetailFilterInput = {
  and?: InputMaybe<Array<AutoScheduleTrafficDetailFilterInput>>;
  autoScheduleTraffic?: InputMaybe<AutoScheduleTrafficFilterInput>;
  autoScheduleTrafficDetailId?: InputMaybe<IntOperationFilterInput>;
  autoScheduleTrafficId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AutoScheduleTrafficDetailFilterInput>>;
  splitPercent?: InputMaybe<DecimalOperationFilterInput>;
  uniqueAd?: InputMaybe<UniqueAdFilterInput>;
  uniqueAdId?: InputMaybe<IntOperationFilterInput>;
};

export type AutoScheduleTrafficFilterInput = {
  and?: InputMaybe<Array<AutoScheduleTrafficFilterInput>>;
  autoSchedule?: InputMaybe<AutoScheduleFilterInput>;
  autoScheduleId?: InputMaybe<IntOperationFilterInput>;
  autoScheduleTrafficDetails?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficDetailFilterInput>;
  autoScheduleTrafficId?: InputMaybe<IntOperationFilterInput>;
  autoScheduleTrafficMediaChanges?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficMediaChangeFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  migrationUniqueAdId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AutoScheduleTrafficFilterInput>>;
  trafficActionLookup?: InputMaybe<LookupFilterInput>;
  trafficActionLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AutoScheduleTrafficMediaChange = {
  __typename?: 'AutoScheduleTrafficMediaChange';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  autoScheduleTraffic?: Maybe<AutoScheduleTraffic>;
  autoScheduleTrafficId: Scalars['Int'];
  autoScheduleTrafficMediaChangeId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  trafficChangeMediaActionLookup?: Maybe<Lookup>;
  trafficChangeMediaActionLookupId?: Maybe<Scalars['Int']>;
};

export type AutoScheduleTrafficMediaChangeFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AutoScheduleTrafficMediaChangeFilterInput>>;
  autoScheduleTraffic?: InputMaybe<AutoScheduleTrafficFilterInput>;
  autoScheduleTrafficId?: InputMaybe<IntOperationFilterInput>;
  autoScheduleTrafficMediaChangeId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AutoScheduleTrafficMediaChangeFilterInput>>;
  trafficChangeMediaActionLookup?: InputMaybe<LookupFilterInput>;
  trafficChangeMediaActionLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AutoSource = {
  __typename?: 'AutoSource';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  autoSourceId: Scalars['Int'];
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  autosourceBeginUtc?: Maybe<Scalars['DateTime']>;
  autosourceEndUtc?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  results?: Maybe<Array<Maybe<Result>>>;
  spikeBaseLines?: Maybe<Array<Maybe<SpikeBaseLine>>>;
  whereClause?: Maybe<Scalars['String']>;
};

export type AutoSourceFilterInput = {
  and?: InputMaybe<Array<AutoSourceFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  autoSourceId?: InputMaybe<IntOperationFilterInput>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  autosourceBeginUtc?: InputMaybe<DateTimeOperationFilterInput>;
  autosourceEndUtc?: InputMaybe<DateTimeOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AutoSourceFilterInput>>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  spikeBaseLines?: InputMaybe<ListFilterInputTypeOfSpikeBaseLineFilterInput>;
  whereClause?: InputMaybe<StringOperationFilterInput>;
};

export type AutoSourceResult = {
  __typename?: 'AutoSourceResult';
  adInstance?: Maybe<AdInstance>;
  adInstanceDetail?: Maybe<AdInstanceDetail>;
  adInstanceDetailId?: Maybe<Scalars['Int']>;
  adInstanceId?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<AutoSource>;
  autoSourceId: Scalars['Int'];
  autoSourceResultId: Scalars['Long'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  result?: Maybe<Result>;
  resultId: Scalars['Long'];
  script?: Maybe<Script>;
  scriptId?: Maybe<Scalars['Int']>;
  unsourcedReasonLookup?: Maybe<Lookup>;
  unsourcedReasonLookupId?: Maybe<Scalars['Int']>;
};

export type AutoSourceResultFilterInput = {
  adInstance?: InputMaybe<AdInstanceFilterInput>;
  adInstanceDetail?: InputMaybe<AdInstanceDetailFilterInput>;
  adInstanceDetailId?: InputMaybe<IntOperationFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AutoSourceResultFilterInput>>;
  autoSource?: InputMaybe<AutoSourceFilterInput>;
  autoSourceId?: InputMaybe<IntOperationFilterInput>;
  autoSourceResultId?: InputMaybe<LongOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AutoSourceResultFilterInput>>;
  result?: InputMaybe<ResultFilterInput>;
  resultId?: InputMaybe<LongOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  unsourcedReasonLookup?: InputMaybe<LookupFilterInput>;
  unsourcedReasonLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AutomatedFileProcessing = {
  __typename?: 'AutomatedFileProcessing';
  automatedFileProcessingId: Scalars['Int'];
  automatedFileProcessingTypeLookup?: Maybe<Lookup>;
  automatedFileProcessingTypeLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createdByAppUser?: Maybe<AppUser>;
  createdByAppUserId?: Maybe<Scalars['Int']>;
  datoramaImportJobs?: Maybe<Array<Maybe<DatoramaImportJob>>>;
  emailTo?: Maybe<Scalars['String']>;
  emlmediaOutlet?: Maybe<MediaOutlet>;
  emlmediaOutletId?: Maybe<Scalars['Int']>;
  emlnoAttachment: Scalars['Boolean'];
  emlsubjectLine?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  ftpdownloadTime?: Maybe<Scalars['TimeSpan']>;
  ftppassword?: Maybe<Scalars['String']>;
  ftpsite?: Maybe<Scalars['String']>;
  ftpuserName?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['Int']>;
  jobPriority?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  offsetDays: Scalars['Int'];
  oldId?: Maybe<Scalars['Int']>;
  oldTableName?: Maybe<Scalars['String']>;
  processor?: Maybe<Processor>;
  processorId?: Maybe<Scalars['Int']>;
  retrieveOnlyMostRecentMatch: Scalars['Boolean'];
  saveToDirectory?: Maybe<Scalars['String']>;
  sendEmail: Scalars['Boolean'];
};

export type AutomatedFileProcessingFilterInput = {
  and?: InputMaybe<Array<AutomatedFileProcessingFilterInput>>;
  automatedFileProcessingId?: InputMaybe<IntOperationFilterInput>;
  automatedFileProcessingTypeLookup?: InputMaybe<LookupFilterInput>;
  automatedFileProcessingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdByAppUser?: InputMaybe<AppUserFilterInput>;
  createdByAppUserId?: InputMaybe<IntOperationFilterInput>;
  datoramaImportJobs?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobFilterInput>;
  emailTo?: InputMaybe<StringOperationFilterInput>;
  emlmediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  emlmediaOutletId?: InputMaybe<IntOperationFilterInput>;
  emlnoAttachment?: InputMaybe<BooleanOperationFilterInput>;
  emlsubjectLine?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  ftpdownloadTime?: InputMaybe<TimeSpanOperationFilterInput>;
  ftppassword?: InputMaybe<StringOperationFilterInput>;
  ftpsite?: InputMaybe<StringOperationFilterInput>;
  ftpuserName?: InputMaybe<StringOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobId?: InputMaybe<IntOperationFilterInput>;
  jobPriority?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  offsetDays?: InputMaybe<IntOperationFilterInput>;
  oldId?: InputMaybe<IntOperationFilterInput>;
  oldTableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AutomatedFileProcessingFilterInput>>;
  processor?: InputMaybe<ProcessorFilterInput>;
  processorId?: InputMaybe<IntOperationFilterInput>;
  retrieveOnlyMostRecentMatch?: InputMaybe<BooleanOperationFilterInput>;
  saveToDirectory?: InputMaybe<StringOperationFilterInput>;
  sendEmail?: InputMaybe<BooleanOperationFilterInput>;
};

export type AutomatedFileProcessingSortInput = {
  automatedFileProcessingId?: InputMaybe<SortEnumType>;
  automatedFileProcessingTypeLookup?: InputMaybe<LookupSortInput>;
  automatedFileProcessingTypeLookupId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdByAppUser?: InputMaybe<AppUserSortInput>;
  createdByAppUserId?: InputMaybe<SortEnumType>;
  emailTo?: InputMaybe<SortEnumType>;
  emlmediaOutlet?: InputMaybe<MediaOutletSortInput>;
  emlmediaOutletId?: InputMaybe<SortEnumType>;
  emlnoAttachment?: InputMaybe<SortEnumType>;
  emlsubjectLine?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  ftpdownloadTime?: InputMaybe<SortEnumType>;
  ftppassword?: InputMaybe<SortEnumType>;
  ftpsite?: InputMaybe<SortEnumType>;
  ftpuserName?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  job?: InputMaybe<JobSortInput>;
  jobId?: InputMaybe<SortEnumType>;
  jobPriority?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  offsetDays?: InputMaybe<SortEnumType>;
  oldId?: InputMaybe<SortEnumType>;
  oldTableName?: InputMaybe<SortEnumType>;
  processor?: InputMaybe<ProcessorSortInput>;
  processorId?: InputMaybe<SortEnumType>;
  retrieveOnlyMostRecentMatch?: InputMaybe<SortEnumType>;
  saveToDirectory?: InputMaybe<SortEnumType>;
  sendEmail?: InputMaybe<SortEnumType>;
};

export type AvailProposal = {
  __typename?: 'AvailProposal';
  availProposalDetails?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  availProposalId: Scalars['Int'];
  availsSoldAsPackage: Scalars['Boolean'];
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletGroup?: Maybe<MediaOutletGroup>;
  mediaOutletGroupId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  syndicatorVendor?: Maybe<Vendor>;
  syndicatorVendorId?: Maybe<Scalars['Int']>;
};

export type AvailProposalDetail = {
  __typename?: 'AvailProposalDetail';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  allowableDays?: Maybe<Scalars['String']>;
  approved: Scalars['Boolean'];
  approvedAppUser?: Maybe<AppUser>;
  approvedAppUserId?: Maybe<Scalars['Int']>;
  approvedUtc?: Maybe<Scalars['DateTime']>;
  availProposal?: Maybe<AvailProposal>;
  availProposalDetailAttributes?: Maybe<Array<Maybe<AvailProposalDetailAttribute>>>;
  availProposalDetailId: Scalars['Int'];
  availProposalId: Scalars['Int'];
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endTime: Scalars['TimeSpan'];
  estimatedValue: Scalars['Decimal'];
  instances: Scalars['Int'];
  isRerun: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  latestDateForApproval: Scalars['Date'];
  mediaDueDate: Scalars['Date'];
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  rateTypeLookup?: Maybe<Lookup>;
  rateTypeLookupId: Scalars['Int'];
  ratingAvailProposalDetails?: Maybe<Array<Maybe<RatingAvailProposalDetail>>>;
  roviProgramId?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  startTime: Scalars['TimeSpan'];
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId: Scalars['Int'];
  theoreticalGrossAmount: Scalars['Decimal'];
};

export type AvailProposalDetailAttribute = {
  __typename?: 'AvailProposalDetailAttribute';
  availProposalDetail?: Maybe<AvailProposalDetail>;
  availProposalDetailAttributeId: Scalars['Int'];
  availProposalDetailId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  scheduleAttributeLookup?: Maybe<Lookup>;
  scheduleAttributeLookupId: Scalars['Int'];
};

export type AvailProposalDetailAttributeFilterInput = {
  and?: InputMaybe<Array<AvailProposalDetailAttributeFilterInput>>;
  availProposalDetail?: InputMaybe<AvailProposalDetailFilterInput>;
  availProposalDetailAttributeId?: InputMaybe<IntOperationFilterInput>;
  availProposalDetailId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AvailProposalDetailAttributeFilterInput>>;
  scheduleAttributeLookup?: InputMaybe<LookupFilterInput>;
  scheduleAttributeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type AvailProposalDetailFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AvailProposalDetailFilterInput>>;
  approved?: InputMaybe<BooleanOperationFilterInput>;
  approvedAppUser?: InputMaybe<AppUserFilterInput>;
  approvedAppUserId?: InputMaybe<IntOperationFilterInput>;
  approvedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  availProposal?: InputMaybe<AvailProposalFilterInput>;
  availProposalDetailAttributes?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailAttributeFilterInput>;
  availProposalDetailId?: InputMaybe<IntOperationFilterInput>;
  availProposalId?: InputMaybe<IntOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  estimatedValue?: InputMaybe<DecimalOperationFilterInput>;
  instances?: InputMaybe<IntOperationFilterInput>;
  isRerun?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  latestDateForApproval?: InputMaybe<DateOperationFilterInput>;
  mediaDueDate?: InputMaybe<DateOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AvailProposalDetailFilterInput>>;
  rateTypeLookup?: InputMaybe<LookupFilterInput>;
  rateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  ratingAvailProposalDetails?: InputMaybe<ListFilterInputTypeOfRatingAvailProposalDetailFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
  theoreticalGrossAmount?: InputMaybe<DecimalOperationFilterInput>;
};

export type AvailProposalFilterInput = {
  and?: InputMaybe<Array<AvailProposalFilterInput>>;
  availProposalDetails?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  availProposalId?: InputMaybe<IntOperationFilterInput>;
  availsSoldAsPackage?: InputMaybe<BooleanOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletGroup?: InputMaybe<MediaOutletGroupFilterInput>;
  mediaOutletGroupId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AvailProposalFilterInput>>;
  syndicatorVendor?: InputMaybe<VendorFilterInput>;
  syndicatorVendorId?: InputMaybe<IntOperationFilterInput>;
};

export type AverageCpmByPublisherByChannel = {
  __typename?: 'AverageCpmByPublisherByChannel';
  averageCpc?: Maybe<Scalars['Decimal']>;
  averageCpm?: Maybe<Scalars['Decimal']>;
  endDate: Scalars['Date'];
  mediaOutletID: Scalars['Int'];
  mediaOutletName: Scalars['String'];
  mediaTypeId: Scalars['Int'];
  mediaTypeName: Scalars['String'];
  startDate: Scalars['Date'];
  totalClicks: Scalars['Int'];
  totalImpressions: Scalars['Int'];
  totalNetSpend: Scalars['Decimal'];
};

export type AverageCpmByPublisherByChannelFilterInput = {
  and?: InputMaybe<Array<AverageCpmByPublisherByChannelFilterInput>>;
  averageCpc?: InputMaybe<DecimalOperationFilterInput>;
  averageCpm?: InputMaybe<DecimalOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  mediaOutletID?: InputMaybe<IntOperationFilterInput>;
  mediaOutletName?: InputMaybe<StringOperationFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaTypeName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AverageCpmByPublisherByChannelFilterInput>>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  totalClicks?: InputMaybe<IntOperationFilterInput>;
  totalImpressions?: InputMaybe<IntOperationFilterInput>;
  totalNetSpend?: InputMaybe<DecimalOperationFilterInput>;
};

export type AxCompany = {
  __typename?: 'AxCompany';
  companyName: Scalars['String'];
  dataAreaId: Scalars['String'];
};

export type AxProjectDetails = {
  __typename?: 'AxProjectDetails';
  brandDim: AxBrandDim;
  brandName: Scalars['String'];
  brandValue: Scalars['String'];
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  customerAccountId: Scalars['String'];
  dataArea: DataArea;
  dataAreaId: Scalars['String'];
  dataAreaName: Scalars['String'];
  dataAreaValue: Scalars['String'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  initiativeDim: AxInitiativeDim;
  initiativeName: Scalars['String'];
  initiativeValue: Scalars['String'];
  isProjectActive: Scalars['Boolean'];
  name: Scalars['String'];
  productDim: AxProductDim;
  productName: Scalars['String'];
  productValue: Scalars['String'];
  projFullName: Scalars['String'];
  projGroupId: Scalars['String'];
  projId: Scalars['String'];
  projectContractId: Scalars['String'];
  purposeValue: Scalars['String'];
  startDate: Scalars['DateTime'];
  statusName: Scalars['String'];
};

export type AxProjectTransactionSummary = {
  __typename?: 'AxProjectTransactionSummary';
  actualCost: Scalars['Decimal'];
  actualRevenue: Scalars['Decimal'];
  brandCode: Scalars['String'];
  brandDim: AxBrandDim;
  brandName: Scalars['String'];
  brandValue: Scalars['String'];
  categoryId: Scalars['String'];
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  departmentDim: AxDepartmentDim;
  departmentName: Scalars['String'];
  departmentValue: Scalars['String'];
  gpCategory: Scalars['String'];
  id: Scalars['String'];
  mediaMonthDim: AxMediaMonth;
  mediaMonthName: Scalars['String'];
  mediaMonthValue: Scalars['String'];
  parentLineIds: Array<Scalars['String']>;
  projectCode: Scalars['String'];
  projectDataArea: Scalars['String'];
  projectDim: AxProjectDim;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  purposeCode: Scalars['String'];
  simpleDepartmentCode: Scalars['String'];
  simpleDepartmentName: Scalars['String'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  addressValidTo: Scalars['DateTime'];
  bankAccountId: Scalars['String'];
  bankGroupId: Scalars['String'];
  dataAreaId: Scalars['String'];
  defaultDimensionDisplayValue: Scalars['String'];
  name: Scalars['String'];
  positivePayFormatName: Scalars['String'];
};

export type BasicCabReportBalanceInfo = {
  __typename?: 'BasicCabReportBalanceInfo';
  cbsReportDefinitionId: Scalars['Int'];
  cbsReportName: Scalars['String'];
  closingBalance: Scalars['Decimal'];
  openingBalance: Scalars['Decimal'];
};

export enum BlobContainer {
  Cerf = 'CERF',
  D365MetaData = 'D365_META_DATA',
  DatoramaExports = 'DATORAMA_EXPORTS',
  Email = 'EMAIL',
  FileReference = 'FILE_REFERENCE',
  FileReferenceSandbox = 'FILE_REFERENCE_SANDBOX',
  Finops = 'FINOPS',
  InsertionOrders = 'INSERTION_ORDERS',
  MediaAuthorizations = 'MEDIA_AUTHORIZATIONS',
  PaymentAchRemittance001 = 'PAYMENT_ACH_REMITTANCE001',
  PaymentAchRemittance002 = 'PAYMENT_ACH_REMITTANCE002',
  PaymentAchRemittance004 = 'PAYMENT_ACH_REMITTANCE004',
  PaymentAchRemittance009 = 'PAYMENT_ACH_REMITTANCE009',
  PaymentCsv = 'PAYMENT_CSV',
  PetOfTheDay = 'PET_OF_THE_DAY',
  Pft = 'PFT',
  StationInvoices = 'STATION_INVOICES',
  Temp = 'TEMP',
  UserProfileImages = 'USER_PROFILE_IMAGES',
  WebsiteHosting = 'WEBSITE_HOSTING'
}

export enum BlockedReason {
  NoCostMetrics = 'NO_COST_METRICS',
  NoRevenueMetrics = 'NO_REVENUE_METRICS',
  Unspecified = 'UNSPECIFIED'
}

export type Booking = {
  __typename?: 'Booking';
  autoSchedules?: Maybe<Array<Maybe<AutoSchedule>>>;
  bookingId: Scalars['Int'];
  bookingOrder?: Maybe<BookingOrder>;
  bookingOrderId?: Maybe<Scalars['Int']>;
  brokerVendor?: Maybe<Vendor>;
  brokerVendorId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  contractFileReference?: Maybe<FileReference>;
  contractFileReferenceId?: Maybe<Scalars['Int']>;
  contractTypeLookup?: Maybe<Lookup>;
  contractTypeLookupId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  externalNotes?: Maybe<Scalars['String']>;
  fileImport?: Maybe<FileImport>;
  fileImportId?: Maybe<Scalars['Int']>;
  hasBeenEmailed: Scalars['Boolean'];
  internalNotes?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
};

export type BookingFilterInput = {
  and?: InputMaybe<Array<BookingFilterInput>>;
  autoSchedules?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  bookingId?: InputMaybe<IntOperationFilterInput>;
  bookingOrder?: InputMaybe<BookingOrderFilterInput>;
  bookingOrderId?: InputMaybe<IntOperationFilterInput>;
  brokerVendor?: InputMaybe<VendorFilterInput>;
  brokerVendorId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  contractFileReference?: InputMaybe<FileReferenceFilterInput>;
  contractFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  contractTypeLookup?: InputMaybe<LookupFilterInput>;
  contractTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  externalNotes?: InputMaybe<StringOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  hasBeenEmailed?: InputMaybe<BooleanOperationFilterInput>;
  internalNotes?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BookingFilterInput>>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
};

export type BookingOrder = {
  __typename?: 'BookingOrder';
  bookingOrderId: Scalars['Int'];
  bookings?: Maybe<Array<Maybe<Booking>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  confirmationFileReference?: Maybe<FileReference>;
  confirmationFileReferenceId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  originalFileReference?: Maybe<FileReference>;
  originalFileReferenceId?: Maybe<Scalars['Int']>;
};

export type BookingOrderFilterInput = {
  and?: InputMaybe<Array<BookingOrderFilterInput>>;
  bookingOrderId?: InputMaybe<IntOperationFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  confirmationFileReference?: InputMaybe<FileReferenceFilterInput>;
  confirmationFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<BookingOrderFilterInput>>;
  originalFileReference?: InputMaybe<FileReferenceFilterInput>;
  originalFileReferenceId?: InputMaybe<IntOperationFilterInput>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type BrokerMediaType = {
  __typename?: 'BrokerMediaType';
  axtypeLookup?: Maybe<Lookup>;
  axtypeLookupId: Scalars['Int'];
  axvendorCode?: Maybe<Scalars['String']>;
  brokerMediaTypeId: Scalars['Int'];
  brokerVendor?: Maybe<Vendor>;
  brokerVendorId: Scalars['Int'];
  commission?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  providesInvoiceNumber: Scalars['Boolean'];
  useGross: Scalars['Boolean'];
};

export type BrokerMediaTypeFilterInput = {
  and?: InputMaybe<Array<BrokerMediaTypeFilterInput>>;
  axtypeLookup?: InputMaybe<LookupFilterInput>;
  axtypeLookupId?: InputMaybe<IntOperationFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  brokerMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  brokerVendor?: InputMaybe<VendorFilterInput>;
  brokerVendorId?: InputMaybe<IntOperationFilterInput>;
  commission?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<BrokerMediaTypeFilterInput>>;
  providesInvoiceNumber?: InputMaybe<BooleanOperationFilterInput>;
  useGross?: InputMaybe<BooleanOperationFilterInput>;
};

export type Budget = {
  __typename?: 'Budget';
  amount?: Maybe<Scalars['Decimal']>;
  amountBilled?: Maybe<Scalars['Decimal']>;
  amountPaid?: Maybe<Scalars['Decimal']>;
  amountPlanned?: Maybe<Scalars['Decimal']>;
  beginDate: Scalars['Date'];
  budgetId: Scalars['Int'];
  budgetRegionTypeSplits?: Maybe<Array<Maybe<BudgetRegionTypeSplit>>>;
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Decimal']>;
};

export type BudgetAllocation = {
  __typename?: 'BudgetAllocation';
  amount: Scalars['Decimal'];
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationFlights?: Maybe<Array<Maybe<BudgetAllocationFlight>>>;
  budgetAllocationId: Scalars['Int'];
  budgetAllocationTags?: Maybe<Array<Maybe<BudgetAllocationTag>>>;
  buyTypeLookup?: Maybe<Lookup>;
  buyTypeLookupId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costMethodLookup?: Maybe<Lookup>;
  costMethodLookupId?: Maybe<Scalars['Int']>;
  costPerUnit?: Maybe<Scalars['Decimal']>;
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  demandSidePlatformMediaOutlet?: Maybe<MediaOutlet>;
  demandSidePlatformMediaOutletId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaOutletOffering?: Maybe<MediaOutletOffering>;
  mediaOutletOfferingId?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['Int']>;
  productAudience?: Maybe<ProductAudience>;
  productAudienceId?: Maybe<Scalars['Int']>;
  programmaticExecutionLookup?: Maybe<Lookup>;
  programmaticExecutionLookupId?: Maybe<Scalars['Int']>;
  strategyLookup?: Maybe<Lookup>;
  strategyLookupId?: Maybe<Scalars['Int']>;
  tacticLookup?: Maybe<Lookup>;
  tacticLookupId?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
};

export type BudgetAllocationFee = {
  __typename?: 'BudgetAllocationFee';
  budgetAllocation?: Maybe<BudgetAllocation>;
  budgetAllocationFeeId: Scalars['Int'];
  budgetAllocationId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costMethodLookup?: Maybe<Lookup>;
  costMethodLookupId: Scalars['Int'];
  costPerUnit: Scalars['Decimal'];
  isProgrammatic: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  vendorMediaOutlet?: Maybe<MediaOutlet>;
  vendorMediaOutletId: Scalars['Int'];
};

export type BudgetAllocationFeeFilterInput = {
  and?: InputMaybe<Array<BudgetAllocationFeeFilterInput>>;
  budgetAllocation?: InputMaybe<BudgetAllocationFilterInput>;
  budgetAllocationFeeId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costMethodLookup?: InputMaybe<LookupFilterInput>;
  costMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  costPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  isProgrammatic?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<BudgetAllocationFeeFilterInput>>;
  vendorMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  vendorMediaOutletId?: InputMaybe<IntOperationFilterInput>;
};

export type BudgetAllocationFeeInput = {
  budgetAllocationFeeId?: InputMaybe<Scalars['Int']>;
  budgetAllocationId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  costMethodLookupId?: InputMaybe<Scalars['Int']>;
  costPerUnit?: InputMaybe<Scalars['Decimal']>;
  isProgrammatic?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  vendorMediaOutletId?: InputMaybe<Scalars['Int']>;
};

export type BudgetAllocationFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<BudgetAllocationFilterInput>>;
  budgetAllocationFees?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFeeFilterInput>;
  budgetAllocationFlights?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFlightFilterInput>;
  budgetAllocationId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationTags?: InputMaybe<ListFilterInputTypeOfBudgetAllocationTagFilterInput>;
  buyTypeLookup?: InputMaybe<LookupFilterInput>;
  buyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costMethodLookup?: InputMaybe<LookupFilterInput>;
  costMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  costPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  demandSidePlatformMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  demandSidePlatformMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletOffering?: InputMaybe<MediaOutletOfferingFilterInput>;
  mediaOutletOfferingId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BudgetAllocationFilterInput>>;
  package?: InputMaybe<PackageFilterInput>;
  packageId?: InputMaybe<IntOperationFilterInput>;
  productAudience?: InputMaybe<ProductAudienceFilterInput>;
  productAudienceId?: InputMaybe<IntOperationFilterInput>;
  programmaticExecutionLookup?: InputMaybe<LookupFilterInput>;
  programmaticExecutionLookupId?: InputMaybe<IntOperationFilterInput>;
  strategyLookup?: InputMaybe<LookupFilterInput>;
  strategyLookupId?: InputMaybe<IntOperationFilterInput>;
  tacticLookup?: InputMaybe<LookupFilterInput>;
  tacticLookupId?: InputMaybe<IntOperationFilterInput>;
  units?: InputMaybe<IntOperationFilterInput>;
};

export type BudgetAllocationFlight = {
  __typename?: 'BudgetAllocationFlight';
  amount: Scalars['Decimal'];
  budgetAllocation?: Maybe<BudgetAllocation>;
  budgetAllocationFlightId: Scalars['Int'];
  budgetAllocationId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDate: Scalars['Date'];
  flightDateGranularityLookup?: Maybe<Lookup>;
  flightDateGranularityLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type BudgetAllocationFlightFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<BudgetAllocationFlightFilterInput>>;
  budgetAllocation?: InputMaybe<BudgetAllocationFilterInput>;
  budgetAllocationFlightId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  flightDateGranularityLookup?: InputMaybe<LookupFilterInput>;
  flightDateGranularityLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BudgetAllocationFlightFilterInput>>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type BudgetAllocationFlightInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  budgetAllocationFlightId?: InputMaybe<Scalars['Int']>;
  budgetAllocationId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  flightDateGranularityLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  notes?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type BudgetAllocationInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  budgetAllocationFees?: InputMaybe<Array<InputMaybe<BudgetAllocationFeeInput>>>;
  budgetAllocationFlights?: InputMaybe<Array<InputMaybe<BudgetAllocationFlightInput>>>;
  budgetAllocationId?: InputMaybe<Scalars['Int']>;
  budgetAllocationTags?: InputMaybe<Array<InputMaybe<BudgetAllocationTagInput>>>;
  buyTypeLookupId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  costMethodLookupId?: InputMaybe<Scalars['Int']>;
  costPerUnit?: InputMaybe<Scalars['Decimal']>;
  crossChannelCampaignVersionId?: InputMaybe<Scalars['Int']>;
  demandSidePlatformMediaOutletId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  mediaOutletOfferingId?: InputMaybe<Scalars['Int']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['Int']>;
  productAudienceId?: InputMaybe<Scalars['Int']>;
  programmaticExecutionLookupId?: InputMaybe<Scalars['Int']>;
  strategyLookupId?: InputMaybe<Scalars['Int']>;
  tacticLookupId?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['Int']>;
};

export type BudgetAllocationModel = {
  __typename?: 'BudgetAllocationModel';
  amount: Scalars['Decimal'];
  areFeesIncludedInBudget: Scalars['Boolean'];
  audience?: Maybe<ProductAudience_ForBudgetAllocation>;
  availableBudget: Scalars['Decimal'];
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationFlights?: Maybe<Array<Maybe<BudgetAllocationFlight>>>;
  budgetAllocationId: Scalars['Int'];
  budgetAllocationTags?: Maybe<Array<Maybe<BudgetAllocationTag>>>;
  buyType?: Maybe<Lookup_ForBudgetAllocation>;
  buyTypeLookup?: Maybe<Lookup>;
  buyTypeLookupId?: Maybe<Scalars['Int']>;
  channel?: Maybe<MediaType_ForBudgetAllocation>;
  clientCommissions?: Maybe<Array<Commission>>;
  clientNetAmount: Scalars['Decimal'];
  commissionAmount: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costMethod?: Maybe<Lookup_ForBudgetAllocation>;
  costMethodLookup?: Maybe<Lookup>;
  costMethodLookupId?: Maybe<Scalars['Int']>;
  costPerUnit?: Maybe<Scalars['Decimal']>;
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  demandSidePlatformMediaOutlet?: Maybe<MediaOutlet>;
  demandSidePlatformMediaOutletId?: Maybe<Scalars['Int']>;
  feeRate: Scalars['Decimal'];
  fees?: Maybe<Array<CrossChannelCampaignFee_ForBudgetAllocation>>;
  flatFees: Scalars['Decimal'];
  id: Scalars['Int'];
  isCommissionIncludedInBudget: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOffering?: Maybe<MediaOutletOffering_ForBudgetAllocation>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaOutletIsProgrammatic: Scalars['Boolean'];
  mediaOutletOffering?: Maybe<MediaOutletOffering>;
  mediaOutletOfferingId?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  netAmount: Scalars['Decimal'];
  netCommissionRate: Scalars['Decimal'];
  notes?: Maybe<Scalars['String']>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productAudience?: Maybe<ProductAudience>;
  productAudienceId?: Maybe<Scalars['Int']>;
  programmaticCommissionExists: Scalars['Boolean'];
  programmaticExecution?: Maybe<Lookup_ForBudgetAllocation>;
  programmaticExecutionLookup?: Maybe<Lookup>;
  programmaticExecutionLookupId?: Maybe<Scalars['Int']>;
  publisher?: Maybe<MediaOutlet_ForBudgetAllocation>;
  rateBasedFees: Scalars['Decimal'];
  startDate: Scalars['Date'];
  strategy?: Maybe<Lookup_ForBudgetAllocation>;
  strategyLookup?: Maybe<Lookup>;
  strategyLookupId?: Maybe<Scalars['Int']>;
  tactic?: Maybe<Lookup_ForBudgetAllocation>;
  tacticLookup?: Maybe<Lookup>;
  tacticLookupId?: Maybe<Scalars['Int']>;
  totalCost: Scalars['Decimal'];
  totalFees: Scalars['Decimal'];
  units?: Maybe<Scalars['Int']>;
};

export type BudgetAllocationModelFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<BudgetAllocationModelFilterInput>>;
  areFeesIncludedInBudget?: InputMaybe<BooleanOperationFilterInput>;
  audience?: InputMaybe<ProductAudience_ForBudgetAllocationFilterInput>;
  availableBudget?: InputMaybe<DecimalOperationFilterInput>;
  budgetAllocationFees?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFeeFilterInput>;
  budgetAllocationFlights?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFlightFilterInput>;
  budgetAllocationId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationTags?: InputMaybe<ListFilterInputTypeOfBudgetAllocationTagFilterInput>;
  buyType?: InputMaybe<Lookup_ForBudgetAllocationFilterInput>;
  buyTypeLookup?: InputMaybe<LookupFilterInput>;
  buyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  channel?: InputMaybe<MediaType_ForBudgetAllocationFilterInput>;
  clientCommissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  clientNetAmount?: InputMaybe<DecimalOperationFilterInput>;
  commissionAmount?: InputMaybe<DecimalOperationFilterInput>;
  commissionRate?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costMethod?: InputMaybe<Lookup_ForBudgetAllocationFilterInput>;
  costMethodLookup?: InputMaybe<LookupFilterInput>;
  costMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  costPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  demandSidePlatformMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  demandSidePlatformMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  feeRate?: InputMaybe<DecimalOperationFilterInput>;
  fees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFee_ForBudgetAllocationFilterInput>;
  flatFees?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isCommissionIncludedInBudget?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOffering?: InputMaybe<MediaOutletOffering_ForBudgetAllocationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletIsProgrammatic?: InputMaybe<BooleanOperationFilterInput>;
  mediaOutletOffering?: InputMaybe<MediaOutletOfferingFilterInput>;
  mediaOutletOfferingId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  netAmount?: InputMaybe<DecimalOperationFilterInput>;
  netCommissionRate?: InputMaybe<DecimalOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BudgetAllocationModelFilterInput>>;
  package?: InputMaybe<PackageFilterInput>;
  packageId?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productAudience?: InputMaybe<ProductAudienceFilterInput>;
  productAudienceId?: InputMaybe<IntOperationFilterInput>;
  programmaticCommissionExists?: InputMaybe<BooleanOperationFilterInput>;
  programmaticExecution?: InputMaybe<Lookup_ForBudgetAllocationFilterInput>;
  programmaticExecutionLookup?: InputMaybe<LookupFilterInput>;
  programmaticExecutionLookupId?: InputMaybe<IntOperationFilterInput>;
  publisher?: InputMaybe<MediaOutlet_ForBudgetAllocationFilterInput>;
  rateBasedFees?: InputMaybe<DecimalOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  strategy?: InputMaybe<Lookup_ForBudgetAllocationFilterInput>;
  strategyLookup?: InputMaybe<LookupFilterInput>;
  strategyLookupId?: InputMaybe<IntOperationFilterInput>;
  tactic?: InputMaybe<Lookup_ForBudgetAllocationFilterInput>;
  tacticLookup?: InputMaybe<LookupFilterInput>;
  tacticLookupId?: InputMaybe<IntOperationFilterInput>;
  totalCost?: InputMaybe<DecimalOperationFilterInput>;
  totalFees?: InputMaybe<DecimalOperationFilterInput>;
  units?: InputMaybe<IntOperationFilterInput>;
};

export type BudgetAllocationTag = {
  __typename?: 'BudgetAllocationTag';
  budgetAllocation?: Maybe<BudgetAllocation>;
  budgetAllocationId: Scalars['Int'];
  budgetAllocationTagId: Scalars['Int'];
  budgetAllocationTagLookup?: Maybe<Lookup>;
  budgetAllocationTagLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type BudgetAllocationTagFilterInput = {
  and?: InputMaybe<Array<BudgetAllocationTagFilterInput>>;
  budgetAllocation?: InputMaybe<BudgetAllocationFilterInput>;
  budgetAllocationId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationTagId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationTagLookup?: InputMaybe<LookupFilterInput>;
  budgetAllocationTagLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<BudgetAllocationTagFilterInput>>;
};

export type BudgetAllocationTagInput = {
  budgetAllocationId?: InputMaybe<Scalars['Int']>;
  budgetAllocationTagId?: InputMaybe<Scalars['Int']>;
  budgetAllocationTagLookupId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
};

export type BudgetBuyerSplit = {
  __typename?: 'BudgetBuyerSplit';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  budgetBuyerSplitId: Scalars['Int'];
  buyerBudgetRegionTypeLookup?: Maybe<Lookup>;
  buyerBudgetRegionTypeLookupId: Scalars['Int'];
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldcampaignid?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  percent: Scalars['Decimal'];
};

export type BudgetBuyerSplitFilterInput = {
  and?: InputMaybe<Array<BudgetBuyerSplitFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  budgetBuyerSplitId?: InputMaybe<IntOperationFilterInput>;
  buyerBudgetRegionTypeLookup?: InputMaybe<LookupFilterInput>;
  buyerBudgetRegionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldcampaignid?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<BudgetBuyerSplitFilterInput>>;
  percent?: InputMaybe<DecimalOperationFilterInput>;
};

export type BudgetFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  amountBilled?: InputMaybe<DecimalOperationFilterInput>;
  amountPaid?: InputMaybe<DecimalOperationFilterInput>;
  amountPlanned?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<BudgetFilterInput>>;
  beginDate?: InputMaybe<DateOperationFilterInput>;
  budgetId?: InputMaybe<IntOperationFilterInput>;
  budgetRegionTypeSplits?: InputMaybe<ListFilterInputTypeOfBudgetRegionTypeSplitFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BudgetFilterInput>>;
  rating?: InputMaybe<DecimalOperationFilterInput>;
};

export type BudgetForClientBilling = {
  __typename?: 'BudgetForClientBilling';
  anyPropertyValuesChanged: Scalars['Boolean'];
  axClientCode?: Maybe<Scalars['String']>;
  axDepartment?: Maybe<Scalars['String']>;
  axMonth?: Maybe<Scalars['String']>;
  axProjectCode?: Maybe<Scalars['String']>;
  buyType?: Maybe<BuyType>;
  buyTypeCategory?: Maybe<BuyTypeCategory>;
  buyTypeLookupID?: Maybe<Scalars['Int']>;
  clientNetBudget?: Maybe<Scalars['Decimal']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractTermID?: Maybe<Scalars['Int']>;
  dataAreaID?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isClientPaying?: Maybe<Scalars['Boolean']>;
  isFee?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  monthDefinitionLookupDisplay?: Maybe<Scalars['String']>;
  monthDefinitionLookupID?: Maybe<Scalars['Int']>;
  netBudget?: Maybe<Scalars['Decimal']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  propertyValueHasChanged: Scalars['Boolean'];
};


export type BudgetForClientBillingPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type BudgetRegionTypeSplit = {
  __typename?: 'BudgetRegionTypeSplit';
  budget?: Maybe<Budget>;
  budgetId?: Maybe<Scalars['Int']>;
  budgetRegionTypeSplitId: Scalars['Int'];
  buyerBudgetRegionTypeLookup?: Maybe<Lookup>;
  buyerBudgetRegionTypeLookupId: Scalars['Int'];
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  percent: Scalars['Decimal'];
};

export type BudgetRegionTypeSplitFilterInput = {
  and?: InputMaybe<Array<BudgetRegionTypeSplitFilterInput>>;
  budget?: InputMaybe<BudgetFilterInput>;
  budgetId?: InputMaybe<IntOperationFilterInput>;
  budgetRegionTypeSplitId?: InputMaybe<IntOperationFilterInput>;
  buyerBudgetRegionTypeLookup?: InputMaybe<LookupFilterInput>;
  buyerBudgetRegionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BudgetRegionTypeSplitFilterInput>>;
  percent?: InputMaybe<DecimalOperationFilterInput>;
};

export enum BuyType {
  Commission = 'COMMISSION',
  Cpm = 'CPM',
  Pi = 'PI',
  Risk = 'RISK'
}

export enum BuyTypeCategory {
  Commission = 'COMMISSION',
  Performance = 'PERFORMANCE'
}

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']>;
  gt?: InputMaybe<Scalars['Byte']>;
  gte?: InputMaybe<Scalars['Byte']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Byte']>>>;
  lt?: InputMaybe<Scalars['Byte']>;
  lte?: InputMaybe<Scalars['Byte']>;
  neq?: InputMaybe<Scalars['Byte']>;
  ngt?: InputMaybe<Scalars['Byte']>;
  ngte?: InputMaybe<Scalars['Byte']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Byte']>>>;
  nlt?: InputMaybe<Scalars['Byte']>;
  nlte?: InputMaybe<Scalars['Byte']>;
};

export enum CabGroupingType {
  Month = 'MONTH',
  Project = 'PROJECT'
}

export type CabIncludedProjectDetails = {
  __typename?: 'CABIncludedProjectDetails';
  projects: Array<CabProject>;
};

export type CabProject = {
  __typename?: 'CABProject';
  projId: Scalars['String'];
  projectName: Scalars['String'];
  reasonIncluded: Scalars['String'];
  subProjects: Array<CabProject>;
};

export type CabReconciliationLine = {
  __typename?: 'CABReconciliationLine';
  cabDefinitionId: Scalars['Int'];
  cabName: Scalars['String'];
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  d365Spend: Scalars['Decimal'];
  dataArea: DataArea;
  dataAreaId: Scalars['String'];
  dataAreaName: Scalars['String'];
  dataAreaValue: Scalars['String'];
  deferredRevenueAmount: Scalars['Decimal'];
  deferredRevenueTransactionAmount: Scalars['Decimal'];
  departmentDim: AxDepartmentDim;
  departmentName: Scalars['String'];
  departmentValue: Scalars['String'];
  id: Scalars['String'];
  invoicedAmount: Scalars['Decimal'];
  ledgerSpend: Scalars['Decimal'];
  mediaMonthDim: AxMediaMonth;
  mediaMonthName: Scalars['String'];
  mediaMonthValue: Scalars['String'];
  projectDim: AxProjectDim;
  projectGroup: Scalars['String'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  recievedAmount: Scalars['Decimal'];
  simpleDepartmentCode: Scalars['String'];
  simpleDepartmentName: Scalars['String'];
  trackerGrossSpend: Scalars['Decimal'];
  trackerNetSpend: Scalars['Decimal'];
  trackerNonClientPaySpend: Scalars['Decimal'];
  trackerSpend: Scalars['Decimal'];
};

export enum CcpBillingCadence {
  Monthly = 'MONTHLY',
  Unknown = 'UNKNOWN',
  Weekly = 'WEEKLY'
}

export enum CcpBillingType {
  PostPay = 'POST_PAY',
  Prepay = 'PREPAY',
  Unknown = 'UNKNOWN'
}

export type CcpException = {
  __typename?: 'CCPException';
  amount: Scalars['Decimal'];
  companyName: Scalars['String'];
  count: Scalars['Int'];
  dataAreaId: Scalars['String'];
  description: Scalars['String'];
};

export type CcpLine = {
  __typename?: 'CCPLine';
  brandDim: AxBrandDim;
  brandName: Scalars['String'];
  brandValue: Scalars['String'];
  ccpMonths: Array<CcpMonth>;
  departmentDim: AxDepartmentDim;
  departmentName: Scalars['String'];
  departmentValue: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated Is Prepaid sort of replaces this. But Is Client payed is not really in the new paradigm */
  isClientPaying: Scalars['Boolean'];
  isPrePaid: Scalars['Boolean'];
  monthDefinition: Scalars['String'];
  productDim: AxProductDim;
  productName: Scalars['String'];
  productValue: Scalars['String'];
  simpleDepartmentCode: Scalars['String'];
  simpleDepartmentName: Scalars['String'];
};

export type CcpMonth = {
  __typename?: 'CCPMonth';
  activeTermId: Scalars['Int'];
  billed: Scalars['Decimal'];
  budget: Scalars['Decimal'];
  cabReportName: Scalars['String'];
  cbsReportDefinitionId: Scalars['Int'];
  ccpPeriodRelativeName: Scalars['String'];
  cleared: Scalars['Decimal'];
  dateMonthIsInScope: Scalars['DateTime'];
  isClientPaying: Scalars['Boolean'];
  monthName: Scalars['String'];
  monthType: CcpMonthType;
  paid: Scalars['Decimal'];
  simpleAxMonthValue: Scalars['String'];
};

export enum CcpMonthType {
  CurrentCompleted = 'CURRENT_COMPLETED',
  CurrentInProgress = 'CURRENT_IN_PROGRESS',
  Future = 'FUTURE',
  FutureInReachOfTerms = 'FUTURE_IN_REACH_OF_TERMS',
  Previous = 'PREVIOUS'
}

export type CcpReport = {
  __typename?: 'CCPReport';
  balance: Scalars['Decimal'];
  billingCompanyId: Scalars['Int'];
  billingCompanyName: Scalars['String'];
  cabBalanceMismatch: CabBalanceMismatch;
  cashOnHand: Scalars['Decimal'];
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  exceptions: Array<CcpException>;
  id: Scalars['String'];
  lines: Array<CcpLine>;
  previousBalanceDetails: Array<PreviousBalanceDetail>;
  termDetails: Array<TermDetails>;
  usePIClientFloatBudgets: Scalars['Boolean'];
};

export type CabBalanceMismatch = {
  __typename?: 'CabBalanceMismatch';
  clientCode: Scalars['String'];
  currentCabOpeningBalance: Scalars['Decimal'];
  currentCabReports: Array<BasicCabReportBalanceInfo>;
  currentYearName: Scalars['String'];
  multiYearCabReports: Array<BasicCabReportBalanceInfo>;
  priorCabEndingBalance: Scalars['Decimal'];
  priorCabReports: Array<BasicCabReportBalanceInfo>;
  priorYearName: Scalars['String'];
  unknownCabs: Array<BasicCabReportBalanceInfo>;
  variance: Scalars['Decimal'];
};

export enum CacheGroupType {
  AccountBalance = 'ACCOUNT_BALANCE',
  AccountBoard = 'ACCOUNT_BOARD',
  ActionItemBackendInfo = 'ACTION_ITEM_BACKEND_INFO',
  Calendars = 'CALENDARS',
  Client = 'CLIENT',
  CubeSecurity = 'CUBE_SECURITY',
  D365Client = 'D365_CLIENT',
  D365Data = 'D365_DATA',
  Dimensions = 'DIMENSIONS',
  FinopsBackendData = 'FINOPS_BACKEND_DATA',
  FinopsJournalEntry = 'FINOPS_JOURNAL_ENTRY',
  FinopsPeople = 'FINOPS_PEOPLE',
  FinopsVendorInfo = 'FINOPS_VENDOR_INFO',
  Holidays = 'HOLIDAYS',
  Impersonation = 'IMPERSONATION',
  Permissions = 'PERMISSIONS',
  Project = 'PROJECT',
  ProjectFinanceTool = 'PROJECT_FINANCE_TOOL',
  RainOnePeople = 'RAIN_ONE_PEOPLE',
  Ratecard = 'RATECARD',
  RequiresIndividualClearing = 'REQUIRES_INDIVIDUAL_CLEARING',
  ShortTermTrackerData = 'SHORT_TERM_TRACKER_DATA',
  Time = 'TIME',
  TimesheetValidation = 'TIMESHEET_VALIDATION',
  TrackerData = 'TRACKER_DATA',
  Vendor = 'VENDOR'
}

export type Campaign = {
  __typename?: 'Campaign';
  adCampaigns?: Maybe<Array<Maybe<AdCampaign>>>;
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  appliesToId?: Maybe<Scalars['Int']>;
  appliesToLookup?: Maybe<Lookup>;
  appliesToLookupId: Scalars['Int'];
  autoSchedules?: Maybe<Array<Maybe<AutoSchedule>>>;
  availProposalDetails?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  axclientCode?: Maybe<Scalars['String']>;
  axprojectCode?: Maybe<Scalars['String']>;
  axtypeLookup?: Maybe<Lookup>;
  axtypeLookupId: Scalars['Int'];
  billableDragDays?: Maybe<Scalars['Int']>;
  budgetBuyerSplits?: Maybe<Array<Maybe<BudgetBuyerSplit>>>;
  budgetEnforcementLookup?: Maybe<Lookup>;
  budgetEnforcementLookupId: Scalars['Int'];
  budgetRegionTypeSplits?: Maybe<Array<Maybe<BudgetRegionTypeSplit>>>;
  budgets?: Maybe<Array<Maybe<Budget>>>;
  campaignCrossChannelCampaigns?: Maybe<Array<Maybe<CampaignCrossChannelCampaign>>>;
  campaignGroup?: Maybe<CampaignGroup>;
  campaignGroupId?: Maybe<Scalars['Int']>;
  campaignId: Scalars['Int'];
  campaignLocalBudgetAssignments?: Maybe<Array<Maybe<CampaignLocalBudgetAssignment>>>;
  campaignMediaOutlets?: Maybe<Array<Maybe<CampaignMediaOutlet>>>;
  campaignProjectCodes?: Maybe<Array<Maybe<CampaignProjectCode>>>;
  clientApprovalRequirementLookup?: Maybe<Lookup>;
  clientApprovalRequirementLookupId: Scalars['Int'];
  clientApprovals?: Maybe<Array<Maybe<ClientApproval>>>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costPerLeadRanges?: Maybe<Array<Maybe<CostPerLeadRange>>>;
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  crossChannelCampaignId?: Maybe<Scalars['Int']>;
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  discloseMedia: Scalars['Boolean'];
  dmainfoBudgets?: Maybe<Array<Maybe<DmainfoBudget>>>;
  inactive: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  isNonCmedia: Scalars['Boolean'];
  isPi: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  oldnaturalkey?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']>;
  requiresTrafficApproval: Scalars['Boolean'];
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  targets?: Maybe<Array<Maybe<Target>>>;
};

export type CampaignCrossChannelCampaign = {
  __typename?: 'CampaignCrossChannelCampaign';
  campaign?: Maybe<Campaign>;
  campaignCrossChannelCampaignId: Scalars['Int'];
  campaignId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  crossChannelCampaignId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  share: Scalars['Decimal'];
};

export type CampaignCrossChannelCampaignFilterInput = {
  and?: InputMaybe<Array<CampaignCrossChannelCampaignFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignCrossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CampaignCrossChannelCampaignFilterInput>>;
  share?: InputMaybe<DecimalOperationFilterInput>;
};

export type CampaignCrossChannelCampaignInput = {
  campaignCrossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  share?: InputMaybe<Scalars['Decimal']>;
};

export type CampaignCrossChannelCampaign_ForLookup = {
  __typename?: 'CampaignCrossChannelCampaign_ForLookup';
  campaign?: Maybe<Campaign>;
  campaignCrossChannelCampaignId: Scalars['Int'];
  campaignId: Scalars['Int'];
  campaignName: Scalars['String'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  crossChannelCampaignId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaTypeDisplay: Scalars['String'];
  mediaTypeId: Scalars['Int'];
  share: Scalars['Decimal'];
};

export type CampaignDimension = {
  __typename?: 'CampaignDimension';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<CampaignDimensionOption>>;
};

export type CampaignDimensionChild = {
  __typename?: 'CampaignDimensionChild';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type CampaignDimensionOption = {
  __typename?: 'CampaignDimensionOption';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  validChildren?: Maybe<CampaignDimensionValidChildren>;
};

export type CampaignDimensionValidChildren = {
  __typename?: 'CampaignDimensionValidChildren';
  children?: Maybe<Array<CampaignDimensionChild>>;
  dimension?: Maybe<Scalars['String']>;
};

export type CampaignFilterInput = {
  adCampaigns?: InputMaybe<ListFilterInputTypeOfAdCampaignFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  and?: InputMaybe<Array<CampaignFilterInput>>;
  appliesToId?: InputMaybe<IntOperationFilterInput>;
  appliesToLookup?: InputMaybe<LookupFilterInput>;
  appliesToLookupId?: InputMaybe<IntOperationFilterInput>;
  autoSchedules?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  availProposalDetails?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  axclientCode?: InputMaybe<StringOperationFilterInput>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  axtypeLookup?: InputMaybe<LookupFilterInput>;
  axtypeLookupId?: InputMaybe<IntOperationFilterInput>;
  billableDragDays?: InputMaybe<IntOperationFilterInput>;
  budgetBuyerSplits?: InputMaybe<ListFilterInputTypeOfBudgetBuyerSplitFilterInput>;
  budgetEnforcementLookup?: InputMaybe<LookupFilterInput>;
  budgetEnforcementLookupId?: InputMaybe<IntOperationFilterInput>;
  budgetRegionTypeSplits?: InputMaybe<ListFilterInputTypeOfBudgetRegionTypeSplitFilterInput>;
  budgets?: InputMaybe<ListFilterInputTypeOfBudgetFilterInput>;
  campaignCrossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCampaignCrossChannelCampaignFilterInput>;
  campaignGroup?: InputMaybe<CampaignGroupFilterInput>;
  campaignGroupId?: InputMaybe<IntOperationFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  campaignLocalBudgetAssignments?: InputMaybe<ListFilterInputTypeOfCampaignLocalBudgetAssignmentFilterInput>;
  campaignMediaOutlets?: InputMaybe<ListFilterInputTypeOfCampaignMediaOutletFilterInput>;
  campaignProjectCodes?: InputMaybe<ListFilterInputTypeOfCampaignProjectCodeFilterInput>;
  clientApprovalRequirementLookup?: InputMaybe<LookupFilterInput>;
  clientApprovalRequirementLookupId?: InputMaybe<IntOperationFilterInput>;
  clientApprovals?: InputMaybe<ListFilterInputTypeOfClientApprovalFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costPerLeadRanges?: InputMaybe<ListFilterInputTypeOfCostPerLeadRangeFilterInput>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  discloseMedia?: InputMaybe<BooleanOperationFilterInput>;
  dmainfoBudgets?: InputMaybe<ListFilterInputTypeOfDmainfoBudgetFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isNonCmedia?: InputMaybe<BooleanOperationFilterInput>;
  isPi?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaPlans?: InputMaybe<ListFilterInputTypeOfMediaPlanFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  oldnaturalkey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CampaignFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  requiresTrafficApproval?: InputMaybe<BooleanOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  targets?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
};

export type CampaignForBudgetAllocation = {
  __typename?: 'CampaignForBudgetAllocation';
  areFeesIncludedInBudget: Scalars['Boolean'];
  averageCpmsByPublisherByChannel?: Maybe<Array<AverageCpmByPublisherByChannel>>;
  axProjectCode?: Maybe<Scalars['String']>;
  budgetAllocations?: Maybe<Array<BudgetAllocationModel>>;
  campaignManagerCampaignId?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientCommissions?: Maybe<Array<Commission>>;
  clientVendorDirectPayments?: Maybe<Array<VendorDirectPayment>>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createByAppUserDescription?: Maybe<Scalars['String']>;
  createUTC: Scalars['DateTime'];
  crossChannelCampaignGroupId?: Maybe<Scalars['Int']>;
  crossChannelCampaignGroupName?: Maybe<Scalars['String']>;
  crossChannelCampaignId: Scalars['Int'];
  crossChannelCampaignVersionId: Scalars['Int'];
  currencyCode: CurrencyCode;
  datoramaKey?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<DimensionForBudgetAllocation>>;
  endDate: Scalars['Date'];
  exchangeRate: Scalars['Decimal'];
  fees?: Maybe<Array<CrossChannelCampaignFee_ForBudgetAllocation>>;
  flightDateGranularityEnum?: Maybe<FlightDateGranularity>;
  flightDays?: Maybe<Array<FlightDay>>;
  hasApprovedMediaAuthorization: Scalars['Boolean'];
  isCommissionIncludedInBudget: Scalars['Boolean'];
  lastEditedByAppUserDescription?: Maybe<Scalars['String']>;
  lastEditedUTC?: Maybe<Scalars['DateTime']>;
  mediaAuthorizations?: Maybe<Array<MediaAuthorization_ForBudgetAllocation>>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  reallocationFlightDateGranularityLookupId?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
  regionName?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  versions?: Maybe<Array<CampaignVersion>>;
};

export type CampaignForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<CampaignForBudgetAllocationFilterInput>>;
  areFeesIncludedInBudget?: InputMaybe<BooleanOperationFilterInput>;
  averageCpmsByPublisherByChannel?: InputMaybe<ListFilterInputTypeOfAverageCpmByPublisherByChannelFilterInput>;
  axProjectCode?: InputMaybe<StringOperationFilterInput>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationModelFilterInput>;
  campaignManagerCampaignId?: InputMaybe<StringOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientCommissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  clientVendorDirectPayments?: InputMaybe<ListFilterInputTypeOfVendorDirectPaymentFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  createUTC?: InputMaybe<DateTimeOperationFilterInput>;
  crossChannelCampaignGroupId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignGroupName?: InputMaybe<StringOperationFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  currencyCode?: InputMaybe<CurrencyCodeOperationFilterInput>;
  datoramaKey?: InputMaybe<StringOperationFilterInput>;
  dimensions?: InputMaybe<ListFilterInputTypeOfDimensionForBudgetAllocationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  exchangeRate?: InputMaybe<DecimalOperationFilterInput>;
  fees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFee_ForBudgetAllocationFilterInput>;
  flightDateGranularityEnum?: InputMaybe<NullableOfFlightDateGranularityOperationFilterInput>;
  flightDays?: InputMaybe<ListFilterInputTypeOfFlightDayFilterInput>;
  hasApprovedMediaAuthorization?: InputMaybe<BooleanOperationFilterInput>;
  isCommissionIncludedInBudget?: InputMaybe<BooleanOperationFilterInput>;
  lastEditedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastEditedUTC?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAuthorizations?: InputMaybe<ListFilterInputTypeOfMediaAuthorization_ForBudgetAllocationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CampaignForBudgetAllocationFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  reallocationFlightDateGranularityLookupId?: InputMaybe<IntOperationFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  regionName?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  versions?: InputMaybe<ListFilterInputTypeOfCampaignVersionFilterInput>;
};

export type CampaignForBudgetAllocationSortInput = {
  areFeesIncludedInBudget?: InputMaybe<SortEnumType>;
  axProjectCode?: InputMaybe<SortEnumType>;
  campaignManagerCampaignId?: InputMaybe<SortEnumType>;
  client?: InputMaybe<ClientSortInput>;
  createAppUserId?: InputMaybe<SortEnumType>;
  createByAppUserDescription?: InputMaybe<SortEnumType>;
  createUTC?: InputMaybe<SortEnumType>;
  crossChannelCampaignGroupId?: InputMaybe<SortEnumType>;
  crossChannelCampaignGroupName?: InputMaybe<SortEnumType>;
  crossChannelCampaignId?: InputMaybe<SortEnumType>;
  crossChannelCampaignVersionId?: InputMaybe<SortEnumType>;
  currencyCode?: InputMaybe<SortEnumType>;
  datoramaKey?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  exchangeRate?: InputMaybe<SortEnumType>;
  flightDateGranularityEnum?: InputMaybe<SortEnumType>;
  hasApprovedMediaAuthorization?: InputMaybe<SortEnumType>;
  isCommissionIncludedInBudget?: InputMaybe<SortEnumType>;
  lastEditedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastEditedUTC?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  reallocationFlightDateGranularityLookupId?: InputMaybe<SortEnumType>;
  regionId?: InputMaybe<SortEnumType>;
  regionName?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
};

export type CampaignForLinearSpendMapping = {
  __typename?: 'CampaignForLinearSpendMapping';
  anyPropertyValuesChanged: Scalars['Boolean'];
  bookedFromDate: Scalars['DateTime'];
  bookedToDate: Scalars['DateTime'];
  campaignID: Scalars['Int'];
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  display?: Maybe<Scalars['String']>;
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaTypeID: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  propertyValueHasChanged: Scalars['Boolean'];
  spendFromDate: Scalars['DateTime'];
  spendToDate: Scalars['DateTime'];
};


export type CampaignForLinearSpendMappingPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type CampaignGroup = {
  __typename?: 'CampaignGroup';
  campaignGroupId: Scalars['Int'];
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
};

export type CampaignGroupFilterInput = {
  and?: InputMaybe<Array<CampaignGroupFilterInput>>;
  campaignGroupId?: InputMaybe<IntOperationFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CampaignGroupFilterInput>>;
};

export type CampaignGroupSortInput = {
  campaignGroupId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
};

export type CampaignLocalBudgetAssignment = {
  __typename?: 'CampaignLocalBudgetAssignment';
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  campaignLocalBudgetAssignmentDetails?: Maybe<Array<Maybe<CampaignLocalBudgetAssignmentDetail>>>;
  campaignLocalBudgetAssignmentId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  endDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type CampaignLocalBudgetAssignmentDetail = {
  __typename?: 'CampaignLocalBudgetAssignmentDetail';
  amount: Scalars['Decimal'];
  campaignLocalBudgetAssignment?: Maybe<CampaignLocalBudgetAssignment>;
  campaignLocalBudgetAssignmentDetailId: Scalars['Int'];
  campaignLocalBudgetAssignmentId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dmainfo?: Maybe<Dmainfo>;
  dmainfoId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type CampaignLocalBudgetAssignmentDetailFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<CampaignLocalBudgetAssignmentDetailFilterInput>>;
  campaignLocalBudgetAssignment?: InputMaybe<CampaignLocalBudgetAssignmentFilterInput>;
  campaignLocalBudgetAssignmentDetailId?: InputMaybe<IntOperationFilterInput>;
  campaignLocalBudgetAssignmentId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dmainfo?: InputMaybe<DmainfoFilterInput>;
  dmainfoId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CampaignLocalBudgetAssignmentDetailFilterInput>>;
};

export type CampaignLocalBudgetAssignmentFilterInput = {
  and?: InputMaybe<Array<CampaignLocalBudgetAssignmentFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  campaignLocalBudgetAssignmentDetails?: InputMaybe<ListFilterInputTypeOfCampaignLocalBudgetAssignmentDetailFilterInput>;
  campaignLocalBudgetAssignmentId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CampaignLocalBudgetAssignmentFilterInput>>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type CampaignMediaOutlet = {
  __typename?: 'CampaignMediaOutlet';
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  campaignMediaOutletId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletAlias?: Maybe<Scalars['String']>;
  mediaOutletId: Scalars['Int'];
};

export type CampaignMediaOutletFilterInput = {
  and?: InputMaybe<Array<CampaignMediaOutletFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  campaignMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletAlias?: InputMaybe<StringOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CampaignMediaOutletFilterInput>>;
};

export type CampaignProjectCode = {
  __typename?: 'CampaignProjectCode';
  axprojectCode?: Maybe<Scalars['String']>;
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  campaignProjectCodeId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type CampaignProjectCodeFilterInput = {
  and?: InputMaybe<Array<CampaignProjectCodeFilterInput>>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  campaignProjectCodeId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CampaignProjectCodeFilterInput>>;
};

export type CampaignRevision = {
  __typename?: 'CampaignRevision';
  allocationsAdded: Array<BudgetAllocation>;
  allocationsCurrentVersion: Array<BudgetAllocation>;
  allocationsDeleted: Array<BudgetAllocation>;
  allocationsPriorVersion: Array<BudgetAllocation>;
  allocationsUpdated: Array<BudgetAllocation>;
};

export type CampaignSortInput = {
  appliesToId?: InputMaybe<SortEnumType>;
  appliesToLookup?: InputMaybe<LookupSortInput>;
  appliesToLookupId?: InputMaybe<SortEnumType>;
  axclientCode?: InputMaybe<SortEnumType>;
  axprojectCode?: InputMaybe<SortEnumType>;
  axtypeLookup?: InputMaybe<LookupSortInput>;
  axtypeLookupId?: InputMaybe<SortEnumType>;
  billableDragDays?: InputMaybe<SortEnumType>;
  budgetEnforcementLookup?: InputMaybe<LookupSortInput>;
  budgetEnforcementLookupId?: InputMaybe<SortEnumType>;
  campaignGroup?: InputMaybe<CampaignGroupSortInput>;
  campaignGroupId?: InputMaybe<SortEnumType>;
  campaignId?: InputMaybe<SortEnumType>;
  clientApprovalRequirementLookup?: InputMaybe<LookupSortInput>;
  clientApprovalRequirementLookupId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignSortInput>;
  crossChannelCampaignId?: InputMaybe<SortEnumType>;
  discloseMedia?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  isDefault?: InputMaybe<SortEnumType>;
  isNonCmedia?: InputMaybe<SortEnumType>;
  isPi?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaOutlet?: InputMaybe<MediaOutletSortInput>;
  mediaOutletId?: InputMaybe<SortEnumType>;
  mediaType?: InputMaybe<MediaTypeSortInput>;
  mediaTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  oldnaturalkey?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  region?: InputMaybe<RegionSortInput>;
  regionId?: InputMaybe<SortEnumType>;
  requiresTrafficApproval?: InputMaybe<SortEnumType>;
};

export type CampaignSpendCollection = {
  __typename?: 'CampaignSpendCollection';
  cacheKey: Scalars['String'];
  crossChannelCampaignId: Scalars['Int'];
  crossChannelCampaignSpends: Array<CrossChannelCampaignSpend>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  lastUpdated?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type CampaignVersion = {
  __typename?: 'CampaignVersion';
  allocationsCount: Scalars['Int'];
  budgetAllocations?: Maybe<Array<BudgetAllocation>>;
  createAppUserId: Scalars['Int'];
  crossChannelCampaignVersionId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaTypesWithAmount?: Maybe<Array<MediaTypeWithAmount>>;
  totalBudget?: Maybe<Scalars['Decimal']>;
  versionNumber: Scalars['Int'];
};

export type CampaignVersionFilterInput = {
  allocationsCount?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<CampaignVersionFilterInput>>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaTypesWithAmount?: InputMaybe<ListFilterInputTypeOfMediaTypeWithAmountFilterInput>;
  or?: InputMaybe<Array<CampaignVersionFilterInput>>;
  totalBudget?: InputMaybe<DecimalOperationFilterInput>;
  versionNumber?: InputMaybe<IntOperationFilterInput>;
};

export type CampaignWithBookedTotal = {
  __typename?: 'CampaignWithBookedTotal';
  amount: Scalars['Decimal'];
  clientNetBooked: Scalars['Decimal'];
  crossChannelCampaignId: Scalars['Int'];
  insertionOrderDetails?: Maybe<Array<InsertionOrderDetail_ForCampaignBudgets>>;
  name?: Maybe<Scalars['String']>;
  netBooked: Scalars['Decimal'];
};

export type CancelProjectEstimateApprovalsError = ArgumentError | RainError;

export type CancelProjectEstimateApprovalsInput = {
  projectEstimateVersionId: Scalars['Int'];
};

export type CancelProjectEstimateApprovalsPayload = {
  __typename?: 'CancelProjectEstimateApprovalsPayload';
  errors?: Maybe<Array<CancelProjectEstimateApprovalsError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type CandidateParentInvoice = {
  __typename?: 'CandidateParentInvoice';
  candidateInvoiceID: Scalars['Int'];
  candidateVendorInvoiceNumber?: Maybe<Scalars['String']>;
};

export type CatchPhrase = {
  __typename?: 'CatchPhrase';
  catchPhraseId: Scalars['Int'];
  createdUTC: Scalars['DateTime'];
  phrase: Scalars['String'];
  rainOnePerson: RainOnePerson;
  rainOnePersonId: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  categoryName: Scalars['String'];
  dataAreaId: Scalars['String'];
  id: Scalars['String'];
  isValidForCurrentUser: Scalars['Boolean'];
};

export type CategoryTermGroupTypeMapping_ForDisplay = {
  __typename?: 'CategoryTermGroupTypeMapping_ForDisplay';
  categoryCode?: Maybe<Scalars['String']>;
  categoryName: Scalars['String'];
  categoryTermGroupTypeMappingId: Scalars['Int'];
  clientTransactionTermGroupType: ClientTransactionTermGroupType;
  id: Scalars['Int'];
};

export type CategoryValidationDetail = {
  __typename?: 'CategoryValidationDetail';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  isCategoryUsable: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type CbsReport = {
  __typename?: 'CbsReport';
  cabGroupingType: CabGroupingType;
  cbsReportDefinitionName: Scalars['String'];
  cbsReportDescription: Array<Scalars['String']>;
  clientCode: Scalars['String'];
  clientName: Scalars['String'];
  downloadLink: Scalars['String'];
  id: Scalars['Int'];
  includedProjects: CabIncludedProjectDetails;
  lines: Array<CbsReportLine>;
};

export type CbsReportDefinition = {
  __typename?: 'CbsReportDefinition';
  cabGroupingType: CabGroupingType;
  cbsReportDefinitionDepartmentGroupId?: Maybe<Scalars['Int']>;
  cbsReportDefinitionFilters?: Maybe<Array<Maybe<CbsReportDefinitionFilter>>>;
  cbsReportDefinitionId: Scalars['Int'];
  clientCode?: Maybe<Scalars['String']>;
  departmentGroupLinks?: Maybe<Array<Maybe<CbsReportDefinition_DepartmentGroup>>>;
  endingMediaMonthCode?: Maybe<Scalars['String']>;
  includeQuarterlySummary: Scalars['Boolean'];
  isTopLevelReport: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  nonAdminAccessable: Scalars['Boolean'];
  startingMediaMonthCode?: Maybe<Scalars['String']>;
};

export type CbsReportDefinitionDepartment = {
  __typename?: 'CbsReportDefinitionDepartment';
  cbsReportDefinitionDepartmentGroup?: Maybe<CbsReportDefinitionDepartmentGroup>;
  cbsReportDefinitionDepartmentGroupId: Scalars['Int'];
  cbsReportDefinitionDepartmentId: Scalars['Int'];
  departmentCode: Scalars['String'];
};

export type CbsReportDefinitionDepartmentGroup = {
  __typename?: 'CbsReportDefinitionDepartmentGroup';
  cbsReportDefinitionDepartmentGroupId: Scalars['Int'];
  cbsReportDefinitionGroupType: CbsReportDefinitionGroupType;
  cbsReportDefinitionLinks?: Maybe<Array<Maybe<CbsReportDefinition_DepartmentGroup>>>;
  departmentGroupName?: Maybe<Scalars['String']>;
  inScopeDepartments?: Maybe<Array<Maybe<CbsReportDefinitionDepartment>>>;
};

export type CbsReportDefinitionDepartmentGroupInput = {
  cbsReportDefinitionDepartmentGroupId: Scalars['Int'];
  departmentGroupName: Scalars['String'];
};

export type CbsReportDefinitionDepartmentInput = {
  cbsReportDefinitionDepartmentGroupId: Scalars['Int'];
  cbsReportDefinitionDepartmentId: Scalars['Int'];
  departmentCode: Scalars['String'];
};

export type CbsReportDefinitionFilter = {
  __typename?: 'CbsReportDefinitionFilter';
  cbsReportDefinition?: Maybe<CbsReportDefinition>;
  cbsReportDefinitionFilterId: Scalars['Int'];
  cbsReportDefinitionId: Scalars['Int'];
  dimensionCode?: Maybe<Scalars['String']>;
  filterType: CbsReportFilterType;
  value?: Maybe<Scalars['String']>;
};

export enum CbsReportDefinitionGroupType {
  AllMedia = 'ALL_MEDIA',
  Creative = 'CREATIVE',
  Digital = 'DIGITAL',
  Other = 'OTHER'
}

export type CbsReportDefinitionInput = {
  brandIds: Array<Scalars['String']>;
  cabGroupingType: CabGroupingType;
  cbsReportDefinitionDepartmentGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  cbsReportDefinitionId: Scalars['Int'];
  clientCode?: InputMaybe<Scalars['String']>;
  companies: Array<Scalars['String']>;
  endingMediaMonthCode?: InputMaybe<Scalars['String']>;
  includeQuarterlySummary: Scalars['Boolean'];
  isTopLevelReport: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  nonAdminAccessable: Scalars['Boolean'];
  projectGroups: Array<Scalars['String']>;
  projects: Array<Scalars['String']>;
  startingMediaMonthCode?: InputMaybe<Scalars['String']>;
};

export type CbsReportDefinition_DepartmentGroup = {
  __typename?: 'CbsReportDefinition_DepartmentGroup';
  cbsReportDefinition?: Maybe<CbsReportDefinition>;
  cbsReportDefinitionDepartmentGroup?: Maybe<CbsReportDefinitionDepartmentGroup>;
  cbsReportDefinitionDepartmentGroupId: Scalars['Int'];
  cbsReportDefinitionId: Scalars['Int'];
  cbsReportDefinition_DepartmentGroupId: Scalars['Int'];
};

export type CbsReportDefinition_ForDisplay = {
  __typename?: 'CbsReportDefinition_ForDisplay';
  cabGroupingType: CabGroupingType;
  cbsReportDefinitionDepartmentGroupId?: Maybe<Scalars['Int']>;
  cbsReportDefinitionFilters?: Maybe<Array<Maybe<CbsReportDefinitionFilter>>>;
  cbsReportDefinitionId: Scalars['Int'];
  clientCode?: Maybe<Scalars['String']>;
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  departmentGroupLinks?: Maybe<Array<Maybe<CbsReportDefinition_DepartmentGroup>>>;
  endingMediaMonth: AxMediaMonth;
  endingMediaMonthCode?: Maybe<Scalars['String']>;
  endingMediaMonthName: Scalars['String'];
  endingMediaMonthValue: Scalars['String'];
  id: Scalars['Int'];
  includeQuarterlySummary: Scalars['Boolean'];
  isTopLevelReport: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  nonAdminAccessable: Scalars['Boolean'];
  startingMediaMonth: AxMediaMonth;
  startingMediaMonthCode?: Maybe<Scalars['String']>;
  startingMediaMonthName: Scalars['String'];
  startingMediaMonthValue: Scalars['String'];
};

export type CbsReportException = {
  __typename?: 'CbsReportException';
  clientCode: Scalars['String'];
  clientName: Scalars['String'];
  duplicatedAxProjectTransactions: Array<AxProjectTransactionSummary>;
  duplicatedFinopsClientTransactions: Array<ClientTransaction_ForDisplay>;
  duplicatedTrackerClearances: Array<TrackerClearance_ForDisplay>;
  missingAxProjectTransactions: Array<AxProjectTransactionSummary>;
  missingFinopsClientTransactions: Array<ClientTransaction_ForDisplay>;
  missingTrackerClearances: Array<TrackerClearance_ForDisplay>;
  unReversedItemsWithNoMediaMonths: Array<UnReversedItemsWithNoMediaMonth>;
};

export enum CbsReportFilterType {
  Brand = 'BRAND',
  Company = 'COMPANY',
  Project = 'PROJECT',
  ProjectGroup = 'PROJECT_GROUP'
}

export type CbsReportLine = {
  __typename?: 'CbsReportLine';
  arBalance: Scalars['Decimal'];
  axClientBalance: Scalars['Decimal'];
  axNonMediaSpend: Scalars['Decimal'];
  axProjectTransactions: Array<AxProjectTransactionSummary>;
  axSpend: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  calendar: Scalars['String'];
  cashBalance: Scalars['Decimal'];
  cashBuySpend: Scalars['Decimal'];
  clientAdjustmentAmount: Scalars['Decimal'];
  clientAdjustments: Array<ClientAdjustment_ForDisplay>;
  clientBalance: Scalars['Decimal'];
  clientBalanceMonthly: Scalars['Decimal'];
  clientServicesSpend: Scalars['Decimal'];
  clientSpend: Scalars['Decimal'];
  dataArea: DataArea;
  dataAreaId: Scalars['String'];
  dataAreaName: Scalars['String'];
  dataAreaValue: Scalars['String'];
  departmentDim: AxDepartmentDim;
  departmentName: Scalars['String'];
  departmentValue: Scalars['String'];
  finopsClientTransactions: Array<ClientTransaction_ForDisplay>;
  groupingKey: Scalars['String'];
  id: Scalars['String'];
  invoiceRecievedAmount: Scalars['Decimal'];
  invoicedAmount: Scalars['Decimal'];
  isHiddenLine: Scalars['Boolean'];
  isQuarterlyTotal: Scalars['Boolean'];
  isStartingBalance: Scalars['Boolean'];
  isTotalLine: Scalars['Boolean'];
  mediaMonthDim: AxMediaMonth;
  mediaMonthName: Scalars['String'];
  mediaMonthNumber: Scalars['Int'];
  mediaMonthValue: Scalars['String'];
  order: Scalars['Int'];
  performanceSpend: Scalars['Decimal'];
  productionSpend: Scalars['Decimal'];
  projectName: Scalars['String'];
  reconciliationStatus: ReconciliationStatus;
  simpleDepartmentCode: Scalars['String'];
  simpleDepartmentName: Scalars['String'];
  trackerAxVariance: Scalars['Decimal'];
  trackerCashBuySpend: Scalars['Decimal'];
  trackerClearances: Array<TrackerClearance_ForDisplay>;
  trackerClientPayingAmount: Scalars['Decimal'];
  trackerCommission: Scalars['Decimal'];
  trackerFeeAmount: Scalars['Decimal'];
  trackerNetMedia: Scalars['Decimal'];
  trackerNetSpend: Scalars['Decimal'];
  trackerPerformanceBuySpend: Scalars['Decimal'];
  trackerPerformanceProfit: Scalars['Decimal'];
  trackerProfit: Scalars['Decimal'];
  trackerSpend: Scalars['Decimal'];
  unReconciledProjects: Array<Scalars['String']>;
};

export type ClearanceForClientBilling = {
  __typename?: 'ClearanceForClientBilling';
  anyPropertyValuesChanged: Scalars['Boolean'];
  axClientCode?: Maybe<Scalars['String']>;
  axDepartment?: Maybe<Scalars['String']>;
  axMonth?: Maybe<Scalars['String']>;
  axProjectCode?: Maybe<Scalars['String']>;
  buyType?: Maybe<BuyType>;
  buyTypeCategory?: Maybe<BuyTypeCategory>;
  buyTypeLookupID?: Maybe<Scalars['Int']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractTermID?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Decimal']>;
  dataAreaID?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isClientPaying?: Maybe<Scalars['Boolean']>;
  isFee?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  monthDefinitionLookupDisplay?: Maybe<Scalars['String']>;
  monthDefinitionLookupID?: Maybe<Scalars['Int']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  propertyValueHasChanged: Scalars['Boolean'];
  revenue?: Maybe<Scalars['Decimal']>;
};


export type ClearanceForClientBillingPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  accountTeamEmail?: Maybe<Scalars['String']>;
  accountingAppLocation?: Maybe<AppLocation>;
  accountingAppLocationId?: Maybe<Scalars['Int']>;
  accountingInvoice: Scalars['Boolean'];
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  agencyOfRecordFileReference?: Maybe<FileReference>;
  agencyOfRecordFileReferenceId?: Maybe<Scalars['Int']>;
  appLocation?: Maybe<AppLocation>;
  appLocationId: Scalars['Int'];
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  audiences?: Maybe<Array<Maybe<Audience>>>;
  availProposals?: Maybe<Array<Maybe<AvailProposal>>>;
  axcode?: Maybe<Scalars['String']>;
  bonusGroupLookup?: Maybe<Lookup>;
  bonusGroupLookupId: Scalars['Int'];
  buyerBudgetsEst: Scalars['Boolean'];
  cashInAdvanceDaysForNonSequentialLiabilityCancelableMedia?: Maybe<Scalars['Int']>;
  cashInAdvanceDaysForNonSequentialLiabilityNonCancelableMedia?: Maybe<Scalars['Int']>;
  cashInAdvanceDaysForSequentialLiabilityMedia?: Maybe<Scalars['Int']>;
  clientAxclientCodes?: Maybe<Array<Maybe<ClientAxclientCode>>>;
  clientContacts?: Maybe<Array<Maybe<ClientContact>>>;
  clientDatoramaKeys?: Maybe<Array<Maybe<ClientDatoramaKey>>>;
  clientFileReferences?: Maybe<Array<Maybe<ClientFileReference>>>;
  clientId: Scalars['Int'];
  clientLookupAliases?: Maybe<Array<Maybe<ClientLookupAlias>>>;
  clientMediaOutlets?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMonthDefinitions?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientVendorServiceMarkups?: Maybe<Array<Maybe<ClientVendorServiceMarkup>>>;
  comments?: Maybe<Scalars['String']>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignGroups?: Maybe<Array<Maybe<CrossChannelCampaignGroup>>>;
  datoramaImportJobs?: Maybe<Array<Maybe<DatoramaImportJob>>>;
  digitalResultTypes?: Maybe<Array<Maybe<DigitalResultType>>>;
  digitalSpendImportDatoramaKeyMappings?: Maybe<Array<Maybe<DigitalSpendImportDatoramaKeyMapping>>>;
  digitalSpendInputFiles?: Maybe<Array<Maybe<DigitalSpendInputFile>>>;
  duplicationMarkUp?: Maybe<Scalars['Decimal']>;
  enforceRainOneCampaignMatch: Scalars['Boolean'];
  firstPaymentReceived?: Maybe<Scalars['Boolean']>;
  forbidBookingBeyondPaymentReceived: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  inverseParentClient?: Maybe<Array<Maybe<Client>>>;
  invoiceComments?: Maybe<Scalars['String']>;
  isBroker: Scalars['Boolean'];
  isCrossCompany?: Maybe<Scalars['Boolean']>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingReportPreset?: Maybe<ReportPreset>;
  leadBillingReportPresetId?: Maybe<Scalars['Int']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  mailingAddress?: Maybe<Address>;
  mailingAddressId?: Maybe<Scalars['Int']>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaTransmissionNote?: Maybe<Scalars['String']>;
  minShipDays: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  noCallProjections: Scalars['Boolean'];
  nonCmedia: Scalars['Boolean'];
  oldclientId?: Maybe<Scalars['Int']>;
  parentClient?: Maybe<Client>;
  parentClientId?: Maybe<Scalars['Int']>;
  paymentEnforcementLookup?: Maybe<Lookup>;
  paymentEnforcementLookupId: Scalars['Int'];
  paymentReceivedThru?: Maybe<Scalars['DateTime']>;
  paysProductionWestDirectly: Scalars['Boolean'];
  products?: Maybe<Array<Maybe<Product>>>;
  shippingAddress?: Maybe<Address>;
  shippingAddressId?: Maybe<Scalars['Int']>;
  surveys?: Maybe<Array<Maybe<Survey>>>;
  taxpayerIdentificationNumber?: Maybe<Scalars['String']>;
  titleClients?: Maybe<Array<Maybe<Title>>>;
  titleParentClients?: Maybe<Array<Maybe<Title>>>;
  topLineMediaPlans?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  trafficAppLocation?: Maybe<AppLocation>;
  trafficAppLocationId: Scalars['Int'];
  usesTitleListings: Scalars['Boolean'];
  vendorDirectPayments?: Maybe<Array<Maybe<VendorDirectPayment>>>;
};

export type ClientAdjustmentInput = {
  amount: Scalars['Decimal'];
  brandCode: Scalars['String'];
  clientAdjustmentId: Scalars['Int'];
  clientCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  departmentCode: Scalars['String'];
  isOpeningBalance?: InputMaybe<Scalars['Boolean']>;
  mediaMonthCode: Scalars['String'];
  notes: Scalars['String'];
  projectCode: Scalars['String'];
  transactionDate?: InputMaybe<Scalars['DateTime']>;
};

export type ClientAdjustment_ForDisplay = {
  __typename?: 'ClientAdjustment_ForDisplay';
  amount: Scalars['Decimal'];
  brandCode?: Maybe<Scalars['String']>;
  brandDim: AxBrandDim;
  brandName: Scalars['String'];
  brandValue: Scalars['String'];
  clientAdjustmentId: Scalars['Int'];
  clientCode?: Maybe<Scalars['String']>;
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdDateUTC: Scalars['DateTime'];
  dataArea: DataArea;
  dataAreaId?: Maybe<Scalars['String']>;
  dataAreaName: Scalars['String'];
  dataAreaValue: Scalars['String'];
  departmentCode?: Maybe<Scalars['String']>;
  departmentDim: AxDepartmentDim;
  departmentName: Scalars['String'];
  departmentValue: Scalars['String'];
  id: Scalars['Int'];
  isOpeningBalance: Scalars['Boolean'];
  mediaMonthCode?: Maybe<Scalars['String']>;
  mediaMonthDim: AxMediaMonth;
  mediaMonthName: Scalars['String'];
  mediaMonthValue: Scalars['String'];
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDateUTC: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  parentLineIds: Array<Scalars['String']>;
  projectCode?: Maybe<Scalars['String']>;
  projectDim: AxProjectDim;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  simpleDepartmentCode: Scalars['String'];
  simpleDepartmentName: Scalars['String'];
};

export type ClientAdjustmentsByClient = {
  __typename?: 'ClientAdjustmentsByClient';
  clientAdjustments: Array<ClientAdjustment_ForDisplay>;
  clientCode: Scalars['String'];
  clientKey: Scalars['Long'];
};

export type ClientApproval = {
  __typename?: 'ClientApproval';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  clientApprovalId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
};

export type ClientApprovalFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<ClientApprovalFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  clientApprovalId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientApprovalFilterInput>>;
};

export type ClientAxclientCode = {
  __typename?: 'ClientAxclientCode';
  axclientCode?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientAxclientCodeId: Scalars['Int'];
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type ClientAxclientCodeFilterInput = {
  and?: InputMaybe<Array<ClientAxclientCodeFilterInput>>;
  axclientCode?: InputMaybe<StringOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientAxclientCodeId?: InputMaybe<IntOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ClientAxclientCodeFilterInput>>;
};

export type ClientBackgroundDataUpdateStatus = {
  __typename?: 'ClientBackgroundDataUpdateStatus';
  clientCode: Scalars['String'];
  id: Scalars['String'];
  isUpdating: Scalars['Boolean'];
  updateStartedAtUTC?: Maybe<Scalars['DateTime']>;
};

export type ClientContact = {
  __typename?: 'ClientContact';
  client?: Maybe<Client>;
  clientContactId: Scalars['Int'];
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type ClientContactFilterInput = {
  and?: InputMaybe<Array<ClientContactFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientContactId?: InputMaybe<IntOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ClientContactFilterInput>>;
};

export type ClientDatoramaKey = {
  __typename?: 'ClientDatoramaKey';
  client?: Maybe<Client>;
  clientDatoramaKeyId: Scalars['Int'];
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  datoramaKey?: Maybe<Scalars['String']>;
  digitalSpendImportDatoramaKeyMappings?: Maybe<Array<Maybe<DigitalSpendImportDatoramaKeyMapping>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type ClientDatoramaKeyFilterInput = {
  and?: InputMaybe<Array<ClientDatoramaKeyFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientDatoramaKeyId?: InputMaybe<IntOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  datoramaKey?: InputMaybe<StringOperationFilterInput>;
  digitalSpendImportDatoramaKeyMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportDatoramaKeyMappingFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ClientDatoramaKeyFilterInput>>;
};

export type ClientDatoramaKeyInput = {
  clientDatoramaKeyId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  datoramaKey?: InputMaybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
};

export type ClientDatoramaKey_ForMaintenance = {
  __typename?: 'ClientDatoramaKey_ForMaintenance';
  client?: Maybe<Client>;
  clientDatoramaKeyId: Scalars['Int'];
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaigns: Array<CrossChannelCampaign>;
  datoramaKey?: Maybe<Scalars['String']>;
  digitalSpendImportDatoramaKeyMappingCount: Scalars['Int'];
  digitalSpendImportDatoramaKeyMappings?: Maybe<Array<Maybe<DigitalSpendImportDatoramaKeyMapping>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type ClientDatoramaKey_ForMaintenanceFilterInput = {
  and?: InputMaybe<Array<ClientDatoramaKey_ForMaintenanceFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientDatoramaKeyId?: InputMaybe<IntOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFilterInput>;
  datoramaKey?: InputMaybe<StringOperationFilterInput>;
  digitalSpendImportDatoramaKeyMappingCount?: InputMaybe<IntOperationFilterInput>;
  digitalSpendImportDatoramaKeyMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportDatoramaKeyMappingFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ClientDatoramaKey_ForMaintenanceFilterInput>>;
};

export type ClientFileReference = {
  __typename?: 'ClientFileReference';
  client?: Maybe<Client>;
  clientFileReferenceId: Scalars['Int'];
  clientFileReferenceTypeLookup?: Maybe<Lookup>;
  clientFileReferenceTypeLookupId: Scalars['Int'];
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type ClientFileReferenceFilterInput = {
  and?: InputMaybe<Array<ClientFileReferenceFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  clientFileReferenceTypeLookup?: InputMaybe<LookupFilterInput>;
  clientFileReferenceTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ClientFileReferenceFilterInput>>;
};

export type ClientFilterInput = {
  accountTeamEmail?: InputMaybe<StringOperationFilterInput>;
  accountingAppLocation?: InputMaybe<AppLocationFilterInput>;
  accountingAppLocationId?: InputMaybe<IntOperationFilterInput>;
  accountingInvoice?: InputMaybe<BooleanOperationFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  agencyOfRecordFileReference?: InputMaybe<FileReferenceFilterInput>;
  agencyOfRecordFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<ClientFilterInput>>;
  appLocation?: InputMaybe<AppLocationFilterInput>;
  appLocationId?: InputMaybe<IntOperationFilterInput>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  audiences?: InputMaybe<ListFilterInputTypeOfAudienceFilterInput>;
  availProposals?: InputMaybe<ListFilterInputTypeOfAvailProposalFilterInput>;
  axcode?: InputMaybe<StringOperationFilterInput>;
  bonusGroupLookup?: InputMaybe<LookupFilterInput>;
  bonusGroupLookupId?: InputMaybe<IntOperationFilterInput>;
  buyerBudgetsEst?: InputMaybe<BooleanOperationFilterInput>;
  cashInAdvanceDaysForNonSequentialLiabilityCancelableMedia?: InputMaybe<IntOperationFilterInput>;
  cashInAdvanceDaysForNonSequentialLiabilityNonCancelableMedia?: InputMaybe<IntOperationFilterInput>;
  cashInAdvanceDaysForSequentialLiabilityMedia?: InputMaybe<IntOperationFilterInput>;
  clientAxclientCodes?: InputMaybe<ListFilterInputTypeOfClientAxclientCodeFilterInput>;
  clientContacts?: InputMaybe<ListFilterInputTypeOfClientContactFilterInput>;
  clientDatoramaKeys?: InputMaybe<ListFilterInputTypeOfClientDatoramaKeyFilterInput>;
  clientFileReferences?: InputMaybe<ListFilterInputTypeOfClientFileReferenceFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientLookupAliases?: InputMaybe<ListFilterInputTypeOfClientLookupAliasFilterInput>;
  clientMediaOutlets?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMonthDefinitions?: InputMaybe<ListFilterInputTypeOfClientMonthDefinitionFilterInput>;
  clientVendorServiceMarkups?: InputMaybe<ListFilterInputTypeOfClientVendorServiceMarkupFilterInput>;
  comments?: InputMaybe<StringOperationFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignGroups?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignGroupFilterInput>;
  datoramaImportJobs?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobFilterInput>;
  digitalResultTypes?: InputMaybe<ListFilterInputTypeOfDigitalResultTypeFilterInput>;
  digitalSpendImportDatoramaKeyMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportDatoramaKeyMappingFilterInput>;
  digitalSpendInputFiles?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputFileFilterInput>;
  duplicationMarkUp?: InputMaybe<DecimalOperationFilterInput>;
  enforceRainOneCampaignMatch?: InputMaybe<BooleanOperationFilterInput>;
  firstPaymentReceived?: InputMaybe<BooleanOperationFilterInput>;
  forbidBookingBeyondPaymentReceived?: InputMaybe<BooleanOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  inverseParentClient?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  invoiceComments?: InputMaybe<StringOperationFilterInput>;
  isBroker?: InputMaybe<BooleanOperationFilterInput>;
  isCrossCompany?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingReportPreset?: InputMaybe<ReportPresetFilterInput>;
  leadBillingReportPresetId?: InputMaybe<IntOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  mailingAddress?: InputMaybe<AddressFilterInput>;
  mailingAddressId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaTransmissionNote?: InputMaybe<StringOperationFilterInput>;
  minShipDays?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  noCallProjections?: InputMaybe<BooleanOperationFilterInput>;
  nonCmedia?: InputMaybe<BooleanOperationFilterInput>;
  oldclientId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientFilterInput>>;
  parentClient?: InputMaybe<ClientFilterInput>;
  parentClientId?: InputMaybe<IntOperationFilterInput>;
  paymentEnforcementLookup?: InputMaybe<LookupFilterInput>;
  paymentEnforcementLookupId?: InputMaybe<IntOperationFilterInput>;
  paymentReceivedThru?: InputMaybe<DateTimeOperationFilterInput>;
  paysProductionWestDirectly?: InputMaybe<BooleanOperationFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  shippingAddress?: InputMaybe<AddressFilterInput>;
  shippingAddressId?: InputMaybe<IntOperationFilterInput>;
  surveys?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  taxpayerIdentificationNumber?: InputMaybe<StringOperationFilterInput>;
  titleClients?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  titleParentClients?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  topLineMediaPlans?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  trafficAppLocation?: InputMaybe<AppLocationFilterInput>;
  trafficAppLocationId?: InputMaybe<IntOperationFilterInput>;
  usesTitleListings?: InputMaybe<BooleanOperationFilterInput>;
  vendorDirectPayments?: InputMaybe<ListFilterInputTypeOfVendorDirectPaymentFilterInput>;
};

export type ClientInfo = {
  __typename?: 'ClientInfo';
  clientCode: Scalars['String'];
  clientName: Scalars['String'];
};

export type ClientInput = {
  accountTeamEmail?: InputMaybe<Scalars['String']>;
  accountingInvoice?: InputMaybe<Scalars['Boolean']>;
  appLocationId?: InputMaybe<Scalars['Int']>;
  bonusGroupLookupId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  enforceRainOneCampaignMatch: Scalars['Boolean'];
  forbidBookingBeyondPaymentReceived?: InputMaybe<Scalars['Boolean']>;
  inactive: Scalars['Boolean'];
  isBroker?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  minShipDays?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noCallProjections?: InputMaybe<Scalars['Boolean']>;
  nonCmedia?: InputMaybe<Scalars['Boolean']>;
  paymentEnforcementLookupId?: InputMaybe<Scalars['Int']>;
  trafficAppLocationId?: InputMaybe<Scalars['Int']>;
  usesTitleListings?: InputMaybe<Scalars['Boolean']>;
};

export type ClientLookupAlias = {
  __typename?: 'ClientLookupAlias';
  alias?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  clientLookupAliasId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lookup?: Maybe<Lookup>;
  lookupId: Scalars['Int'];
};

export type ClientLookupAliasFilterInput = {
  alias?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ClientLookupAliasFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientLookupAliasId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lookup?: InputMaybe<LookupFilterInput>;
  lookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientLookupAliasFilterInput>>;
};

export type ClientLookupAliasInput = {
  alias?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  clientLookupAliasId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  lookupId?: InputMaybe<Scalars['Int']>;
};

export type ClientMediaOutlet = {
  __typename?: 'ClientMediaOutlet';
  bookingPaperworkNote?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  clientMediaClassificationLookup?: Maybe<Lookup>;
  clientMediaClassificationLookupId?: Maybe<Scalars['Int']>;
  clientMediaOutletId: Scalars['Int'];
  clientReducedAudienceFeedMediaClassificationLookup?: Maybe<Lookup>;
  clientReducedAudienceFeedMediaClassificationLookupId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  daypart?: Maybe<Daypart>;
  daypartId?: Maybe<Scalars['Int']>;
  invoiceApproverAppUser?: Maybe<AppUser>;
  invoiceApproverAppUserId?: Maybe<Scalars['Int']>;
  isHighProfile: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaClassificationLookup?: Maybe<Lookup>;
  mediaClassificationLookupId?: Maybe<Scalars['Int']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletAlias?: Maybe<Scalars['String']>;
  mediaOutletId: Scalars['Int'];
  reducedAudienceFeedMediaClassificationLookup?: Maybe<Lookup>;
  reducedAudienceFeedMediaClassificationLookupId?: Maybe<Scalars['Int']>;
};

export type ClientMediaOutletFilterInput = {
  and?: InputMaybe<Array<ClientMediaOutletFilterInput>>;
  bookingPaperworkNote?: InputMaybe<StringOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientMediaClassificationLookup?: InputMaybe<LookupFilterInput>;
  clientMediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  clientMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  clientReducedAudienceFeedMediaClassificationLookup?: InputMaybe<LookupFilterInput>;
  clientReducedAudienceFeedMediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  daypart?: InputMaybe<DaypartFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  invoiceApproverAppUser?: InputMaybe<AppUserFilterInput>;
  invoiceApproverAppUserId?: InputMaybe<IntOperationFilterInput>;
  isHighProfile?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaClassificationLookup?: InputMaybe<LookupFilterInput>;
  mediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletAlias?: InputMaybe<StringOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientMediaOutletFilterInput>>;
  reducedAudienceFeedMediaClassificationLookup?: InputMaybe<LookupFilterInput>;
  reducedAudienceFeedMediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type ClientMediaOutletInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  clientMediaOutletId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  invoiceApproverAppUserId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
};

export type ClientMonthDefinition = {
  __typename?: 'ClientMonthDefinition';
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  clientMonthDefinitionId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  offlineMonthDefinitionLookup?: Maybe<Lookup>;
  offlineMonthDefinitionLookupId: Scalars['Int'];
  onlineMonthDefinitionLookup?: Maybe<Lookup>;
  onlineMonthDefinitionLookupId: Scalars['Int'];
};

export type ClientMonthDefinitionFilterInput = {
  and?: InputMaybe<Array<ClientMonthDefinitionFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientMonthDefinitionId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  offlineMonthDefinitionLookup?: InputMaybe<LookupFilterInput>;
  offlineMonthDefinitionLookupId?: InputMaybe<IntOperationFilterInput>;
  onlineMonthDefinitionLookup?: InputMaybe<LookupFilterInput>;
  onlineMonthDefinitionLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientMonthDefinitionFilterInput>>;
};

export type ClientMonthDefinition_ForLookup = {
  __typename?: 'ClientMonthDefinition_ForLookup';
  client?: Maybe<Client>;
  clientCode?: Maybe<Scalars['String']>;
  clientId: Scalars['Int'];
  clientMonthDefinitionId: Scalars['Int'];
  clientName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  offlineMonthDefinitionLookup?: Maybe<Lookup>;
  offlineMonthDefinitionLookupId: Scalars['Int'];
  offlineMonthDefinitionName?: Maybe<Scalars['String']>;
  onlineMonthDefinitionLookup?: Maybe<Lookup>;
  onlineMonthDefinitionLookupId: Scalars['Int'];
  onlineMonthDefinitionName?: Maybe<Scalars['String']>;
};

export type ClientOpportunityType = {
  __typename?: 'ClientOpportunityType';
  clientOpportunityTypeId: Scalars['Int'];
  clientOpportunityTypeName?: Maybe<Scalars['String']>;
};

export type ClientOpportunityTypeFilterInput = {
  and?: InputMaybe<Array<ClientOpportunityTypeFilterInput>>;
  clientOpportunityTypeId?: InputMaybe<IntOperationFilterInput>;
  clientOpportunityTypeName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClientOpportunityTypeFilterInput>>;
};

export type ClientReconciliationMonthStatus = {
  __typename?: 'ClientReconciliationMonthStatus';
  clientCode: Scalars['String'];
  clientReconciliationStatuses: Array<ClientReconciliationStatus_ForDisplay>;
  currentAXRevenue: Scalars['Decimal'];
  currentClientBalance: Scalars['Decimal'];
  currentClientLineSettledAmount: Scalars['Decimal'];
  currentInvoicedAmount: Scalars['Decimal'];
  currentRecievedAmount: Scalars['Decimal'];
  currentTrackerClearanceRevenue: Scalars['Decimal'];
  id: Scalars['String'];
  mediaMonth: AxMediaMonth;
  reconciledAXRevenue: Scalars['Decimal'];
  reconciledClientBalance: Scalars['Decimal'];
  reconciledClientLineSettledAmount: Scalars['Decimal'];
  reconciledInvoicedAmount: Scalars['Decimal'];
  reconciledRecievedAmount: Scalars['Decimal'];
  reconciledTrackerClearanceRevenue: Scalars['Decimal'];
  reconciliationStatus: ReconciliationStatus;
  trackerLockedStatus: ReconciliationStatus;
};

export type ClientReconciliationStatusInput = {
  clientCode: Scalars['String'];
  isReconciled: Scalars['Boolean'];
  mediaMonthCode: Scalars['String'];
  projectCode: Scalars['String'];
};

export type ClientReconciliationStatus_ForDisplay = {
  __typename?: 'ClientReconciliationStatus_ForDisplay';
  axRevenue: Scalars['Decimal'];
  clientBalance: Scalars['Decimal'];
  clientCode?: Maybe<Scalars['String']>;
  clientLineSettledAmount: Scalars['Decimal'];
  clientReconciliationStatusId: Scalars['Int'];
  currentAXRevenue: Scalars['Decimal'];
  currentClientBalance: Scalars['Decimal'];
  currentClientLineSettledAmount: Scalars['Decimal'];
  currentInvoicedAmount: Scalars['Decimal'];
  currentRecievedAmount: Scalars['Decimal'];
  currentTrackerClearanceRevenue: Scalars['Decimal'];
  departmentRevenueDetails: Array<DepartmentRevenueDetails>;
  id: Scalars['String'];
  invoicedAmount: Scalars['Decimal'];
  mediaMonthCode?: Maybe<Scalars['String']>;
  project: AxProjectDim;
  projectCode?: Maybe<Scalars['String']>;
  recievedAmount: Scalars['Decimal'];
  reconciledBy?: Maybe<Scalars['String']>;
  reconciledDateUTC: Scalars['DateTime'];
  reconciliationStatus: ReconciliationStatus;
  trackerClearanceRevenue: Scalars['Decimal'];
  trackerLockedStatus: ReconciliationStatus;
};

export type ClientRole = {
  __typename?: 'ClientRole';
  clientRoleFunctionalRoles: Array<ClientRoleFunctionalRole>;
  clientRoleId: Scalars['Int'];
  clientRolePermissions: Array<ClientRolePermission>;
  /** The D365 CompanyCode (aka DataAreaId) that this role falls under. */
  companyCode: Scalars['String'];
  departmentGroupId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export enum ClientRoleEnum {
  Ac = 'AC',
  AcLf = 'AC_LF',
  AcSf = 'AC_SF',
  Ae = 'AE',
  Analytics = 'ANALYTICS',
  As = 'AS',
  AssistantMediaPlanner = 'ASSISTANT_MEDIA_PLANNER',
  AssociateProjectManager = 'ASSOCIATE_PROJECT_MANAGER',
  BackupAnalytics = 'BACKUP_ANALYTICS',
  ClientFinance = 'CLIENT_FINANCE',
  DigitalAnalyst = 'DIGITAL_ANALYST',
  DigitalMediaSupervisor = 'DIGITAL_MEDIA_SUPERVISOR',
  DigitalProjectManager = 'DIGITAL_PROJECT_MANAGER',
  DoBackUp = 'DO_BACK_UP',
  Dubs = 'DUBS',
  InvoiceApprover = 'INVOICE_APPROVER',
  Ma = 'MA',
  Mba = 'MBA',
  MediaPlanner = 'MEDIA_PLANNER',
  ProjectManager = 'PROJECT_MANAGER',
  SeniorProjectManager = 'SENIOR_PROJECT_MANAGER'
}

export type ClientRoleFilterInput = {
  and?: InputMaybe<Array<ClientRoleFilterInput>>;
  clientRoleFunctionalRoles?: InputMaybe<ListFilterInputTypeOfClientRoleFunctionalRoleFilterInput>;
  clientRoleId?: InputMaybe<IntOperationFilterInput>;
  clientRolePermissions?: InputMaybe<ListFilterInputTypeOfClientRolePermissionFilterInput>;
  /** The D365 CompanyCode (aka DataAreaId) that this role falls under. */
  companyCode?: InputMaybe<StringOperationFilterInput>;
  departmentGroupId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClientRoleFilterInput>>;
  sortOrder?: InputMaybe<IntOperationFilterInput>;
};

export type ClientRoleFunctionalRole = {
  __typename?: 'ClientRoleFunctionalRole';
  addedBy: Scalars['String'];
  clientRole: ClientRole;
  clientRoleId: Scalars['Int'];
  dateAddedUTC: Scalars['DateTime'];
  functionalRole: FunctionalRole;
  id: Scalars['Int'];
};

export type ClientRoleFunctionalRoleFilterInput = {
  addedBy?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ClientRoleFunctionalRoleFilterInput>>;
  clientRole?: InputMaybe<ClientRoleFilterInput>;
  clientRoleId?: InputMaybe<IntOperationFilterInput>;
  dateAddedUTC?: InputMaybe<DateTimeOperationFilterInput>;
  functionalRole?: InputMaybe<FunctionalRoleOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientRoleFunctionalRoleFilterInput>>;
};

export type ClientRolePermission = {
  __typename?: 'ClientRolePermission';
  addedBy: Scalars['String'];
  clientRole: ClientRole;
  clientRoleId: Scalars['Int'];
  dateAddedUTC: Scalars['DateTime'];
  id: Scalars['Int'];
  rainOnePermission: RainOnePermission;
};

export type ClientRolePermissionFilterInput = {
  addedBy?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ClientRolePermissionFilterInput>>;
  clientRole?: InputMaybe<ClientRoleFilterInput>;
  clientRoleId?: InputMaybe<IntOperationFilterInput>;
  dateAddedUTC?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientRolePermissionFilterInput>>;
  rainOnePermission?: InputMaybe<RainOnePermissionOperationFilterInput>;
};

export type ClientRoleSortOrderInput = {
  clientRoleId: Scalars['Int'];
  sortOrder: Scalars['Int'];
};

export type ClientRole_ForDisplay = {
  __typename?: 'ClientRole_ForDisplay';
  clientRoleFunctionalRoles: Array<ClientRoleFunctionalRole>;
  clientRoleId: Scalars['Int'];
  clientRolePermissions: Array<ClientRolePermission>;
  companyCode: Scalars['String'];
  departmentGroupId: Scalars['Int'];
  departmentGroupName: Scalars['String'];
  name: Scalars['String'];
  person_Clients: Array<Person_Client_ForDisplay>;
  sortOrder: Scalars['Int'];
};

export type ClientSortInput = {
  accountTeamEmail?: InputMaybe<SortEnumType>;
  accountingAppLocation?: InputMaybe<AppLocationSortInput>;
  accountingAppLocationId?: InputMaybe<SortEnumType>;
  accountingInvoice?: InputMaybe<SortEnumType>;
  agencyOfRecordFileReference?: InputMaybe<FileReferenceSortInput>;
  agencyOfRecordFileReferenceId?: InputMaybe<SortEnumType>;
  appLocation?: InputMaybe<AppLocationSortInput>;
  appLocationId?: InputMaybe<SortEnumType>;
  axcode?: InputMaybe<SortEnumType>;
  bonusGroupLookup?: InputMaybe<LookupSortInput>;
  bonusGroupLookupId?: InputMaybe<SortEnumType>;
  buyerBudgetsEst?: InputMaybe<SortEnumType>;
  cashInAdvanceDaysForNonSequentialLiabilityCancelableMedia?: InputMaybe<SortEnumType>;
  cashInAdvanceDaysForNonSequentialLiabilityNonCancelableMedia?: InputMaybe<SortEnumType>;
  cashInAdvanceDaysForSequentialLiabilityMedia?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  comments?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  duplicationMarkUp?: InputMaybe<SortEnumType>;
  enforceRainOneCampaignMatch?: InputMaybe<SortEnumType>;
  firstPaymentReceived?: InputMaybe<SortEnumType>;
  forbidBookingBeyondPaymentReceived?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  invoiceComments?: InputMaybe<SortEnumType>;
  isBroker?: InputMaybe<SortEnumType>;
  isCrossCompany?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  leadBillingReportPreset?: InputMaybe<ReportPresetSortInput>;
  leadBillingReportPresetId?: InputMaybe<SortEnumType>;
  mailingAddress?: InputMaybe<AddressSortInput>;
  mailingAddressId?: InputMaybe<SortEnumType>;
  mediaTransmissionNote?: InputMaybe<SortEnumType>;
  minShipDays?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  noCallProjections?: InputMaybe<SortEnumType>;
  nonCmedia?: InputMaybe<SortEnumType>;
  oldclientId?: InputMaybe<SortEnumType>;
  parentClient?: InputMaybe<ClientSortInput>;
  parentClientId?: InputMaybe<SortEnumType>;
  paymentEnforcementLookup?: InputMaybe<LookupSortInput>;
  paymentEnforcementLookupId?: InputMaybe<SortEnumType>;
  paymentReceivedThru?: InputMaybe<SortEnumType>;
  paysProductionWestDirectly?: InputMaybe<SortEnumType>;
  shippingAddress?: InputMaybe<AddressSortInput>;
  shippingAddressId?: InputMaybe<SortEnumType>;
  taxpayerIdentificationNumber?: InputMaybe<SortEnumType>;
  trafficAppLocation?: InputMaybe<AppLocationSortInput>;
  trafficAppLocationId?: InputMaybe<SortEnumType>;
  usesTitleListings?: InputMaybe<SortEnumType>;
};

export enum ClientTransactionTermGroupType {
  ClientPaidCommission = 'CLIENT_PAID_COMMISSION',
  Internal = 'INTERNAL',
  PostPaidFees = 'POST_PAID_FEES',
  PrePaidFees = 'PRE_PAID_FEES',
  Standard = 'STANDARD',
  UnknownFees = 'UNKNOWN_FEES'
}

export type ClientTransaction_ForDisplay = {
  __typename?: 'ClientTransaction_ForDisplay';
  balance: Scalars['Decimal'];
  brandCode?: Maybe<Scalars['String']>;
  brandDim: AxBrandDim;
  brandName: Scalars['String'];
  brandValue: Scalars['String'];
  categoryCode: Scalars['String'];
  clientCode: Scalars['String'];
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  custTransRecId: Scalars['Long'];
  dataAreaId: Scalars['String'];
  daysPastDue: Scalars['Int'];
  departmentCode: Scalars['String'];
  departmentDim: AxDepartmentDim;
  departmentName: Scalars['String'];
  departmentValue: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  effectiveTerms: Scalars['String'];
  enumItemLabel: Scalars['String'];
  id: Scalars['String'];
  invoice: Scalars['String'];
  invoiceAmount: Scalars['Decimal'];
  invoiceBalance: Scalars['Decimal'];
  invoiceRecievedAmount: Scalars['Decimal'];
  isIntercompanyTransfer: Scalars['Boolean'];
  lastEditedBy: Scalars['String'];
  lastEditedDateUTC?: Maybe<Scalars['DateTime']>;
  lastSettleDate: Scalars['DateTime'];
  lastSettleVoucher: Scalars['String'];
  lineAmount: Scalars['Decimal'];
  lineSettledAmount: Scalars['Decimal'];
  mediaMonthCode: Scalars['String'];
  mediaMonthDim: AxMediaMonth;
  mediaMonthName: Scalars['String'];
  mediaMonthValue: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  parentLineIds: Array<Scalars['String']>;
  paymmode: Scalars['String'];
  paymreference: Scalars['String'];
  prettyInvoice: Scalars['String'];
  projectCode: Scalars['String'];
  projectDim: AxProjectDim;
  projectId: Scalars['String'];
  projectInvoiceInfoSourceType: ProjectInvoiceInfoSourceType;
  projectLineText?: Maybe<Scalars['String']>;
  projectName: Scalars['String'];
  realDueDate: Scalars['DateTime'];
  simpleDepartmentCode: Scalars['String'];
  simpleDepartmentName: Scalars['String'];
  termGroup: ClientTransactionTermGroupType;
  termGroupFromD365: ClientTransactionTermGroupType;
  termGroupIdOverriden: Scalars['Boolean'];
  termGroupOverride?: Maybe<ClientTransactionTermGroupType>;
  transDate: Scalars['DateTime'];
  uniqueKey?: Maybe<Scalars['String']>;
  vendorId: Scalars['String'];
  voucher: Scalars['String'];
};

export type ClientVendorServiceMarkup = {
  __typename?: 'ClientVendorServiceMarkup';
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  clientVendorServiceMarkupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  markUp: Scalars['Decimal'];
  vendorService?: Maybe<VendorService>;
  vendorServiceId: Scalars['Int'];
};

export type ClientVendorServiceMarkupFilterInput = {
  and?: InputMaybe<Array<ClientVendorServiceMarkupFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientVendorServiceMarkupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  markUp?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<ClientVendorServiceMarkupFilterInput>>;
  vendorService?: InputMaybe<VendorServiceFilterInput>;
  vendorServiceId?: InputMaybe<IntOperationFilterInput>;
};

export type Client_ForMaintenance = {
  __typename?: 'Client_ForMaintenance';
  accountTeamEmail?: Maybe<Scalars['String']>;
  accountingAppLocation?: Maybe<AppLocation>;
  accountingAppLocationId?: Maybe<Scalars['Int']>;
  accountingInvoice: Scalars['Boolean'];
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  agencyOfRecordFileReference?: Maybe<FileReference>;
  agencyOfRecordFileReferenceId?: Maybe<Scalars['Int']>;
  appLocation?: Maybe<AppLocation>;
  appLocationId: Scalars['Int'];
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  audiences?: Maybe<Array<Maybe<Audience>>>;
  availProposals?: Maybe<Array<Maybe<AvailProposal>>>;
  axcode?: Maybe<Scalars['String']>;
  bonusGroupLookup?: Maybe<Lookup>;
  bonusGroupLookupId: Scalars['Int'];
  buyerBudgetsEst: Scalars['Boolean'];
  cashInAdvanceDaysForNonSequentialLiabilityCancelableMedia?: Maybe<Scalars['Int']>;
  cashInAdvanceDaysForNonSequentialLiabilityNonCancelableMedia?: Maybe<Scalars['Int']>;
  cashInAdvanceDaysForSequentialLiabilityMedia?: Maybe<Scalars['Int']>;
  clientAxclientCodes?: Maybe<Array<Maybe<ClientAxclientCode>>>;
  clientContacts?: Maybe<Array<Maybe<ClientContact>>>;
  clientDatoramaKeys?: Maybe<Array<Maybe<ClientDatoramaKey>>>;
  clientDatoramaKeysForMaintenance: Array<ClientDatoramaKey_ForMaintenance>;
  clientFileReferences?: Maybe<Array<Maybe<ClientFileReference>>>;
  clientId: Scalars['Int'];
  clientLookupAliases?: Maybe<Array<Maybe<ClientLookupAlias>>>;
  clientMediaOutlets?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMonthDefinitions?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientVendorServiceMarkups?: Maybe<Array<Maybe<ClientVendorServiceMarkup>>>;
  comments?: Maybe<Scalars['String']>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignGroups?: Maybe<Array<Maybe<CrossChannelCampaignGroup>>>;
  datoramaImportJobs?: Maybe<Array<Maybe<DatoramaImportJob>>>;
  digitalAppUsers: Array<AppUserClient_ForMaintenance>;
  digitalResultTypes?: Maybe<Array<Maybe<DigitalResultType>>>;
  digitalSpendImportDatoramaKeyMappings?: Maybe<Array<Maybe<DigitalSpendImportDatoramaKeyMapping>>>;
  digitalSpendInputFiles?: Maybe<Array<Maybe<DigitalSpendInputFile>>>;
  duplicationMarkUp?: Maybe<Scalars['Decimal']>;
  enforceRainOneCampaignMatch: Scalars['Boolean'];
  firstPaymentReceived?: Maybe<Scalars['Boolean']>;
  forbidBookingBeyondPaymentReceived: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  inverseParentClient?: Maybe<Array<Maybe<Client>>>;
  invoiceComments?: Maybe<Scalars['String']>;
  isBroker: Scalars['Boolean'];
  isCrossCompany?: Maybe<Scalars['Boolean']>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingReportPreset?: Maybe<ReportPreset>;
  leadBillingReportPresetId?: Maybe<Scalars['Int']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  mailingAddress?: Maybe<Address>;
  mailingAddressId?: Maybe<Scalars['Int']>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaTransmissionNote?: Maybe<Scalars['String']>;
  minShipDays: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  noCallProjections: Scalars['Boolean'];
  nonCmedia: Scalars['Boolean'];
  oldclientId?: Maybe<Scalars['Int']>;
  parentClient?: Maybe<Client>;
  parentClientId?: Maybe<Scalars['Int']>;
  paymentEnforcementLookup?: Maybe<Lookup>;
  paymentEnforcementLookupId: Scalars['Int'];
  paymentReceivedThru?: Maybe<Scalars['DateTime']>;
  paysProductionWestDirectly: Scalars['Boolean'];
  products?: Maybe<Array<Maybe<Product>>>;
  productsForMaintenance: Array<Product_ForMaintenance>;
  shippingAddress?: Maybe<Address>;
  shippingAddressId?: Maybe<Scalars['Int']>;
  surveys?: Maybe<Array<Maybe<Survey>>>;
  taxpayerIdentificationNumber?: Maybe<Scalars['String']>;
  titleClients?: Maybe<Array<Maybe<Title>>>;
  titleParentClients?: Maybe<Array<Maybe<Title>>>;
  topLineMediaPlans?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  trafficAppLocation?: Maybe<AppLocation>;
  trafficAppLocationId: Scalars['Int'];
  usesTitleListings: Scalars['Boolean'];
  vendorDirectPayments?: Maybe<Array<Maybe<VendorDirectPayment>>>;
};

export type Client_ForMaintenanceFilterInput = {
  accountTeamEmail?: InputMaybe<StringOperationFilterInput>;
  accountingAppLocation?: InputMaybe<AppLocationFilterInput>;
  accountingAppLocationId?: InputMaybe<IntOperationFilterInput>;
  accountingInvoice?: InputMaybe<BooleanOperationFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  agencyOfRecordFileReference?: InputMaybe<FileReferenceFilterInput>;
  agencyOfRecordFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<Client_ForMaintenanceFilterInput>>;
  appLocation?: InputMaybe<AppLocationFilterInput>;
  appLocationId?: InputMaybe<IntOperationFilterInput>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  audiences?: InputMaybe<ListFilterInputTypeOfAudienceFilterInput>;
  availProposals?: InputMaybe<ListFilterInputTypeOfAvailProposalFilterInput>;
  axcode?: InputMaybe<StringOperationFilterInput>;
  bonusGroupLookup?: InputMaybe<LookupFilterInput>;
  bonusGroupLookupId?: InputMaybe<IntOperationFilterInput>;
  buyerBudgetsEst?: InputMaybe<BooleanOperationFilterInput>;
  cashInAdvanceDaysForNonSequentialLiabilityCancelableMedia?: InputMaybe<IntOperationFilterInput>;
  cashInAdvanceDaysForNonSequentialLiabilityNonCancelableMedia?: InputMaybe<IntOperationFilterInput>;
  cashInAdvanceDaysForSequentialLiabilityMedia?: InputMaybe<IntOperationFilterInput>;
  clientAxclientCodes?: InputMaybe<ListFilterInputTypeOfClientAxclientCodeFilterInput>;
  clientContacts?: InputMaybe<ListFilterInputTypeOfClientContactFilterInput>;
  clientDatoramaKeys?: InputMaybe<ListFilterInputTypeOfClientDatoramaKeyFilterInput>;
  clientDatoramaKeysForMaintenance?: InputMaybe<ListFilterInputTypeOfClientDatoramaKey_ForMaintenanceFilterInput>;
  clientFileReferences?: InputMaybe<ListFilterInputTypeOfClientFileReferenceFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientLookupAliases?: InputMaybe<ListFilterInputTypeOfClientLookupAliasFilterInput>;
  clientMediaOutlets?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMonthDefinitions?: InputMaybe<ListFilterInputTypeOfClientMonthDefinitionFilterInput>;
  clientVendorServiceMarkups?: InputMaybe<ListFilterInputTypeOfClientVendorServiceMarkupFilterInput>;
  comments?: InputMaybe<StringOperationFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignGroups?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignGroupFilterInput>;
  datoramaImportJobs?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobFilterInput>;
  digitalAppUsers?: InputMaybe<ListFilterInputTypeOfAppUserClient_ForMaintenanceFilterInput>;
  digitalResultTypes?: InputMaybe<ListFilterInputTypeOfDigitalResultTypeFilterInput>;
  digitalSpendImportDatoramaKeyMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportDatoramaKeyMappingFilterInput>;
  digitalSpendInputFiles?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputFileFilterInput>;
  duplicationMarkUp?: InputMaybe<DecimalOperationFilterInput>;
  enforceRainOneCampaignMatch?: InputMaybe<BooleanOperationFilterInput>;
  firstPaymentReceived?: InputMaybe<BooleanOperationFilterInput>;
  forbidBookingBeyondPaymentReceived?: InputMaybe<BooleanOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  inverseParentClient?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  invoiceComments?: InputMaybe<StringOperationFilterInput>;
  isBroker?: InputMaybe<BooleanOperationFilterInput>;
  isCrossCompany?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingReportPreset?: InputMaybe<ReportPresetFilterInput>;
  leadBillingReportPresetId?: InputMaybe<IntOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  mailingAddress?: InputMaybe<AddressFilterInput>;
  mailingAddressId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaTransmissionNote?: InputMaybe<StringOperationFilterInput>;
  minShipDays?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  noCallProjections?: InputMaybe<BooleanOperationFilterInput>;
  nonCmedia?: InputMaybe<BooleanOperationFilterInput>;
  oldclientId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Client_ForMaintenanceFilterInput>>;
  parentClient?: InputMaybe<ClientFilterInput>;
  parentClientId?: InputMaybe<IntOperationFilterInput>;
  paymentEnforcementLookup?: InputMaybe<LookupFilterInput>;
  paymentEnforcementLookupId?: InputMaybe<IntOperationFilterInput>;
  paymentReceivedThru?: InputMaybe<DateTimeOperationFilterInput>;
  paysProductionWestDirectly?: InputMaybe<BooleanOperationFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  productsForMaintenance?: InputMaybe<ListFilterInputTypeOfProduct_ForMaintenanceFilterInput>;
  shippingAddress?: InputMaybe<AddressFilterInput>;
  shippingAddressId?: InputMaybe<IntOperationFilterInput>;
  surveys?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  taxpayerIdentificationNumber?: InputMaybe<StringOperationFilterInput>;
  titleClients?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  titleParentClients?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  topLineMediaPlans?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  trafficAppLocation?: InputMaybe<AppLocationFilterInput>;
  trafficAppLocationId?: InputMaybe<IntOperationFilterInput>;
  usesTitleListings?: InputMaybe<BooleanOperationFilterInput>;
  vendorDirectPayments?: InputMaybe<ListFilterInputTypeOfVendorDirectPaymentFilterInput>;
};

export type Client_ForMaintenanceSortInput = {
  accountTeamEmail?: InputMaybe<SortEnumType>;
  accountingAppLocation?: InputMaybe<AppLocationSortInput>;
  accountingAppLocationId?: InputMaybe<SortEnumType>;
  accountingInvoice?: InputMaybe<SortEnumType>;
  agencyOfRecordFileReference?: InputMaybe<FileReferenceSortInput>;
  agencyOfRecordFileReferenceId?: InputMaybe<SortEnumType>;
  appLocation?: InputMaybe<AppLocationSortInput>;
  appLocationId?: InputMaybe<SortEnumType>;
  axcode?: InputMaybe<SortEnumType>;
  bonusGroupLookup?: InputMaybe<LookupSortInput>;
  bonusGroupLookupId?: InputMaybe<SortEnumType>;
  buyerBudgetsEst?: InputMaybe<SortEnumType>;
  cashInAdvanceDaysForNonSequentialLiabilityCancelableMedia?: InputMaybe<SortEnumType>;
  cashInAdvanceDaysForNonSequentialLiabilityNonCancelableMedia?: InputMaybe<SortEnumType>;
  cashInAdvanceDaysForSequentialLiabilityMedia?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  comments?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  duplicationMarkUp?: InputMaybe<SortEnumType>;
  enforceRainOneCampaignMatch?: InputMaybe<SortEnumType>;
  firstPaymentReceived?: InputMaybe<SortEnumType>;
  forbidBookingBeyondPaymentReceived?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  invoiceComments?: InputMaybe<SortEnumType>;
  isBroker?: InputMaybe<SortEnumType>;
  isCrossCompany?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  leadBillingReportPreset?: InputMaybe<ReportPresetSortInput>;
  leadBillingReportPresetId?: InputMaybe<SortEnumType>;
  mailingAddress?: InputMaybe<AddressSortInput>;
  mailingAddressId?: InputMaybe<SortEnumType>;
  mediaTransmissionNote?: InputMaybe<SortEnumType>;
  minShipDays?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  noCallProjections?: InputMaybe<SortEnumType>;
  nonCmedia?: InputMaybe<SortEnumType>;
  oldclientId?: InputMaybe<SortEnumType>;
  parentClient?: InputMaybe<ClientSortInput>;
  parentClientId?: InputMaybe<SortEnumType>;
  paymentEnforcementLookup?: InputMaybe<LookupSortInput>;
  paymentEnforcementLookupId?: InputMaybe<SortEnumType>;
  paymentReceivedThru?: InputMaybe<SortEnumType>;
  paysProductionWestDirectly?: InputMaybe<SortEnumType>;
  shippingAddress?: InputMaybe<AddressSortInput>;
  shippingAddressId?: InputMaybe<SortEnumType>;
  taxpayerIdentificationNumber?: InputMaybe<SortEnumType>;
  trafficAppLocation?: InputMaybe<AppLocationSortInput>;
  trafficAppLocationId?: InputMaybe<SortEnumType>;
  usesTitleListings?: InputMaybe<SortEnumType>;
};

export type Commission = {
  __typename?: 'Commission';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  commissionId: Scalars['Int'];
  commissionSsdetailTriggeredBy?: Maybe<Scalars['Int']>;
  commissionSsdetailTriggeredByNavigation?: Maybe<CommissionSlidingScaleDetail>;
  commissionSsperiodTriggeredBy?: Maybe<Scalars['Int']>;
  commissionSsperiodTriggeredByNavigation?: Maybe<CommissionSlidingScalePeriod>;
  commissionSstriggeredBy?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  effectiveDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  endDateManuallyEdited?: Maybe<Scalars['Boolean']>;
  internalComment?: Maybe<Scalars['String']>;
  isProgrammatic: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  manuallyOverridden: Scalars['Boolean'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  rate: Scalars['Decimal'];
};

export type CommissionFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<CommissionFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  commissionId?: InputMaybe<IntOperationFilterInput>;
  commissionSsdetailTriggeredBy?: InputMaybe<IntOperationFilterInput>;
  commissionSsdetailTriggeredByNavigation?: InputMaybe<CommissionSlidingScaleDetailFilterInput>;
  commissionSsperiodTriggeredBy?: InputMaybe<IntOperationFilterInput>;
  commissionSsperiodTriggeredByNavigation?: InputMaybe<CommissionSlidingScalePeriodFilterInput>;
  commissionSstriggeredBy?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dateEntered?: InputMaybe<DateTimeOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endDateManuallyEdited?: InputMaybe<BooleanOperationFilterInput>;
  internalComment?: InputMaybe<StringOperationFilterInput>;
  isProgrammatic?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  manuallyOverridden?: InputMaybe<BooleanOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CommissionFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
};

export type CommissionSlidingScale = {
  __typename?: 'CommissionSlidingScale';
  amendmentDate?: Maybe<Scalars['DateTime']>;
  annualBudgetReviewedQuarterly: Scalars['Boolean'];
  calendarTypeLookup?: Maybe<Lookup>;
  calendarTypeLookupId?: Maybe<Scalars['Int']>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  clearedMediaReportPreset?: Maybe<ReportPreset>;
  clearedMediaReportPresetId?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  commissionBasedOnRateTypeLookup?: Maybe<Lookup>;
  commissionBasedOnRateTypeLookupId: Scalars['Int'];
  commissionContingencyActionTypeLookup?: Maybe<Lookup>;
  commissionContingencyActionTypeLookupId?: Maybe<Scalars['Int']>;
  commissionSlidingScaleDetails?: Maybe<Array<Maybe<CommissionSlidingScaleDetail>>>;
  commissionSlidingScaleId: Scalars['Int'];
  commissionSlidingScaleMediaTypes?: Maybe<Array<Maybe<CommissionSlidingScaleMediaType>>>;
  commissionSlidingScalePeriods?: Maybe<Array<Maybe<CommissionSlidingScalePeriod>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contingencyDateUnitQty?: Maybe<Scalars['Int']>;
  contingencyDateUnitTypeLookup?: Maybe<Lookup>;
  contingencyDateUnitTypeLookupId?: Maybe<Scalars['Int']>;
  contingencyInitialDelay?: Maybe<Scalars['Int']>;
  contractDate: Scalars['DateTime'];
  contractFileReference?: Maybe<FileReference>;
  contractFileReferenceId?: Maybe<Scalars['Int']>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  effectiveDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  isRetroactive?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  minimumAmount?: Maybe<Scalars['Decimal']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  resetAmount?: Maybe<Scalars['Decimal']>;
  resetAmountAlertExists?: Maybe<Scalars['Boolean']>;
  resetConditionLookup?: Maybe<Lookup>;
  resetConditionLookupId: Scalars['Int'];
  resetDate?: Maybe<Scalars['DateTime']>;
  resetsAutomatically: Scalars['Boolean'];
  thresholdBasedOnRateTypeLookup?: Maybe<Lookup>;
  thresholdBasedOnRateTypeLookupId: Scalars['Int'];
};

export type CommissionSlidingScaleDetail = {
  __typename?: 'CommissionSlidingScaleDetail';
  commissionSlidingScale?: Maybe<CommissionSlidingScale>;
  commissionSlidingScaleDetailId: Scalars['Int'];
  commissionSlidingScaleId: Scalars['Int'];
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contingencyAlertExists?: Maybe<Scalars['Boolean']>;
  contingencyMinPerDateUnit?: Maybe<Scalars['Decimal']>;
  contingencyPenaltyInLevels?: Maybe<Scalars['Int']>;
  flatFee?: Maybe<Scalars['Decimal']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc: Scalars['DateTime'];
  lowerSpendAmount: Scalars['Int'];
  manuallyOverridden: Scalars['Boolean'];
  rate: Scalars['Decimal'];
  transitionDate?: Maybe<Scalars['DateTime']>;
};

export type CommissionSlidingScaleDetailFilterInput = {
  and?: InputMaybe<Array<CommissionSlidingScaleDetailFilterInput>>;
  commissionSlidingScale?: InputMaybe<CommissionSlidingScaleFilterInput>;
  commissionSlidingScaleDetailId?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScaleId?: InputMaybe<IntOperationFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contingencyAlertExists?: InputMaybe<BooleanOperationFilterInput>;
  contingencyMinPerDateUnit?: InputMaybe<DecimalOperationFilterInput>;
  contingencyPenaltyInLevels?: InputMaybe<IntOperationFilterInput>;
  flatFee?: InputMaybe<DecimalOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lowerSpendAmount?: InputMaybe<IntOperationFilterInput>;
  manuallyOverridden?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CommissionSlidingScaleDetailFilterInput>>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
  transitionDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CommissionSlidingScaleDetailSortInput = {
  commissionSlidingScale?: InputMaybe<CommissionSlidingScaleSortInput>;
  commissionSlidingScaleDetailId?: InputMaybe<SortEnumType>;
  commissionSlidingScaleId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  contingencyAlertExists?: InputMaybe<SortEnumType>;
  contingencyMinPerDateUnit?: InputMaybe<SortEnumType>;
  contingencyPenaltyInLevels?: InputMaybe<SortEnumType>;
  flatFee?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  lowerSpendAmount?: InputMaybe<SortEnumType>;
  manuallyOverridden?: InputMaybe<SortEnumType>;
  rate?: InputMaybe<SortEnumType>;
  transitionDate?: InputMaybe<SortEnumType>;
};

export type CommissionSlidingScaleFilterInput = {
  amendmentDate?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<CommissionSlidingScaleFilterInput>>;
  annualBudgetReviewedQuarterly?: InputMaybe<BooleanOperationFilterInput>;
  calendarTypeLookup?: InputMaybe<LookupFilterInput>;
  calendarTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  clearedMediaReportPreset?: InputMaybe<ReportPresetFilterInput>;
  clearedMediaReportPresetId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  commissionBasedOnRateTypeLookup?: InputMaybe<LookupFilterInput>;
  commissionBasedOnRateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  commissionContingencyActionTypeLookup?: InputMaybe<LookupFilterInput>;
  commissionContingencyActionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScaleDetails?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleDetailFilterInput>;
  commissionSlidingScaleId?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScaleMediaTypes?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleMediaTypeFilterInput>;
  commissionSlidingScalePeriods?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScalePeriodFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contingencyDateUnitQty?: InputMaybe<IntOperationFilterInput>;
  contingencyDateUnitTypeLookup?: InputMaybe<LookupFilterInput>;
  contingencyDateUnitTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  contingencyInitialDelay?: InputMaybe<IntOperationFilterInput>;
  contractDate?: InputMaybe<DateTimeOperationFilterInput>;
  contractFileReference?: InputMaybe<FileReferenceFilterInput>;
  contractFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  isRetroactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  minimumAmount?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<CommissionSlidingScaleFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  resetAmount?: InputMaybe<DecimalOperationFilterInput>;
  resetAmountAlertExists?: InputMaybe<BooleanOperationFilterInput>;
  resetConditionLookup?: InputMaybe<LookupFilterInput>;
  resetConditionLookupId?: InputMaybe<IntOperationFilterInput>;
  resetDate?: InputMaybe<DateTimeOperationFilterInput>;
  resetsAutomatically?: InputMaybe<BooleanOperationFilterInput>;
  thresholdBasedOnRateTypeLookup?: InputMaybe<LookupFilterInput>;
  thresholdBasedOnRateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type CommissionSlidingScaleInput = {
  amendmentDate?: InputMaybe<Scalars['DateTime']>;
  annualBudgetReviewedQuarterly: Scalars['Boolean'];
  calendarTypeLookupId?: InputMaybe<Scalars['Int']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  clearedMediaReportPresetId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  commissionBasedOnRateTypeLookupId?: InputMaybe<Scalars['Int']>;
  commissionContingencyActionTypeLookupId?: InputMaybe<Scalars['Int']>;
  commissionSlidingScaleId?: InputMaybe<Scalars['Int']>;
  commissionSlidingScaleMediaTypes?: InputMaybe<Array<InputMaybe<CommissionSlidingScaleMediaTypeInput>>>;
  companyId?: InputMaybe<Scalars['Int']>;
  contingencyDateUnitQty?: InputMaybe<Scalars['Int']>;
  contingencyDateUnitTypeLookupId?: InputMaybe<Scalars['Int']>;
  contingencyInitialDelay?: InputMaybe<Scalars['Int']>;
  contractDate?: InputMaybe<Scalars['DateTime']>;
  contractFileReferenceId?: InputMaybe<Scalars['Int']>;
  contractTermInputs?: InputMaybe<Array<InputMaybe<ContractTermInput>>>;
  effectiveDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  isRetroactive?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
  minimumAmount?: InputMaybe<Scalars['Decimal']>;
  productId?: InputMaybe<Scalars['Int']>;
  resetAmount?: InputMaybe<Scalars['Decimal']>;
  resetAmountAlertExists?: InputMaybe<Scalars['Boolean']>;
  resetConditionLookupId?: InputMaybe<Scalars['Int']>;
  resetDate?: InputMaybe<Scalars['DateTime']>;
  resetsAutomatically?: InputMaybe<Scalars['Boolean']>;
  thresholdBasedOnRateTypeLookupId?: InputMaybe<Scalars['Int']>;
};

export type CommissionSlidingScaleMediaType = {
  __typename?: 'CommissionSlidingScaleMediaType';
  commissionSlidingScale?: Maybe<CommissionSlidingScale>;
  commissionSlidingScaleId: Scalars['Int'];
  commissionSlidingScaleMediaTypeId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
};

export type CommissionSlidingScaleMediaTypeFilterInput = {
  and?: InputMaybe<Array<CommissionSlidingScaleMediaTypeFilterInput>>;
  commissionSlidingScale?: InputMaybe<CommissionSlidingScaleFilterInput>;
  commissionSlidingScaleId?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScaleMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CommissionSlidingScaleMediaTypeFilterInput>>;
};

export type CommissionSlidingScaleMediaTypeInput = {
  commissionSlidingScaleId?: InputMaybe<Scalars['Int']>;
  commissionSlidingScaleMediaTypeId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
};

export type CommissionSlidingScalePeriod = {
  __typename?: 'CommissionSlidingScalePeriod';
  commissionSlidingScale?: Maybe<CommissionSlidingScale>;
  commissionSlidingScaleId: Scalars['Int'];
  commissionSlidingScalePeriodId: Scalars['Int'];
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc: Scalars['DateTime'];
  manuallyOverridden: Scalars['Boolean'];
  minimumChargeBalance: Scalars['Decimal'];
  periodEndDate?: Maybe<Scalars['Date']>;
  periodStartDate: Scalars['Date'];
};

export type CommissionSlidingScalePeriodFilterInput = {
  and?: InputMaybe<Array<CommissionSlidingScalePeriodFilterInput>>;
  commissionSlidingScale?: InputMaybe<CommissionSlidingScaleFilterInput>;
  commissionSlidingScaleId?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScalePeriodId?: InputMaybe<IntOperationFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  manuallyOverridden?: InputMaybe<BooleanOperationFilterInput>;
  minimumChargeBalance?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<CommissionSlidingScalePeriodFilterInput>>;
  periodEndDate?: InputMaybe<DateOperationFilterInput>;
  periodStartDate?: InputMaybe<DateOperationFilterInput>;
};

export type CommissionSlidingScalePeriodSortInput = {
  commissionSlidingScale?: InputMaybe<CommissionSlidingScaleSortInput>;
  commissionSlidingScaleId?: InputMaybe<SortEnumType>;
  commissionSlidingScalePeriodId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  manuallyOverridden?: InputMaybe<SortEnumType>;
  minimumChargeBalance?: InputMaybe<SortEnumType>;
  periodEndDate?: InputMaybe<SortEnumType>;
  periodStartDate?: InputMaybe<SortEnumType>;
};

export type CommissionSlidingScaleSortInput = {
  amendmentDate?: InputMaybe<SortEnumType>;
  annualBudgetReviewedQuarterly?: InputMaybe<SortEnumType>;
  calendarTypeLookup?: InputMaybe<LookupSortInput>;
  calendarTypeLookupId?: InputMaybe<SortEnumType>;
  campaign?: InputMaybe<CampaignSortInput>;
  campaignId?: InputMaybe<SortEnumType>;
  clearedMediaReportPreset?: InputMaybe<ReportPresetSortInput>;
  clearedMediaReportPresetId?: InputMaybe<SortEnumType>;
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  commissionBasedOnRateTypeLookup?: InputMaybe<LookupSortInput>;
  commissionBasedOnRateTypeLookupId?: InputMaybe<SortEnumType>;
  commissionContingencyActionTypeLookup?: InputMaybe<LookupSortInput>;
  commissionContingencyActionTypeLookupId?: InputMaybe<SortEnumType>;
  commissionSlidingScaleId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  contingencyDateUnitQty?: InputMaybe<SortEnumType>;
  contingencyDateUnitTypeLookup?: InputMaybe<LookupSortInput>;
  contingencyDateUnitTypeLookupId?: InputMaybe<SortEnumType>;
  contingencyInitialDelay?: InputMaybe<SortEnumType>;
  contractDate?: InputMaybe<SortEnumType>;
  contractFileReference?: InputMaybe<FileReferenceSortInput>;
  contractFileReferenceId?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  isRetroactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaType?: InputMaybe<MediaTypeSortInput>;
  mediaTypeId?: InputMaybe<SortEnumType>;
  minimumAmount?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  resetAmount?: InputMaybe<SortEnumType>;
  resetAmountAlertExists?: InputMaybe<SortEnumType>;
  resetConditionLookup?: InputMaybe<LookupSortInput>;
  resetConditionLookupId?: InputMaybe<SortEnumType>;
  resetDate?: InputMaybe<SortEnumType>;
  resetsAutomatically?: InputMaybe<SortEnumType>;
  thresholdBasedOnRateTypeLookup?: InputMaybe<LookupSortInput>;
  thresholdBasedOnRateTypeLookupId?: InputMaybe<SortEnumType>;
};

export type CommissionSortInput = {
  ad?: InputMaybe<AdSortInput>;
  adId?: InputMaybe<SortEnumType>;
  appUser?: InputMaybe<AppUserSortInput>;
  appUserId?: InputMaybe<SortEnumType>;
  campaign?: InputMaybe<CampaignSortInput>;
  campaignId?: InputMaybe<SortEnumType>;
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  commissionId?: InputMaybe<SortEnumType>;
  commissionSsdetailTriggeredBy?: InputMaybe<SortEnumType>;
  commissionSsdetailTriggeredByNavigation?: InputMaybe<CommissionSlidingScaleDetailSortInput>;
  commissionSsperiodTriggeredBy?: InputMaybe<SortEnumType>;
  commissionSsperiodTriggeredByNavigation?: InputMaybe<CommissionSlidingScalePeriodSortInput>;
  commissionSstriggeredBy?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  dateEntered?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  endDateManuallyEdited?: InputMaybe<SortEnumType>;
  internalComment?: InputMaybe<SortEnumType>;
  isProgrammatic?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  manuallyOverridden?: InputMaybe<SortEnumType>;
  mediaType?: InputMaybe<MediaTypeSortInput>;
  mediaTypeId?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  rate?: InputMaybe<SortEnumType>;
};

export type Company = {
  __typename?: 'Company';
  abbreviation?: Maybe<Scalars['String']>;
  accountingBatchLineLinks?: Maybe<Array<Maybe<AccountingBatchLineLink>>>;
  accountingBatches?: Maybe<Array<Maybe<AccountingBatch>>>;
  accountingLineNonPrepaySchedules?: Maybe<Array<Maybe<AccountingLineNonPrepaySchedule>>>;
  accountingLines?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLocks?: Maybe<Array<Maybe<AccountingLock>>>;
  adCampaigns?: Maybe<Array<Maybe<AdCampaign>>>;
  adInstanceDetails?: Maybe<Array<Maybe<AdInstanceDetail>>>;
  adInstanceImportFileImports?: Maybe<Array<Maybe<AdInstanceImportFileImport>>>;
  adInstanceImportTargetDemos?: Maybe<Array<Maybe<AdInstanceImportTargetDemo>>>;
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstances?: Maybe<Array<Maybe<AdInstance>>>;
  adUniqueIscis?: Maybe<Array<Maybe<AdUniqueIsci>>>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  aliases?: Maybe<Array<Maybe<Alias>>>;
  appFields?: Maybe<Array<Maybe<AppField>>>;
  appRibbonButtons?: Maybe<Array<Maybe<AppRibbonButton>>>;
  appRibbonGroups?: Maybe<Array<Maybe<AppRibbonGroup>>>;
  appRibbonTabs?: Maybe<Array<Maybe<AppRibbonTab>>>;
  appScreens?: Maybe<Array<Maybe<AppScreen>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserCompanies?: Maybe<Array<Maybe<AppUserCompany>>>;
  appUserFavoriteReportPresets?: Maybe<Array<Maybe<AppUserFavoriteReportPreset>>>;
  audiences?: Maybe<Array<Maybe<Audience>>>;
  autoReports?: Maybe<Array<Maybe<AutoReport>>>;
  autoScheduleTrafficDetails?: Maybe<Array<Maybe<AutoScheduleTrafficDetail>>>;
  autoScheduleTrafficMediaChanges?: Maybe<Array<Maybe<AutoScheduleTrafficMediaChange>>>;
  autoScheduleTraffics?: Maybe<Array<Maybe<AutoScheduleTraffic>>>;
  autoSchedules?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  autoSources?: Maybe<Array<Maybe<AutoSource>>>;
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  availProposalDetailAttributes?: Maybe<Array<Maybe<AvailProposalDetailAttribute>>>;
  availProposalDetails?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  availProposals?: Maybe<Array<Maybe<AvailProposal>>>;
  bookingOrders?: Maybe<Array<Maybe<BookingOrder>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationFlights?: Maybe<Array<Maybe<BudgetAllocationFlight>>>;
  budgetAllocationTags?: Maybe<Array<Maybe<BudgetAllocationTag>>>;
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetBuyerSplits?: Maybe<Array<Maybe<BudgetBuyerSplit>>>;
  budgetRegionTypeSplits?: Maybe<Array<Maybe<BudgetRegionTypeSplit>>>;
  budgets?: Maybe<Array<Maybe<Budget>>>;
  campaignCrossChannelCampaigns?: Maybe<Array<Maybe<CampaignCrossChannelCampaign>>>;
  campaignGroups?: Maybe<Array<Maybe<CampaignGroup>>>;
  campaignLocalBudgetAssignmentDetails?: Maybe<Array<Maybe<CampaignLocalBudgetAssignmentDetail>>>;
  campaignLocalBudgetAssignments?: Maybe<Array<Maybe<CampaignLocalBudgetAssignment>>>;
  campaignMediaOutlets?: Maybe<Array<Maybe<CampaignMediaOutlet>>>;
  campaignProjectCodes?: Maybe<Array<Maybe<CampaignProjectCode>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  clientAxclientCodes?: Maybe<Array<Maybe<ClientAxclientCode>>>;
  clientContacts?: Maybe<Array<Maybe<ClientContact>>>;
  clientDatoramaKeys?: Maybe<Array<Maybe<ClientDatoramaKey>>>;
  clientFileReferences?: Maybe<Array<Maybe<ClientFileReference>>>;
  clientLookupAliases?: Maybe<Array<Maybe<ClientLookupAlias>>>;
  clientMediaOutlets?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMonthDefinitions?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientVendorServiceMarkups?: Maybe<Array<Maybe<ClientVendorServiceMarkup>>>;
  clients?: Maybe<Array<Maybe<Client>>>;
  commissionSlidingScaleDetails?: Maybe<Array<Maybe<CommissionSlidingScaleDetail>>>;
  commissionSlidingScaleMediaTypes?: Maybe<Array<Maybe<CommissionSlidingScaleMediaType>>>;
  commissionSlidingScalePeriods?: Maybe<Array<Maybe<CommissionSlidingScalePeriod>>>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  companyCode?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  costPerLeadRanges?: Maybe<Array<Maybe<CostPerLeadRange>>>;
  creatives?: Maybe<Array<Maybe<Creative>>>;
  crossChannelCampaignDimensions?: Maybe<Array<Maybe<CrossChannelCampaignDimension>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  crossChannelCampaignGroups?: Maybe<Array<Maybe<CrossChannelCampaignGroup>>>;
  crossChannelCampaignVersions?: Maybe<Array<Maybe<CrossChannelCampaignVersion>>>;
  crossChannelCampaigns?: Maybe<Array<Maybe<CrossChannelCampaign>>>;
  curves?: Maybe<Array<Maybe<Curve>>>;
  datoramaImportJobProducts?: Maybe<Array<Maybe<DatoramaImportJobProduct>>>;
  datoramaImportJobs?: Maybe<Array<Maybe<DatoramaImportJob>>>;
  digitalDeliveryServiceAttributes?: Maybe<Array<Maybe<DigitalDeliveryServiceAttribute>>>;
  digitalResultTypes?: Maybe<Array<Maybe<DigitalResultType>>>;
  digitalSpendImportChannelMappings?: Maybe<Array<Maybe<DigitalSpendImportChannelMapping>>>;
  digitalSpendImportDatoramaKeyMappings?: Maybe<Array<Maybe<DigitalSpendImportDatoramaKeyMapping>>>;
  digitalSpendImportFieldMappings?: Maybe<Array<Maybe<DigitalSpendImportFieldMapping>>>;
  digitalSpendImportValueMappings?: Maybe<Array<Maybe<DigitalSpendImportValueMapping>>>;
  digitalSpendInputFiles?: Maybe<Array<Maybe<DigitalSpendInputFile>>>;
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  dmainfoBudgets?: Maybe<Array<Maybe<DmainfoBudget>>>;
  dmainfoBuyers?: Maybe<Array<Maybe<DmainfoBuyer>>>;
  dubOrderTransmissions?: Maybe<Array<Maybe<DubOrderTransmission>>>;
  duplicationCostEstimateAds?: Maybe<Array<Maybe<DuplicationCostEstimateAd>>>;
  duplicationCostEstimateDetails?: Maybe<Array<Maybe<DuplicationCostEstimateDetail>>>;
  duplicationCostEstimateMediaOutlets?: Maybe<Array<Maybe<DuplicationCostEstimateMediaOutlet>>>;
  duplicationCostEstimates?: Maybe<Array<Maybe<DuplicationCostEstimate>>>;
  duplicationInvoiceLines?: Maybe<Array<Maybe<DuplicationInvoiceLine>>>;
  duplicationLayouts?: Maybe<Array<Maybe<DuplicationLayout>>>;
  duplicationPresets?: Maybe<Array<Maybe<DuplicationPreset>>>;
  edgeColor?: Maybe<Scalars['String']>;
  edgeIcon?: Maybe<Scalars['String']>;
  fileImportMaps?: Maybe<Array<Maybe<FileImportMap>>>;
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
  fileReferences?: Maybe<Array<Maybe<FileReference>>>;
  fileToProcesses?: Maybe<Array<Maybe<FileToProcess>>>;
  files?: Maybe<Array<Maybe<File>>>;
  gareportSkudefinitions?: Maybe<Array<Maybe<GareportSkudefinition>>>;
  gareports?: Maybe<Array<Maybe<Gareport>>>;
  glanceReportDimensions?: Maybe<Array<Maybe<GlanceReportDimension>>>;
  glanceReportElements?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportRanges?: Maybe<Array<Maybe<GlanceReportRange>>>;
  glanceReportWorksheets?: Maybe<Array<Maybe<GlanceReportWorksheet>>>;
  glanceReports?: Maybe<Array<Maybe<GlanceReport>>>;
  imsdetections?: Maybe<Array<Maybe<Imsdetection>>>;
  inputFieldMappingSchemes?: Maybe<Array<Maybe<InputFieldMappingScheme>>>;
  inputFieldMappings?: Maybe<Array<Maybe<InputFieldMapping>>>;
  insertionOrderContacts?: Maybe<Array<Maybe<InsertionOrderContact>>>;
  insertionOrderDetailPlacements?: Maybe<Array<Maybe<InsertionOrderDetailPlacement>>>;
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  insertionOrderFileReferences?: Maybe<Array<Maybe<InsertionOrderFileReference>>>;
  insertionOrderPlacementResults?: Maybe<Array<Maybe<InsertionOrderPlacementResult>>>;
  insertionOrders?: Maybe<Array<Maybe<InsertionOrder>>>;
  invoiceSchedules?: Maybe<Array<Maybe<InvoiceSchedule>>>;
  invoiceServiceInstances?: Maybe<Array<Maybe<InvoiceServiceInstance>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  issueDateInfos?: Maybe<Array<Maybe<IssueDateInfo>>>;
  jobAppUsers?: Maybe<Array<Maybe<JobAppUser>>>;
  jobDependencies?: Maybe<Array<Maybe<JobDependency>>>;
  jobInstanceLogs?: Maybe<Array<Maybe<JobInstanceLog>>>;
  jobInstances?: Maybe<Array<Maybe<JobInstance>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingLocks?: Maybe<Array<Maybe<LeadBillingLock>>>;
  localMarketCostPers?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateAdLengths?: Maybe<Array<Maybe<LocalMarketRatingEstimateAdLength>>>;
  localMarketRatingEstimateDetails?: Maybe<Array<Maybe<LocalMarketRatingEstimateDetail>>>;
  localMarketRatingEstimateMarketGoals?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarketGoal>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  localMarketRatingEstimates?: Maybe<Array<Maybe<LocalMarketRatingEstimate>>>;
  logoImage?: Maybe<Array<Scalars['Byte']>>;
  logoMimeType?: Maybe<Scalars['String']>;
  lookups?: Maybe<Array<Maybe<Lookup>>>;
  marketContributionAnalyses?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  mediaAuthorizationDimensions?: Maybe<Array<Maybe<MediaAuthorizationDimension>>>;
  mediaAuthorizations?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaAvailRates?: Maybe<Array<Maybe<MediaAvailRate>>>;
  mediaAvailRatings?: Maybe<Array<Maybe<MediaAvailRating>>>;
  mediaAvails?: Maybe<Array<Maybe<MediaAvail>>>;
  mediaLibraries?: Maybe<Array<Maybe<MediaLibrary>>>;
  mediaOutletAddresses?: Maybe<Array<Maybe<MediaOutletAddress>>>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaOutletClusters?: Maybe<Array<Maybe<MediaOutletCluster>>>;
  mediaOutletContacts?: Maybe<Array<Maybe<MediaOutletContact>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutletGroups?: Maybe<Array<Maybe<MediaOutletGroup>>>;
  mediaOutletMediaTypeDigitalDeliveryServices?: Maybe<Array<Maybe<MediaOutletMediaTypeDigitalDeliveryService>>>;
  mediaOutletMediaTypeVendorCodes?: Maybe<Array<Maybe<MediaOutletMediaTypeVendorCode>>>;
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMirrorRotations?: Maybe<Array<Maybe<MediaOutletMirrorRotation>>>;
  mediaOutletOfferings?: Maybe<Array<Maybe<MediaOutletOffering>>>;
  mediaOutletParsers?: Maybe<Array<Maybe<MediaOutletParser>>>;
  mediaOutletPrintFormats?: Maybe<Array<Maybe<MediaOutletPrintFormat>>>;
  mediaOutletPrintTerritories?: Maybe<Array<Maybe<MediaOutletPrintTerritory>>>;
  mediaOutletRates?: Maybe<Array<Maybe<MediaOutletRate>>>;
  mediaOutletRegions?: Maybe<Array<Maybe<MediaOutletRegion>>>;
  mediaOutletRoles?: Maybe<Array<Maybe<MediaOutletRole>>>;
  mediaOutletRotations?: Maybe<Array<Maybe<MediaOutletRotation>>>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaPlanExportExcelOptions?: Maybe<Array<Maybe<MediaPlanExportExcelOption>>>;
  mediaPlanNielsenDemos?: Maybe<Array<Maybe<MediaPlanNielsenDemo>>>;
  mediaPlanRentrakDemographics?: Maybe<Array<Maybe<MediaPlanRentrakDemographic>>>;
  mediaPlanTapeLengths?: Maybe<Array<Maybe<MediaPlanTapeLength>>>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  mediaScheduleAds?: Maybe<Array<Maybe<MediaScheduleAd>>>;
  mediaScheduleDetailAttributes?: Maybe<Array<Maybe<MediaScheduleDetailAttribute>>>;
  mediaScheduleDetailHistoricalKpimatches?: Maybe<Array<Maybe<MediaScheduleDetailHistoricalKpimatch>>>;
  mediaScheduleDetailInstances?: Maybe<Array<Maybe<MediaScheduleDetailInstance>>>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDisplays?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTransmissionNotifications?: Maybe<Array<Maybe<MediaTransmissionNotification>>>;
  mediaTransmissions?: Maybe<Array<Maybe<MediaTransmission>>>;
  mriindices?: Maybe<Array<Maybe<Mriindex>>>;
  newCampaignAnnouncementFileReferences?: Maybe<Array<Maybe<NewCampaignAnnouncementFileReference>>>;
  newCampaignAnnouncementPiassociations?: Maybe<Array<Maybe<NewCampaignAnnouncementPiassociation>>>;
  newCampaignAnnouncementPimediaOutlets?: Maybe<Array<Maybe<NewCampaignAnnouncementPimediaOutlet>>>;
  newCampaignAnnouncementPis?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAssociations?: Maybe<Array<Maybe<NewCampaignAssociation>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  onlineScheduleAttributes?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  packages?: Maybe<Array<Maybe<Package>>>;
  parentInvoices?: Maybe<Array<Maybe<ParentInvoice>>>;
  parserFileReferences?: Maybe<Array<Maybe<ParserFileReference>>>;
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  prelogEstimates?: Maybe<Array<Maybe<PrelogEstimate>>>;
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  printAvails?: Maybe<Array<Maybe<PrintAvail>>>;
  printScheduleAttributes?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printTerritories?: Maybe<Array<Maybe<PrintTerritory>>>;
  processors?: Maybe<Array<Maybe<Processor>>>;
  prodColor?: Maybe<Scalars['String']>;
  prodIcon?: Maybe<Scalars['String']>;
  productAudiences?: Maybe<Array<Maybe<ProductAudience>>>;
  productDateBlackLists?: Maybe<Array<Maybe<ProductDateBlackList>>>;
  productDimensionColumns?: Maybe<Array<Maybe<ProductDimensionColumn>>>;
  productDimensionTypes?: Maybe<Array<Maybe<ProductDimensionType>>>;
  productDimensions?: Maybe<Array<Maybe<ProductDimension>>>;
  productFulfillments?: Maybe<Array<Maybe<ProductFulfillment>>>;
  productHours?: Maybe<Array<Maybe<ProductHour>>>;
  productMediaOutletBlackLists?: Maybe<Array<Maybe<ProductMediaOutletBlackList>>>;
  productMediaOutletPirates?: Maybe<Array<Maybe<ProductMediaOutletPirate>>>;
  productMediaTargets?: Maybe<Array<Maybe<ProductMediaTarget>>>;
  productMediaTypeLockDates?: Maybe<Array<Maybe<ProductMediaTypeLockDate>>>;
  productOfferGroups?: Maybe<Array<Maybe<ProductOfferGroup>>>;
  productProgramBlackLists?: Maybe<Array<Maybe<ProductProgramBlackList>>>;
  productRegionBlackLists?: Maybe<Array<Maybe<ProductRegionBlackList>>>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  productionWestAgencyId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<Product>>>;
  rateCards?: Maybe<Array<Maybe<RateCard>>>;
  ratingAdInstances?: Maybe<Array<Maybe<RatingAdInstance>>>;
  ratingAvailProposalDetails?: Maybe<Array<Maybe<RatingAvailProposalDetail>>>;
  ratingMediaScheduleDetails?: Maybe<Array<Maybe<RatingMediaScheduleDetail>>>;
  ratingSchedules?: Maybe<Array<Maybe<RatingSchedule>>>;
  ratings?: Maybe<Array<Maybe<Rating>>>;
  rentrakRatingAdInstances?: Maybe<Array<Maybe<RentrakRatingAdInstance>>>;
  rentrakRatingSchedules?: Maybe<Array<Maybe<RentrakRatingSchedule>>>;
  rentrakTelecastDetails?: Maybe<Array<Maybe<RentrakTelecastDetail>>>;
  reportExportFormats?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportPresets?: Maybe<Array<Maybe<ReportPreset>>>;
  resultDetails?: Maybe<Array<Maybe<ResultDetail>>>;
  resultManualEntryDetails?: Maybe<Array<Maybe<ResultManualEntryDetail>>>;
  resultManualEntryPresets?: Maybe<Array<Maybe<ResultManualEntryPreset>>>;
  resultStagingDetails?: Maybe<Array<Maybe<ResultStagingDetail>>>;
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  revisionGroups?: Maybe<Array<Maybe<RevisionGroup>>>;
  revisionRecords?: Maybe<Array<Maybe<RevisionRecord>>>;
  revisionRequests?: Maybe<Array<Maybe<RevisionRequest>>>;
  revisions?: Maybe<Array<Maybe<Revision>>>;
  rvresultDetails?: Maybe<Array<Maybe<RvresultDetail>>>;
  rvresults?: Maybe<Array<Maybe<Rvresult>>>;
  scheduleAttributes?: Maybe<Array<Maybe<ScheduleAttribute>>>;
  scheduleCostAdjustments?: Maybe<Array<Maybe<ScheduleCostAdjustment>>>;
  scheduleFileImports?: Maybe<Array<Maybe<ScheduleFileImport>>>;
  scheduleFileReferences?: Maybe<Array<Maybe<ScheduleFileReference>>>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  scriptAds?: Maybe<Array<Maybe<ScriptAd>>>;
  scriptGroupScripts?: Maybe<Array<Maybe<ScriptGroupScript>>>;
  scriptGroups?: Maybe<Array<Maybe<ScriptGroup>>>;
  scriptSkus?: Maybe<Array<Maybe<ScriptSku>>>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  skugroupSkus?: Maybe<Array<Maybe<SkugroupSku>>>;
  skugroups?: Maybe<Array<Maybe<Skugroup>>>;
  skuprices?: Maybe<Array<Maybe<Skuprice>>>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  spikeBaseLines?: Maybe<Array<Maybe<SpikeBaseLine>>>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
  surveyQuestionAnswers?: Maybe<Array<Maybe<SurveyQuestionAnswer>>>;
  surveyQuestions?: Maybe<Array<Maybe<SurveyQuestion>>>;
  surveyResponseDetails?: Maybe<Array<Maybe<SurveyResponseDetail>>>;
  surveyResponses?: Maybe<Array<Maybe<SurveyResponse>>>;
  surveySurveyQuestions?: Maybe<Array<Maybe<SurveySurveyQuestion>>>;
  surveys?: Maybe<Array<Maybe<Survey>>>;
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  targets?: Maybe<Array<Maybe<Target>>>;
  taskAssociatedRecords?: Maybe<Array<Maybe<TaskAssociatedRecord>>>;
  taskHistories?: Maybe<Array<Maybe<TaskHistory>>>;
  taskHistoryFileReferences?: Maybe<Array<Maybe<TaskHistoryFileReference>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  testColor?: Maybe<Scalars['String']>;
  testIcon?: Maybe<Scalars['String']>;
  titles?: Maybe<Array<Maybe<Title>>>;
  tmpAccountingBatchLineLinks?: Maybe<Array<Maybe<TmpAccountingBatchLineLink>>>;
  tmpAccountingBatches?: Maybe<Array<Maybe<TmpAccountingBatch>>>;
  tmpAccountingLines?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  tmpInvoices?: Maybe<Array<Maybe<TmpInvoice>>>;
  topLineMediaPlanDetailAttributes?: Maybe<Array<Maybe<TopLineMediaPlanDetailAttribute>>>;
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  topLineMediaPlans?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  trackingCodeAvailTrackingCodes?: Maybe<Array<Maybe<TrackingCodeAvailTrackingCode>>>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodeImports?: Maybe<Array<Maybe<TrackingCodeImport>>>;
  trackingCodeMaps?: Maybe<Array<Maybe<TrackingCodeMap>>>;
  trackingCodes?: Maybe<Array<Maybe<TrackingCode>>>;
  trackingServiceLines?: Maybe<Array<Maybe<TrackingServiceLine>>>;
  trafficSheetDetailDevices?: Maybe<Array<Maybe<TrafficSheetDetailDevice>>>;
  trafficSheetDetails?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheets?: Maybe<Array<Maybe<TrafficSheet>>>;
  uniqueAdScheduleLeadAdjustments?: Maybe<Array<Maybe<UniqueAdScheduleLeadAdjustment>>>;
  uniqueAdSchedules?: Maybe<Array<Maybe<UniqueAdSchedule>>>;
  uniqueAdTrackingCodes?: Maybe<Array<Maybe<UniqueAdTrackingCode>>>;
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  vendorBlackoutDates?: Maybe<Array<Maybe<VendorBlackoutDate>>>;
  vendorContactRoles?: Maybe<Array<Maybe<VendorContactRole>>>;
  vendorDirectPayments?: Maybe<Array<Maybe<VendorDirectPayment>>>;
  vendorServiceCosts?: Maybe<Array<Maybe<VendorServiceCost>>>;
  vendorServiceMediaTypes?: Maybe<Array<Maybe<VendorServiceMediaType>>>;
  vendorServices?: Maybe<Array<Maybe<VendorService>>>;
  vendors?: Maybe<Array<Maybe<Vendor>>>;
  waveCastAdInstances?: Maybe<Array<Maybe<WaveCastAdInstance>>>;
  waveCastDetails?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastMappings?: Maybe<Array<Maybe<WaveCastMapping>>>;
  waveCastModelSkugroups?: Maybe<Array<Maybe<WaveCastModelSkugroup>>>;
  waveCastModels?: Maybe<Array<Maybe<WaveCastModel>>>;
  waveCastWaves?: Maybe<Array<Maybe<WaveCastWave>>>;
};

export type CompanyFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  accountingBatchLineLinks?: InputMaybe<ListFilterInputTypeOfAccountingBatchLineLinkFilterInput>;
  accountingBatches?: InputMaybe<ListFilterInputTypeOfAccountingBatchFilterInput>;
  accountingLineNonPrepaySchedules?: InputMaybe<ListFilterInputTypeOfAccountingLineNonPrepayScheduleFilterInput>;
  accountingLines?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  accountingLocks?: InputMaybe<ListFilterInputTypeOfAccountingLockFilterInput>;
  adCampaigns?: InputMaybe<ListFilterInputTypeOfAdCampaignFilterInput>;
  adInstanceDetails?: InputMaybe<ListFilterInputTypeOfAdInstanceDetailFilterInput>;
  adInstanceImportFileImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFileImportFilterInput>;
  adInstanceImportTargetDemos?: InputMaybe<ListFilterInputTypeOfAdInstanceImportTargetDemoFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstances?: InputMaybe<ListFilterInputTypeOfAdInstanceFilterInput>;
  adUniqueIscis?: InputMaybe<ListFilterInputTypeOfAdUniqueIsciFilterInput>;
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  aliases?: InputMaybe<ListFilterInputTypeOfAliasFilterInput>;
  and?: InputMaybe<Array<CompanyFilterInput>>;
  appFields?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appRibbonButtons?: InputMaybe<ListFilterInputTypeOfAppRibbonButtonFilterInput>;
  appRibbonGroups?: InputMaybe<ListFilterInputTypeOfAppRibbonGroupFilterInput>;
  appRibbonTabs?: InputMaybe<ListFilterInputTypeOfAppRibbonTabFilterInput>;
  appScreens?: InputMaybe<ListFilterInputTypeOfAppScreenFilterInput>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  appUserCompanies?: InputMaybe<ListFilterInputTypeOfAppUserCompanyFilterInput>;
  appUserFavoriteReportPresets?: InputMaybe<ListFilterInputTypeOfAppUserFavoriteReportPresetFilterInput>;
  audiences?: InputMaybe<ListFilterInputTypeOfAudienceFilterInput>;
  autoReports?: InputMaybe<ListFilterInputTypeOfAutoReportFilterInput>;
  autoScheduleTrafficDetails?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficDetailFilterInput>;
  autoScheduleTrafficMediaChanges?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficMediaChangeFilterInput>;
  autoScheduleTraffics?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficFilterInput>;
  autoSchedules?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  autoSources?: InputMaybe<ListFilterInputTypeOfAutoSourceFilterInput>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  availProposalDetailAttributes?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailAttributeFilterInput>;
  availProposalDetails?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  availProposals?: InputMaybe<ListFilterInputTypeOfAvailProposalFilterInput>;
  bookingOrders?: InputMaybe<ListFilterInputTypeOfBookingOrderFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  budgetAllocationFees?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFeeFilterInput>;
  budgetAllocationFlights?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFlightFilterInput>;
  budgetAllocationTags?: InputMaybe<ListFilterInputTypeOfBudgetAllocationTagFilterInput>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetBuyerSplits?: InputMaybe<ListFilterInputTypeOfBudgetBuyerSplitFilterInput>;
  budgetRegionTypeSplits?: InputMaybe<ListFilterInputTypeOfBudgetRegionTypeSplitFilterInput>;
  budgets?: InputMaybe<ListFilterInputTypeOfBudgetFilterInput>;
  campaignCrossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCampaignCrossChannelCampaignFilterInput>;
  campaignGroups?: InputMaybe<ListFilterInputTypeOfCampaignGroupFilterInput>;
  campaignLocalBudgetAssignmentDetails?: InputMaybe<ListFilterInputTypeOfCampaignLocalBudgetAssignmentDetailFilterInput>;
  campaignLocalBudgetAssignments?: InputMaybe<ListFilterInputTypeOfCampaignLocalBudgetAssignmentFilterInput>;
  campaignMediaOutlets?: InputMaybe<ListFilterInputTypeOfCampaignMediaOutletFilterInput>;
  campaignProjectCodes?: InputMaybe<ListFilterInputTypeOfCampaignProjectCodeFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  clientAxclientCodes?: InputMaybe<ListFilterInputTypeOfClientAxclientCodeFilterInput>;
  clientContacts?: InputMaybe<ListFilterInputTypeOfClientContactFilterInput>;
  clientDatoramaKeys?: InputMaybe<ListFilterInputTypeOfClientDatoramaKeyFilterInput>;
  clientFileReferences?: InputMaybe<ListFilterInputTypeOfClientFileReferenceFilterInput>;
  clientLookupAliases?: InputMaybe<ListFilterInputTypeOfClientLookupAliasFilterInput>;
  clientMediaOutlets?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMonthDefinitions?: InputMaybe<ListFilterInputTypeOfClientMonthDefinitionFilterInput>;
  clientVendorServiceMarkups?: InputMaybe<ListFilterInputTypeOfClientVendorServiceMarkupFilterInput>;
  clients?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  commissionSlidingScaleDetails?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleDetailFilterInput>;
  commissionSlidingScaleMediaTypes?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleMediaTypeFilterInput>;
  commissionSlidingScalePeriods?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScalePeriodFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  companyCode?: InputMaybe<StringOperationFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  contacts?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  costPerLeadRanges?: InputMaybe<ListFilterInputTypeOfCostPerLeadRangeFilterInput>;
  creatives?: InputMaybe<ListFilterInputTypeOfCreativeFilterInput>;
  crossChannelCampaignDimensions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignDimensionFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  crossChannelCampaignGroups?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignGroupFilterInput>;
  crossChannelCampaignVersions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignVersionFilterInput>;
  crossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFilterInput>;
  curves?: InputMaybe<ListFilterInputTypeOfCurveFilterInput>;
  datoramaImportJobProducts?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobProductFilterInput>;
  datoramaImportJobs?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobFilterInput>;
  digitalDeliveryServiceAttributes?: InputMaybe<ListFilterInputTypeOfDigitalDeliveryServiceAttributeFilterInput>;
  digitalResultTypes?: InputMaybe<ListFilterInputTypeOfDigitalResultTypeFilterInput>;
  digitalSpendImportChannelMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportChannelMappingFilterInput>;
  digitalSpendImportDatoramaKeyMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportDatoramaKeyMappingFilterInput>;
  digitalSpendImportFieldMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportFieldMappingFilterInput>;
  digitalSpendImportValueMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportValueMappingFilterInput>;
  digitalSpendInputFiles?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputFileFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  dmainfoBudgets?: InputMaybe<ListFilterInputTypeOfDmainfoBudgetFilterInput>;
  dmainfoBuyers?: InputMaybe<ListFilterInputTypeOfDmainfoBuyerFilterInput>;
  dubOrderTransmissions?: InputMaybe<ListFilterInputTypeOfDubOrderTransmissionFilterInput>;
  duplicationCostEstimateAds?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateAdFilterInput>;
  duplicationCostEstimateDetails?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateDetailFilterInput>;
  duplicationCostEstimateMediaOutlets?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateMediaOutletFilterInput>;
  duplicationCostEstimates?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateFilterInput>;
  duplicationInvoiceLines?: InputMaybe<ListFilterInputTypeOfDuplicationInvoiceLineFilterInput>;
  duplicationLayouts?: InputMaybe<ListFilterInputTypeOfDuplicationLayoutFilterInput>;
  duplicationPresets?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  edgeColor?: InputMaybe<StringOperationFilterInput>;
  edgeIcon?: InputMaybe<StringOperationFilterInput>;
  fileImportMaps?: InputMaybe<ListFilterInputTypeOfFileImportMapFilterInput>;
  fileImports?: InputMaybe<ListFilterInputTypeOfFileImportFilterInput>;
  fileReferences?: InputMaybe<ListFilterInputTypeOfFileReferenceFilterInput>;
  fileToProcesses?: InputMaybe<ListFilterInputTypeOfFileToProcessFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFileFilterInput>;
  gareportSkudefinitions?: InputMaybe<ListFilterInputTypeOfGareportSkudefinitionFilterInput>;
  gareports?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  glanceReportDimensions?: InputMaybe<ListFilterInputTypeOfGlanceReportDimensionFilterInput>;
  glanceReportElements?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportRanges?: InputMaybe<ListFilterInputTypeOfGlanceReportRangeFilterInput>;
  glanceReportWorksheets?: InputMaybe<ListFilterInputTypeOfGlanceReportWorksheetFilterInput>;
  glanceReports?: InputMaybe<ListFilterInputTypeOfGlanceReportFilterInput>;
  imsdetections?: InputMaybe<ListFilterInputTypeOfImsdetectionFilterInput>;
  inputFieldMappingSchemes?: InputMaybe<ListFilterInputTypeOfInputFieldMappingSchemeFilterInput>;
  inputFieldMappings?: InputMaybe<ListFilterInputTypeOfInputFieldMappingFilterInput>;
  insertionOrderContacts?: InputMaybe<ListFilterInputTypeOfInsertionOrderContactFilterInput>;
  insertionOrderDetailPlacements?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailPlacementFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  insertionOrderFileReferences?: InputMaybe<ListFilterInputTypeOfInsertionOrderFileReferenceFilterInput>;
  insertionOrderPlacementResults?: InputMaybe<ListFilterInputTypeOfInsertionOrderPlacementResultFilterInput>;
  insertionOrders?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  invoiceSchedules?: InputMaybe<ListFilterInputTypeOfInvoiceScheduleFilterInput>;
  invoiceServiceInstances?: InputMaybe<ListFilterInputTypeOfInvoiceServiceInstanceFilterInput>;
  invoices?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  issueDateInfos?: InputMaybe<ListFilterInputTypeOfIssueDateInfoFilterInput>;
  jobAppUsers?: InputMaybe<ListFilterInputTypeOfJobAppUserFilterInput>;
  jobDependencies?: InputMaybe<ListFilterInputTypeOfJobDependencyFilterInput>;
  jobInstanceLogs?: InputMaybe<ListFilterInputTypeOfJobInstanceLogFilterInput>;
  jobInstances?: InputMaybe<ListFilterInputTypeOfJobInstanceFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingLocks?: InputMaybe<ListFilterInputTypeOfLeadBillingLockFilterInput>;
  localMarketCostPers?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateAdLengths?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateAdLengthFilterInput>;
  localMarketRatingEstimateDetails?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateDetailFilterInput>;
  localMarketRatingEstimateMarketGoals?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketGoalFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  localMarketRatingEstimates?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateFilterInput>;
  logoImage?: InputMaybe<ListByteOperationFilterInput>;
  logoMimeType?: InputMaybe<StringOperationFilterInput>;
  lookups?: InputMaybe<ListFilterInputTypeOfLookupFilterInput>;
  marketContributionAnalyses?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  mediaAuthorizationDimensions?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationDimensionFilterInput>;
  mediaAuthorizations?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaAvailRates?: InputMaybe<ListFilterInputTypeOfMediaAvailRateFilterInput>;
  mediaAvailRatings?: InputMaybe<ListFilterInputTypeOfMediaAvailRatingFilterInput>;
  mediaAvails?: InputMaybe<ListFilterInputTypeOfMediaAvailFilterInput>;
  mediaLibraries?: InputMaybe<ListFilterInputTypeOfMediaLibraryFilterInput>;
  mediaOutletAddresses?: InputMaybe<ListFilterInputTypeOfMediaOutletAddressFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaOutletClusters?: InputMaybe<ListFilterInputTypeOfMediaOutletClusterFilterInput>;
  mediaOutletContacts?: InputMaybe<ListFilterInputTypeOfMediaOutletContactFilterInput>;
  mediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfMediaOutletFileReferenceFilterInput>;
  mediaOutletGroups?: InputMaybe<ListFilterInputTypeOfMediaOutletGroupFilterInput>;
  mediaOutletMediaTypeDigitalDeliveryServices?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeDigitalDeliveryServiceFilterInput>;
  mediaOutletMediaTypeVendorCodes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeVendorCodeFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMirrorRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletMirrorRotationFilterInput>;
  mediaOutletOfferings?: InputMaybe<ListFilterInputTypeOfMediaOutletOfferingFilterInput>;
  mediaOutletParsers?: InputMaybe<ListFilterInputTypeOfMediaOutletParserFilterInput>;
  mediaOutletPrintFormats?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintFormatFilterInput>;
  mediaOutletPrintTerritories?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintTerritoryFilterInput>;
  mediaOutletRates?: InputMaybe<ListFilterInputTypeOfMediaOutletRateFilterInput>;
  mediaOutletRegions?: InputMaybe<ListFilterInputTypeOfMediaOutletRegionFilterInput>;
  mediaOutletRoles?: InputMaybe<ListFilterInputTypeOfMediaOutletRoleFilterInput>;
  mediaOutletRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletRotationFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaPlanExportExcelOptions?: InputMaybe<ListFilterInputTypeOfMediaPlanExportExcelOptionFilterInput>;
  mediaPlanNielsenDemos?: InputMaybe<ListFilterInputTypeOfMediaPlanNielsenDemoFilterInput>;
  mediaPlanRentrakDemographics?: InputMaybe<ListFilterInputTypeOfMediaPlanRentrakDemographicFilterInput>;
  mediaPlanTapeLengths?: InputMaybe<ListFilterInputTypeOfMediaPlanTapeLengthFilterInput>;
  mediaPlans?: InputMaybe<ListFilterInputTypeOfMediaPlanFilterInput>;
  mediaScheduleAds?: InputMaybe<ListFilterInputTypeOfMediaScheduleAdFilterInput>;
  mediaScheduleDetailAttributes?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailAttributeFilterInput>;
  mediaScheduleDetailHistoricalKpimatches?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailHistoricalKpimatchFilterInput>;
  mediaScheduleDetailInstances?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailInstanceFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDisplays?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaTransmissionNotifications?: InputMaybe<ListFilterInputTypeOfMediaTransmissionNotificationFilterInput>;
  mediaTransmissions?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mriindices?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  newCampaignAnnouncementFileReferences?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFileReferenceFilterInput>;
  newCampaignAnnouncementPiassociations?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiassociationFilterInput>;
  newCampaignAnnouncementPimediaOutlets?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPimediaOutletFilterInput>;
  newCampaignAnnouncementPis?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAssociations?: InputMaybe<ListFilterInputTypeOfNewCampaignAssociationFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  notes?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  onlineScheduleAttributes?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
  packages?: InputMaybe<ListFilterInputTypeOfPackageFilterInput>;
  parentInvoices?: InputMaybe<ListFilterInputTypeOfParentInvoiceFilterInput>;
  parserFileReferences?: InputMaybe<ListFilterInputTypeOfParserFileReferenceFilterInput>;
  perInquiryCosts?: InputMaybe<ListFilterInputTypeOfPerInquiryCostFilterInput>;
  prelogEstimates?: InputMaybe<ListFilterInputTypeOfPrelogEstimateFilterInput>;
  prelogs?: InputMaybe<ListFilterInputTypeOfPrelogFilterInput>;
  printAvails?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  printScheduleAttributes?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  printTerritories?: InputMaybe<ListFilterInputTypeOfPrintTerritoryFilterInput>;
  processors?: InputMaybe<ListFilterInputTypeOfProcessorFilterInput>;
  prodColor?: InputMaybe<StringOperationFilterInput>;
  prodIcon?: InputMaybe<StringOperationFilterInput>;
  productAudiences?: InputMaybe<ListFilterInputTypeOfProductAudienceFilterInput>;
  productDateBlackLists?: InputMaybe<ListFilterInputTypeOfProductDateBlackListFilterInput>;
  productDimensionColumns?: InputMaybe<ListFilterInputTypeOfProductDimensionColumnFilterInput>;
  productDimensionTypes?: InputMaybe<ListFilterInputTypeOfProductDimensionTypeFilterInput>;
  productDimensions?: InputMaybe<ListFilterInputTypeOfProductDimensionFilterInput>;
  productFulfillments?: InputMaybe<ListFilterInputTypeOfProductFulfillmentFilterInput>;
  productHours?: InputMaybe<ListFilterInputTypeOfProductHourFilterInput>;
  productMediaOutletBlackLists?: InputMaybe<ListFilterInputTypeOfProductMediaOutletBlackListFilterInput>;
  productMediaOutletPirates?: InputMaybe<ListFilterInputTypeOfProductMediaOutletPirateFilterInput>;
  productMediaTargets?: InputMaybe<ListFilterInputTypeOfProductMediaTargetFilterInput>;
  productMediaTypeLockDates?: InputMaybe<ListFilterInputTypeOfProductMediaTypeLockDateFilterInput>;
  productOfferGroups?: InputMaybe<ListFilterInputTypeOfProductOfferGroupFilterInput>;
  productProgramBlackLists?: InputMaybe<ListFilterInputTypeOfProductProgramBlackListFilterInput>;
  productRegionBlackLists?: InputMaybe<ListFilterInputTypeOfProductRegionBlackListFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  productionWestAgencyId?: InputMaybe<IntOperationFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  rateCards?: InputMaybe<ListFilterInputTypeOfRateCardFilterInput>;
  ratingAdInstances?: InputMaybe<ListFilterInputTypeOfRatingAdInstanceFilterInput>;
  ratingAvailProposalDetails?: InputMaybe<ListFilterInputTypeOfRatingAvailProposalDetailFilterInput>;
  ratingMediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfRatingMediaScheduleDetailFilterInput>;
  ratingSchedules?: InputMaybe<ListFilterInputTypeOfRatingScheduleFilterInput>;
  ratings?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  rentrakRatingAdInstances?: InputMaybe<ListFilterInputTypeOfRentrakRatingAdInstanceFilterInput>;
  rentrakRatingSchedules?: InputMaybe<ListFilterInputTypeOfRentrakRatingScheduleFilterInput>;
  rentrakTelecastDetails?: InputMaybe<ListFilterInputTypeOfRentrakTelecastDetailFilterInput>;
  reportExportFormats?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportPresets?: InputMaybe<ListFilterInputTypeOfReportPresetFilterInput>;
  resultDetails?: InputMaybe<ListFilterInputTypeOfResultDetailFilterInput>;
  resultManualEntryDetails?: InputMaybe<ListFilterInputTypeOfResultManualEntryDetailFilterInput>;
  resultManualEntryPresets?: InputMaybe<ListFilterInputTypeOfResultManualEntryPresetFilterInput>;
  resultStagingDetails?: InputMaybe<ListFilterInputTypeOfResultStagingDetailFilterInput>;
  resultStagings?: InputMaybe<ListFilterInputTypeOfResultStagingFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  revisionGroups?: InputMaybe<ListFilterInputTypeOfRevisionGroupFilterInput>;
  revisionRecords?: InputMaybe<ListFilterInputTypeOfRevisionRecordFilterInput>;
  revisionRequests?: InputMaybe<ListFilterInputTypeOfRevisionRequestFilterInput>;
  revisions?: InputMaybe<ListFilterInputTypeOfRevisionFilterInput>;
  rvresultDetails?: InputMaybe<ListFilterInputTypeOfRvresultDetailFilterInput>;
  rvresults?: InputMaybe<ListFilterInputTypeOfRvresultFilterInput>;
  scheduleAttributes?: InputMaybe<ListFilterInputTypeOfScheduleAttributeFilterInput>;
  scheduleCostAdjustments?: InputMaybe<ListFilterInputTypeOfScheduleCostAdjustmentFilterInput>;
  scheduleFileImports?: InputMaybe<ListFilterInputTypeOfScheduleFileImportFilterInput>;
  scheduleFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFileReferenceFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scriptAds?: InputMaybe<ListFilterInputTypeOfScriptAdFilterInput>;
  scriptGroupScripts?: InputMaybe<ListFilterInputTypeOfScriptGroupScriptFilterInput>;
  scriptGroups?: InputMaybe<ListFilterInputTypeOfScriptGroupFilterInput>;
  scriptSkus?: InputMaybe<ListFilterInputTypeOfScriptSkuFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  skugroupSkus?: InputMaybe<ListFilterInputTypeOfSkugroupSkuFilterInput>;
  skugroups?: InputMaybe<ListFilterInputTypeOfSkugroupFilterInput>;
  skuprices?: InputMaybe<ListFilterInputTypeOfSkupriceFilterInput>;
  skus?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  spikeBaseLines?: InputMaybe<ListFilterInputTypeOfSpikeBaseLineFilterInput>;
  standardsAndPracticesApprovals?: InputMaybe<ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput>;
  surveyQuestionAnswers?: InputMaybe<ListFilterInputTypeOfSurveyQuestionAnswerFilterInput>;
  surveyQuestions?: InputMaybe<ListFilterInputTypeOfSurveyQuestionFilterInput>;
  surveyResponseDetails?: InputMaybe<ListFilterInputTypeOfSurveyResponseDetailFilterInput>;
  surveyResponses?: InputMaybe<ListFilterInputTypeOfSurveyResponseFilterInput>;
  surveySurveyQuestions?: InputMaybe<ListFilterInputTypeOfSurveySurveyQuestionFilterInput>;
  surveys?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  targets?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  taskAssociatedRecords?: InputMaybe<ListFilterInputTypeOfTaskAssociatedRecordFilterInput>;
  taskHistories?: InputMaybe<ListFilterInputTypeOfTaskHistoryFilterInput>;
  taskHistoryFileReferences?: InputMaybe<ListFilterInputTypeOfTaskHistoryFileReferenceFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  testColor?: InputMaybe<StringOperationFilterInput>;
  testIcon?: InputMaybe<StringOperationFilterInput>;
  titles?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  tmpAccountingBatchLineLinks?: InputMaybe<ListFilterInputTypeOfTmpAccountingBatchLineLinkFilterInput>;
  tmpAccountingBatches?: InputMaybe<ListFilterInputTypeOfTmpAccountingBatchFilterInput>;
  tmpAccountingLines?: InputMaybe<ListFilterInputTypeOfTmpAccountingLineFilterInput>;
  tmpInvoices?: InputMaybe<ListFilterInputTypeOfTmpInvoiceFilterInput>;
  topLineMediaPlanDetailAttributes?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailAttributeFilterInput>;
  topLineMediaPlanDetails?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput>;
  topLineMediaPlans?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  trackingCodeAvailTrackingCodes?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailTrackingCodeFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodeImports?: InputMaybe<ListFilterInputTypeOfTrackingCodeImportFilterInput>;
  trackingCodeMaps?: InputMaybe<ListFilterInputTypeOfTrackingCodeMapFilterInput>;
  trackingCodes?: InputMaybe<ListFilterInputTypeOfTrackingCodeFilterInput>;
  trackingServiceLines?: InputMaybe<ListFilterInputTypeOfTrackingServiceLineFilterInput>;
  trafficSheetDetailDevices?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailDeviceFilterInput>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheets?: InputMaybe<ListFilterInputTypeOfTrafficSheetFilterInput>;
  uniqueAdScheduleLeadAdjustments?: InputMaybe<ListFilterInputTypeOfUniqueAdScheduleLeadAdjustmentFilterInput>;
  uniqueAdSchedules?: InputMaybe<ListFilterInputTypeOfUniqueAdScheduleFilterInput>;
  uniqueAdTrackingCodes?: InputMaybe<ListFilterInputTypeOfUniqueAdTrackingCodeFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  vendorBlackoutDates?: InputMaybe<ListFilterInputTypeOfVendorBlackoutDateFilterInput>;
  vendorContactRoles?: InputMaybe<ListFilterInputTypeOfVendorContactRoleFilterInput>;
  vendorDirectPayments?: InputMaybe<ListFilterInputTypeOfVendorDirectPaymentFilterInput>;
  vendorServiceCosts?: InputMaybe<ListFilterInputTypeOfVendorServiceCostFilterInput>;
  vendorServiceMediaTypes?: InputMaybe<ListFilterInputTypeOfVendorServiceMediaTypeFilterInput>;
  vendorServices?: InputMaybe<ListFilterInputTypeOfVendorServiceFilterInput>;
  vendors?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  waveCastAdInstances?: InputMaybe<ListFilterInputTypeOfWaveCastAdInstanceFilterInput>;
  waveCastDetails?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastMappings?: InputMaybe<ListFilterInputTypeOfWaveCastMappingFilterInput>;
  waveCastModelSkugroups?: InputMaybe<ListFilterInputTypeOfWaveCastModelSkugroupFilterInput>;
  waveCastModels?: InputMaybe<ListFilterInputTypeOfWaveCastModelFilterInput>;
  waveCastWaves?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
};

export type CompanySortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  companyCode?: InputMaybe<SortEnumType>;
  companyId?: InputMaybe<SortEnumType>;
  companyName?: InputMaybe<SortEnumType>;
  edgeColor?: InputMaybe<SortEnumType>;
  edgeIcon?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  logoMimeType?: InputMaybe<SortEnumType>;
  prodColor?: InputMaybe<SortEnumType>;
  prodIcon?: InputMaybe<SortEnumType>;
  productionWestAgencyId?: InputMaybe<SortEnumType>;
  testColor?: InputMaybe<SortEnumType>;
  testIcon?: InputMaybe<SortEnumType>;
};

export type Company_ForDisplay = {
  __typename?: 'Company_ForDisplay';
  businessSegmentName: Scalars['String'];
  companyCode: Scalars['String'];
  companyId: Scalars['Int'];
  companyName: Scalars['String'];
  isPrimaryCompany: Scalars['Boolean'];
};

export type Contact = {
  __typename?: 'Contact';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  assistant?: Maybe<Scalars['String']>;
  assistantEmail?: Maybe<Scalars['String']>;
  assistantPhone?: Maybe<Scalars['String']>;
  autoSchedules?: Maybe<Array<Maybe<AutoSchedule>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  clientContacts?: Maybe<Array<Maybe<ClientContact>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contactId: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  insertionOrderContacts?: Maybe<Array<Maybe<InsertionOrderContact>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletContactAssistantContacts?: Maybe<Array<Maybe<MediaOutletContact>>>;
  mediaOutletContactContacts?: Maybe<Array<Maybe<MediaOutletContact>>>;
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementPiDubHouseContacts?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncementPiTsbcontacts?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  notes?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  oldtableName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  title?: Maybe<Scalars['String']>;
  trackingCodeImports?: Maybe<Array<Maybe<TrackingCodeImport>>>;
  vendorContactRoles?: Maybe<Array<Maybe<VendorContactRole>>>;
};

export type ContactFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<ContactFilterInput>>;
  assistant?: InputMaybe<StringOperationFilterInput>;
  assistantEmail?: InputMaybe<StringOperationFilterInput>;
  assistantPhone?: InputMaybe<StringOperationFilterInput>;
  autoSchedules?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  clientContacts?: InputMaybe<ListFilterInputTypeOfClientContactFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrderContacts?: InputMaybe<ListFilterInputTypeOfInsertionOrderContactFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletContactAssistantContacts?: InputMaybe<ListFilterInputTypeOfMediaOutletContactFilterInput>;
  mediaOutletContactContacts?: InputMaybe<ListFilterInputTypeOfMediaOutletContactFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementPiDubHouseContacts?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementPiTsbcontacts?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  trackingCodeImports?: InputMaybe<ListFilterInputTypeOfTrackingCodeImportFilterInput>;
  vendorContactRoles?: InputMaybe<ListFilterInputTypeOfVendorContactRoleFilterInput>;
};

export type ContactInput = {
  addressId?: InputMaybe<Scalars['Int']>;
  assistant?: InputMaybe<Scalars['String']>;
  assistantEmail?: InputMaybe<Scalars['String']>;
  assistantPhone?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contactId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletContactInputs?: InputMaybe<Array<InputMaybe<MediaOutletContactInput>>>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContactSortInput = {
  address?: InputMaybe<AddressSortInput>;
  addressId?: InputMaybe<SortEnumType>;
  assistant?: InputMaybe<SortEnumType>;
  assistantEmail?: InputMaybe<SortEnumType>;
  assistantPhone?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  contactId?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  oldnaturalKey?: InputMaybe<SortEnumType>;
  oldtableName?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export type ContactType = {
  __typename?: 'ContactType';
  contactTypeGroupLookup?: Maybe<Lookup>;
  contactTypeGroupLookupId?: Maybe<Scalars['Int']>;
  contactTypeId: Scalars['Int'];
  correspondingTrafficContactType?: Maybe<ContactType>;
  correspondingTrafficContactTypeId?: Maybe<Scalars['Int']>;
  inverseCorrespondingTrafficContactType?: Maybe<Array<Maybe<ContactType>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletContacts?: Maybe<Array<Maybe<MediaOutletContact>>>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  vendorContactRoles?: Maybe<Array<Maybe<VendorContactRole>>>;
  vendorTypeLookup?: Maybe<Lookup>;
  vendorTypeLookupId: Scalars['Int'];
};

export type ContactTypeFilterInput = {
  and?: InputMaybe<Array<ContactTypeFilterInput>>;
  contactTypeGroupLookup?: InputMaybe<LookupFilterInput>;
  contactTypeGroupLookupId?: InputMaybe<IntOperationFilterInput>;
  contactTypeId?: InputMaybe<IntOperationFilterInput>;
  correspondingTrafficContactType?: InputMaybe<ContactTypeFilterInput>;
  correspondingTrafficContactTypeId?: InputMaybe<IntOperationFilterInput>;
  inverseCorrespondingTrafficContactType?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletContacts?: InputMaybe<ListFilterInputTypeOfMediaOutletContactFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactTypeFilterInput>>;
  vendorContactRoles?: InputMaybe<ListFilterInputTypeOfVendorContactRoleFilterInput>;
  vendorTypeLookup?: InputMaybe<LookupFilterInput>;
  vendorTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type ContractTerm = {
  __typename?: 'ContractTerm';
  approvedUtc?: Maybe<Scalars['DateTime']>;
  approverAppUser?: Maybe<AppUser>;
  approverAppUserId?: Maybe<Scalars['Int']>;
  billingLeadDays: Scalars['Int'];
  commissionSlidingScale?: Maybe<CommissionSlidingScale>;
  commissionSlidingScaleId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contractTermId: Scalars['Int'];
  coversClientPaid: Scalars['Boolean'];
  coversFees?: Maybe<Scalars['Boolean']>;
  coversMedia?: Maybe<Scalars['Boolean']>;
  creditExceededPreBillDays?: Maybe<Scalars['Int']>;
  dayTypeLookup?: Maybe<Lookup>;
  dayTypeLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  maxPostBillCredit?: Maybe<Scalars['Decimal']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  termsBillingTypeLookup?: Maybe<Lookup>;
  termsBillingTypeLookupId: Scalars['Int'];
  termsCadenceLookup?: Maybe<Lookup>;
  termsCadenceLookupId: Scalars['Int'];
  termsDays: Scalars['Int'];
  termsServiceLookup?: Maybe<Lookup>;
  termsServiceLookupId?: Maybe<Scalars['Int']>;
};

export type ContractTermFilterInput = {
  and?: InputMaybe<Array<ContractTermFilterInput>>;
  approvedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  approverAppUser?: InputMaybe<AppUserFilterInput>;
  approverAppUserId?: InputMaybe<IntOperationFilterInput>;
  billingLeadDays?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScale?: InputMaybe<CommissionSlidingScaleFilterInput>;
  commissionSlidingScaleId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contractTermId?: InputMaybe<IntOperationFilterInput>;
  coversClientPaid?: InputMaybe<BooleanOperationFilterInput>;
  coversFees?: InputMaybe<BooleanOperationFilterInput>;
  coversMedia?: InputMaybe<BooleanOperationFilterInput>;
  creditExceededPreBillDays?: InputMaybe<IntOperationFilterInput>;
  dayTypeLookup?: InputMaybe<LookupFilterInput>;
  dayTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  maxPostBillCredit?: InputMaybe<DecimalOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContractTermFilterInput>>;
  termsBillingTypeLookup?: InputMaybe<LookupFilterInput>;
  termsBillingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  termsCadenceLookup?: InputMaybe<LookupFilterInput>;
  termsCadenceLookupId?: InputMaybe<IntOperationFilterInput>;
  termsDays?: InputMaybe<IntOperationFilterInput>;
  termsServiceLookup?: InputMaybe<LookupFilterInput>;
  termsServiceLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type ContractTermInput = {
  approvedUTC?: InputMaybe<Scalars['DateTime']>;
  approverAppUserDescription?: InputMaybe<Scalars['String']>;
  approverAppUserId?: InputMaybe<Scalars['Int']>;
  billingLeadDays?: InputMaybe<Scalars['Int']>;
  commissionSlidingScaleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contractTermId?: InputMaybe<Scalars['Int']>;
  contractTermMediaOutlets?: InputMaybe<Array<InputMaybe<ContractTermMediaOutletInput>>>;
  contractTermServices?: InputMaybe<Array<InputMaybe<ContractTermServiceInput>>>;
  coversClientPaid: Scalars['Boolean'];
  coversFees: Scalars['Boolean'];
  coversMedia: Scalars['Boolean'];
  creditExceededPreBillDays?: InputMaybe<Scalars['Int']>;
  dayTypeLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  maxPostBillCredit?: InputMaybe<Scalars['Decimal']>;
  notes?: InputMaybe<Scalars['String']>;
  termsBillingTypeLookupId?: InputMaybe<Scalars['Int']>;
  termsCadenceLookupId?: InputMaybe<Scalars['Int']>;
  termsDays?: InputMaybe<Scalars['Int']>;
};

export type ContractTermMediaOutletInput = {
  contractTermId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type ContractTermServiceInput = {
  contractTermId: Scalars['Int'];
  itemType: TermsServiceItemType;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  serviceItemId: Scalars['Int'];
};

export type CostAdjustmentType = {
  __typename?: 'CostAdjustmentType';
  addCommission: Scalars['Boolean'];
  costAdjustmentTypeId: Scalars['Int'];
  exportToAccounting: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  reportToClient: Scalars['Boolean'];
  scheduleCostAdjustments?: Maybe<Array<Maybe<ScheduleCostAdjustment>>>;
};

export type CostAdjustmentTypeFilterInput = {
  addCommission?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<CostAdjustmentTypeFilterInput>>;
  costAdjustmentTypeId?: InputMaybe<IntOperationFilterInput>;
  exportToAccounting?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CostAdjustmentTypeFilterInput>>;
  reportToClient?: InputMaybe<BooleanOperationFilterInput>;
  scheduleCostAdjustments?: InputMaybe<ListFilterInputTypeOfScheduleCostAdjustmentFilterInput>;
};

export type CostPerLeadRange = {
  __typename?: 'CostPerLeadRange';
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costPerLeadRangeId: Scalars['Int'];
  endDate?: Maybe<Scalars['Date']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  maximumCostPerLead: Scalars['Decimal'];
  minimumCostPerLead: Scalars['Decimal'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type CostPerLeadRangeFilterInput = {
  and?: InputMaybe<Array<CostPerLeadRangeFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costPerLeadRangeId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  maximumCostPerLead?: InputMaybe<DecimalOperationFilterInput>;
  minimumCostPerLead?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<CostPerLeadRangeFilterInput>>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type Creative = {
  __typename?: 'Creative';
  ads?: Maybe<Array<Maybe<Ad>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  creativeId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  producerVendor?: Maybe<Vendor>;
  producerVendorId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
};

export type CreativeFilterInput = {
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<CreativeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  creativeId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CreativeFilterInput>>;
  producerVendor?: InputMaybe<VendorFilterInput>;
  producerVendorId?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
};

export type CreativeSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  creativeId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  producerVendor?: InputMaybe<VendorSortInput>;
  producerVendorId?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
};

export type CrewCentralPersonUpdateInput = {
  companyCode: Scalars['String'];
  cost: Scalars['Decimal'];
  departmentId: Scalars['Int'];
  displayName: Scalars['String'];
  divisionId: Scalars['Int'];
  effectiveDate: Scalars['DateTime'];
  employmentTypeId: Scalars['Int'];
  endDate: Scalars['DateTime'];
  firstName: Scalars['String'];
  isPersonDirectlyEnteringTime: Scalars['Boolean'];
  lastName: Scalars['String'];
  legalFirstName: Scalars['String'];
  legalLastName: Scalars['String'];
  officeId: Scalars['Int'];
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
  title: Scalars['String'];
  ulmPersonId: Scalars['Int'];
  ulmPersonManagerId: Scalars['Int'];
};

export type CrewCentralPersonUpdate_ForDisplay = {
  __typename?: 'CrewCentralPersonUpdate_ForDisplay';
  companyCode: Scalars['String'];
  cost: Scalars['Decimal'];
  crewCentralPersonUpdateId: Scalars['Int'];
  departmentId: Scalars['Int'];
  displayName: Scalars['String'];
  divisionId: Scalars['Int'];
  effectiveDate: Scalars['DateTime'];
  employmentTypeId: Scalars['Int'];
  endDate: Scalars['DateTime'];
  firstName: Scalars['String'];
  isPersonDirectlyEnteringTime: Scalars['Boolean'];
  isProcessed: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastProcessingMessage: Scalars['String'];
  legalFirstName: Scalars['String'];
  legalLastName: Scalars['String'];
  officeId: Scalars['Int'];
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
  title: Scalars['String'];
  ulmPersonId: Scalars['Int'];
  ulmPersonManagerId: Scalars['Int'];
};

export type CrossChannelCampaign = {
  __typename?: 'CrossChannelCampaign';
  campaignCrossChannelCampaigns?: Maybe<Array<Maybe<CampaignCrossChannelCampaign>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  crossChannelCampaignGroup?: Maybe<CrossChannelCampaignGroup>;
  crossChannelCampaignGroupId?: Maybe<Scalars['Int']>;
  crossChannelCampaignId: Scalars['Int'];
  crossChannelCampaignVersions?: Maybe<Array<Maybe<CrossChannelCampaignVersion>>>;
  datoramaKey?: Maybe<Scalars['String']>;
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  trafficSheets?: Maybe<Array<Maybe<TrafficSheet>>>;
};

export type CrossChannelCampaignDimension = {
  __typename?: 'CrossChannelCampaignDimension';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignDimensionId: Scalars['Int'];
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  dimensionLookup?: Maybe<Lookup>;
  dimensionLookupId: Scalars['Int'];
  dimensionOrder: Scalars['Int'];
  isVisible?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type CrossChannelCampaignDimensionFilterInput = {
  and?: InputMaybe<Array<CrossChannelCampaignDimensionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignDimensionId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  dimensionLookup?: InputMaybe<LookupFilterInput>;
  dimensionLookupId?: InputMaybe<IntOperationFilterInput>;
  dimensionOrder?: InputMaybe<IntOperationFilterInput>;
  isVisible?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CrossChannelCampaignDimensionFilterInput>>;
};

export type CrossChannelCampaignFee = {
  __typename?: 'CrossChannelCampaignFee';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignFeeId: Scalars['Int'];
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  estimatedPublisherCostPerUnit?: Maybe<Scalars['Decimal']>;
  feeCostMethodLookup?: Maybe<Lookup>;
  feeCostMethodLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  overridePublisherCostPerUnit?: Maybe<Scalars['Decimal']>;
  publisherMediaOutlet?: Maybe<MediaOutlet>;
  publisherMediaOutletId?: Maybe<Scalars['Int']>;
  units: Scalars['Decimal'];
  vendorCostPerUnit: Scalars['Decimal'];
  vendorMediaOutlet?: Maybe<MediaOutlet>;
  vendorMediaOutletId: Scalars['Int'];
  vendorService?: Maybe<VendorService>;
  vendorServiceId: Scalars['Int'];
};

export type CrossChannelCampaignFeeFilterInput = {
  and?: InputMaybe<Array<CrossChannelCampaignFeeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignFeeId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  estimatedPublisherCostPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  feeCostMethodLookup?: InputMaybe<LookupFilterInput>;
  feeCostMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CrossChannelCampaignFeeFilterInput>>;
  overridePublisherCostPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  publisherMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  publisherMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  units?: InputMaybe<DecimalOperationFilterInput>;
  vendorCostPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  vendorMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  vendorMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  vendorService?: InputMaybe<VendorServiceFilterInput>;
  vendorServiceId?: InputMaybe<IntOperationFilterInput>;
};

export type CrossChannelCampaignFeeInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignFeeId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignVersionId?: InputMaybe<Scalars['Int']>;
  estimatedPublisherCostPerUnit?: InputMaybe<Scalars['Decimal']>;
  feeCostMethodLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
  overridePublisherCostPerUnit?: InputMaybe<Scalars['Decimal']>;
  publisherMediaOutletId?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['Decimal']>;
  vendorCostPerUnit?: InputMaybe<Scalars['Decimal']>;
  vendorMediaOutletId?: InputMaybe<Scalars['Int']>;
  vendorServiceId?: InputMaybe<Scalars['Int']>;
};

export type CrossChannelCampaignFee_ForBudgetAllocation = {
  __typename?: 'CrossChannelCampaignFee_ForBudgetAllocation';
  channel?: Maybe<MediaType_ForBudgetAllocation>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignFeeId: Scalars['Int'];
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  estimatedPublisherCostPerUnit?: Maybe<Scalars['Decimal']>;
  feeCostMethodLookup?: Maybe<Lookup>;
  feeCostMethodLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  overridePublisherCostPerUnit?: Maybe<Scalars['Decimal']>;
  publisherMediaOutlet?: Maybe<MediaOutlet>;
  publisherMediaOutletId?: Maybe<Scalars['Int']>;
  totalFee?: Maybe<Scalars['Decimal']>;
  units: Scalars['Decimal'];
  vendorCostPerUnit: Scalars['Decimal'];
  vendorMediaOutlet?: Maybe<MediaOutlet>;
  vendorMediaOutletId: Scalars['Int'];
  vendorService?: Maybe<VendorService>;
  vendorServiceId: Scalars['Int'];
};

export type CrossChannelCampaignFee_ForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<CrossChannelCampaignFee_ForBudgetAllocationFilterInput>>;
  channel?: InputMaybe<MediaType_ForBudgetAllocationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignFeeId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  estimatedPublisherCostPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  feeCostMethodLookup?: InputMaybe<LookupFilterInput>;
  feeCostMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CrossChannelCampaignFee_ForBudgetAllocationFilterInput>>;
  overridePublisherCostPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  publisherMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  publisherMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  totalFee?: InputMaybe<DecimalOperationFilterInput>;
  units?: InputMaybe<DecimalOperationFilterInput>;
  vendorCostPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  vendorMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  vendorMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  vendorService?: InputMaybe<VendorServiceFilterInput>;
  vendorServiceId?: InputMaybe<IntOperationFilterInput>;
};

export type CrossChannelCampaignFilterInput = {
  and?: InputMaybe<Array<CrossChannelCampaignFilterInput>>;
  campaignCrossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCampaignCrossChannelCampaignFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  crossChannelCampaignGroup?: InputMaybe<CrossChannelCampaignGroupFilterInput>;
  crossChannelCampaignGroupId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignVersionFilterInput>;
  datoramaKey?: InputMaybe<StringOperationFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CrossChannelCampaignFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  trafficSheets?: InputMaybe<ListFilterInputTypeOfTrafficSheetFilterInput>;
};

export type CrossChannelCampaignForLookup = {
  __typename?: 'CrossChannelCampaignForLookup';
  amount: Scalars['Decimal'];
  areFeesIncludedInBudget: Scalars['Boolean'];
  axProjectCode?: Maybe<Scalars['String']>;
  campaignManagerCampaignId?: Maybe<Scalars['String']>;
  clientId: Scalars['Int'];
  clientName?: Maybe<Scalars['String']>;
  createAppUserId: Scalars['Int'];
  createByAppUserDescription?: Maybe<Scalars['String']>;
  createUTC: Scalars['DateTime'];
  crossChannelCampaignGroupId?: Maybe<Scalars['Int']>;
  crossChannelCampaignGroupName?: Maybe<Scalars['String']>;
  crossChannelCampaignId: Scalars['Int'];
  crossChannelCampaignVersionId: Scalars['Int'];
  datoramaKey?: Maybe<Scalars['String']>;
  endDate: Scalars['Date'];
  hasApprovedMediaAuthorization: Scalars['Boolean'];
  hasTacticLevelBudgets: Scalars['Boolean'];
  hasTacticLevelFlighting: Scalars['Boolean'];
  isCommissionIncludedInBudget: Scalars['Boolean'];
  lastEditedByAppUserDescription?: Maybe<Scalars['String']>;
  lastEditedUTC?: Maybe<Scalars['DateTime']>;
  mediaTypes?: Maybe<Array<MediaType>>;
  missingBudgetChannels: Array<Scalars['String']>;
  missingTrackerCampaignMappings: Array<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
  productName?: Maybe<Scalars['String']>;
  reallocationFlightDateGranularityLookupId?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
  regionName?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  trackerCampaignMappings?: Maybe<Array<CampaignCrossChannelCampaign_ForLookup>>;
  versionCount: Scalars['Int'];
  versionCreatedByAppUserDescriptions?: Maybe<Array<Scalars['String']>>;
};

export type CrossChannelCampaignGroup = {
  __typename?: 'CrossChannelCampaignGroup';
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignGroupId: Scalars['Int'];
  crossChannelCampaigns?: Maybe<Array<Maybe<CrossChannelCampaign>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CrossChannelCampaignGroupFilterInput = {
  and?: InputMaybe<Array<CrossChannelCampaignGroupFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignGroupId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CrossChannelCampaignGroupFilterInput>>;
};

export type CrossChannelCampaignGroupInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignGroupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CrossChannelCampaignGroupSortInput = {
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  crossChannelCampaignGroupId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type CrossChannelCampaignInput = {
  amount: Scalars['Decimal'];
  areFeesIncludedInBudget: Scalars['Boolean'];
  axProjectCode?: InputMaybe<Scalars['String']>;
  budgetAllocations?: InputMaybe<Array<InputMaybe<BudgetAllocationInput>>>;
  buildDefaultBudgetAllocationsFromHistoricalSpend: Scalars['Boolean'];
  campaignManagerCampaignId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  copyFlighting: Scalars['Boolean'];
  copyFromCrossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  createUTC?: InputMaybe<Scalars['DateTime']>;
  crossChannelCampaignGroupId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  datoramaKey?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  fees?: InputMaybe<Array<InputMaybe<CrossChannelCampaignFeeInput>>>;
  historicalSpendEndDate?: InputMaybe<Scalars['DateTime']>;
  historicalSpendStartDate?: InputMaybe<Scalars['DateTime']>;
  isCommissionIncludedInBudget: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaAuthorizations?: InputMaybe<Array<InputMaybe<MediaAuthorizationInput>>>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  reallocationFlightDateGranularityLookupId?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
};

export type CrossChannelCampaignSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createAppUser?: InputMaybe<AppUserSortInput>;
  createAppUserId?: InputMaybe<SortEnumType>;
  createUtc?: InputMaybe<SortEnumType>;
  crossChannelCampaignGroup?: InputMaybe<CrossChannelCampaignGroupSortInput>;
  crossChannelCampaignGroupId?: InputMaybe<SortEnumType>;
  crossChannelCampaignId?: InputMaybe<SortEnumType>;
  datoramaKey?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
};

export type CrossChannelCampaignSpend = {
  __typename?: 'CrossChannelCampaignSpend';
  anyPropertyValuesChanged: Scalars['Boolean'];
  buyTypeIsPerformanceBased: Scalars['Boolean'];
  buyTypeLookupDisplay?: Maybe<Scalars['String']>;
  buyTypeLookupID?: Maybe<Scalars['Int']>;
  clientNetClearedWithRevenueAdjustments?: Maybe<Scalars['Decimal']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  crossChannelCampaignID?: Maybe<Scalars['Int']>;
  datoramaKey?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Decimal']>;
  hasChanged: Scalars['Boolean'];
  id: Scalars['Int'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isDigital: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaOutletID: Scalars['Int'];
  mediaOutletIsCommissionExempt: Scalars['Boolean'];
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaOutletOfferingID?: Maybe<Scalars['Int']>;
  mediaOutletOfferingName?: Maybe<Scalars['String']>;
  mediaTypeID: Scalars['Int'];
  mediaTypeName?: Maybe<Scalars['String']>;
  netClearedWithCostAdjustments?: Maybe<Scalars['Decimal']>;
  networkMediaOutletID: Scalars['Int'];
  networkMediaOutletName?: Maybe<Scalars['String']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  propertyValueHasChanged: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  strategyEnum?: Maybe<Strategy>;
  strategyLookupDisplay?: Maybe<Scalars['String']>;
  strategyLookupID?: Maybe<Scalars['Int']>;
  tacticEnum?: Maybe<Tactic>;
  tacticLookupDisplay?: Maybe<Scalars['String']>;
  tacticLookupID?: Maybe<Scalars['Int']>;
};


export type CrossChannelCampaignSpendPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type CrossChannelCampaignVersion = {
  __typename?: 'CrossChannelCampaignVersion';
  areFeesIncludedInBudget: Scalars['Boolean'];
  axprojectCode?: Maybe<Scalars['String']>;
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  crossChannelCampaignDimensions?: Maybe<Array<Maybe<CrossChannelCampaignDimension>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  crossChannelCampaignId: Scalars['Int'];
  crossChannelCampaignVersionId: Scalars['Int'];
  endDate: Scalars['Date'];
  isCommissionIncludedInBudget: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAuthorizations?: Maybe<Array<Maybe<MediaAuthorization>>>;
  reallocationFlightDateGranularityLookup?: Maybe<Lookup>;
  reallocationFlightDateGranularityLookupId?: Maybe<Scalars['Int']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
};

export type CrossChannelCampaignVersionFilterInput = {
  and?: InputMaybe<Array<CrossChannelCampaignVersionFilterInput>>;
  areFeesIncludedInBudget?: InputMaybe<BooleanOperationFilterInput>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignFilterInput>;
  crossChannelCampaignDimensions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignDimensionFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  isCommissionIncludedInBudget?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAuthorizations?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  or?: InputMaybe<Array<CrossChannelCampaignVersionFilterInput>>;
  reallocationFlightDateGranularityLookup?: InputMaybe<LookupFilterInput>;
  reallocationFlightDateGranularityLookupId?: InputMaybe<IntOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type CsiFileDetails = {
  __typename?: 'CsiFileDetails';
  backupFileExists: Scalars['Boolean'];
  blockSendingToCSI: Scalars['Boolean'];
  details: Array<CsiSummaryDetails>;
  downloadLink: Scalars['String'];
  fileName: Scalars['String'];
  hasErrors: Scalars['Boolean'];
  id: Scalars['String'];
  lastPaymentProofUpload?: Maybe<PaymentProofUpload>;
  originalFileName: Scalars['String'];
  parsingError: Scalars['String'];
  uploadDateTimeUTC: Scalars['DateTime'];
};

export type CsiSummaryDetails = {
  __typename?: 'CsiSummaryDetails';
  amount: Scalars['Decimal'];
  checkNumber: Scalars['String'];
  dataAreaId: Scalars['String'];
  description: Scalars['String'];
  errorMessage: Scalars['String'];
  id: Scalars['String'];
  inError: Scalars['Boolean'];
  invoiceCount: Scalars['Int'];
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceNumber: Scalars['String'];
  missingInvoiceInfoError: Scalars['Boolean'];
  otherError: Scalars['Boolean'];
  otherErrorMessage: Scalars['String'];
  paymentCount: Scalars['Int'];
  paymentType: Scalars['String'];
  rowNumber: Scalars['Int'];
  vendorAccountNumber: Scalars['String'];
  vendorName: Scalars['String'];
};

export type CsiVendorInfo = {
  __typename?: 'CsiVendorInfo';
  achInfoExists: Scalars['Boolean'];
  vendorChangeDescription: Scalars['String'];
  vendorGroup: Scalars['String'];
  vendorId: Scalars['String'];
  vendorName: Scalars['String'];
};

export enum CurrencyCode {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Ang = 'ANG',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Bzd = 'BZD',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Ghc = 'GHC',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mgf = 'MGF',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzm = 'MZM',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rol = 'ROL',
  Rub = 'RUB',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srg = 'SRG',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Veb = 'VEB',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Yum = 'YUM',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

export type CurrencyCodeOperationFilterInput = {
  eq?: InputMaybe<CurrencyCode>;
  in?: InputMaybe<Array<CurrencyCode>>;
  neq?: InputMaybe<CurrencyCode>;
  nin?: InputMaybe<Array<CurrencyCode>>;
};

export type Curve = {
  __typename?: 'Curve';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  curveId: Scalars['Int'];
  curveSchemaLookup?: Maybe<Lookup>;
  curveSchemaLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  minute: Scalars['Decimal'];
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId: Scalars['Int'];
  value: Scalars['Decimal'];
};

export type CurveFilterInput = {
  and?: InputMaybe<Array<CurveFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  curveId?: InputMaybe<IntOperationFilterInput>;
  curveSchemaLookup?: InputMaybe<LookupFilterInput>;
  curveSchemaLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  minute?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<CurveFilterInput>>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<DecimalOperationFilterInput>;
};

export enum CustVendorBlocked {
  All = 'ALL',
  Invoice = 'INVOICE',
  Never = 'NEVER',
  No = 'NO',
  Payment = 'PAYMENT',
  Requisition = 'REQUISITION'
}

export type D365Category = {
  __typename?: 'D365Category';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  dataAreaId: Scalars['String'];
  sharedCategoryId: Scalars['String'];
  useInExpense: NoYes;
  useInProduction: NoYes;
  useInProject: NoYes;
};

export enum D365CheckStatus {
  Cancelled = 'CANCELLED',
  CancelPending = 'CANCEL_PENDING',
  Created = 'CREATED',
  NotPrinted = 'NOT_PRINTED',
  Payment = 'PAYMENT',
  Rejected = 'REJECTED',
  Testprint = 'TESTPRINT',
  Void = 'VOID'
}

export enum D365LedgerTransType {
  AdvanceAdjustmentRu = 'ADVANCE_ADJUSTMENT_RU',
  Allocation = 'ALLOCATION',
  AssetLeaseAssetImpairment = 'ASSET_LEASE_ASSET_IMPAIRMENT',
  AssetLeaseLeaseAdjustment = 'ASSET_LEASE_LEASE_ADJUSTMENT',
  AssetLeaseLeaseAdjustmentDecrease = 'ASSET_LEASE_LEASE_ADJUSTMENT_DECREASE',
  AssetLeaseLeaseAdjustmentDecreaseReversal = 'ASSET_LEASE_LEASE_ADJUSTMENT_DECREASE_REVERSAL',
  AssetLeaseLeaseAdjustmentIncrease = 'ASSET_LEASE_LEASE_ADJUSTMENT_INCREASE',
  AssetLeaseLeaseAdjustmentIncreaseReversal = 'ASSET_LEASE_LEASE_ADJUSTMENT_INCREASE_REVERSAL',
  AssetLeaseLeaseAdjustmentReversal = 'ASSET_LEASE_LEASE_ADJUSTMENT_REVERSAL',
  AssetLeaseLeaseAssetImpairmentReversal = 'ASSET_LEASE_LEASE_ASSET_IMPAIRMENT_REVERSAL',
  AssetLeaseLeaseExpense = 'ASSET_LEASE_LEASE_EXPENSE',
  AssetLeaseLeaseExpenseReversal = 'ASSET_LEASE_LEASE_EXPENSE_REVERSAL',
  AssetLeaseLeaseInterest = 'ASSET_LEASE_LEASE_INTEREST',
  AssetLeaseLeaseInterestReversal = 'ASSET_LEASE_LEASE_INTEREST_REVERSAL',
  AssetLeaseLeaseIr = 'ASSET_LEASE_LEASE_IR',
  AssetLeaseLeaseIrReversal = 'ASSET_LEASE_LEASE_IR_REVERSAL',
  AssetLeaseLeaseLiabilityShortTermReclass = 'ASSET_LEASE_LEASE_LIABILITY_SHORT_TERM_RECLASS',
  AssetLeaseLeaseLiabilityShortTermReclassReversal = 'ASSET_LEASE_LEASE_LIABILITY_SHORT_TERM_RECLASS_REVERSAL',
  AssetLeaseLeasePayment = 'ASSET_LEASE_LEASE_PAYMENT',
  AssetLeaseLeasePaymentReversal = 'ASSET_LEASE_LEASE_PAYMENT_REVERSAL',
  AssetLeaseLeaseRouDepreciation = 'ASSET_LEASE_LEASE_ROU_DEPRECIATION',
  AssetLeaseLeaseRouDepreciationReversal = 'ASSET_LEASE_LEASE_ROU_DEPRECIATION_REVERSAL',
  AssetLeaseLeaseTermination = 'ASSET_LEASE_LEASE_TERMINATION',
  AssetLeaseLeaseTerminationReversal = 'ASSET_LEASE_LEASE_TERMINATION_REVERSAL',
  AssetLeaseRecordNewLeaseClassification = 'ASSET_LEASE_RECORD_NEW_LEASE_CLASSIFICATION',
  AssetLeaseRecordNewLeaseClassificationReversal = 'ASSET_LEASE_RECORD_NEW_LEASE_CLASSIFICATION_REVERSAL',
  AssetLeaseReversePriorLeaseClassification = 'ASSET_LEASE_REVERSE_PRIOR_LEASE_CLASSIFICATION',
  AssetLeaseReversePriorLeaseClassificationReversal = 'ASSET_LEASE_REVERSE_PRIOR_LEASE_CLASSIFICATION_REVERSAL',
  Bank = 'BANK',
  BillOfExchange = 'BILL_OF_EXCHANGE',
  CashDiscount = 'CASH_DISCOUNT',
  CollectionLetter = 'COLLECTION_LETTER',
  Conversion = 'CONVERSION',
  ConversionReporting = 'CONVERSION_REPORTING',
  Cost = 'COST',
  CrossCompanySettlement = 'CROSS_COMPANY_SETTLEMENT',
  Cust = 'CUST',
  Elimination = 'ELIMINATION',
  ExchAdjustment = 'EXCH_ADJUSTMENT',
  Fee = 'FEE',
  FixedAssets = 'FIXED_ASSETS',
  FixedAssetsRu = 'FIXED_ASSETS_RU',
  GainLossCurrencyDealRu = 'GAIN_LOSS_CURRENCY_DEAL_RU',
  GeneralJournal = 'GENERAL_JOURNAL',
  InflationAdjustmentMx = 'INFLATION_ADJUSTMENT_MX',
  Interest = 'INTEREST',
  Invent = 'INVENT',
  McrUnderpayWriteOff = 'MCR_UNDERPAY_WRITE_OFF',
  None = 'NONE',
  OverUnder = 'OVER_UNDER',
  Payment = 'PAYMENT',
  Payroll = 'PAYROLL',
  PdsRebateCreditNote = 'PDS_REBATE_CREDIT_NOTE',
  PdsRebatePassToAp = 'PDS_REBATE_PASS_TO_AP',
  PennyDifference = 'PENNY_DIFFERENCE',
  Production = 'PRODUCTION',
  Project = 'PROJECT',
  PromissoryNote = 'PROMISSORY_NOTE',
  Purch = 'PURCH',
  PurchAdvanceApplication = 'PURCH_ADVANCE_APPLICATION',
  PurchReq = 'PURCH_REQ',
  ReportingCurrencyAdjustment = 'REPORTING_CURRENCY_ADJUSTMENT',
  RevRecSalesOrderRevenueAdjustment = 'REV_REC_SALES_ORDER_REVENUE_ADJUSTMENT',
  RDeferrals = 'R_DEFERRALS',
  RTax25BadDebtCreditAmortisation = 'R_TAX25_BAD_DEBT_CREDIT_AMORTISATION',
  RTax25BadDebtDebitAmortisation = 'R_TAX25_BAD_DEBT_DEBIT_AMORTISATION',
  Sales = 'SALES',
  Settlement = 'SETTLEMENT',
  SummedUp = 'SUMMED_UP',
  Tax = 'TAX',
  Transfer = 'TRANSFER',
  VatTaxAgentRu = 'VAT_TAX_AGENT_RU',
  Vend = 'VEND',
  Work = 'WORK',
  WriteOff = 'WRITE_OFF'
}

export enum D365MediaType {
  Commission = 'COMMISSION',
  Ddv = 'DDV',
  Digital = 'DIGITAL',
  Media = 'MEDIA',
  NowNetworks = 'NOW_NETWORKS',
  Ooh = 'OOH',
  Pi = 'PI',
  Print = 'PRINT',
  Radio = 'RADIO',
  RiskBuy = 'RISK_BUY'
}

export enum D365TrackerBuyType {
  Commision = 'COMMISION',
  Pi = 'PI',
  Prod = 'PROD',
  Risk = 'RISK'
}

export type DataArea = {
  __typename?: 'DataArea';
  id: Scalars['String'];
  name: Scalars['String'];
  nameAndNumber: Scalars['String'];
};

/** Class for saving / recalling MUI DataGrid default states. */
export type DataGridPreset = {
  __typename?: 'DataGridPreset';
  /** The person who created this preset. */
  createdByRainOnePersonId: Scalars['Int'];
  /** When the record was created. */
  createdUTC: Scalars['DateTime'];
  /** The front-end-defined unique identifier of the grid.  (Perhaps something like "client/BAR005/balanceSheet/49-Account Balance".) */
  dataGridKey: Scalars['String'];
  /** The Db's identity column. */
  dataGridPresetId: Scalars['Int'];
  /** The JSON of the MUI DataGrid state to be feed to its "InitialState" parameter. */
  gridState?: Maybe<Scalars['String']>;
  /** The owner of this preset.  Leave null for the default preset. */
  ownerRainOnePersonId?: Maybe<Scalars['Int']>;
  /** The JSON of the MUI DataGrid's "sortModel" property. */
  sortModel?: Maybe<Scalars['String']>;
  /** The JSON of the MUI DataGrid's "sortModel" property. */
  visibilityModel?: Maybe<Scalars['String']>;
};

export type DataGridPresetInput = {
  /** The front-end-defined unique identifier of the grid.  (Perhaps something like "client/BAR005/balanceSheet/49-Account Balance".) */
  dataGridKey: Scalars['String'];
  /** The Db's identity column. */
  dataGridPresetId?: InputMaybe<Scalars['Int']>;
  /** The JSON of the MUI DataGrid state to be feed to its "InitialState" parameter. */
  gridState?: InputMaybe<Scalars['String']>;
  /** The owner of this preset.  Leave null for the default preset. */
  ownerRainOnePersonId?: InputMaybe<Scalars['Int']>;
  /** The JSON of the MUI DataGrid's "sortModel" property. */
  sortModel?: InputMaybe<Scalars['String']>;
  /** The JSON of the MUI DataGrid's "sortModel" property. */
  visibilityModel?: InputMaybe<Scalars['String']>;
};

/** Class for saving / recalling MUI DataGrid default states. */
export type DataGridPresetSortInput = {
  /** The person who created this preset. */
  createdByRainOnePersonId?: InputMaybe<SortEnumType>;
  /** When the record was created. */
  createdUTC?: InputMaybe<SortEnumType>;
  /** The front-end-defined unique identifier of the grid.  (Perhaps something like "client/BAR005/balanceSheet/49-Account Balance".) */
  dataGridKey?: InputMaybe<SortEnumType>;
  /** The Db's identity column. */
  dataGridPresetId?: InputMaybe<SortEnumType>;
  /** The JSON of the MUI DataGrid state to be feed to its "InitialState" parameter. */
  gridState?: InputMaybe<SortEnumType>;
  /** The owner of this preset.  Leave null for the default preset. */
  ownerRainOnePersonId?: InputMaybe<SortEnumType>;
  /** The JSON of the MUI DataGrid's "sortModel" property. */
  sortModel?: InputMaybe<SortEnumType>;
  /** The JSON of the MUI DataGrid's "sortModel" property. */
  visibilityModel?: InputMaybe<SortEnumType>;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  neq?: InputMaybe<Scalars['Date']>;
  ngt?: InputMaybe<Scalars['Date']>;
  ngte?: InputMaybe<Scalars['Date']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  nlt?: InputMaybe<Scalars['Date']>;
  nlte?: InputMaybe<Scalars['Date']>;
};

export type DateRange = {
  __typename?: 'DateRange';
  allowableDays?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<Scalars['DateTime']>>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type DatoramaImportJob = {
  __typename?: 'DatoramaImportJob';
  automatedFileProcessing?: Maybe<AutomatedFileProcessing>;
  automatedFileProcessingId: Scalars['Int'];
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  datoramaImportJobId: Scalars['Int'];
  datoramaImportJobProducts?: Maybe<Array<Maybe<DatoramaImportJobProduct>>>;
  digitalSpendInputFiles?: Maybe<Array<Maybe<DigitalSpendInputFile>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type DatoramaImportJobFilterInput = {
  and?: InputMaybe<Array<DatoramaImportJobFilterInput>>;
  automatedFileProcessing?: InputMaybe<AutomatedFileProcessingFilterInput>;
  automatedFileProcessingId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  datoramaImportJobId?: InputMaybe<IntOperationFilterInput>;
  datoramaImportJobProducts?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobProductFilterInput>;
  digitalSpendInputFiles?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputFileFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DatoramaImportJobFilterInput>>;
};

export type DatoramaImportJobInput = {
  analystAppUserIds?: InputMaybe<Array<Scalars['Int']>>;
  automatedFileProcessingId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  datoramaImportJobId?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  ftpDownloadTime?: InputMaybe<Scalars['TimeSpan']>;
  isActive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  offsetDays?: InputMaybe<Scalars['Int']>;
  productIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type DatoramaImportJobProduct = {
  __typename?: 'DatoramaImportJobProduct';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  datoramaImportJob?: Maybe<DatoramaImportJob>;
  datoramaImportJobId: Scalars['Int'];
  datoramaImportJobProductId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
};

export type DatoramaImportJobProductFilterInput = {
  and?: InputMaybe<Array<DatoramaImportJobProductFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  datoramaImportJob?: InputMaybe<DatoramaImportJobFilterInput>;
  datoramaImportJobId?: InputMaybe<IntOperationFilterInput>;
  datoramaImportJobProductId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DatoramaImportJobProductFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
};

export type DatoramaImportJobProductForLookup = {
  __typename?: 'DatoramaImportJobProductForLookup';
  anyPropertyValuesChanged: Scalars['Boolean'];
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  datoramaImportJobID: Scalars['Int'];
  datoramaImportJobProductID: Scalars['Int'];
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  productID: Scalars['Int'];
  productName?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
};


export type DatoramaImportJobProductForLookupPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type DatoramaImportJobSortInput = {
  automatedFileProcessing?: InputMaybe<AutomatedFileProcessingSortInput>;
  automatedFileProcessingId?: InputMaybe<SortEnumType>;
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  datoramaImportJobId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
};

export type Daypart = {
  __typename?: 'Daypart';
  allowableDays?: Maybe<Scalars['String']>;
  clientMediaOutlets?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  daypartId: Scalars['Int'];
  daypartSchemaLookup?: Maybe<Lookup>;
  daypartSchemaLookupId: Scalars['Int'];
  display?: Maybe<Scalars['String']>;
  endTime: Scalars['TimeSpan'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketCostPers?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateDetails?: Maybe<Array<Maybe<LocalMarketRatingEstimateDetail>>>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mriindices?: Maybe<Array<Maybe<Mriindex>>>;
  name?: Maybe<Scalars['String']>;
  orderBy: Scalars['Int'];
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  startTime: Scalars['TimeSpan'];
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  waveCastDetails?: Maybe<Array<Maybe<WaveCastDetail>>>;
};

export type DaypartFilterInput = {
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<DaypartFilterInput>>;
  clientMediaOutlets?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  daypartSchemaLookup?: InputMaybe<LookupFilterInput>;
  daypartSchemaLookupId?: InputMaybe<IntOperationFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketCostPers?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateDetails?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateDetailFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mriindices?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DaypartFilterInput>>;
  orderBy?: InputMaybe<IntOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  topLineMediaPlanDetails?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput>;
  waveCastDetails?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export enum DelegateType {
  TimesheetApprovals = 'TIMESHEET_APPROVALS',
  TimesheetEntry = 'TIMESHEET_ENTRY'
}

export type DeleteRowFromCsiFileError = ArgumentError | RainError;

export type DeleteRowFromCsiFileInput = {
  fileName: Scalars['String'];
  rowNumber: Scalars['Int'];
};

export type DeleteRowFromCsiFilePayload = {
  __typename?: 'DeleteRowFromCsiFilePayload';
  csiFileDetails?: Maybe<Array<CsiFileDetails>>;
  errors?: Maybe<Array<DeleteRowFromCsiFileError>>;
};

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  succeeded: Scalars['Boolean'];
  validationMessages: Array<ValidationMessage>;
};

export type DepartmentCompany_ForDisplay = {
  __typename?: 'DepartmentCompany_ForDisplay';
  companyCode: Scalars['String'];
  companyId: Scalars['Int'];
  departmentCompanyId: Scalars['Int'];
  departmentId: Scalars['Int'];
  divisionId?: Maybe<Scalars['Int']>;
};

export type DepartmentDivision = {
  __typename?: 'DepartmentDivision';
  companyCode: Scalars['String'];
  companyId: Scalars['Int'];
  departmentId: Scalars['Int'];
  divisionId: Scalars['Int'];
  divisionName: Scalars['String'];
};

export type DepartmentGroupDetail = {
  __typename?: 'DepartmentGroupDetail';
  categories: Array<Scalars['String']>;
  departmentGroupName: Scalars['String'];
  isAdditionalDepartmentGroup: Scalars['Boolean'];
};

export type DepartmentGroupFunctionalRole = {
  __typename?: 'DepartmentGroupFunctionalRole';
  addedBy: Scalars['String'];
  dateAddedUTC: Scalars['DateTime'];
  departmentGroupId: Scalars['Int'];
  functionalRole: FunctionalRole;
  id: Scalars['Int'];
};

export type DepartmentGroupPermission = {
  __typename?: 'DepartmentGroupPermission';
  addedBy: Scalars['String'];
  dateAddedUTC: Scalars['DateTime'];
  departmentGroupId: Scalars['Int'];
  id: Scalars['Int'];
  rainOnePermission: RainOnePermission;
};

export type DepartmentGroupSortOrderInput = {
  departmentGroupId: Scalars['Int'];
  sortOrder: Scalars['Int'];
};

export enum DepartmentGroupType {
  ClientDepartment = 'CLIENT_DEPARTMENT',
  PftWorkflow = 'PFT_WORKFLOW',
  TimeValidation = 'TIME_VALIDATION'
}

export type DepartmentGroup_ForDisplay = {
  __typename?: 'DepartmentGroup_ForDisplay';
  companyCode: Scalars['String'];
  departmentGroupId: Scalars['Int'];
  departmentGroupName: Scalars['String'];
  departmentGroupType: DepartmentGroupType;
  id: Scalars['Int'];
  linkedDepartments: Array<Department_DepartmentGroup_ForDisplay>;
  rainOnePermissions: Array<DepartmentGroupPermission>;
  sortOrder: Scalars['Int'];
};

export type DepartmentRevenueDetails = {
  __typename?: 'DepartmentRevenueDetails';
  axRevenue: Scalars['Decimal'];
  clientBalance: Scalars['Decimal'];
  clientBalanceMonthly: Scalars['Decimal'];
  clientLineSettledAmount: Scalars['Decimal'];
  departmentCode: Scalars['String'];
  departmentName: Scalars['String'];
  invoicedAmount: Scalars['Decimal'];
  isTrackerLocked: ReconciliationStatus;
  month: Scalars['String'];
  recievedAmount: Scalars['Decimal'];
  trackerClearanceRevenue: Scalars['Decimal'];
};

export type Department_DepartmentGroup_ForDisplay = {
  __typename?: 'Department_DepartmentGroup_ForDisplay';
  departmentGroupId: Scalars['Int'];
  departmentGroupName: Scalars['String'];
  departmentId: Scalars['Int'];
  departmentName: Scalars['String'];
  department_DepartmentGroupId: Scalars['Int'];
  id: Scalars['Int'];
};

export type Department_ForDisplay = {
  __typename?: 'Department_ForDisplay';
  departmentCode: Scalars['String'];
  departmentGroup: Scalars['String'];
  departmentId: Scalars['Int'];
  departmentName: Scalars['String'];
  isEmploymentDepartment: Scalars['Boolean'];
};

export enum DigitalBuyType {
  Client = 'CLIENT',
  InternalTv = 'INTERNAL_TV',
  Standard = 'STANDARD'
}

export type DigitalDeliveryServiceAttribute = {
  __typename?: 'DigitalDeliveryServiceAttribute';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalDeliveryServiceAttributeId: Scalars['Int'];
  digitalDeliveryServiceLookup?: Maybe<Lookup>;
  digitalDeliveryServiceLookupId: Scalars['Int'];
  duplicationLayout?: Maybe<DuplicationLayout>;
  duplicationLayoutId?: Maybe<Scalars['Int']>;
  hdduplicationPreset?: Maybe<DuplicationPreset>;
  hdduplicationPresetId?: Maybe<Scalars['Int']>;
  isFileShare: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  sdduplicationPreset?: Maybe<DuplicationPreset>;
  sdduplicationPresetId?: Maybe<Scalars['Int']>;
};

export type DigitalDeliveryServiceAttributeFilterInput = {
  and?: InputMaybe<Array<DigitalDeliveryServiceAttributeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalDeliveryServiceAttributeId?: InputMaybe<IntOperationFilterInput>;
  digitalDeliveryServiceLookup?: InputMaybe<LookupFilterInput>;
  digitalDeliveryServiceLookupId?: InputMaybe<IntOperationFilterInput>;
  duplicationLayout?: InputMaybe<DuplicationLayoutFilterInput>;
  duplicationLayoutId?: InputMaybe<IntOperationFilterInput>;
  hdduplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  hdduplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  isFileShare?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DigitalDeliveryServiceAttributeFilterInput>>;
  sdduplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  sdduplicationPresetId?: InputMaybe<IntOperationFilterInput>;
};

export type DigitalResultType = {
  __typename?: 'DigitalResultType';
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalResultTypeId: Scalars['Int'];
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  insertionOrderPlacementResults?: Maybe<Array<Maybe<InsertionOrderPlacementResult>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type DigitalResultTypeFilterInput = {
  and?: InputMaybe<Array<DigitalResultTypeFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalResultTypeId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  insertionOrderPlacementResults?: InputMaybe<ListFilterInputTypeOfInsertionOrderPlacementResultFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DigitalResultTypeFilterInput>>;
};

export type DigitalResultTypeInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  digitalResultTypeId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DigitalResultTypeSortInput = {
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  digitalResultTypeId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type DigitalSpendImportChannelMapping = {
  __typename?: 'DigitalSpendImportChannelMapping';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  datoramaValue?: Maybe<Scalars['String']>;
  digitalSpendImportChannelMappingId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
};

export type DigitalSpendImportChannelMappingFilterInput = {
  and?: InputMaybe<Array<DigitalSpendImportChannelMappingFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  datoramaValue?: InputMaybe<StringOperationFilterInput>;
  digitalSpendImportChannelMappingId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DigitalSpendImportChannelMappingFilterInput>>;
};

export type DigitalSpendImportChannelMappingSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  datoramaValue?: InputMaybe<SortEnumType>;
  digitalSpendImportChannelMappingId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaType?: InputMaybe<MediaTypeSortInput>;
  mediaTypeId?: InputMaybe<SortEnumType>;
};

export type DigitalSpendImportDatoramaKeyMapping = {
  __typename?: 'DigitalSpendImportDatoramaKeyMapping';
  client?: Maybe<Client>;
  clientDatoramaKey?: Maybe<ClientDatoramaKey>;
  clientDatoramaKeyId: Scalars['Int'];
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  datoramaValue?: Maybe<Scalars['String']>;
  digitalSpendImportDatoramaKeyMappingId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type DigitalSpendImportDatoramaKeyMappingFilterInput = {
  and?: InputMaybe<Array<DigitalSpendImportDatoramaKeyMappingFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientDatoramaKey?: InputMaybe<ClientDatoramaKeyFilterInput>;
  clientDatoramaKeyId?: InputMaybe<IntOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  datoramaValue?: InputMaybe<StringOperationFilterInput>;
  digitalSpendImportDatoramaKeyMappingId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DigitalSpendImportDatoramaKeyMappingFilterInput>>;
};

export type DigitalSpendImportFieldMapping = {
  __typename?: 'DigitalSpendImportFieldMapping';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  datoramaColumnHeader?: Maybe<Scalars['String']>;
  digitalSpendImportFieldMappingId: Scalars['Int'];
  digitalSpendInputFieldLookup?: Maybe<Lookup>;
  digitalSpendInputFieldLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type DigitalSpendImportFieldMappingFilterInput = {
  and?: InputMaybe<Array<DigitalSpendImportFieldMappingFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  datoramaColumnHeader?: InputMaybe<StringOperationFilterInput>;
  digitalSpendImportFieldMappingId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputFieldLookup?: InputMaybe<LookupFilterInput>;
  digitalSpendInputFieldLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DigitalSpendImportFieldMappingFilterInput>>;
};

export type DigitalSpendImportFieldMappingInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  datoramaColumnHeader?: InputMaybe<Scalars['String']>;
  digitalSpendImportFieldMappingId?: InputMaybe<Scalars['Int']>;
  digitalSpendInputFieldLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
};

export type DigitalSpendImportFieldMappingSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  datoramaColumnHeader?: InputMaybe<SortEnumType>;
  digitalSpendImportFieldMappingId?: InputMaybe<SortEnumType>;
  digitalSpendInputFieldLookup?: InputMaybe<LookupSortInput>;
  digitalSpendInputFieldLookupId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
};

export type DigitalSpendImportValueMapping = {
  __typename?: 'DigitalSpendImportValueMapping';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  datoramaValue?: Maybe<Scalars['String']>;
  digitalSpendImportValueMappingId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lookupType?: Maybe<LookupType>;
  lookupTypeId: Scalars['Int'];
  valueLookup?: Maybe<Lookup>;
  valueLookupId: Scalars['Int'];
};

export type DigitalSpendImportValueMappingFilterInput = {
  and?: InputMaybe<Array<DigitalSpendImportValueMappingFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  datoramaValue?: InputMaybe<StringOperationFilterInput>;
  digitalSpendImportValueMappingId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lookupType?: InputMaybe<LookupTypeFilterInput>;
  lookupTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DigitalSpendImportValueMappingFilterInput>>;
  valueLookup?: InputMaybe<LookupFilterInput>;
  valueLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type DigitalSpendInput = {
  __typename?: 'DigitalSpendInput';
  adID?: Maybe<Scalars['Int']>;
  adInstanceDate?: Maybe<Scalars['DateTime']>;
  adInstanceID?: Maybe<Scalars['Int']>;
  adServingCPM?: Maybe<Scalars['Decimal']>;
  allCosts: Scalars['Decimal'];
  allowableDays?: Maybe<Scalars['String']>;
  anyPropertyValuesChanged: Scalars['Boolean'];
  applicableCommissionRate?: Maybe<Scalars['Decimal']>;
  bookedImpressions?: Maybe<Scalars['Int']>;
  bookingID?: Maybe<Scalars['Int']>;
  brandCategory?: Maybe<Scalars['String']>;
  buyType?: Maybe<Scalars['String']>;
  buyTypeEnum: BuyType;
  buyerAppUserID?: Maybe<Scalars['Int']>;
  campaignBelongsToAnotherClient: Scalars['Boolean'];
  campaignID?: Maybe<Scalars['Int']>;
  campaignNotMatched: Scalars['Boolean'];
  campaignShouldHaveMatched: Scalars['Boolean'];
  channel?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
  clientDatoramaKeyID?: Maybe<Scalars['Int']>;
  clientID?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['String']>;
  clientNetCost?: Maybe<Scalars['Decimal']>;
  clinchFees?: Maybe<Scalars['Decimal']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissionRate?: Maybe<Scalars['Decimal']>;
  cpm?: Maybe<Scalars['Decimal']>;
  date?: Maybe<Scalars['DateTime']>;
  datoramaKey?: Maybe<Scalars['String']>;
  demandSidePlatform?: Maybe<Scalars['String']>;
  digitalBuyType?: Maybe<DigitalBuyType>;
  digitalBuyTypeLookupID?: Maybe<Scalars['Int']>;
  digitalSpendInputFileID: Scalars['Int'];
  digitalSpendInputID: Scalars['Int'];
  dspFees?: Maybe<Scalars['Decimal']>;
  dvFees?: Maybe<Scalars['Decimal']>;
  effectiveCommissionRate?: Maybe<Scalars['Decimal']>;
  exchangeRate?: Maybe<Scalars['Decimal']>;
  externalComment?: Maybe<Scalars['String']>;
  fourCFees?: Maybe<Scalars['Decimal']>;
  hasChanged: Scalars['Boolean'];
  hasCost: Scalars['Boolean'];
  hasMediaCost: Scalars['Boolean'];
  hasSignificantCost: Scalars['Boolean'];
  iSpotFees?: Maybe<Scalars['Decimal']>;
  impressions?: Maybe<Scalars['Int']>;
  impressionsForSubtotal?: Maybe<Scalars['Long']>;
  isAmbiguousCampaignMatch: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isChannelMissing: Scalars['Boolean'];
  isDimensionValidationCandidate: Scalars['Boolean'];
  isDiscrepantFromApprovedInvoice: Scalars['Boolean'];
  isDiscrepantFromLockedSchedule: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  isInLockedMonth: Scalars['Boolean'];
  isMissingRainOneChannel: Scalars['Boolean'];
  isProvisional: Scalars['Boolean'];
  isStrategyMissing: Scalars['Boolean'];
  isTacticMissing: Scalars['Boolean'];
  isUnmatchedDatoramaKey: Scalars['Boolean'];
  isValidForImport: Scalars['Boolean'];
  isValidForScheduleMatching: Scalars['Boolean'];
  isValidMediaType: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  markUpAmount?: Maybe<Scalars['Decimal']>;
  matchedMediaOutletByNotAliasDates: Scalars['Boolean'];
  matchedMediaOutletByNotMediaType: Scalars['Boolean'];
  mediaCost?: Maybe<Scalars['Decimal']>;
  mediaOutletID?: Maybe<Scalars['Int']>;
  mediaOutletIsCommissionExempt: Scalars['Boolean'];
  mediaOutletIsProgrammatic: Scalars['Boolean'];
  mediaOutletIsServiceFeeVendor: Scalars['Boolean'];
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaOutletOffering?: Maybe<Scalars['String']>;
  mediaOutletOfferingID?: Maybe<Scalars['Int']>;
  mediaTeamOwner?: Maybe<Scalars['String']>;
  mediaTeamOwnerAppUserID?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaTypeEnum>;
  mediaTypeDisplay?: Maybe<Scalars['String']>;
  mediaTypeID?: Maybe<Scalars['Int']>;
  misassignedProjectCodeClientName?: Maybe<Scalars['String']>;
  monthDisplay?: Maybe<Scalars['String']>;
  netCost?: Maybe<Scalars['Decimal']>;
  networkMediaOutletID?: Maybe<Scalars['Int']>;
  networkMediaOutletName?: Maybe<Scalars['String']>;
  newMarketviewConsultingFees?: Maybe<Scalars['Decimal']>;
  oneViewFees?: Maybe<Scalars['Decimal']>;
  originalDatoramaKey?: Maybe<Scalars['String']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  productID?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  projectCode?: Maybe<Scalars['String']>;
  projectCodeIsValid?: Maybe<Scalars['Boolean']>;
  propertyValueHasChanged: Scalars['Boolean'];
  publisher?: Maybe<Scalars['String']>;
  publisherForMatching?: Maybe<Scalars['String']>;
  publisherIsEligibleForFeeCalculation: Scalars['Boolean'];
  publisherIsEligibleForMatching: Scalars['Boolean'];
  publisherNotMatched: Scalars['Boolean'];
  rateType: RateType;
  resonateFees?: Maybe<Scalars['Decimal']>;
  scheduleCreated?: Maybe<ScheduleEntity>;
  scheduleID?: Maybe<Scalars['Int']>;
  scheduleIsMarkedAsIgnoreDiscreps: Scalars['Boolean'];
  scheduleIsMarkedAsNoCharge: Scalars['Boolean'];
  scheduleStatus: ScheduleStatus;
  strategy?: Maybe<Scalars['String']>;
  strategyLookupID?: Maybe<Scalars['Int']>;
  tactic?: Maybe<Scalars['String']>;
  tacticLookupID?: Maybe<Scalars['Int']>;
  totalCost?: Maybe<Scalars['Decimal']>;
  totalThirdPartyFees?: Maybe<Scalars['Decimal']>;
  trackingFees?: Maybe<Scalars['Decimal']>;
  uniqueAdID?: Maybe<Scalars['Int']>;
  uniqueAdScheduleID?: Maybe<Scalars['Int']>;
  uspsFees?: Maybe<Scalars['Decimal']>;
};


export type DigitalSpendInputPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type DigitalSpendInputFile = {
  __typename?: 'DigitalSpendInputFile';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dataGeneratedUtc?: Maybe<Scalars['DateTime']>;
  datoramaImportJob?: Maybe<DatoramaImportJob>;
  datoramaImportJobId?: Maybe<Scalars['Int']>;
  digitalSpendInputFileId: Scalars['Int'];
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type DigitalSpendInputFileFilterInput = {
  and?: InputMaybe<Array<DigitalSpendInputFileFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dataGeneratedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  datoramaImportJob?: InputMaybe<DatoramaImportJobFilterInput>;
  datoramaImportJobId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputFileId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DigitalSpendInputFileFilterInput>>;
};

export type DigitalSpendInputFileForLookup = {
  __typename?: 'DigitalSpendInputFileForLookup';
  anyPropertyValuesChanged: Scalars['Boolean'];
  appUserClients?: Maybe<Array<Maybe<AppUserClientForLookup>>>;
  clientFinanceAppUserDescriptions?: Maybe<Scalars['String']>;
  clientFinanceUsers?: Maybe<Array<Maybe<AppUserClientForLookup>>>;
  clientID?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['String']>;
  clientNameForDisplay?: Maybe<Scalars['String']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataGeneratedUTC?: Maybe<Scalars['DateTime']>;
  datoramaImportJobID: Scalars['Int'];
  datoramaImportJobProductForLookups?: Maybe<Array<Maybe<DatoramaImportJobProductForLookup>>>;
  digitalAnalystAppUserDescription?: Maybe<Scalars['String']>;
  digitalAnalystAppUsers?: Maybe<Array<Maybe<AppUserClientForLookup>>>;
  digitalSpendInputFileID?: Maybe<Scalars['Int']>;
  digitalSpendInputs?: Maybe<Array<Maybe<DigitalSpendInput>>>;
  digitalSpendInputsCount: Scalars['Int'];
  digitalSpendInputsScheduleCount: Scalars['Int'];
  discrepantSchedules?: Maybe<Array<Maybe<DigitalSpendProblemSchedule>>>;
  discrepantSchedulesHeader?: Maybe<Scalars['String']>;
  errorsHeader?: Maybe<Scalars['String']>;
  fileImportID: Scalars['Int'];
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  fileReferenceID: Scalars['Int'];
  hasChanged: Scalars['Boolean'];
  hasErrors?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  importAppUserID?: Maybe<Scalars['Int']>;
  importDateUTC?: Maybe<Scalars['DateTime']>;
  importUserDescription?: Maybe<Scalars['String']>;
  invoiceMonthWithUnattachedSchedules?: Maybe<Array<Maybe<InvoiceMonthWithUnattachedSchedule>>>;
  isChangeTrackingEnabled: Scalars['Boolean'];
  isMostRecentFile?: Maybe<Scalars['Boolean']>;
  jobInstanceDetails?: Maybe<Array<Maybe<JobInstanceDetail>>>;
  jobInstanceID: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaEndDate?: Maybe<Scalars['DateTime']>;
  mediaStartDate?: Maybe<Scalars['DateTime']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  orphanedSchedules?: Maybe<Array<Maybe<DigitalSpendProblemSchedule>>>;
  orphanedSchedulesHeader?: Maybe<Scalars['String']>;
  parent?: Maybe<EntityBase>;
  productNamesDisplay?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
  selectedDiscrepantSchedules?: Maybe<Array<Maybe<DigitalSpendProblemSchedule>>>;
  selectedOrphanedSchedules?: Maybe<Array<Maybe<DigitalSpendProblemSchedule>>>;
  selectedUninvoicedSchedules?: Maybe<Array<Maybe<InvoiceMonthWithUnattachedSchedule>>>;
  selectedUnmatchedPublishers?: Maybe<Array<Maybe<DigitalSpendInputPublisher>>>;
  spendEndDate?: Maybe<Scalars['DateTime']>;
  spendStartDate?: Maybe<Scalars['DateTime']>;
  trackerClientName?: Maybe<Scalars['String']>;
  uninvoicedSchedulesHeader?: Maybe<Scalars['String']>;
  unmatchedProjects?: Maybe<Array<Maybe<DigitalSpendInputProject>>>;
  unmatchedProjectsHeader?: Maybe<Scalars['String']>;
  unmatchedPublishers?: Maybe<Array<Maybe<DigitalSpendInputPublisher>>>;
  unmatchedPublishersHeader?: Maybe<Scalars['String']>;
};


export type DigitalSpendInputFileForLookupPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type DigitalSpendInputLine = {
  __typename?: 'DigitalSpendInputLine';
  adServingCpm?: Maybe<Scalars['Decimal']>;
  brandCategory?: Maybe<Scalars['String']>;
  buyType?: Maybe<Scalars['String']>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  channel?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
  clinchFees?: Maybe<Scalars['Decimal']>;
  commissionRate?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  datoramaKey?: Maybe<Scalars['String']>;
  demandSidePlatform?: Maybe<Scalars['String']>;
  digitalBuyTypeLookup?: Maybe<Lookup>;
  digitalBuyTypeLookupId: Scalars['Int'];
  digitalSpendInputFile?: Maybe<DigitalSpendInputFile>;
  digitalSpendInputFileId: Scalars['Int'];
  digitalSpendInputId: Scalars['Int'];
  dspfees?: Maybe<Scalars['Decimal']>;
  dvfees?: Maybe<Scalars['Decimal']>;
  fourCfees?: Maybe<Scalars['Decimal']>;
  impressions?: Maybe<Scalars['Int']>;
  ispotFees?: Maybe<Scalars['Decimal']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaCost?: Maybe<Scalars['Decimal']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaOutletOffering?: Maybe<Scalars['String']>;
  mediaOutletOfferingId?: Maybe<Scalars['Int']>;
  mediaOutletOfferingNavigation?: Maybe<MediaOutletOffering>;
  mediaTeamOwner?: Maybe<Scalars['String']>;
  newMarketviewConsultingFees?: Maybe<Scalars['Decimal']>;
  projectCode?: Maybe<Scalars['String']>;
  publisher?: Maybe<Scalars['String']>;
  resonateFees?: Maybe<Scalars['Decimal']>;
  schedule?: Maybe<Schedule>;
  scheduleId?: Maybe<Scalars['Int']>;
  strategy?: Maybe<Scalars['String']>;
  strategyLookup?: Maybe<Lookup>;
  strategyLookupId?: Maybe<Scalars['Int']>;
  tactic?: Maybe<Scalars['String']>;
  tacticLookup?: Maybe<Lookup>;
  tacticLookupId?: Maybe<Scalars['Int']>;
  totalCost?: Maybe<Scalars['Decimal']>;
  totalThirdPartyFees?: Maybe<Scalars['Decimal']>;
  trackingFees?: Maybe<Scalars['Decimal']>;
  uspsfees?: Maybe<Scalars['Decimal']>;
};

export type DigitalSpendInputLineFilterInput = {
  adServingCpm?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<DigitalSpendInputLineFilterInput>>;
  brandCategory?: InputMaybe<StringOperationFilterInput>;
  buyType?: InputMaybe<StringOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  channel?: InputMaybe<StringOperationFilterInput>;
  clicks?: InputMaybe<IntOperationFilterInput>;
  clinchFees?: InputMaybe<DecimalOperationFilterInput>;
  commissionRate?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  datoramaKey?: InputMaybe<StringOperationFilterInput>;
  demandSidePlatform?: InputMaybe<StringOperationFilterInput>;
  digitalBuyTypeLookup?: InputMaybe<LookupFilterInput>;
  digitalBuyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputFile?: InputMaybe<DigitalSpendInputFileFilterInput>;
  digitalSpendInputFileId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputId?: InputMaybe<IntOperationFilterInput>;
  dspfees?: InputMaybe<DecimalOperationFilterInput>;
  dvfees?: InputMaybe<DecimalOperationFilterInput>;
  fourCfees?: InputMaybe<DecimalOperationFilterInput>;
  impressions?: InputMaybe<IntOperationFilterInput>;
  ispotFees?: InputMaybe<DecimalOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaCost?: InputMaybe<DecimalOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletOffering?: InputMaybe<StringOperationFilterInput>;
  mediaOutletOfferingId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletOfferingNavigation?: InputMaybe<MediaOutletOfferingFilterInput>;
  mediaTeamOwner?: InputMaybe<StringOperationFilterInput>;
  newMarketviewConsultingFees?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<DigitalSpendInputLineFilterInput>>;
  projectCode?: InputMaybe<StringOperationFilterInput>;
  publisher?: InputMaybe<StringOperationFilterInput>;
  resonateFees?: InputMaybe<DecimalOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  strategy?: InputMaybe<StringOperationFilterInput>;
  strategyLookup?: InputMaybe<LookupFilterInput>;
  strategyLookupId?: InputMaybe<IntOperationFilterInput>;
  tactic?: InputMaybe<StringOperationFilterInput>;
  tacticLookup?: InputMaybe<LookupFilterInput>;
  tacticLookupId?: InputMaybe<IntOperationFilterInput>;
  totalCost?: InputMaybe<DecimalOperationFilterInput>;
  totalThirdPartyFees?: InputMaybe<DecimalOperationFilterInput>;
  trackingFees?: InputMaybe<DecimalOperationFilterInput>;
  uspsfees?: InputMaybe<DecimalOperationFilterInput>;
};

export type DigitalSpendInputProject = {
  __typename?: 'DigitalSpendInputProject';
  channel?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type DigitalSpendInputPublisher = {
  __typename?: 'DigitalSpendInputPublisher';
  channels?: Maybe<Scalars['String']>;
  mediaOutletType?: Maybe<MediaOutletTypeEnum>;
  name?: Maybe<Scalars['String']>;
};

export type DigitalSpendProblemSchedule = {
  __typename?: 'DigitalSpendProblemSchedule';
  adName?: Maybe<Scalars['String']>;
  amountToPayStation?: Maybe<Scalars['Decimal']>;
  anyPropertyValuesChanged: Scalars['Boolean'];
  axProjectCode?: Maybe<Scalars['String']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  datoramaKey?: Maybe<Scalars['String']>;
  datoramaMediaCost?: Maybe<Scalars['Decimal']>;
  digitalSpendInputFileID: Scalars['Int'];
  discrepancy: Scalars['Decimal'];
  fileImportID?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  hasChanged: Scalars['Boolean'];
  importDateUTC?: Maybe<Scalars['DateTime']>;
  isChangeTrackingEnabled: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isLockedByAccounting: Scalars['Boolean'];
  isMonthLocked: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaOutletID: Scalars['Int'];
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaTypeDisplay?: Maybe<Scalars['String']>;
  mediaTypeID: Scalars['Int'];
  month: Scalars['DateTime'];
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  productID: Scalars['Int'];
  productName?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
  scheduleID: Scalars['Int'];
  startDate: Scalars['DateTime'];
  strategyLookupDisplay?: Maybe<Scalars['String']>;
  tacticLookupDisplay?: Maybe<Scalars['String']>;
};


export type DigitalSpendProblemSchedulePropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type DimensionForBudgetAllocation = {
  __typename?: 'DimensionForBudgetAllocation';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignDimensionId: Scalars['Int'];
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  dimensionLookup?: Maybe<Lookup>;
  dimensionLookupId: Scalars['Int'];
  dimensionOrder: Scalars['Int'];
  display?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type DimensionForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<DimensionForBudgetAllocationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignDimensionId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  dimensionLookup?: InputMaybe<LookupFilterInput>;
  dimensionLookupId?: InputMaybe<IntOperationFilterInput>;
  dimensionOrder?: InputMaybe<IntOperationFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  isVisible?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DimensionForBudgetAllocationFilterInput>>;
};

export type DimensionHeader = {
  __typename?: 'DimensionHeader';
  calculateSize: Scalars['Int'];
  clone?: Maybe<DimensionHeader>;
  name?: Maybe<Scalars['String']>;
};

export enum DimensionLedgerAccountType {
  Asset = 'ASSET',
  BalanceSheet = 'BALANCE_SHEET',
  Blank = 'BLANK',
  CommonCn = 'COMMON_CN',
  Equity = 'EQUITY',
  Expense = 'EXPENSE',
  Liability = 'LIABILITY',
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  Reporting = 'REPORTING',
  Revenue = 'REVENUE',
  Total = 'TOTAL'
}

export type DimensionMetadata = {
  __typename?: 'DimensionMetadata';
  apiName?: Maybe<Scalars['String']>;
  calculateSize: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  clone?: Maybe<DimensionMetadata>;
  customDefinition: Scalars['Boolean'];
  deprecatedApiNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  uiName?: Maybe<Scalars['String']>;
};

export type DimensionsForClient = {
  __typename?: 'DimensionsForClient';
  activeProjects: Array<AxProjectDim>;
  brands: Array<AxBrandDim>;
  initiatives: Array<AxInitiativeDim>;
  products: Array<AxProductDim>;
};

export type Division_ForDisplay = {
  __typename?: 'Division_ForDisplay';
  companyCode: Scalars['String'];
  defaultTimeKeepingCompanyId?: Maybe<Scalars['Int']>;
  divisionId: Scalars['Int'];
  divisionName: Scalars['String'];
};

export type Dmainfo = {
  __typename?: 'Dmainfo';
  abbreviation?: Maybe<Scalars['String']>;
  campaignLocalBudgetAssignmentDetails?: Maybe<Array<Maybe<CampaignLocalBudgetAssignmentDetail>>>;
  countryRegion?: Maybe<Region>;
  countryRegionId: Scalars['Int'];
  dmainfoBudgets?: Maybe<Array<Maybe<DmainfoBudget>>>;
  dmainfoBuyers?: Maybe<Array<Maybe<DmainfoBuyer>>>;
  dmainfoId: Scalars['Int'];
  hispanicHouseholds?: Maybe<Scalars['Int']>;
  households?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  marketArea?: Maybe<Scalars['String']>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  percent?: Maybe<Scalars['Decimal']>;
  printTerritories?: Maybe<Array<Maybe<PrintTerritory>>>;
  rank?: Maybe<Scalars['Int']>;
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
  shortName?: Maybe<Scalars['String']>;
  stateRegion?: Maybe<Region>;
  stateRegionId?: Maybe<Scalars['Int']>;
  timeZoneInfo?: Maybe<TimeZoneInfo>;
  timeZoneInfoId: Scalars['Int'];
};

export type DmainfoBudget = {
  __typename?: 'DmainfoBudget';
  amount?: Maybe<Scalars['Decimal']>;
  beginDate: Scalars['Date'];
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dmainfo?: Maybe<Dmainfo>;
  dmainfoBudgetId: Scalars['Int'];
  dmainfoId: Scalars['Int'];
  endDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
};

export type DmainfoBudgetFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<DmainfoBudgetFilterInput>>;
  beginDate?: InputMaybe<DateOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dmainfo?: InputMaybe<DmainfoFilterInput>;
  dmainfoBudgetId?: InputMaybe<IntOperationFilterInput>;
  dmainfoId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DmainfoBudgetFilterInput>>;
};

export type DmainfoBuyer = {
  __typename?: 'DmainfoBuyer';
  appUserRole?: Maybe<AppUserRole>;
  appUserRoleId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  dmainfo?: Maybe<Dmainfo>;
  dmainfoBuyerId: Scalars['Int'];
  dmainfoId: Scalars['Int'];
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type DmainfoBuyerFilterInput = {
  and?: InputMaybe<Array<DmainfoBuyerFilterInput>>;
  appUserRole?: InputMaybe<AppUserRoleFilterInput>;
  appUserRoleId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  dmainfo?: InputMaybe<DmainfoFilterInput>;
  dmainfoBuyerId?: InputMaybe<IntOperationFilterInput>;
  dmainfoId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DmainfoBuyerFilterInput>>;
};

export type DmainfoFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<DmainfoFilterInput>>;
  campaignLocalBudgetAssignmentDetails?: InputMaybe<ListFilterInputTypeOfCampaignLocalBudgetAssignmentDetailFilterInput>;
  countryRegion?: InputMaybe<RegionFilterInput>;
  countryRegionId?: InputMaybe<IntOperationFilterInput>;
  dmainfoBudgets?: InputMaybe<ListFilterInputTypeOfDmainfoBudgetFilterInput>;
  dmainfoBuyers?: InputMaybe<ListFilterInputTypeOfDmainfoBuyerFilterInput>;
  dmainfoId?: InputMaybe<IntOperationFilterInput>;
  hispanicHouseholds?: InputMaybe<IntOperationFilterInput>;
  households?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  marketArea?: InputMaybe<StringOperationFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  or?: InputMaybe<Array<DmainfoFilterInput>>;
  percent?: InputMaybe<DecimalOperationFilterInput>;
  printTerritories?: InputMaybe<ListFilterInputTypeOfPrintTerritoryFilterInput>;
  rank?: InputMaybe<IntOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  shortName?: InputMaybe<StringOperationFilterInput>;
  stateRegion?: InputMaybe<RegionFilterInput>;
  stateRegionId?: InputMaybe<IntOperationFilterInput>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoFilterInput>;
  timeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
};

export type DubOrderTransmission = {
  __typename?: 'DubOrderTransmission';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dubOrderTransmissionId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  transmissionDate: Scalars['DateTime'];
};

export type DubOrderTransmissionFilterInput = {
  and?: InputMaybe<Array<DubOrderTransmissionFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dubOrderTransmissionId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DubOrderTransmissionFilterInput>>;
  transmissionDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type DuplicationCostEstimate = {
  __typename?: 'DuplicationCostEstimate';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  duplicationCostEstimateAds?: Maybe<Array<Maybe<DuplicationCostEstimateAd>>>;
  duplicationCostEstimateDetails?: Maybe<Array<Maybe<DuplicationCostEstimateDetail>>>;
  duplicationCostEstimateId: Scalars['Int'];
  duplicationCostEstimateMediaOutlets?: Maybe<Array<Maybe<DuplicationCostEstimateMediaOutlet>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
};

export type DuplicationCostEstimateAd = {
  __typename?: 'DuplicationCostEstimateAd';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  duplicationCostEstimate?: Maybe<DuplicationCostEstimate>;
  duplicationCostEstimateAdId: Scalars['Int'];
  duplicationCostEstimateId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type DuplicationCostEstimateAdFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<DuplicationCostEstimateAdFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  duplicationCostEstimate?: InputMaybe<DuplicationCostEstimateFilterInput>;
  duplicationCostEstimateAdId?: InputMaybe<IntOperationFilterInput>;
  duplicationCostEstimateId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DuplicationCostEstimateAdFilterInput>>;
};

export type DuplicationCostEstimateDetail = {
  __typename?: 'DuplicationCostEstimateDetail';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  definition?: Maybe<Scalars['String']>;
  deliveryCost?: Maybe<Scalars['Decimal']>;
  duplicationCostEstimate?: Maybe<DuplicationCostEstimate>;
  duplicationCostEstimateDetailId: Scalars['Int'];
  duplicationCostEstimateId: Scalars['Int'];
  expediteFee?: Maybe<Scalars['Decimal']>;
  format?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaCost?: Maybe<Scalars['Decimal']>;
  mediaName?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
};

export type DuplicationCostEstimateDetailFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<DuplicationCostEstimateDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  definition?: InputMaybe<StringOperationFilterInput>;
  deliveryCost?: InputMaybe<DecimalOperationFilterInput>;
  duplicationCostEstimate?: InputMaybe<DuplicationCostEstimateFilterInput>;
  duplicationCostEstimateDetailId?: InputMaybe<IntOperationFilterInput>;
  duplicationCostEstimateId?: InputMaybe<IntOperationFilterInput>;
  expediteFee?: InputMaybe<DecimalOperationFilterInput>;
  format?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaCost?: InputMaybe<DecimalOperationFilterInput>;
  mediaName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DuplicationCostEstimateDetailFilterInput>>;
  serviceName?: InputMaybe<StringOperationFilterInput>;
};

export type DuplicationCostEstimateFilterInput = {
  and?: InputMaybe<Array<DuplicationCostEstimateFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  duplicationCostEstimateAds?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateAdFilterInput>;
  duplicationCostEstimateDetails?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateDetailFilterInput>;
  duplicationCostEstimateId?: InputMaybe<IntOperationFilterInput>;
  duplicationCostEstimateMediaOutlets?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateMediaOutletFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DuplicationCostEstimateFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
};

export type DuplicationCostEstimateMediaOutlet = {
  __typename?: 'DuplicationCostEstimateMediaOutlet';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  duplicationCostEstimate?: Maybe<DuplicationCostEstimate>;
  duplicationCostEstimateId: Scalars['Int'];
  duplicationCostEstimateMediaOutletId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
};

export type DuplicationCostEstimateMediaOutletFilterInput = {
  and?: InputMaybe<Array<DuplicationCostEstimateMediaOutletFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  duplicationCostEstimate?: InputMaybe<DuplicationCostEstimateFilterInput>;
  duplicationCostEstimateId?: InputMaybe<IntOperationFilterInput>;
  duplicationCostEstimateMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DuplicationCostEstimateMediaOutletFilterInput>>;
};

export type DuplicationInvoiceLine = {
  __typename?: 'DuplicationInvoiceLine';
  adName?: Maybe<Scalars['String']>;
  axvendorCode?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  duplicationInvoiceLineId: Scalars['Int'];
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['Int']>;
  isci?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  recordId?: Maybe<Scalars['Int']>;
  recordTableName?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['Int']>;
  vendorName?: Maybe<Scalars['String']>;
};

export type DuplicationInvoiceLineFilterInput = {
  adName?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<DuplicationInvoiceLineFilterInput>>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  duplicationInvoiceLineId?: InputMaybe<IntOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  invoice?: InputMaybe<InvoiceFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  isci?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DuplicationInvoiceLineFilterInput>>;
  productName?: InputMaybe<StringOperationFilterInput>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
  recordId?: InputMaybe<IntOperationFilterInput>;
  recordTableName?: InputMaybe<StringOperationFilterInput>;
  vendor?: InputMaybe<VendorFilterInput>;
  vendorId?: InputMaybe<IntOperationFilterInput>;
  vendorName?: InputMaybe<StringOperationFilterInput>;
};

export type DuplicationLayout = {
  __typename?: 'DuplicationLayout';
  blackHead: Scalars['TimeSpan'];
  blackTail: Scalars['TimeSpan'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalDeliveryServiceAttributes?: Maybe<Array<Maybe<DigitalDeliveryServiceAttribute>>>;
  duplicationLayoutId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletBroadcastDuplicationLayouts?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletViewDuplicationLayouts?: Maybe<Array<Maybe<MediaOutlet>>>;
  name?: Maybe<Scalars['String']>;
  slateLength: Scalars['TimeSpan'];
  startTimeCode: Scalars['TimeSpan'];
};

export type DuplicationLayoutFilterInput = {
  and?: InputMaybe<Array<DuplicationLayoutFilterInput>>;
  blackHead?: InputMaybe<TimeSpanOperationFilterInput>;
  blackTail?: InputMaybe<TimeSpanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalDeliveryServiceAttributes?: InputMaybe<ListFilterInputTypeOfDigitalDeliveryServiceAttributeFilterInput>;
  duplicationLayoutId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletBroadcastDuplicationLayouts?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletViewDuplicationLayouts?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DuplicationLayoutFilterInput>>;
  slateLength?: InputMaybe<TimeSpanOperationFilterInput>;
  startTimeCode?: InputMaybe<TimeSpanOperationFilterInput>;
};

export type DuplicationLayoutSortInput = {
  blackHead?: InputMaybe<SortEnumType>;
  blackTail?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  duplicationLayoutId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  slateLength?: InputMaybe<SortEnumType>;
  startTimeCode?: InputMaybe<SortEnumType>;
};

export type DuplicationPreset = {
  __typename?: 'DuplicationPreset';
  bitRate: Scalars['Int'];
  codecLookup?: Maybe<Lookup>;
  codecLookupId: Scalars['Int'];
  codecProfileLookup?: Maybe<Lookup>;
  codecProfileLookupId: Scalars['Int'];
  codecWrapperLookup?: Maybe<Lookup>;
  codecWrapperLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalDeliveryServiceAttributeHdduplicationPresets?: Maybe<Array<Maybe<DigitalDeliveryServiceAttribute>>>;
  digitalDeliveryServiceAttributeSdduplicationPresets?: Maybe<Array<Maybe<DigitalDeliveryServiceAttribute>>>;
  duplicationPresetId: Scalars['Int'];
  frameRateLookup?: Maybe<Lookup>;
  frameRateLookupId: Scalars['Int'];
  interlaceModeLookup?: Maybe<Lookup>;
  interlaceModeLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletHdduplicationPresets?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletSdduplicationPresets?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletViewDuplicationPresets?: Maybe<Array<Maybe<MediaOutlet>>>;
  name?: Maybe<Scalars['String']>;
  videoHeightLookup?: Maybe<Lookup>;
  videoHeightLookupId: Scalars['Int'];
  videoWidthLookup?: Maybe<Lookup>;
  videoWidthLookupId: Scalars['Int'];
};

export type DuplicationPresetFilterInput = {
  and?: InputMaybe<Array<DuplicationPresetFilterInput>>;
  bitRate?: InputMaybe<IntOperationFilterInput>;
  codecLookup?: InputMaybe<LookupFilterInput>;
  codecLookupId?: InputMaybe<IntOperationFilterInput>;
  codecProfileLookup?: InputMaybe<LookupFilterInput>;
  codecProfileLookupId?: InputMaybe<IntOperationFilterInput>;
  codecWrapperLookup?: InputMaybe<LookupFilterInput>;
  codecWrapperLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalDeliveryServiceAttributeHdduplicationPresets?: InputMaybe<ListFilterInputTypeOfDigitalDeliveryServiceAttributeFilterInput>;
  digitalDeliveryServiceAttributeSdduplicationPresets?: InputMaybe<ListFilterInputTypeOfDigitalDeliveryServiceAttributeFilterInput>;
  duplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  frameRateLookup?: InputMaybe<LookupFilterInput>;
  frameRateLookupId?: InputMaybe<IntOperationFilterInput>;
  interlaceModeLookup?: InputMaybe<LookupFilterInput>;
  interlaceModeLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletHdduplicationPresets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletSdduplicationPresets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletViewDuplicationPresets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DuplicationPresetFilterInput>>;
  videoHeightLookup?: InputMaybe<LookupFilterInput>;
  videoHeightLookupId?: InputMaybe<IntOperationFilterInput>;
  videoWidthLookup?: InputMaybe<LookupFilterInput>;
  videoWidthLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type DuplicationPresetSortInput = {
  bitRate?: InputMaybe<SortEnumType>;
  codecLookup?: InputMaybe<LookupSortInput>;
  codecLookupId?: InputMaybe<SortEnumType>;
  codecProfileLookup?: InputMaybe<LookupSortInput>;
  codecProfileLookupId?: InputMaybe<SortEnumType>;
  codecWrapperLookup?: InputMaybe<LookupSortInput>;
  codecWrapperLookupId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  duplicationPresetId?: InputMaybe<SortEnumType>;
  frameRateLookup?: InputMaybe<LookupSortInput>;
  frameRateLookupId?: InputMaybe<SortEnumType>;
  interlaceModeLookup?: InputMaybe<LookupSortInput>;
  interlaceModeLookupId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  videoHeightLookup?: InputMaybe<LookupSortInput>;
  videoHeightLookupId?: InputMaybe<SortEnumType>;
  videoWidthLookup?: InputMaybe<LookupSortInput>;
  videoWidthLookupId?: InputMaybe<SortEnumType>;
};

export type EditInvoiceInfoForCsiFileError = ArgumentError | RainError;

export type EditInvoiceInfoForCsiFileInput = {
  checkNumber: Scalars['String'];
  description: Scalars['String'];
  fileName: Scalars['String'];
  invoiceDate: Scalars['DateTime'];
  invoiceNumber: Scalars['String'];
  rowNumber: Scalars['Int'];
};

export type EditInvoiceInfoForCsiFilePayload = {
  __typename?: 'EditInvoiceInfoForCSIFilePayload';
  csiFileDetails?: Maybe<Array<CsiFileDetails>>;
  errors?: Maybe<Array<EditInvoiceInfoForCsiFileError>>;
};

export type EntityBase = {
  __typename?: 'EntityBase';
  anyPropertyValuesChanged: Scalars['Boolean'];
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  propertyValueHasChanged: Scalars['Boolean'];
};


export type EntityBasePropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type EnumDescription = {
  __typename?: 'EnumDescription';
  enumDescriptionId: Scalars['Int'];
  enumDescriptionType: EnumDescriptionType;
  enumDisplayName: Scalars['String'];
  enumName: Scalars['String'];
  enumValue: Scalars['Int'];
};

export enum EnumDescriptionType {
  FunctionalRole = 'FUNCTIONAL_ROLE',
  RainOnePermission = 'RAIN_ONE_PERMISSION',
  Unknown = 'UNKNOWN'
}

export type Error = {
  message: Scalars['String'];
};

export enum ErrorLevel {
  Failure = 'FAILURE',
  Info = 'INFO',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

export type Favorite = {
  __typename?: 'Favorite';
  categoryEndDate?: Maybe<Scalars['DateTime']>;
  categoryId: Scalars['String'];
  categoryStateDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Long'];
  projId: Scalars['String'];
  projectDataAreaId: Scalars['String'];
  projectEndDate?: Maybe<Scalars['DateTime']>;
  projectStartDate?: Maybe<Scalars['DateTime']>;
};

export type File = {
  __typename?: 'File';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileData?: Maybe<Array<Scalars['Byte']>>;
  fileId: Scalars['Int'];
  fileName?: Maybe<Scalars['String']>;
  fileTypeLookup?: Maybe<Lookup>;
  fileTypeLookupId: Scalars['Int'];
  glanceReportElements?: Maybe<Array<Maybe<GlanceReportElement>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  uploadAppUser?: Maybe<AppUser>;
  uploadAppUserId: Scalars['Int'];
  uploadUtc: Scalars['DateTime'];
};

export type FileFilterInput = {
  and?: InputMaybe<Array<FileFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileData?: InputMaybe<ListByteOperationFilterInput>;
  fileId?: InputMaybe<IntOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  fileTypeLookup?: InputMaybe<LookupFilterInput>;
  fileTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  glanceReportElements?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  or?: InputMaybe<Array<FileFilterInput>>;
  uploadAppUser?: InputMaybe<AppUserFilterInput>;
  uploadAppUserId?: InputMaybe<IntOperationFilterInput>;
  uploadUtc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type FileImport = {
  __typename?: 'FileImport';
  adInstanceImportFileImports?: Maybe<Array<Maybe<AdInstanceImportFileImport>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalSpendInputFiles?: Maybe<Array<Maybe<DigitalSpendInputFile>>>;
  duplicationInvoiceLines?: Maybe<Array<Maybe<DuplicationInvoiceLine>>>;
  fileImportId: Scalars['Int'];
  fileImportTypeLookup?: Maybe<Lookup>;
  fileImportTypeLookupId: Scalars['Int'];
  fileName?: Maybe<Scalars['String']>;
  fileProcessorRunFileImports?: Maybe<Array<Maybe<FileProcessorRunFileImport>>>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  firstLine?: Maybe<Scalars['String']>;
  importAppUser?: Maybe<AppUser>;
  importAppUserId?: Maybe<Scalars['Int']>;
  importDate?: Maybe<Scalars['DateTime']>;
  importDateUtc?: Maybe<Scalars['DateTime']>;
  jobInstances?: Maybe<Array<Maybe<JobInstance>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketCostPers?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  mediaAvails?: Maybe<Array<Maybe<MediaAvail>>>;
  mriindices?: Maybe<Array<Maybe<Mriindex>>>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  printAvails?: Maybe<Array<Maybe<PrintAvail>>>;
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  scheduleFileImports?: Maybe<Array<Maybe<ScheduleFileImport>>>;
  surveyChoiceTextFileImports?: Maybe<Array<Maybe<Survey>>>;
  surveyCodedValuesFileImports?: Maybe<Array<Maybe<Survey>>>;
  trackingServiceLines?: Maybe<Array<Maybe<TrackingServiceLine>>>;
};

export type FileImportFilterInput = {
  adInstanceImportFileImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFileImportFilterInput>;
  and?: InputMaybe<Array<FileImportFilterInput>>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputFiles?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputFileFilterInput>;
  duplicationInvoiceLines?: InputMaybe<ListFilterInputTypeOfDuplicationInvoiceLineFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  fileImportTypeLookup?: InputMaybe<LookupFilterInput>;
  fileImportTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  fileProcessorRunFileImports?: InputMaybe<ListFilterInputTypeOfFileProcessorRunFileImportFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  firstLine?: InputMaybe<StringOperationFilterInput>;
  importAppUser?: InputMaybe<AppUserFilterInput>;
  importAppUserId?: InputMaybe<IntOperationFilterInput>;
  importDate?: InputMaybe<DateTimeOperationFilterInput>;
  importDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  jobInstances?: InputMaybe<ListFilterInputTypeOfJobInstanceFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketCostPers?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  mediaAvails?: InputMaybe<ListFilterInputTypeOfMediaAvailFilterInput>;
  mriindices?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileImportFilterInput>>;
  prelogs?: InputMaybe<ListFilterInputTypeOfPrelogFilterInput>;
  printAvails?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  resultStagings?: InputMaybe<ListFilterInputTypeOfResultStagingFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  scheduleFileImports?: InputMaybe<ListFilterInputTypeOfScheduleFileImportFilterInput>;
  surveyChoiceTextFileImports?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  surveyCodedValuesFileImports?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  trackingServiceLines?: InputMaybe<ListFilterInputTypeOfTrackingServiceLineFilterInput>;
};

export type FileImportMap = {
  __typename?: 'FileImportMap';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fieldName?: Maybe<Scalars['String']>;
  fileImportMapId: Scalars['Int'];
  fileImportTypeLookup?: Maybe<Lookup>;
  fileImportTypeLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  replaceWith?: Maybe<Scalars['String']>;
  searchFor?: Maybe<Scalars['String']>;
};

export type FileImportMapFilterInput = {
  and?: InputMaybe<Array<FileImportMapFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fieldName?: InputMaybe<StringOperationFilterInput>;
  fileImportMapId?: InputMaybe<IntOperationFilterInput>;
  fileImportTypeLookup?: InputMaybe<LookupFilterInput>;
  fileImportTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileImportMapFilterInput>>;
  replaceWith?: InputMaybe<StringOperationFilterInput>;
  searchFor?: InputMaybe<StringOperationFilterInput>;
};

export type FileProcessorRun = {
  __typename?: 'FileProcessorRun';
  fileProcessorRunFileImports?: Maybe<Array<Maybe<FileProcessorRunFileImport>>>;
  fileProcessorRunId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  xmldata?: Maybe<Scalars['String']>;
  xmlfileReference?: Maybe<FileReference>;
  xmlfileReferenceId?: Maybe<Scalars['Int']>;
};

export type FileProcessorRunFileImport = {
  __typename?: 'FileProcessorRunFileImport';
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  fileProcessorRun?: Maybe<FileProcessorRun>;
  fileProcessorRunFileImportId: Scalars['Int'];
  fileProcessorRunId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type FileProcessorRunFileImportFilterInput = {
  and?: InputMaybe<Array<FileProcessorRunFileImportFilterInput>>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  fileProcessorRun?: InputMaybe<FileProcessorRunFilterInput>;
  fileProcessorRunFileImportId?: InputMaybe<IntOperationFilterInput>;
  fileProcessorRunId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<FileProcessorRunFileImportFilterInput>>;
};

export type FileProcessorRunFilterInput = {
  and?: InputMaybe<Array<FileProcessorRunFilterInput>>;
  fileProcessorRunFileImports?: InputMaybe<ListFilterInputTypeOfFileProcessorRunFileImportFilterInput>;
  fileProcessorRunId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<FileProcessorRunFilterInput>>;
  xmldata?: InputMaybe<StringOperationFilterInput>;
  xmlfileReference?: InputMaybe<FileReferenceFilterInput>;
  xmlfileReferenceId?: InputMaybe<IntOperationFilterInput>;
};

export type FileReference = {
  __typename?: 'FileReference';
  accountingLines?: Maybe<Array<Maybe<AccountingLine>>>;
  adFileReferences?: Maybe<Array<Maybe<Ad>>>;
  adMediaFileReferences?: Maybe<Array<Maybe<Ad>>>;
  availProposals?: Maybe<Array<Maybe<AvailProposal>>>;
  bookingOrderConfirmationFileReferences?: Maybe<Array<Maybe<BookingOrder>>>;
  bookingOrderOriginalFileReferences?: Maybe<Array<Maybe<BookingOrder>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  clientFileReferences?: Maybe<Array<Maybe<ClientFileReference>>>;
  clients?: Maybe<Array<Maybe<Client>>>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costPerLeadRanges?: Maybe<Array<Maybe<CostPerLeadRange>>>;
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
  filePath?: Maybe<Scalars['String']>;
  fileProcessorRuns?: Maybe<Array<Maybe<FileProcessorRun>>>;
  fileReferenceId: Scalars['Int'];
  fileToProcesses?: Maybe<Array<Maybe<FileToProcess>>>;
  fileTypeLookup?: Maybe<Lookup>;
  fileTypeLookupId: Scalars['Int'];
  insertionOrderFileReferences?: Maybe<Array<Maybe<InsertionOrderFileReference>>>;
  invoiceFileReferences?: Maybe<Array<Maybe<Invoice>>>;
  invoiceInvoiceFileReferences?: Maybe<Array<Maybe<Invoice>>>;
  invoicePaymentFileReferences?: Maybe<Array<Maybe<Invoice>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingLocks?: Maybe<Array<Maybe<LeadBillingLock>>>;
  mediaAuthorizationConfirmationFileReferences?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaAuthorizationFileReferences?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaTransmissions?: Maybe<Array<Maybe<MediaTransmission>>>;
  newCampaignAnnouncementFileReferences?: Maybe<Array<Maybe<NewCampaignAnnouncementFileReference>>>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  originalFileName?: Maybe<Scalars['String']>;
  parserFileReferences?: Maybe<Array<Maybe<ParserFileReference>>>;
  rateCards?: Maybe<Array<Maybe<RateCard>>>;
  revisionGroupConfirmationFileReferences?: Maybe<Array<Maybe<RevisionGroup>>>;
  revisionGroupOriginalFileReferences?: Maybe<Array<Maybe<RevisionGroup>>>;
  scheduleCreditMemoFileReferences?: Maybe<Array<Maybe<Schedule>>>;
  scheduleFileReferences?: Maybe<Array<Maybe<ScheduleFileReference>>>;
  scheduleLeadCountsFileReferences?: Maybe<Array<Maybe<Schedule>>>;
  scheduleMakeGoodFileReferences?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtartFileReferences?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSfpostLogFileReferences?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSfpreLogFileReferences?: Maybe<Array<Maybe<Schedule>>>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
  taskHistoryFileReferences?: Maybe<Array<Maybe<TaskHistoryFileReference>>>;
  tmpInvoiceFileReferences?: Maybe<Array<Maybe<TmpInvoice>>>;
  tmpInvoiceInvoiceFileReferences?: Maybe<Array<Maybe<TmpInvoice>>>;
  tmpInvoicePaymentFileReferences?: Maybe<Array<Maybe<TmpInvoice>>>;
  waveCastWaveStimulusFileReferences?: Maybe<Array<Maybe<WaveCastWave>>>;
  waveCastWaveWaveCastOutputAggregatesFileReferences?: Maybe<Array<Maybe<WaveCastWave>>>;
  waveCastWaveWaveCastOutputColinearityFileReferences?: Maybe<Array<Maybe<WaveCastWave>>>;
  waveCastWaveWaveCastOutputMediaOutletFileReferences?: Maybe<Array<Maybe<WaveCastWave>>>;
  waveCastWaveWaveCastOutputProgramFileReferences?: Maybe<Array<Maybe<WaveCastWave>>>;
};

export type FileReferenceFilterInput = {
  accountingLines?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  adFileReferences?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adMediaFileReferences?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<FileReferenceFilterInput>>;
  availProposals?: InputMaybe<ListFilterInputTypeOfAvailProposalFilterInput>;
  bookingOrderConfirmationFileReferences?: InputMaybe<ListFilterInputTypeOfBookingOrderFilterInput>;
  bookingOrderOriginalFileReferences?: InputMaybe<ListFilterInputTypeOfBookingOrderFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  clientFileReferences?: InputMaybe<ListFilterInputTypeOfClientFileReferenceFilterInput>;
  clients?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costPerLeadRanges?: InputMaybe<ListFilterInputTypeOfCostPerLeadRangeFilterInput>;
  fileImports?: InputMaybe<ListFilterInputTypeOfFileImportFilterInput>;
  filePath?: InputMaybe<StringOperationFilterInput>;
  fileProcessorRuns?: InputMaybe<ListFilterInputTypeOfFileProcessorRunFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  fileToProcesses?: InputMaybe<ListFilterInputTypeOfFileToProcessFilterInput>;
  fileTypeLookup?: InputMaybe<LookupFilterInput>;
  fileTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderFileReferences?: InputMaybe<ListFilterInputTypeOfInsertionOrderFileReferenceFilterInput>;
  invoiceFileReferences?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  invoiceInvoiceFileReferences?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  invoicePaymentFileReferences?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingLocks?: InputMaybe<ListFilterInputTypeOfLeadBillingLockFilterInput>;
  mediaAuthorizationConfirmationFileReferences?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaAuthorizationFileReferences?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfMediaOutletFileReferenceFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaTransmissions?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  newCampaignAnnouncementFileReferences?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFileReferenceFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileReferenceFilterInput>>;
  originalFileName?: InputMaybe<StringOperationFilterInput>;
  parserFileReferences?: InputMaybe<ListFilterInputTypeOfParserFileReferenceFilterInput>;
  rateCards?: InputMaybe<ListFilterInputTypeOfRateCardFilterInput>;
  revisionGroupConfirmationFileReferences?: InputMaybe<ListFilterInputTypeOfRevisionGroupFilterInput>;
  revisionGroupOriginalFileReferences?: InputMaybe<ListFilterInputTypeOfRevisionGroupFilterInput>;
  scheduleCreditMemoFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFileReferenceFilterInput>;
  scheduleLeadCountsFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleMakeGoodFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtartFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleSfpostLogFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleSfpreLogFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  standardsAndPracticesApprovals?: InputMaybe<ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput>;
  taskHistoryFileReferences?: InputMaybe<ListFilterInputTypeOfTaskHistoryFileReferenceFilterInput>;
  tmpInvoiceFileReferences?: InputMaybe<ListFilterInputTypeOfTmpInvoiceFilterInput>;
  tmpInvoiceInvoiceFileReferences?: InputMaybe<ListFilterInputTypeOfTmpInvoiceFilterInput>;
  tmpInvoicePaymentFileReferences?: InputMaybe<ListFilterInputTypeOfTmpInvoiceFilterInput>;
  waveCastWaveStimulusFileReferences?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
  waveCastWaveWaveCastOutputAggregatesFileReferences?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
  waveCastWaveWaveCastOutputColinearityFileReferences?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
  waveCastWaveWaveCastOutputMediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
  waveCastWaveWaveCastOutputProgramFileReferences?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
};

export type FileReferenceInput = {
  fileName: Scalars['String'];
  fileType: FileType;
};

export type FileReferenceSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  filePath?: InputMaybe<SortEnumType>;
  fileReferenceId?: InputMaybe<SortEnumType>;
  fileTypeLookup?: InputMaybe<LookupSortInput>;
  fileTypeLookupId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  oldtableName?: InputMaybe<SortEnumType>;
  originalFileName?: InputMaybe<SortEnumType>;
};

export type FileToProcess = {
  __typename?: 'FileToProcess';
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId: Scalars['Int'];
  fileToProcessId: Scalars['Int'];
  firstLine?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc: Scalars['DateTime'];
  loadTime: Scalars['DateTime'];
  loadedBy?: Maybe<Scalars['String']>;
  logType?: Maybe<LogType>;
  logTypeId: Scalars['Int'];
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  parser?: Maybe<Parser>;
  parserId?: Maybe<Scalars['Int']>;
  processTime?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  sourceName?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type FileToProcessFilterInput = {
  and?: InputMaybe<Array<FileToProcessFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  fileToProcessId?: InputMaybe<IntOperationFilterInput>;
  firstLine?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  loadTime?: InputMaybe<DateTimeOperationFilterInput>;
  loadedBy?: InputMaybe<StringOperationFilterInput>;
  logType?: InputMaybe<LogTypeFilterInput>;
  logTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FileToProcessFilterInput>>;
  parser?: InputMaybe<ParserFilterInput>;
  parserId?: InputMaybe<IntOperationFilterInput>;
  processTime?: InputMaybe<DateTimeOperationFilterInput>;
  source?: InputMaybe<StringOperationFilterInput>;
  sourceName?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<IntOperationFilterInput>;
};

export enum FileType {
  Aif = 'AIF',
  Avi = 'AVI',
  Bmp = 'BMP',
  Csv = 'CSV',
  Excel = 'EXCEL',
  Gif = 'GIF',
  Gxf = 'GXF',
  Html = 'HTML',
  Jpg = 'JPG',
  Lxf = 'LXF',
  M2Ts = 'M2TS',
  M4A = 'M4A',
  Mht = 'MHT',
  Mov = 'MOV',
  Mp3 = 'MP3',
  Mp4 = 'MP4',
  Mpg = 'MPG',
  Mxf = 'MXF',
  OutlookEmail = 'OUTLOOK_EMAIL',
  Pdf = 'PDF',
  Png = 'PNG',
  Scx = 'SCX',
  Snp = 'SNP',
  Swf = 'SWF',
  Tif = 'TIF',
  Ts = 'TS',
  Txt = 'TXT',
  Wav = 'WAV',
  Wmv = 'WMV',
  WordDocument = 'WORD_DOCUMENT',
  Xml = 'XML'
}

export type FinopsDepartment = {
  __typename?: 'FinopsDepartment';
  departmentCode: Scalars['String'];
  departmentId: Scalars['Int'];
  departmentName: Scalars['String'];
  isEmploymentDepartment: Scalars['Boolean'];
};

export enum FlightDateGranularity {
  BroadcastMonth = 'BROADCAST_MONTH',
  CalendarWeek = 'CALENDAR_WEEK',
  Campaign = 'CAMPAIGN',
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK'
}

export type FlightDay = {
  __typename?: 'FlightDay';
  broadcastMonth: Scalars['Date'];
  broadcastMonthDisplay: Scalars['String'];
  calendarWeek: Scalars['Date'];
  calendarWeekDisplay: Scalars['String'];
  date: Scalars['Date'];
  dateDisplay: Scalars['String'];
  month: Scalars['Date'];
  monthDisplay: Scalars['String'];
  quarter: Scalars['Date'];
  quarterDisplay: Scalars['String'];
  week: Scalars['Date'];
  weekDisplay: Scalars['String'];
};

export type FlightDayFilterInput = {
  and?: InputMaybe<Array<FlightDayFilterInput>>;
  broadcastMonth?: InputMaybe<DateOperationFilterInput>;
  broadcastMonthDisplay?: InputMaybe<StringOperationFilterInput>;
  calendarWeek?: InputMaybe<DateOperationFilterInput>;
  calendarWeekDisplay?: InputMaybe<StringOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  dateDisplay?: InputMaybe<StringOperationFilterInput>;
  month?: InputMaybe<DateOperationFilterInput>;
  monthDisplay?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FlightDayFilterInput>>;
  quarter?: InputMaybe<DateOperationFilterInput>;
  quarterDisplay?: InputMaybe<StringOperationFilterInput>;
  week?: InputMaybe<DateOperationFilterInput>;
  weekDisplay?: InputMaybe<StringOperationFilterInput>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export enum FunctionalRole {
  AccountsReceivable = 'ACCOUNTS_RECEIVABLE',
  ClientArAging = 'CLIENT_AR_AGING',
  ClientFinance = 'CLIENT_FINANCE',
  /** @deprecated No Longer in Use */
  ClientProjectManager = 'CLIENT_PROJECT_MANAGER',
  CreativeProjectManager = 'CREATIVE_PROJECT_MANAGER',
  DataBreackageAlert = 'DATA_BREACKAGE_ALERT',
  DigitalMedia = 'DIGITAL_MEDIA',
  FinopsAccountsPayable = 'FINOPS_ACCOUNTS_PAYABLE',
  MissingClientRoleManager = 'MISSING_CLIENT_ROLE_MANAGER',
  /** @deprecated No Longer in Use */
  MyClientCcp = 'MY_CLIENT_CCP',
  /** @deprecated No Longer in Use */
  MyCompanyArAging = 'MY_COMPANY_AR_AGING',
  /** @deprecated No Longer in Use */
  MyCompanyCcp = 'MY_COMPANY_CCP',
  RainOneUser = 'RAIN_ONE_USER',
  TimesheetInputer = 'TIMESHEET_INPUTER',
  TimesheetManager = 'TIMESHEET_MANAGER',
  TimesheetViewAll = 'TIMESHEET_VIEW_ALL',
  TimeTransferApprover = 'TIME_TRANSFER_APPROVER'
}

export type FunctionalRoleOperationFilterInput = {
  eq?: InputMaybe<FunctionalRole>;
  in?: InputMaybe<Array<FunctionalRole>>;
  neq?: InputMaybe<FunctionalRole>;
  nin?: InputMaybe<Array<FunctionalRole>>;
};

export type GaAccountSummary = {
  __typename?: 'GaAccountSummary';
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  propertySummaries?: Maybe<Array<GaPropertySummary>>;
};

export type GaPropertyMetadata = {
  __typename?: 'GaPropertyMetadata';
  dimensions?: Maybe<Array<DimensionMetadata>>;
  metrics?: Maybe<Array<MetricMetadata>>;
  name?: Maybe<Scalars['String']>;
};

export type GaPropertySummary = {
  __typename?: 'GaPropertySummary';
  displayName?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
};

export type GaReportResponse = {
  __typename?: 'GaReportResponse';
  dimensionHeaders?: Maybe<Array<DimensionHeader>>;
  kind?: Maybe<Scalars['String']>;
  metadata?: Maybe<ResponseMetaData>;
  metricHeaders?: Maybe<Array<MetricHeader>>;
  rowCount: Scalars['Int'];
  rows?: Maybe<Array<GaRow>>;
};

export type GaRow = {
  __typename?: 'GaRow';
  dimensionValues?: Maybe<Array<GaValue>>;
  metricValues?: Maybe<Array<GaValue>>;
};

export type GaValue = {
  __typename?: 'GaValue';
  value?: Maybe<Scalars['String']>;
};

export type Gareport = {
  __typename?: 'Gareport';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createUtc?: Maybe<Scalars['DateTime']>;
  dataGapThreshold?: Maybe<Scalars['Int']>;
  filePath?: Maybe<Scalars['String']>;
  ga4accountId?: Maybe<Scalars['String']>;
  ga4propertyId?: Maybe<Scalars['String']>;
  gaaccountName?: Maybe<Scalars['String']>;
  gadimensions?: Maybe<Scalars['String']>;
  gafilter?: Maybe<Scalars['String']>;
  gametrics?: Maybe<Scalars['String']>;
  gapropertyName?: Maybe<Scalars['String']>;
  gareportId: Scalars['Int'];
  gareportSkudefinitions?: Maybe<Array<Maybe<GareportSkudefinition>>>;
  gasegmentId?: Maybe<Scalars['String']>;
  gaviewId: Scalars['Int'];
  gaviewName?: Maybe<Scalars['String']>;
  gaviewTimeZone?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId: Scalars['Int'];
  lastSuccessfulRunUtc?: Maybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productCodeDefinition?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  reportEnabled: Scalars['Boolean'];
  resultCommentDefinition?: Maybe<Scalars['String']>;
  runAt?: Maybe<Scalars['TimeSpan']>;
  script?: Maybe<Script>;
  scriptId?: Maybe<Scalars['Int']>;
  timeGranularityLookup?: Maybe<Lookup>;
  timeGranularityLookupId?: Maybe<Scalars['Int']>;
  trackingCodeDefinition?: Maybe<Scalars['String']>;
  trackingCodeTypeLookup?: Maybe<Lookup>;
  trackingCodeTypeLookupId?: Maybe<Scalars['Int']>;
  uniqueIddefinition?: Maybe<Scalars['String']>;
};

export type GareportFilterInput = {
  and?: InputMaybe<Array<GareportFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  dataGapThreshold?: InputMaybe<IntOperationFilterInput>;
  filePath?: InputMaybe<StringOperationFilterInput>;
  ga4accountId?: InputMaybe<StringOperationFilterInput>;
  ga4propertyId?: InputMaybe<StringOperationFilterInput>;
  gaaccountName?: InputMaybe<StringOperationFilterInput>;
  gadimensions?: InputMaybe<StringOperationFilterInput>;
  gafilter?: InputMaybe<StringOperationFilterInput>;
  gametrics?: InputMaybe<StringOperationFilterInput>;
  gapropertyName?: InputMaybe<StringOperationFilterInput>;
  gareportId?: InputMaybe<IntOperationFilterInput>;
  gareportSkudefinitions?: InputMaybe<ListFilterInputTypeOfGareportSkudefinitionFilterInput>;
  gasegmentId?: InputMaybe<StringOperationFilterInput>;
  gaviewId?: InputMaybe<IntOperationFilterInput>;
  gaviewName?: InputMaybe<StringOperationFilterInput>;
  gaviewTimeZone?: InputMaybe<StringOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobId?: InputMaybe<IntOperationFilterInput>;
  lastSuccessfulRunUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GareportFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productCodeDefinition?: InputMaybe<StringOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  reportEnabled?: InputMaybe<BooleanOperationFilterInput>;
  resultCommentDefinition?: InputMaybe<StringOperationFilterInput>;
  runAt?: InputMaybe<TimeSpanOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  timeGranularityLookup?: InputMaybe<LookupFilterInput>;
  timeGranularityLookupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeDefinition?: InputMaybe<StringOperationFilterInput>;
  trackingCodeTypeLookup?: InputMaybe<LookupFilterInput>;
  trackingCodeTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  uniqueIddefinition?: InputMaybe<StringOperationFilterInput>;
};

export type GareportInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  createUTC?: InputMaybe<Scalars['DateTime']>;
  dataGapThreshold?: InputMaybe<Scalars['Int']>;
  filePath?: InputMaybe<Scalars['String']>;
  ga4AccountId?: InputMaybe<Scalars['String']>;
  ga4PropertyId?: InputMaybe<Scalars['String']>;
  gaAccountName?: InputMaybe<Scalars['String']>;
  gaDimensions?: InputMaybe<Scalars['String']>;
  gaFilter?: InputMaybe<Scalars['String']>;
  gaMetrics?: InputMaybe<Scalars['String']>;
  gaPropertyName?: InputMaybe<Scalars['String']>;
  gaReportId?: InputMaybe<Scalars['Int']>;
  gaSegmentId?: InputMaybe<Scalars['String']>;
  gaViewId?: InputMaybe<Scalars['Int']>;
  gaViewName?: InputMaybe<Scalars['String']>;
  gaViewTimeZone?: InputMaybe<Scalars['String']>;
  gareportSkudefinitions: Array<GareportSkudefinitionInput>;
  jobId?: InputMaybe<Scalars['Int']>;
  lastSuccessfulRunUTC?: InputMaybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  productCodeDefinition?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  reportEnabled?: InputMaybe<Scalars['Boolean']>;
  resultCommentDefinition?: InputMaybe<Scalars['String']>;
  runAt?: InputMaybe<Scalars['TimeSpan']>;
  scriptId?: InputMaybe<Scalars['Int']>;
  timeGranularityLookupId?: InputMaybe<Scalars['Int']>;
  trackingCodeDefinition?: InputMaybe<Scalars['String']>;
  trackingCodeTypeLookupId?: InputMaybe<Scalars['Int']>;
  uniqueIDDefinition?: InputMaybe<Scalars['String']>;
};

export type GareportSkudefinition = {
  __typename?: 'GareportSkudefinition';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  gareport?: Maybe<Gareport>;
  gareportId: Scalars['Int'];
  gareportSkudefinitionId: Scalars['Int'];
  googleAnalyticsMeasureLookup?: Maybe<Lookup>;
  googleAnalyticsMeasureLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  skudefinition?: Maybe<Scalars['String']>;
};

export type GareportSkudefinitionFilterInput = {
  and?: InputMaybe<Array<GareportSkudefinitionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  gareport?: InputMaybe<GareportFilterInput>;
  gareportId?: InputMaybe<IntOperationFilterInput>;
  gareportSkudefinitionId?: InputMaybe<IntOperationFilterInput>;
  googleAnalyticsMeasureLookup?: InputMaybe<LookupFilterInput>;
  googleAnalyticsMeasureLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GareportSkudefinitionFilterInput>>;
  skudefinition?: InputMaybe<StringOperationFilterInput>;
};

export type GareportSkudefinitionInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  gaReportId?: InputMaybe<Scalars['Int']>;
  gareportSkudefinitionId?: InputMaybe<Scalars['Int']>;
  googleAnalyticsMeasureLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  skuDefinition?: InputMaybe<Scalars['String']>;
};

export type GareportSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createAppUser?: InputMaybe<AppUserSortInput>;
  createAppUserId?: InputMaybe<SortEnumType>;
  createUtc?: InputMaybe<SortEnumType>;
  dataGapThreshold?: InputMaybe<SortEnumType>;
  filePath?: InputMaybe<SortEnumType>;
  ga4accountId?: InputMaybe<SortEnumType>;
  ga4propertyId?: InputMaybe<SortEnumType>;
  gaaccountName?: InputMaybe<SortEnumType>;
  gadimensions?: InputMaybe<SortEnumType>;
  gafilter?: InputMaybe<SortEnumType>;
  gametrics?: InputMaybe<SortEnumType>;
  gapropertyName?: InputMaybe<SortEnumType>;
  gareportId?: InputMaybe<SortEnumType>;
  gasegmentId?: InputMaybe<SortEnumType>;
  gaviewId?: InputMaybe<SortEnumType>;
  gaviewName?: InputMaybe<SortEnumType>;
  gaviewTimeZone?: InputMaybe<SortEnumType>;
  job?: InputMaybe<JobSortInput>;
  jobId?: InputMaybe<SortEnumType>;
  lastSuccessfulRunUtc?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productCodeDefinition?: InputMaybe<SortEnumType>;
  productId?: InputMaybe<SortEnumType>;
  reportEnabled?: InputMaybe<SortEnumType>;
  resultCommentDefinition?: InputMaybe<SortEnumType>;
  runAt?: InputMaybe<SortEnumType>;
  script?: InputMaybe<ScriptSortInput>;
  scriptId?: InputMaybe<SortEnumType>;
  timeGranularityLookup?: InputMaybe<LookupSortInput>;
  timeGranularityLookupId?: InputMaybe<SortEnumType>;
  trackingCodeDefinition?: InputMaybe<SortEnumType>;
  trackingCodeTypeLookup?: InputMaybe<LookupSortInput>;
  trackingCodeTypeLookupId?: InputMaybe<SortEnumType>;
  uniqueIddefinition?: InputMaybe<SortEnumType>;
};

export type GenerateFinalCostSummaryError = ArgumentError | RainError;

export type GenerateFinalCostSummaryInput = {
  projectEstimateVersionId: Scalars['Int'];
};

export type GenerateFinalCostSummaryPayload = {
  __typename?: 'GenerateFinalCostSummaryPayload';
  errors?: Maybe<Array<GenerateFinalCostSummaryError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type GlanceReport = {
  __typename?: 'GlanceReport';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  autoReportItems?: Maybe<Array<Maybe<AutoReportItem>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate: Scalars['DateTime'];
  glanceReportId: Scalars['Int'];
  glanceReportWorksheets?: Maybe<Array<Maybe<GlanceReportWorksheet>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type GlanceReportDimension = {
  __typename?: 'GlanceReportDimension';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dimensionTypeLookup?: Maybe<Lookup>;
  dimensionTypeLookupId: Scalars['Int'];
  glanceReportDimensionId: Scalars['Int'];
  glanceReportWorksheet?: Maybe<GlanceReportWorksheet>;
  glanceReportWorksheetId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  position: Scalars['Int'];
  value: Scalars['Int'];
};

export type GlanceReportDimensionFilterInput = {
  and?: InputMaybe<Array<GlanceReportDimensionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dimensionTypeLookup?: InputMaybe<LookupFilterInput>;
  dimensionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  glanceReportDimensionId?: InputMaybe<IntOperationFilterInput>;
  glanceReportWorksheet?: InputMaybe<GlanceReportWorksheetFilterInput>;
  glanceReportWorksheetId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GlanceReportDimensionFilterInput>>;
  position?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type GlanceReportElement = {
  __typename?: 'GlanceReportElement';
  backgroundColor?: Maybe<Scalars['String']>;
  boxDetailsMargin?: Maybe<Scalars['String']>;
  boxGlanceReportBoxElementDirectionLookup?: Maybe<Lookup>;
  boxGlanceReportBoxElementDirectionLookupId?: Maybe<Scalars['Int']>;
  boxGlanceReportBoxElementTotalsBasedOnLookup?: Maybe<Lookup>;
  boxGlanceReportBoxElementTotalsBasedOnLookupId?: Maybe<Scalars['Int']>;
  boxGroupAppField?: Maybe<AppField>;
  boxGroupAppFieldId?: Maybe<Scalars['Int']>;
  boxGroupNumberOfRecords?: Maybe<Scalars['Int']>;
  boxGroupSortAppField?: Maybe<AppField>;
  boxGroupSortAppFieldId?: Maybe<Scalars['Int']>;
  boxGroupSortDirectionLookup?: Maybe<Lookup>;
  boxGroupSortDirectionLookupId?: Maybe<Scalars['Int']>;
  boxHideBoxHeader: Scalars['Boolean'];
  boxHideHeaders: Scalars['Boolean'];
  boxHideLabels: Scalars['Boolean'];
  boxReportExportFormat?: Maybe<ReportExportFormat>;
  boxReportExportFormatId?: Maybe<Scalars['Int']>;
  boxReportPreset?: Maybe<ReportPreset>;
  boxReportPresetId?: Maybe<Scalars['Int']>;
  boxTotalsHeaderDisplayName?: Maybe<Scalars['String']>;
  column: Scalars['Int'];
  columnSpan: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createDate: Scalars['DateTime'];
  elementTypeLookup?: Maybe<Lookup>;
  elementTypeLookupId: Scalars['Int'];
  excelFormula?: Maybe<Scalars['String']>;
  fontBold: Scalars['Boolean'];
  fontUnderline: Scalars['Boolean'];
  foregroundColor?: Maybe<Scalars['String']>;
  glanceReportElementId: Scalars['Int'];
  glanceReportWorksheet?: Maybe<GlanceReportWorksheet>;
  glanceReportWorksheetId: Scalars['Int'];
  horizontalAlignmentLookup?: Maybe<Lookup>;
  horizontalAlignmentLookupId: Scalars['Int'];
  imageFile?: Maybe<File>;
  imageFileId?: Maybe<Scalars['Int']>;
  imageScale?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  row: Scalars['Int'];
  verticalAlignmentLookup?: Maybe<Lookup>;
  verticalAlignmentLookupId: Scalars['Int'];
};

export type GlanceReportElementFilterInput = {
  and?: InputMaybe<Array<GlanceReportElementFilterInput>>;
  backgroundColor?: InputMaybe<StringOperationFilterInput>;
  boxDetailsMargin?: InputMaybe<StringOperationFilterInput>;
  boxGlanceReportBoxElementDirectionLookup?: InputMaybe<LookupFilterInput>;
  boxGlanceReportBoxElementDirectionLookupId?: InputMaybe<IntOperationFilterInput>;
  boxGlanceReportBoxElementTotalsBasedOnLookup?: InputMaybe<LookupFilterInput>;
  boxGlanceReportBoxElementTotalsBasedOnLookupId?: InputMaybe<IntOperationFilterInput>;
  boxGroupAppField?: InputMaybe<AppFieldFilterInput>;
  boxGroupAppFieldId?: InputMaybe<IntOperationFilterInput>;
  boxGroupNumberOfRecords?: InputMaybe<IntOperationFilterInput>;
  boxGroupSortAppField?: InputMaybe<AppFieldFilterInput>;
  boxGroupSortAppFieldId?: InputMaybe<IntOperationFilterInput>;
  boxGroupSortDirectionLookup?: InputMaybe<LookupFilterInput>;
  boxGroupSortDirectionLookupId?: InputMaybe<IntOperationFilterInput>;
  boxHideBoxHeader?: InputMaybe<BooleanOperationFilterInput>;
  boxHideHeaders?: InputMaybe<BooleanOperationFilterInput>;
  boxHideLabels?: InputMaybe<BooleanOperationFilterInput>;
  boxReportExportFormat?: InputMaybe<ReportExportFormatFilterInput>;
  boxReportExportFormatId?: InputMaybe<IntOperationFilterInput>;
  boxReportPreset?: InputMaybe<ReportPresetFilterInput>;
  boxReportPresetId?: InputMaybe<IntOperationFilterInput>;
  boxTotalsHeaderDisplayName?: InputMaybe<StringOperationFilterInput>;
  column?: InputMaybe<IntOperationFilterInput>;
  columnSpan?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  elementTypeLookup?: InputMaybe<LookupFilterInput>;
  elementTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  excelFormula?: InputMaybe<StringOperationFilterInput>;
  fontBold?: InputMaybe<BooleanOperationFilterInput>;
  fontUnderline?: InputMaybe<BooleanOperationFilterInput>;
  foregroundColor?: InputMaybe<StringOperationFilterInput>;
  glanceReportElementId?: InputMaybe<IntOperationFilterInput>;
  glanceReportWorksheet?: InputMaybe<GlanceReportWorksheetFilterInput>;
  glanceReportWorksheetId?: InputMaybe<IntOperationFilterInput>;
  horizontalAlignmentLookup?: InputMaybe<LookupFilterInput>;
  horizontalAlignmentLookupId?: InputMaybe<IntOperationFilterInput>;
  imageFile?: InputMaybe<FileFilterInput>;
  imageFileId?: InputMaybe<IntOperationFilterInput>;
  imageScale?: InputMaybe<FloatOperationFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GlanceReportElementFilterInput>>;
  row?: InputMaybe<IntOperationFilterInput>;
  verticalAlignmentLookup?: InputMaybe<LookupFilterInput>;
  verticalAlignmentLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type GlanceReportFilterInput = {
  and?: InputMaybe<Array<GlanceReportFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  autoReportItems?: InputMaybe<ListFilterInputTypeOfAutoReportItemFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  glanceReportId?: InputMaybe<IntOperationFilterInput>;
  glanceReportWorksheets?: InputMaybe<ListFilterInputTypeOfGlanceReportWorksheetFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GlanceReportFilterInput>>;
};

export type GlanceReportRange = {
  __typename?: 'GlanceReportRange';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endCell?: Maybe<Scalars['String']>;
  excelFormatTypeLookup?: Maybe<Lookup>;
  excelFormatTypeLookupId: Scalars['Int'];
  formatValue?: Maybe<Scalars['String']>;
  glanceReportRangeId: Scalars['Int'];
  glanceReportWorksheet?: Maybe<GlanceReportWorksheet>;
  glanceReportWorksheetId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  startCell?: Maybe<Scalars['String']>;
};

export type GlanceReportRangeFilterInput = {
  and?: InputMaybe<Array<GlanceReportRangeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endCell?: InputMaybe<StringOperationFilterInput>;
  excelFormatTypeLookup?: InputMaybe<LookupFilterInput>;
  excelFormatTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  formatValue?: InputMaybe<StringOperationFilterInput>;
  glanceReportRangeId?: InputMaybe<IntOperationFilterInput>;
  glanceReportWorksheet?: InputMaybe<GlanceReportWorksheetFilterInput>;
  glanceReportWorksheetId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GlanceReportRangeFilterInput>>;
  startCell?: InputMaybe<StringOperationFilterInput>;
};

export type GlanceReportWorksheet = {
  __typename?: 'GlanceReportWorksheet';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  glanceReport?: Maybe<GlanceReport>;
  glanceReportDimensions?: Maybe<Array<Maybe<GlanceReportDimension>>>;
  glanceReportElements?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportId: Scalars['Int'];
  glanceReportRanges?: Maybe<Array<Maybe<GlanceReportRange>>>;
  glanceReportWorksheetId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
};

export type GlanceReportWorksheetFilterInput = {
  and?: InputMaybe<Array<GlanceReportWorksheetFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  glanceReport?: InputMaybe<GlanceReportFilterInput>;
  glanceReportDimensions?: InputMaybe<ListFilterInputTypeOfGlanceReportDimensionFilterInput>;
  glanceReportElements?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportId?: InputMaybe<IntOperationFilterInput>;
  glanceReportRanges?: InputMaybe<ListFilterInputTypeOfGlanceReportRangeFilterInput>;
  glanceReportWorksheetId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GlanceReportWorksheetFilterInput>>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type Holiday = {
  __typename?: 'Holiday';
  date: Scalars['DateTime'];
  holidayId: Scalars['Int'];
  holidayName?: Maybe<Scalars['String']>;
  hours: Scalars['Decimal'];
};

export type Imsdetection = {
  __typename?: 'Imsdetection';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileImportId: Scalars['Int'];
  imsdetectionId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  roviProgramId: Scalars['Int'];
  startDateTime: Scalars['DateTime'];
};

export type ImsdetectionFilterInput = {
  and?: InputMaybe<Array<ImsdetectionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  imsdetectionId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ImsdetectionFilterInput>>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
  startDateTime?: InputMaybe<DateTimeOperationFilterInput>;
};

export type InputFieldMapping = {
  __typename?: 'InputFieldMapping';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  inputFieldMappingId: Scalars['Int'];
  inputFieldMappingScheme?: Maybe<InputFieldMappingScheme>;
  inputFieldMappingSchemeId?: Maybe<Scalars['Int']>;
  inputFieldMappingTypeLookup?: Maybe<Lookup>;
  inputFieldMappingTypeLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lookupValue?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type InputFieldMappingFilterInput = {
  and?: InputMaybe<Array<InputFieldMappingFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  inputFieldMappingId?: InputMaybe<IntOperationFilterInput>;
  inputFieldMappingScheme?: InputMaybe<InputFieldMappingSchemeFilterInput>;
  inputFieldMappingSchemeId?: InputMaybe<IntOperationFilterInput>;
  inputFieldMappingTypeLookup?: InputMaybe<LookupFilterInput>;
  inputFieldMappingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lookupValue?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InputFieldMappingFilterInput>>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type InputFieldMappingScheme = {
  __typename?: 'InputFieldMappingScheme';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  inputFieldMappingSchemeId: Scalars['Int'];
  inputFieldMappings?: Maybe<Array<Maybe<InputFieldMapping>>>;
  inputMappingSchemeTypeLookup?: Maybe<Lookup>;
  inputMappingSchemeTypeLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  script?: Maybe<Script>;
  scriptId?: Maybe<Scalars['Int']>;
};

export type InputFieldMappingSchemeFilterInput = {
  and?: InputMaybe<Array<InputFieldMappingSchemeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  inputFieldMappingSchemeId?: InputMaybe<IntOperationFilterInput>;
  inputFieldMappings?: InputMaybe<ListFilterInputTypeOfInputFieldMappingFilterInput>;
  inputMappingSchemeTypeLookup?: InputMaybe<LookupFilterInput>;
  inputMappingSchemeTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InputFieldMappingSchemeFilterInput>>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
};

export type InsertionOrder = {
  __typename?: 'InsertionOrder';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  insertionOrderContacts?: Maybe<Array<Maybe<InsertionOrderContact>>>;
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  insertionOrderFileReferences?: Maybe<Array<Maybe<InsertionOrderFileReference>>>;
  insertionOrderId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  noGoogleCampaignManager: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  revisionNumber: Scalars['Int'];
  spendDisplayUnitLookup?: Maybe<Lookup>;
  spendDisplayUnitLookupId: Scalars['Int'];
  statusLookup?: Maybe<Lookup>;
  statusLookupId: Scalars['Int'];
  verificationVendor?: Maybe<Vendor>;
  verificationVendorId: Scalars['Int'];
};

export type InsertionOrderContact = {
  __typename?: 'InsertionOrderContact';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId: Scalars['Int'];
  insertionOrder?: Maybe<InsertionOrder>;
  insertionOrderContactId: Scalars['Int'];
  insertionOrderId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type InsertionOrderContactFilterInput = {
  and?: InputMaybe<Array<InsertionOrderContactFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  insertionOrder?: InputMaybe<InsertionOrderFilterInput>;
  insertionOrderContactId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderContactFilterInput>>;
};

export type InsertionOrderContactInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  contactId?: InputMaybe<Scalars['Int']>;
  insertionOrderContactId?: InputMaybe<Scalars['Int']>;
  insertionOrderId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
};

export type InsertionOrderDetail = {
  __typename?: 'InsertionOrderDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costMethodLookup?: Maybe<Lookup>;
  costMethodLookupId: Scalars['Int'];
  costPerLeadDigitalResultType?: Maybe<DigitalResultType>;
  costPerLeadDigitalResultTypeId?: Maybe<Scalars['Int']>;
  costPerUnit: Scalars['Decimal'];
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  crossChannelCampaignId: Scalars['Int'];
  endDate: Scalars['Date'];
  geoDescription?: Maybe<Scalars['String']>;
  insertionOrder?: Maybe<InsertionOrder>;
  insertionOrderDetailId: Scalars['Int'];
  insertionOrderDetailPlacements?: Maybe<Array<Maybe<InsertionOrderDetailPlacement>>>;
  insertionOrderId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  units: Scalars['Int'];
};

export type InsertionOrderDetailFilterInput = {
  and?: InputMaybe<Array<InsertionOrderDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costMethodLookup?: InputMaybe<LookupFilterInput>;
  costMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  costPerLeadDigitalResultType?: InputMaybe<DigitalResultTypeFilterInput>;
  costPerLeadDigitalResultTypeId?: InputMaybe<IntOperationFilterInput>;
  costPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  geoDescription?: InputMaybe<StringOperationFilterInput>;
  insertionOrder?: InputMaybe<InsertionOrderFilterInput>;
  insertionOrderDetailId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderDetailPlacements?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailPlacementFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderDetailFilterInput>>;
  packageName?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  units?: InputMaybe<IntOperationFilterInput>;
};

export type InsertionOrderDetailInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  costMethodLookupId?: InputMaybe<Scalars['Int']>;
  costPerLeadDigitalResultTypeID?: InputMaybe<Scalars['Int']>;
  costPerUnit?: InputMaybe<Scalars['Decimal']>;
  crossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  geoDescription?: InputMaybe<Scalars['String']>;
  insertionOrderDetailId?: InputMaybe<Scalars['Int']>;
  insertionOrderDetailPlacements?: InputMaybe<Array<InputMaybe<InsertionOrderDetailPlacementInput>>>;
  insertionOrderId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  packageName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  totalCost?: InputMaybe<Scalars['Decimal']>;
  units?: InputMaybe<Scalars['Int']>;
};

export type InsertionOrderDetailPlacement = {
  __typename?: 'InsertionOrderDetailPlacement';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  insertionOrderDetail?: Maybe<InsertionOrderDetail>;
  insertionOrderDetailId: Scalars['Int'];
  insertionOrderDetailPlacementId: Scalars['Int'];
  insertionOrderPlacementResults?: Maybe<Array<Maybe<InsertionOrderPlacementResult>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  placementId?: Maybe<Scalars['Long']>;
  placementName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type InsertionOrderDetailPlacementFilterInput = {
  and?: InputMaybe<Array<InsertionOrderDetailPlacementFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  insertionOrderDetail?: InputMaybe<InsertionOrderDetailFilterInput>;
  insertionOrderDetailId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderDetailPlacementId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderPlacementResults?: InputMaybe<ListFilterInputTypeOfInsertionOrderPlacementResultFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderDetailPlacementFilterInput>>;
  placementId?: InputMaybe<LongOperationFilterInput>;
  placementName?: InputMaybe<StringOperationFilterInput>;
  size?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type InsertionOrderDetailPlacementInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  insertionOrderDetailId?: InputMaybe<Scalars['Int']>;
  insertionOrderDetailPlacementId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  placementId?: InputMaybe<Scalars['Long']>;
  placementName?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type InsertionOrderDetail_ForCampaignBudgets = {
  __typename?: 'InsertionOrderDetail_ForCampaignBudgets';
  campaignName?: Maybe<Scalars['String']>;
  clientCommissions?: Maybe<Array<Commission>>;
  clientNetBooked: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costMethodLookup?: Maybe<Lookup>;
  costMethodLookupId: Scalars['Int'];
  costPerLeadDigitalResultType?: Maybe<DigitalResultType>;
  costPerLeadDigitalResultTypeId?: Maybe<Scalars['Int']>;
  costPerUnit: Scalars['Decimal'];
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  crossChannelCampaignId: Scalars['Int'];
  endDate: Scalars['Date'];
  geoDescription?: Maybe<Scalars['String']>;
  insertionOrder?: Maybe<InsertionOrder>;
  insertionOrderDetailId: Scalars['Int'];
  insertionOrderDetailPlacements?: Maybe<Array<Maybe<InsertionOrderDetailPlacement>>>;
  insertionOrderId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletIsProgrammatic: Scalars['Boolean'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  netBooked: Scalars['Decimal'];
  netCommissionRate: Scalars['Decimal'];
  notes?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  programmaticCommissionExists: Scalars['Boolean'];
  startDate: Scalars['Date'];
  units: Scalars['Int'];
};

export type InsertionOrderDetail_ForCampaignBudgetsFilterInput = {
  and?: InputMaybe<Array<InsertionOrderDetail_ForCampaignBudgetsFilterInput>>;
  campaignName?: InputMaybe<StringOperationFilterInput>;
  clientCommissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  clientNetBooked?: InputMaybe<DecimalOperationFilterInput>;
  commissionRate?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costMethodLookup?: InputMaybe<LookupFilterInput>;
  costMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  costPerLeadDigitalResultType?: InputMaybe<DigitalResultTypeFilterInput>;
  costPerLeadDigitalResultTypeId?: InputMaybe<IntOperationFilterInput>;
  costPerUnit?: InputMaybe<DecimalOperationFilterInput>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  geoDescription?: InputMaybe<StringOperationFilterInput>;
  insertionOrder?: InputMaybe<InsertionOrderFilterInput>;
  insertionOrderDetailId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderDetailPlacements?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailPlacementFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletIsProgrammatic?: InputMaybe<BooleanOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  netBooked?: InputMaybe<DecimalOperationFilterInput>;
  netCommissionRate?: InputMaybe<DecimalOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderDetail_ForCampaignBudgetsFilterInput>>;
  packageName?: InputMaybe<StringOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  programmaticCommissionExists?: InputMaybe<BooleanOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  units?: InputMaybe<IntOperationFilterInput>;
};

export type InsertionOrderFileReference = {
  __typename?: 'InsertionOrderFileReference';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  filteReference?: Maybe<FileReference>;
  filteReferenceId: Scalars['Int'];
  insertOrderFileTypeLookup?: Maybe<Lookup>;
  insertOrderFileTypeLookupId: Scalars['Int'];
  insertionOrder?: Maybe<InsertionOrder>;
  insertionOrderFileReferenceId: Scalars['Int'];
  insertionOrderId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  revisionNumber: Scalars['Int'];
};

export type InsertionOrderFileReferenceFilterInput = {
  and?: InputMaybe<Array<InsertionOrderFileReferenceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  filteReference?: InputMaybe<FileReferenceFilterInput>;
  filteReferenceId?: InputMaybe<IntOperationFilterInput>;
  insertOrderFileTypeLookup?: InputMaybe<LookupFilterInput>;
  insertOrderFileTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  insertionOrder?: InputMaybe<InsertionOrderFilterInput>;
  insertionOrderFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderFileReferenceFilterInput>>;
  revisionNumber?: InputMaybe<IntOperationFilterInput>;
};

export type InsertionOrderFileReferenceInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  filteReferenceId?: InputMaybe<Scalars['Int']>;
  insertOrderFileTypeLookupId?: InputMaybe<Scalars['Int']>;
  insertionOrderFileReferenceId?: InputMaybe<Scalars['Int']>;
  insertionOrderId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  revisionNumber?: InputMaybe<Scalars['Int']>;
};

export type InsertionOrderFileReference_ForLookup = {
  __typename?: 'InsertionOrderFileReference_ForLookup';
  azureFileName: Scalars['String'];
  azureFileReferenceURL?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileType: InsertionOrderFileType;
  filteReference?: Maybe<FileReference>;
  filteReferenceId: Scalars['Int'];
  insertOrderFileTypeLookup?: Maybe<Lookup>;
  insertOrderFileTypeLookupId: Scalars['Int'];
  insertionOrder?: Maybe<InsertionOrder>;
  insertionOrderFileReferenceId: Scalars['Int'];
  insertionOrderFileTypeLookupDisplay: Scalars['String'];
  insertionOrderId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  revisionNumber: Scalars['Int'];
};

export type InsertionOrderFileReference_ForLookupFilterInput = {
  and?: InputMaybe<Array<InsertionOrderFileReference_ForLookupFilterInput>>;
  azureFileName?: InputMaybe<StringOperationFilterInput>;
  azureFileReferenceURL?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileType?: InputMaybe<InsertionOrderFileTypeOperationFilterInput>;
  filteReference?: InputMaybe<FileReferenceFilterInput>;
  filteReferenceId?: InputMaybe<IntOperationFilterInput>;
  insertOrderFileTypeLookup?: InputMaybe<LookupFilterInput>;
  insertOrderFileTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  insertionOrder?: InputMaybe<InsertionOrderFilterInput>;
  insertionOrderFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderFileTypeLookupDisplay?: InputMaybe<StringOperationFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderFileReference_ForLookupFilterInput>>;
  revisionNumber?: InputMaybe<IntOperationFilterInput>;
};

export enum InsertionOrderFileType {
  Confirmation = 'CONFIRMATION',
  Original = 'ORIGINAL'
}

export type InsertionOrderFileTypeOperationFilterInput = {
  eq?: InputMaybe<InsertionOrderFileType>;
  in?: InputMaybe<Array<InsertionOrderFileType>>;
  neq?: InputMaybe<InsertionOrderFileType>;
  nin?: InputMaybe<Array<InsertionOrderFileType>>;
};

export type InsertionOrderFilterInput = {
  and?: InputMaybe<Array<InsertionOrderFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  insertionOrderContacts?: InputMaybe<ListFilterInputTypeOfInsertionOrderContactFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  insertionOrderFileReferences?: InputMaybe<ListFilterInputTypeOfInsertionOrderFileReferenceFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  noGoogleCampaignManager?: InputMaybe<BooleanOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderFilterInput>>;
  revisionNumber?: InputMaybe<IntOperationFilterInput>;
  spendDisplayUnitLookup?: InputMaybe<LookupFilterInput>;
  spendDisplayUnitLookupId?: InputMaybe<IntOperationFilterInput>;
  statusLookup?: InputMaybe<LookupFilterInput>;
  statusLookupId?: InputMaybe<IntOperationFilterInput>;
  verificationVendor?: InputMaybe<VendorFilterInput>;
  verificationVendorId?: InputMaybe<IntOperationFilterInput>;
};

export type InsertionOrderForLookup = {
  __typename?: 'InsertionOrderForLookup';
  azureFileName?: Maybe<Scalars['String']>;
  azureFileReferenceURL?: Maybe<Scalars['String']>;
  clientId: Scalars['Int'];
  clientName?: Maybe<Scalars['String']>;
  confirmationDocumentFileReferences?: Maybe<Array<InsertionOrderFileReference_ForLookup>>;
  confirmationStatus: Scalars['String'];
  createAppUserId: Scalars['Int'];
  createByAppUserDescription?: Maybe<Scalars['String']>;
  createUTC: Scalars['DateTime'];
  crossChannelCampaignNames?: Maybe<Scalars['String']>;
  currentConfirmationDocument?: Maybe<InsertionOrderFileReference_ForLookup>;
  endDate: Scalars['Date'];
  hasConfirmation: Scalars['Boolean'];
  insertionOrderDetails?: Maybe<Array<InsertionOrderDetail_ForCampaignBudgets>>;
  insertionOrderId: Scalars['Int'];
  insertionOrderPlacementResults?: Maybe<Array<InsertionOrderPlacementResult_ForLookup>>;
  mediaOutletId: Scalars['Int'];
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaTypesDisplay?: Maybe<Scalars['String']>;
  originalDocumentFileReferences?: Maybe<Array<InsertionOrderFileReference_ForLookup>>;
  packageNames?: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
  productName?: Maybe<Scalars['String']>;
  revisionNumber: Scalars['Int'];
  startDate: Scalars['Date'];
  statusLookupDisplay?: Maybe<Scalars['String']>;
  statusLookupId: Scalars['Int'];
  totalClientNetAmount: Scalars['Decimal'];
  totalNetAmount: Scalars['Decimal'];
};

export type InsertionOrderForLookupFilterInput = {
  and?: InputMaybe<Array<InsertionOrderForLookupFilterInput>>;
  azureFileName?: InputMaybe<StringOperationFilterInput>;
  azureFileReferenceURL?: InputMaybe<StringOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  confirmationDocumentFileReferences?: InputMaybe<ListFilterInputTypeOfInsertionOrderFileReference_ForLookupFilterInput>;
  confirmationStatus?: InputMaybe<StringOperationFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  createUTC?: InputMaybe<DateTimeOperationFilterInput>;
  crossChannelCampaignNames?: InputMaybe<StringOperationFilterInput>;
  currentConfirmationDocument?: InputMaybe<InsertionOrderFileReference_ForLookupFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  hasConfirmation?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetail_ForCampaignBudgetsFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderPlacementResults?: InputMaybe<ListFilterInputTypeOfInsertionOrderPlacementResult_ForLookupFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletName?: InputMaybe<StringOperationFilterInput>;
  mediaTypesDisplay?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderForLookupFilterInput>>;
  originalDocumentFileReferences?: InputMaybe<ListFilterInputTypeOfInsertionOrderFileReference_ForLookupFilterInput>;
  packageNames?: InputMaybe<StringOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
  revisionNumber?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  statusLookupDisplay?: InputMaybe<StringOperationFilterInput>;
  statusLookupId?: InputMaybe<IntOperationFilterInput>;
  totalClientNetAmount?: InputMaybe<DecimalOperationFilterInput>;
  totalNetAmount?: InputMaybe<DecimalOperationFilterInput>;
};

export type InsertionOrderInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  createUTC?: InputMaybe<Scalars['DateTime']>;
  insertionOrderContacts?: InputMaybe<Array<InputMaybe<InsertionOrderContactInput>>>;
  insertionOrderDetails?: InputMaybe<Array<InputMaybe<InsertionOrderDetailInput>>>;
  insertionOrderFileReferences?: InputMaybe<Array<InputMaybe<InsertionOrderFileReferenceInput>>>;
  insertionOrderId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  noGoogleCampaignManager: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  revisionNumber?: InputMaybe<Scalars['Int']>;
  spendDisplayUnitLookupId?: InputMaybe<Scalars['Int']>;
  statusLookupId?: InputMaybe<Scalars['Int']>;
  verificationVendorId?: InputMaybe<Scalars['Int']>;
};

export type InsertionOrderPlacementResult = {
  __typename?: 'InsertionOrderPlacementResult';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalResultType?: Maybe<DigitalResultType>;
  digitalResultTypeId: Scalars['Int'];
  endDate: Scalars['Date'];
  insertionOrderDetailPlacement?: Maybe<InsertionOrderDetailPlacement>;
  insertionOrderDetailPlacementId: Scalars['Int'];
  insertionOrderPlacementResultId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Decimal'];
  startDate: Scalars['Date'];
};

export type InsertionOrderPlacementResultFilterInput = {
  and?: InputMaybe<Array<InsertionOrderPlacementResultFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalResultType?: InputMaybe<DigitalResultTypeFilterInput>;
  digitalResultTypeId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  insertionOrderDetailPlacement?: InputMaybe<InsertionOrderDetailPlacementFilterInput>;
  insertionOrderDetailPlacementId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderPlacementResultId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderPlacementResultFilterInput>>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type InsertionOrderPlacementResultInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  digitalResultTypeId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  insertionOrderDetailPlacementId?: InputMaybe<Scalars['Int']>;
  insertionOrderPlacementResultId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  quantity?: InputMaybe<Scalars['Decimal']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type InsertionOrderPlacementResult_ForLookup = {
  __typename?: 'InsertionOrderPlacementResult_ForLookup';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalResultType?: Maybe<DigitalResultType>;
  digitalResultTypeId: Scalars['Int'];
  endDate: Scalars['Date'];
  insertionOrderDetailId: Scalars['Int'];
  insertionOrderDetailPlacement?: Maybe<InsertionOrderDetailPlacement>;
  insertionOrderDetailPlacementId: Scalars['Int'];
  insertionOrderId: Scalars['Int'];
  insertionOrderPlacementResultId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  packageEndDate?: Maybe<Scalars['Date']>;
  packageName?: Maybe<Scalars['String']>;
  packageStartDate?: Maybe<Scalars['Date']>;
  placementEndDate?: Maybe<Scalars['Date']>;
  placementId?: Maybe<Scalars['Long']>;
  placementName?: Maybe<Scalars['String']>;
  placementStartDate?: Maybe<Scalars['Date']>;
  quantity: Scalars['Decimal'];
  resultTypeName?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type InsertionOrderPlacementResult_ForLookupFilterInput = {
  and?: InputMaybe<Array<InsertionOrderPlacementResult_ForLookupFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalResultType?: InputMaybe<DigitalResultTypeFilterInput>;
  digitalResultTypeId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  insertionOrderDetailId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderDetailPlacement?: InputMaybe<InsertionOrderDetailPlacementFilterInput>;
  insertionOrderDetailPlacementId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderId?: InputMaybe<IntOperationFilterInput>;
  insertionOrderPlacementResultId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InsertionOrderPlacementResult_ForLookupFilterInput>>;
  packageEndDate?: InputMaybe<DateOperationFilterInput>;
  packageName?: InputMaybe<StringOperationFilterInput>;
  packageStartDate?: InputMaybe<DateOperationFilterInput>;
  placementEndDate?: InputMaybe<DateOperationFilterInput>;
  placementId?: InputMaybe<LongOperationFilterInput>;
  placementName?: InputMaybe<StringOperationFilterInput>;
  placementStartDate?: InputMaybe<DateOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  resultTypeName?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type InsertionOrderSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createAppUser?: InputMaybe<AppUserSortInput>;
  createAppUserId?: InputMaybe<SortEnumType>;
  createUtc?: InputMaybe<SortEnumType>;
  insertionOrderId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaOutlet?: InputMaybe<MediaOutletSortInput>;
  mediaOutletId?: InputMaybe<SortEnumType>;
  noGoogleCampaignManager?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  revisionNumber?: InputMaybe<SortEnumType>;
  spendDisplayUnitLookup?: InputMaybe<LookupSortInput>;
  spendDisplayUnitLookupId?: InputMaybe<SortEnumType>;
  statusLookup?: InputMaybe<LookupSortInput>;
  statusLookupId?: InputMaybe<SortEnumType>;
  verificationVendor?: InputMaybe<VendorSortInput>;
  verificationVendorId?: InputMaybe<SortEnumType>;
};

export enum InsertionOrderStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED'
}

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  accountingLineCreditMemoInvoices?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLineInvoices?: Maybe<Array<Maybe<AccountingLine>>>;
  amount?: Maybe<Scalars['Decimal']>;
  amountInLocalCurrency?: Maybe<Scalars['Decimal']>;
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  approvedByAppUser?: Maybe<AppUser>;
  approvedByAppUserId?: Maybe<Scalars['Int']>;
  approvedUtc?: Maybe<Scalars['DateTime']>;
  assignedApproverAppUser?: Maybe<AppUser>;
  assignedApproverAppUserId?: Maybe<Scalars['Int']>;
  axmonth?: Maybe<Scalars['String']>;
  axvendorCode?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  dateDue?: Maybe<Scalars['Date']>;
  datePaid?: Maybe<Scalars['DateTime']>;
  dateReceived?: Maybe<Scalars['Date']>;
  duplicationInvoiceLines?: Maybe<Array<Maybe<DuplicationInvoiceLine>>>;
  fileName?: Maybe<Scalars['String']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  internalComment?: Maybe<Scalars['String']>;
  invoiceFileReference?: Maybe<FileReference>;
  invoiceFileReferenceId?: Maybe<Scalars['Int']>;
  invoiceId: Scalars['Int'];
  invoiceSchedules?: Maybe<Array<Maybe<InvoiceSchedule>>>;
  invoiceServiceInstances?: Maybe<Array<Maybe<InvoiceServiceInstance>>>;
  isClientPaid: Scalars['Boolean'];
  isCreatedByProcessor: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  monthDefinitionLookup?: Maybe<Lookup>;
  monthDefinitionLookupId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  parentInvoice?: Maybe<ParentInvoice>;
  parentInvoiceId?: Maybe<Scalars['Int']>;
  paymentFileReference?: Maybe<FileReference>;
  paymentFileReferenceId?: Maybe<Scalars['Int']>;
  paymentReference?: Maybe<Scalars['String']>;
  skipSalesTax: Scalars['Boolean'];
  vendorInvoiceNumber?: Maybe<Scalars['String']>;
};

export type InvoiceFilterInput = {
  accountingLineCreditMemoInvoices?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  accountingLineInvoices?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  amount?: InputMaybe<DecimalOperationFilterInput>;
  amountInLocalCurrency?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<InvoiceFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  approvedByAppUser?: InputMaybe<AppUserFilterInput>;
  approvedByAppUserId?: InputMaybe<IntOperationFilterInput>;
  approvedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  assignedApproverAppUser?: InputMaybe<AppUserFilterInput>;
  assignedApproverAppUserId?: InputMaybe<IntOperationFilterInput>;
  axmonth?: InputMaybe<StringOperationFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  dateDue?: InputMaybe<DateOperationFilterInput>;
  datePaid?: InputMaybe<DateTimeOperationFilterInput>;
  dateReceived?: InputMaybe<DateOperationFilterInput>;
  duplicationInvoiceLines?: InputMaybe<ListFilterInputTypeOfDuplicationInvoiceLineFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  internalComment?: InputMaybe<StringOperationFilterInput>;
  invoiceFileReference?: InputMaybe<FileReferenceFilterInput>;
  invoiceFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  invoiceSchedules?: InputMaybe<ListFilterInputTypeOfInvoiceScheduleFilterInput>;
  invoiceServiceInstances?: InputMaybe<ListFilterInputTypeOfInvoiceServiceInstanceFilterInput>;
  isClientPaid?: InputMaybe<BooleanOperationFilterInput>;
  isCreatedByProcessor?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  monthDefinitionLookup?: InputMaybe<LookupFilterInput>;
  monthDefinitionLookupId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<InvoiceFilterInput>>;
  parentInvoice?: InputMaybe<ParentInvoiceFilterInput>;
  parentInvoiceId?: InputMaybe<IntOperationFilterInput>;
  paymentFileReference?: InputMaybe<FileReferenceFilterInput>;
  paymentFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  paymentReference?: InputMaybe<StringOperationFilterInput>;
  skipSalesTax?: InputMaybe<BooleanOperationFilterInput>;
  vendorInvoiceNumber?: InputMaybe<StringOperationFilterInput>;
};

export type InvoiceForApproval = {
  __typename?: 'InvoiceForApproval';
  accountingBatchID?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Decimal']>;
  amountInLocalCurrency?: Maybe<Scalars['Decimal']>;
  anyPropertyValuesChanged: Scalars['Boolean'];
  appUser?: Maybe<Scalars['String']>;
  appUserID?: Maybe<Scalars['Int']>;
  approvedByAppUserDescription?: Maybe<Scalars['String']>;
  approvedByAppUserID?: Maybe<Scalars['Int']>;
  approvedUTC?: Maybe<Scalars['DateTime']>;
  approver?: Maybe<Scalars['String']>;
  approverEmail?: Maybe<Scalars['String']>;
  assignedApproverAppUserDescription?: Maybe<Scalars['String']>;
  assignedApproverAppUserID?: Maybe<Scalars['Int']>;
  axMonth?: Maybe<Scalars['String']>;
  axVendorCode?: Maybe<Scalars['String']>;
  azureFileReferenceURL?: Maybe<Scalars['String']>;
  candidateParentInvoices?: Maybe<Array<Maybe<CandidateParentInvoice>>>;
  clientID: Scalars['Int'];
  clientName?: Maybe<Scalars['String']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  creditMemoInvoiceID?: Maybe<Scalars['Int']>;
  creditsOnInvoice?: Maybe<Scalars['Decimal']>;
  currencyCode?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  dateDue?: Maybe<Scalars['DateTime']>;
  datePaid?: Maybe<Scalars['DateTime']>;
  dateReceived: Scalars['DateTime'];
  datoramaBaseKey?: Maybe<Scalars['String']>;
  datoramaKeys?: Maybe<Scalars['String']>;
  digitalAnalystAppUserDescription?: Maybe<Scalars['String']>;
  digitalAnalystEmail?: Maybe<Scalars['String']>;
  discrepancy: Scalars['Decimal'];
  discrepancyAfterCredits: Scalars['Decimal'];
  exchangeRate: Scalars['Decimal'];
  fileName?: Maybe<Scalars['String']>;
  fileReferenceID?: Maybe<Scalars['Int']>;
  hasBeenExported: Scalars['Boolean'];
  hasChanged: Scalars['Boolean'];
  internalComment?: Maybe<Scalars['String']>;
  invoiceFilePath?: Maybe<Scalars['String']>;
  invoiceFileReferenceID?: Maybe<Scalars['Int']>;
  invoiceID?: Maybe<Scalars['Int']>;
  invoiceReceiverEmailAddress?: Maybe<Scalars['String']>;
  isApproved: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isClientPaid: Scalars['Boolean'];
  isCreatedByProcessor: Scalars['Boolean'];
  isWebbed: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaMonth?: Maybe<Scalars['DateTime']>;
  mediaOutletNames?: Maybe<Scalars['String']>;
  mediaOutlets?: Maybe<Scalars['String']>;
  mediaSupervisorAppUserDescription?: Maybe<Scalars['String']>;
  mediaSupervisorAppUserID?: Maybe<Scalars['Int']>;
  mediaSupervisorEmail?: Maybe<Scalars['String']>;
  monthDefinition?: Maybe<MonthDefinition>;
  monthDefinitionDisplay?: Maybe<Scalars['String']>;
  monthDefinitionLookupID?: Maybe<Scalars['Int']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  overrideTotalScheduleCostAdjustments?: Maybe<Scalars['Decimal']>;
  overrideTotalScheduleNet?: Maybe<Scalars['Decimal']>;
  parent?: Maybe<EntityBase>;
  parentInvoice?: Maybe<Scalars['String']>;
  parentInvoiceID?: Maybe<Scalars['Int']>;
  parentInvoiceInvoiceID?: Maybe<Scalars['Int']>;
  paymentFilePath?: Maybe<Scalars['String']>;
  paymentFileReferenceID?: Maybe<Scalars['Int']>;
  paymentReference?: Maybe<Scalars['String']>;
  productNames?: Maybe<Scalars['String']>;
  projectCode?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
  referencesLockedSchedules: Scalars['Boolean'];
  schedules?: Maybe<Array<Maybe<ScheduleForInvoiceApproval>>>;
  siblingInvoices?: Maybe<Scalars['String']>;
  skipSalesTax: Scalars['Boolean'];
  totalDatoramaNetCleared?: Maybe<Scalars['Decimal']>;
  totalScheduleCostAdjustments?: Maybe<Scalars['Decimal']>;
  vendorInvoiceNumber?: Maybe<Scalars['String']>;
  webID?: Maybe<Scalars['Int']>;
};


export type InvoiceForApprovalPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type InvoiceMonthWithUnattachedSchedule = {
  __typename?: 'InvoiceMonthWithUnattachedSchedule';
  anyPropertyValuesChanged: Scalars['Boolean'];
  channelName?: Maybe<Scalars['String']>;
  clientID: Scalars['Int'];
  clientName?: Maybe<Scalars['String']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  datoramaKey?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<InvoiceMonthWithUnattachedScheduleDetail>>>;
  hasChanged: Scalars['Boolean'];
  invoiceIDs?: Maybe<Array<Scalars['Int']>>;
  invoiceNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceNumbersDisplay?: Maybe<Scalars['String']>;
  isChangeTrackingEnabled: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaOutletID: Scalars['Int'];
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaTypeID: Scalars['Int'];
  mediaTypeName?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['DateTime']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  productID: Scalars['Int'];
  productName?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
  scheduleCount: Scalars['Int'];
  scheduleIDs?: Maybe<Array<Scalars['Int']>>;
};


export type InvoiceMonthWithUnattachedSchedulePropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type InvoiceMonthWithUnattachedScheduleDetail = {
  __typename?: 'InvoiceMonthWithUnattachedScheduleDetail';
  anyPropertyValuesChanged: Scalars['Boolean'];
  axProjectCode?: Maybe<Scalars['String']>;
  axVendorCode?: Maybe<Scalars['String']>;
  clientID: Scalars['Int'];
  clientName?: Maybe<Scalars['String']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  datoramaKey?: Maybe<Scalars['String']>;
  hasChanged: Scalars['Boolean'];
  invoiceApprovedUTC?: Maybe<Scalars['DateTime']>;
  invoiceID: Scalars['Int'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaOutletID: Scalars['Int'];
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaTypeID: Scalars['Int'];
  mediaTypeName?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['DateTime']>;
  naturalKey?: Maybe<Scalars['String']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  productID: Scalars['Int'];
  productName?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
  scheduleID: Scalars['Int'];
  vendorInvoiceNumber?: Maybe<Scalars['String']>;
};


export type InvoiceMonthWithUnattachedScheduleDetailPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type InvoiceSchedule = {
  __typename?: 'InvoiceSchedule';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['Int'];
  invoiceScheduleId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  schedule?: Maybe<Schedule>;
  scheduleId: Scalars['Int'];
};

export type InvoiceScheduleFilterInput = {
  and?: InputMaybe<Array<InvoiceScheduleFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  invoice?: InputMaybe<InvoiceFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  invoiceScheduleId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InvoiceScheduleFilterInput>>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type InvoiceServiceInstance = {
  __typename?: 'InvoiceServiceInstance';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['Int'];
  invoiceServiceInstanceId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  recordId: Scalars['Int'];
  serviceId: Scalars['Int'];
};

export type InvoiceServiceInstanceFilterInput = {
  and?: InputMaybe<Array<InvoiceServiceInstanceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  invoice?: InputMaybe<InvoiceFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  invoiceServiceInstanceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InvoiceServiceInstanceFilterInput>>;
  recordId?: InputMaybe<IntOperationFilterInput>;
  serviceId?: InputMaybe<IntOperationFilterInput>;
};

export type IssueDateInfo = {
  __typename?: 'IssueDateInfo';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDay?: Maybe<Scalars['Int']>;
  endMonth?: Maybe<Scalars['Int']>;
  issueDateId: Scalars['Int'];
  issueDateLookup?: Maybe<Lookup>;
  issueDateLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  startDay?: Maybe<Scalars['Int']>;
  startMonth?: Maybe<Scalars['Int']>;
};

export type IssueDateInfoFilterInput = {
  and?: InputMaybe<Array<IssueDateInfoFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endDay?: InputMaybe<IntOperationFilterInput>;
  endMonth?: InputMaybe<IntOperationFilterInput>;
  issueDateId?: InputMaybe<IntOperationFilterInput>;
  issueDateLookup?: InputMaybe<LookupFilterInput>;
  issueDateLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<IssueDateInfoFilterInput>>;
  startDay?: InputMaybe<IntOperationFilterInput>;
  startMonth?: InputMaybe<IntOperationFilterInput>;
};

export type Job = {
  __typename?: 'Job';
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  gareports?: Maybe<Array<Maybe<Gareport>>>;
  jobAppUsers?: Maybe<Array<Maybe<JobAppUser>>>;
  jobDependencyJobDependents?: Maybe<Array<Maybe<JobDependency>>>;
  jobDependencyJobs?: Maybe<Array<Maybe<JobDependency>>>;
  jobId: Scalars['Int'];
  jobInstances?: Maybe<Array<Maybe<JobInstance>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notifyIfNotStartedWithin?: Maybe<Scalars['TimeSpan']>;
  processor?: Maybe<Processor>;
  processorId: Scalars['Int'];
};

export type JobAppUser = {
  __typename?: 'JobAppUser';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  emails?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Job>;
  jobAppUserId: Scalars['Int'];
  jobId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  trackerAlerts?: Maybe<Scalars['Boolean']>;
};

export type JobAppUserFilterInput = {
  and?: InputMaybe<Array<JobAppUserFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  emails?: InputMaybe<BooleanOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobAppUserId?: InputMaybe<IntOperationFilterInput>;
  jobId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<JobAppUserFilterInput>>;
  trackerAlerts?: InputMaybe<BooleanOperationFilterInput>;
};

export type JobDependency = {
  __typename?: 'JobDependency';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dateOffset: Scalars['Int'];
  job?: Maybe<Job>;
  jobDependencyId: Scalars['Int'];
  jobDependent?: Maybe<Job>;
  jobDependentId: Scalars['Int'];
  jobId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type JobDependencyFilterInput = {
  and?: InputMaybe<Array<JobDependencyFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dateOffset?: InputMaybe<IntOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobDependencyId?: InputMaybe<IntOperationFilterInput>;
  jobDependent?: InputMaybe<JobFilterInput>;
  jobDependentId?: InputMaybe<IntOperationFilterInput>;
  jobId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<JobDependencyFilterInput>>;
};

export type JobFilterInput = {
  and?: InputMaybe<Array<JobFilterInput>>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  gareports?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  jobAppUsers?: InputMaybe<ListFilterInputTypeOfJobAppUserFilterInput>;
  jobDependencyJobDependents?: InputMaybe<ListFilterInputTypeOfJobDependencyFilterInput>;
  jobDependencyJobs?: InputMaybe<ListFilterInputTypeOfJobDependencyFilterInput>;
  jobId?: InputMaybe<IntOperationFilterInput>;
  jobInstances?: InputMaybe<ListFilterInputTypeOfJobInstanceFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notifyIfNotStartedWithin?: InputMaybe<TimeSpanOperationFilterInput>;
  or?: InputMaybe<Array<JobFilterInput>>;
  processor?: InputMaybe<ProcessorFilterInput>;
  processorId?: InputMaybe<IntOperationFilterInput>;
};

export type JobInstance = {
  __typename?: 'JobInstance';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endUtc?: Maybe<Scalars['DateTime']>;
  fileDate?: Maybe<Scalars['Date']>;
  fileImport?: Maybe<FileImport>;
  fileImportId?: Maybe<Scalars['Int']>;
  job?: Maybe<Job>;
  jobId: Scalars['Int'];
  jobInstanceId: Scalars['Int'];
  jobInstanceLogs?: Maybe<Array<Maybe<JobInstanceLog>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  records: Scalars['Int'];
  startUtc: Scalars['DateTime'];
  successRecords: Scalars['Int'];
};

export type JobInstanceDetail = {
  __typename?: 'JobInstanceDetail';
  anyPropertyValuesChanged: Scalars['Boolean'];
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  jobInstanceDetailID: Scalars['Int'];
  jobInstanceDetailTypeLookupDisplay?: Maybe<Scalars['String']>;
  jobInstanceDetailTypeLookupID: Scalars['Int'];
  jobInstanceID: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  propertyValueHasChanged: Scalars['Boolean'];
};


export type JobInstanceDetailPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type JobInstanceFilterInput = {
  and?: InputMaybe<Array<JobInstanceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endUtc?: InputMaybe<DateTimeOperationFilterInput>;
  fileDate?: InputMaybe<DateOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobId?: InputMaybe<IntOperationFilterInput>;
  jobInstanceId?: InputMaybe<IntOperationFilterInput>;
  jobInstanceLogs?: InputMaybe<ListFilterInputTypeOfJobInstanceLogFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<JobInstanceFilterInput>>;
  records?: InputMaybe<IntOperationFilterInput>;
  startUtc?: InputMaybe<DateTimeOperationFilterInput>;
  successRecords?: InputMaybe<IntOperationFilterInput>;
};

export type JobInstanceLog = {
  __typename?: 'JobInstanceLog';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dateTime: Scalars['DateTime'];
  entry?: Maybe<Scalars['String']>;
  jobInstance?: Maybe<JobInstance>;
  jobInstanceId: Scalars['Int'];
  jobInstanceLogId: Scalars['Int'];
  jobInstanceLogStatusLookup?: Maybe<Lookup>;
  jobInstanceLogStatusLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  percentageComplete?: Maybe<Scalars['Decimal']>;
};

export type JobInstanceLogFilterInput = {
  and?: InputMaybe<Array<JobInstanceLogFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  entry?: InputMaybe<StringOperationFilterInput>;
  jobInstance?: InputMaybe<JobInstanceFilterInput>;
  jobInstanceId?: InputMaybe<IntOperationFilterInput>;
  jobInstanceLogId?: InputMaybe<IntOperationFilterInput>;
  jobInstanceLogStatusLookup?: InputMaybe<LookupFilterInput>;
  jobInstanceLogStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<JobInstanceLogFilterInput>>;
  percentageComplete?: InputMaybe<DecimalOperationFilterInput>;
};

export type JobInstanceLog_ForDisplay = {
  __typename?: 'JobInstanceLog_ForDisplay';
  dateTime: Scalars['DateTime'];
  entry?: Maybe<Scalars['String']>;
  jobInstanceLogId: Scalars['Int'];
  jobInstanceLogStatusLookupId: Scalars['Int'];
  percentageComplete?: Maybe<Scalars['Decimal']>;
  sanitizedPercentComplete?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['String']>;
};

export type JobInstance_ForDisplay = {
  __typename?: 'JobInstance_ForDisplay';
  clientName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endUtc?: Maybe<Scalars['DateTime']>;
  fileDate?: Maybe<Scalars['Date']>;
  fileImport?: Maybe<FileImport>;
  fileImportId?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId: Scalars['Int'];
  jobInstanceId: Scalars['Int'];
  jobInstanceLogs?: Maybe<Array<Maybe<JobInstanceLog>>>;
  jobName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  latestStatus?: Maybe<Scalars['String']>;
  latestStatusId?: Maybe<Scalars['Int']>;
  records: Scalars['Int'];
  startUtc: Scalars['DateTime'];
  steps: Array<JobInstanceLog_ForDisplay>;
  successRecords: Scalars['Int'];
};

export type JobSortInput = {
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  jobId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notifyIfNotStartedWithin?: InputMaybe<SortEnumType>;
  processor?: InputMaybe<ProcessorSortInput>;
  processorId?: InputMaybe<SortEnumType>;
};

export type KeyValuePairOfStringAndObject = {
  __typename?: 'KeyValuePairOfStringAndObject';
  key: Scalars['String'];
};

export type LeadBillingLock = {
  __typename?: 'LeadBillingLock';
  billedByAppUser?: Maybe<AppUser>;
  billedByAppUserId?: Maybe<Scalars['Int']>;
  billingFileReference?: Maybe<FileReference>;
  billingFileReferenceId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  easternTimeEndUtc: Scalars['DateTime'];
  easternTimeStartUtc: Scalars['DateTime'];
  isLocked: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingLockId: Scalars['Int'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  weekOf: Scalars['Date'];
};

export type LeadBillingLockFilterInput = {
  and?: InputMaybe<Array<LeadBillingLockFilterInput>>;
  billedByAppUser?: InputMaybe<AppUserFilterInput>;
  billedByAppUserId?: InputMaybe<IntOperationFilterInput>;
  billingFileReference?: InputMaybe<FileReferenceFilterInput>;
  billingFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  easternTimeEndUtc?: InputMaybe<DateTimeOperationFilterInput>;
  easternTimeStartUtc?: InputMaybe<DateTimeOperationFilterInput>;
  isLocked?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingLockId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LeadBillingLockFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  weekOf?: InputMaybe<DateOperationFilterInput>;
};

export enum LedgerJournalAcType {
  Bank = 'BANK',
  Cust = 'CUST',
  EmployeeRu = 'EMPLOYEE_RU',
  FixedAssets = 'FIXED_ASSETS',
  FixedAssetsRu = 'FIXED_ASSETS_RU',
  Ledger = 'LEDGER',
  Project = 'PROJECT',
  RCash = 'R_CASH',
  RDeferrals = 'R_DEFERRALS',
  Vend = 'VEND'
}

export type LedgerJournalLine = {
  __typename?: 'LedgerJournalLine';
  accountDisplayValue: Scalars['String'];
  accountType: LedgerJournalAcType;
  cashDiscount: Scalars['String'];
  cashDiscountAmount: Scalars['Decimal'];
  cashDiscountDate: Scalars['DateTime'];
  chineseVoucher: Scalars['String'];
  chineseVoucherType: Scalars['String'];
  company: Scalars['String'];
  creditAmount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  debitAmount: Scalars['Decimal'];
  defaultDimensionDisplayValue: Scalars['String'];
  discountPercentage: Scalars['Decimal'];
  document: Scalars['String'];
  documentDate: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  exchRate: Scalars['Decimal'];
  exchRateSecond: Scalars['Decimal'];
  invoice: Scalars['String'];
  isWithholdingCalculationEnabled: NoYes;
  itemSalesTaxGroup: Scalars['String'];
  itemWithholdingTaxGroupCode: Scalars['String'];
  journalBatchNumber: Scalars['String'];
  lineNumber: Scalars['Int'];
  offsetAccountDisplayValue: Scalars['String'];
  offsetAccountType: LedgerJournalAcType;
  offsetCompany: Scalars['String'];
  offsetDefaultDimensionDisplayValue: Scalars['String'];
  offsetText: Scalars['String'];
  paymentId: Scalars['String'];
  paymentMethod: Scalars['String'];
  paymentReference: Scalars['String'];
  postingProfile: Scalars['String'];
  quantity: Scalars['Decimal'];
  reportingCurrencyExchRate: Scalars['Decimal'];
  reportingCurrencyExchRateSecondary: Scalars['Decimal'];
  reverseDate: Scalars['DateTime'];
  reverseEntry: NoYes;
  salesTaxCode: Scalars['String'];
  salesTaxGroup: Scalars['String'];
  taxExemptNumber: Scalars['String'];
  text: Scalars['String'];
  transDate: Scalars['DateTime'];
  voucher: Scalars['String'];
};

export type ListByteOperationFilterInput = {
  all?: InputMaybe<ByteOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ByteOperationFilterInput>;
  some?: InputMaybe<ByteOperationFilterInput>;
};

export type ListFilterInputTypeOfAccountingBatchFilterInput = {
  all?: InputMaybe<AccountingBatchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountingBatchFilterInput>;
  some?: InputMaybe<AccountingBatchFilterInput>;
};

export type ListFilterInputTypeOfAccountingBatchLineLinkFilterInput = {
  all?: InputMaybe<AccountingBatchLineLinkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountingBatchLineLinkFilterInput>;
  some?: InputMaybe<AccountingBatchLineLinkFilterInput>;
};

export type ListFilterInputTypeOfAccountingLineFilterInput = {
  all?: InputMaybe<AccountingLineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountingLineFilterInput>;
  some?: InputMaybe<AccountingLineFilterInput>;
};

export type ListFilterInputTypeOfAccountingLineNonPrepayScheduleFilterInput = {
  all?: InputMaybe<AccountingLineNonPrepayScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountingLineNonPrepayScheduleFilterInput>;
  some?: InputMaybe<AccountingLineNonPrepayScheduleFilterInput>;
};

export type ListFilterInputTypeOfAccountingLockFilterInput = {
  all?: InputMaybe<AccountingLockFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountingLockFilterInput>;
  some?: InputMaybe<AccountingLockFilterInput>;
};

export type ListFilterInputTypeOfAdCampaignFilterInput = {
  all?: InputMaybe<AdCampaignFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdCampaignFilterInput>;
  some?: InputMaybe<AdCampaignFilterInput>;
};

export type ListFilterInputTypeOfAdFilterInput = {
  all?: InputMaybe<AdFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdFilterInput>;
  some?: InputMaybe<AdFilterInput>;
};

export type ListFilterInputTypeOfAdInstanceDetailFilterInput = {
  all?: InputMaybe<AdInstanceDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdInstanceDetailFilterInput>;
  some?: InputMaybe<AdInstanceDetailFilterInput>;
};

export type ListFilterInputTypeOfAdInstanceFilterInput = {
  all?: InputMaybe<AdInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdInstanceFilterInput>;
  some?: InputMaybe<AdInstanceFilterInput>;
};

export type ListFilterInputTypeOfAdInstanceImportFileImportFilterInput = {
  all?: InputMaybe<AdInstanceImportFileImportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdInstanceImportFileImportFilterInput>;
  some?: InputMaybe<AdInstanceImportFileImportFilterInput>;
};

export type ListFilterInputTypeOfAdInstanceImportFilterInput = {
  all?: InputMaybe<AdInstanceImportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdInstanceImportFilterInput>;
  some?: InputMaybe<AdInstanceImportFilterInput>;
};

export type ListFilterInputTypeOfAdInstanceImportTargetDemoFilterInput = {
  all?: InputMaybe<AdInstanceImportTargetDemoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdInstanceImportTargetDemoFilterInput>;
  some?: InputMaybe<AdInstanceImportTargetDemoFilterInput>;
};

export type ListFilterInputTypeOfAdUniqueIsciFilterInput = {
  all?: InputMaybe<AdUniqueIsciFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdUniqueIsciFilterInput>;
  some?: InputMaybe<AdUniqueIsciFilterInput>;
};

export type ListFilterInputTypeOfAliasFilterInput = {
  all?: InputMaybe<AliasFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AliasFilterInput>;
  some?: InputMaybe<AliasFilterInput>;
};

export type ListFilterInputTypeOfAliasTypeFilterInput = {
  all?: InputMaybe<AliasTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AliasTypeFilterInput>;
  some?: InputMaybe<AliasTypeFilterInput>;
};

export type ListFilterInputTypeOfAppFieldFilterInput = {
  all?: InputMaybe<AppFieldFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppFieldFilterInput>;
  some?: InputMaybe<AppFieldFilterInput>;
};

export type ListFilterInputTypeOfAppFilterFilterInput = {
  all?: InputMaybe<AppFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppFilterFilterInput>;
  some?: InputMaybe<AppFilterFilterInput>;
};

export type ListFilterInputTypeOfAppFilterOperatorFilterInput = {
  all?: InputMaybe<AppFilterOperatorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppFilterOperatorFilterInput>;
  some?: InputMaybe<AppFilterOperatorFilterInput>;
};

export type ListFilterInputTypeOfAppGridColumnUserSettingFilterInput = {
  all?: InputMaybe<AppGridColumnUserSettingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppGridColumnUserSettingFilterInput>;
  some?: InputMaybe<AppGridColumnUserSettingFilterInput>;
};

export type ListFilterInputTypeOfAppLocationFilterInput = {
  all?: InputMaybe<AppLocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppLocationFilterInput>;
  some?: InputMaybe<AppLocationFilterInput>;
};

export type ListFilterInputTypeOfAppLogEntryDetailFilterInput = {
  all?: InputMaybe<AppLogEntryDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppLogEntryDetailFilterInput>;
  some?: InputMaybe<AppLogEntryDetailFilterInput>;
};

export type ListFilterInputTypeOfAppLogEntryFilterInput = {
  all?: InputMaybe<AppLogEntryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppLogEntryFilterInput>;
  some?: InputMaybe<AppLogEntryFilterInput>;
};

export type ListFilterInputTypeOfAppQueryLogEntryFilterInput = {
  all?: InputMaybe<AppQueryLogEntryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppQueryLogEntryFilterInput>;
  some?: InputMaybe<AppQueryLogEntryFilterInput>;
};

export type ListFilterInputTypeOfAppRibbonButtonFilterInput = {
  all?: InputMaybe<AppRibbonButtonFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppRibbonButtonFilterInput>;
  some?: InputMaybe<AppRibbonButtonFilterInput>;
};

export type ListFilterInputTypeOfAppRibbonGroupFilterInput = {
  all?: InputMaybe<AppRibbonGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppRibbonGroupFilterInput>;
  some?: InputMaybe<AppRibbonGroupFilterInput>;
};

export type ListFilterInputTypeOfAppRibbonTabFilterInput = {
  all?: InputMaybe<AppRibbonTabFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppRibbonTabFilterInput>;
  some?: InputMaybe<AppRibbonTabFilterInput>;
};

export type ListFilterInputTypeOfAppScreenFilterInput = {
  all?: InputMaybe<AppScreenFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppScreenFilterInput>;
  some?: InputMaybe<AppScreenFilterInput>;
};

export type ListFilterInputTypeOfAppUserClientFilterInput = {
  all?: InputMaybe<AppUserClientFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserClientFilterInput>;
  some?: InputMaybe<AppUserClientFilterInput>;
};

export type ListFilterInputTypeOfAppUserClient_ForMaintenanceFilterInput = {
  all?: InputMaybe<AppUserClient_ForMaintenanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserClient_ForMaintenanceFilterInput>;
  some?: InputMaybe<AppUserClient_ForMaintenanceFilterInput>;
};

export type ListFilterInputTypeOfAppUserCompanyFilterInput = {
  all?: InputMaybe<AppUserCompanyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserCompanyFilterInput>;
  some?: InputMaybe<AppUserCompanyFilterInput>;
};

export type ListFilterInputTypeOfAppUserFavoriteReportPresetFilterInput = {
  all?: InputMaybe<AppUserFavoriteReportPresetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserFavoriteReportPresetFilterInput>;
  some?: InputMaybe<AppUserFavoriteReportPresetFilterInput>;
};

export type ListFilterInputTypeOfAppUserFilterInput = {
  all?: InputMaybe<AppUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserFilterInput>;
  some?: InputMaybe<AppUserFilterInput>;
};

export type ListFilterInputTypeOfAppUserReportPresetFilterInput = {
  all?: InputMaybe<AppUserReportPresetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserReportPresetFilterInput>;
  some?: InputMaybe<AppUserReportPresetFilterInput>;
};

export type ListFilterInputTypeOfAppUserRibbonShortcutFilterInput = {
  all?: InputMaybe<AppUserRibbonShortcutFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserRibbonShortcutFilterInput>;
  some?: InputMaybe<AppUserRibbonShortcutFilterInput>;
};

export type ListFilterInputTypeOfAppUserRoleFilterInput = {
  all?: InputMaybe<AppUserRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserRoleFilterInput>;
  some?: InputMaybe<AppUserRoleFilterInput>;
};

export type ListFilterInputTypeOfAppUserSettingFilterInput = {
  all?: InputMaybe<AppUserSettingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppUserSettingFilterInput>;
  some?: InputMaybe<AppUserSettingFilterInput>;
};

export type ListFilterInputTypeOfAppViewFieldFilterInput = {
  all?: InputMaybe<AppViewFieldFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppViewFieldFilterInput>;
  some?: InputMaybe<AppViewFieldFilterInput>;
};

export type ListFilterInputTypeOfAppViewFilterInput = {
  all?: InputMaybe<AppViewFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppViewFilterInput>;
  some?: InputMaybe<AppViewFilterInput>;
};

export type ListFilterInputTypeOfAppViewFilterRecordFilterInput = {
  all?: InputMaybe<AppViewFilterRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppViewFilterRecordFilterInput>;
  some?: InputMaybe<AppViewFilterRecordFilterInput>;
};

export type ListFilterInputTypeOfAudienceFilterInput = {
  all?: InputMaybe<AudienceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AudienceFilterInput>;
  some?: InputMaybe<AudienceFilterInput>;
};

export type ListFilterInputTypeOfAutoReportFilterInput = {
  all?: InputMaybe<AutoReportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoReportFilterInput>;
  some?: InputMaybe<AutoReportFilterInput>;
};

export type ListFilterInputTypeOfAutoReportItemFilterInput = {
  all?: InputMaybe<AutoReportItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoReportItemFilterInput>;
  some?: InputMaybe<AutoReportItemFilterInput>;
};

export type ListFilterInputTypeOfAutoScheduleFilterInput = {
  all?: InputMaybe<AutoScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoScheduleFilterInput>;
  some?: InputMaybe<AutoScheduleFilterInput>;
};

export type ListFilterInputTypeOfAutoScheduleTrafficDetailFilterInput = {
  all?: InputMaybe<AutoScheduleTrafficDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoScheduleTrafficDetailFilterInput>;
  some?: InputMaybe<AutoScheduleTrafficDetailFilterInput>;
};

export type ListFilterInputTypeOfAutoScheduleTrafficFilterInput = {
  all?: InputMaybe<AutoScheduleTrafficFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoScheduleTrafficFilterInput>;
  some?: InputMaybe<AutoScheduleTrafficFilterInput>;
};

export type ListFilterInputTypeOfAutoScheduleTrafficMediaChangeFilterInput = {
  all?: InputMaybe<AutoScheduleTrafficMediaChangeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoScheduleTrafficMediaChangeFilterInput>;
  some?: InputMaybe<AutoScheduleTrafficMediaChangeFilterInput>;
};

export type ListFilterInputTypeOfAutoSourceFilterInput = {
  all?: InputMaybe<AutoSourceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoSourceFilterInput>;
  some?: InputMaybe<AutoSourceFilterInput>;
};

export type ListFilterInputTypeOfAutoSourceResultFilterInput = {
  all?: InputMaybe<AutoSourceResultFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutoSourceResultFilterInput>;
  some?: InputMaybe<AutoSourceResultFilterInput>;
};

export type ListFilterInputTypeOfAutomatedFileProcessingFilterInput = {
  all?: InputMaybe<AutomatedFileProcessingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AutomatedFileProcessingFilterInput>;
  some?: InputMaybe<AutomatedFileProcessingFilterInput>;
};

export type ListFilterInputTypeOfAvailProposalDetailAttributeFilterInput = {
  all?: InputMaybe<AvailProposalDetailAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AvailProposalDetailAttributeFilterInput>;
  some?: InputMaybe<AvailProposalDetailAttributeFilterInput>;
};

export type ListFilterInputTypeOfAvailProposalDetailFilterInput = {
  all?: InputMaybe<AvailProposalDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AvailProposalDetailFilterInput>;
  some?: InputMaybe<AvailProposalDetailFilterInput>;
};

export type ListFilterInputTypeOfAvailProposalFilterInput = {
  all?: InputMaybe<AvailProposalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AvailProposalFilterInput>;
  some?: InputMaybe<AvailProposalFilterInput>;
};

export type ListFilterInputTypeOfAverageCpmByPublisherByChannelFilterInput = {
  all?: InputMaybe<AverageCpmByPublisherByChannelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AverageCpmByPublisherByChannelFilterInput>;
  some?: InputMaybe<AverageCpmByPublisherByChannelFilterInput>;
};

export type ListFilterInputTypeOfBookingFilterInput = {
  all?: InputMaybe<BookingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BookingFilterInput>;
  some?: InputMaybe<BookingFilterInput>;
};

export type ListFilterInputTypeOfBookingOrderFilterInput = {
  all?: InputMaybe<BookingOrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BookingOrderFilterInput>;
  some?: InputMaybe<BookingOrderFilterInput>;
};

export type ListFilterInputTypeOfBrokerMediaTypeFilterInput = {
  all?: InputMaybe<BrokerMediaTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BrokerMediaTypeFilterInput>;
  some?: InputMaybe<BrokerMediaTypeFilterInput>;
};

export type ListFilterInputTypeOfBudgetAllocationFeeFilterInput = {
  all?: InputMaybe<BudgetAllocationFeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetAllocationFeeFilterInput>;
  some?: InputMaybe<BudgetAllocationFeeFilterInput>;
};

export type ListFilterInputTypeOfBudgetAllocationFilterInput = {
  all?: InputMaybe<BudgetAllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetAllocationFilterInput>;
  some?: InputMaybe<BudgetAllocationFilterInput>;
};

export type ListFilterInputTypeOfBudgetAllocationFlightFilterInput = {
  all?: InputMaybe<BudgetAllocationFlightFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetAllocationFlightFilterInput>;
  some?: InputMaybe<BudgetAllocationFlightFilterInput>;
};

export type ListFilterInputTypeOfBudgetAllocationModelFilterInput = {
  all?: InputMaybe<BudgetAllocationModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetAllocationModelFilterInput>;
  some?: InputMaybe<BudgetAllocationModelFilterInput>;
};

export type ListFilterInputTypeOfBudgetAllocationTagFilterInput = {
  all?: InputMaybe<BudgetAllocationTagFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetAllocationTagFilterInput>;
  some?: InputMaybe<BudgetAllocationTagFilterInput>;
};

export type ListFilterInputTypeOfBudgetBuyerSplitFilterInput = {
  all?: InputMaybe<BudgetBuyerSplitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetBuyerSplitFilterInput>;
  some?: InputMaybe<BudgetBuyerSplitFilterInput>;
};

export type ListFilterInputTypeOfBudgetFilterInput = {
  all?: InputMaybe<BudgetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetFilterInput>;
  some?: InputMaybe<BudgetFilterInput>;
};

export type ListFilterInputTypeOfBudgetRegionTypeSplitFilterInput = {
  all?: InputMaybe<BudgetRegionTypeSplitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BudgetRegionTypeSplitFilterInput>;
  some?: InputMaybe<BudgetRegionTypeSplitFilterInput>;
};

export type ListFilterInputTypeOfCampaignCrossChannelCampaignFilterInput = {
  all?: InputMaybe<CampaignCrossChannelCampaignFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignCrossChannelCampaignFilterInput>;
  some?: InputMaybe<CampaignCrossChannelCampaignFilterInput>;
};

export type ListFilterInputTypeOfCampaignFilterInput = {
  all?: InputMaybe<CampaignFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignFilterInput>;
  some?: InputMaybe<CampaignFilterInput>;
};

export type ListFilterInputTypeOfCampaignGroupFilterInput = {
  all?: InputMaybe<CampaignGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignGroupFilterInput>;
  some?: InputMaybe<CampaignGroupFilterInput>;
};

export type ListFilterInputTypeOfCampaignLocalBudgetAssignmentDetailFilterInput = {
  all?: InputMaybe<CampaignLocalBudgetAssignmentDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignLocalBudgetAssignmentDetailFilterInput>;
  some?: InputMaybe<CampaignLocalBudgetAssignmentDetailFilterInput>;
};

export type ListFilterInputTypeOfCampaignLocalBudgetAssignmentFilterInput = {
  all?: InputMaybe<CampaignLocalBudgetAssignmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignLocalBudgetAssignmentFilterInput>;
  some?: InputMaybe<CampaignLocalBudgetAssignmentFilterInput>;
};

export type ListFilterInputTypeOfCampaignMediaOutletFilterInput = {
  all?: InputMaybe<CampaignMediaOutletFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignMediaOutletFilterInput>;
  some?: InputMaybe<CampaignMediaOutletFilterInput>;
};

export type ListFilterInputTypeOfCampaignProjectCodeFilterInput = {
  all?: InputMaybe<CampaignProjectCodeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignProjectCodeFilterInput>;
  some?: InputMaybe<CampaignProjectCodeFilterInput>;
};

export type ListFilterInputTypeOfCampaignVersionFilterInput = {
  all?: InputMaybe<CampaignVersionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CampaignVersionFilterInput>;
  some?: InputMaybe<CampaignVersionFilterInput>;
};

export type ListFilterInputTypeOfClientApprovalFilterInput = {
  all?: InputMaybe<ClientApprovalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientApprovalFilterInput>;
  some?: InputMaybe<ClientApprovalFilterInput>;
};

export type ListFilterInputTypeOfClientAxclientCodeFilterInput = {
  all?: InputMaybe<ClientAxclientCodeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientAxclientCodeFilterInput>;
  some?: InputMaybe<ClientAxclientCodeFilterInput>;
};

export type ListFilterInputTypeOfClientContactFilterInput = {
  all?: InputMaybe<ClientContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientContactFilterInput>;
  some?: InputMaybe<ClientContactFilterInput>;
};

export type ListFilterInputTypeOfClientDatoramaKeyFilterInput = {
  all?: InputMaybe<ClientDatoramaKeyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientDatoramaKeyFilterInput>;
  some?: InputMaybe<ClientDatoramaKeyFilterInput>;
};

export type ListFilterInputTypeOfClientDatoramaKey_ForMaintenanceFilterInput = {
  all?: InputMaybe<ClientDatoramaKey_ForMaintenanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientDatoramaKey_ForMaintenanceFilterInput>;
  some?: InputMaybe<ClientDatoramaKey_ForMaintenanceFilterInput>;
};

export type ListFilterInputTypeOfClientFileReferenceFilterInput = {
  all?: InputMaybe<ClientFileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientFileReferenceFilterInput>;
  some?: InputMaybe<ClientFileReferenceFilterInput>;
};

export type ListFilterInputTypeOfClientFilterInput = {
  all?: InputMaybe<ClientFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientFilterInput>;
  some?: InputMaybe<ClientFilterInput>;
};

export type ListFilterInputTypeOfClientLookupAliasFilterInput = {
  all?: InputMaybe<ClientLookupAliasFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientLookupAliasFilterInput>;
  some?: InputMaybe<ClientLookupAliasFilterInput>;
};

export type ListFilterInputTypeOfClientMediaOutletFilterInput = {
  all?: InputMaybe<ClientMediaOutletFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientMediaOutletFilterInput>;
  some?: InputMaybe<ClientMediaOutletFilterInput>;
};

export type ListFilterInputTypeOfClientMonthDefinitionFilterInput = {
  all?: InputMaybe<ClientMonthDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientMonthDefinitionFilterInput>;
  some?: InputMaybe<ClientMonthDefinitionFilterInput>;
};

export type ListFilterInputTypeOfClientRoleFunctionalRoleFilterInput = {
  all?: InputMaybe<ClientRoleFunctionalRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientRoleFunctionalRoleFilterInput>;
  some?: InputMaybe<ClientRoleFunctionalRoleFilterInput>;
};

export type ListFilterInputTypeOfClientRolePermissionFilterInput = {
  all?: InputMaybe<ClientRolePermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientRolePermissionFilterInput>;
  some?: InputMaybe<ClientRolePermissionFilterInput>;
};

export type ListFilterInputTypeOfClientVendorServiceMarkupFilterInput = {
  all?: InputMaybe<ClientVendorServiceMarkupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ClientVendorServiceMarkupFilterInput>;
  some?: InputMaybe<ClientVendorServiceMarkupFilterInput>;
};

export type ListFilterInputTypeOfCommissionFilterInput = {
  all?: InputMaybe<CommissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CommissionFilterInput>;
  some?: InputMaybe<CommissionFilterInput>;
};

export type ListFilterInputTypeOfCommissionSlidingScaleDetailFilterInput = {
  all?: InputMaybe<CommissionSlidingScaleDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CommissionSlidingScaleDetailFilterInput>;
  some?: InputMaybe<CommissionSlidingScaleDetailFilterInput>;
};

export type ListFilterInputTypeOfCommissionSlidingScaleFilterInput = {
  all?: InputMaybe<CommissionSlidingScaleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CommissionSlidingScaleFilterInput>;
  some?: InputMaybe<CommissionSlidingScaleFilterInput>;
};

export type ListFilterInputTypeOfCommissionSlidingScaleMediaTypeFilterInput = {
  all?: InputMaybe<CommissionSlidingScaleMediaTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CommissionSlidingScaleMediaTypeFilterInput>;
  some?: InputMaybe<CommissionSlidingScaleMediaTypeFilterInput>;
};

export type ListFilterInputTypeOfCommissionSlidingScalePeriodFilterInput = {
  all?: InputMaybe<CommissionSlidingScalePeriodFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CommissionSlidingScalePeriodFilterInput>;
  some?: InputMaybe<CommissionSlidingScalePeriodFilterInput>;
};

export type ListFilterInputTypeOfContactFilterInput = {
  all?: InputMaybe<ContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ContactFilterInput>;
  some?: InputMaybe<ContactFilterInput>;
};

export type ListFilterInputTypeOfContactTypeFilterInput = {
  all?: InputMaybe<ContactTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ContactTypeFilterInput>;
  some?: InputMaybe<ContactTypeFilterInput>;
};

export type ListFilterInputTypeOfContractTermFilterInput = {
  all?: InputMaybe<ContractTermFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ContractTermFilterInput>;
  some?: InputMaybe<ContractTermFilterInput>;
};

export type ListFilterInputTypeOfCostPerLeadRangeFilterInput = {
  all?: InputMaybe<CostPerLeadRangeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CostPerLeadRangeFilterInput>;
  some?: InputMaybe<CostPerLeadRangeFilterInput>;
};

export type ListFilterInputTypeOfCreativeFilterInput = {
  all?: InputMaybe<CreativeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CreativeFilterInput>;
  some?: InputMaybe<CreativeFilterInput>;
};

export type ListFilterInputTypeOfCrossChannelCampaignDimensionFilterInput = {
  all?: InputMaybe<CrossChannelCampaignDimensionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CrossChannelCampaignDimensionFilterInput>;
  some?: InputMaybe<CrossChannelCampaignDimensionFilterInput>;
};

export type ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput = {
  all?: InputMaybe<CrossChannelCampaignFeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CrossChannelCampaignFeeFilterInput>;
  some?: InputMaybe<CrossChannelCampaignFeeFilterInput>;
};

export type ListFilterInputTypeOfCrossChannelCampaignFee_ForBudgetAllocationFilterInput = {
  all?: InputMaybe<CrossChannelCampaignFee_ForBudgetAllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CrossChannelCampaignFee_ForBudgetAllocationFilterInput>;
  some?: InputMaybe<CrossChannelCampaignFee_ForBudgetAllocationFilterInput>;
};

export type ListFilterInputTypeOfCrossChannelCampaignFilterInput = {
  all?: InputMaybe<CrossChannelCampaignFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CrossChannelCampaignFilterInput>;
  some?: InputMaybe<CrossChannelCampaignFilterInput>;
};

export type ListFilterInputTypeOfCrossChannelCampaignGroupFilterInput = {
  all?: InputMaybe<CrossChannelCampaignGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CrossChannelCampaignGroupFilterInput>;
  some?: InputMaybe<CrossChannelCampaignGroupFilterInput>;
};

export type ListFilterInputTypeOfCrossChannelCampaignVersionFilterInput = {
  all?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  some?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
};

export type ListFilterInputTypeOfCurveFilterInput = {
  all?: InputMaybe<CurveFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CurveFilterInput>;
  some?: InputMaybe<CurveFilterInput>;
};

export type ListFilterInputTypeOfDatoramaImportJobFilterInput = {
  all?: InputMaybe<DatoramaImportJobFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DatoramaImportJobFilterInput>;
  some?: InputMaybe<DatoramaImportJobFilterInput>;
};

export type ListFilterInputTypeOfDatoramaImportJobProductFilterInput = {
  all?: InputMaybe<DatoramaImportJobProductFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DatoramaImportJobProductFilterInput>;
  some?: InputMaybe<DatoramaImportJobProductFilterInput>;
};

export type ListFilterInputTypeOfDaypartFilterInput = {
  all?: InputMaybe<DaypartFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DaypartFilterInput>;
  some?: InputMaybe<DaypartFilterInput>;
};

export type ListFilterInputTypeOfDigitalDeliveryServiceAttributeFilterInput = {
  all?: InputMaybe<DigitalDeliveryServiceAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalDeliveryServiceAttributeFilterInput>;
  some?: InputMaybe<DigitalDeliveryServiceAttributeFilterInput>;
};

export type ListFilterInputTypeOfDigitalResultTypeFilterInput = {
  all?: InputMaybe<DigitalResultTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalResultTypeFilterInput>;
  some?: InputMaybe<DigitalResultTypeFilterInput>;
};

export type ListFilterInputTypeOfDigitalSpendImportChannelMappingFilterInput = {
  all?: InputMaybe<DigitalSpendImportChannelMappingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalSpendImportChannelMappingFilterInput>;
  some?: InputMaybe<DigitalSpendImportChannelMappingFilterInput>;
};

export type ListFilterInputTypeOfDigitalSpendImportDatoramaKeyMappingFilterInput = {
  all?: InputMaybe<DigitalSpendImportDatoramaKeyMappingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalSpendImportDatoramaKeyMappingFilterInput>;
  some?: InputMaybe<DigitalSpendImportDatoramaKeyMappingFilterInput>;
};

export type ListFilterInputTypeOfDigitalSpendImportFieldMappingFilterInput = {
  all?: InputMaybe<DigitalSpendImportFieldMappingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalSpendImportFieldMappingFilterInput>;
  some?: InputMaybe<DigitalSpendImportFieldMappingFilterInput>;
};

export type ListFilterInputTypeOfDigitalSpendImportValueMappingFilterInput = {
  all?: InputMaybe<DigitalSpendImportValueMappingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalSpendImportValueMappingFilterInput>;
  some?: InputMaybe<DigitalSpendImportValueMappingFilterInput>;
};

export type ListFilterInputTypeOfDigitalSpendInputFileFilterInput = {
  all?: InputMaybe<DigitalSpendInputFileFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalSpendInputFileFilterInput>;
  some?: InputMaybe<DigitalSpendInputFileFilterInput>;
};

export type ListFilterInputTypeOfDigitalSpendInputLineFilterInput = {
  all?: InputMaybe<DigitalSpendInputLineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DigitalSpendInputLineFilterInput>;
  some?: InputMaybe<DigitalSpendInputLineFilterInput>;
};

export type ListFilterInputTypeOfDimensionForBudgetAllocationFilterInput = {
  all?: InputMaybe<DimensionForBudgetAllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DimensionForBudgetAllocationFilterInput>;
  some?: InputMaybe<DimensionForBudgetAllocationFilterInput>;
};

export type ListFilterInputTypeOfDmainfoBudgetFilterInput = {
  all?: InputMaybe<DmainfoBudgetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DmainfoBudgetFilterInput>;
  some?: InputMaybe<DmainfoBudgetFilterInput>;
};

export type ListFilterInputTypeOfDmainfoBuyerFilterInput = {
  all?: InputMaybe<DmainfoBuyerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DmainfoBuyerFilterInput>;
  some?: InputMaybe<DmainfoBuyerFilterInput>;
};

export type ListFilterInputTypeOfDmainfoFilterInput = {
  all?: InputMaybe<DmainfoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DmainfoFilterInput>;
  some?: InputMaybe<DmainfoFilterInput>;
};

export type ListFilterInputTypeOfDubOrderTransmissionFilterInput = {
  all?: InputMaybe<DubOrderTransmissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DubOrderTransmissionFilterInput>;
  some?: InputMaybe<DubOrderTransmissionFilterInput>;
};

export type ListFilterInputTypeOfDuplicationCostEstimateAdFilterInput = {
  all?: InputMaybe<DuplicationCostEstimateAdFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DuplicationCostEstimateAdFilterInput>;
  some?: InputMaybe<DuplicationCostEstimateAdFilterInput>;
};

export type ListFilterInputTypeOfDuplicationCostEstimateDetailFilterInput = {
  all?: InputMaybe<DuplicationCostEstimateDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DuplicationCostEstimateDetailFilterInput>;
  some?: InputMaybe<DuplicationCostEstimateDetailFilterInput>;
};

export type ListFilterInputTypeOfDuplicationCostEstimateFilterInput = {
  all?: InputMaybe<DuplicationCostEstimateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DuplicationCostEstimateFilterInput>;
  some?: InputMaybe<DuplicationCostEstimateFilterInput>;
};

export type ListFilterInputTypeOfDuplicationCostEstimateMediaOutletFilterInput = {
  all?: InputMaybe<DuplicationCostEstimateMediaOutletFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DuplicationCostEstimateMediaOutletFilterInput>;
  some?: InputMaybe<DuplicationCostEstimateMediaOutletFilterInput>;
};

export type ListFilterInputTypeOfDuplicationInvoiceLineFilterInput = {
  all?: InputMaybe<DuplicationInvoiceLineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DuplicationInvoiceLineFilterInput>;
  some?: InputMaybe<DuplicationInvoiceLineFilterInput>;
};

export type ListFilterInputTypeOfDuplicationLayoutFilterInput = {
  all?: InputMaybe<DuplicationLayoutFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DuplicationLayoutFilterInput>;
  some?: InputMaybe<DuplicationLayoutFilterInput>;
};

export type ListFilterInputTypeOfDuplicationPresetFilterInput = {
  all?: InputMaybe<DuplicationPresetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DuplicationPresetFilterInput>;
  some?: InputMaybe<DuplicationPresetFilterInput>;
};

export type ListFilterInputTypeOfFileFilterInput = {
  all?: InputMaybe<FileFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileFilterInput>;
  some?: InputMaybe<FileFilterInput>;
};

export type ListFilterInputTypeOfFileImportFilterInput = {
  all?: InputMaybe<FileImportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileImportFilterInput>;
  some?: InputMaybe<FileImportFilterInput>;
};

export type ListFilterInputTypeOfFileImportMapFilterInput = {
  all?: InputMaybe<FileImportMapFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileImportMapFilterInput>;
  some?: InputMaybe<FileImportMapFilterInput>;
};

export type ListFilterInputTypeOfFileProcessorRunFileImportFilterInput = {
  all?: InputMaybe<FileProcessorRunFileImportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileProcessorRunFileImportFilterInput>;
  some?: InputMaybe<FileProcessorRunFileImportFilterInput>;
};

export type ListFilterInputTypeOfFileProcessorRunFilterInput = {
  all?: InputMaybe<FileProcessorRunFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileProcessorRunFilterInput>;
  some?: InputMaybe<FileProcessorRunFilterInput>;
};

export type ListFilterInputTypeOfFileReferenceFilterInput = {
  all?: InputMaybe<FileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileReferenceFilterInput>;
  some?: InputMaybe<FileReferenceFilterInput>;
};

export type ListFilterInputTypeOfFileToProcessFilterInput = {
  all?: InputMaybe<FileToProcessFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileToProcessFilterInput>;
  some?: InputMaybe<FileToProcessFilterInput>;
};

export type ListFilterInputTypeOfFlightDayFilterInput = {
  all?: InputMaybe<FlightDayFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FlightDayFilterInput>;
  some?: InputMaybe<FlightDayFilterInput>;
};

export type ListFilterInputTypeOfGareportFilterInput = {
  all?: InputMaybe<GareportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GareportFilterInput>;
  some?: InputMaybe<GareportFilterInput>;
};

export type ListFilterInputTypeOfGareportSkudefinitionFilterInput = {
  all?: InputMaybe<GareportSkudefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GareportSkudefinitionFilterInput>;
  some?: InputMaybe<GareportSkudefinitionFilterInput>;
};

export type ListFilterInputTypeOfGlanceReportDimensionFilterInput = {
  all?: InputMaybe<GlanceReportDimensionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GlanceReportDimensionFilterInput>;
  some?: InputMaybe<GlanceReportDimensionFilterInput>;
};

export type ListFilterInputTypeOfGlanceReportElementFilterInput = {
  all?: InputMaybe<GlanceReportElementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GlanceReportElementFilterInput>;
  some?: InputMaybe<GlanceReportElementFilterInput>;
};

export type ListFilterInputTypeOfGlanceReportFilterInput = {
  all?: InputMaybe<GlanceReportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GlanceReportFilterInput>;
  some?: InputMaybe<GlanceReportFilterInput>;
};

export type ListFilterInputTypeOfGlanceReportRangeFilterInput = {
  all?: InputMaybe<GlanceReportRangeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GlanceReportRangeFilterInput>;
  some?: InputMaybe<GlanceReportRangeFilterInput>;
};

export type ListFilterInputTypeOfGlanceReportWorksheetFilterInput = {
  all?: InputMaybe<GlanceReportWorksheetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GlanceReportWorksheetFilterInput>;
  some?: InputMaybe<GlanceReportWorksheetFilterInput>;
};

export type ListFilterInputTypeOfImsdetectionFilterInput = {
  all?: InputMaybe<ImsdetectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ImsdetectionFilterInput>;
  some?: InputMaybe<ImsdetectionFilterInput>;
};

export type ListFilterInputTypeOfInputFieldMappingFilterInput = {
  all?: InputMaybe<InputFieldMappingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InputFieldMappingFilterInput>;
  some?: InputMaybe<InputFieldMappingFilterInput>;
};

export type ListFilterInputTypeOfInputFieldMappingSchemeFilterInput = {
  all?: InputMaybe<InputFieldMappingSchemeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InputFieldMappingSchemeFilterInput>;
  some?: InputMaybe<InputFieldMappingSchemeFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderContactFilterInput = {
  all?: InputMaybe<InsertionOrderContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderContactFilterInput>;
  some?: InputMaybe<InsertionOrderContactFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderDetailFilterInput = {
  all?: InputMaybe<InsertionOrderDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderDetailFilterInput>;
  some?: InputMaybe<InsertionOrderDetailFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderDetailPlacementFilterInput = {
  all?: InputMaybe<InsertionOrderDetailPlacementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderDetailPlacementFilterInput>;
  some?: InputMaybe<InsertionOrderDetailPlacementFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderDetail_ForCampaignBudgetsFilterInput = {
  all?: InputMaybe<InsertionOrderDetail_ForCampaignBudgetsFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderDetail_ForCampaignBudgetsFilterInput>;
  some?: InputMaybe<InsertionOrderDetail_ForCampaignBudgetsFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderFileReferenceFilterInput = {
  all?: InputMaybe<InsertionOrderFileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderFileReferenceFilterInput>;
  some?: InputMaybe<InsertionOrderFileReferenceFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderFileReference_ForLookupFilterInput = {
  all?: InputMaybe<InsertionOrderFileReference_ForLookupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderFileReference_ForLookupFilterInput>;
  some?: InputMaybe<InsertionOrderFileReference_ForLookupFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderFilterInput = {
  all?: InputMaybe<InsertionOrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderFilterInput>;
  some?: InputMaybe<InsertionOrderFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderPlacementResultFilterInput = {
  all?: InputMaybe<InsertionOrderPlacementResultFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderPlacementResultFilterInput>;
  some?: InputMaybe<InsertionOrderPlacementResultFilterInput>;
};

export type ListFilterInputTypeOfInsertionOrderPlacementResult_ForLookupFilterInput = {
  all?: InputMaybe<InsertionOrderPlacementResult_ForLookupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InsertionOrderPlacementResult_ForLookupFilterInput>;
  some?: InputMaybe<InsertionOrderPlacementResult_ForLookupFilterInput>;
};

export type ListFilterInputTypeOfInvoiceFilterInput = {
  all?: InputMaybe<InvoiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InvoiceFilterInput>;
  some?: InputMaybe<InvoiceFilterInput>;
};

export type ListFilterInputTypeOfInvoiceScheduleFilterInput = {
  all?: InputMaybe<InvoiceScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InvoiceScheduleFilterInput>;
  some?: InputMaybe<InvoiceScheduleFilterInput>;
};

export type ListFilterInputTypeOfInvoiceServiceInstanceFilterInput = {
  all?: InputMaybe<InvoiceServiceInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<InvoiceServiceInstanceFilterInput>;
  some?: InputMaybe<InvoiceServiceInstanceFilterInput>;
};

export type ListFilterInputTypeOfIssueDateInfoFilterInput = {
  all?: InputMaybe<IssueDateInfoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<IssueDateInfoFilterInput>;
  some?: InputMaybe<IssueDateInfoFilterInput>;
};

export type ListFilterInputTypeOfJobAppUserFilterInput = {
  all?: InputMaybe<JobAppUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<JobAppUserFilterInput>;
  some?: InputMaybe<JobAppUserFilterInput>;
};

export type ListFilterInputTypeOfJobDependencyFilterInput = {
  all?: InputMaybe<JobDependencyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<JobDependencyFilterInput>;
  some?: InputMaybe<JobDependencyFilterInput>;
};

export type ListFilterInputTypeOfJobFilterInput = {
  all?: InputMaybe<JobFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<JobFilterInput>;
  some?: InputMaybe<JobFilterInput>;
};

export type ListFilterInputTypeOfJobInstanceFilterInput = {
  all?: InputMaybe<JobInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<JobInstanceFilterInput>;
  some?: InputMaybe<JobInstanceFilterInput>;
};

export type ListFilterInputTypeOfJobInstanceLogFilterInput = {
  all?: InputMaybe<JobInstanceLogFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<JobInstanceLogFilterInput>;
  some?: InputMaybe<JobInstanceLogFilterInput>;
};

export type ListFilterInputTypeOfLeadBillingLockFilterInput = {
  all?: InputMaybe<LeadBillingLockFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LeadBillingLockFilterInput>;
  some?: InputMaybe<LeadBillingLockFilterInput>;
};

export type ListFilterInputTypeOfLocalMarketCostPerFilterInput = {
  all?: InputMaybe<LocalMarketCostPerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocalMarketCostPerFilterInput>;
  some?: InputMaybe<LocalMarketCostPerFilterInput>;
};

export type ListFilterInputTypeOfLocalMarketRatingEstimateAdLengthFilterInput = {
  all?: InputMaybe<LocalMarketRatingEstimateAdLengthFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocalMarketRatingEstimateAdLengthFilterInput>;
  some?: InputMaybe<LocalMarketRatingEstimateAdLengthFilterInput>;
};

export type ListFilterInputTypeOfLocalMarketRatingEstimateDetailFilterInput = {
  all?: InputMaybe<LocalMarketRatingEstimateDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocalMarketRatingEstimateDetailFilterInput>;
  some?: InputMaybe<LocalMarketRatingEstimateDetailFilterInput>;
};

export type ListFilterInputTypeOfLocalMarketRatingEstimateFilterInput = {
  all?: InputMaybe<LocalMarketRatingEstimateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocalMarketRatingEstimateFilterInput>;
  some?: InputMaybe<LocalMarketRatingEstimateFilterInput>;
};

export type ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput = {
  all?: InputMaybe<LocalMarketRatingEstimateMarketFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocalMarketRatingEstimateMarketFilterInput>;
  some?: InputMaybe<LocalMarketRatingEstimateMarketFilterInput>;
};

export type ListFilterInputTypeOfLocalMarketRatingEstimateMarketGoalFilterInput = {
  all?: InputMaybe<LocalMarketRatingEstimateMarketGoalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocalMarketRatingEstimateMarketGoalFilterInput>;
  some?: InputMaybe<LocalMarketRatingEstimateMarketGoalFilterInput>;
};

export type ListFilterInputTypeOfLogFileLineFilterInput = {
  all?: InputMaybe<LogFileLineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LogFileLineFilterInput>;
  some?: InputMaybe<LogFileLineFilterInput>;
};

export type ListFilterInputTypeOfLookupFilterInput = {
  all?: InputMaybe<LookupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LookupFilterInput>;
  some?: InputMaybe<LookupFilterInput>;
};

export type ListFilterInputTypeOfManualClearanceLogFilterInput = {
  all?: InputMaybe<ManualClearanceLogFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ManualClearanceLogFilterInput>;
  some?: InputMaybe<ManualClearanceLogFilterInput>;
};

export type ListFilterInputTypeOfMarketContributionAnalysisFilterInput = {
  all?: InputMaybe<MarketContributionAnalysisFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MarketContributionAnalysisFilterInput>;
  some?: InputMaybe<MarketContributionAnalysisFilterInput>;
};

export type ListFilterInputTypeOfMediaAuthorizationDimensionFilterInput = {
  all?: InputMaybe<MediaAuthorizationDimensionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaAuthorizationDimensionFilterInput>;
  some?: InputMaybe<MediaAuthorizationDimensionFilterInput>;
};

export type ListFilterInputTypeOfMediaAuthorizationDimension_ForBudgetAllocationFilterInput = {
  all?: InputMaybe<MediaAuthorizationDimension_ForBudgetAllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaAuthorizationDimension_ForBudgetAllocationFilterInput>;
  some?: InputMaybe<MediaAuthorizationDimension_ForBudgetAllocationFilterInput>;
};

export type ListFilterInputTypeOfMediaAuthorizationFilterInput = {
  all?: InputMaybe<MediaAuthorizationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaAuthorizationFilterInput>;
  some?: InputMaybe<MediaAuthorizationFilterInput>;
};

export type ListFilterInputTypeOfMediaAuthorization_ForBudgetAllocationFilterInput = {
  all?: InputMaybe<MediaAuthorization_ForBudgetAllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaAuthorization_ForBudgetAllocationFilterInput>;
  some?: InputMaybe<MediaAuthorization_ForBudgetAllocationFilterInput>;
};

export type ListFilterInputTypeOfMediaAvailFilterInput = {
  all?: InputMaybe<MediaAvailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaAvailFilterInput>;
  some?: InputMaybe<MediaAvailFilterInput>;
};

export type ListFilterInputTypeOfMediaAvailRateFilterInput = {
  all?: InputMaybe<MediaAvailRateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaAvailRateFilterInput>;
  some?: InputMaybe<MediaAvailRateFilterInput>;
};

export type ListFilterInputTypeOfMediaAvailRatingFilterInput = {
  all?: InputMaybe<MediaAvailRatingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaAvailRatingFilterInput>;
  some?: InputMaybe<MediaAvailRatingFilterInput>;
};

export type ListFilterInputTypeOfMediaFormatAttributeFilterInput = {
  all?: InputMaybe<MediaFormatAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaFormatAttributeFilterInput>;
  some?: InputMaybe<MediaFormatAttributeFilterInput>;
};

export type ListFilterInputTypeOfMediaLibraryFilterInput = {
  all?: InputMaybe<MediaLibraryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaLibraryFilterInput>;
  some?: InputMaybe<MediaLibraryFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletAddressFilterInput = {
  all?: InputMaybe<MediaOutletAddressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletAddressFilterInput>;
  some?: InputMaybe<MediaOutletAddressFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput = {
  all?: InputMaybe<MediaOutletClientCreditLimitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletClientCreditLimitFilterInput>;
  some?: InputMaybe<MediaOutletClientCreditLimitFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletClusterFilterInput = {
  all?: InputMaybe<MediaOutletClusterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletClusterFilterInput>;
  some?: InputMaybe<MediaOutletClusterFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletContactFilterInput = {
  all?: InputMaybe<MediaOutletContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletContactFilterInput>;
  some?: InputMaybe<MediaOutletContactFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletDigitalDeliveryTypeToAddressTypeFilterInput = {
  all?: InputMaybe<MediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>;
  some?: InputMaybe<MediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletFileReferenceFilterInput = {
  all?: InputMaybe<MediaOutletFileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletFileReferenceFilterInput>;
  some?: InputMaybe<MediaOutletFileReferenceFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletFilterInput = {
  all?: InputMaybe<MediaOutletFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletFilterInput>;
  some?: InputMaybe<MediaOutletFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletGroupFilterInput = {
  all?: InputMaybe<MediaOutletGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletGroupFilterInput>;
  some?: InputMaybe<MediaOutletGroupFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletMediaTypeDigitalDeliveryServiceFilterInput = {
  all?: InputMaybe<MediaOutletMediaTypeDigitalDeliveryServiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletMediaTypeDigitalDeliveryServiceFilterInput>;
  some?: InputMaybe<MediaOutletMediaTypeDigitalDeliveryServiceFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletMediaTypeFilterInput = {
  all?: InputMaybe<MediaOutletMediaTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletMediaTypeFilterInput>;
  some?: InputMaybe<MediaOutletMediaTypeFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletMediaTypeVendorCodeFilterInput = {
  all?: InputMaybe<MediaOutletMediaTypeVendorCodeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletMediaTypeVendorCodeFilterInput>;
  some?: InputMaybe<MediaOutletMediaTypeVendorCodeFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput = {
  all?: InputMaybe<MediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput>;
  some?: InputMaybe<MediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletMirrorRotationFilterInput = {
  all?: InputMaybe<MediaOutletMirrorRotationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletMirrorRotationFilterInput>;
  some?: InputMaybe<MediaOutletMirrorRotationFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletOfferingFilterInput = {
  all?: InputMaybe<MediaOutletOfferingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletOfferingFilterInput>;
  some?: InputMaybe<MediaOutletOfferingFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletParserFilterInput = {
  all?: InputMaybe<MediaOutletParserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletParserFilterInput>;
  some?: InputMaybe<MediaOutletParserFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletPrintFormatFilterInput = {
  all?: InputMaybe<MediaOutletPrintFormatFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletPrintFormatFilterInput>;
  some?: InputMaybe<MediaOutletPrintFormatFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletPrintTerritoryFilterInput = {
  all?: InputMaybe<MediaOutletPrintTerritoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletPrintTerritoryFilterInput>;
  some?: InputMaybe<MediaOutletPrintTerritoryFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletRateFilterInput = {
  all?: InputMaybe<MediaOutletRateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletRateFilterInput>;
  some?: InputMaybe<MediaOutletRateFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletRegionFilterInput = {
  all?: InputMaybe<MediaOutletRegionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletRegionFilterInput>;
  some?: InputMaybe<MediaOutletRegionFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletRoleFilterInput = {
  all?: InputMaybe<MediaOutletRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletRoleFilterInput>;
  some?: InputMaybe<MediaOutletRoleFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletRotationFilterInput = {
  all?: InputMaybe<MediaOutletRotationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletRotationFilterInput>;
  some?: InputMaybe<MediaOutletRotationFilterInput>;
};

export type ListFilterInputTypeOfMediaOutletTypeFilterInput = {
  all?: InputMaybe<MediaOutletTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaOutletTypeFilterInput>;
  some?: InputMaybe<MediaOutletTypeFilterInput>;
};

export type ListFilterInputTypeOfMediaPlanExportExcelOptionFilterInput = {
  all?: InputMaybe<MediaPlanExportExcelOptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaPlanExportExcelOptionFilterInput>;
  some?: InputMaybe<MediaPlanExportExcelOptionFilterInput>;
};

export type ListFilterInputTypeOfMediaPlanFilterInput = {
  all?: InputMaybe<MediaPlanFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaPlanFilterInput>;
  some?: InputMaybe<MediaPlanFilterInput>;
};

export type ListFilterInputTypeOfMediaPlanNielsenDemoFilterInput = {
  all?: InputMaybe<MediaPlanNielsenDemoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaPlanNielsenDemoFilterInput>;
  some?: InputMaybe<MediaPlanNielsenDemoFilterInput>;
};

export type ListFilterInputTypeOfMediaPlanRentrakDemographicFilterInput = {
  all?: InputMaybe<MediaPlanRentrakDemographicFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaPlanRentrakDemographicFilterInput>;
  some?: InputMaybe<MediaPlanRentrakDemographicFilterInput>;
};

export type ListFilterInputTypeOfMediaPlanTapeLengthFilterInput = {
  all?: InputMaybe<MediaPlanTapeLengthFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaPlanTapeLengthFilterInput>;
  some?: InputMaybe<MediaPlanTapeLengthFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleAdFilterInput = {
  all?: InputMaybe<MediaScheduleAdFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleAdFilterInput>;
  some?: InputMaybe<MediaScheduleAdFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleDetailAdOverrideFilterInput = {
  all?: InputMaybe<MediaScheduleDetailAdOverrideFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleDetailAdOverrideFilterInput>;
  some?: InputMaybe<MediaScheduleDetailAdOverrideFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleDetailAttributeFilterInput = {
  all?: InputMaybe<MediaScheduleDetailAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleDetailAttributeFilterInput>;
  some?: InputMaybe<MediaScheduleDetailAttributeFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleDetailFilterInput = {
  all?: InputMaybe<MediaScheduleDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleDetailFilterInput>;
  some?: InputMaybe<MediaScheduleDetailFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleDetailHistoricalKpimatchFilterInput = {
  all?: InputMaybe<MediaScheduleDetailHistoricalKpimatchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleDetailHistoricalKpimatchFilterInput>;
  some?: InputMaybe<MediaScheduleDetailHistoricalKpimatchFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleDetailInstanceFilterInput = {
  all?: InputMaybe<MediaScheduleDetailInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleDetailInstanceFilterInput>;
  some?: InputMaybe<MediaScheduleDetailInstanceFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleDisplayFilterInput = {
  all?: InputMaybe<MediaScheduleDisplayFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleDisplayFilterInput>;
  some?: InputMaybe<MediaScheduleDisplayFilterInput>;
};

export type ListFilterInputTypeOfMediaScheduleFilterInput = {
  all?: InputMaybe<MediaScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaScheduleFilterInput>;
  some?: InputMaybe<MediaScheduleFilterInput>;
};

export type ListFilterInputTypeOfMediaTransmissionFilterInput = {
  all?: InputMaybe<MediaTransmissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaTransmissionFilterInput>;
  some?: InputMaybe<MediaTransmissionFilterInput>;
};

export type ListFilterInputTypeOfMediaTransmissionNotificationFilterInput = {
  all?: InputMaybe<MediaTransmissionNotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaTransmissionNotificationFilterInput>;
  some?: InputMaybe<MediaTransmissionNotificationFilterInput>;
};

export type ListFilterInputTypeOfMediaTypeFilterInput = {
  all?: InputMaybe<MediaTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaTypeFilterInput>;
  some?: InputMaybe<MediaTypeFilterInput>;
};

export type ListFilterInputTypeOfMediaTypeWithAmountFilterInput = {
  all?: InputMaybe<MediaTypeWithAmountFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MediaTypeWithAmountFilterInput>;
  some?: InputMaybe<MediaTypeWithAmountFilterInput>;
};

export type ListFilterInputTypeOfMriindexFilterInput = {
  all?: InputMaybe<MriindexFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MriindexFilterInput>;
  some?: InputMaybe<MriindexFilterInput>;
};

export type ListFilterInputTypeOfNewCampaignAnnouncementFileReferenceFilterInput = {
  all?: InputMaybe<NewCampaignAnnouncementFileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewCampaignAnnouncementFileReferenceFilterInput>;
  some?: InputMaybe<NewCampaignAnnouncementFileReferenceFilterInput>;
};

export type ListFilterInputTypeOfNewCampaignAnnouncementFilterInput = {
  all?: InputMaybe<NewCampaignAnnouncementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewCampaignAnnouncementFilterInput>;
  some?: InputMaybe<NewCampaignAnnouncementFilterInput>;
};

export type ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput = {
  all?: InputMaybe<NewCampaignAnnouncementPiFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewCampaignAnnouncementPiFilterInput>;
  some?: InputMaybe<NewCampaignAnnouncementPiFilterInput>;
};

export type ListFilterInputTypeOfNewCampaignAnnouncementPiassociationFilterInput = {
  all?: InputMaybe<NewCampaignAnnouncementPiassociationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewCampaignAnnouncementPiassociationFilterInput>;
  some?: InputMaybe<NewCampaignAnnouncementPiassociationFilterInput>;
};

export type ListFilterInputTypeOfNewCampaignAnnouncementPimediaOutletFilterInput = {
  all?: InputMaybe<NewCampaignAnnouncementPimediaOutletFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewCampaignAnnouncementPimediaOutletFilterInput>;
  some?: InputMaybe<NewCampaignAnnouncementPimediaOutletFilterInput>;
};

export type ListFilterInputTypeOfNewCampaignAssociationFilterInput = {
  all?: InputMaybe<NewCampaignAssociationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewCampaignAssociationFilterInput>;
  some?: InputMaybe<NewCampaignAssociationFilterInput>;
};

export type ListFilterInputTypeOfNielsenDemoPopulationFilterInput = {
  all?: InputMaybe<NielsenDemoPopulationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NielsenDemoPopulationFilterInput>;
  some?: InputMaybe<NielsenDemoPopulationFilterInput>;
};

export type ListFilterInputTypeOfNoteFilterInput = {
  all?: InputMaybe<NoteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NoteFilterInput>;
  some?: InputMaybe<NoteFilterInput>;
};

export type ListFilterInputTypeOfOnlineScheduleAttributeFilterInput = {
  all?: InputMaybe<OnlineScheduleAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OnlineScheduleAttributeFilterInput>;
  some?: InputMaybe<OnlineScheduleAttributeFilterInput>;
};

export type ListFilterInputTypeOfPackageFilterInput = {
  all?: InputMaybe<PackageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PackageFilterInput>;
  some?: InputMaybe<PackageFilterInput>;
};

export type ListFilterInputTypeOfParentInvoiceFilterInput = {
  all?: InputMaybe<ParentInvoiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ParentInvoiceFilterInput>;
  some?: InputMaybe<ParentInvoiceFilterInput>;
};

export type ListFilterInputTypeOfParserFileReferenceFilterInput = {
  all?: InputMaybe<ParserFileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ParserFileReferenceFilterInput>;
  some?: InputMaybe<ParserFileReferenceFilterInput>;
};

export type ListFilterInputTypeOfParserFilterInput = {
  all?: InputMaybe<ParserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ParserFilterInput>;
  some?: InputMaybe<ParserFilterInput>;
};

export type ListFilterInputTypeOfPerInquiryCostFilterInput = {
  all?: InputMaybe<PerInquiryCostFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PerInquiryCostFilterInput>;
  some?: InputMaybe<PerInquiryCostFilterInput>;
};

export type ListFilterInputTypeOfPersonFunctionalRoleFilterInput = {
  all?: InputMaybe<PersonFunctionalRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PersonFunctionalRoleFilterInput>;
  some?: InputMaybe<PersonFunctionalRoleFilterInput>;
};

export type ListFilterInputTypeOfPerson_ClientFilterInput = {
  all?: InputMaybe<Person_ClientFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<Person_ClientFilterInput>;
  some?: InputMaybe<Person_ClientFilterInput>;
};

export type ListFilterInputTypeOfPrelogEstimateFilterInput = {
  all?: InputMaybe<PrelogEstimateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PrelogEstimateFilterInput>;
  some?: InputMaybe<PrelogEstimateFilterInput>;
};

export type ListFilterInputTypeOfPrelogFilterInput = {
  all?: InputMaybe<PrelogFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PrelogFilterInput>;
  some?: InputMaybe<PrelogFilterInput>;
};

export type ListFilterInputTypeOfPrintAvailFilterInput = {
  all?: InputMaybe<PrintAvailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PrintAvailFilterInput>;
  some?: InputMaybe<PrintAvailFilterInput>;
};

export type ListFilterInputTypeOfPrintScheduleAttributeFilterInput = {
  all?: InputMaybe<PrintScheduleAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PrintScheduleAttributeFilterInput>;
  some?: InputMaybe<PrintScheduleAttributeFilterInput>;
};

export type ListFilterInputTypeOfPrintTerritoryFilterInput = {
  all?: InputMaybe<PrintTerritoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PrintTerritoryFilterInput>;
  some?: InputMaybe<PrintTerritoryFilterInput>;
};

export type ListFilterInputTypeOfProcessorFilterInput = {
  all?: InputMaybe<ProcessorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProcessorFilterInput>;
  some?: InputMaybe<ProcessorFilterInput>;
};

export type ListFilterInputTypeOfProductAudienceFilterInput = {
  all?: InputMaybe<ProductAudienceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductAudienceFilterInput>;
  some?: InputMaybe<ProductAudienceFilterInput>;
};

export type ListFilterInputTypeOfProductCategoryHierarchyFilterInput = {
  all?: InputMaybe<ProductCategoryHierarchyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductCategoryHierarchyFilterInput>;
  some?: InputMaybe<ProductCategoryHierarchyFilterInput>;
};

export type ListFilterInputTypeOfProductDateBlackListFilterInput = {
  all?: InputMaybe<ProductDateBlackListFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductDateBlackListFilterInput>;
  some?: InputMaybe<ProductDateBlackListFilterInput>;
};

export type ListFilterInputTypeOfProductDimensionColumnFilterInput = {
  all?: InputMaybe<ProductDimensionColumnFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductDimensionColumnFilterInput>;
  some?: InputMaybe<ProductDimensionColumnFilterInput>;
};

export type ListFilterInputTypeOfProductDimensionFilterInput = {
  all?: InputMaybe<ProductDimensionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductDimensionFilterInput>;
  some?: InputMaybe<ProductDimensionFilterInput>;
};

export type ListFilterInputTypeOfProductDimensionTypeFilterInput = {
  all?: InputMaybe<ProductDimensionTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductDimensionTypeFilterInput>;
  some?: InputMaybe<ProductDimensionTypeFilterInput>;
};

export type ListFilterInputTypeOfProductFilterInput = {
  all?: InputMaybe<ProductFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductFilterInput>;
  some?: InputMaybe<ProductFilterInput>;
};

export type ListFilterInputTypeOfProductFulfillmentFilterInput = {
  all?: InputMaybe<ProductFulfillmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductFulfillmentFilterInput>;
  some?: InputMaybe<ProductFulfillmentFilterInput>;
};

export type ListFilterInputTypeOfProductHourFilterInput = {
  all?: InputMaybe<ProductHourFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductHourFilterInput>;
  some?: InputMaybe<ProductHourFilterInput>;
};

export type ListFilterInputTypeOfProductMediaOutletBlackListFilterInput = {
  all?: InputMaybe<ProductMediaOutletBlackListFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductMediaOutletBlackListFilterInput>;
  some?: InputMaybe<ProductMediaOutletBlackListFilterInput>;
};

export type ListFilterInputTypeOfProductMediaOutletPirateFilterInput = {
  all?: InputMaybe<ProductMediaOutletPirateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductMediaOutletPirateFilterInput>;
  some?: InputMaybe<ProductMediaOutletPirateFilterInput>;
};

export type ListFilterInputTypeOfProductMediaTargetFilterInput = {
  all?: InputMaybe<ProductMediaTargetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductMediaTargetFilterInput>;
  some?: InputMaybe<ProductMediaTargetFilterInput>;
};

export type ListFilterInputTypeOfProductMediaTypeLockDateFilterInput = {
  all?: InputMaybe<ProductMediaTypeLockDateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductMediaTypeLockDateFilterInput>;
  some?: InputMaybe<ProductMediaTypeLockDateFilterInput>;
};

export type ListFilterInputTypeOfProductOfferGroupFilterInput = {
  all?: InputMaybe<ProductOfferGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductOfferGroupFilterInput>;
  some?: InputMaybe<ProductOfferGroupFilterInput>;
};

export type ListFilterInputTypeOfProductProgramBlackListFilterInput = {
  all?: InputMaybe<ProductProgramBlackListFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductProgramBlackListFilterInput>;
  some?: InputMaybe<ProductProgramBlackListFilterInput>;
};

export type ListFilterInputTypeOfProductRegionBlackListFilterInput = {
  all?: InputMaybe<ProductRegionBlackListFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductRegionBlackListFilterInput>;
  some?: InputMaybe<ProductRegionBlackListFilterInput>;
};

export type ListFilterInputTypeOfProductTsbcontactListFilterInput = {
  all?: InputMaybe<ProductTsbcontactListFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductTsbcontactListFilterInput>;
  some?: InputMaybe<ProductTsbcontactListFilterInput>;
};

export type ListFilterInputTypeOfProduct_ForMaintenanceFilterInput = {
  all?: InputMaybe<Product_ForMaintenanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<Product_ForMaintenanceFilterInput>;
  some?: InputMaybe<Product_ForMaintenanceFilterInput>;
};

export type ListFilterInputTypeOfRainOnePersonLinkFilterInput = {
  all?: InputMaybe<RainOnePersonLinkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RainOnePersonLinkFilterInput>;
  some?: InputMaybe<RainOnePersonLinkFilterInput>;
};

export type ListFilterInputTypeOfRainOnePerson_RainOneRoleFilterInput = {
  all?: InputMaybe<RainOnePerson_RainOneRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RainOnePerson_RainOneRoleFilterInput>;
  some?: InputMaybe<RainOnePerson_RainOneRoleFilterInput>;
};

export type ListFilterInputTypeOfRainOneRole_RainPermissionFilterInput = {
  all?: InputMaybe<RainOneRole_RainPermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RainOneRole_RainPermissionFilterInput>;
  some?: InputMaybe<RainOneRole_RainPermissionFilterInput>;
};

export type ListFilterInputTypeOfRateCardFilterInput = {
  all?: InputMaybe<RateCardFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RateCardFilterInput>;
  some?: InputMaybe<RateCardFilterInput>;
};

export type ListFilterInputTypeOfRatingAdInstanceFilterInput = {
  all?: InputMaybe<RatingAdInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RatingAdInstanceFilterInput>;
  some?: InputMaybe<RatingAdInstanceFilterInput>;
};

export type ListFilterInputTypeOfRatingAvailProposalDetailFilterInput = {
  all?: InputMaybe<RatingAvailProposalDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RatingAvailProposalDetailFilterInput>;
  some?: InputMaybe<RatingAvailProposalDetailFilterInput>;
};

export type ListFilterInputTypeOfRatingFilterInput = {
  all?: InputMaybe<RatingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RatingFilterInput>;
  some?: InputMaybe<RatingFilterInput>;
};

export type ListFilterInputTypeOfRatingMediaScheduleDetailFilterInput = {
  all?: InputMaybe<RatingMediaScheduleDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RatingMediaScheduleDetailFilterInput>;
  some?: InputMaybe<RatingMediaScheduleDetailFilterInput>;
};

export type ListFilterInputTypeOfRatingScheduleFilterInput = {
  all?: InputMaybe<RatingScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RatingScheduleFilterInput>;
  some?: InputMaybe<RatingScheduleFilterInput>;
};

export type ListFilterInputTypeOfRegionFilterInput = {
  all?: InputMaybe<RegionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RegionFilterInput>;
  some?: InputMaybe<RegionFilterInput>;
};

export type ListFilterInputTypeOfRegionLinkFilterInput = {
  all?: InputMaybe<RegionLinkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RegionLinkFilterInput>;
  some?: InputMaybe<RegionLinkFilterInput>;
};

export type ListFilterInputTypeOfRentrakNetworkFilterInput = {
  all?: InputMaybe<RentrakNetworkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RentrakNetworkFilterInput>;
  some?: InputMaybe<RentrakNetworkFilterInput>;
};

export type ListFilterInputTypeOfRentrakRatingAdInstanceFilterInput = {
  all?: InputMaybe<RentrakRatingAdInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RentrakRatingAdInstanceFilterInput>;
  some?: InputMaybe<RentrakRatingAdInstanceFilterInput>;
};

export type ListFilterInputTypeOfRentrakRatingScheduleFilterInput = {
  all?: InputMaybe<RentrakRatingScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RentrakRatingScheduleFilterInput>;
  some?: InputMaybe<RentrakRatingScheduleFilterInput>;
};

export type ListFilterInputTypeOfRentrakTelecastDetailFilterInput = {
  all?: InputMaybe<RentrakTelecastDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RentrakTelecastDetailFilterInput>;
  some?: InputMaybe<RentrakTelecastDetailFilterInput>;
};

export type ListFilterInputTypeOfReportControlSettingFilterInput = {
  all?: InputMaybe<ReportControlSettingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportControlSettingFilterInput>;
  some?: InputMaybe<ReportControlSettingFilterInput>;
};

export type ListFilterInputTypeOfReportEditFieldFilterInput = {
  all?: InputMaybe<ReportEditFieldFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportEditFieldFilterInput>;
  some?: InputMaybe<ReportEditFieldFilterInput>;
};

export type ListFilterInputTypeOfReportExportFormatBreakpointFilterInput = {
  all?: InputMaybe<ReportExportFormatBreakpointFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportExportFormatBreakpointFilterInput>;
  some?: InputMaybe<ReportExportFormatBreakpointFilterInput>;
};

export type ListFilterInputTypeOfReportExportFormatFieldFilterInput = {
  all?: InputMaybe<ReportExportFormatFieldFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportExportFormatFieldFilterInput>;
  some?: InputMaybe<ReportExportFormatFieldFilterInput>;
};

export type ListFilterInputTypeOfReportExportFormatFilterInput = {
  all?: InputMaybe<ReportExportFormatFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportExportFormatFilterInput>;
  some?: InputMaybe<ReportExportFormatFilterInput>;
};

export type ListFilterInputTypeOfReportExportFormatStyleFilterInput = {
  all?: InputMaybe<ReportExportFormatStyleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportExportFormatStyleFilterInput>;
  some?: InputMaybe<ReportExportFormatStyleFilterInput>;
};

export type ListFilterInputTypeOfReportFilterInput = {
  all?: InputMaybe<ReportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportFilterInput>;
  some?: InputMaybe<ReportFilterInput>;
};

export type ListFilterInputTypeOfReportFormatFilterInput = {
  all?: InputMaybe<ReportFormatFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportFormatFilterInput>;
  some?: InputMaybe<ReportFormatFilterInput>;
};

export type ListFilterInputTypeOfReportOptionFilterInput = {
  all?: InputMaybe<ReportOptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportOptionFilterInput>;
  some?: InputMaybe<ReportOptionFilterInput>;
};

export type ListFilterInputTypeOfReportPresetFilterInput = {
  all?: InputMaybe<ReportPresetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportPresetFilterInput>;
  some?: InputMaybe<ReportPresetFilterInput>;
};

export type ListFilterInputTypeOfReportPresetFilterRecordFilterInput = {
  all?: InputMaybe<ReportPresetFilterRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportPresetFilterRecordFilterInput>;
  some?: InputMaybe<ReportPresetFilterRecordFilterInput>;
};

export type ListFilterInputTypeOfReportPresetGroupSortFilterInput = {
  all?: InputMaybe<ReportPresetGroupSortFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportPresetGroupSortFilterInput>;
  some?: InputMaybe<ReportPresetGroupSortFilterInput>;
};

export type ListFilterInputTypeOfReportPresetOptionFilterInput = {
  all?: InputMaybe<ReportPresetOptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ReportPresetOptionFilterInput>;
  some?: InputMaybe<ReportPresetOptionFilterInput>;
};

export type ListFilterInputTypeOfResultDetailFilterInput = {
  all?: InputMaybe<ResultDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResultDetailFilterInput>;
  some?: InputMaybe<ResultDetailFilterInput>;
};

export type ListFilterInputTypeOfResultFilterInput = {
  all?: InputMaybe<ResultFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResultFilterInput>;
  some?: InputMaybe<ResultFilterInput>;
};

export type ListFilterInputTypeOfResultManualEntryDetailFilterInput = {
  all?: InputMaybe<ResultManualEntryDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResultManualEntryDetailFilterInput>;
  some?: InputMaybe<ResultManualEntryDetailFilterInput>;
};

export type ListFilterInputTypeOfResultManualEntryPresetFilterInput = {
  all?: InputMaybe<ResultManualEntryPresetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResultManualEntryPresetFilterInput>;
  some?: InputMaybe<ResultManualEntryPresetFilterInput>;
};

export type ListFilterInputTypeOfResultStagingDetailFilterInput = {
  all?: InputMaybe<ResultStagingDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResultStagingDetailFilterInput>;
  some?: InputMaybe<ResultStagingDetailFilterInput>;
};

export type ListFilterInputTypeOfResultStagingFilterInput = {
  all?: InputMaybe<ResultStagingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResultStagingFilterInput>;
  some?: InputMaybe<ResultStagingFilterInput>;
};

export type ListFilterInputTypeOfRevisionFilterInput = {
  all?: InputMaybe<RevisionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RevisionFilterInput>;
  some?: InputMaybe<RevisionFilterInput>;
};

export type ListFilterInputTypeOfRevisionGroupFilterInput = {
  all?: InputMaybe<RevisionGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RevisionGroupFilterInput>;
  some?: InputMaybe<RevisionGroupFilterInput>;
};

export type ListFilterInputTypeOfRevisionRecordFilterInput = {
  all?: InputMaybe<RevisionRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RevisionRecordFilterInput>;
  some?: InputMaybe<RevisionRecordFilterInput>;
};

export type ListFilterInputTypeOfRevisionRequestFilterInput = {
  all?: InputMaybe<RevisionRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RevisionRequestFilterInput>;
  some?: InputMaybe<RevisionRequestFilterInput>;
};

export type ListFilterInputTypeOfRvAdInstanceFilterInput = {
  all?: InputMaybe<RvAdInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RvAdInstanceFilterInput>;
  some?: InputMaybe<RvAdInstanceFilterInput>;
};

export type ListFilterInputTypeOfRvresultDetailFilterInput = {
  all?: InputMaybe<RvresultDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RvresultDetailFilterInput>;
  some?: InputMaybe<RvresultDetailFilterInput>;
};

export type ListFilterInputTypeOfRvresultFilterInput = {
  all?: InputMaybe<RvresultFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RvresultFilterInput>;
  some?: InputMaybe<RvresultFilterInput>;
};

export type ListFilterInputTypeOfScheduleAttributeFilterInput = {
  all?: InputMaybe<ScheduleAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScheduleAttributeFilterInput>;
  some?: InputMaybe<ScheduleAttributeFilterInput>;
};

export type ListFilterInputTypeOfScheduleCostAdjustmentFilterInput = {
  all?: InputMaybe<ScheduleCostAdjustmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScheduleCostAdjustmentFilterInput>;
  some?: InputMaybe<ScheduleCostAdjustmentFilterInput>;
};

export type ListFilterInputTypeOfScheduleFileImportFilterInput = {
  all?: InputMaybe<ScheduleFileImportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScheduleFileImportFilterInput>;
  some?: InputMaybe<ScheduleFileImportFilterInput>;
};

export type ListFilterInputTypeOfScheduleFileReferenceFilterInput = {
  all?: InputMaybe<ScheduleFileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScheduleFileReferenceFilterInput>;
  some?: InputMaybe<ScheduleFileReferenceFilterInput>;
};

export type ListFilterInputTypeOfScheduleFilterInput = {
  all?: InputMaybe<ScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScheduleFilterInput>;
  some?: InputMaybe<ScheduleFilterInput>;
};

export type ListFilterInputTypeOfScheduleStatusStateFilterInput = {
  all?: InputMaybe<ScheduleStatusStateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScheduleStatusStateFilterInput>;
  some?: InputMaybe<ScheduleStatusStateFilterInput>;
};

export type ListFilterInputTypeOfScriptAdFilterInput = {
  all?: InputMaybe<ScriptAdFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScriptAdFilterInput>;
  some?: InputMaybe<ScriptAdFilterInput>;
};

export type ListFilterInputTypeOfScriptFilterInput = {
  all?: InputMaybe<ScriptFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScriptFilterInput>;
  some?: InputMaybe<ScriptFilterInput>;
};

export type ListFilterInputTypeOfScriptGroupFilterInput = {
  all?: InputMaybe<ScriptGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScriptGroupFilterInput>;
  some?: InputMaybe<ScriptGroupFilterInput>;
};

export type ListFilterInputTypeOfScriptGroupScriptFilterInput = {
  all?: InputMaybe<ScriptGroupScriptFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScriptGroupScriptFilterInput>;
  some?: InputMaybe<ScriptGroupScriptFilterInput>;
};

export type ListFilterInputTypeOfScriptSkuFilterInput = {
  all?: InputMaybe<ScriptSkuFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ScriptSkuFilterInput>;
  some?: InputMaybe<ScriptSkuFilterInput>;
};

export type ListFilterInputTypeOfSkuFilterInput = {
  all?: InputMaybe<SkuFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SkuFilterInput>;
  some?: InputMaybe<SkuFilterInput>;
};

export type ListFilterInputTypeOfSkugroupFilterInput = {
  all?: InputMaybe<SkugroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SkugroupFilterInput>;
  some?: InputMaybe<SkugroupFilterInput>;
};

export type ListFilterInputTypeOfSkugroupSkuFilterInput = {
  all?: InputMaybe<SkugroupSkuFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SkugroupSkuFilterInput>;
  some?: InputMaybe<SkugroupSkuFilterInput>;
};

export type ListFilterInputTypeOfSkupriceFilterInput = {
  all?: InputMaybe<SkupriceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SkupriceFilterInput>;
  some?: InputMaybe<SkupriceFilterInput>;
};

export type ListFilterInputTypeOfSnapshotFilterInput = {
  all?: InputMaybe<SnapshotFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SnapshotFilterInput>;
  some?: InputMaybe<SnapshotFilterInput>;
};

export type ListFilterInputTypeOfSnapshotScheduleWithResultsAndSaleFilterInput = {
  all?: InputMaybe<SnapshotScheduleWithResultsAndSaleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SnapshotScheduleWithResultsAndSaleFilterInput>;
  some?: InputMaybe<SnapshotScheduleWithResultsAndSaleFilterInput>;
};

export type ListFilterInputTypeOfSourcingAlgorithmFilterInput = {
  all?: InputMaybe<SourcingAlgorithmFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SourcingAlgorithmFilterInput>;
  some?: InputMaybe<SourcingAlgorithmFilterInput>;
};

export type ListFilterInputTypeOfSpikeBaseLineFilterInput = {
  all?: InputMaybe<SpikeBaseLineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SpikeBaseLineFilterInput>;
  some?: InputMaybe<SpikeBaseLineFilterInput>;
};

export type ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput = {
  all?: InputMaybe<StandardsAndPracticesApprovalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StandardsAndPracticesApprovalFilterInput>;
  some?: InputMaybe<StandardsAndPracticesApprovalFilterInput>;
};

export type ListFilterInputTypeOfSurveyFilterInput = {
  all?: InputMaybe<SurveyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SurveyFilterInput>;
  some?: InputMaybe<SurveyFilterInput>;
};

export type ListFilterInputTypeOfSurveyQuestionAnswerFilterInput = {
  all?: InputMaybe<SurveyQuestionAnswerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SurveyQuestionAnswerFilterInput>;
  some?: InputMaybe<SurveyQuestionAnswerFilterInput>;
};

export type ListFilterInputTypeOfSurveyQuestionFilterInput = {
  all?: InputMaybe<SurveyQuestionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SurveyQuestionFilterInput>;
  some?: InputMaybe<SurveyQuestionFilterInput>;
};

export type ListFilterInputTypeOfSurveyResponseDetailFilterInput = {
  all?: InputMaybe<SurveyResponseDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SurveyResponseDetailFilterInput>;
  some?: InputMaybe<SurveyResponseDetailFilterInput>;
};

export type ListFilterInputTypeOfSurveyResponseFilterInput = {
  all?: InputMaybe<SurveyResponseFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SurveyResponseFilterInput>;
  some?: InputMaybe<SurveyResponseFilterInput>;
};

export type ListFilterInputTypeOfSurveySurveyQuestionFilterInput = {
  all?: InputMaybe<SurveySurveyQuestionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SurveySurveyQuestionFilterInput>;
  some?: InputMaybe<SurveySurveyQuestionFilterInput>;
};

export type ListFilterInputTypeOfTacticInfoFilterInput = {
  all?: InputMaybe<TacticInfoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TacticInfoFilterInput>;
  some?: InputMaybe<TacticInfoFilterInput>;
};

export type ListFilterInputTypeOfTargetFilterInput = {
  all?: InputMaybe<TargetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TargetFilterInput>;
  some?: InputMaybe<TargetFilterInput>;
};

export type ListFilterInputTypeOfTaskAssociatedRecordFilterInput = {
  all?: InputMaybe<TaskAssociatedRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TaskAssociatedRecordFilterInput>;
  some?: InputMaybe<TaskAssociatedRecordFilterInput>;
};

export type ListFilterInputTypeOfTaskFilterInput = {
  all?: InputMaybe<TaskFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TaskFilterInput>;
  some?: InputMaybe<TaskFilterInput>;
};

export type ListFilterInputTypeOfTaskHistoryFileReferenceFilterInput = {
  all?: InputMaybe<TaskHistoryFileReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TaskHistoryFileReferenceFilterInput>;
  some?: InputMaybe<TaskHistoryFileReferenceFilterInput>;
};

export type ListFilterInputTypeOfTaskHistoryFilterInput = {
  all?: InputMaybe<TaskHistoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TaskHistoryFilterInput>;
  some?: InputMaybe<TaskHistoryFilterInput>;
};

export type ListFilterInputTypeOfTaskTypeStatusAvailFilterInput = {
  all?: InputMaybe<TaskTypeStatusAvailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TaskTypeStatusAvailFilterInput>;
  some?: InputMaybe<TaskTypeStatusAvailFilterInput>;
};

export type ListFilterInputTypeOfTitleFilterInput = {
  all?: InputMaybe<TitleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TitleFilterInput>;
  some?: InputMaybe<TitleFilterInput>;
};

export type ListFilterInputTypeOfTmpAccountingBatchFilterInput = {
  all?: InputMaybe<TmpAccountingBatchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TmpAccountingBatchFilterInput>;
  some?: InputMaybe<TmpAccountingBatchFilterInput>;
};

export type ListFilterInputTypeOfTmpAccountingBatchLineLinkFilterInput = {
  all?: InputMaybe<TmpAccountingBatchLineLinkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TmpAccountingBatchLineLinkFilterInput>;
  some?: InputMaybe<TmpAccountingBatchLineLinkFilterInput>;
};

export type ListFilterInputTypeOfTmpAccountingLineFilterInput = {
  all?: InputMaybe<TmpAccountingLineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TmpAccountingLineFilterInput>;
  some?: InputMaybe<TmpAccountingLineFilterInput>;
};

export type ListFilterInputTypeOfTmpInvoiceFilterInput = {
  all?: InputMaybe<TmpInvoiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TmpInvoiceFilterInput>;
  some?: InputMaybe<TmpInvoiceFilterInput>;
};

export type ListFilterInputTypeOfTopLineMediaPlanDetailAttributeFilterInput = {
  all?: InputMaybe<TopLineMediaPlanDetailAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TopLineMediaPlanDetailAttributeFilterInput>;
  some?: InputMaybe<TopLineMediaPlanDetailAttributeFilterInput>;
};

export type ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput = {
  all?: InputMaybe<TopLineMediaPlanDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TopLineMediaPlanDetailFilterInput>;
  some?: InputMaybe<TopLineMediaPlanDetailFilterInput>;
};

export type ListFilterInputTypeOfTopLineMediaPlanFilterInput = {
  all?: InputMaybe<TopLineMediaPlanFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TopLineMediaPlanFilterInput>;
  some?: InputMaybe<TopLineMediaPlanFilterInput>;
};

export type ListFilterInputTypeOfTrackingCodeAvailFilterInput = {
  all?: InputMaybe<TrackingCodeAvailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrackingCodeAvailFilterInput>;
  some?: InputMaybe<TrackingCodeAvailFilterInput>;
};

export type ListFilterInputTypeOfTrackingCodeAvailSetupFilterInput = {
  all?: InputMaybe<TrackingCodeAvailSetupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrackingCodeAvailSetupFilterInput>;
  some?: InputMaybe<TrackingCodeAvailSetupFilterInput>;
};

export type ListFilterInputTypeOfTrackingCodeAvailTrackingCodeFilterInput = {
  all?: InputMaybe<TrackingCodeAvailTrackingCodeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrackingCodeAvailTrackingCodeFilterInput>;
  some?: InputMaybe<TrackingCodeAvailTrackingCodeFilterInput>;
};

export type ListFilterInputTypeOfTrackingCodeFilterInput = {
  all?: InputMaybe<TrackingCodeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrackingCodeFilterInput>;
  some?: InputMaybe<TrackingCodeFilterInput>;
};

export type ListFilterInputTypeOfTrackingCodeImportFilterInput = {
  all?: InputMaybe<TrackingCodeImportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrackingCodeImportFilterInput>;
  some?: InputMaybe<TrackingCodeImportFilterInput>;
};

export type ListFilterInputTypeOfTrackingCodeMapFilterInput = {
  all?: InputMaybe<TrackingCodeMapFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrackingCodeMapFilterInput>;
  some?: InputMaybe<TrackingCodeMapFilterInput>;
};

export type ListFilterInputTypeOfTrackingServiceLineFilterInput = {
  all?: InputMaybe<TrackingServiceLineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrackingServiceLineFilterInput>;
  some?: InputMaybe<TrackingServiceLineFilterInput>;
};

export type ListFilterInputTypeOfTrafficSheetDetailDeviceFilterInput = {
  all?: InputMaybe<TrafficSheetDetailDeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrafficSheetDetailDeviceFilterInput>;
  some?: InputMaybe<TrafficSheetDetailDeviceFilterInput>;
};

export type ListFilterInputTypeOfTrafficSheetDetailFilterInput = {
  all?: InputMaybe<TrafficSheetDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrafficSheetDetailFilterInput>;
  some?: InputMaybe<TrafficSheetDetailFilterInput>;
};

export type ListFilterInputTypeOfTrafficSheetDetail_ForLookupFilterInput = {
  all?: InputMaybe<TrafficSheetDetail_ForLookupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrafficSheetDetail_ForLookupFilterInput>;
  some?: InputMaybe<TrafficSheetDetail_ForLookupFilterInput>;
};

export type ListFilterInputTypeOfTrafficSheetFilterInput = {
  all?: InputMaybe<TrafficSheetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TrafficSheetFilterInput>;
  some?: InputMaybe<TrafficSheetFilterInput>;
};

export type ListFilterInputTypeOfUniqueAdFilterInput = {
  all?: InputMaybe<UniqueAdFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UniqueAdFilterInput>;
  some?: InputMaybe<UniqueAdFilterInput>;
};

export type ListFilterInputTypeOfUniqueAdScheduleFilterInput = {
  all?: InputMaybe<UniqueAdScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UniqueAdScheduleFilterInput>;
  some?: InputMaybe<UniqueAdScheduleFilterInput>;
};

export type ListFilterInputTypeOfUniqueAdScheduleLeadAdjustmentFilterInput = {
  all?: InputMaybe<UniqueAdScheduleLeadAdjustmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UniqueAdScheduleLeadAdjustmentFilterInput>;
  some?: InputMaybe<UniqueAdScheduleLeadAdjustmentFilterInput>;
};

export type ListFilterInputTypeOfUniqueAdTrackingCodeFilterInput = {
  all?: InputMaybe<UniqueAdTrackingCodeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UniqueAdTrackingCodeFilterInput>;
  some?: InputMaybe<UniqueAdTrackingCodeFilterInput>;
};

export type ListFilterInputTypeOfVendorBlackoutDateFilterInput = {
  all?: InputMaybe<VendorBlackoutDateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorBlackoutDateFilterInput>;
  some?: InputMaybe<VendorBlackoutDateFilterInput>;
};

export type ListFilterInputTypeOfVendorContactRoleFilterInput = {
  all?: InputMaybe<VendorContactRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorContactRoleFilterInput>;
  some?: InputMaybe<VendorContactRoleFilterInput>;
};

export type ListFilterInputTypeOfVendorDirectPaymentFilterInput = {
  all?: InputMaybe<VendorDirectPaymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorDirectPaymentFilterInput>;
  some?: InputMaybe<VendorDirectPaymentFilterInput>;
};

export type ListFilterInputTypeOfVendorFilterInput = {
  all?: InputMaybe<VendorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorFilterInput>;
  some?: InputMaybe<VendorFilterInput>;
};

export type ListFilterInputTypeOfVendorLookupFilterInput = {
  all?: InputMaybe<VendorLookupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorLookupFilterInput>;
  some?: InputMaybe<VendorLookupFilterInput>;
};

export type ListFilterInputTypeOfVendorServiceCostFilterInput = {
  all?: InputMaybe<VendorServiceCostFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorServiceCostFilterInput>;
  some?: InputMaybe<VendorServiceCostFilterInput>;
};

export type ListFilterInputTypeOfVendorServiceFilterInput = {
  all?: InputMaybe<VendorServiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorServiceFilterInput>;
  some?: InputMaybe<VendorServiceFilterInput>;
};

export type ListFilterInputTypeOfVendorServiceMediaTypeFilterInput = {
  all?: InputMaybe<VendorServiceMediaTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VendorServiceMediaTypeFilterInput>;
  some?: InputMaybe<VendorServiceMediaTypeFilterInput>;
};

export type ListFilterInputTypeOfViewSourceFilterInput = {
  all?: InputMaybe<ViewSourceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ViewSourceFilterInput>;
  some?: InputMaybe<ViewSourceFilterInput>;
};

export type ListFilterInputTypeOfWaveCastAdInstanceFilterInput = {
  all?: InputMaybe<WaveCastAdInstanceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WaveCastAdInstanceFilterInput>;
  some?: InputMaybe<WaveCastAdInstanceFilterInput>;
};

export type ListFilterInputTypeOfWaveCastDetailFilterInput = {
  all?: InputMaybe<WaveCastDetailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WaveCastDetailFilterInput>;
  some?: InputMaybe<WaveCastDetailFilterInput>;
};

export type ListFilterInputTypeOfWaveCastMappingFilterInput = {
  all?: InputMaybe<WaveCastMappingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WaveCastMappingFilterInput>;
  some?: InputMaybe<WaveCastMappingFilterInput>;
};

export type ListFilterInputTypeOfWaveCastModelFilterInput = {
  all?: InputMaybe<WaveCastModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WaveCastModelFilterInput>;
  some?: InputMaybe<WaveCastModelFilterInput>;
};

export type ListFilterInputTypeOfWaveCastModelSkugroupFilterInput = {
  all?: InputMaybe<WaveCastModelSkugroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WaveCastModelSkugroupFilterInput>;
  some?: InputMaybe<WaveCastModelSkugroupFilterInput>;
};

export type ListFilterInputTypeOfWaveCastWaveFilterInput = {
  all?: InputMaybe<WaveCastWaveFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WaveCastWaveFilterInput>;
  some?: InputMaybe<WaveCastWaveFilterInput>;
};

export type LocalMarketCostPer = {
  __typename?: 'LocalMarketCostPer';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  cpp: Scalars['Int'];
  daypart?: Maybe<Daypart>;
  daypartId: Scalars['Int'];
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketCostPerId: Scalars['Int'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId: Scalars['Int'];
  quarter: Scalars['Int'];
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
  sqadlevelLookup?: Maybe<Lookup>;
  sqadlevelLookupId: Scalars['Int'];
  year: Scalars['Int'];
};

export type LocalMarketCostPerFilterInput = {
  and?: InputMaybe<Array<LocalMarketCostPerFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  cpp?: InputMaybe<IntOperationFilterInput>;
  daypart?: InputMaybe<DaypartFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketCostPerId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LocalMarketCostPerFilterInput>>;
  quarter?: InputMaybe<IntOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  sqadlevelLookup?: InputMaybe<LookupFilterInput>;
  sqadlevelLookupId?: InputMaybe<IntOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type LocalMarketRatingEstimate = {
  __typename?: 'LocalMarketRatingEstimate';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketRatingEstimateAdLengths?: Maybe<Array<Maybe<LocalMarketRatingEstimateAdLength>>>;
  localMarketRatingEstimateId: Scalars['Int'];
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  name?: Maybe<Scalars['String']>;
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId: Scalars['Int'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
};

export type LocalMarketRatingEstimateAdLength = {
  __typename?: 'LocalMarketRatingEstimateAdLength';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketRatingEstimate?: Maybe<LocalMarketRatingEstimate>;
  localMarketRatingEstimateAdLengthId: Scalars['Int'];
  localMarketRatingEstimateId: Scalars['Int'];
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId: Scalars['Int'];
};

export type LocalMarketRatingEstimateAdLengthFilterInput = {
  and?: InputMaybe<Array<LocalMarketRatingEstimateAdLengthFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketRatingEstimate?: InputMaybe<LocalMarketRatingEstimateFilterInput>;
  localMarketRatingEstimateAdLengthId?: InputMaybe<IntOperationFilterInput>;
  localMarketRatingEstimateId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LocalMarketRatingEstimateAdLengthFilterInput>>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type LocalMarketRatingEstimateDetail = {
  __typename?: 'LocalMarketRatingEstimateDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  daypart?: Maybe<Daypart>;
  daypartId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketRatingEstimateDetailId: Scalars['Int'];
  localMarketRatingEstimateMarket?: Maybe<LocalMarketRatingEstimateMarket>;
  localMarketRatingEstimateMarketId: Scalars['Int'];
  splitPercent?: Maybe<Scalars['Decimal']>;
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId: Scalars['Int'];
};

export type LocalMarketRatingEstimateDetailFilterInput = {
  and?: InputMaybe<Array<LocalMarketRatingEstimateDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  daypart?: InputMaybe<DaypartFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketRatingEstimateDetailId?: InputMaybe<IntOperationFilterInput>;
  localMarketRatingEstimateMarket?: InputMaybe<LocalMarketRatingEstimateMarketFilterInput>;
  localMarketRatingEstimateMarketId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LocalMarketRatingEstimateDetailFilterInput>>;
  splitPercent?: InputMaybe<DecimalOperationFilterInput>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type LocalMarketRatingEstimateFilterInput = {
  and?: InputMaybe<Array<LocalMarketRatingEstimateFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketRatingEstimateAdLengths?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateAdLengthFilterInput>;
  localMarketRatingEstimateId?: InputMaybe<IntOperationFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LocalMarketRatingEstimateFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
};

export type LocalMarketRatingEstimateMarket = {
  __typename?: 'LocalMarketRatingEstimateMarket';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dataSetQuarter: Scalars['Int'];
  dataSetYear: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketRatingEstimate?: Maybe<LocalMarketRatingEstimate>;
  localMarketRatingEstimateDetails?: Maybe<Array<Maybe<LocalMarketRatingEstimateDetail>>>;
  localMarketRatingEstimateId: Scalars['Int'];
  localMarketRatingEstimateMarketGoals?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarketGoal>>>;
  localMarketRatingEstimateMarketId: Scalars['Int'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  ratingGoal: Scalars['Decimal'];
  ratingMultiplier?: Maybe<Scalars['Decimal']>;
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
  sqadlevelLookup?: Maybe<Lookup>;
  sqadlevelLookupId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
};

export type LocalMarketRatingEstimateMarketFilterInput = {
  and?: InputMaybe<Array<LocalMarketRatingEstimateMarketFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dataSetQuarter?: InputMaybe<IntOperationFilterInput>;
  dataSetYear?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketRatingEstimate?: InputMaybe<LocalMarketRatingEstimateFilterInput>;
  localMarketRatingEstimateDetails?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateDetailFilterInput>;
  localMarketRatingEstimateId?: InputMaybe<IntOperationFilterInput>;
  localMarketRatingEstimateMarketGoals?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketGoalFilterInput>;
  localMarketRatingEstimateMarketId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LocalMarketRatingEstimateMarketFilterInput>>;
  ratingGoal?: InputMaybe<DecimalOperationFilterInput>;
  ratingMultiplier?: InputMaybe<DecimalOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  sqadlevelLookup?: InputMaybe<LookupFilterInput>;
  sqadlevelLookupId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LocalMarketRatingEstimateMarketGoal = {
  __typename?: 'LocalMarketRatingEstimateMarketGoal';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketRatingEstimateMarket?: Maybe<LocalMarketRatingEstimateMarket>;
  localMarketRatingEstimateMarketGoalId: Scalars['Int'];
  localMarketRatingEstimateMarketId: Scalars['Int'];
  ratingGoal: Scalars['Decimal'];
  startDate: Scalars['Date'];
};

export type LocalMarketRatingEstimateMarketGoalFilterInput = {
  and?: InputMaybe<Array<LocalMarketRatingEstimateMarketGoalFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketRatingEstimateMarket?: InputMaybe<LocalMarketRatingEstimateMarketFilterInput>;
  localMarketRatingEstimateMarketGoalId?: InputMaybe<IntOperationFilterInput>;
  localMarketRatingEstimateMarketId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LocalMarketRatingEstimateMarketGoalFilterInput>>;
  ratingGoal?: InputMaybe<DecimalOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type LockProjectEstimateDepartmentError = ArgumentError | RainError;

export type LockProjectEstimateDepartmentInput = {
  projectEstimateDepartmentId: Scalars['Int'];
};

export type LockProjectEstimateDepartmentPayload = {
  __typename?: 'LockProjectEstimateDepartmentPayload';
  errors?: Maybe<Array<LockProjectEstimateDepartmentError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type LogFileLine = {
  __typename?: 'LogFileLine';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  adInstance?: Maybe<AdInstance>;
  adInstanceDetail?: Maybe<AdInstanceDetail>;
  adInstanceDetailId?: Maybe<Scalars['Int']>;
  adInstanceId?: Maybe<Scalars['Int']>;
  adLength?: Maybe<Scalars['String']>;
  adName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileImport?: Maybe<FileImport>;
  fileImportId?: Maybe<Scalars['Int']>;
  fullLine?: Maybe<Scalars['String']>;
  importMessage?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['Int']>;
  isci?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLineId: Scalars['Long'];
  matchType?: Maybe<Scalars['String']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  networkMediaOutlet?: Maybe<MediaOutlet>;
  networkMediaOutletId?: Maybe<Scalars['Int']>;
  networkMediaOutletName?: Maybe<Scalars['String']>;
  overClearanceIgnored: Scalars['Boolean'];
  prelog?: Maybe<Prelog>;
  prelogId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  schedule?: Maybe<Schedule>;
  scheduleId?: Maybe<Scalars['Int']>;
  spotDate?: Maybe<Scalars['Date']>;
  spotTime?: Maybe<Scalars['TimeSpan']>;
  stationName?: Maybe<Scalars['String']>;
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId?: Maybe<Scalars['Int']>;
  tfn?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeId?: Maybe<Scalars['Int']>;
  uniqueAd?: Maybe<UniqueAd>;
  uniqueAdId?: Maybe<Scalars['Int']>;
  uniqueAdSchedule?: Maybe<UniqueAdSchedule>;
  uniqueAdScheduleId?: Maybe<Scalars['Int']>;
  utc?: Maybe<Scalars['DateTime']>;
};

export type LogFileLineFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  adInstance?: InputMaybe<AdInstanceFilterInput>;
  adInstanceDetail?: InputMaybe<AdInstanceDetailFilterInput>;
  adInstanceDetailId?: InputMaybe<IntOperationFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  adLength?: InputMaybe<StringOperationFilterInput>;
  adName?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<LogFileLineFilterInput>>;
  attributes?: InputMaybe<StringOperationFilterInput>;
  campaignName?: InputMaybe<StringOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  fullLine?: InputMaybe<StringOperationFilterInput>;
  importMessage?: InputMaybe<StringOperationFilterInput>;
  invoice?: InputMaybe<InvoiceFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  isci?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLineId?: InputMaybe<LongOperationFilterInput>;
  matchType?: InputMaybe<StringOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  networkMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  networkMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  networkMediaOutletName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LogFileLineFilterInput>>;
  overClearanceIgnored?: InputMaybe<BooleanOperationFilterInput>;
  prelog?: InputMaybe<PrelogFilterInput>;
  prelogId?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  spotDate?: InputMaybe<DateOperationFilterInput>;
  spotTime?: InputMaybe<TimeSpanOperationFilterInput>;
  stationName?: InputMaybe<StringOperationFilterInput>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
  tfn?: InputMaybe<StringOperationFilterInput>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
  uniqueAd?: InputMaybe<UniqueAdFilterInput>;
  uniqueAdId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdSchedule?: InputMaybe<UniqueAdScheduleFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LogType = {
  __typename?: 'LogType';
  active?: Maybe<Scalars['Boolean']>;
  fileToProcesses?: Maybe<Array<Maybe<FileToProcess>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logType1?: Maybe<Scalars['String']>;
  logTypeId: Scalars['Int'];
  mediaOutletParsers?: Maybe<Array<Maybe<MediaOutletParser>>>;
};

export type LogTypeFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<LogTypeFilterInput>>;
  fileToProcesses?: InputMaybe<ListFilterInputTypeOfFileToProcessFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logType1?: InputMaybe<StringOperationFilterInput>;
  logTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletParsers?: InputMaybe<ListFilterInputTypeOfMediaOutletParserFilterInput>;
  or?: InputMaybe<Array<LogTypeFilterInput>>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']>;
  gt?: InputMaybe<Scalars['Long']>;
  gte?: InputMaybe<Scalars['Long']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  lt?: InputMaybe<Scalars['Long']>;
  lte?: InputMaybe<Scalars['Long']>;
  neq?: InputMaybe<Scalars['Long']>;
  ngt?: InputMaybe<Scalars['Long']>;
  ngte?: InputMaybe<Scalars['Long']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  nlt?: InputMaybe<Scalars['Long']>;
  nlte?: InputMaybe<Scalars['Long']>;
};

export type Lookup = {
  __typename?: 'Lookup';
  accountingBatchLineLinks?: Maybe<Array<Maybe<AccountingBatchLineLink>>>;
  accountingBatches?: Maybe<Array<Maybe<AccountingBatch>>>;
  accountingLineBuyTypeNavigations?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLineMonthDefinitionLookups?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLineSubTypeLookups?: Maybe<Array<Maybe<AccountingLine>>>;
  adAgencyLookups?: Maybe<Array<Maybe<Ad>>>;
  adAxtypeLookups?: Maybe<Array<Maybe<Ad>>>;
  adCampaignStatusLookups?: Maybe<Array<Maybe<Ad>>>;
  adCreativeAgencyLookups?: Maybe<Array<Maybe<Ad>>>;
  adInstanceImportDateDefinitionLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportRateTypeLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportRatingImportModeLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportTargetDemos?: Maybe<Array<Maybe<AdInstanceImportTargetDemo>>>;
  adInstances?: Maybe<Array<Maybe<AdInstance>>>;
  adLanguageLookups?: Maybe<Array<Maybe<Ad>>>;
  adMailerFormatLookups?: Maybe<Array<Maybe<Ad>>>;
  adOfferTypeLookups?: Maybe<Array<Maybe<Ad>>>;
  adTapeLengthLookups?: Maybe<Array<Maybe<Ad>>>;
  adVeilEncodingLookups?: Maybe<Array<Maybe<Ad>>>;
  appFieldAggregateFormatLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldAggregateTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldDataTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldDefinitionTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldFormatLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldTempTableConsolidateTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFilterOperators?: Maybe<Array<Maybe<AppFilterOperator>>>;
  appFilters?: Maybe<Array<Maybe<AppFilter>>>;
  appLogEntries?: Maybe<Array<Maybe<AppLogEntry>>>;
  appScreenInitialPositionLookups?: Maybe<Array<Maybe<AppScreen>>>;
  appScreenTabTypeLookups?: Maybe<Array<Maybe<AppScreen>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserRoles?: Maybe<Array<Maybe<AppUserRole>>>;
  appUserSettings?: Maybe<Array<Maybe<AppUserSetting>>>;
  appViewFields?: Maybe<Array<Maybe<AppViewField>>>;
  appViews?: Maybe<Array<Maybe<AppView>>>;
  autoReportItems?: Maybe<Array<Maybe<AutoReportItem>>>;
  autoScheduleBuyTypeLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleInstanceCreationLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoSchedulePibasedOnLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleRateTypeLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleStatusLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleTrafficMediaChanges?: Maybe<Array<Maybe<AutoScheduleTrafficMediaChange>>>;
  autoScheduleTraffics?: Maybe<Array<Maybe<AutoScheduleTraffic>>>;
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  availProposalDetailAttributes?: Maybe<Array<Maybe<AvailProposalDetailAttribute>>>;
  availProposalDetailRateTypeLookups?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  availProposalDetailTapeLengthLookups?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  budgetAllocationBuyTypeLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationCostMethodLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationFlights?: Maybe<Array<Maybe<BudgetAllocationFlight>>>;
  budgetAllocationProgrammaticExecutionLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationStrategyLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationTacticLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationTags?: Maybe<Array<Maybe<BudgetAllocationTag>>>;
  budgetBuyerSplits?: Maybe<Array<Maybe<BudgetBuyerSplit>>>;
  budgetRegionTypeSplits?: Maybe<Array<Maybe<BudgetRegionTypeSplit>>>;
  campaignAppliesToLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignAxtypeLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignBudgetEnforcementLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignClientApprovalRequirementLookups?: Maybe<Array<Maybe<Campaign>>>;
  clientBonusGroupLookups?: Maybe<Array<Maybe<Client>>>;
  clientFileReferences?: Maybe<Array<Maybe<ClientFileReference>>>;
  clientLookupAliases?: Maybe<Array<Maybe<ClientLookupAlias>>>;
  clientMediaOutletClientMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletClientReducedAudienceFeedMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletReducedAudienceFeedMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMonthDefinitionOfflineMonthDefinitionLookups?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientMonthDefinitionOnlineMonthDefinitionLookups?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientPaymentEnforcementLookups?: Maybe<Array<Maybe<Client>>>;
  commissionSlidingScaleCalendarTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleCommissionBasedOnRateTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleCommissionContingencyActionTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleContingencyDateUnitTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleResetConditionLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleThresholdBasedOnRateTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contactTypeContactTypeGroupLookups?: Maybe<Array<Maybe<ContactType>>>;
  contactTypeVendorTypeLookups?: Maybe<Array<Maybe<ContactType>>>;
  contractTermDayTypeLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsBillingTypeLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsCadenceLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsServiceLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  crossChannelCampaignDimensions?: Maybe<Array<Maybe<CrossChannelCampaignDimension>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  crossChannelCampaignVersions?: Maybe<Array<Maybe<CrossChannelCampaignVersion>>>;
  curveCurveSchemaLookups?: Maybe<Array<Maybe<Curve>>>;
  curveTapeLengthLookups?: Maybe<Array<Maybe<Curve>>>;
  dayparts?: Maybe<Array<Maybe<Daypart>>>;
  digitalDeliveryServiceAttributes?: Maybe<Array<Maybe<DigitalDeliveryServiceAttribute>>>;
  digitalSpendImportFieldMappings?: Maybe<Array<Maybe<DigitalSpendImportFieldMapping>>>;
  digitalSpendImportValueMappings?: Maybe<Array<Maybe<DigitalSpendImportValueMapping>>>;
  digitalSpendInputLineDigitalBuyTypeLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  digitalSpendInputLineStrategyLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  digitalSpendInputLineTacticLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  display?: Maybe<Scalars['String']>;
  duplicationPresetCodecLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetCodecProfileLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetCodecWrapperLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetFrameRateLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetInterlaceModeLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetVideoHeightLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetVideoWidthLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  fileImportMaps?: Maybe<Array<Maybe<FileImportMap>>>;
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
  fileReferences?: Maybe<Array<Maybe<FileReference>>>;
  files?: Maybe<Array<Maybe<File>>>;
  gareportSkudefinitions?: Maybe<Array<Maybe<GareportSkudefinition>>>;
  gareportTimeGranularityLookups?: Maybe<Array<Maybe<Gareport>>>;
  gareportTrackingCodeTypeLookups?: Maybe<Array<Maybe<Gareport>>>;
  glanceReportDimensions?: Maybe<Array<Maybe<GlanceReportDimension>>>;
  glanceReportElementBoxGlanceReportBoxElementDirectionLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementBoxGlanceReportBoxElementTotalsBasedOnLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementBoxGroupSortDirectionLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementElementTypeLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementHorizontalAlignmentLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementVerticalAlignmentLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportRanges?: Maybe<Array<Maybe<GlanceReportRange>>>;
  inputFieldMappingSchemes?: Maybe<Array<Maybe<InputFieldMappingScheme>>>;
  inputFieldMappings?: Maybe<Array<Maybe<InputFieldMapping>>>;
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  insertionOrderFileReferences?: Maybe<Array<Maybe<InsertionOrderFileReference>>>;
  insertionOrderSpendDisplayUnitLookups?: Maybe<Array<Maybe<InsertionOrder>>>;
  insertionOrderStatusLookups?: Maybe<Array<Maybe<InsertionOrder>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  issueDateInfos?: Maybe<Array<Maybe<IssueDateInfo>>>;
  jobInstanceLogs?: Maybe<Array<Maybe<JobInstanceLog>>>;
  localMarketCostPerNielsenDemoLookups?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketCostPerSqadlevelLookups?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateAdLengths?: Maybe<Array<Maybe<LocalMarketRatingEstimateAdLength>>>;
  localMarketRatingEstimateDetails?: Maybe<Array<Maybe<LocalMarketRatingEstimateDetail>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  localMarketRatingEstimates?: Maybe<Array<Maybe<LocalMarketRatingEstimate>>>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  lookupId: Scalars['Int'];
  lookupType?: Maybe<LookupType>;
  lookupTypeId: Scalars['Int'];
  mediaAuthorizationDimensions?: Maybe<Array<Maybe<MediaAuthorizationDimension>>>;
  mediaAuthorizations?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaAvailRates?: Maybe<Array<Maybe<MediaAvailRate>>>;
  mediaFormatAttributes?: Maybe<Array<Maybe<MediaFormatAttribute>>>;
  mediaOutletAddresses?: Maybe<Array<Maybe<MediaOutletAddress>>>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaOutletDigitalDeliveryTypeToAddressTypeAddressTypeLookups?: Maybe<Array<Maybe<MediaOutletDigitalDeliveryTypeToAddressType>>>;
  mediaOutletDigitalDeliveryTypeToAddressTypeDigitalDeliveryServiceLookups?: Maybe<Array<Maybe<MediaOutletDigitalDeliveryTypeToAddressType>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutletFrameRateSchemeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletLanguageLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletLfviewTapeRequirementLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaClassificationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaOutletAffiliateLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaOutletGenreLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaTypeAccountingNetworkLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeAxtypeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeDigitalDeliveryServices?: Maybe<Array<Maybe<MediaOutletMediaTypeDigitalDeliveryService>>>;
  mediaOutletMediaTypeInvoiceTypeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatHighDefLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatViewTapeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeTermLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeTvhddubRequirementLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeVendorCodes?: Maybe<Array<Maybe<MediaOutletMediaTypeVendorCode>>>;
  mediaOutletMediaTypeViewTapeRequirementLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletPartnerBuyTypeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPiinvoiceCadenceLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPrintFormats?: Maybe<Array<Maybe<MediaOutletPrintFormat>>>;
  mediaOutletPrtfrequencyLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPrtpublicationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletRates?: Maybe<Array<Maybe<MediaOutletRate>>>;
  mediaOutletRdradioFormatLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletRoles?: Maybe<Array<Maybe<MediaOutletRole>>>;
  mediaOutletRotations?: Maybe<Array<Maybe<MediaOutletRotation>>>;
  mediaOutletSfprelogStatusLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTalentFeeClassificationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvclosedCaptionStatusLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvhddubRequirementLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvsupportedMediaTypeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaPlanExportExcelOptions?: Maybe<Array<Maybe<MediaPlanExportExcelOption>>>;
  mediaPlanNielsenDemos?: Maybe<Array<Maybe<MediaPlanNielsenDemo>>>;
  mediaPlanTapeLengths?: Maybe<Array<Maybe<MediaPlanTapeLength>>>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  mediaScheduleDetailAdvancedTargetingDemoLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailAttributes?: Maybe<Array<Maybe<MediaScheduleDetailAttribute>>>;
  mediaScheduleDetailBuyTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPlacementPositionLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtadSizeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtadTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtcolorLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtissueDateLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtlayoutLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtlocationLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtsectionLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailRateTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailReadTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailStatusLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailTapeLengthLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailTrackingServicePaidByLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailWeekPartLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDisplayEstimatedRatingModeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaScheduleDisplayKpidateRangeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaScheduleDisplayRateTypeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTransmissionHdmediaFormatLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaFormatLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaTransmissionStatusLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaTransmissionTypeLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionPrintAdSizeLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionPriorityLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mriindexMridataStabilityLookups?: Maybe<Array<Maybe<Mriindex>>>;
  mriindexMridemoLookups?: Maybe<Array<Maybe<Mriindex>>>;
  mriindexMriindexTypeLookups?: Maybe<Array<Maybe<Mriindex>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementFileReferences?: Maybe<Array<Maybe<NewCampaignAnnouncementFileReference>>>;
  newCampaignAnnouncementTargetMetricLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementTfntrackingCodeAssignmentTypeLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementUrltrackingCodeAssignmentTypeLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementVeilEncodingLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  onlineScheduleAttributeOnlineContentCategoryLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlineCreativeLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlineCreativeSizeLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlinePlacementIdlookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlinePlacementLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  printAvailAdSizeLookups?: Maybe<Array<Maybe<PrintAvail>>>;
  printAvailIssueDateLookups?: Maybe<Array<Maybe<PrintAvail>>>;
  printScheduleAttributePrintAdSizeLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintAdTypeLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintLocationLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintSectionLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  productCategoryHierarchyProductCategoryLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productCategoryHierarchyProductSubCategoryLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productCategoryHierarchyProductTypeLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productMediaTargets?: Maybe<Array<Maybe<ProductMediaTarget>>>;
  productProductCategoryLookups?: Maybe<Array<Maybe<Product>>>;
  productProductSubCategoryLookups?: Maybe<Array<Maybe<Product>>>;
  productTrackingCodeAvailSetupTypeLookups?: Maybe<Array<Maybe<Product>>>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  ratingC3ratingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingNielsenDemoLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingPlus3RatingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingRatingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  regionCurrencyCodeLookups?: Maybe<Array<Maybe<Region>>>;
  regionRegionTypeLookups?: Maybe<Array<Maybe<Region>>>;
  rentrakNetworks?: Maybe<Array<Maybe<RentrakNetwork>>>;
  reportDefaultPresetVisibilityLookups?: Maybe<Array<Maybe<Report>>>;
  reportExportFormatFieldAggregateFormatLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldDataTypeLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFontLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFormatLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFormulaTypeLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldHorizontalAlignmentLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldReportFieldValueModifierLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFontLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatMapBreakpointTypeLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatPageOrientationLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatReportExportFormatTypeLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatStyleEvaluationOperatorTypeLookups?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportExportFormatStyleReportStyleFormatTypeLookups?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportFormats?: Maybe<Array<Maybe<ReportFormat>>>;
  reportPresetFilterRecords?: Maybe<Array<Maybe<ReportPresetFilterRecord>>>;
  reportPresetGroupSorts?: Maybe<Array<Maybe<ReportPresetGroupSort>>>;
  reportTargetLookups?: Maybe<Array<Maybe<Report>>>;
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  scheduleAdvancedTargetingLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleAttributes?: Maybe<Array<Maybe<ScheduleAttribute>>>;
  scheduleBillingMetricLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleBuyTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleDigitalPlacementDescriptionLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleFileReferences?: Maybe<Array<Maybe<ScheduleFileReference>>>;
  scheduleInternalStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleLftribuneNameLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePibasedOnLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePlacementPositionLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtadSizeLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtadTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtcolorLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtissueDateLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtlayoutLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtlocationLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtplacedSectionLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtsectionLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleRateTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleReadTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSfschedulePrelogStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSpendAllocationSchemaLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleStatusStateScheduleStateLookups?: Maybe<Array<Maybe<ScheduleStatusState>>>;
  scheduleStatusStateStatusLookups?: Maybe<Array<Maybe<ScheduleStatusState>>>;
  scheduleStrategyLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleTacticLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleTrackingServicePaidByLookups?: Maybe<Array<Maybe<Schedule>>>;
  scriptAutosourcerAlgorithmLookups?: Maybe<Array<Maybe<Script>>>;
  scriptLiftTypeLookups?: Maybe<Array<Maybe<Script>>>;
  scriptOrderCallCreationLookups?: Maybe<Array<Maybe<Script>>>;
  scriptSignalLinkCurveSchemaLookups?: Maybe<Array<Maybe<Script>>>;
  scriptSpikeNielsenDemoLookups?: Maybe<Array<Maybe<Script>>>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  sourcingAlgorithmAudienceWeightLookups?: Maybe<Array<Maybe<SourcingAlgorithm>>>;
  sourcingAlgorithmSourcingAlgorithmTypeLookups?: Maybe<Array<Maybe<SourcingAlgorithm>>>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
  surveyDateRangeLookups?: Maybe<Array<Maybe<Survey>>>;
  surveyNielsenDemoLookups?: Maybe<Array<Maybe<Survey>>>;
  surveyQuestions?: Maybe<Array<Maybe<SurveyQuestion>>>;
  surveySurveyTypeLookups?: Maybe<Array<Maybe<Survey>>>;
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  targetMridemoLookups?: Maybe<Array<Maybe<Target>>>;
  targetNielsenDemoLookups?: Maybe<Array<Maybe<Target>>>;
  targetTargetTypeLookups?: Maybe<Array<Maybe<Target>>>;
  taskHistories?: Maybe<Array<Maybe<TaskHistory>>>;
  taskPriorityLookups?: Maybe<Array<Maybe<Task>>>;
  taskRoleLookups?: Maybe<Array<Maybe<Task>>>;
  taskTaskSubTypeLookups?: Maybe<Array<Maybe<Task>>>;
  taskTypeStatusAvailTaskStateLookups?: Maybe<Array<Maybe<TaskTypeStatusAvail>>>;
  taskTypeStatusAvailTaskStatusLookups?: Maybe<Array<Maybe<TaskTypeStatusAvail>>>;
  tmpAccountingLineBuyTypeNavigations?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  tmpAccountingLineMonthDefinitionLookups?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  topLineMediaPlanDetailAttributes?: Maybe<Array<Maybe<TopLineMediaPlanDetailAttribute>>>;
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  topLineMediaPlanNielsenDemoLookupId1Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  topLineMediaPlanNielsenDemoLookupId2Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  topLineMediaPlanNielsenDemoLookupId3Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  trackingCodeAvailSetupTrackingCodeAvailSetupTypeLookups?: Maybe<Array<Maybe<TrackingCodeAvailSetup>>>;
  trackingCodeAvailSetupTrackingCodeTypeLookups?: Maybe<Array<Maybe<TrackingCodeAvailSetup>>>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodes?: Maybe<Array<Maybe<TrackingCode>>>;
  trafficSheetDetailCountryLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailDevices?: Maybe<Array<Maybe<TrafficSheetDetailDevice>>>;
  trafficSheetDetailDigitalRegionLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailOperatingSystemLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailProgrammaticExecutionLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailSizeLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailStrategyLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailTacticLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  vendorAddressTypeLookups?: Maybe<Array<Maybe<Vendor>>>;
  vendorBrokerCurrencyCodeLookups?: Maybe<Array<Maybe<Vendor>>>;
  vendorLookups?: Maybe<Array<Maybe<VendorLookup>>>;
  vendorServices?: Maybe<Array<Maybe<VendorService>>>;
  vendorVendorTypeLookups?: Maybe<Array<Maybe<Vendor>>>;
  viewSources?: Maybe<Array<Maybe<ViewSource>>>;
  waveCastDetailActionTypeLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastDetailOverrideBubbleChartDirectiveLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastDetailWaveCastDetailTypeLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
};

export type LookupFilterInput = {
  accountingBatchLineLinks?: InputMaybe<ListFilterInputTypeOfAccountingBatchLineLinkFilterInput>;
  accountingBatches?: InputMaybe<ListFilterInputTypeOfAccountingBatchFilterInput>;
  accountingLineBuyTypeNavigations?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  accountingLineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  accountingLineSubTypeLookups?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  adAgencyLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adAxtypeLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adCampaignStatusLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adCreativeAgencyLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adInstanceImportDateDefinitionLookups?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstanceImportRateTypeLookups?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstanceImportRatingImportModeLookups?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstanceImportTargetDemos?: InputMaybe<ListFilterInputTypeOfAdInstanceImportTargetDemoFilterInput>;
  adInstances?: InputMaybe<ListFilterInputTypeOfAdInstanceFilterInput>;
  adLanguageLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adMailerFormatLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adOfferTypeLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adVeilEncodingLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<LookupFilterInput>>;
  appFieldAggregateFormatLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldAggregateTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldDataTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldDefinitionTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldFormatLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldTempTableConsolidateTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFilterOperators?: InputMaybe<ListFilterInputTypeOfAppFilterOperatorFilterInput>;
  appFilters?: InputMaybe<ListFilterInputTypeOfAppFilterFilterInput>;
  appLogEntries?: InputMaybe<ListFilterInputTypeOfAppLogEntryFilterInput>;
  appScreenInitialPositionLookups?: InputMaybe<ListFilterInputTypeOfAppScreenFilterInput>;
  appScreenTabTypeLookups?: InputMaybe<ListFilterInputTypeOfAppScreenFilterInput>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  appUserRoles?: InputMaybe<ListFilterInputTypeOfAppUserRoleFilterInput>;
  appUserSettings?: InputMaybe<ListFilterInputTypeOfAppUserSettingFilterInput>;
  appViewFields?: InputMaybe<ListFilterInputTypeOfAppViewFieldFilterInput>;
  appViews?: InputMaybe<ListFilterInputTypeOfAppViewFilterInput>;
  autoReportItems?: InputMaybe<ListFilterInputTypeOfAutoReportItemFilterInput>;
  autoScheduleBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleInstanceCreationLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoSchedulePibasedOnLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleRateTypeLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleStatusLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleTrafficMediaChanges?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficMediaChangeFilterInput>;
  autoScheduleTraffics?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficFilterInput>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  availProposalDetailAttributes?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailAttributeFilterInput>;
  availProposalDetailRateTypeLookups?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  availProposalDetailTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  budgetAllocationBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationCostMethodLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationFees?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFeeFilterInput>;
  budgetAllocationFlights?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFlightFilterInput>;
  budgetAllocationProgrammaticExecutionLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationStrategyLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationTacticLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationTags?: InputMaybe<ListFilterInputTypeOfBudgetAllocationTagFilterInput>;
  budgetBuyerSplits?: InputMaybe<ListFilterInputTypeOfBudgetBuyerSplitFilterInput>;
  budgetRegionTypeSplits?: InputMaybe<ListFilterInputTypeOfBudgetRegionTypeSplitFilterInput>;
  campaignAppliesToLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  campaignAxtypeLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  campaignBudgetEnforcementLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  campaignClientApprovalRequirementLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  clientBonusGroupLookups?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  clientFileReferences?: InputMaybe<ListFilterInputTypeOfClientFileReferenceFilterInput>;
  clientLookupAliases?: InputMaybe<ListFilterInputTypeOfClientLookupAliasFilterInput>;
  clientMediaOutletClientMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMediaOutletClientReducedAudienceFeedMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMediaOutletMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMediaOutletReducedAudienceFeedMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMonthDefinitionOfflineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfClientMonthDefinitionFilterInput>;
  clientMonthDefinitionOnlineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfClientMonthDefinitionFilterInput>;
  clientPaymentEnforcementLookups?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  commissionSlidingScaleCalendarTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleCommissionBasedOnRateTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleCommissionContingencyActionTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleContingencyDateUnitTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleResetConditionLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleThresholdBasedOnRateTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contactTypeContactTypeGroupLookups?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  contactTypeVendorTypeLookups?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  contractTermDayTypeLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  contractTermTermsBillingTypeLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  contractTermTermsCadenceLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  contractTermTermsServiceLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  crossChannelCampaignDimensions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignDimensionFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  crossChannelCampaignVersions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignVersionFilterInput>;
  curveCurveSchemaLookups?: InputMaybe<ListFilterInputTypeOfCurveFilterInput>;
  curveTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfCurveFilterInput>;
  dayparts?: InputMaybe<ListFilterInputTypeOfDaypartFilterInput>;
  digitalDeliveryServiceAttributes?: InputMaybe<ListFilterInputTypeOfDigitalDeliveryServiceAttributeFilterInput>;
  digitalSpendImportFieldMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportFieldMappingFilterInput>;
  digitalSpendImportValueMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportValueMappingFilterInput>;
  digitalSpendInputLineDigitalBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  digitalSpendInputLineStrategyLookups?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  digitalSpendInputLineTacticLookups?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  duplicationPresetCodecLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetCodecProfileLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetCodecWrapperLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetFrameRateLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetInterlaceModeLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetVideoHeightLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetVideoWidthLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  fileImportMaps?: InputMaybe<ListFilterInputTypeOfFileImportMapFilterInput>;
  fileImports?: InputMaybe<ListFilterInputTypeOfFileImportFilterInput>;
  fileReferences?: InputMaybe<ListFilterInputTypeOfFileReferenceFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFileFilterInput>;
  gareportSkudefinitions?: InputMaybe<ListFilterInputTypeOfGareportSkudefinitionFilterInput>;
  gareportTimeGranularityLookups?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  gareportTrackingCodeTypeLookups?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  glanceReportDimensions?: InputMaybe<ListFilterInputTypeOfGlanceReportDimensionFilterInput>;
  glanceReportElementBoxGlanceReportBoxElementDirectionLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementBoxGlanceReportBoxElementTotalsBasedOnLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementBoxGroupSortDirectionLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementElementTypeLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementHorizontalAlignmentLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementVerticalAlignmentLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportRanges?: InputMaybe<ListFilterInputTypeOfGlanceReportRangeFilterInput>;
  inputFieldMappingSchemes?: InputMaybe<ListFilterInputTypeOfInputFieldMappingSchemeFilterInput>;
  inputFieldMappings?: InputMaybe<ListFilterInputTypeOfInputFieldMappingFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  insertionOrderFileReferences?: InputMaybe<ListFilterInputTypeOfInsertionOrderFileReferenceFilterInput>;
  insertionOrderSpendDisplayUnitLookups?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  insertionOrderStatusLookups?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  invoices?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  issueDateInfos?: InputMaybe<ListFilterInputTypeOfIssueDateInfoFilterInput>;
  jobInstanceLogs?: InputMaybe<ListFilterInputTypeOfJobInstanceLogFilterInput>;
  localMarketCostPerNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketCostPerSqadlevelLookups?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateAdLengths?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateAdLengthFilterInput>;
  localMarketRatingEstimateDetails?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateDetailFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  localMarketRatingEstimates?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  lookupId?: InputMaybe<IntOperationFilterInput>;
  lookupType?: InputMaybe<LookupTypeFilterInput>;
  lookupTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaAuthorizationDimensions?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationDimensionFilterInput>;
  mediaAuthorizations?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaAvailRates?: InputMaybe<ListFilterInputTypeOfMediaAvailRateFilterInput>;
  mediaFormatAttributes?: InputMaybe<ListFilterInputTypeOfMediaFormatAttributeFilterInput>;
  mediaOutletAddresses?: InputMaybe<ListFilterInputTypeOfMediaOutletAddressFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaOutletDigitalDeliveryTypeToAddressTypeAddressTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>;
  mediaOutletDigitalDeliveryTypeToAddressTypeDigitalDeliveryServiceLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>;
  mediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfMediaOutletFileReferenceFilterInput>;
  mediaOutletFrameRateSchemeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletLanguageLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletLfviewTapeRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaOutletAffiliateLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaOutletGenreLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaTypeAccountingNetworkLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeAxtypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeDigitalDeliveryServices?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeDigitalDeliveryServiceFilterInput>;
  mediaOutletMediaTypeInvoiceTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeMediaFormatHighDefLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeMediaFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeMediaFormatViewTapeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeTermLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeTvhddubRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeVendorCodes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeVendorCodeFilterInput>;
  mediaOutletMediaTypeViewTapeRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletPartnerBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletPiinvoiceCadenceLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletPrintFormats?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintFormatFilterInput>;
  mediaOutletPrtfrequencyLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletPrtpublicationLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletRates?: InputMaybe<ListFilterInputTypeOfMediaOutletRateFilterInput>;
  mediaOutletRdradioFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletRoles?: InputMaybe<ListFilterInputTypeOfMediaOutletRoleFilterInput>;
  mediaOutletRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletRotationFilterInput>;
  mediaOutletSfprelogStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTalentFeeClassificationLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTvclosedCaptionStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTvhddubRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTvsupportedMediaTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaPlanExportExcelOptions?: InputMaybe<ListFilterInputTypeOfMediaPlanExportExcelOptionFilterInput>;
  mediaPlanNielsenDemos?: InputMaybe<ListFilterInputTypeOfMediaPlanNielsenDemoFilterInput>;
  mediaPlanTapeLengths?: InputMaybe<ListFilterInputTypeOfMediaPlanTapeLengthFilterInput>;
  mediaPlans?: InputMaybe<ListFilterInputTypeOfMediaPlanFilterInput>;
  mediaScheduleDetailAdvancedTargetingDemoLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailAttributes?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailAttributeFilterInput>;
  mediaScheduleDetailBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPlacementPositionLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtadSizeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtadTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtcolorLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtissueDateLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtlayoutLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtlocationLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtsectionLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailRateTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailReadTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailTrackingServicePaidByLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailWeekPartLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDisplayEstimatedRatingModeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaScheduleDisplayKpidateRangeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaScheduleDisplayRateTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaTransmissionHdmediaFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionMediaFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionMediaTransmissionStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionMediaTransmissionTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionPrintAdSizeLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionPriorityLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mriindexMridataStabilityLookups?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  mriindexMridemoLookups?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  mriindexMriindexTypeLookups?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementFileReferences?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFileReferenceFilterInput>;
  newCampaignAnnouncementTargetMetricLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementTfntrackingCodeAssignmentTypeLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementUrltrackingCodeAssignmentTypeLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementVeilEncodingLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  notes?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  onlineScheduleAttributeOnlineContentCategoryLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlineCreativeLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlineCreativeSizeLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlinePlacementIdlookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlinePlacementLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  or?: InputMaybe<Array<LookupFilterInput>>;
  perInquiryCosts?: InputMaybe<ListFilterInputTypeOfPerInquiryCostFilterInput>;
  prelogs?: InputMaybe<ListFilterInputTypeOfPrelogFilterInput>;
  printAvailAdSizeLookups?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  printAvailIssueDateLookups?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  printScheduleAttributePrintAdSizeLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  printScheduleAttributePrintAdTypeLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  printScheduleAttributePrintLocationLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  printScheduleAttributePrintSectionLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  productCategoryHierarchyProductCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductCategoryHierarchyFilterInput>;
  productCategoryHierarchyProductSubCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductCategoryHierarchyFilterInput>;
  productCategoryHierarchyProductTypeLookups?: InputMaybe<ListFilterInputTypeOfProductCategoryHierarchyFilterInput>;
  productMediaTargets?: InputMaybe<ListFilterInputTypeOfProductMediaTargetFilterInput>;
  productProductCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  productProductSubCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  productTrackingCodeAvailSetupTypeLookups?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  ratingC3ratingTypeLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  ratingNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  ratingPlus3RatingTypeLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  ratingRatingTypeLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  regionCurrencyCodeLookups?: InputMaybe<ListFilterInputTypeOfRegionFilterInput>;
  regionRegionTypeLookups?: InputMaybe<ListFilterInputTypeOfRegionFilterInput>;
  rentrakNetworks?: InputMaybe<ListFilterInputTypeOfRentrakNetworkFilterInput>;
  reportDefaultPresetVisibilityLookups?: InputMaybe<ListFilterInputTypeOfReportFilterInput>;
  reportExportFormatFieldAggregateFormatLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldDataTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldFontLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldFormatLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldFormulaTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldHorizontalAlignmentLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldReportFieldValueModifierLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFontLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatMapBreakpointTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatPageOrientationLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatReportExportFormatTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatStyleEvaluationOperatorTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatStyleFilterInput>;
  reportExportFormatStyleReportStyleFormatTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatStyleFilterInput>;
  reportFormats?: InputMaybe<ListFilterInputTypeOfReportFormatFilterInput>;
  reportPresetFilterRecords?: InputMaybe<ListFilterInputTypeOfReportPresetFilterRecordFilterInput>;
  reportPresetGroupSorts?: InputMaybe<ListFilterInputTypeOfReportPresetGroupSortFilterInput>;
  reportTargetLookups?: InputMaybe<ListFilterInputTypeOfReportFilterInput>;
  resultStagings?: InputMaybe<ListFilterInputTypeOfResultStagingFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  scheduleAdvancedTargetingLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleAttributes?: InputMaybe<ListFilterInputTypeOfScheduleAttributeFilterInput>;
  scheduleBillingMetricLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleDigitalPlacementDescriptionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFileReferenceFilterInput>;
  scheduleInternalStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleLftribuneNameLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePibasedOnLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePlacementPositionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtadSizeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtadTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtcolorLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtissueDateLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtlayoutLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtlocationLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtplacedSectionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtsectionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleRateTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleReadTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleSfschedulePrelogStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleSpendAllocationSchemaLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleStatusStateScheduleStateLookups?: InputMaybe<ListFilterInputTypeOfScheduleStatusStateFilterInput>;
  scheduleStatusStateStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleStatusStateFilterInput>;
  scheduleStrategyLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleTacticLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleTrackingServicePaidByLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scriptAutosourcerAlgorithmLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptLiftTypeLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptOrderCallCreationLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptSignalLinkCurveSchemaLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptSpikeNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  skus?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  sourcingAlgorithmAudienceWeightLookups?: InputMaybe<ListFilterInputTypeOfSourcingAlgorithmFilterInput>;
  sourcingAlgorithmSourcingAlgorithmTypeLookups?: InputMaybe<ListFilterInputTypeOfSourcingAlgorithmFilterInput>;
  standardsAndPracticesApprovals?: InputMaybe<ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput>;
  surveyDateRangeLookups?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  surveyNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  surveyQuestions?: InputMaybe<ListFilterInputTypeOfSurveyQuestionFilterInput>;
  surveySurveyTypeLookups?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  targetMridemoLookups?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  targetNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  targetTargetTypeLookups?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  taskHistories?: InputMaybe<ListFilterInputTypeOfTaskHistoryFilterInput>;
  taskPriorityLookups?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskRoleLookups?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskTaskSubTypeLookups?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskTypeStatusAvailTaskStateLookups?: InputMaybe<ListFilterInputTypeOfTaskTypeStatusAvailFilterInput>;
  taskTypeStatusAvailTaskStatusLookups?: InputMaybe<ListFilterInputTypeOfTaskTypeStatusAvailFilterInput>;
  tmpAccountingLineBuyTypeNavigations?: InputMaybe<ListFilterInputTypeOfTmpAccountingLineFilterInput>;
  tmpAccountingLineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfTmpAccountingLineFilterInput>;
  topLineMediaPlanDetailAttributes?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailAttributeFilterInput>;
  topLineMediaPlanDetails?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput>;
  topLineMediaPlanNielsenDemoLookupId1Navigations?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  topLineMediaPlanNielsenDemoLookupId2Navigations?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  topLineMediaPlanNielsenDemoLookupId3Navigations?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  trackingCodeAvailSetupTrackingCodeAvailSetupTypeLookups?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailSetupFilterInput>;
  trackingCodeAvailSetupTrackingCodeTypeLookups?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailSetupFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodes?: InputMaybe<ListFilterInputTypeOfTrackingCodeFilterInput>;
  trafficSheetDetailCountryLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailDevices?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailDeviceFilterInput>;
  trafficSheetDetailDigitalRegionLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailOperatingSystemLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailProgrammaticExecutionLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailSizeLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailStrategyLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailTacticLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  vendorAddressTypeLookups?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  vendorBrokerCurrencyCodeLookups?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  vendorLookups?: InputMaybe<ListFilterInputTypeOfVendorLookupFilterInput>;
  vendorServices?: InputMaybe<ListFilterInputTypeOfVendorServiceFilterInput>;
  vendorVendorTypeLookups?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  viewSources?: InputMaybe<ListFilterInputTypeOfViewSourceFilterInput>;
  waveCastDetailActionTypeLookups?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastDetailOverrideBubbleChartDirectiveLookups?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastDetailWaveCastDetailTypeLookups?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
};

export type LookupInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  display?: InputMaybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  lookupId?: InputMaybe<Scalars['Int']>;
  lookupTypeEnum?: InputMaybe<LookupTypeEnum>;
  lookupTypeId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LookupSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  display?: InputMaybe<SortEnumType>;
  lookupId?: InputMaybe<SortEnumType>;
  lookupType?: InputMaybe<LookupTypeSortInput>;
  lookupTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type LookupType = {
  __typename?: 'LookupType';
  digitalSpendImportValueMappings?: Maybe<Array<Maybe<DigitalSpendImportValueMapping>>>;
  lookupIsVisibleToAllCompanies: Scalars['Boolean'];
  lookupTypeId: Scalars['Int'];
  lookups?: Maybe<Array<Maybe<Lookup>>>;
  name?: Maybe<Scalars['String']>;
  userCanAddRecords: Scalars['Boolean'];
  userCanDeleteRecords: Scalars['Boolean'];
};

export enum LookupTypeEnum {
  Accountingbatchtype = 'ACCOUNTINGBATCHTYPE',
  Accountinggroup = 'ACCOUNTINGGROUP',
  Accountingsubtype = 'ACCOUNTINGSUBTYPE',
  Addresstype = 'ADDRESSTYPE',
  Adinstancestatus = 'ADINSTANCESTATUS',
  Adpriority = 'ADPRIORITY',
  AdvancedTargetingDemo = 'ADVANCED_TARGETING_DEMO',
  Affiliate = 'AFFILIATE',
  Agency = 'AGENCY',
  AirCheckStatus = 'AIR_CHECK_STATUS',
  Allocationbasedontype = 'ALLOCATIONBASEDONTYPE',
  Appuserrole = 'APPUSERROLE',
  Appusersetting = 'APPUSERSETTING',
  Audienceweight = 'AUDIENCEWEIGHT',
  Automatedfileprocessingtype = 'AUTOMATEDFILEPROCESSINGTYPE',
  Autoscheduleinstancecreation = 'AUTOSCHEDULEINSTANCECREATION',
  Autosourceralgorithm = 'AUTOSOURCERALGORITHM',
  AutoScheduleTrafficStatus = 'AUTO_SCHEDULE_TRAFFIC_STATUS',
  AvailProposalMediaStatus = 'AVAIL_PROPOSAL_MEDIA_STATUS',
  AvailProposalStatus = 'AVAIL_PROPOSAL_STATUS',
  Axtype = 'AXTYPE',
  Axvendorcodebuytype = 'AXVENDORCODEBUYTYPE',
  AxItemCode = 'AX_ITEM_CODE',
  AxRevRecGroupRowsBy = 'AX_REV_REC_GROUP_ROWS_BY',
  AxServer = 'AX_SERVER',
  BillingMetric = 'BILLING_METRIC',
  BillingType = 'BILLING_TYPE',
  Bonusgroup = 'BONUSGROUP',
  Bookingpaperworkstatus = 'BOOKINGPAPERWORKSTATUS',
  Bookingpaperworktype = 'BOOKINGPAPERWORKTYPE',
  Bookingreporttype = 'BOOKINGREPORTTYPE',
  Borderstyle = 'BORDERSTYLE',
  Budgetappliesto = 'BUDGETAPPLIESTO',
  Budgetenforcement = 'BUDGETENFORCEMENT',
  BudgetAllocationTag = 'BUDGET_ALLOCATION_TAG',
  Buyerbudgetregiontype = 'BUYERBUDGETREGIONTYPE',
  Buytype = 'BUYTYPE',
  BuyTypeCategory = 'BUY_TYPE_CATEGORY',
  Bvsiscimixandmatch = 'BVSISCIMIXANDMATCH',
  Bvssyndicationmixandmatch = 'BVSSYNDICATIONMIXANDMATCH',
  Campaigngroup = 'CAMPAIGNGROUP',
  Campaignstatus = 'CAMPAIGNSTATUS',
  Clientrole = 'CLIENTROLE',
  ClientApprovalRequirement = 'CLIENT_APPROVAL_REQUIREMENT',
  ClientApprovalStatus = 'CLIENT_APPROVAL_STATUS',
  ClientCreditLimitTerm = 'CLIENT_CREDIT_LIMIT_TERM',
  ClientFileReferenceType = 'CLIENT_FILE_REFERENCE_TYPE',
  ClientMediaClassification = 'CLIENT_MEDIA_CLASSIFICATION',
  ClientPaymentEnforcement = 'CLIENT_PAYMENT_ENFORCEMENT',
  Closedcaptionstatus = 'CLOSEDCAPTIONSTATUS',
  Codec = 'CODEC',
  CodecProfile = 'CODEC_PROFILE',
  CodecWrapper = 'CODEC_WRAPPER',
  Color = 'COLOR',
  CommissionBasedOnRateType = 'COMMISSION_BASED_ON_RATE_TYPE',
  CommissionCalendarType = 'COMMISSION_CALENDAR_TYPE',
  CommissionContingencyActionType = 'COMMISSION_CONTINGENCY_ACTION_TYPE',
  CommissionContingencyDateUnitType = 'COMMISSION_CONTINGENCY_DATE_UNIT_TYPE',
  CommissionResetType = 'COMMISSION_RESET_TYPE',
  Consolidatetype = 'CONSOLIDATETYPE',
  Contacttypegroup = 'CONTACTTYPEGROUP',
  ContractType = 'CONTRACT_TYPE',
  Country = 'COUNTRY',
  CountryCode = 'COUNTRY_CODE',
  Creative = 'CREATIVE',
  CreativeAgency = 'CREATIVE_AGENCY',
  CrossChannelCampaignDimension = 'CROSS_CHANNEL_CAMPAIGN_DIMENSION',
  CurrencyCode = 'CURRENCY_CODE',
  DashboardDatabaseName = 'DASHBOARD_DATABASE_NAME',
  DashboardDatabaseNameForDo = 'DASHBOARD_DATABASE_NAME_FOR_DO',
  Databasename = 'DATABASENAME',
  Datasourcetype = 'DATASOURCETYPE',
  Dateoffset = 'DATEOFFSET',
  Daterange = 'DATERANGE',
  DateDefinition = 'DATE_DEFINITION',
  Daxsqlpathname = 'DAXSQLPATHNAME',
  Daypartschema = 'DAYPARTSCHEMA',
  DayType = 'DAY_TYPE',
  Department = 'DEPARTMENT',
  DeviceType = 'DEVICE_TYPE',
  Digitaldeliveryservice = 'DIGITALDELIVERYSERVICE',
  DigitalBillingCountsSource = 'DIGITAL_BILLING_COUNTS_SOURCE',
  DigitalBuyType = 'DIGITAL_BUY_TYPE',
  DigitalCostMethod = 'DIGITAL_COST_METHOD',
  DigitalCreativeSize = 'DIGITAL_CREATIVE_SIZE',
  DigitalPlacementDescription = 'DIGITAL_PLACEMENT_DESCRIPTION',
  DigitalSpendInputField = 'DIGITAL_SPEND_INPUT_FIELD',
  DigitalTrafficRegion = 'DIGITAL_TRAFFIC_REGION',
  Dimensiontype = 'DIMENSIONTYPE',
  Discrepstatus = 'DISCREPSTATUS',
  Discrepstatusfilter = 'DISCREPSTATUSFILTER',
  Discreptype = 'DISCREPTYPE',
  Dubrequestdeliveryinstructions = 'DUBREQUESTDELIVERYINSTRUCTIONS',
  DuplicationScheme = 'DUPLICATION_SCHEME',
  EmailSignatureLogo = 'EMAIL_SIGNATURE_LOGO',
  Estimatedvsreportoption = 'ESTIMATEDVSREPORTOPTION',
  EstimatedRatingMode = 'ESTIMATED_RATING_MODE',
  Excelformattype = 'EXCELFORMATTYPE',
  Feed = 'FEED',
  FeeCostMethod = 'FEE_COST_METHOD',
  Fieldaggregatetype = 'FIELDAGGREGATETYPE',
  Fielddatatype = 'FIELDDATATYPE',
  Fielddefinitiontype = 'FIELDDEFINITIONTYPE',
  Fieldformat = 'FIELDFORMAT',
  Fileimporttype = 'FILEIMPORTTYPE',
  Filetype = 'FILETYPE',
  FileDownloadAction = 'FILE_DOWNLOAD_ACTION',
  Filterincludeineditortype = 'FILTERINCLUDEINEDITORTYPE',
  Filteroperatortype = 'FILTEROPERATORTYPE',
  FlightDateGranularity = 'FLIGHT_DATE_GRANULARITY',
  Font = 'FONT',
  Formulafieldmodifier = 'FORMULAFIELDMODIFIER',
  Formulatype = 'FORMULATYPE',
  FrameRate = 'FRAME_RATE',
  FrameRateScheme = 'FRAME_RATE_SCHEME',
  GenericAlertStatus = 'GENERIC_ALERT_STATUS',
  Glancereportboxelementdirection = 'GLANCEREPORTBOXELEMENTDIRECTION',
  Glancereportboxelementtotalsbasedon = 'GLANCEREPORTBOXELEMENTTOTALSBASEDON',
  Glancereportelementtype = 'GLANCEREPORTELEMENTTYPE',
  GoogleAnalyticsDimension = 'GOOGLE_ANALYTICS_DIMENSION',
  GoogleAnalyticsMetric = 'GOOGLE_ANALYTICS_METRIC',
  GoogleAnalyticsTimeGranularity = 'GOOGLE_ANALYTICS_TIME_GRANULARITY',
  Horizontalalignment = 'HORIZONTALALIGNMENT',
  Indexcomparisontype = 'INDEXCOMPARISONTYPE',
  InputFieldMappingType = 'INPUT_FIELD_MAPPING_TYPE',
  InputMappingSchemeType = 'INPUT_MAPPING_SCHEME_TYPE',
  InsertionOrderFileType = 'INSERTION_ORDER_FILE_TYPE',
  InsertionOrderStatus = 'INSERTION_ORDER_STATUS',
  InterlaceMode = 'INTERLACE_MODE',
  Invoicetype = 'INVOICETYPE',
  InvoiceApprovalState = 'INVOICE_APPROVAL_STATE',
  InvoicePayer = 'INVOICE_PAYER',
  Issueassignee = 'ISSUEASSIGNEE',
  Issuecategory = 'ISSUECATEGORY',
  Issuepriority = 'ISSUEPRIORITY',
  Issuetype = 'ISSUETYPE',
  JobInstanceDetailType = 'JOB_INSTANCE_DETAIL_TYPE',
  JobInstanceLogStatus = 'JOB_INSTANCE_LOG_STATUS',
  Language = 'LANGUAGE',
  LiftCalculation = 'LIFT_CALCULATION',
  LiftVisualizerDateUnit = 'LIFT_VISUALIZER_DATE_UNIT',
  Listsortdirection = 'LISTSORTDIRECTION',
  Logentrytype = 'LOGENTRYTYPE',
  Lookupaffidavitreceived = 'LOOKUPAFFIDAVITRECEIVED',
  Lookupbrokerexist = 'LOOKUPBROKEREXIST',
  Lookupfilterinvoice = 'LOOKUPFILTERINVOICE',
  Lookupfilterpi = 'LOOKUPFILTERPI',
  LookupsYouWishYouCouldDelete = 'LOOKUPS_YOU_WISH_YOU_COULD_DELETE',
  Mailerformat = 'MAILERFORMAT',
  Mapbreakpointtype = 'MAPBREAKPOINTTYPE',
  MappingType = 'MAPPING_TYPE',
  MasterFileReferenceType = 'MASTER_FILE_REFERENCE_TYPE',
  Mediaformat = 'MEDIAFORMAT',
  Mediaindextype = 'MEDIAINDEXTYPE',
  Mediaoutletfilereferencetype = 'MEDIAOUTLETFILEREFERENCETYPE',
  Mediaoutletgroup = 'MEDIAOUTLETGROUP',
  Mediaoutletrole = 'MEDIAOUTLETROLE',
  Mediaoutletsupportedmediatype = 'MEDIAOUTLETSUPPORTEDMEDIATYPE',
  Mediaplanreportamount = 'MEDIAPLANREPORTAMOUNT',
  Mediatransmissionrequeststatus = 'MEDIATRANSMISSIONREQUESTSTATUS',
  Mediatransmissionstatus = 'MEDIATRANSMISSIONSTATUS',
  Mediatransmissiontype = 'MEDIATRANSMISSIONTYPE',
  MediaClassification = 'MEDIA_CLASSIFICATION',
  MediaOutletGenre = 'MEDIA_OUTLET_GENRE',
  MediaPlanType = 'MEDIA_PLAN_TYPE',
  MediaTargetType = 'MEDIA_TARGET_TYPE',
  MediaTransmissionSubType = 'MEDIA_TRANSMISSION_SUB_TYPE',
  MergeAction = 'MERGE_ACTION',
  MonthDefinition = 'MONTH_DEFINITION',
  Moveorderstatus = 'MOVEORDERSTATUS',
  Moveordersubtype = 'MOVEORDERSUBTYPE',
  MriDataStability = 'MRI_DATA_STABILITY',
  MriDemo = 'MRI_DEMO',
  MriIndexType = 'MRI_INDEX_TYPE',
  NcaFileType = 'NCA_FILE_TYPE',
  NcaTargetMetric = 'NCA_TARGET_METRIC',
  NewClientAlertStatus = 'NEW_CLIENT_ALERT_STATUS',
  NewTapeAlertStatus = 'NEW_TAPE_ALERT_STATUS',
  Nielsendemo = 'NIELSENDEMO',
  NoteType = 'NOTE_TYPE',
  NumerisArea = 'NUMERIS_AREA',
  NumerisCalculatedDemo = 'NUMERIS_CALCULATED_DEMO',
  NumerisDemo = 'NUMERIS_DEMO',
  Offergroupingtype = 'OFFERGROUPINGTYPE',
  Offertype = 'OFFERTYPE',
  Onlinecontenttype = 'ONLINECONTENTTYPE',
  Onlinecreative = 'ONLINECREATIVE',
  Onlinecreativesize = 'ONLINECREATIVESIZE',
  Onlineplacement = 'ONLINEPLACEMENT',
  OperatingSystem = 'OPERATING_SYSTEM',
  Ordercallcreationtype = 'ORDERCALLCREATIONTYPE',
  Pageorientation = 'PAGEORIENTATION',
  PartnerBuyType = 'PARTNER_BUY_TYPE',
  PerInquiryCostType = 'PER_INQUIRY_COST_TYPE',
  PhoneNumberFormat = 'PHONE_NUMBER_FORMAT',
  Piinvoicecadence = 'PIINVOICECADENCE',
  Pitype = 'PITYPE',
  PiBookingDocumentType = 'PI_BOOKING_DOCUMENT_TYPE',
  PiLeadCountStatus = 'PI_LEAD_COUNT_STATUS',
  PlacementPosition = 'PLACEMENT_POSITION',
  Prelogstatus = 'PRELOGSTATUS',
  PrelogsRemovedAlertStatus = 'PRELOGS_REMOVED_ALERT_STATUS',
  Printadsize = 'PRINTADSIZE',
  Printadtype = 'PRINTADTYPE',
  Printfrequency = 'PRINTFREQUENCY',
  Printlocation = 'PRINTLOCATION',
  Printsection = 'PRINTSECTION',
  Productcategory = 'PRODUCTCATEGORY',
  ProductionWestBillingType = 'PRODUCTION_WEST_BILLING_TYPE',
  ProductionWestEditingService = 'PRODUCTION_WEST_EDITING_SERVICE',
  ProductionWestMediaFormat = 'PRODUCTION_WEST_MEDIA_FORMAT',
  ProductionWestShipping = 'PRODUCTION_WEST_SHIPPING',
  Productoffergroup = 'PRODUCTOFFERGROUP',
  Producttsbcontactlisttype = 'PRODUCTTSBCONTACTLISTTYPE',
  ProductSubCategory = 'PRODUCT_SUB_CATEGORY',
  ProductType = 'PRODUCT_TYPE',
  ProgrammaticExecution = 'PROGRAMMATIC_EXECUTION',
  PrtColor = 'PRT_COLOR',
  PrtIssueDate = 'PRT_ISSUE_DATE',
  PrtLayout = 'PRT_LAYOUT',
  Publicationcategory = 'PUBLICATIONCATEGORY',
  Radioformat = 'RADIOFORMAT',
  Ratetype = 'RATETYPE',
  RatingsDisplay = 'RATINGS_DISPLAY',
  Ratingtype = 'RATINGTYPE',
  RatingDataAvailabilityType = 'RATING_DATA_AVAILABILITY_TYPE',
  RatingDataStream = 'RATING_DATA_STREAM',
  RatingHeatMapRatingType = 'RATING_HEAT_MAP_RATING_TYPE',
  RatingHeatMapValueType = 'RATING_HEAT_MAP_VALUE_TYPE',
  RatingImportMode = 'RATING_IMPORT_MODE',
  RatingVendor = 'RATING_VENDOR',
  ReadType = 'READ_TYPE',
  RecordMaintenanceLookupMode = 'RECORD_MAINTENANCE_LOOKUP_MODE',
  Regiontype = 'REGIONTYPE',
  Reportdefaultpresetvisibility = 'REPORTDEFAULTPRESETVISIBILITY',
  Reportexportformattype = 'REPORTEXPORTFORMATTYPE',
  Reportfieldvaluemodifier = 'REPORTFIELDVALUEMODIFIER',
  Reportformat = 'REPORTFORMAT',
  Reportstyleformattype = 'REPORTSTYLEFORMATTYPE',
  Reporttarget = 'REPORTTARGET',
  Reporttype = 'REPORTTYPE',
  Requiredtype = 'REQUIREDTYPE',
  Resultauditfieldtosum = 'RESULTAUDITFIELDTOSUM',
  Resultauditreporttype = 'RESULTAUDITREPORTTYPE',
  Resultfindandreplacetype = 'RESULTFINDANDREPLACETYPE',
  ReviewPerformanceChartType = 'REVIEW_PERFORMANCE_CHART_TYPE',
  Revisionrequeststatus = 'REVISIONREQUESTSTATUS',
  Revisionrequesttype = 'REVISIONREQUESTTYPE',
  Revisionstatus = 'REVISIONSTATUS',
  Roviprogramgenre = 'ROVIPROGRAMGENRE',
  RoviProgramAiringType = 'ROVI_PROGRAM_AIRING_TYPE',
  RoviProgramShowingType = 'ROVI_PROGRAM_SHOWING_TYPE',
  Scheduleattribute = 'SCHEDULEATTRIBUTE',
  Scheduleinternalstatus = 'SCHEDULEINTERNALSTATUS',
  Scheduleprelogstatus = 'SCHEDULEPRELOGSTATUS',
  Schedulestate = 'SCHEDULESTATE',
  Schedulestatus = 'SCHEDULESTATUS',
  ScheduleDocumentType = 'SCHEDULE_DOCUMENT_TYPE',
  Sfprelogstatus = 'SFPRELOGSTATUS',
  SignalLinkCurveSchema = 'SIGNAL_LINK_CURVE_SCHEMA',
  Sourcingaction = 'SOURCINGACTION',
  Sourcingalgorithmtype = 'SOURCINGALGORITHMTYPE',
  Spendallocationschema = 'SPENDALLOCATIONSCHEMA',
  SpendDisplayDateUnit = 'SPEND_DISPLAY_DATE_UNIT',
  SqadLevel = 'SQAD_LEVEL',
  Sqlserverreservedkeywords = 'SQLSERVERRESERVEDKEYWORDS',
  Strategy = 'STRATEGY',
  SubFolder = 'SUB_FOLDER',
  SurveyNorm = 'SURVEY_NORM',
  SurveyQuestionType = 'SURVEY_QUESTION_TYPE',
  SurveyType = 'SURVEY_TYPE',
  Tabinitialposition = 'TABINITIALPOSITION',
  Tabtype = 'TABTYPE',
  Tactic = 'TACTIC',
  TalentFeeClassification = 'TALENT_FEE_CLASSIFICATION',
  Tapelength = 'TAPELENGTH',
  Targettype = 'TARGETTYPE',
  Taskpanetype = 'TASKPANETYPE',
  Taskstate = 'TASKSTATE',
  TaskAssociatedRecordCardinality = 'TASK_ASSOCIATED_RECORD_CARDINALITY',
  Term = 'TERM',
  TermsBillingType = 'TERMS_BILLING_TYPE',
  TermsCadence = 'TERMS_CADENCE',
  TermsService = 'TERMS_SERVICE',
  Teststatus = 'TESTSTATUS',
  TfnAssignmentAlertStatus = 'TFN_ASSIGNMENT_ALERT_STATUS',
  TrackerMessageType = 'TRACKER_MESSAGE_TYPE',
  Trackingcodeassignmenttype = 'TRACKINGCODEASSIGNMENTTYPE',
  Trackingcodeavailsetuptype = 'TRACKINGCODEAVAILSETUPTYPE',
  Trackingcodetype = 'TRACKINGCODETYPE',
  TrackingServicePaidBy = 'TRACKING_SERVICE_PAID_BY',
  TrafficAction = 'TRAFFIC_ACTION',
  TrafficChangeMediaAction = 'TRAFFIC_CHANGE_MEDIA_ACTION',
  Tribunename = 'TRIBUNENAME',
  Tvhdrequirement = 'TVHDREQUIREMENT',
  Tvlfcancellationleaddays = 'TVLFCANCELLATIONLEADDAYS',
  Tvlfrevisionleaddays = 'TVLFREVISIONLEADDAYS',
  Tvlftapeinhouseleaddays = 'TVLFTAPEINHOUSELEADDAYS',
  Tvsfremnantfilter = 'TVSFREMNANTFILTER',
  Unknown = 'UNKNOWN',
  UnlockScheduleRequestStatus = 'UNLOCK_SCHEDULE_REQUEST_STATUS',
  Unsourcedreason = 'UNSOURCEDREASON',
  Veilencoding = 'VEILENCODING',
  Vendortype = 'VENDORTYPE',
  Verticalalignment = 'VERTICALALIGNMENT',
  VideoHeight = 'VIDEO_HEIGHT',
  VideoWidth = 'VIDEO_WIDTH',
  Viewtaperequirement = 'VIEWTAPEREQUIREMENT',
  ViewLinkAlertStatus = 'VIEW_LINK_ALERT_STATUS',
  ViewSourceType = 'VIEW_SOURCE_TYPE',
  WaveCastAction = 'WAVE_CAST_ACTION',
  WaveCastBubbleChartDirective = 'WAVE_CAST_BUBBLE_CHART_DIRECTIVE',
  WaveCastDetailType = 'WAVE_CAST_DETAIL_TYPE',
  WaveCastReportType = 'WAVE_CAST_REPORT_TYPE',
  WeekPart = 'WEEK_PART',
  YesNo = 'YES_NO'
}

export type LookupTypeFilterInput = {
  and?: InputMaybe<Array<LookupTypeFilterInput>>;
  digitalSpendImportValueMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportValueMappingFilterInput>;
  lookupIsVisibleToAllCompanies?: InputMaybe<BooleanOperationFilterInput>;
  lookupTypeId?: InputMaybe<IntOperationFilterInput>;
  lookups?: InputMaybe<ListFilterInputTypeOfLookupFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LookupTypeFilterInput>>;
  userCanAddRecords?: InputMaybe<BooleanOperationFilterInput>;
  userCanDeleteRecords?: InputMaybe<BooleanOperationFilterInput>;
};

export type LookupTypeSortInput = {
  lookupIsVisibleToAllCompanies?: InputMaybe<SortEnumType>;
  lookupTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  userCanAddRecords?: InputMaybe<SortEnumType>;
  userCanDeleteRecords?: InputMaybe<SortEnumType>;
};

export type Lookup_ForBudgetAllocation = {
  __typename?: 'Lookup_ForBudgetAllocation';
  accountingBatchLineLinks?: Maybe<Array<Maybe<AccountingBatchLineLink>>>;
  accountingBatches?: Maybe<Array<Maybe<AccountingBatch>>>;
  accountingLineBuyTypeNavigations?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLineMonthDefinitionLookups?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLineSubTypeLookups?: Maybe<Array<Maybe<AccountingLine>>>;
  adAgencyLookups?: Maybe<Array<Maybe<Ad>>>;
  adAxtypeLookups?: Maybe<Array<Maybe<Ad>>>;
  adCampaignStatusLookups?: Maybe<Array<Maybe<Ad>>>;
  adCreativeAgencyLookups?: Maybe<Array<Maybe<Ad>>>;
  adInstanceImportDateDefinitionLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportRateTypeLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportRatingImportModeLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportTargetDemos?: Maybe<Array<Maybe<AdInstanceImportTargetDemo>>>;
  adInstances?: Maybe<Array<Maybe<AdInstance>>>;
  adLanguageLookups?: Maybe<Array<Maybe<Ad>>>;
  adMailerFormatLookups?: Maybe<Array<Maybe<Ad>>>;
  adOfferTypeLookups?: Maybe<Array<Maybe<Ad>>>;
  adTapeLengthLookups?: Maybe<Array<Maybe<Ad>>>;
  adVeilEncodingLookups?: Maybe<Array<Maybe<Ad>>>;
  appFieldAggregateFormatLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldAggregateTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldDataTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldDefinitionTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldFormatLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldTempTableConsolidateTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFilterOperators?: Maybe<Array<Maybe<AppFilterOperator>>>;
  appFilters?: Maybe<Array<Maybe<AppFilter>>>;
  appLogEntries?: Maybe<Array<Maybe<AppLogEntry>>>;
  appScreenInitialPositionLookups?: Maybe<Array<Maybe<AppScreen>>>;
  appScreenTabTypeLookups?: Maybe<Array<Maybe<AppScreen>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserRoles?: Maybe<Array<Maybe<AppUserRole>>>;
  appUserSettings?: Maybe<Array<Maybe<AppUserSetting>>>;
  appViewFields?: Maybe<Array<Maybe<AppViewField>>>;
  appViews?: Maybe<Array<Maybe<AppView>>>;
  autoReportItems?: Maybe<Array<Maybe<AutoReportItem>>>;
  autoScheduleBuyTypeLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleInstanceCreationLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoSchedulePibasedOnLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleRateTypeLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleStatusLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleTrafficMediaChanges?: Maybe<Array<Maybe<AutoScheduleTrafficMediaChange>>>;
  autoScheduleTraffics?: Maybe<Array<Maybe<AutoScheduleTraffic>>>;
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  availProposalDetailAttributes?: Maybe<Array<Maybe<AvailProposalDetailAttribute>>>;
  availProposalDetailRateTypeLookups?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  availProposalDetailTapeLengthLookups?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  budgetAllocationBuyTypeLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationCostMethodLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationFlights?: Maybe<Array<Maybe<BudgetAllocationFlight>>>;
  budgetAllocationProgrammaticExecutionLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationStrategyLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationTacticLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationTags?: Maybe<Array<Maybe<BudgetAllocationTag>>>;
  budgetBuyerSplits?: Maybe<Array<Maybe<BudgetBuyerSplit>>>;
  budgetRegionTypeSplits?: Maybe<Array<Maybe<BudgetRegionTypeSplit>>>;
  campaignAppliesToLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignAxtypeLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignBudgetEnforcementLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignClientApprovalRequirementLookups?: Maybe<Array<Maybe<Campaign>>>;
  clientBonusGroupLookups?: Maybe<Array<Maybe<Client>>>;
  clientFileReferences?: Maybe<Array<Maybe<ClientFileReference>>>;
  clientLookupAliases?: Maybe<Array<Maybe<ClientLookupAlias>>>;
  clientMediaOutletClientMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletClientReducedAudienceFeedMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletReducedAudienceFeedMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMonthDefinitionOfflineMonthDefinitionLookups?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientMonthDefinitionOnlineMonthDefinitionLookups?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientPaymentEnforcementLookups?: Maybe<Array<Maybe<Client>>>;
  commissionSlidingScaleCalendarTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleCommissionBasedOnRateTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleCommissionContingencyActionTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleContingencyDateUnitTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleResetConditionLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleThresholdBasedOnRateTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contactTypeContactTypeGroupLookups?: Maybe<Array<Maybe<ContactType>>>;
  contactTypeVendorTypeLookups?: Maybe<Array<Maybe<ContactType>>>;
  contractTermDayTypeLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsBillingTypeLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsCadenceLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsServiceLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  crossChannelCampaignDimensions?: Maybe<Array<Maybe<CrossChannelCampaignDimension>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  crossChannelCampaignVersions?: Maybe<Array<Maybe<CrossChannelCampaignVersion>>>;
  curveCurveSchemaLookups?: Maybe<Array<Maybe<Curve>>>;
  curveTapeLengthLookups?: Maybe<Array<Maybe<Curve>>>;
  dayparts?: Maybe<Array<Maybe<Daypart>>>;
  digitalDeliveryServiceAttributes?: Maybe<Array<Maybe<DigitalDeliveryServiceAttribute>>>;
  digitalSpendImportFieldMappings?: Maybe<Array<Maybe<DigitalSpendImportFieldMapping>>>;
  digitalSpendImportValueMappings?: Maybe<Array<Maybe<DigitalSpendImportValueMapping>>>;
  digitalSpendInputLineDigitalBuyTypeLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  digitalSpendInputLineStrategyLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  digitalSpendInputLineTacticLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  display?: Maybe<Scalars['String']>;
  duplicationPresetCodecLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetCodecProfileLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetCodecWrapperLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetFrameRateLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetInterlaceModeLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetVideoHeightLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetVideoWidthLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  fileImportMaps?: Maybe<Array<Maybe<FileImportMap>>>;
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
  fileReferences?: Maybe<Array<Maybe<FileReference>>>;
  files?: Maybe<Array<Maybe<File>>>;
  gareportSkudefinitions?: Maybe<Array<Maybe<GareportSkudefinition>>>;
  gareportTimeGranularityLookups?: Maybe<Array<Maybe<Gareport>>>;
  gareportTrackingCodeTypeLookups?: Maybe<Array<Maybe<Gareport>>>;
  glanceReportDimensions?: Maybe<Array<Maybe<GlanceReportDimension>>>;
  glanceReportElementBoxGlanceReportBoxElementDirectionLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementBoxGlanceReportBoxElementTotalsBasedOnLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementBoxGroupSortDirectionLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementElementTypeLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementHorizontalAlignmentLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementVerticalAlignmentLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportRanges?: Maybe<Array<Maybe<GlanceReportRange>>>;
  id: Scalars['Int'];
  inputFieldMappingSchemes?: Maybe<Array<Maybe<InputFieldMappingScheme>>>;
  inputFieldMappings?: Maybe<Array<Maybe<InputFieldMapping>>>;
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  insertionOrderFileReferences?: Maybe<Array<Maybe<InsertionOrderFileReference>>>;
  insertionOrderSpendDisplayUnitLookups?: Maybe<Array<Maybe<InsertionOrder>>>;
  insertionOrderStatusLookups?: Maybe<Array<Maybe<InsertionOrder>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  issueDateInfos?: Maybe<Array<Maybe<IssueDateInfo>>>;
  jobInstanceLogs?: Maybe<Array<Maybe<JobInstanceLog>>>;
  localMarketCostPerNielsenDemoLookups?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketCostPerSqadlevelLookups?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateAdLengths?: Maybe<Array<Maybe<LocalMarketRatingEstimateAdLength>>>;
  localMarketRatingEstimateDetails?: Maybe<Array<Maybe<LocalMarketRatingEstimateDetail>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  localMarketRatingEstimates?: Maybe<Array<Maybe<LocalMarketRatingEstimate>>>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  lookupId: Scalars['Int'];
  lookupType?: Maybe<LookupType>;
  lookupTypeId: Scalars['Int'];
  mediaAuthorizationDimensions?: Maybe<Array<Maybe<MediaAuthorizationDimension>>>;
  mediaAuthorizations?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaAvailRates?: Maybe<Array<Maybe<MediaAvailRate>>>;
  mediaFormatAttributes?: Maybe<Array<Maybe<MediaFormatAttribute>>>;
  mediaOutletAddresses?: Maybe<Array<Maybe<MediaOutletAddress>>>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaOutletDigitalDeliveryTypeToAddressTypeAddressTypeLookups?: Maybe<Array<Maybe<MediaOutletDigitalDeliveryTypeToAddressType>>>;
  mediaOutletDigitalDeliveryTypeToAddressTypeDigitalDeliveryServiceLookups?: Maybe<Array<Maybe<MediaOutletDigitalDeliveryTypeToAddressType>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutletFrameRateSchemeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletLanguageLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletLfviewTapeRequirementLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaClassificationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaOutletAffiliateLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaOutletGenreLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaTypeAccountingNetworkLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeAxtypeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeDigitalDeliveryServices?: Maybe<Array<Maybe<MediaOutletMediaTypeDigitalDeliveryService>>>;
  mediaOutletMediaTypeInvoiceTypeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatHighDefLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatViewTapeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeTermLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeTvhddubRequirementLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeVendorCodes?: Maybe<Array<Maybe<MediaOutletMediaTypeVendorCode>>>;
  mediaOutletMediaTypeViewTapeRequirementLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletPartnerBuyTypeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPiinvoiceCadenceLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPrintFormats?: Maybe<Array<Maybe<MediaOutletPrintFormat>>>;
  mediaOutletPrtfrequencyLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPrtpublicationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletRates?: Maybe<Array<Maybe<MediaOutletRate>>>;
  mediaOutletRdradioFormatLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletRoles?: Maybe<Array<Maybe<MediaOutletRole>>>;
  mediaOutletRotations?: Maybe<Array<Maybe<MediaOutletRotation>>>;
  mediaOutletSfprelogStatusLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTalentFeeClassificationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvclosedCaptionStatusLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvhddubRequirementLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvsupportedMediaTypeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaPlanExportExcelOptions?: Maybe<Array<Maybe<MediaPlanExportExcelOption>>>;
  mediaPlanNielsenDemos?: Maybe<Array<Maybe<MediaPlanNielsenDemo>>>;
  mediaPlanTapeLengths?: Maybe<Array<Maybe<MediaPlanTapeLength>>>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  mediaScheduleDetailAdvancedTargetingDemoLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailAttributes?: Maybe<Array<Maybe<MediaScheduleDetailAttribute>>>;
  mediaScheduleDetailBuyTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPlacementPositionLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtadSizeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtadTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtcolorLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtissueDateLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtlayoutLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtlocationLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtsectionLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailRateTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailReadTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailStatusLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailTapeLengthLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailTrackingServicePaidByLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailWeekPartLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDisplayEstimatedRatingModeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaScheduleDisplayKpidateRangeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaScheduleDisplayRateTypeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTransmissionHdmediaFormatLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaFormatLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaTransmissionStatusLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaTransmissionTypeLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionPrintAdSizeLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionPriorityLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mriindexMridataStabilityLookups?: Maybe<Array<Maybe<Mriindex>>>;
  mriindexMridemoLookups?: Maybe<Array<Maybe<Mriindex>>>;
  mriindexMriindexTypeLookups?: Maybe<Array<Maybe<Mriindex>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementFileReferences?: Maybe<Array<Maybe<NewCampaignAnnouncementFileReference>>>;
  newCampaignAnnouncementTargetMetricLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementTfntrackingCodeAssignmentTypeLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementUrltrackingCodeAssignmentTypeLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementVeilEncodingLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  onlineScheduleAttributeOnlineContentCategoryLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlineCreativeLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlineCreativeSizeLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlinePlacementIdlookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlinePlacementLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  printAvailAdSizeLookups?: Maybe<Array<Maybe<PrintAvail>>>;
  printAvailIssueDateLookups?: Maybe<Array<Maybe<PrintAvail>>>;
  printScheduleAttributePrintAdSizeLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintAdTypeLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintLocationLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintSectionLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  productCategoryHierarchyProductCategoryLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productCategoryHierarchyProductSubCategoryLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productCategoryHierarchyProductTypeLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productMediaTargets?: Maybe<Array<Maybe<ProductMediaTarget>>>;
  productProductCategoryLookups?: Maybe<Array<Maybe<Product>>>;
  productProductSubCategoryLookups?: Maybe<Array<Maybe<Product>>>;
  productTrackingCodeAvailSetupTypeLookups?: Maybe<Array<Maybe<Product>>>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  ratingC3ratingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingNielsenDemoLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingPlus3RatingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingRatingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  regionCurrencyCodeLookups?: Maybe<Array<Maybe<Region>>>;
  regionRegionTypeLookups?: Maybe<Array<Maybe<Region>>>;
  rentrakNetworks?: Maybe<Array<Maybe<RentrakNetwork>>>;
  reportDefaultPresetVisibilityLookups?: Maybe<Array<Maybe<Report>>>;
  reportExportFormatFieldAggregateFormatLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldDataTypeLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFontLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFormatLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFormulaTypeLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldHorizontalAlignmentLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldReportFieldValueModifierLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFontLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatMapBreakpointTypeLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatPageOrientationLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatReportExportFormatTypeLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatStyleEvaluationOperatorTypeLookups?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportExportFormatStyleReportStyleFormatTypeLookups?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportFormats?: Maybe<Array<Maybe<ReportFormat>>>;
  reportPresetFilterRecords?: Maybe<Array<Maybe<ReportPresetFilterRecord>>>;
  reportPresetGroupSorts?: Maybe<Array<Maybe<ReportPresetGroupSort>>>;
  reportTargetLookups?: Maybe<Array<Maybe<Report>>>;
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  scheduleAdvancedTargetingLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleAttributes?: Maybe<Array<Maybe<ScheduleAttribute>>>;
  scheduleBillingMetricLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleBuyTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleDigitalPlacementDescriptionLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleFileReferences?: Maybe<Array<Maybe<ScheduleFileReference>>>;
  scheduleInternalStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleLftribuneNameLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePibasedOnLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePlacementPositionLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtadSizeLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtadTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtcolorLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtissueDateLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtlayoutLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtlocationLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtplacedSectionLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtsectionLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleRateTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleReadTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSfschedulePrelogStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSpendAllocationSchemaLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleStatusStateScheduleStateLookups?: Maybe<Array<Maybe<ScheduleStatusState>>>;
  scheduleStatusStateStatusLookups?: Maybe<Array<Maybe<ScheduleStatusState>>>;
  scheduleStrategyLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleTacticLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleTrackingServicePaidByLookups?: Maybe<Array<Maybe<Schedule>>>;
  scriptAutosourcerAlgorithmLookups?: Maybe<Array<Maybe<Script>>>;
  scriptLiftTypeLookups?: Maybe<Array<Maybe<Script>>>;
  scriptOrderCallCreationLookups?: Maybe<Array<Maybe<Script>>>;
  scriptSignalLinkCurveSchemaLookups?: Maybe<Array<Maybe<Script>>>;
  scriptSpikeNielsenDemoLookups?: Maybe<Array<Maybe<Script>>>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  sourcingAlgorithmAudienceWeightLookups?: Maybe<Array<Maybe<SourcingAlgorithm>>>;
  sourcingAlgorithmSourcingAlgorithmTypeLookups?: Maybe<Array<Maybe<SourcingAlgorithm>>>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
  surveyDateRangeLookups?: Maybe<Array<Maybe<Survey>>>;
  surveyNielsenDemoLookups?: Maybe<Array<Maybe<Survey>>>;
  surveyQuestions?: Maybe<Array<Maybe<SurveyQuestion>>>;
  surveySurveyTypeLookups?: Maybe<Array<Maybe<Survey>>>;
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  targetMridemoLookups?: Maybe<Array<Maybe<Target>>>;
  targetNielsenDemoLookups?: Maybe<Array<Maybe<Target>>>;
  targetTargetTypeLookups?: Maybe<Array<Maybe<Target>>>;
  taskHistories?: Maybe<Array<Maybe<TaskHistory>>>;
  taskPriorityLookups?: Maybe<Array<Maybe<Task>>>;
  taskRoleLookups?: Maybe<Array<Maybe<Task>>>;
  taskTaskSubTypeLookups?: Maybe<Array<Maybe<Task>>>;
  taskTypeStatusAvailTaskStateLookups?: Maybe<Array<Maybe<TaskTypeStatusAvail>>>;
  taskTypeStatusAvailTaskStatusLookups?: Maybe<Array<Maybe<TaskTypeStatusAvail>>>;
  tmpAccountingLineBuyTypeNavigations?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  tmpAccountingLineMonthDefinitionLookups?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  topLineMediaPlanDetailAttributes?: Maybe<Array<Maybe<TopLineMediaPlanDetailAttribute>>>;
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  topLineMediaPlanNielsenDemoLookupId1Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  topLineMediaPlanNielsenDemoLookupId2Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  topLineMediaPlanNielsenDemoLookupId3Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  trackingCodeAvailSetupTrackingCodeAvailSetupTypeLookups?: Maybe<Array<Maybe<TrackingCodeAvailSetup>>>;
  trackingCodeAvailSetupTrackingCodeTypeLookups?: Maybe<Array<Maybe<TrackingCodeAvailSetup>>>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodes?: Maybe<Array<Maybe<TrackingCode>>>;
  trafficSheetDetailCountryLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailDevices?: Maybe<Array<Maybe<TrafficSheetDetailDevice>>>;
  trafficSheetDetailDigitalRegionLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailOperatingSystemLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailProgrammaticExecutionLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailSizeLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailStrategyLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailTacticLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  vendorAddressTypeLookups?: Maybe<Array<Maybe<Vendor>>>;
  vendorBrokerCurrencyCodeLookups?: Maybe<Array<Maybe<Vendor>>>;
  vendorLookups?: Maybe<Array<Maybe<VendorLookup>>>;
  vendorServices?: Maybe<Array<Maybe<VendorService>>>;
  vendorVendorTypeLookups?: Maybe<Array<Maybe<Vendor>>>;
  viewSources?: Maybe<Array<Maybe<ViewSource>>>;
  waveCastDetailActionTypeLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastDetailOverrideBubbleChartDirectiveLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastDetailWaveCastDetailTypeLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
};

export type Lookup_ForBudgetAllocationFilterInput = {
  accountingBatchLineLinks?: InputMaybe<ListFilterInputTypeOfAccountingBatchLineLinkFilterInput>;
  accountingBatches?: InputMaybe<ListFilterInputTypeOfAccountingBatchFilterInput>;
  accountingLineBuyTypeNavigations?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  accountingLineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  accountingLineSubTypeLookups?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  adAgencyLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adAxtypeLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adCampaignStatusLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adCreativeAgencyLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adInstanceImportDateDefinitionLookups?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstanceImportRateTypeLookups?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstanceImportRatingImportModeLookups?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  adInstanceImportTargetDemos?: InputMaybe<ListFilterInputTypeOfAdInstanceImportTargetDemoFilterInput>;
  adInstances?: InputMaybe<ListFilterInputTypeOfAdInstanceFilterInput>;
  adLanguageLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adMailerFormatLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adOfferTypeLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adVeilEncodingLookups?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<Lookup_ForBudgetAllocationFilterInput>>;
  appFieldAggregateFormatLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldAggregateTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldDataTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldDefinitionTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldFormatLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFieldTempTableConsolidateTypeLookups?: InputMaybe<ListFilterInputTypeOfAppFieldFilterInput>;
  appFilterOperators?: InputMaybe<ListFilterInputTypeOfAppFilterOperatorFilterInput>;
  appFilters?: InputMaybe<ListFilterInputTypeOfAppFilterFilterInput>;
  appLogEntries?: InputMaybe<ListFilterInputTypeOfAppLogEntryFilterInput>;
  appScreenInitialPositionLookups?: InputMaybe<ListFilterInputTypeOfAppScreenFilterInput>;
  appScreenTabTypeLookups?: InputMaybe<ListFilterInputTypeOfAppScreenFilterInput>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  appUserRoles?: InputMaybe<ListFilterInputTypeOfAppUserRoleFilterInput>;
  appUserSettings?: InputMaybe<ListFilterInputTypeOfAppUserSettingFilterInput>;
  appViewFields?: InputMaybe<ListFilterInputTypeOfAppViewFieldFilterInput>;
  appViews?: InputMaybe<ListFilterInputTypeOfAppViewFilterInput>;
  autoReportItems?: InputMaybe<ListFilterInputTypeOfAutoReportItemFilterInput>;
  autoScheduleBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleInstanceCreationLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoSchedulePibasedOnLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleRateTypeLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleStatusLookups?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  autoScheduleTrafficMediaChanges?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficMediaChangeFilterInput>;
  autoScheduleTraffics?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficFilterInput>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  availProposalDetailAttributes?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailAttributeFilterInput>;
  availProposalDetailRateTypeLookups?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  availProposalDetailTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  budgetAllocationBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationCostMethodLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationFees?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFeeFilterInput>;
  budgetAllocationFlights?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFlightFilterInput>;
  budgetAllocationProgrammaticExecutionLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationStrategyLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationTacticLookups?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationTags?: InputMaybe<ListFilterInputTypeOfBudgetAllocationTagFilterInput>;
  budgetBuyerSplits?: InputMaybe<ListFilterInputTypeOfBudgetBuyerSplitFilterInput>;
  budgetRegionTypeSplits?: InputMaybe<ListFilterInputTypeOfBudgetRegionTypeSplitFilterInput>;
  campaignAppliesToLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  campaignAxtypeLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  campaignBudgetEnforcementLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  campaignClientApprovalRequirementLookups?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  clientBonusGroupLookups?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  clientFileReferences?: InputMaybe<ListFilterInputTypeOfClientFileReferenceFilterInput>;
  clientLookupAliases?: InputMaybe<ListFilterInputTypeOfClientLookupAliasFilterInput>;
  clientMediaOutletClientMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMediaOutletClientReducedAudienceFeedMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMediaOutletMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMediaOutletReducedAudienceFeedMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clientMonthDefinitionOfflineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfClientMonthDefinitionFilterInput>;
  clientMonthDefinitionOnlineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfClientMonthDefinitionFilterInput>;
  clientPaymentEnforcementLookups?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  commissionSlidingScaleCalendarTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleCommissionBasedOnRateTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleCommissionContingencyActionTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleContingencyDateUnitTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleResetConditionLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissionSlidingScaleThresholdBasedOnRateTypeLookups?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contactTypeContactTypeGroupLookups?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  contactTypeVendorTypeLookups?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  contractTermDayTypeLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  contractTermTermsBillingTypeLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  contractTermTermsCadenceLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  contractTermTermsServiceLookups?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  crossChannelCampaignDimensions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignDimensionFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  crossChannelCampaignVersions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignVersionFilterInput>;
  curveCurveSchemaLookups?: InputMaybe<ListFilterInputTypeOfCurveFilterInput>;
  curveTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfCurveFilterInput>;
  dayparts?: InputMaybe<ListFilterInputTypeOfDaypartFilterInput>;
  digitalDeliveryServiceAttributes?: InputMaybe<ListFilterInputTypeOfDigitalDeliveryServiceAttributeFilterInput>;
  digitalSpendImportFieldMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportFieldMappingFilterInput>;
  digitalSpendImportValueMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportValueMappingFilterInput>;
  digitalSpendInputLineDigitalBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  digitalSpendInputLineStrategyLookups?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  digitalSpendInputLineTacticLookups?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  duplicationPresetCodecLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetCodecProfileLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetCodecWrapperLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetFrameRateLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetInterlaceModeLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetVideoHeightLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  duplicationPresetVideoWidthLookups?: InputMaybe<ListFilterInputTypeOfDuplicationPresetFilterInput>;
  fileImportMaps?: InputMaybe<ListFilterInputTypeOfFileImportMapFilterInput>;
  fileImports?: InputMaybe<ListFilterInputTypeOfFileImportFilterInput>;
  fileReferences?: InputMaybe<ListFilterInputTypeOfFileReferenceFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFileFilterInput>;
  gareportSkudefinitions?: InputMaybe<ListFilterInputTypeOfGareportSkudefinitionFilterInput>;
  gareportTimeGranularityLookups?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  gareportTrackingCodeTypeLookups?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  glanceReportDimensions?: InputMaybe<ListFilterInputTypeOfGlanceReportDimensionFilterInput>;
  glanceReportElementBoxGlanceReportBoxElementDirectionLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementBoxGlanceReportBoxElementTotalsBasedOnLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementBoxGroupSortDirectionLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementElementTypeLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementHorizontalAlignmentLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportElementVerticalAlignmentLookups?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  glanceReportRanges?: InputMaybe<ListFilterInputTypeOfGlanceReportRangeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  inputFieldMappingSchemes?: InputMaybe<ListFilterInputTypeOfInputFieldMappingSchemeFilterInput>;
  inputFieldMappings?: InputMaybe<ListFilterInputTypeOfInputFieldMappingFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  insertionOrderFileReferences?: InputMaybe<ListFilterInputTypeOfInsertionOrderFileReferenceFilterInput>;
  insertionOrderSpendDisplayUnitLookups?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  insertionOrderStatusLookups?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  invoices?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  issueDateInfos?: InputMaybe<ListFilterInputTypeOfIssueDateInfoFilterInput>;
  jobInstanceLogs?: InputMaybe<ListFilterInputTypeOfJobInstanceLogFilterInput>;
  localMarketCostPerNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketCostPerSqadlevelLookups?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateAdLengths?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateAdLengthFilterInput>;
  localMarketRatingEstimateDetails?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateDetailFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  localMarketRatingEstimates?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  lookupId?: InputMaybe<IntOperationFilterInput>;
  lookupType?: InputMaybe<LookupTypeFilterInput>;
  lookupTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaAuthorizationDimensions?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationDimensionFilterInput>;
  mediaAuthorizations?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationFilterInput>;
  mediaAvailRates?: InputMaybe<ListFilterInputTypeOfMediaAvailRateFilterInput>;
  mediaFormatAttributes?: InputMaybe<ListFilterInputTypeOfMediaFormatAttributeFilterInput>;
  mediaOutletAddresses?: InputMaybe<ListFilterInputTypeOfMediaOutletAddressFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaOutletDigitalDeliveryTypeToAddressTypeAddressTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>;
  mediaOutletDigitalDeliveryTypeToAddressTypeDigitalDeliveryServiceLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>;
  mediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfMediaOutletFileReferenceFilterInput>;
  mediaOutletFrameRateSchemeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletLanguageLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletLfviewTapeRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaClassificationLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaOutletAffiliateLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaOutletGenreLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletMediaTypeAccountingNetworkLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeAxtypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeDigitalDeliveryServices?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeDigitalDeliveryServiceFilterInput>;
  mediaOutletMediaTypeInvoiceTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeMediaFormatHighDefLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeMediaFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeMediaFormatViewTapeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeTermLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeTvhddubRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeVendorCodes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeVendorCodeFilterInput>;
  mediaOutletMediaTypeViewTapeRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletPartnerBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletPiinvoiceCadenceLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletPrintFormats?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintFormatFilterInput>;
  mediaOutletPrtfrequencyLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletPrtpublicationLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletRates?: InputMaybe<ListFilterInputTypeOfMediaOutletRateFilterInput>;
  mediaOutletRdradioFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletRoles?: InputMaybe<ListFilterInputTypeOfMediaOutletRoleFilterInput>;
  mediaOutletRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletRotationFilterInput>;
  mediaOutletSfprelogStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTalentFeeClassificationLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTvclosedCaptionStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTvhddubRequirementLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaOutletTvsupportedMediaTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaPlanExportExcelOptions?: InputMaybe<ListFilterInputTypeOfMediaPlanExportExcelOptionFilterInput>;
  mediaPlanNielsenDemos?: InputMaybe<ListFilterInputTypeOfMediaPlanNielsenDemoFilterInput>;
  mediaPlanTapeLengths?: InputMaybe<ListFilterInputTypeOfMediaPlanTapeLengthFilterInput>;
  mediaPlans?: InputMaybe<ListFilterInputTypeOfMediaPlanFilterInput>;
  mediaScheduleDetailAdvancedTargetingDemoLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailAttributes?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailAttributeFilterInput>;
  mediaScheduleDetailBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPlacementPositionLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtadSizeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtadTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtcolorLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtissueDateLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtlayoutLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtlocationLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailPrtsectionLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailRateTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailReadTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailTapeLengthLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailTrackingServicePaidByLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailWeekPartLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDisplayEstimatedRatingModeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaScheduleDisplayKpidateRangeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaScheduleDisplayRateTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaTransmissionHdmediaFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionMediaFormatLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionMediaTransmissionStatusLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionMediaTransmissionTypeLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionPrintAdSizeLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionPriorityLookups?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mriindexMridataStabilityLookups?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  mriindexMridemoLookups?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  mriindexMriindexTypeLookups?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementFileReferences?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFileReferenceFilterInput>;
  newCampaignAnnouncementTargetMetricLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementTfntrackingCodeAssignmentTypeLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementUrltrackingCodeAssignmentTypeLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementVeilEncodingLookups?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  notes?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  onlineScheduleAttributeOnlineContentCategoryLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlineCreativeLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlineCreativeSizeLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlinePlacementIdlookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  onlineScheduleAttributeOnlinePlacementLookups?: InputMaybe<ListFilterInputTypeOfOnlineScheduleAttributeFilterInput>;
  or?: InputMaybe<Array<Lookup_ForBudgetAllocationFilterInput>>;
  perInquiryCosts?: InputMaybe<ListFilterInputTypeOfPerInquiryCostFilterInput>;
  prelogs?: InputMaybe<ListFilterInputTypeOfPrelogFilterInput>;
  printAvailAdSizeLookups?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  printAvailIssueDateLookups?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  printScheduleAttributePrintAdSizeLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  printScheduleAttributePrintAdTypeLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  printScheduleAttributePrintLocationLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  printScheduleAttributePrintSectionLookups?: InputMaybe<ListFilterInputTypeOfPrintScheduleAttributeFilterInput>;
  productCategoryHierarchyProductCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductCategoryHierarchyFilterInput>;
  productCategoryHierarchyProductSubCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductCategoryHierarchyFilterInput>;
  productCategoryHierarchyProductTypeLookups?: InputMaybe<ListFilterInputTypeOfProductCategoryHierarchyFilterInput>;
  productMediaTargets?: InputMaybe<ListFilterInputTypeOfProductMediaTargetFilterInput>;
  productProductCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  productProductSubCategoryLookups?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  productTrackingCodeAvailSetupTypeLookups?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  ratingC3ratingTypeLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  ratingNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  ratingPlus3RatingTypeLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  ratingRatingTypeLookups?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  regionCurrencyCodeLookups?: InputMaybe<ListFilterInputTypeOfRegionFilterInput>;
  regionRegionTypeLookups?: InputMaybe<ListFilterInputTypeOfRegionFilterInput>;
  rentrakNetworks?: InputMaybe<ListFilterInputTypeOfRentrakNetworkFilterInput>;
  reportDefaultPresetVisibilityLookups?: InputMaybe<ListFilterInputTypeOfReportFilterInput>;
  reportExportFormatFieldAggregateFormatLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldDataTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldFontLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldFormatLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldFormulaTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldHorizontalAlignmentLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFieldReportFieldValueModifierLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatFontLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatMapBreakpointTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatPageOrientationLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatReportExportFormatTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportExportFormatStyleEvaluationOperatorTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatStyleFilterInput>;
  reportExportFormatStyleReportStyleFormatTypeLookups?: InputMaybe<ListFilterInputTypeOfReportExportFormatStyleFilterInput>;
  reportFormats?: InputMaybe<ListFilterInputTypeOfReportFormatFilterInput>;
  reportPresetFilterRecords?: InputMaybe<ListFilterInputTypeOfReportPresetFilterRecordFilterInput>;
  reportPresetGroupSorts?: InputMaybe<ListFilterInputTypeOfReportPresetGroupSortFilterInput>;
  reportTargetLookups?: InputMaybe<ListFilterInputTypeOfReportFilterInput>;
  resultStagings?: InputMaybe<ListFilterInputTypeOfResultStagingFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  scheduleAdvancedTargetingLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleAttributes?: InputMaybe<ListFilterInputTypeOfScheduleAttributeFilterInput>;
  scheduleBillingMetricLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleBuyTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleDigitalPlacementDescriptionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFileReferenceFilterInput>;
  scheduleInternalStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleLftribuneNameLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePibasedOnLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePlacementPositionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtadSizeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtadTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtcolorLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtissueDateLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtlayoutLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtlocationLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtplacedSectionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  schedulePrtsectionLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleRateTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleReadTypeLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleSfschedulePrelogStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleSpendAllocationSchemaLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleStatusStateScheduleStateLookups?: InputMaybe<ListFilterInputTypeOfScheduleStatusStateFilterInput>;
  scheduleStatusStateStatusLookups?: InputMaybe<ListFilterInputTypeOfScheduleStatusStateFilterInput>;
  scheduleStrategyLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleTacticLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scheduleTrackingServicePaidByLookups?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scriptAutosourcerAlgorithmLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptLiftTypeLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptOrderCallCreationLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptSignalLinkCurveSchemaLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  scriptSpikeNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  skus?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  sourcingAlgorithmAudienceWeightLookups?: InputMaybe<ListFilterInputTypeOfSourcingAlgorithmFilterInput>;
  sourcingAlgorithmSourcingAlgorithmTypeLookups?: InputMaybe<ListFilterInputTypeOfSourcingAlgorithmFilterInput>;
  standardsAndPracticesApprovals?: InputMaybe<ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput>;
  surveyDateRangeLookups?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  surveyNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  surveyQuestions?: InputMaybe<ListFilterInputTypeOfSurveyQuestionFilterInput>;
  surveySurveyTypeLookups?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  targetMridemoLookups?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  targetNielsenDemoLookups?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  targetTargetTypeLookups?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  taskHistories?: InputMaybe<ListFilterInputTypeOfTaskHistoryFilterInput>;
  taskPriorityLookups?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskRoleLookups?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskTaskSubTypeLookups?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  taskTypeStatusAvailTaskStateLookups?: InputMaybe<ListFilterInputTypeOfTaskTypeStatusAvailFilterInput>;
  taskTypeStatusAvailTaskStatusLookups?: InputMaybe<ListFilterInputTypeOfTaskTypeStatusAvailFilterInput>;
  tmpAccountingLineBuyTypeNavigations?: InputMaybe<ListFilterInputTypeOfTmpAccountingLineFilterInput>;
  tmpAccountingLineMonthDefinitionLookups?: InputMaybe<ListFilterInputTypeOfTmpAccountingLineFilterInput>;
  topLineMediaPlanDetailAttributes?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailAttributeFilterInput>;
  topLineMediaPlanDetails?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput>;
  topLineMediaPlanNielsenDemoLookupId1Navigations?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  topLineMediaPlanNielsenDemoLookupId2Navigations?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  topLineMediaPlanNielsenDemoLookupId3Navigations?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanFilterInput>;
  trackingCodeAvailSetupTrackingCodeAvailSetupTypeLookups?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailSetupFilterInput>;
  trackingCodeAvailSetupTrackingCodeTypeLookups?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailSetupFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodes?: InputMaybe<ListFilterInputTypeOfTrackingCodeFilterInput>;
  trafficSheetDetailCountryLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailDevices?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailDeviceFilterInput>;
  trafficSheetDetailDigitalRegionLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailOperatingSystemLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailProgrammaticExecutionLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailSizeLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailStrategyLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailTacticLookups?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  vendorAddressTypeLookups?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  vendorBrokerCurrencyCodeLookups?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  vendorLookups?: InputMaybe<ListFilterInputTypeOfVendorLookupFilterInput>;
  vendorServices?: InputMaybe<ListFilterInputTypeOfVendorServiceFilterInput>;
  vendorVendorTypeLookups?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  viewSources?: InputMaybe<ListFilterInputTypeOfViewSourceFilterInput>;
  waveCastDetailActionTypeLookups?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastDetailOverrideBubbleChartDirectiveLookups?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastDetailWaveCastDetailTypeLookups?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
};

export type MainAccount = {
  __typename?: 'MainAccount';
  accountCategoryDescription: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  chartOfAccounts: Scalars['String'];
  chartOfAccountsRecId: Scalars['Long'];
  defaultCurrency: Scalars['String'];
  isSuspended: NoYes;
  mainAccountCategory: Scalars['String'];
  mainAccountId: Scalars['String'];
  mainAccountRecId: Scalars['Long'];
  mainAccountType: DimensionLedgerAccountType;
  name: Scalars['String'];
  offsetAccountDisplayValue: Scalars['String'];
  parentMainAccountId: Scalars['String'];
};

export type ManualClearanceLog = {
  __typename?: 'ManualClearanceLog';
  dateCleared?: Maybe<Scalars['DateTime']>;
  dateUncleared?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  manualClearanceLogId: Scalars['Int'];
  prelogEstimate?: Maybe<PrelogEstimate>;
  prelogEstimateId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  userLogin?: Maybe<Scalars['String']>;
};

export type ManualClearanceLogFilterInput = {
  and?: InputMaybe<Array<ManualClearanceLogFilterInput>>;
  dateCleared?: InputMaybe<DateTimeOperationFilterInput>;
  dateUncleared?: InputMaybe<DateTimeOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  manualClearanceLogId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ManualClearanceLogFilterInput>>;
  prelogEstimate?: InputMaybe<PrelogEstimateFilterInput>;
  prelogEstimateId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  userLogin?: InputMaybe<StringOperationFilterInput>;
};

export type MarketContributionAnalysis = {
  __typename?: 'MarketContributionAnalysis';
  betaCoefficient?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dateReportExportFormatField?: Maybe<ReportExportFormatField>;
  dateReportExportFormatFieldId: Scalars['Int'];
  decayRate: Scalars['Int'];
  kpisourceSku?: Maybe<Sku>;
  kpisourceSkugroup?: Maybe<Skugroup>;
  kpisourceSkugroupId?: Maybe<Scalars['Int']>;
  kpisourceSkuid?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  marketContributionAnalysisId: Scalars['Int'];
  mediaValueReportExportFormatField?: Maybe<ReportExportFormatField>;
  mediaValueReportExportFormatFieldId: Scalars['Int'];
  mediaVariableStartDate?: Maybe<Scalars['Date']>;
  modeledEndDate: Scalars['Date'];
  modeledStartDate: Scalars['Date'];
  name?: Maybe<Scalars['String']>;
  outputEndDate?: Maybe<Scalars['Date']>;
  outputStartDate: Scalars['Date'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  reportPreset?: Maybe<ReportPreset>;
  reportPresetId: Scalars['Int'];
  script?: Maybe<Script>;
  scriptId: Scalars['Int'];
  scurvePoi: Scalars['Decimal'];
  scurveStandardDeviation: Scalars['Decimal'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
};

export type MarketContributionAnalysisFilterInput = {
  and?: InputMaybe<Array<MarketContributionAnalysisFilterInput>>;
  betaCoefficient?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dateReportExportFormatField?: InputMaybe<ReportExportFormatFieldFilterInput>;
  dateReportExportFormatFieldId?: InputMaybe<IntOperationFilterInput>;
  decayRate?: InputMaybe<IntOperationFilterInput>;
  kpisourceSku?: InputMaybe<SkuFilterInput>;
  kpisourceSkugroup?: InputMaybe<SkugroupFilterInput>;
  kpisourceSkugroupId?: InputMaybe<IntOperationFilterInput>;
  kpisourceSkuid?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  marketContributionAnalysisId?: InputMaybe<IntOperationFilterInput>;
  mediaValueReportExportFormatField?: InputMaybe<ReportExportFormatFieldFilterInput>;
  mediaValueReportExportFormatFieldId?: InputMaybe<IntOperationFilterInput>;
  mediaVariableStartDate?: InputMaybe<DateOperationFilterInput>;
  modeledEndDate?: InputMaybe<DateOperationFilterInput>;
  modeledStartDate?: InputMaybe<DateOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MarketContributionAnalysisFilterInput>>;
  outputEndDate?: InputMaybe<DateOperationFilterInput>;
  outputStartDate?: InputMaybe<DateOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  scurvePoi?: InputMaybe<DecimalOperationFilterInput>;
  scurveStandardDeviation?: InputMaybe<DecimalOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
};

export type MediaAuthorization = {
  __typename?: 'MediaAuthorization';
  clientPurchaseOrderNumber?: Maybe<Scalars['String']>;
  clientServicesApprovedUtc?: Maybe<Scalars['DateTime']>;
  clientServicesApproverAppUser?: Maybe<AppUser>;
  clientServicesApproverAppUserId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  confirmationFileReference?: Maybe<FileReference>;
  confirmationFileReferenceId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  dateGranularityLookup?: Maybe<Lookup>;
  dateGranularityLookupId: Scalars['Int'];
  endDate?: Maybe<Scalars['Date']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  financeApprovedUtc?: Maybe<Scalars['DateTime']>;
  financeApproverAppUser?: Maybe<AppUser>;
  financeApproverAppUserId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAuthorizationDimensions?: Maybe<Array<Maybe<MediaAuthorizationDimension>>>;
  mediaAuthorizationId: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  showCommission: Scalars['Boolean'];
  showFees: Scalars['Boolean'];
  showPaidBy: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Date']>;
};

export type MediaAuthorizationConfirmationInput = {
  crossChannelCampaignVersionNumber?: InputMaybe<Scalars['Int']>;
  mediaAuthorizationId?: InputMaybe<Scalars['Int']>;
  revisionNumber?: InputMaybe<Scalars['Int']>;
};

export type MediaAuthorizationDimension = {
  __typename?: 'MediaAuthorizationDimension';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dimensionLookup?: Maybe<Lookup>;
  dimensionLookupId: Scalars['Int'];
  dimensionOrder: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAuthorization?: Maybe<MediaAuthorization>;
  mediaAuthorizationDimensionId: Scalars['Int'];
  mediaAuthorizationId: Scalars['Int'];
};

export type MediaAuthorizationDimensionFilterInput = {
  and?: InputMaybe<Array<MediaAuthorizationDimensionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dimensionLookup?: InputMaybe<LookupFilterInput>;
  dimensionLookupId?: InputMaybe<IntOperationFilterInput>;
  dimensionOrder?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAuthorization?: InputMaybe<MediaAuthorizationFilterInput>;
  mediaAuthorizationDimensionId?: InputMaybe<IntOperationFilterInput>;
  mediaAuthorizationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaAuthorizationDimensionFilterInput>>;
};

export type MediaAuthorizationDimensionInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  dimensionLookupId?: InputMaybe<Scalars['Int']>;
  dimensionOrder?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaAuthorizationDimensionId?: InputMaybe<Scalars['Int']>;
  mediaAuthorizationId?: InputMaybe<Scalars['Int']>;
};

export type MediaAuthorizationDimension_ForBudgetAllocation = {
  __typename?: 'MediaAuthorizationDimension_ForBudgetAllocation';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dimensionLookup?: Maybe<Lookup>;
  dimensionLookupId: Scalars['Int'];
  dimensionOrder: Scalars['Int'];
  display: Scalars['String'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAuthorization?: Maybe<MediaAuthorization>;
  mediaAuthorizationDimensionId: Scalars['Int'];
  mediaAuthorizationId: Scalars['Int'];
};

export type MediaAuthorizationDimension_ForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<MediaAuthorizationDimension_ForBudgetAllocationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dimensionLookup?: InputMaybe<LookupFilterInput>;
  dimensionLookupId?: InputMaybe<IntOperationFilterInput>;
  dimensionOrder?: InputMaybe<IntOperationFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAuthorization?: InputMaybe<MediaAuthorizationFilterInput>;
  mediaAuthorizationDimensionId?: InputMaybe<IntOperationFilterInput>;
  mediaAuthorizationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaAuthorizationDimension_ForBudgetAllocationFilterInput>>;
};

export type MediaAuthorizationDocumentInput = {
  campaignName?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  clientPurchaseOrderNumber?: InputMaybe<Scalars['String']>;
  createAppUserDescription?: InputMaybe<Scalars['String']>;
  createAppUserEmail?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  isPreview?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  projectNumber?: InputMaybe<Scalars['String']>;
  revisionNumber?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  table?: InputMaybe<MediaAuthorizationReportInputTableInput>;
};

export type MediaAuthorizationFilterInput = {
  and?: InputMaybe<Array<MediaAuthorizationFilterInput>>;
  clientPurchaseOrderNumber?: InputMaybe<StringOperationFilterInput>;
  clientServicesApprovedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  clientServicesApproverAppUser?: InputMaybe<AppUserFilterInput>;
  clientServicesApproverAppUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  confirmationFileReference?: InputMaybe<FileReferenceFilterInput>;
  confirmationFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  dateGranularityLookup?: InputMaybe<LookupFilterInput>;
  dateGranularityLookupId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  financeApprovedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  financeApproverAppUser?: InputMaybe<AppUserFilterInput>;
  financeApproverAppUserId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAuthorizationDimensions?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationDimensionFilterInput>;
  mediaAuthorizationId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MediaAuthorizationFilterInput>>;
  showCommission?: InputMaybe<BooleanOperationFilterInput>;
  showFees?: InputMaybe<BooleanOperationFilterInput>;
  showPaidBy?: InputMaybe<BooleanOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type MediaAuthorizationInput = {
  clientPurchaseOrderNumber?: InputMaybe<Scalars['String']>;
  clientServicesApprovedUtc?: InputMaybe<Scalars['DateTime']>;
  clientServicesApproverAppUserDescription?: InputMaybe<Scalars['String']>;
  clientServicesApproverAppUserId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  confirmationFileReferenceId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignVersionId?: InputMaybe<Scalars['Int']>;
  dateGranularityLookupId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fileReferenceId?: InputMaybe<Scalars['Int']>;
  financeApprovedUtc?: InputMaybe<Scalars['DateTime']>;
  financeApproverAppUserDescription?: InputMaybe<Scalars['String']>;
  financeApproverAppUserId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaAuthorizationDimensions?: InputMaybe<Array<MediaAuthorizationDimensionInput>>;
  mediaAuthorizationDocument?: InputMaybe<MediaAuthorizationDocumentInput>;
  mediaAuthorizationId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  showCommission: Scalars['Boolean'];
  showFees: Scalars['Boolean'];
  showPaidBy: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type MediaAuthorizationReportInputRowInput = {
  cellValues?: InputMaybe<Array<Scalars['String']>>;
};

export type MediaAuthorizationReportInputTableInput = {
  agencyFooterRow?: InputMaybe<MediaAuthorizationReportInputRowInput>;
  clientFooterRow?: InputMaybe<MediaAuthorizationReportInputRowInput>;
  detailRows?: InputMaybe<Array<MediaAuthorizationReportInputRowInput>>;
  footerRow?: InputMaybe<MediaAuthorizationReportInputRowInput>;
  headerRow?: InputMaybe<MediaAuthorizationReportInputRowInput>;
};

export type MediaAuthorization_ForBudgetAllocation = {
  __typename?: 'MediaAuthorization_ForBudgetAllocation';
  clientPurchaseOrderNumber?: Maybe<Scalars['String']>;
  clientServicesApprovedUtc?: Maybe<Scalars['DateTime']>;
  clientServicesApproverAppUser?: Maybe<AppUser>;
  clientServicesApproverAppUserDescription?: Maybe<Scalars['String']>;
  clientServicesApproverAppUserId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  confirmationFilePath?: Maybe<Scalars['String']>;
  confirmationFileReference?: Maybe<FileReference>;
  confirmationFileReferenceId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserDescription?: Maybe<Scalars['String']>;
  createAppUserId: Scalars['Int'];
  crossChannelCampaignVersion?: Maybe<CrossChannelCampaignVersion>;
  crossChannelCampaignVersionId: Scalars['Int'];
  crossChannelCampaignVersionNumber: Scalars['Int'];
  dateGranularityLookup?: Maybe<Lookup>;
  dateGranularityLookupDisplay?: Maybe<Scalars['String']>;
  dateGranularityLookupId: Scalars['Int'];
  dimensions?: Maybe<Array<MediaAuthorizationDimension_ForBudgetAllocation>>;
  endDate?: Maybe<Scalars['Date']>;
  filePath?: Maybe<Scalars['String']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  financeApprovedUtc?: Maybe<Scalars['DateTime']>;
  financeApproverAppUser?: Maybe<AppUser>;
  financeApproverAppUserDescription?: Maybe<Scalars['String']>;
  financeApproverAppUserId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAuthorizationDimensions?: Maybe<Array<Maybe<MediaAuthorizationDimension>>>;
  mediaAuthorizationId: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  showCommission: Scalars['Boolean'];
  showFees: Scalars['Boolean'];
  showPaidBy: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Date']>;
  versionNumber: Scalars['Int'];
};

export type MediaAuthorization_ForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<MediaAuthorization_ForBudgetAllocationFilterInput>>;
  clientPurchaseOrderNumber?: InputMaybe<StringOperationFilterInput>;
  clientServicesApprovedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  clientServicesApproverAppUser?: InputMaybe<AppUserFilterInput>;
  clientServicesApproverAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  clientServicesApproverAppUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  confirmationFilePath?: InputMaybe<StringOperationFilterInput>;
  confirmationFileReference?: InputMaybe<FileReferenceFilterInput>;
  confirmationFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersion?: InputMaybe<CrossChannelCampaignVersionFilterInput>;
  crossChannelCampaignVersionId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignVersionNumber?: InputMaybe<IntOperationFilterInput>;
  dateGranularityLookup?: InputMaybe<LookupFilterInput>;
  dateGranularityLookupDisplay?: InputMaybe<StringOperationFilterInput>;
  dateGranularityLookupId?: InputMaybe<IntOperationFilterInput>;
  dimensions?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationDimension_ForBudgetAllocationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  filePath?: InputMaybe<StringOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  financeApprovedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  financeApproverAppUser?: InputMaybe<AppUserFilterInput>;
  financeApproverAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  financeApproverAppUserId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAuthorizationDimensions?: InputMaybe<ListFilterInputTypeOfMediaAuthorizationDimensionFilterInput>;
  mediaAuthorizationId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MediaAuthorization_ForBudgetAllocationFilterInput>>;
  showCommission?: InputMaybe<BooleanOperationFilterInput>;
  showFees?: InputMaybe<BooleanOperationFilterInput>;
  showPaidBy?: InputMaybe<BooleanOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  versionNumber?: InputMaybe<IntOperationFilterInput>;
};

export type MediaAvail = {
  __typename?: 'MediaAvail';
  allowableDays?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['Date'];
  endTime: Scalars['TimeSpan'];
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAvailId: Scalars['Int'];
  mediaAvailRates?: Maybe<Array<Maybe<MediaAvailRate>>>;
  mediaAvailRatings?: Maybe<Array<Maybe<MediaAvailRating>>>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  startDate: Scalars['Date'];
  startTime: Scalars['TimeSpan'];
};

export type MediaAvailFilterInput = {
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<MediaAvailFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAvailId?: InputMaybe<IntOperationFilterInput>;
  mediaAvailRates?: InputMaybe<ListFilterInputTypeOfMediaAvailRateFilterInput>;
  mediaAvailRatings?: InputMaybe<ListFilterInputTypeOfMediaAvailRatingFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaAvailFilterInput>>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
};

export type MediaAvailRate = {
  __typename?: 'MediaAvailRate';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAvail?: Maybe<MediaAvail>;
  mediaAvailId: Scalars['Int'];
  mediaAvailRateId: Scalars['Int'];
  rate: Scalars['Decimal'];
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId: Scalars['Int'];
};

export type MediaAvailRateFilterInput = {
  and?: InputMaybe<Array<MediaAvailRateFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAvail?: InputMaybe<MediaAvailFilterInput>;
  mediaAvailId?: InputMaybe<IntOperationFilterInput>;
  mediaAvailRateId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaAvailRateFilterInput>>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaAvailRating = {
  __typename?: 'MediaAvailRating';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAvail?: Maybe<MediaAvail>;
  mediaAvailId: Scalars['Int'];
  mediaAvailRatingId: Scalars['Int'];
  rating?: Maybe<Rating>;
  ratingId: Scalars['Int'];
};

export type MediaAvailRatingFilterInput = {
  and?: InputMaybe<Array<MediaAvailRatingFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAvail?: InputMaybe<MediaAvailFilterInput>;
  mediaAvailId?: InputMaybe<IntOperationFilterInput>;
  mediaAvailRatingId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaAvailRatingFilterInput>>;
  rating?: InputMaybe<RatingFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaFormatAttribute = {
  __typename?: 'MediaFormatAttribute';
  inactive: Scalars['Boolean'];
  isDigital: Scalars['Boolean'];
  isHd?: Maybe<Scalars['Boolean']>;
  isViewMediaCandidate: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaFormatAttributeId: Scalars['Int'];
  mediaFormatLookup?: Maybe<Lookup>;
  mediaFormatLookupId: Scalars['Int'];
};

export type MediaFormatAttributeFilterInput = {
  and?: InputMaybe<Array<MediaFormatAttributeFilterInput>>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  isDigital?: InputMaybe<BooleanOperationFilterInput>;
  isHd?: InputMaybe<BooleanOperationFilterInput>;
  isViewMediaCandidate?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaFormatAttributeId?: InputMaybe<IntOperationFilterInput>;
  mediaFormatLookup?: InputMaybe<LookupFilterInput>;
  mediaFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaFormatAttributeFilterInput>>;
};

export type MediaLibrary = {
  __typename?: 'MediaLibrary';
  canShareUniqueAdsAcrossMediaOutlets?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  inactive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaLibraryId: Scalars['Int'];
  mediaOutletGroups?: Maybe<Array<Maybe<MediaOutletGroup>>>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  name?: Maybe<Scalars['String']>;
};

export type MediaLibraryFilterInput = {
  and?: InputMaybe<Array<MediaLibraryFilterInput>>;
  canShareUniqueAdsAcrossMediaOutlets?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaLibraryId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletGroups?: InputMaybe<ListFilterInputTypeOfMediaOutletGroupFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MediaLibraryFilterInput>>;
};

export type MediaLibrarySortInput = {
  canShareUniqueAdsAcrossMediaOutlets?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaLibraryId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type MediaOutlet = {
  __typename?: 'MediaOutlet';
  accountingNotes?: Maybe<Scalars['String']>;
  adInstanceDetails?: Maybe<Array<Maybe<AdInstanceDetail>>>;
  aecomment?: Maybe<Scalars['String']>;
  allowMultipleTrafficAssignments: Scalars['Boolean'];
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  audienceAverageAge?: Maybe<Scalars['Decimal']>;
  audienceAverageIncome?: Maybe<Scalars['Decimal']>;
  audiencePercentFemale?: Maybe<Scalars['Decimal']>;
  autoSchedules?: Maybe<Array<Maybe<AutoSchedule>>>;
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  availProposalDetails?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  badDebtor: Scalars['Boolean'];
  billingCountsBasedOn?: Maybe<Scalars['String']>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  broadcastDay: Scalars['TimeSpan'];
  broadcastDuplicationLayout?: Maybe<DuplicationLayout>;
  broadcastDuplicationLayoutId?: Maybe<Scalars['Int']>;
  budgetAllocationDemandSidePlatformMediaOutlets?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationMediaOutlets?: Maybe<Array<Maybe<BudgetAllocation>>>;
  campaignMediaOutlets?: Maybe<Array<Maybe<CampaignMediaOutlet>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  channel?: Maybe<Scalars['String']>;
  clientApprovals?: Maybe<Array<Maybe<ClientApproval>>>;
  clientMediaOutlets?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clusterLoggedEndDate?: Maybe<Scalars['Date']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  copiedFromMediaOutletId?: Maybe<Scalars['Int']>;
  createHighPriorityDiscreps: Scalars['Boolean'];
  crossChannelCampaignFeePublisherMediaOutlets?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  crossChannelCampaignFeeVendorMediaOutlets?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  description?: Maybe<Scalars['String']>;
  digitalDeliveryEmail?: Maybe<Scalars['String']>;
  digitalDeliveryFilePath?: Maybe<Scalars['String']>;
  digitalDeliveryLogin?: Maybe<Scalars['String']>;
  digitalDeliveryPassword?: Maybe<Scalars['String']>;
  digitalDeliveryUrl?: Maybe<Scalars['String']>;
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  duplicationComment?: Maybe<Scalars['String']>;
  duplicationCostEstimateMediaOutlets?: Maybe<Array<Maybe<DuplicationCostEstimateMediaOutlet>>>;
  excludeFromTitleListings: Scalars['Boolean'];
  fax?: Maybe<Scalars['String']>;
  fileToProcesses?: Maybe<Array<Maybe<FileToProcess>>>;
  frameRateSchemeLookup?: Maybe<Lookup>;
  frameRateSchemeLookupId: Scalars['Int'];
  hdduplicationPreset?: Maybe<DuplicationPreset>;
  hdduplicationPresetId?: Maybe<Scalars['Int']>;
  imsdetections?: Maybe<Array<Maybe<Imsdetection>>>;
  inactive: Scalars['Boolean'];
  insertionOrderGovernedBy?: Maybe<Scalars['String']>;
  insertionOrders?: Maybe<Array<Maybe<InsertionOrder>>>;
  isAor: Scalars['Boolean'];
  isCanadaTelecaster: Scalars['Boolean'];
  isClusterLogged: Scalars['Boolean'];
  isCommissionExempt: Scalars['Boolean'];
  isProgrammatic: Scalars['Boolean'];
  isServiceFeeVendor: Scalars['Boolean'];
  isSffixedMediaCancelable: Scalars['Boolean'];
  isSfnonFixedMediaCancelable: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  ispotNetworkId?: Maybe<Scalars['Int']>;
  languageLookup?: Maybe<Lookup>;
  languageLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Decimal']>;
  lfbrokerPrompt: Scalars['Boolean'];
  lfnearBookingExempt: Scalars['Boolean'];
  lftapeLength?: Maybe<Scalars['String']>;
  lftrafficWindowDays?: Maybe<Scalars['Int']>;
  lfviewTapeRequirementLookup?: Maybe<Lookup>;
  lfviewTapeRequirementLookupId?: Maybe<Scalars['Int']>;
  liveAiringOverride: Scalars['Boolean'];
  logDiscrepancyMinutesThreshold?: Maybe<Scalars['Int']>;
  logFileLineMediaOutlets?: Maybe<Array<Maybe<LogFileLine>>>;
  logFileLineNetworkMediaOutlets?: Maybe<Array<Maybe<LogFileLine>>>;
  longitude?: Maybe<Scalars['Decimal']>;
  mediaAvails?: Maybe<Array<Maybe<MediaAvail>>>;
  mediaClassificationLookup?: Maybe<Lookup>;
  mediaClassificationLookupId?: Maybe<Scalars['Int']>;
  mediaOutletAddresses?: Maybe<Array<Maybe<MediaOutletAddress>>>;
  mediaOutletAffiliateLookup?: Maybe<Lookup>;
  mediaOutletAffiliateLookupId?: Maybe<Scalars['Int']>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaOutletClusterMediaOutletChildren?: Maybe<Array<Maybe<MediaOutletCluster>>>;
  mediaOutletClusterMediaOutletParents?: Maybe<Array<Maybe<MediaOutletCluster>>>;
  mediaOutletContacts?: Maybe<Array<Maybe<MediaOutletContact>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutletGenreLookup?: Maybe<Lookup>;
  mediaOutletGenreLookupId?: Maybe<Scalars['Int']>;
  mediaOutletGroup?: Maybe<MediaOutletGroup>;
  mediaOutletGroupId?: Maybe<Scalars['Int']>;
  mediaOutletId: Scalars['Int'];
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMirrorRotations?: Maybe<Array<Maybe<MediaOutletMirrorRotation>>>;
  mediaOutletOfferings?: Maybe<Array<Maybe<MediaOutletOffering>>>;
  mediaOutletParsers?: Maybe<Array<Maybe<MediaOutletParser>>>;
  mediaOutletPrintFormats?: Maybe<Array<Maybe<MediaOutletPrintFormat>>>;
  mediaOutletPrintTerritories?: Maybe<Array<Maybe<MediaOutletPrintTerritory>>>;
  mediaOutletRates?: Maybe<Array<Maybe<MediaOutletRate>>>;
  mediaOutletRegions?: Maybe<Array<Maybe<MediaOutletRegion>>>;
  mediaOutletRotations?: Maybe<Array<Maybe<MediaOutletRotation>>>;
  mediaOutletType?: Maybe<MediaOutletType>;
  mediaOutletTypeId: Scalars['Int'];
  mediaScheduleDetailMediaOutlets?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailNetworkMediaOutlets?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mriindices?: Maybe<Array<Maybe<Mriindex>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementPimediaOutlets?: Maybe<Array<Maybe<NewCampaignAnnouncementPimediaOutlet>>>;
  noIngestibles: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  oldMediaClassificationLookupId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  outClause?: Maybe<Scalars['String']>;
  overrideMediaLibrary?: Maybe<MediaLibrary>;
  overrideMediaLibraryId?: Maybe<Scalars['Int']>;
  partnerBuyTypeLookup?: Maybe<Lookup>;
  partnerBuyTypeLookupId?: Maybe<Scalars['Int']>;
  paymentTerms?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  piinvoiceCadenceLookup?: Maybe<Lookup>;
  piinvoiceCadenceLookupId?: Maybe<Scalars['Int']>;
  pionly: Scalars['Boolean'];
  printAvails?: Maybe<Array<Maybe<PrintAvail>>>;
  productMediaOutletBlackLists?: Maybe<Array<Maybe<ProductMediaOutletBlackList>>>;
  productMediaOutletPirates?: Maybe<Array<Maybe<ProductMediaOutletPirate>>>;
  prtfrequencyLookup?: Maybe<Lookup>;
  prtfrequencyLookupId?: Maybe<Scalars['Int']>;
  prtpublicationLookup?: Maybe<Lookup>;
  prtpublicationLookupId?: Maybe<Scalars['Int']>;
  prtspecConfirmedDate?: Maybe<Scalars['Date']>;
  radioFrequency?: Maybe<Scalars['String']>;
  rateCards?: Maybe<Array<Maybe<RateCard>>>;
  ratingClaimPercentage?: Maybe<Scalars['Decimal']>;
  rdradioFormatLookup?: Maybe<Lookup>;
  rdradioFormatLookupId?: Maybe<Scalars['Int']>;
  reconcileInvoiceByProductByWeek: Scalars['Boolean'];
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
  regionalMarkets?: Maybe<Scalars['String']>;
  rentrakNetwork?: Maybe<RentrakNetwork>;
  rentrakNetworkId?: Maybe<Scalars['Int']>;
  reportingTerms?: Maybe<Scalars['String']>;
  requiresCustomizedViewMedia?: Maybe<Scalars['Boolean']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  sdduplicationPreset?: Maybe<DuplicationPreset>;
  sdduplicationPresetId?: Maybe<Scalars['Int']>;
  sendsPiinvoice: Scalars['Boolean'];
  sfclearSynAfterOneWeek: Scalars['Boolean'];
  sffillWithDummyPrelogs: Scalars['Boolean'];
  sffillWithVerbalPrelogs: Scalars['Boolean'];
  sffixedMediaMinimumCancellationBusinessDays?: Maybe<Scalars['Int']>;
  sfnonFixedMediaMinimumCancellationBusinessDays?: Maybe<Scalars['Int']>;
  sfpaperworkComment?: Maybe<Scalars['String']>;
  sfpostlogsSentAutomatically: Scalars['Boolean'];
  sfprelogDays?: Maybe<Scalars['String']>;
  sfprelogStatusLookup?: Maybe<Lookup>;
  sfprelogStatusLookupId?: Maybe<Scalars['Int']>;
  sfroviProgramId?: Maybe<Scalars['Int']>;
  sfsyndicatorVendor?: Maybe<Vendor>;
  sfsyndicatorVendorId?: Maybe<Scalars['Int']>;
  specSheetFileReference?: Maybe<FileReference>;
  specSheetFileReferenceId?: Maybe<Scalars['Int']>;
  standardsAndPracticesComment?: Maybe<Scalars['String']>;
  suppressUnevenClearanceAlerts: Scalars['Boolean'];
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  talentFeeClassificationLookup?: Maybe<Lookup>;
  talentFeeClassificationLookupId?: Maybe<Scalars['Int']>;
  timeZoneInfo?: Maybe<TimeZoneInfo>;
  timeZoneInfoId: Scalars['Int'];
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trafficSheetDetailDemandSidePlatformMediaOutlets?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailMediaOutlets?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  tvacceptsDg: Scalars['Boolean'];
  tvacceptsEr: Scalars['Boolean'];
  tvbvsoffset?: Maybe<Scalars['Int']>;
  tvbvstracked: Scalars['Boolean'];
  tvclosedCaptionStatusLookup?: Maybe<Lookup>;
  tvclosedCaptionStatusLookupId?: Maybe<Scalars['Int']>;
  tvhddubRequirementLookup?: Maybe<Lookup>;
  tvhddubRequirementLookupId?: Maybe<Scalars['Int']>;
  tvlocalFeedCoveragePercent?: Maybe<Scalars['Decimal']>;
  tvroviSourceId?: Maybe<Scalars['Long']>;
  tvroviSourceIdOld?: Maybe<Scalars['Int']>;
  tvsigmaTracked: Scalars['Boolean'];
  tvsplitFeed: Scalars['Boolean'];
  tvsupportedMediaTypeLookup?: Maybe<Lookup>;
  tvsupportedMediaTypeLookupId?: Maybe<Scalars['Int']>;
  tvteletraxTracked: Scalars['Boolean'];
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  useExclusiveTrackingCode: Scalars['Boolean'];
  vendors?: Maybe<Array<Maybe<Vendor>>>;
  viewDuplicationLayout?: Maybe<DuplicationLayout>;
  viewDuplicationLayoutId?: Maybe<Scalars['Int']>;
  viewDuplicationPreset?: Maybe<DuplicationPreset>;
  viewDuplicationPresetId?: Maybe<Scalars['Int']>;
  viewSource?: Maybe<ViewSource>;
  viewSourceId?: Maybe<Scalars['Int']>;
  viewers?: Maybe<Scalars['Int']>;
  waveCastDetails?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastMappings?: Maybe<Array<Maybe<WaveCastMapping>>>;
};

export type MediaOutletAddress = {
  __typename?: 'MediaOutletAddress';
  address?: Maybe<Address>;
  addressId: Scalars['Int'];
  addressTypeLookup?: Maybe<Lookup>;
  addressTypeLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  isFreeDelivery: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletAddressId: Scalars['Int'];
  mediaOutletId: Scalars['Int'];
};

export type MediaOutletAddressFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  addressTypeLookup?: InputMaybe<LookupFilterInput>;
  addressTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<MediaOutletAddressFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isFreeDelivery?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletAddressId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletAddressFilterInput>>;
};

export type MediaOutletClientCreditLimit = {
  __typename?: 'MediaOutletClientCreditLimit';
  client?: Maybe<Client>;
  clientCreditLimitTermLookup?: Maybe<Lookup>;
  clientCreditLimitTermLookupId?: Maybe<Scalars['Int']>;
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  creditLimit?: Maybe<Scalars['Decimal']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletClientCreditLimitId: Scalars['Int'];
  mediaOutletGroup?: Maybe<MediaOutletGroup>;
  mediaOutletGroupId?: Maybe<Scalars['Int']>;
  mediaOutletId?: Maybe<Scalars['Int']>;
};

export type MediaOutletClientCreditLimitFilterInput = {
  and?: InputMaybe<Array<MediaOutletClientCreditLimitFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientCreditLimitTermLookup?: InputMaybe<LookupFilterInput>;
  clientCreditLimitTermLookupId?: InputMaybe<IntOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  creditLimit?: InputMaybe<DecimalOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletClientCreditLimitId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletGroup?: InputMaybe<MediaOutletGroupFilterInput>;
  mediaOutletGroupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletClientCreditLimitFilterInput>>;
};

export type MediaOutletCluster = {
  __typename?: 'MediaOutletCluster';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletChild?: Maybe<MediaOutlet>;
  mediaOutletChildId: Scalars['Int'];
  mediaOutletClusterId: Scalars['Int'];
  mediaOutletParent?: Maybe<MediaOutlet>;
  mediaOutletParentId: Scalars['Int'];
};

export type MediaOutletClusterFilterInput = {
  and?: InputMaybe<Array<MediaOutletClusterFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletChild?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletChildId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletClusterId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletParent?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletParentId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletClusterFilterInput>>;
};

export type MediaOutletContact = {
  __typename?: 'MediaOutletContact';
  assistantContact?: Maybe<Contact>;
  assistantContactId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId: Scalars['Int'];
  contactType?: Maybe<ContactType>;
  contactTypeId: Scalars['Int'];
  inactive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletContactId: Scalars['Int'];
  mediaOutletId: Scalars['Int'];
  roviProgramId?: Maybe<Scalars['Int']>;
};

export type MediaOutletContactFilterInput = {
  and?: InputMaybe<Array<MediaOutletContactFilterInput>>;
  assistantContact?: InputMaybe<ContactFilterInput>;
  assistantContactId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  contactType?: InputMaybe<ContactTypeFilterInput>;
  contactTypeId?: InputMaybe<IntOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletContactId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletContactFilterInput>>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaOutletContactInput = {
  assistantContactId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['Int']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactTypeId?: InputMaybe<Scalars['Int']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletContactId?: InputMaybe<Scalars['Int']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  roviProgramId?: InputMaybe<Scalars['Int']>;
};

export type MediaOutletDigitalDeliveryTypeToAddressType = {
  __typename?: 'MediaOutletDigitalDeliveryTypeToAddressType';
  addressTypeLookup?: Maybe<Lookup>;
  addressTypeLookupId: Scalars['Int'];
  digitalDeliveryServiceLookup?: Maybe<Lookup>;
  digitalDeliveryServiceLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
};

export type MediaOutletDigitalDeliveryTypeToAddressTypeFilterInput = {
  addressTypeLookup?: InputMaybe<LookupFilterInput>;
  addressTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<MediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>>;
  digitalDeliveryServiceLookup?: InputMaybe<LookupFilterInput>;
  digitalDeliveryServiceLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletDigitalDeliveryTypeToAddressTypeFilterInput>>;
};

export type MediaOutletFileReference = {
  __typename?: 'MediaOutletFileReference';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  fileReference?: Maybe<FileReference>;
  fileReferenceId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletFileReferenceId: Scalars['Int'];
  mediaOutletFileReferenceLookupType?: Maybe<Lookup>;
  mediaOutletFileReferenceLookupTypeId: Scalars['Int'];
  mediaOutletId: Scalars['Int'];
};

export type MediaOutletFileReferenceFilterInput = {
  and?: InputMaybe<Array<MediaOutletFileReferenceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletFileReferenceLookupType?: InputMaybe<LookupFilterInput>;
  mediaOutletFileReferenceLookupTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletFileReferenceFilterInput>>;
};

export type MediaOutletFilterInput = {
  accountingNotes?: InputMaybe<StringOperationFilterInput>;
  adInstanceDetails?: InputMaybe<ListFilterInputTypeOfAdInstanceDetailFilterInput>;
  aecomment?: InputMaybe<StringOperationFilterInput>;
  allowMultipleTrafficAssignments?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<MediaOutletFilterInput>>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  audienceAverageAge?: InputMaybe<DecimalOperationFilterInput>;
  audienceAverageIncome?: InputMaybe<DecimalOperationFilterInput>;
  audiencePercentFemale?: InputMaybe<DecimalOperationFilterInput>;
  autoSchedules?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  availProposalDetails?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  badDebtor?: InputMaybe<BooleanOperationFilterInput>;
  billingCountsBasedOn?: InputMaybe<StringOperationFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  broadcastDay?: InputMaybe<TimeSpanOperationFilterInput>;
  broadcastDuplicationLayout?: InputMaybe<DuplicationLayoutFilterInput>;
  broadcastDuplicationLayoutId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationDemandSidePlatformMediaOutlets?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationFees?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFeeFilterInput>;
  budgetAllocationMediaOutlets?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  campaignMediaOutlets?: InputMaybe<ListFilterInputTypeOfCampaignMediaOutletFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  channel?: InputMaybe<StringOperationFilterInput>;
  clientApprovals?: InputMaybe<ListFilterInputTypeOfClientApprovalFilterInput>;
  clientMediaOutlets?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clusterLoggedEndDate?: InputMaybe<DateOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  copiedFromMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  createHighPriorityDiscreps?: InputMaybe<BooleanOperationFilterInput>;
  crossChannelCampaignFeePublisherMediaOutlets?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  crossChannelCampaignFeeVendorMediaOutlets?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryEmail?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryFilePath?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryLogin?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryPassword?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryUrl?: InputMaybe<StringOperationFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  duplicationComment?: InputMaybe<StringOperationFilterInput>;
  duplicationCostEstimateMediaOutlets?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateMediaOutletFilterInput>;
  excludeFromTitleListings?: InputMaybe<BooleanOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  fileToProcesses?: InputMaybe<ListFilterInputTypeOfFileToProcessFilterInput>;
  frameRateSchemeLookup?: InputMaybe<LookupFilterInput>;
  frameRateSchemeLookupId?: InputMaybe<IntOperationFilterInput>;
  hdduplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  hdduplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  imsdetections?: InputMaybe<ListFilterInputTypeOfImsdetectionFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrderGovernedBy?: InputMaybe<StringOperationFilterInput>;
  insertionOrders?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  isAor?: InputMaybe<BooleanOperationFilterInput>;
  isCanadaTelecaster?: InputMaybe<BooleanOperationFilterInput>;
  isClusterLogged?: InputMaybe<BooleanOperationFilterInput>;
  isCommissionExempt?: InputMaybe<BooleanOperationFilterInput>;
  isProgrammatic?: InputMaybe<BooleanOperationFilterInput>;
  isServiceFeeVendor?: InputMaybe<BooleanOperationFilterInput>;
  isSffixedMediaCancelable?: InputMaybe<BooleanOperationFilterInput>;
  isSfnonFixedMediaCancelable?: InputMaybe<BooleanOperationFilterInput>;
  isSystem?: InputMaybe<BooleanOperationFilterInput>;
  ispotNetworkId?: InputMaybe<IntOperationFilterInput>;
  languageLookup?: InputMaybe<LookupFilterInput>;
  languageLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  latitude?: InputMaybe<DecimalOperationFilterInput>;
  lfbrokerPrompt?: InputMaybe<BooleanOperationFilterInput>;
  lfnearBookingExempt?: InputMaybe<BooleanOperationFilterInput>;
  lftapeLength?: InputMaybe<StringOperationFilterInput>;
  lftrafficWindowDays?: InputMaybe<IntOperationFilterInput>;
  lfviewTapeRequirementLookup?: InputMaybe<LookupFilterInput>;
  lfviewTapeRequirementLookupId?: InputMaybe<IntOperationFilterInput>;
  liveAiringOverride?: InputMaybe<BooleanOperationFilterInput>;
  logDiscrepancyMinutesThreshold?: InputMaybe<IntOperationFilterInput>;
  logFileLineMediaOutlets?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  logFileLineNetworkMediaOutlets?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  longitude?: InputMaybe<DecimalOperationFilterInput>;
  mediaAvails?: InputMaybe<ListFilterInputTypeOfMediaAvailFilterInput>;
  mediaClassificationLookup?: InputMaybe<LookupFilterInput>;
  mediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletAddresses?: InputMaybe<ListFilterInputTypeOfMediaOutletAddressFilterInput>;
  mediaOutletAffiliateLookup?: InputMaybe<LookupFilterInput>;
  mediaOutletAffiliateLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaOutletClusterMediaOutletChildren?: InputMaybe<ListFilterInputTypeOfMediaOutletClusterFilterInput>;
  mediaOutletClusterMediaOutletParents?: InputMaybe<ListFilterInputTypeOfMediaOutletClusterFilterInput>;
  mediaOutletContacts?: InputMaybe<ListFilterInputTypeOfMediaOutletContactFilterInput>;
  mediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfMediaOutletFileReferenceFilterInput>;
  mediaOutletGenreLookup?: InputMaybe<LookupFilterInput>;
  mediaOutletGenreLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletGroup?: InputMaybe<MediaOutletGroupFilterInput>;
  mediaOutletGroupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMirrorRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletMirrorRotationFilterInput>;
  mediaOutletOfferings?: InputMaybe<ListFilterInputTypeOfMediaOutletOfferingFilterInput>;
  mediaOutletParsers?: InputMaybe<ListFilterInputTypeOfMediaOutletParserFilterInput>;
  mediaOutletPrintFormats?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintFormatFilterInput>;
  mediaOutletPrintTerritories?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintTerritoryFilterInput>;
  mediaOutletRates?: InputMaybe<ListFilterInputTypeOfMediaOutletRateFilterInput>;
  mediaOutletRegions?: InputMaybe<ListFilterInputTypeOfMediaOutletRegionFilterInput>;
  mediaOutletRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletRotationFilterInput>;
  mediaOutletType?: InputMaybe<MediaOutletTypeFilterInput>;
  mediaOutletTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailMediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailNetworkMediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mriindices?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementPimediaOutlets?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPimediaOutletFilterInput>;
  noIngestibles?: InputMaybe<BooleanOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oldMediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletFilterInput>>;
  outClause?: InputMaybe<StringOperationFilterInput>;
  overrideMediaLibrary?: InputMaybe<MediaLibraryFilterInput>;
  overrideMediaLibraryId?: InputMaybe<IntOperationFilterInput>;
  partnerBuyTypeLookup?: InputMaybe<LookupFilterInput>;
  partnerBuyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  paymentTerms?: InputMaybe<StringOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  piinvoiceCadenceLookup?: InputMaybe<LookupFilterInput>;
  piinvoiceCadenceLookupId?: InputMaybe<IntOperationFilterInput>;
  pionly?: InputMaybe<BooleanOperationFilterInput>;
  printAvails?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  productMediaOutletBlackLists?: InputMaybe<ListFilterInputTypeOfProductMediaOutletBlackListFilterInput>;
  productMediaOutletPirates?: InputMaybe<ListFilterInputTypeOfProductMediaOutletPirateFilterInput>;
  prtfrequencyLookup?: InputMaybe<LookupFilterInput>;
  prtfrequencyLookupId?: InputMaybe<IntOperationFilterInput>;
  prtpublicationLookup?: InputMaybe<LookupFilterInput>;
  prtpublicationLookupId?: InputMaybe<IntOperationFilterInput>;
  prtspecConfirmedDate?: InputMaybe<DateOperationFilterInput>;
  radioFrequency?: InputMaybe<StringOperationFilterInput>;
  rateCards?: InputMaybe<ListFilterInputTypeOfRateCardFilterInput>;
  ratingClaimPercentage?: InputMaybe<DecimalOperationFilterInput>;
  rdradioFormatLookup?: InputMaybe<LookupFilterInput>;
  rdradioFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  reconcileInvoiceByProductByWeek?: InputMaybe<BooleanOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  regionalMarkets?: InputMaybe<StringOperationFilterInput>;
  rentrakNetwork?: InputMaybe<RentrakNetworkFilterInput>;
  rentrakNetworkId?: InputMaybe<IntOperationFilterInput>;
  reportingTerms?: InputMaybe<StringOperationFilterInput>;
  requiresCustomizedViewMedia?: InputMaybe<BooleanOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  sdduplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  sdduplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  sendsPiinvoice?: InputMaybe<BooleanOperationFilterInput>;
  sfclearSynAfterOneWeek?: InputMaybe<BooleanOperationFilterInput>;
  sffillWithDummyPrelogs?: InputMaybe<BooleanOperationFilterInput>;
  sffillWithVerbalPrelogs?: InputMaybe<BooleanOperationFilterInput>;
  sffixedMediaMinimumCancellationBusinessDays?: InputMaybe<IntOperationFilterInput>;
  sfnonFixedMediaMinimumCancellationBusinessDays?: InputMaybe<IntOperationFilterInput>;
  sfpaperworkComment?: InputMaybe<StringOperationFilterInput>;
  sfpostlogsSentAutomatically?: InputMaybe<BooleanOperationFilterInput>;
  sfprelogDays?: InputMaybe<StringOperationFilterInput>;
  sfprelogStatusLookup?: InputMaybe<LookupFilterInput>;
  sfprelogStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  sfroviProgramId?: InputMaybe<IntOperationFilterInput>;
  sfsyndicatorVendor?: InputMaybe<VendorFilterInput>;
  sfsyndicatorVendorId?: InputMaybe<IntOperationFilterInput>;
  specSheetFileReference?: InputMaybe<FileReferenceFilterInput>;
  specSheetFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  standardsAndPracticesComment?: InputMaybe<StringOperationFilterInput>;
  suppressUnevenClearanceAlerts?: InputMaybe<BooleanOperationFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  talentFeeClassificationLookup?: InputMaybe<LookupFilterInput>;
  talentFeeClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoFilterInput>;
  timeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
  topLineMediaPlanDetails?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trafficSheetDetailDemandSidePlatformMediaOutlets?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailMediaOutlets?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  tvacceptsDg?: InputMaybe<BooleanOperationFilterInput>;
  tvacceptsEr?: InputMaybe<BooleanOperationFilterInput>;
  tvbvsoffset?: InputMaybe<IntOperationFilterInput>;
  tvbvstracked?: InputMaybe<BooleanOperationFilterInput>;
  tvclosedCaptionStatusLookup?: InputMaybe<LookupFilterInput>;
  tvclosedCaptionStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  tvhddubRequirementLookup?: InputMaybe<LookupFilterInput>;
  tvhddubRequirementLookupId?: InputMaybe<IntOperationFilterInput>;
  tvlocalFeedCoveragePercent?: InputMaybe<DecimalOperationFilterInput>;
  tvroviSourceId?: InputMaybe<LongOperationFilterInput>;
  tvroviSourceIdOld?: InputMaybe<IntOperationFilterInput>;
  tvsigmaTracked?: InputMaybe<BooleanOperationFilterInput>;
  tvsplitFeed?: InputMaybe<BooleanOperationFilterInput>;
  tvsupportedMediaTypeLookup?: InputMaybe<LookupFilterInput>;
  tvsupportedMediaTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  tvteletraxTracked?: InputMaybe<BooleanOperationFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  useExclusiveTrackingCode?: InputMaybe<BooleanOperationFilterInput>;
  vendors?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  viewDuplicationLayout?: InputMaybe<DuplicationLayoutFilterInput>;
  viewDuplicationLayoutId?: InputMaybe<IntOperationFilterInput>;
  viewDuplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  viewDuplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  viewSource?: InputMaybe<ViewSourceFilterInput>;
  viewSourceId?: InputMaybe<IntOperationFilterInput>;
  viewers?: InputMaybe<IntOperationFilterInput>;
  waveCastDetails?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastMappings?: InputMaybe<ListFilterInputTypeOfWaveCastMappingFilterInput>;
};

export type MediaOutletGroup = {
  __typename?: 'MediaOutletGroup';
  availProposals?: Maybe<Array<Maybe<AvailProposal>>>;
  canShareUniqueAdsAcrossMediaOutlets: Scalars['Boolean'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  defaultMediaLibrary?: Maybe<MediaLibrary>;
  defaultMediaLibraryId?: Maybe<Scalars['Int']>;
  inactive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaOutletGroupId: Scalars['Int'];
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  name?: Maybe<Scalars['String']>;
  parsers?: Maybe<Array<Maybe<Parser>>>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
};

export type MediaOutletGroupFilterInput = {
  and?: InputMaybe<Array<MediaOutletGroupFilterInput>>;
  availProposals?: InputMaybe<ListFilterInputTypeOfAvailProposalFilterInput>;
  canShareUniqueAdsAcrossMediaOutlets?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  defaultMediaLibrary?: InputMaybe<MediaLibraryFilterInput>;
  defaultMediaLibraryId?: InputMaybe<IntOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaOutletGroupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletGroupFilterInput>>;
  parsers?: InputMaybe<ListFilterInputTypeOfParserFilterInput>;
  standardsAndPracticesApprovals?: InputMaybe<ListFilterInputTypeOfStandardsAndPracticesApprovalFilterInput>;
};

export type MediaOutletGroupSortInput = {
  canShareUniqueAdsAcrossMediaOutlets?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  defaultMediaLibrary?: InputMaybe<MediaLibrarySortInput>;
  defaultMediaLibraryId?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaOutletGroupId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type MediaOutletInput = {
  billingCountsBasedOn?: InputMaybe<Scalars['String']>;
  broadcastDay?: InputMaybe<Scalars['TimeSpan']>;
  campaignManagerSiteId?: InputMaybe<Scalars['String']>;
  clientMediaOutlets?: InputMaybe<Array<InputMaybe<ClientMediaOutletInput>>>;
  companyId?: InputMaybe<Scalars['Int']>;
  frameRateSchemeLookupId?: InputMaybe<Scalars['Int']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  insertionOrderGovernedBy?: InputMaybe<Scalars['String']>;
  isProgrammatic?: InputMaybe<Scalars['Boolean']>;
  languageLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletContacts?: InputMaybe<Array<InputMaybe<MediaOutletContactInput>>>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  mediaOutletMediaTypes?: InputMaybe<Array<InputMaybe<MediaOutletMediaTypeInput>>>;
  mediaOutletOfferings?: InputMaybe<Array<InputMaybe<MediaOutletOfferingInput>>>;
  mediaOutletTypeId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  outClause?: InputMaybe<Scalars['String']>;
  partnerBuyTypeLookupId?: InputMaybe<Scalars['Int']>;
  paymentTerms?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
  reportingTerms?: InputMaybe<Scalars['String']>;
  timeZoneInfoId?: InputMaybe<Scalars['Int']>;
};

export type MediaOutletMediaType = {
  __typename?: 'MediaOutletMediaType';
  accountingContact?: Maybe<Contact>;
  accountingContactId?: Maybe<Scalars['Int']>;
  accountingNetworkLookup?: Maybe<Lookup>;
  accountingNetworkLookupId?: Maybe<Scalars['Int']>;
  axtypeLookup?: Maybe<Lookup>;
  axtypeLookupId: Scalars['Int'];
  axvendorCode?: Maybe<Scalars['String']>;
  cancellationLeadDays?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  invoiceTypeLookup?: Maybe<Lookup>;
  invoiceTypeLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaFormatHighDefLookup?: Maybe<Lookup>;
  mediaFormatHighDefLookupId?: Maybe<Scalars['Int']>;
  mediaFormatLookup?: Maybe<Lookup>;
  mediaFormatLookupId?: Maybe<Scalars['Int']>;
  mediaFormatViewTapeLookup?: Maybe<Lookup>;
  mediaFormatViewTapeLookupId?: Maybe<Scalars['Int']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletMediaTypeDigitalDeliveryServices?: Maybe<Array<Maybe<MediaOutletMediaTypeDigitalDeliveryService>>>;
  mediaOutletMediaTypeId: Scalars['Int'];
  mediaOutletMediaTypeVendorCodes?: Maybe<Array<Maybe<MediaOutletMediaTypeVendorCode>>>;
  mediaOutletRoles?: Maybe<Array<Maybe<MediaOutletRole>>>;
  mediaTransmissionComment?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  numTapes?: Maybe<Scalars['Int']>;
  requiresCustomizedViewMedia?: Maybe<Scalars['Boolean']>;
  revisionLeadDays?: Maybe<Scalars['Int']>;
  tapeInHouseLeadDays?: Maybe<Scalars['Int']>;
  taxRate: Scalars['Decimal'];
  termLookup?: Maybe<Lookup>;
  termLookupId?: Maybe<Scalars['Int']>;
  tvhddubRequirementLookup?: Maybe<Lookup>;
  tvhddubRequirementLookupId?: Maybe<Scalars['Int']>;
  tvrepFirmVendor?: Maybe<Vendor>;
  tvrepFirmVendorId?: Maybe<Scalars['Int']>;
  viewTapeRequirementLookup?: Maybe<Lookup>;
  viewTapeRequirementLookupId?: Maybe<Scalars['Int']>;
};

export type MediaOutletMediaTypeDigitalDeliveryService = {
  __typename?: 'MediaOutletMediaTypeDigitalDeliveryService';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  digitalDeliveryServiceLookup?: Maybe<Lookup>;
  digitalDeliveryServiceLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletMediaType?: Maybe<MediaOutletMediaType>;
  mediaOutletMediaTypeDigitalDeliveryServiceId: Scalars['Int'];
  mediaOutletMediaTypeId: Scalars['Int'];
};

export type MediaOutletMediaTypeDigitalDeliveryServiceFilterInput = {
  and?: InputMaybe<Array<MediaOutletMediaTypeDigitalDeliveryServiceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  digitalDeliveryServiceLookup?: InputMaybe<LookupFilterInput>;
  digitalDeliveryServiceLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletMediaType?: InputMaybe<MediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeDigitalDeliveryServiceId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletMediaTypeDigitalDeliveryServiceFilterInput>>;
};

export type MediaOutletMediaTypeFilterInput = {
  accountingContact?: InputMaybe<ContactFilterInput>;
  accountingContactId?: InputMaybe<IntOperationFilterInput>;
  accountingNetworkLookup?: InputMaybe<LookupFilterInput>;
  accountingNetworkLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<MediaOutletMediaTypeFilterInput>>;
  axtypeLookup?: InputMaybe<LookupFilterInput>;
  axtypeLookupId?: InputMaybe<IntOperationFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  cancellationLeadDays?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  invoiceTypeLookup?: InputMaybe<LookupFilterInput>;
  invoiceTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaFormatHighDefLookup?: InputMaybe<LookupFilterInput>;
  mediaFormatHighDefLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaFormatLookup?: InputMaybe<LookupFilterInput>;
  mediaFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaFormatViewTapeLookup?: InputMaybe<LookupFilterInput>;
  mediaFormatViewTapeLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypeDigitalDeliveryServices?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeDigitalDeliveryServiceFilterInput>;
  mediaOutletMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypeVendorCodes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeVendorCodeFilterInput>;
  mediaOutletRoles?: InputMaybe<ListFilterInputTypeOfMediaOutletRoleFilterInput>;
  mediaTransmissionComment?: InputMaybe<StringOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  numTapes?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletMediaTypeFilterInput>>;
  requiresCustomizedViewMedia?: InputMaybe<BooleanOperationFilterInput>;
  revisionLeadDays?: InputMaybe<IntOperationFilterInput>;
  tapeInHouseLeadDays?: InputMaybe<IntOperationFilterInput>;
  taxRate?: InputMaybe<DecimalOperationFilterInput>;
  termLookup?: InputMaybe<LookupFilterInput>;
  termLookupId?: InputMaybe<IntOperationFilterInput>;
  tvhddubRequirementLookup?: InputMaybe<LookupFilterInput>;
  tvhddubRequirementLookupId?: InputMaybe<IntOperationFilterInput>;
  tvrepFirmVendor?: InputMaybe<VendorFilterInput>;
  tvrepFirmVendorId?: InputMaybe<IntOperationFilterInput>;
  viewTapeRequirementLookup?: InputMaybe<LookupFilterInput>;
  viewTapeRequirementLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaOutletMediaTypeInput = {
  axTypeLookupId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  display?: InputMaybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  mediaOutletMediaTypeId?: InputMaybe<Scalars['Int']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
};

export type MediaOutletMediaTypeVendorCode = {
  __typename?: 'MediaOutletMediaTypeVendorCode';
  axvendorCode?: Maybe<Scalars['String']>;
  axvendorCodeBuyTypeLookup?: Maybe<Lookup>;
  axvendorCodeBuyTypeLookupId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletMediaType?: Maybe<MediaOutletMediaType>;
  mediaOutletMediaTypeId: Scalars['Int'];
  mediaOutletMediaTypeVendorCodeId: Scalars['Int'];
};

export type MediaOutletMediaTypeVendorCodeFilterInput = {
  and?: InputMaybe<Array<MediaOutletMediaTypeVendorCodeFilterInput>>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  axvendorCodeBuyTypeLookup?: InputMaybe<LookupFilterInput>;
  axvendorCodeBuyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletMediaType?: InputMaybe<MediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypeVendorCodeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletMediaTypeVendorCodeFilterInput>>;
};

export type MediaOutletMediaTypeVendorCode_ForBudgetAllocation = {
  __typename?: 'MediaOutletMediaTypeVendorCode_ForBudgetAllocation';
  axvendorCode?: Maybe<Scalars['String']>;
  axvendorCodeBuyTypeLookup?: Maybe<Lookup>;
  axvendorCodeBuyTypeLookupId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletMediaType?: Maybe<MediaOutletMediaType>;
  mediaOutletMediaTypeId: Scalars['Int'];
  mediaOutletMediaTypeVendorCodeId: Scalars['Int'];
  mediaTypeId: Scalars['Int'];
};

export type MediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<MediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput>>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  axvendorCodeBuyTypeLookup?: InputMaybe<LookupFilterInput>;
  axvendorCodeBuyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletMediaType?: InputMaybe<MediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypeVendorCodeId?: InputMaybe<IntOperationFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput>>;
};

export type MediaOutletMirrorRotation = {
  __typename?: 'MediaOutletMirrorRotation';
  allowableDays?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletMirrorRotationId: Scalars['Int'];
  mirrorEndTime: Scalars['TimeSpan'];
  mirrorStartTime: Scalars['TimeSpan'];
  originalEndTime: Scalars['TimeSpan'];
  originalStartTime: Scalars['TimeSpan'];
};

export type MediaOutletMirrorRotationFilterInput = {
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<MediaOutletMirrorRotationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMirrorRotationId?: InputMaybe<IntOperationFilterInput>;
  mirrorEndTime?: InputMaybe<TimeSpanOperationFilterInput>;
  mirrorStartTime?: InputMaybe<TimeSpanOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletMirrorRotationFilterInput>>;
  originalEndTime?: InputMaybe<TimeSpanOperationFilterInput>;
  originalStartTime?: InputMaybe<TimeSpanOperationFilterInput>;
};

export type MediaOutletOffering = {
  __typename?: 'MediaOutletOffering';
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletOfferingId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
};

export type MediaOutletOfferingFilterInput = {
  and?: InputMaybe<Array<MediaOutletOfferingFilterInput>>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletOfferingId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletOfferingFilterInput>>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
};

export type MediaOutletOfferingInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  mediaOutletOfferingId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MediaOutletOffering_ForBudgetAllocation = {
  __typename?: 'MediaOutletOffering_ForBudgetAllocation';
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  id: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletOfferingId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
};

export type MediaOutletOffering_ForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<MediaOutletOffering_ForBudgetAllocationFilterInput>>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletOfferingId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletOffering_ForBudgetAllocationFilterInput>>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
};

export type MediaOutletParser = {
  __typename?: 'MediaOutletParser';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc: Scalars['DateTime'];
  logType?: Maybe<LogType>;
  logTypeId: Scalars['Int'];
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletParserId: Scalars['Int'];
  parser?: Maybe<Parser>;
  parserId?: Maybe<Scalars['Int']>;
};

export type MediaOutletParserFilterInput = {
  and?: InputMaybe<Array<MediaOutletParserFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logType?: InputMaybe<LogTypeFilterInput>;
  logTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletParserId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletParserFilterInput>>;
  parser?: InputMaybe<ParserFilterInput>;
  parserId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaOutletPrintFormat = {
  __typename?: 'MediaOutletPrintFormat';
  bleed?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  liveArea?: Maybe<Scalars['String']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaOutletPrintFormatId: Scalars['Int'];
  printAdSizeLookup?: Maybe<Lookup>;
  printAdSizeLookupId?: Maybe<Scalars['Int']>;
  trim?: Maybe<Scalars['String']>;
};

export type MediaOutletPrintFormatFilterInput = {
  and?: InputMaybe<Array<MediaOutletPrintFormatFilterInput>>;
  bleed?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  liveArea?: InputMaybe<StringOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletPrintFormatId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletPrintFormatFilterInput>>;
  printAdSizeLookup?: InputMaybe<LookupFilterInput>;
  printAdSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  trim?: InputMaybe<StringOperationFilterInput>;
};

export type MediaOutletPrintTerritory = {
  __typename?: 'MediaOutletPrintTerritory';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletPrintTerritoryId: Scalars['Int'];
  printTerritory?: Maybe<PrintTerritory>;
  printTerritoryId: Scalars['Int'];
};

export type MediaOutletPrintTerritoryFilterInput = {
  and?: InputMaybe<Array<MediaOutletPrintTerritoryFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletPrintTerritoryId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletPrintTerritoryFilterInput>>;
  printTerritory?: InputMaybe<PrintTerritoryFilterInput>;
  printTerritoryId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaOutletRate = {
  __typename?: 'MediaOutletRate';
  brokerVendor?: Maybe<Vendor>;
  brokerVendorId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletRateId: Scalars['Int'];
  netRate?: Maybe<Scalars['Decimal']>;
  openRate?: Maybe<Scalars['Decimal']>;
  printAdSizeLookup?: Maybe<Lookup>;
  printAdSizeLookupId?: Maybe<Scalars['Int']>;
  quoteDate?: Maybe<Scalars['Date']>;
};

export type MediaOutletRateFilterInput = {
  and?: InputMaybe<Array<MediaOutletRateFilterInput>>;
  brokerVendor?: InputMaybe<VendorFilterInput>;
  brokerVendorId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletRateId?: InputMaybe<IntOperationFilterInput>;
  netRate?: InputMaybe<DecimalOperationFilterInput>;
  openRate?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletRateFilterInput>>;
  printAdSizeLookup?: InputMaybe<LookupFilterInput>;
  printAdSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  quoteDate?: InputMaybe<DateOperationFilterInput>;
};

export type MediaOutletRegion = {
  __typename?: 'MediaOutletRegion';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletRegionId: Scalars['Int'];
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
};

export type MediaOutletRegionFilterInput = {
  and?: InputMaybe<Array<MediaOutletRegionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletRegionId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletRegionFilterInput>>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaOutletRole = {
  __typename?: 'MediaOutletRole';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletMediaType?: Maybe<MediaOutletMediaType>;
  mediaOutletMediaTypeId: Scalars['Int'];
  mediaOutletRoleId: Scalars['Int'];
  mediaOutletRoleLookup?: Maybe<Lookup>;
  mediaOutletRoleLookupId: Scalars['Int'];
};

export type MediaOutletRoleFilterInput = {
  and?: InputMaybe<Array<MediaOutletRoleFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletMediaType?: InputMaybe<MediaOutletMediaTypeFilterInput>;
  mediaOutletMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletRoleId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletRoleLookup?: InputMaybe<LookupFilterInput>;
  mediaOutletRoleLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletRoleFilterInput>>;
};

export type MediaOutletRotation = {
  __typename?: 'MediaOutletRotation';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  drrate?: Maybe<Scalars['Decimal']>;
  endTime: Scalars['TimeSpan'];
  fixedRate?: Maybe<Scalars['Decimal']>;
  isLocalBreak: Scalars['Boolean'];
  isMirror: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletRotationId: Scalars['Int'];
  rateCard?: Maybe<RateCard>;
  rateCardId?: Maybe<Scalars['Int']>;
  reducedAudienceRate?: Maybe<Scalars['Decimal']>;
  remnantRate?: Maybe<Scalars['Decimal']>;
  startTime: Scalars['TimeSpan'];
  weekPartLookup?: Maybe<Lookup>;
  weekPartLookupId: Scalars['Int'];
};

export type MediaOutletRotationFilterInput = {
  and?: InputMaybe<Array<MediaOutletRotationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  drrate?: InputMaybe<DecimalOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  fixedRate?: InputMaybe<DecimalOperationFilterInput>;
  isLocalBreak?: InputMaybe<BooleanOperationFilterInput>;
  isMirror?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletRotationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletRotationFilterInput>>;
  rateCard?: InputMaybe<RateCardFilterInput>;
  rateCardId?: InputMaybe<IntOperationFilterInput>;
  reducedAudienceRate?: InputMaybe<DecimalOperationFilterInput>;
  remnantRate?: InputMaybe<DecimalOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  weekPartLookup?: InputMaybe<LookupFilterInput>;
  weekPartLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaOutletSortInput = {
  accountingNotes?: InputMaybe<SortEnumType>;
  aecomment?: InputMaybe<SortEnumType>;
  allowMultipleTrafficAssignments?: InputMaybe<SortEnumType>;
  audienceAverageAge?: InputMaybe<SortEnumType>;
  audienceAverageIncome?: InputMaybe<SortEnumType>;
  audiencePercentFemale?: InputMaybe<SortEnumType>;
  badDebtor?: InputMaybe<SortEnumType>;
  billingCountsBasedOn?: InputMaybe<SortEnumType>;
  broadcastDay?: InputMaybe<SortEnumType>;
  broadcastDuplicationLayout?: InputMaybe<DuplicationLayoutSortInput>;
  broadcastDuplicationLayoutId?: InputMaybe<SortEnumType>;
  channel?: InputMaybe<SortEnumType>;
  clusterLoggedEndDate?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  copiedFromMediaOutletId?: InputMaybe<SortEnumType>;
  createHighPriorityDiscreps?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  digitalDeliveryEmail?: InputMaybe<SortEnumType>;
  digitalDeliveryFilePath?: InputMaybe<SortEnumType>;
  digitalDeliveryLogin?: InputMaybe<SortEnumType>;
  digitalDeliveryPassword?: InputMaybe<SortEnumType>;
  digitalDeliveryUrl?: InputMaybe<SortEnumType>;
  duplicationComment?: InputMaybe<SortEnumType>;
  excludeFromTitleListings?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  frameRateSchemeLookup?: InputMaybe<LookupSortInput>;
  frameRateSchemeLookupId?: InputMaybe<SortEnumType>;
  hdduplicationPreset?: InputMaybe<DuplicationPresetSortInput>;
  hdduplicationPresetId?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  insertionOrderGovernedBy?: InputMaybe<SortEnumType>;
  isAor?: InputMaybe<SortEnumType>;
  isCanadaTelecaster?: InputMaybe<SortEnumType>;
  isClusterLogged?: InputMaybe<SortEnumType>;
  isCommissionExempt?: InputMaybe<SortEnumType>;
  isProgrammatic?: InputMaybe<SortEnumType>;
  isServiceFeeVendor?: InputMaybe<SortEnumType>;
  isSffixedMediaCancelable?: InputMaybe<SortEnumType>;
  isSfnonFixedMediaCancelable?: InputMaybe<SortEnumType>;
  isSystem?: InputMaybe<SortEnumType>;
  ispotNetworkId?: InputMaybe<SortEnumType>;
  languageLookup?: InputMaybe<LookupSortInput>;
  languageLookupId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  lfbrokerPrompt?: InputMaybe<SortEnumType>;
  lfnearBookingExempt?: InputMaybe<SortEnumType>;
  lftapeLength?: InputMaybe<SortEnumType>;
  lftrafficWindowDays?: InputMaybe<SortEnumType>;
  lfviewTapeRequirementLookup?: InputMaybe<LookupSortInput>;
  lfviewTapeRequirementLookupId?: InputMaybe<SortEnumType>;
  liveAiringOverride?: InputMaybe<SortEnumType>;
  logDiscrepancyMinutesThreshold?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  mediaClassificationLookup?: InputMaybe<LookupSortInput>;
  mediaClassificationLookupId?: InputMaybe<SortEnumType>;
  mediaOutletAffiliateLookup?: InputMaybe<LookupSortInput>;
  mediaOutletAffiliateLookupId?: InputMaybe<SortEnumType>;
  mediaOutletGenreLookup?: InputMaybe<LookupSortInput>;
  mediaOutletGenreLookupId?: InputMaybe<SortEnumType>;
  mediaOutletGroup?: InputMaybe<MediaOutletGroupSortInput>;
  mediaOutletGroupId?: InputMaybe<SortEnumType>;
  mediaOutletId?: InputMaybe<SortEnumType>;
  mediaOutletType?: InputMaybe<MediaOutletTypeSortInput>;
  mediaOutletTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  noIngestibles?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  oldMediaClassificationLookupId?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  outClause?: InputMaybe<SortEnumType>;
  overrideMediaLibrary?: InputMaybe<MediaLibrarySortInput>;
  overrideMediaLibraryId?: InputMaybe<SortEnumType>;
  partnerBuyTypeLookup?: InputMaybe<LookupSortInput>;
  partnerBuyTypeLookupId?: InputMaybe<SortEnumType>;
  paymentTerms?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  piinvoiceCadenceLookup?: InputMaybe<LookupSortInput>;
  piinvoiceCadenceLookupId?: InputMaybe<SortEnumType>;
  pionly?: InputMaybe<SortEnumType>;
  prtfrequencyLookup?: InputMaybe<LookupSortInput>;
  prtfrequencyLookupId?: InputMaybe<SortEnumType>;
  prtpublicationLookup?: InputMaybe<LookupSortInput>;
  prtpublicationLookupId?: InputMaybe<SortEnumType>;
  prtspecConfirmedDate?: InputMaybe<SortEnumType>;
  radioFrequency?: InputMaybe<SortEnumType>;
  ratingClaimPercentage?: InputMaybe<SortEnumType>;
  rdradioFormatLookup?: InputMaybe<LookupSortInput>;
  rdradioFormatLookupId?: InputMaybe<SortEnumType>;
  reconcileInvoiceByProductByWeek?: InputMaybe<SortEnumType>;
  region?: InputMaybe<RegionSortInput>;
  regionId?: InputMaybe<SortEnumType>;
  regionalMarkets?: InputMaybe<SortEnumType>;
  rentrakNetwork?: InputMaybe<RentrakNetworkSortInput>;
  rentrakNetworkId?: InputMaybe<SortEnumType>;
  reportingTerms?: InputMaybe<SortEnumType>;
  requiresCustomizedViewMedia?: InputMaybe<SortEnumType>;
  sdduplicationPreset?: InputMaybe<DuplicationPresetSortInput>;
  sdduplicationPresetId?: InputMaybe<SortEnumType>;
  sendsPiinvoice?: InputMaybe<SortEnumType>;
  sfclearSynAfterOneWeek?: InputMaybe<SortEnumType>;
  sffillWithDummyPrelogs?: InputMaybe<SortEnumType>;
  sffillWithVerbalPrelogs?: InputMaybe<SortEnumType>;
  sffixedMediaMinimumCancellationBusinessDays?: InputMaybe<SortEnumType>;
  sfnonFixedMediaMinimumCancellationBusinessDays?: InputMaybe<SortEnumType>;
  sfpaperworkComment?: InputMaybe<SortEnumType>;
  sfpostlogsSentAutomatically?: InputMaybe<SortEnumType>;
  sfprelogDays?: InputMaybe<SortEnumType>;
  sfprelogStatusLookup?: InputMaybe<LookupSortInput>;
  sfprelogStatusLookupId?: InputMaybe<SortEnumType>;
  sfroviProgramId?: InputMaybe<SortEnumType>;
  sfsyndicatorVendor?: InputMaybe<VendorSortInput>;
  sfsyndicatorVendorId?: InputMaybe<SortEnumType>;
  specSheetFileReference?: InputMaybe<FileReferenceSortInput>;
  specSheetFileReferenceId?: InputMaybe<SortEnumType>;
  standardsAndPracticesComment?: InputMaybe<SortEnumType>;
  suppressUnevenClearanceAlerts?: InputMaybe<SortEnumType>;
  talentFeeClassificationLookup?: InputMaybe<LookupSortInput>;
  talentFeeClassificationLookupId?: InputMaybe<SortEnumType>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoSortInput>;
  timeZoneInfoId?: InputMaybe<SortEnumType>;
  tvacceptsDg?: InputMaybe<SortEnumType>;
  tvacceptsEr?: InputMaybe<SortEnumType>;
  tvbvsoffset?: InputMaybe<SortEnumType>;
  tvbvstracked?: InputMaybe<SortEnumType>;
  tvclosedCaptionStatusLookup?: InputMaybe<LookupSortInput>;
  tvclosedCaptionStatusLookupId?: InputMaybe<SortEnumType>;
  tvhddubRequirementLookup?: InputMaybe<LookupSortInput>;
  tvhddubRequirementLookupId?: InputMaybe<SortEnumType>;
  tvlocalFeedCoveragePercent?: InputMaybe<SortEnumType>;
  tvroviSourceId?: InputMaybe<SortEnumType>;
  tvroviSourceIdOld?: InputMaybe<SortEnumType>;
  tvsigmaTracked?: InputMaybe<SortEnumType>;
  tvsplitFeed?: InputMaybe<SortEnumType>;
  tvsupportedMediaTypeLookup?: InputMaybe<LookupSortInput>;
  tvsupportedMediaTypeLookupId?: InputMaybe<SortEnumType>;
  tvteletraxTracked?: InputMaybe<SortEnumType>;
  useExclusiveTrackingCode?: InputMaybe<SortEnumType>;
  viewDuplicationLayout?: InputMaybe<DuplicationLayoutSortInput>;
  viewDuplicationLayoutId?: InputMaybe<SortEnumType>;
  viewDuplicationPreset?: InputMaybe<DuplicationPresetSortInput>;
  viewDuplicationPresetId?: InputMaybe<SortEnumType>;
  viewSource?: InputMaybe<ViewSourceSortInput>;
  viewSourceId?: InputMaybe<SortEnumType>;
  viewers?: InputMaybe<SortEnumType>;
};

export type MediaOutletType = {
  __typename?: 'MediaOutletType';
  allowAverageCostPerPointRatingEstimation: Scalars['Boolean'];
  isDdv: Scalars['Boolean'];
  isNonLinear: Scalars['Boolean'];
  isNotInstanceBased: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletTypeId: Scalars['Int'];
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  vendorServices?: Maybe<Array<Maybe<VendorService>>>;
};

export enum MediaOutletTypeEnum {
  Affiliate = 'AFFILIATE',
  AltMedia = 'ALT_MEDIA',
  Billboard = 'BILLBOARD',
  BillingInsert = 'BILLING_INSERT',
  BindIn = 'BIND_IN',
  BlowIn = 'BLOW_IN',
  BroadcastLocal = 'BROADCAST_LOCAL',
  BroadReach = 'BROAD_REACH',
  Brochures = 'BROCHURES',
  BrochureMailings = 'BROCHURE_MAILINGS',
  CableLocal = 'CABLE_LOCAL',
  CableNational = 'CABLE_NATIONAL',
  CablePackage = 'CABLE_PACKAGE',
  CableRegional = 'CABLE_REGIONAL',
  CardDeck = 'CARD_DECK',
  Cinema = 'CINEMA',
  Conference = 'CONFERENCE',
  CoOp = 'CO_OP',
  Digital = 'DIGITAL',
  DigitalAudio = 'DIGITAL_AUDIO',
  DigitalDisplay = 'DIGITAL_DISPLAY',
  DigitalMulticastNetwork = 'DIGITAL_MULTICAST_NETWORK',
  DigitalPaidSearch = 'DIGITAL_PAID_SEARCH',
  DigitalPaidSocial = 'DIGITAL_PAID_SOCIAL',
  DigitalValueAdded = 'DIGITAL_VALUE_ADDED',
  DigitalVideo = 'DIGITAL_VIDEO',
  Display = 'DISPLAY',
  Email = 'EMAIL',
  EnvelopeMailer = 'ENVELOPE_MAILER',
  FeesTv = 'FEES_TV',
  ImmOrders = 'IMM_ORDERS',
  Internet = 'INTERNET',
  InternetRadio = 'INTERNET_RADIO',
  Magazines = 'MAGAZINES',
  MagOnpage = 'MAG_ONPAGE',
  Mail = 'MAIL',
  Misc = 'MISC',
  Mvpd = 'MVPD',
  NbcNetwork = 'NBC_NETWORK',
  Newspapers = 'NEWSPAPERS',
  NppOnpage = 'NPP_ONPAGE',
  Onsert = 'ONSERT',
  OutboundOrders = 'OUTBOUND_ORDERS',
  OutletOrders = 'OUTLET_ORDERS',
  PaidSearch = 'PAID_SEARCH',
  Passback = 'PASSBACK',
  PayrollInserts = 'PAYROLL_INSERTS',
  Pi = 'PI',
  Pip = 'PIP',
  Podcast = 'PODCAST',
  Poster = 'POSTER',
  PrintAd = 'PRINT_AD',
  Radio = 'RADIO',
  RadioonDemand = 'RADIOON_DEMAND',
  Retail = 'RETAIL',
  RideAlong = 'RIDE_ALONG',
  Satellite = 'SATELLITE',
  Search = 'SEARCH',
  SharedMail = 'SHARED_MAIL',
  SocialMedia = 'SOCIAL_MEDIA',
  SoloFsi = 'SOLO_FSI',
  Streaming = 'STREAMING',
  StreetTeam = 'STREET_TEAM',
  Syndication = 'SYNDICATION',
  TakeOne = 'TAKE_ONE',
  TvNetwork = 'TV_NETWORK',
  Unwired = 'UNWIRED',
  VideoOrders = 'VIDEO_ORDERS',
  Vod = 'VOD',
  Website = 'WEBSITE',
  WebNetwork = 'WEB_NETWORK',
  WebPortal = 'WEB_PORTAL',
  WrapOoh = 'WRAP_OOH',
  WrapPrint = 'WRAP_PRINT',
  YellowPages = 'YELLOW_PAGES'
}

export type MediaOutletTypeFilterInput = {
  allowAverageCostPerPointRatingEstimation?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<MediaOutletTypeFilterInput>>;
  isDdv?: InputMaybe<BooleanOperationFilterInput>;
  isNonLinear?: InputMaybe<BooleanOperationFilterInput>;
  isNotInstanceBased?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutletTypeFilterInput>>;
  vendorServices?: InputMaybe<ListFilterInputTypeOfVendorServiceFilterInput>;
};

export type MediaOutletTypeSortInput = {
  allowAverageCostPerPointRatingEstimation?: InputMaybe<SortEnumType>;
  isDdv?: InputMaybe<SortEnumType>;
  isNonLinear?: InputMaybe<SortEnumType>;
  isNotInstanceBased?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaOutletTypeId?: InputMaybe<SortEnumType>;
  mediaType?: InputMaybe<MediaTypeSortInput>;
  mediaTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
};

export type MediaOutlet_ForBudgetAllocation = {
  __typename?: 'MediaOutlet_ForBudgetAllocation';
  accountingNotes?: Maybe<Scalars['String']>;
  adInstanceDetails?: Maybe<Array<Maybe<AdInstanceDetail>>>;
  aecomment?: Maybe<Scalars['String']>;
  allowMultipleTrafficAssignments: Scalars['Boolean'];
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  audienceAverageAge?: Maybe<Scalars['Decimal']>;
  audienceAverageIncome?: Maybe<Scalars['Decimal']>;
  audiencePercentFemale?: Maybe<Scalars['Decimal']>;
  autoSchedules?: Maybe<Array<Maybe<AutoSchedule>>>;
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  availProposalDetails?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  badDebtor: Scalars['Boolean'];
  billingCountsBasedOn?: Maybe<Scalars['String']>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  broadcastDay: Scalars['TimeSpan'];
  broadcastDuplicationLayout?: Maybe<DuplicationLayout>;
  broadcastDuplicationLayoutId?: Maybe<Scalars['Int']>;
  budgetAllocationDemandSidePlatformMediaOutlets?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationMediaOutlets?: Maybe<Array<Maybe<BudgetAllocation>>>;
  buyType?: Maybe<Scalars['String']>;
  campaignMediaOutlets?: Maybe<Array<Maybe<CampaignMediaOutlet>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  channel?: Maybe<Scalars['String']>;
  clientApprovals?: Maybe<Array<Maybe<ClientApproval>>>;
  clientMediaOutlets?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clusterLoggedEndDate?: Maybe<Scalars['Date']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  copiedFromMediaOutletId?: Maybe<Scalars['Int']>;
  createHighPriorityDiscreps: Scalars['Boolean'];
  crossChannelCampaignFeePublisherMediaOutlets?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  crossChannelCampaignFeeVendorMediaOutlets?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  description?: Maybe<Scalars['String']>;
  digitalDeliveryEmail?: Maybe<Scalars['String']>;
  digitalDeliveryFilePath?: Maybe<Scalars['String']>;
  digitalDeliveryLogin?: Maybe<Scalars['String']>;
  digitalDeliveryPassword?: Maybe<Scalars['String']>;
  digitalDeliveryUrl?: Maybe<Scalars['String']>;
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  duplicationComment?: Maybe<Scalars['String']>;
  duplicationCostEstimateMediaOutlets?: Maybe<Array<Maybe<DuplicationCostEstimateMediaOutlet>>>;
  excludeFromTitleListings: Scalars['Boolean'];
  fax?: Maybe<Scalars['String']>;
  fileToProcesses?: Maybe<Array<Maybe<FileToProcess>>>;
  frameRateSchemeLookup?: Maybe<Lookup>;
  frameRateSchemeLookupId: Scalars['Int'];
  hdduplicationPreset?: Maybe<DuplicationPreset>;
  hdduplicationPresetId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  imsdetections?: Maybe<Array<Maybe<Imsdetection>>>;
  inactive: Scalars['Boolean'];
  insertionOrderGovernedBy?: Maybe<Scalars['String']>;
  insertionOrders?: Maybe<Array<Maybe<InsertionOrder>>>;
  isAor: Scalars['Boolean'];
  isCanadaTelecaster: Scalars['Boolean'];
  isClusterLogged: Scalars['Boolean'];
  isCommissionExempt: Scalars['Boolean'];
  isProgrammatic: Scalars['Boolean'];
  isServiceFeeVendor: Scalars['Boolean'];
  isSffixedMediaCancelable: Scalars['Boolean'];
  isSfnonFixedMediaCancelable: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  ispotNetworkId?: Maybe<Scalars['Int']>;
  languageLookup?: Maybe<Lookup>;
  languageLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Decimal']>;
  lfbrokerPrompt: Scalars['Boolean'];
  lfnearBookingExempt: Scalars['Boolean'];
  lftapeLength?: Maybe<Scalars['String']>;
  lftrafficWindowDays?: Maybe<Scalars['Int']>;
  lfviewTapeRequirementLookup?: Maybe<Lookup>;
  lfviewTapeRequirementLookupId?: Maybe<Scalars['Int']>;
  liveAiringOverride: Scalars['Boolean'];
  logDiscrepancyMinutesThreshold?: Maybe<Scalars['Int']>;
  logFileLineMediaOutlets?: Maybe<Array<Maybe<LogFileLine>>>;
  logFileLineNetworkMediaOutlets?: Maybe<Array<Maybe<LogFileLine>>>;
  longitude?: Maybe<Scalars['Decimal']>;
  mediaAvails?: Maybe<Array<Maybe<MediaAvail>>>;
  mediaClassificationLookup?: Maybe<Lookup>;
  mediaClassificationLookupId?: Maybe<Scalars['Int']>;
  mediaOutletAddresses?: Maybe<Array<Maybe<MediaOutletAddress>>>;
  mediaOutletAffiliateLookup?: Maybe<Lookup>;
  mediaOutletAffiliateLookupId?: Maybe<Scalars['Int']>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaOutletClusterMediaOutletChildren?: Maybe<Array<Maybe<MediaOutletCluster>>>;
  mediaOutletClusterMediaOutletParents?: Maybe<Array<Maybe<MediaOutletCluster>>>;
  mediaOutletContacts?: Maybe<Array<Maybe<MediaOutletContact>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutletGenreLookup?: Maybe<Lookup>;
  mediaOutletGenreLookupId?: Maybe<Scalars['Int']>;
  mediaOutletGroup?: Maybe<MediaOutletGroup>;
  mediaOutletGroupId?: Maybe<Scalars['Int']>;
  mediaOutletId: Scalars['Int'];
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMirrorRotations?: Maybe<Array<Maybe<MediaOutletMirrorRotation>>>;
  mediaOutletOfferings?: Maybe<Array<Maybe<MediaOutletOffering>>>;
  mediaOutletParsers?: Maybe<Array<Maybe<MediaOutletParser>>>;
  mediaOutletPrintFormats?: Maybe<Array<Maybe<MediaOutletPrintFormat>>>;
  mediaOutletPrintTerritories?: Maybe<Array<Maybe<MediaOutletPrintTerritory>>>;
  mediaOutletRates?: Maybe<Array<Maybe<MediaOutletRate>>>;
  mediaOutletRegions?: Maybe<Array<Maybe<MediaOutletRegion>>>;
  mediaOutletRotations?: Maybe<Array<Maybe<MediaOutletRotation>>>;
  mediaOutletType?: Maybe<MediaOutletType>;
  mediaOutletTypeId: Scalars['Int'];
  mediaScheduleDetailMediaOutlets?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailNetworkMediaOutlets?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mriindices?: Maybe<Array<Maybe<Mriindex>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementPimediaOutlets?: Maybe<Array<Maybe<NewCampaignAnnouncementPimediaOutlet>>>;
  noIngestibles: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  oldMediaClassificationLookupId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  outClause?: Maybe<Scalars['String']>;
  overrideMediaLibrary?: Maybe<MediaLibrary>;
  overrideMediaLibraryId?: Maybe<Scalars['Int']>;
  partnerBuyTypeLookup?: Maybe<Lookup>;
  partnerBuyTypeLookupId?: Maybe<Scalars['Int']>;
  paymentTerms?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  piinvoiceCadenceLookup?: Maybe<Lookup>;
  piinvoiceCadenceLookupId?: Maybe<Scalars['Int']>;
  pionly: Scalars['Boolean'];
  printAvails?: Maybe<Array<Maybe<PrintAvail>>>;
  productMediaOutletBlackLists?: Maybe<Array<Maybe<ProductMediaOutletBlackList>>>;
  productMediaOutletPirates?: Maybe<Array<Maybe<ProductMediaOutletPirate>>>;
  prtfrequencyLookup?: Maybe<Lookup>;
  prtfrequencyLookupId?: Maybe<Scalars['Int']>;
  prtpublicationLookup?: Maybe<Lookup>;
  prtpublicationLookupId?: Maybe<Scalars['Int']>;
  prtspecConfirmedDate?: Maybe<Scalars['Date']>;
  radioFrequency?: Maybe<Scalars['String']>;
  rateCards?: Maybe<Array<Maybe<RateCard>>>;
  ratingClaimPercentage?: Maybe<Scalars['Decimal']>;
  rdradioFormatLookup?: Maybe<Lookup>;
  rdradioFormatLookupId?: Maybe<Scalars['Int']>;
  reconcileInvoiceByProductByWeek: Scalars['Boolean'];
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
  regionalMarkets?: Maybe<Scalars['String']>;
  rentrakNetwork?: Maybe<RentrakNetwork>;
  rentrakNetworkId?: Maybe<Scalars['Int']>;
  reportingTerms?: Maybe<Scalars['String']>;
  requiresCustomizedViewMedia?: Maybe<Scalars['Boolean']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  sdduplicationPreset?: Maybe<DuplicationPreset>;
  sdduplicationPresetId?: Maybe<Scalars['Int']>;
  sendsPiinvoice: Scalars['Boolean'];
  sfclearSynAfterOneWeek: Scalars['Boolean'];
  sffillWithDummyPrelogs: Scalars['Boolean'];
  sffillWithVerbalPrelogs: Scalars['Boolean'];
  sffixedMediaMinimumCancellationBusinessDays?: Maybe<Scalars['Int']>;
  sfnonFixedMediaMinimumCancellationBusinessDays?: Maybe<Scalars['Int']>;
  sfpaperworkComment?: Maybe<Scalars['String']>;
  sfpostlogsSentAutomatically: Scalars['Boolean'];
  sfprelogDays?: Maybe<Scalars['String']>;
  sfprelogStatusLookup?: Maybe<Lookup>;
  sfprelogStatusLookupId?: Maybe<Scalars['Int']>;
  sfroviProgramId?: Maybe<Scalars['Int']>;
  sfsyndicatorVendor?: Maybe<Vendor>;
  sfsyndicatorVendorId?: Maybe<Scalars['Int']>;
  specSheetFileReference?: Maybe<FileReference>;
  specSheetFileReferenceId?: Maybe<Scalars['Int']>;
  standardsAndPracticesComment?: Maybe<Scalars['String']>;
  suppressUnevenClearanceAlerts: Scalars['Boolean'];
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  talentFeeClassificationLookup?: Maybe<Lookup>;
  talentFeeClassificationLookupId?: Maybe<Scalars['Int']>;
  timeZoneInfo?: Maybe<TimeZoneInfo>;
  timeZoneInfoId: Scalars['Int'];
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trafficSheetDetailDemandSidePlatformMediaOutlets?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailMediaOutlets?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  tvacceptsDg: Scalars['Boolean'];
  tvacceptsEr: Scalars['Boolean'];
  tvbvsoffset?: Maybe<Scalars['Int']>;
  tvbvstracked: Scalars['Boolean'];
  tvclosedCaptionStatusLookup?: Maybe<Lookup>;
  tvclosedCaptionStatusLookupId?: Maybe<Scalars['Int']>;
  tvhddubRequirementLookup?: Maybe<Lookup>;
  tvhddubRequirementLookupId?: Maybe<Scalars['Int']>;
  tvlocalFeedCoveragePercent?: Maybe<Scalars['Decimal']>;
  tvroviSourceId?: Maybe<Scalars['Long']>;
  tvroviSourceIdOld?: Maybe<Scalars['Int']>;
  tvsigmaTracked: Scalars['Boolean'];
  tvsplitFeed: Scalars['Boolean'];
  tvsupportedMediaTypeLookup?: Maybe<Lookup>;
  tvsupportedMediaTypeLookupId?: Maybe<Scalars['Int']>;
  tvteletraxTracked: Scalars['Boolean'];
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  useExclusiveTrackingCode: Scalars['Boolean'];
  vendorCodes?: Maybe<Array<MediaOutletMediaTypeVendorCode_ForBudgetAllocation>>;
  vendors?: Maybe<Array<Maybe<Vendor>>>;
  viewDuplicationLayout?: Maybe<DuplicationLayout>;
  viewDuplicationLayoutId?: Maybe<Scalars['Int']>;
  viewDuplicationPreset?: Maybe<DuplicationPreset>;
  viewDuplicationPresetId?: Maybe<Scalars['Int']>;
  viewSource?: Maybe<ViewSource>;
  viewSourceId?: Maybe<Scalars['Int']>;
  viewers?: Maybe<Scalars['Int']>;
  waveCastDetails?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastMappings?: Maybe<Array<Maybe<WaveCastMapping>>>;
};

export type MediaOutlet_ForBudgetAllocationFilterInput = {
  accountingNotes?: InputMaybe<StringOperationFilterInput>;
  adInstanceDetails?: InputMaybe<ListFilterInputTypeOfAdInstanceDetailFilterInput>;
  aecomment?: InputMaybe<StringOperationFilterInput>;
  allowMultipleTrafficAssignments?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<MediaOutlet_ForBudgetAllocationFilterInput>>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  audienceAverageAge?: InputMaybe<DecimalOperationFilterInput>;
  audienceAverageIncome?: InputMaybe<DecimalOperationFilterInput>;
  audiencePercentFemale?: InputMaybe<DecimalOperationFilterInput>;
  autoSchedules?: InputMaybe<ListFilterInputTypeOfAutoScheduleFilterInput>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  availProposalDetails?: InputMaybe<ListFilterInputTypeOfAvailProposalDetailFilterInput>;
  badDebtor?: InputMaybe<BooleanOperationFilterInput>;
  billingCountsBasedOn?: InputMaybe<StringOperationFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  broadcastDay?: InputMaybe<TimeSpanOperationFilterInput>;
  broadcastDuplicationLayout?: InputMaybe<DuplicationLayoutFilterInput>;
  broadcastDuplicationLayoutId?: InputMaybe<IntOperationFilterInput>;
  budgetAllocationDemandSidePlatformMediaOutlets?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  budgetAllocationFees?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFeeFilterInput>;
  budgetAllocationMediaOutlets?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  buyType?: InputMaybe<StringOperationFilterInput>;
  campaignMediaOutlets?: InputMaybe<ListFilterInputTypeOfCampaignMediaOutletFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  channel?: InputMaybe<StringOperationFilterInput>;
  clientApprovals?: InputMaybe<ListFilterInputTypeOfClientApprovalFilterInput>;
  clientMediaOutlets?: InputMaybe<ListFilterInputTypeOfClientMediaOutletFilterInput>;
  clusterLoggedEndDate?: InputMaybe<DateOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  copiedFromMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  createHighPriorityDiscreps?: InputMaybe<BooleanOperationFilterInput>;
  crossChannelCampaignFeePublisherMediaOutlets?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  crossChannelCampaignFeeVendorMediaOutlets?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryEmail?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryFilePath?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryLogin?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryPassword?: InputMaybe<StringOperationFilterInput>;
  digitalDeliveryUrl?: InputMaybe<StringOperationFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  duplicationComment?: InputMaybe<StringOperationFilterInput>;
  duplicationCostEstimateMediaOutlets?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateMediaOutletFilterInput>;
  excludeFromTitleListings?: InputMaybe<BooleanOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  fileToProcesses?: InputMaybe<ListFilterInputTypeOfFileToProcessFilterInput>;
  frameRateSchemeLookup?: InputMaybe<LookupFilterInput>;
  frameRateSchemeLookupId?: InputMaybe<IntOperationFilterInput>;
  hdduplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  hdduplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  imsdetections?: InputMaybe<ListFilterInputTypeOfImsdetectionFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrderGovernedBy?: InputMaybe<StringOperationFilterInput>;
  insertionOrders?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  isAor?: InputMaybe<BooleanOperationFilterInput>;
  isCanadaTelecaster?: InputMaybe<BooleanOperationFilterInput>;
  isClusterLogged?: InputMaybe<BooleanOperationFilterInput>;
  isCommissionExempt?: InputMaybe<BooleanOperationFilterInput>;
  isProgrammatic?: InputMaybe<BooleanOperationFilterInput>;
  isServiceFeeVendor?: InputMaybe<BooleanOperationFilterInput>;
  isSffixedMediaCancelable?: InputMaybe<BooleanOperationFilterInput>;
  isSfnonFixedMediaCancelable?: InputMaybe<BooleanOperationFilterInput>;
  isSystem?: InputMaybe<BooleanOperationFilterInput>;
  ispotNetworkId?: InputMaybe<IntOperationFilterInput>;
  languageLookup?: InputMaybe<LookupFilterInput>;
  languageLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  latitude?: InputMaybe<DecimalOperationFilterInput>;
  lfbrokerPrompt?: InputMaybe<BooleanOperationFilterInput>;
  lfnearBookingExempt?: InputMaybe<BooleanOperationFilterInput>;
  lftapeLength?: InputMaybe<StringOperationFilterInput>;
  lftrafficWindowDays?: InputMaybe<IntOperationFilterInput>;
  lfviewTapeRequirementLookup?: InputMaybe<LookupFilterInput>;
  lfviewTapeRequirementLookupId?: InputMaybe<IntOperationFilterInput>;
  liveAiringOverride?: InputMaybe<BooleanOperationFilterInput>;
  logDiscrepancyMinutesThreshold?: InputMaybe<IntOperationFilterInput>;
  logFileLineMediaOutlets?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  logFileLineNetworkMediaOutlets?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  longitude?: InputMaybe<DecimalOperationFilterInput>;
  mediaAvails?: InputMaybe<ListFilterInputTypeOfMediaAvailFilterInput>;
  mediaClassificationLookup?: InputMaybe<LookupFilterInput>;
  mediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletAddresses?: InputMaybe<ListFilterInputTypeOfMediaOutletAddressFilterInput>;
  mediaOutletAffiliateLookup?: InputMaybe<LookupFilterInput>;
  mediaOutletAffiliateLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletClientCreditLimits?: InputMaybe<ListFilterInputTypeOfMediaOutletClientCreditLimitFilterInput>;
  mediaOutletClusterMediaOutletChildren?: InputMaybe<ListFilterInputTypeOfMediaOutletClusterFilterInput>;
  mediaOutletClusterMediaOutletParents?: InputMaybe<ListFilterInputTypeOfMediaOutletClusterFilterInput>;
  mediaOutletContacts?: InputMaybe<ListFilterInputTypeOfMediaOutletContactFilterInput>;
  mediaOutletFileReferences?: InputMaybe<ListFilterInputTypeOfMediaOutletFileReferenceFilterInput>;
  mediaOutletGenreLookup?: InputMaybe<LookupFilterInput>;
  mediaOutletGenreLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletGroup?: InputMaybe<MediaOutletGroupFilterInput>;
  mediaOutletGroupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletMirrorRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletMirrorRotationFilterInput>;
  mediaOutletOfferings?: InputMaybe<ListFilterInputTypeOfMediaOutletOfferingFilterInput>;
  mediaOutletParsers?: InputMaybe<ListFilterInputTypeOfMediaOutletParserFilterInput>;
  mediaOutletPrintFormats?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintFormatFilterInput>;
  mediaOutletPrintTerritories?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintTerritoryFilterInput>;
  mediaOutletRates?: InputMaybe<ListFilterInputTypeOfMediaOutletRateFilterInput>;
  mediaOutletRegions?: InputMaybe<ListFilterInputTypeOfMediaOutletRegionFilterInput>;
  mediaOutletRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletRotationFilterInput>;
  mediaOutletType?: InputMaybe<MediaOutletTypeFilterInput>;
  mediaOutletTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailMediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDetailNetworkMediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mriindices?: InputMaybe<ListFilterInputTypeOfMriindexFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementPimediaOutlets?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPimediaOutletFilterInput>;
  noIngestibles?: InputMaybe<BooleanOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oldMediaClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaOutlet_ForBudgetAllocationFilterInput>>;
  outClause?: InputMaybe<StringOperationFilterInput>;
  overrideMediaLibrary?: InputMaybe<MediaLibraryFilterInput>;
  overrideMediaLibraryId?: InputMaybe<IntOperationFilterInput>;
  partnerBuyTypeLookup?: InputMaybe<LookupFilterInput>;
  partnerBuyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  paymentTerms?: InputMaybe<StringOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  piinvoiceCadenceLookup?: InputMaybe<LookupFilterInput>;
  piinvoiceCadenceLookupId?: InputMaybe<IntOperationFilterInput>;
  pionly?: InputMaybe<BooleanOperationFilterInput>;
  printAvails?: InputMaybe<ListFilterInputTypeOfPrintAvailFilterInput>;
  productMediaOutletBlackLists?: InputMaybe<ListFilterInputTypeOfProductMediaOutletBlackListFilterInput>;
  productMediaOutletPirates?: InputMaybe<ListFilterInputTypeOfProductMediaOutletPirateFilterInput>;
  prtfrequencyLookup?: InputMaybe<LookupFilterInput>;
  prtfrequencyLookupId?: InputMaybe<IntOperationFilterInput>;
  prtpublicationLookup?: InputMaybe<LookupFilterInput>;
  prtpublicationLookupId?: InputMaybe<IntOperationFilterInput>;
  prtspecConfirmedDate?: InputMaybe<DateOperationFilterInput>;
  radioFrequency?: InputMaybe<StringOperationFilterInput>;
  rateCards?: InputMaybe<ListFilterInputTypeOfRateCardFilterInput>;
  ratingClaimPercentage?: InputMaybe<DecimalOperationFilterInput>;
  rdradioFormatLookup?: InputMaybe<LookupFilterInput>;
  rdradioFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  reconcileInvoiceByProductByWeek?: InputMaybe<BooleanOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  regionalMarkets?: InputMaybe<StringOperationFilterInput>;
  rentrakNetwork?: InputMaybe<RentrakNetworkFilterInput>;
  rentrakNetworkId?: InputMaybe<IntOperationFilterInput>;
  reportingTerms?: InputMaybe<StringOperationFilterInput>;
  requiresCustomizedViewMedia?: InputMaybe<BooleanOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  sdduplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  sdduplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  sendsPiinvoice?: InputMaybe<BooleanOperationFilterInput>;
  sfclearSynAfterOneWeek?: InputMaybe<BooleanOperationFilterInput>;
  sffillWithDummyPrelogs?: InputMaybe<BooleanOperationFilterInput>;
  sffillWithVerbalPrelogs?: InputMaybe<BooleanOperationFilterInput>;
  sffixedMediaMinimumCancellationBusinessDays?: InputMaybe<IntOperationFilterInput>;
  sfnonFixedMediaMinimumCancellationBusinessDays?: InputMaybe<IntOperationFilterInput>;
  sfpaperworkComment?: InputMaybe<StringOperationFilterInput>;
  sfpostlogsSentAutomatically?: InputMaybe<BooleanOperationFilterInput>;
  sfprelogDays?: InputMaybe<StringOperationFilterInput>;
  sfprelogStatusLookup?: InputMaybe<LookupFilterInput>;
  sfprelogStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  sfroviProgramId?: InputMaybe<IntOperationFilterInput>;
  sfsyndicatorVendor?: InputMaybe<VendorFilterInput>;
  sfsyndicatorVendorId?: InputMaybe<IntOperationFilterInput>;
  specSheetFileReference?: InputMaybe<FileReferenceFilterInput>;
  specSheetFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  standardsAndPracticesComment?: InputMaybe<StringOperationFilterInput>;
  suppressUnevenClearanceAlerts?: InputMaybe<BooleanOperationFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  talentFeeClassificationLookup?: InputMaybe<LookupFilterInput>;
  talentFeeClassificationLookupId?: InputMaybe<IntOperationFilterInput>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoFilterInput>;
  timeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
  topLineMediaPlanDetails?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trafficSheetDetailDemandSidePlatformMediaOutlets?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetDetailMediaOutlets?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  tvacceptsDg?: InputMaybe<BooleanOperationFilterInput>;
  tvacceptsEr?: InputMaybe<BooleanOperationFilterInput>;
  tvbvsoffset?: InputMaybe<IntOperationFilterInput>;
  tvbvstracked?: InputMaybe<BooleanOperationFilterInput>;
  tvclosedCaptionStatusLookup?: InputMaybe<LookupFilterInput>;
  tvclosedCaptionStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  tvhddubRequirementLookup?: InputMaybe<LookupFilterInput>;
  tvhddubRequirementLookupId?: InputMaybe<IntOperationFilterInput>;
  tvlocalFeedCoveragePercent?: InputMaybe<DecimalOperationFilterInput>;
  tvroviSourceId?: InputMaybe<LongOperationFilterInput>;
  tvroviSourceIdOld?: InputMaybe<IntOperationFilterInput>;
  tvsigmaTracked?: InputMaybe<BooleanOperationFilterInput>;
  tvsplitFeed?: InputMaybe<BooleanOperationFilterInput>;
  tvsupportedMediaTypeLookup?: InputMaybe<LookupFilterInput>;
  tvsupportedMediaTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  tvteletraxTracked?: InputMaybe<BooleanOperationFilterInput>;
  uniqueAds?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  useExclusiveTrackingCode?: InputMaybe<BooleanOperationFilterInput>;
  vendorCodes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeVendorCode_ForBudgetAllocationFilterInput>;
  vendors?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
  viewDuplicationLayout?: InputMaybe<DuplicationLayoutFilterInput>;
  viewDuplicationLayoutId?: InputMaybe<IntOperationFilterInput>;
  viewDuplicationPreset?: InputMaybe<DuplicationPresetFilterInput>;
  viewDuplicationPresetId?: InputMaybe<IntOperationFilterInput>;
  viewSource?: InputMaybe<ViewSourceFilterInput>;
  viewSourceId?: InputMaybe<IntOperationFilterInput>;
  viewers?: InputMaybe<IntOperationFilterInput>;
  waveCastDetails?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastMappings?: InputMaybe<ListFilterInputTypeOfWaveCastMappingFilterInput>;
};

export type MediaPlan = {
  __typename?: 'MediaPlan';
  budget?: Maybe<Scalars['Decimal']>;
  budgetNote?: Maybe<Scalars['String']>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  demoNote?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  flightEnd: Scalars['Date'];
  flightStart: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mastersAvailable?: Maybe<Scalars['String']>;
  mediaPlanExportExcelOptions?: Maybe<Array<Maybe<MediaPlanExportExcelOption>>>;
  mediaPlanId: Scalars['Int'];
  mediaPlanNielsenDemos?: Maybe<Array<Maybe<MediaPlanNielsenDemo>>>;
  mediaPlanRentrakDemographics?: Maybe<Array<Maybe<MediaPlanRentrakDemographic>>>;
  mediaPlanTapeLengths?: Maybe<Array<Maybe<MediaPlanTapeLength>>>;
  mediaPlanTypeLookup?: Maybe<Lookup>;
  mediaPlanTypeLookupId?: Maybe<Scalars['Int']>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mpformatOrLength?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  offer?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  tmhours?: Maybe<Scalars['String']>;
  viewTapeAvailable?: Maybe<Scalars['String']>;
};

export type MediaPlanExportExcelOption = {
  __typename?: 'MediaPlanExportExcelOption';
  adNameSubtotals: Scalars['Boolean'];
  amountTypeLookup?: Maybe<Lookup>;
  amountTypeLookupId: Scalars['Int'];
  attributeSubtotals: Scalars['Boolean'];
  averageWeekDaypart: Scalars['Boolean'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dayOfWeekSubtotals: Scalars['Boolean'];
  displayC3ratings: Scalars['Boolean'];
  displayLiveRatings: Scalars['Boolean'];
  displayPlus3Ratings: Scalars['Boolean'];
  displaySummaryWorksheet: Scalars['Boolean'];
  grpsByWeek: Scalars['Boolean'];
  hideDayPartTotals: Scalars['Boolean'];
  hideTiers: Scalars['Boolean'];
  includeStationDaypartBreakout: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletTypeSubtotals: Scalars['Boolean'];
  mediaPlan?: Maybe<MediaPlan>;
  mediaPlanExportExcelOptionsId: Scalars['Int'];
  mediaPlanId: Scalars['Int'];
  showEstimatedDubCosts: Scalars['Boolean'];
  showGenres: Scalars['Boolean'];
  showKpicolumn: Scalars['Boolean'];
  showMediaClassifications: Scalars['Boolean'];
  showOnlyImpressions: Scalars['Boolean'];
  showZeroSpotRotations: Scalars['Boolean'];
  spendByWeek: Scalars['Boolean'];
  stationSubtotals: Scalars['Boolean'];
};

export type MediaPlanExportExcelOptionFilterInput = {
  adNameSubtotals?: InputMaybe<BooleanOperationFilterInput>;
  amountTypeLookup?: InputMaybe<LookupFilterInput>;
  amountTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<MediaPlanExportExcelOptionFilterInput>>;
  attributeSubtotals?: InputMaybe<BooleanOperationFilterInput>;
  averageWeekDaypart?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dayOfWeekSubtotals?: InputMaybe<BooleanOperationFilterInput>;
  displayC3ratings?: InputMaybe<BooleanOperationFilterInput>;
  displayLiveRatings?: InputMaybe<BooleanOperationFilterInput>;
  displayPlus3Ratings?: InputMaybe<BooleanOperationFilterInput>;
  displaySummaryWorksheet?: InputMaybe<BooleanOperationFilterInput>;
  grpsByWeek?: InputMaybe<BooleanOperationFilterInput>;
  hideDayPartTotals?: InputMaybe<BooleanOperationFilterInput>;
  hideTiers?: InputMaybe<BooleanOperationFilterInput>;
  includeStationDaypartBreakout?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletTypeSubtotals?: InputMaybe<BooleanOperationFilterInput>;
  mediaPlan?: InputMaybe<MediaPlanFilterInput>;
  mediaPlanExportExcelOptionsId?: InputMaybe<IntOperationFilterInput>;
  mediaPlanId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaPlanExportExcelOptionFilterInput>>;
  showEstimatedDubCosts?: InputMaybe<BooleanOperationFilterInput>;
  showGenres?: InputMaybe<BooleanOperationFilterInput>;
  showKpicolumn?: InputMaybe<BooleanOperationFilterInput>;
  showMediaClassifications?: InputMaybe<BooleanOperationFilterInput>;
  showOnlyImpressions?: InputMaybe<BooleanOperationFilterInput>;
  showZeroSpotRotations?: InputMaybe<BooleanOperationFilterInput>;
  spendByWeek?: InputMaybe<BooleanOperationFilterInput>;
  stationSubtotals?: InputMaybe<BooleanOperationFilterInput>;
};

export type MediaPlanFilterInput = {
  and?: InputMaybe<Array<MediaPlanFilterInput>>;
  budget?: InputMaybe<DecimalOperationFilterInput>;
  budgetNote?: InputMaybe<StringOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  demoNote?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dueDate?: InputMaybe<DateOperationFilterInput>;
  flightEnd?: InputMaybe<DateOperationFilterInput>;
  flightStart?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mastersAvailable?: InputMaybe<StringOperationFilterInput>;
  mediaPlanExportExcelOptions?: InputMaybe<ListFilterInputTypeOfMediaPlanExportExcelOptionFilterInput>;
  mediaPlanId?: InputMaybe<IntOperationFilterInput>;
  mediaPlanNielsenDemos?: InputMaybe<ListFilterInputTypeOfMediaPlanNielsenDemoFilterInput>;
  mediaPlanRentrakDemographics?: InputMaybe<ListFilterInputTypeOfMediaPlanRentrakDemographicFilterInput>;
  mediaPlanTapeLengths?: InputMaybe<ListFilterInputTypeOfMediaPlanTapeLengthFilterInput>;
  mediaPlanTypeLookup?: InputMaybe<LookupFilterInput>;
  mediaPlanTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mpformatOrLength?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  objective?: InputMaybe<StringOperationFilterInput>;
  offer?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaPlanFilterInput>>;
  price?: InputMaybe<StringOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  tmhours?: InputMaybe<StringOperationFilterInput>;
  viewTapeAvailable?: InputMaybe<StringOperationFilterInput>;
};

export type MediaPlanNielsenDemo = {
  __typename?: 'MediaPlanNielsenDemo';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  demoOrder: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaPlan?: Maybe<MediaPlan>;
  mediaPlanId: Scalars['Int'];
  mediaPlanNielsenDemoId: Scalars['Int'];
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId: Scalars['Int'];
};

export type MediaPlanNielsenDemoFilterInput = {
  and?: InputMaybe<Array<MediaPlanNielsenDemoFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  demoOrder?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaPlan?: InputMaybe<MediaPlanFilterInput>;
  mediaPlanId?: InputMaybe<IntOperationFilterInput>;
  mediaPlanNielsenDemoId?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaPlanNielsenDemoFilterInput>>;
};

export type MediaPlanRentrakDemographic = {
  __typename?: 'MediaPlanRentrakDemographic';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  demoOrder: Scalars['Int'];
  lastUpdatedAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaPlan?: Maybe<MediaPlan>;
  mediaPlanId: Scalars['Int'];
  mediaPlanRentrakDemographicId: Scalars['Int'];
  rentrakDemographic?: Maybe<RentrakDemographic>;
  rentrakDemographicId: Scalars['Int'];
};

export type MediaPlanRentrakDemographicFilterInput = {
  and?: InputMaybe<Array<MediaPlanRentrakDemographicFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  demoOrder?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaPlan?: InputMaybe<MediaPlanFilterInput>;
  mediaPlanId?: InputMaybe<IntOperationFilterInput>;
  mediaPlanRentrakDemographicId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaPlanRentrakDemographicFilterInput>>;
  rentrakDemographic?: InputMaybe<RentrakDemographicFilterInput>;
  rentrakDemographicId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaPlanTapeLength = {
  __typename?: 'MediaPlanTapeLength';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaPlan?: Maybe<MediaPlan>;
  mediaPlanId: Scalars['Int'];
  mediaPlanTapeLengthId: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId: Scalars['Int'];
};

export type MediaPlanTapeLengthFilterInput = {
  and?: InputMaybe<Array<MediaPlanTapeLengthFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaPlan?: InputMaybe<MediaPlanFilterInput>;
  mediaPlanId?: InputMaybe<IntOperationFilterInput>;
  mediaPlanTapeLengthId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaPlanTapeLengthFilterInput>>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaSchedule = {
  __typename?: 'MediaSchedule';
  bookedByAppUser?: Maybe<AppUser>;
  bookedByAppUserId?: Maybe<Scalars['Int']>;
  bookedDate?: Maybe<Scalars['DateTime']>;
  bookedThrough?: Maybe<Scalars['Date']>;
  budget?: Maybe<Scalars['Decimal']>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  commissionRate?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createdByAppUser?: Maybe<AppUser>;
  createdByAppUserId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  dmainfo?: Maybe<Dmainfo>;
  dmainfoId: Scalars['Int'];
  flightEnd?: Maybe<Scalars['Date']>;
  flightStart?: Maybe<Scalars['Date']>;
  inverseProgenitorMediaSchedule?: Maybe<Array<Maybe<MediaSchedule>>>;
  isNonLinear: Scalars['Boolean'];
  kpiskugroup?: Maybe<Skugroup>;
  kpiskugroupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaPlan?: Maybe<MediaPlan>;
  mediaPlanId: Scalars['Int'];
  mediaScheduleAds?: Maybe<Array<Maybe<MediaScheduleAd>>>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDisplays?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaScheduleId: Scalars['Int'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  progenitorMediaSchedule?: Maybe<MediaSchedule>;
  progenitorMediaScheduleId?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Decimal']>;
  targetTypeLookup?: Maybe<Lookup>;
  targetTypeLookupId?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export type MediaScheduleAd = {
  __typename?: 'MediaScheduleAd';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaSchedule?: Maybe<MediaSchedule>;
  mediaScheduleAdId: Scalars['Int'];
  mediaScheduleId: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  percent: Scalars['Decimal'];
};

export type MediaScheduleAdFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<MediaScheduleAdFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaSchedule?: InputMaybe<MediaScheduleFilterInput>;
  mediaScheduleAdId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleAdFilterInput>>;
  percent?: InputMaybe<DecimalOperationFilterInput>;
};

export type MediaScheduleDetail = {
  __typename?: 'MediaScheduleDetail';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  advancedTargetingDemoLookup?: Maybe<Lookup>;
  advancedTargetingDemoLookupId?: Maybe<Scalars['Int']>;
  allowableDays?: Maybe<Scalars['String']>;
  amountToChargeClient?: Maybe<Scalars['Decimal']>;
  amountToPayStation?: Maybe<Scalars['Decimal']>;
  buyTypeLookup?: Maybe<Lookup>;
  buyTypeLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costPerKpi?: Maybe<Scalars['Decimal']>;
  cpmamount?: Maybe<Scalars['Decimal']>;
  daypart?: Maybe<Daypart>;
  daypartId?: Maybe<Scalars['Int']>;
  discount: Scalars['Decimal'];
  endDate?: Maybe<Scalars['Date']>;
  endTime: Scalars['TimeSpan'];
  estimatedValue?: Maybe<Scalars['Decimal']>;
  internalNotes?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaSchedule?: Maybe<MediaSchedule>;
  mediaScheduleDetailAdOverrides?: Maybe<Array<Maybe<MediaScheduleDetailAdOverride>>>;
  mediaScheduleDetailAttributes?: Maybe<Array<Maybe<MediaScheduleDetailAttribute>>>;
  mediaScheduleDetailHistoricalKpimatches?: Maybe<Array<Maybe<MediaScheduleDetailHistoricalKpimatch>>>;
  mediaScheduleDetailId: Scalars['Int'];
  mediaScheduleDetailInstances?: Maybe<Array<Maybe<MediaScheduleDetailInstance>>>;
  mediaScheduleId: Scalars['Int'];
  netRate?: Maybe<Scalars['Decimal']>;
  networkMediaOutlet?: Maybe<MediaOutlet>;
  networkMediaOutletId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  overrideCostPerKpi?: Maybe<Scalars['Decimal']>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['Int']>;
  placementPositionLookup?: Maybe<Lookup>;
  placementPositionLookupId?: Maybe<Scalars['Int']>;
  programming?: Maybe<Scalars['String']>;
  prtadSizeLookup?: Maybe<Lookup>;
  prtadSizeLookupId?: Maybe<Scalars['Int']>;
  prtadTypeLookup?: Maybe<Lookup>;
  prtadTypeLookupId?: Maybe<Scalars['Int']>;
  prtcirculation?: Maybe<Scalars['Int']>;
  prtcolorLookup?: Maybe<Lookup>;
  prtcolorLookupId?: Maybe<Scalars['Int']>;
  prtissueDateLookup?: Maybe<Lookup>;
  prtissueDateLookupId?: Maybe<Scalars['Int']>;
  prtlayoutLookup?: Maybe<Lookup>;
  prtlayoutLookupId?: Maybe<Scalars['Int']>;
  prtlocationLookup?: Maybe<Lookup>;
  prtlocationLookupId?: Maybe<Scalars['Int']>;
  prtmaterialsDueDate?: Maybe<Scalars['Date']>;
  prtonSaleDate?: Maybe<Scalars['Date']>;
  prtsectionLookup?: Maybe<Lookup>;
  prtsectionLookupId?: Maybe<Scalars['Int']>;
  prtspaceDate?: Maybe<Scalars['Date']>;
  prtterritory?: Maybe<PrintTerritory>;
  prtterritoryId?: Maybe<Scalars['Int']>;
  rateTypeLookup?: Maybe<Lookup>;
  rateTypeLookupId: Scalars['Int'];
  ratingMediaScheduleDetails?: Maybe<Array<Maybe<RatingMediaScheduleDetail>>>;
  readTypeLookup?: Maybe<Lookup>;
  readTypeLookupId?: Maybe<Scalars['Int']>;
  roviProgramId?: Maybe<Scalars['Int']>;
  schedule?: Maybe<Schedule>;
  scheduleId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  startTime: Scalars['TimeSpan'];
  statusLookup?: Maybe<Lookup>;
  statusLookupId: Scalars['Int'];
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId: Scalars['Int'];
  theoreticalGrossAmount?: Maybe<Scalars['Decimal']>;
  trackClicks?: Maybe<Scalars['Boolean']>;
  trackingServicePaidByLookup?: Maybe<Lookup>;
  trackingServicePaidByLookupId?: Maybe<Scalars['Int']>;
  trackingServiceVendor?: Maybe<Vendor>;
  trackingServiceVendorId?: Maybe<Scalars['Int']>;
  weekOf?: Maybe<Scalars['Date']>;
  weekPartLookup?: Maybe<Lookup>;
  weekPartLookupId?: Maybe<Scalars['Int']>;
};

export type MediaScheduleDetailAdOverride = {
  __typename?: 'MediaScheduleDetailAdOverride';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaScheduleDetail?: Maybe<MediaScheduleDetail>;
  mediaScheduleDetailAdOverrideId: Scalars['Int'];
  mediaScheduleDetailId: Scalars['Int'];
  splitPercentage: Scalars['Decimal'];
};

export type MediaScheduleDetailAdOverrideFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<MediaScheduleDetailAdOverrideFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaScheduleDetail?: InputMaybe<MediaScheduleDetailFilterInput>;
  mediaScheduleDetailAdOverrideId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleDetailAdOverrideFilterInput>>;
  splitPercentage?: InputMaybe<DecimalOperationFilterInput>;
};

export type MediaScheduleDetailAttribute = {
  __typename?: 'MediaScheduleDetailAttribute';
  attributeLookup?: Maybe<Lookup>;
  attributeLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaScheduleDetail?: Maybe<MediaScheduleDetail>;
  mediaScheduleDetailAttributeId: Scalars['Int'];
  mediaScheduleDetailId: Scalars['Int'];
};

export type MediaScheduleDetailAttributeFilterInput = {
  and?: InputMaybe<Array<MediaScheduleDetailAttributeFilterInput>>;
  attributeLookup?: InputMaybe<LookupFilterInput>;
  attributeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaScheduleDetail?: InputMaybe<MediaScheduleDetailFilterInput>;
  mediaScheduleDetailAttributeId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleDetailAttributeFilterInput>>;
};

export type MediaScheduleDetailFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  advancedTargetingDemoLookup?: InputMaybe<LookupFilterInput>;
  advancedTargetingDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  amountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  amountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<MediaScheduleDetailFilterInput>>;
  buyTypeLookup?: InputMaybe<LookupFilterInput>;
  buyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costPerKpi?: InputMaybe<DecimalOperationFilterInput>;
  cpmamount?: InputMaybe<DecimalOperationFilterInput>;
  daypart?: InputMaybe<DaypartFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  discount?: InputMaybe<DecimalOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  estimatedValue?: InputMaybe<DecimalOperationFilterInput>;
  internalNotes?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaSchedule?: InputMaybe<MediaScheduleFilterInput>;
  mediaScheduleDetailAdOverrides?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailAdOverrideFilterInput>;
  mediaScheduleDetailAttributes?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailAttributeFilterInput>;
  mediaScheduleDetailHistoricalKpimatches?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailHistoricalKpimatchFilterInput>;
  mediaScheduleDetailId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailInstances?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailInstanceFilterInput>;
  mediaScheduleId?: InputMaybe<IntOperationFilterInput>;
  netRate?: InputMaybe<DecimalOperationFilterInput>;
  networkMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  networkMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleDetailFilterInput>>;
  overrideCostPerKpi?: InputMaybe<DecimalOperationFilterInput>;
  package?: InputMaybe<PackageFilterInput>;
  packageId?: InputMaybe<IntOperationFilterInput>;
  placementPositionLookup?: InputMaybe<LookupFilterInput>;
  placementPositionLookupId?: InputMaybe<IntOperationFilterInput>;
  programming?: InputMaybe<StringOperationFilterInput>;
  prtadSizeLookup?: InputMaybe<LookupFilterInput>;
  prtadSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  prtadTypeLookup?: InputMaybe<LookupFilterInput>;
  prtadTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  prtcirculation?: InputMaybe<IntOperationFilterInput>;
  prtcolorLookup?: InputMaybe<LookupFilterInput>;
  prtcolorLookupId?: InputMaybe<IntOperationFilterInput>;
  prtissueDateLookup?: InputMaybe<LookupFilterInput>;
  prtissueDateLookupId?: InputMaybe<IntOperationFilterInput>;
  prtlayoutLookup?: InputMaybe<LookupFilterInput>;
  prtlayoutLookupId?: InputMaybe<IntOperationFilterInput>;
  prtlocationLookup?: InputMaybe<LookupFilterInput>;
  prtlocationLookupId?: InputMaybe<IntOperationFilterInput>;
  prtmaterialsDueDate?: InputMaybe<DateOperationFilterInput>;
  prtonSaleDate?: InputMaybe<DateOperationFilterInput>;
  prtsectionLookup?: InputMaybe<LookupFilterInput>;
  prtsectionLookupId?: InputMaybe<IntOperationFilterInput>;
  prtspaceDate?: InputMaybe<DateOperationFilterInput>;
  prtterritory?: InputMaybe<PrintTerritoryFilterInput>;
  prtterritoryId?: InputMaybe<IntOperationFilterInput>;
  rateTypeLookup?: InputMaybe<LookupFilterInput>;
  rateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  ratingMediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfRatingMediaScheduleDetailFilterInput>;
  readTypeLookup?: InputMaybe<LookupFilterInput>;
  readTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  statusLookup?: InputMaybe<LookupFilterInput>;
  statusLookupId?: InputMaybe<IntOperationFilterInput>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
  theoreticalGrossAmount?: InputMaybe<DecimalOperationFilterInput>;
  trackClicks?: InputMaybe<BooleanOperationFilterInput>;
  trackingServicePaidByLookup?: InputMaybe<LookupFilterInput>;
  trackingServicePaidByLookupId?: InputMaybe<IntOperationFilterInput>;
  trackingServiceVendor?: InputMaybe<VendorFilterInput>;
  trackingServiceVendorId?: InputMaybe<IntOperationFilterInput>;
  weekOf?: InputMaybe<DateOperationFilterInput>;
  weekPartLookup?: InputMaybe<LookupFilterInput>;
  weekPartLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaScheduleDetailHistoricalKpimatch = {
  __typename?: 'MediaScheduleDetailHistoricalKpimatch';
  airings: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costPerKpi?: Maybe<Scalars['Decimal']>;
  firstAiringDate: Scalars['Date'];
  lastAirDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  maxGrossRate: Scalars['Decimal'];
  mediaScheduleDetail?: Maybe<MediaScheduleDetail>;
  mediaScheduleDetailHistoricalKpimatchId: Scalars['Int'];
  mediaScheduleDetailId: Scalars['Int'];
  minGrossRate: Scalars['Decimal'];
};

export type MediaScheduleDetailHistoricalKpimatchFilterInput = {
  airings?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<MediaScheduleDetailHistoricalKpimatchFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costPerKpi?: InputMaybe<DecimalOperationFilterInput>;
  firstAiringDate?: InputMaybe<DateOperationFilterInput>;
  lastAirDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  maxGrossRate?: InputMaybe<DecimalOperationFilterInput>;
  mediaScheduleDetail?: InputMaybe<MediaScheduleDetailFilterInput>;
  mediaScheduleDetailHistoricalKpimatchId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailId?: InputMaybe<IntOperationFilterInput>;
  minGrossRate?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleDetailHistoricalKpimatchFilterInput>>;
};

export type MediaScheduleDetailInstance = {
  __typename?: 'MediaScheduleDetailInstance';
  blocked: Scalars['Boolean'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  impressions: Scalars['Int'];
  instances: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaScheduleDetail?: Maybe<MediaScheduleDetail>;
  mediaScheduleDetailId: Scalars['Int'];
  mediaScheduleDetailInstancesId: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  schedule?: Maybe<Schedule>;
  scheduleId?: Maybe<Scalars['Int']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  startDate: Scalars['Date'];
};

export type MediaScheduleDetailInstanceFilterInput = {
  and?: InputMaybe<Array<MediaScheduleDetailInstanceFilterInput>>;
  blocked?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  impressions?: InputMaybe<IntOperationFilterInput>;
  instances?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaScheduleDetail?: InputMaybe<MediaScheduleDetailFilterInput>;
  mediaScheduleDetailId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailInstancesId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleDetailInstanceFilterInput>>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type MediaScheduleDisplay = {
  __typename?: 'MediaScheduleDisplay';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  displayC3ratings: Scalars['Boolean'];
  displayLiveRatings: Scalars['Boolean'];
  displayPlus3Ratings: Scalars['Boolean'];
  estimatedRatingModeLookup?: Maybe<Lookup>;
  estimatedRatingModeLookupId: Scalars['Int'];
  kpidateRangeLookup?: Maybe<Lookup>;
  kpidateRangeLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaSchedule?: Maybe<MediaSchedule>;
  mediaScheduleDisplayId: Scalars['Int'];
  mediaScheduleId: Scalars['Int'];
  rateTypeLookup?: Maybe<Lookup>;
  rateTypeLookupId: Scalars['Int'];
  showWeeklySpend: Scalars['Boolean'];
  showWeeklySpots: Scalars['Boolean'];
  showWeeklyTrps: Scalars['Boolean'];
};

export type MediaScheduleDisplayFilterInput = {
  and?: InputMaybe<Array<MediaScheduleDisplayFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  displayC3ratings?: InputMaybe<BooleanOperationFilterInput>;
  displayLiveRatings?: InputMaybe<BooleanOperationFilterInput>;
  displayPlus3Ratings?: InputMaybe<BooleanOperationFilterInput>;
  estimatedRatingModeLookup?: InputMaybe<LookupFilterInput>;
  estimatedRatingModeLookupId?: InputMaybe<IntOperationFilterInput>;
  kpidateRangeLookup?: InputMaybe<LookupFilterInput>;
  kpidateRangeLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaSchedule?: InputMaybe<MediaScheduleFilterInput>;
  mediaScheduleDisplayId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleDisplayFilterInput>>;
  rateTypeLookup?: InputMaybe<LookupFilterInput>;
  rateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  showWeeklySpend?: InputMaybe<BooleanOperationFilterInput>;
  showWeeklySpots?: InputMaybe<BooleanOperationFilterInput>;
  showWeeklyTrps?: InputMaybe<BooleanOperationFilterInput>;
};

export type MediaScheduleFilterInput = {
  and?: InputMaybe<Array<MediaScheduleFilterInput>>;
  bookedByAppUser?: InputMaybe<AppUserFilterInput>;
  bookedByAppUserId?: InputMaybe<IntOperationFilterInput>;
  bookedDate?: InputMaybe<DateTimeOperationFilterInput>;
  bookedThrough?: InputMaybe<DateOperationFilterInput>;
  budget?: InputMaybe<DecimalOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  commissionRate?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdByAppUser?: InputMaybe<AppUserFilterInput>;
  createdByAppUserId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dmainfo?: InputMaybe<DmainfoFilterInput>;
  dmainfoId?: InputMaybe<IntOperationFilterInput>;
  flightEnd?: InputMaybe<DateOperationFilterInput>;
  flightStart?: InputMaybe<DateOperationFilterInput>;
  inverseProgenitorMediaSchedule?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  isNonLinear?: InputMaybe<BooleanOperationFilterInput>;
  kpiskugroup?: InputMaybe<SkugroupFilterInput>;
  kpiskugroupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaPlan?: InputMaybe<MediaPlanFilterInput>;
  mediaPlanId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleAds?: InputMaybe<ListFilterInputTypeOfMediaScheduleAdFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaScheduleDisplays?: InputMaybe<ListFilterInputTypeOfMediaScheduleDisplayFilterInput>;
  mediaScheduleId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaScheduleFilterInput>>;
  progenitorMediaSchedule?: InputMaybe<MediaScheduleFilterInput>;
  progenitorMediaScheduleId?: InputMaybe<IntOperationFilterInput>;
  target?: InputMaybe<DecimalOperationFilterInput>;
  targetTypeLookup?: InputMaybe<LookupFilterInput>;
  targetTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  version?: InputMaybe<IntOperationFilterInput>;
};

export type MediaTransmission = {
  __typename?: 'MediaTransmission';
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  bleed?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  courierVendor?: Maybe<Vendor>;
  courierVendorId?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  dateDueAtDestination?: Maybe<Scalars['Date']>;
  dateOfFirstUse?: Maybe<Scalars['Date']>;
  dateSent?: Maybe<Scalars['DateTime']>;
  deliveryConfirmationFileReference?: Maybe<FileReference>;
  deliveryConfirmationFileReferenceId?: Maybe<Scalars['Int']>;
  externalComment?: Maybe<Scalars['String']>;
  hdmediaFormatLookup?: Maybe<Lookup>;
  hdmediaFormatLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  liveArea?: Maybe<Scalars['String']>;
  mediaFormatLookup?: Maybe<Lookup>;
  mediaFormatLookupId?: Maybe<Scalars['Int']>;
  mediaTransmissionId: Scalars['Int'];
  mediaTransmissionNotifications?: Maybe<Array<Maybe<MediaTransmissionNotification>>>;
  mediaTransmissionStatusLookup?: Maybe<Lookup>;
  mediaTransmissionStatusLookupId?: Maybe<Scalars['Int']>;
  mediaTransmissionTypeLookup?: Maybe<Lookup>;
  mediaTransmissionTypeLookupId: Scalars['Int'];
  numberOfTapes: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  printAdSizeLookup?: Maybe<Lookup>;
  printAdSizeLookupId?: Maybe<Scalars['Int']>;
  priorityLookup?: Maybe<Lookup>;
  priorityLookupId: Scalars['Int'];
  pwmediaRequestBatchImportId?: Maybe<Scalars['Int']>;
  r2cbillingsExportDate?: Maybe<Scalars['DateTime']>;
  r2cbillingsImportDate?: Maybe<Scalars['DateTime']>;
  r2cbillingsRejectionDate?: Maybe<Scalars['DateTime']>;
  senderAppUserId?: Maybe<Scalars['Int']>;
  shipArrivalDate?: Maybe<Scalars['Date']>;
  shipDate?: Maybe<Scalars['DateTime']>;
  shipMethod?: Maybe<Scalars['String']>;
  shipTrackingId?: Maybe<Scalars['String']>;
  shipper?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  uniqueAd?: Maybe<UniqueAd>;
  uniqueAdId: Scalars['Int'];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type MediaTransmissionFilterInput = {
  and?: InputMaybe<Array<MediaTransmissionFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  bleed?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  courierVendor?: InputMaybe<VendorFilterInput>;
  courierVendorId?: InputMaybe<IntOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDueAtDestination?: InputMaybe<DateOperationFilterInput>;
  dateOfFirstUse?: InputMaybe<DateOperationFilterInput>;
  dateSent?: InputMaybe<DateTimeOperationFilterInput>;
  deliveryConfirmationFileReference?: InputMaybe<FileReferenceFilterInput>;
  deliveryConfirmationFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  externalComment?: InputMaybe<StringOperationFilterInput>;
  hdmediaFormatLookup?: InputMaybe<LookupFilterInput>;
  hdmediaFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  liveArea?: InputMaybe<StringOperationFilterInput>;
  mediaFormatLookup?: InputMaybe<LookupFilterInput>;
  mediaFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaTransmissionId?: InputMaybe<IntOperationFilterInput>;
  mediaTransmissionNotifications?: InputMaybe<ListFilterInputTypeOfMediaTransmissionNotificationFilterInput>;
  mediaTransmissionStatusLookup?: InputMaybe<LookupFilterInput>;
  mediaTransmissionStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  mediaTransmissionTypeLookup?: InputMaybe<LookupFilterInput>;
  mediaTransmissionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  numberOfTapes?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaTransmissionFilterInput>>;
  printAdSizeLookup?: InputMaybe<LookupFilterInput>;
  printAdSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  priorityLookup?: InputMaybe<LookupFilterInput>;
  priorityLookupId?: InputMaybe<IntOperationFilterInput>;
  pwmediaRequestBatchImportId?: InputMaybe<IntOperationFilterInput>;
  r2cbillingsExportDate?: InputMaybe<DateTimeOperationFilterInput>;
  r2cbillingsImportDate?: InputMaybe<DateTimeOperationFilterInput>;
  r2cbillingsRejectionDate?: InputMaybe<DateTimeOperationFilterInput>;
  senderAppUserId?: InputMaybe<IntOperationFilterInput>;
  shipArrivalDate?: InputMaybe<DateOperationFilterInput>;
  shipDate?: InputMaybe<DateTimeOperationFilterInput>;
  shipMethod?: InputMaybe<StringOperationFilterInput>;
  shipTrackingId?: InputMaybe<StringOperationFilterInput>;
  shipper?: InputMaybe<StringOperationFilterInput>;
  trim?: InputMaybe<StringOperationFilterInput>;
  uniqueAd?: InputMaybe<UniqueAdFilterInput>;
  uniqueAdId?: InputMaybe<IntOperationFilterInput>;
  vendor?: InputMaybe<VendorFilterInput>;
  vendorId?: InputMaybe<IntOperationFilterInput>;
};

export type MediaTransmissionNotification = {
  __typename?: 'MediaTransmissionNotification';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaTransmission?: Maybe<MediaTransmission>;
  mediaTransmissionId: Scalars['Int'];
  mediaTransmissionNotificationId: Scalars['Int'];
};

export type MediaTransmissionNotificationFilterInput = {
  and?: InputMaybe<Array<MediaTransmissionNotificationFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaTransmission?: InputMaybe<MediaTransmissionFilterInput>;
  mediaTransmissionId?: InputMaybe<IntOperationFilterInput>;
  mediaTransmissionNotificationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaTransmissionNotificationFilterInput>>;
};

export type MediaType = {
  __typename?: 'MediaType';
  accountingLocks?: Maybe<Array<Maybe<AccountingLock>>>;
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserRoles?: Maybe<Array<Maybe<AppUserRole>>>;
  axcode?: Maybe<Scalars['String']>;
  axdepartment?: Maybe<Scalars['String']>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  commissionSlidingScaleMediaTypes?: Maybe<Array<Maybe<CommissionSlidingScaleMediaType>>>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  contactTypes?: Maybe<Array<Maybe<ContactType>>>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  digitalSpendImportChannelMappings?: Maybe<Array<Maybe<DigitalSpendImportChannelMapping>>>;
  display?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  inverseMediaTypeParent?: Maybe<Array<Maybe<MediaType>>>;
  isRotationBased: Scalars['Boolean'];
  isTimeBased: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketCostPers?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletTypes?: Maybe<Array<Maybe<MediaOutletType>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTypeId: Scalars['Int'];
  mediaTypeParent?: Maybe<MediaType>;
  mediaTypeParentId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  oldid?: Maybe<Scalars['Int']>;
  placeholder: Scalars['Boolean'];
  productMediaTypeLockDates?: Maybe<Array<Maybe<ProductMediaTypeLockDate>>>;
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  trafficSheetDetails?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  vendorServiceMediaTypes?: Maybe<Array<Maybe<VendorServiceMediaType>>>;
};

export enum MediaTypeEnum {
  AltMedia = 'ALT_MEDIA',
  Audio = 'AUDIO',
  Creative = 'CREATIVE',
  Digital = 'DIGITAL',
  DigitalAudio = 'DIGITAL_AUDIO',
  DigitalContentSyndication = 'DIGITAL_CONTENT_SYNDICATION',
  DigitalDisplay = 'DIGITAL_DISPLAY',
  DigitalEmail = 'DIGITAL_EMAIL',
  DigitalOoh = 'DIGITAL_OOH',
  DigitalPaidSearch = 'DIGITAL_PAID_SEARCH',
  DigitalPaidSocial = 'DIGITAL_PAID_SOCIAL',
  DigitalTbd = 'DIGITAL_TBD',
  DigitalVideo = 'DIGITAL_VIDEO',
  DirectMail = 'DIRECT_MAIL',
  Ooh = 'OOH',
  Other = 'OTHER',
  Print = 'PRINT',
  TrackedOnly = 'TRACKED_ONLY',
  Tv = 'TV',
  TvLf = 'TV_LF',
  TvSf = 'TV_SF',
  Web = 'WEB'
}

export type MediaTypeFilterInput = {
  accountingLocks?: InputMaybe<ListFilterInputTypeOfAccountingLockFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<MediaTypeFilterInput>>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  appUserRoles?: InputMaybe<ListFilterInputTypeOfAppUserRoleFilterInput>;
  axcode?: InputMaybe<StringOperationFilterInput>;
  axdepartment?: InputMaybe<StringOperationFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  commissionSlidingScaleMediaTypes?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleMediaTypeFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  contactTypes?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  digitalSpendImportChannelMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportChannelMappingFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  inverseMediaTypeParent?: InputMaybe<ListFilterInputTypeOfMediaTypeFilterInput>;
  isRotationBased?: InputMaybe<BooleanOperationFilterInput>;
  isTimeBased?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketCostPers?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletTypeFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaTypeParent?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeParentId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaTypeFilterInput>>;
  placeholder?: InputMaybe<BooleanOperationFilterInput>;
  productMediaTypeLockDates?: InputMaybe<ListFilterInputTypeOfProductMediaTypeLockDateFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  vendorServiceMediaTypes?: InputMaybe<ListFilterInputTypeOfVendorServiceMediaTypeFilterInput>;
};

export type MediaTypeSortInput = {
  axcode?: InputMaybe<SortEnumType>;
  axdepartment?: InputMaybe<SortEnumType>;
  display?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  isRotationBased?: InputMaybe<SortEnumType>;
  isTimeBased?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaTypeId?: InputMaybe<SortEnumType>;
  mediaTypeParent?: InputMaybe<MediaTypeSortInput>;
  mediaTypeParentId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  placeholder?: InputMaybe<SortEnumType>;
};

export type MediaTypeWithAmount = {
  __typename?: 'MediaTypeWithAmount';
  accountingLocks?: Maybe<Array<Maybe<AccountingLock>>>;
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  amount?: Maybe<Scalars['Decimal']>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserRoles?: Maybe<Array<Maybe<AppUserRole>>>;
  axcode?: Maybe<Scalars['String']>;
  axdepartment?: Maybe<Scalars['String']>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  commissionSlidingScaleMediaTypes?: Maybe<Array<Maybe<CommissionSlidingScaleMediaType>>>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  contactTypes?: Maybe<Array<Maybe<ContactType>>>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  digitalSpendImportChannelMappings?: Maybe<Array<Maybe<DigitalSpendImportChannelMapping>>>;
  display?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  inverseMediaTypeParent?: Maybe<Array<Maybe<MediaType>>>;
  isRotationBased: Scalars['Boolean'];
  isTimeBased: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketCostPers?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletTypes?: Maybe<Array<Maybe<MediaOutletType>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTypeId: Scalars['Int'];
  mediaTypeParent?: Maybe<MediaType>;
  mediaTypeParentId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  oldid?: Maybe<Scalars['Int']>;
  placeholder: Scalars['Boolean'];
  productMediaTypeLockDates?: Maybe<Array<Maybe<ProductMediaTypeLockDate>>>;
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  trafficSheetDetails?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  vendorServiceMediaTypes?: Maybe<Array<Maybe<VendorServiceMediaType>>>;
};

export type MediaTypeWithAmountFilterInput = {
  accountingLocks?: InputMaybe<ListFilterInputTypeOfAccountingLockFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<MediaTypeWithAmountFilterInput>>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  appUserRoles?: InputMaybe<ListFilterInputTypeOfAppUserRoleFilterInput>;
  axcode?: InputMaybe<StringOperationFilterInput>;
  axdepartment?: InputMaybe<StringOperationFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  commissionSlidingScaleMediaTypes?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleMediaTypeFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  contactTypes?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  digitalSpendImportChannelMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportChannelMappingFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  inverseMediaTypeParent?: InputMaybe<ListFilterInputTypeOfMediaTypeFilterInput>;
  isRotationBased?: InputMaybe<BooleanOperationFilterInput>;
  isTimeBased?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketCostPers?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletTypeFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaTypeParent?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeParentId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaTypeWithAmountFilterInput>>;
  placeholder?: InputMaybe<BooleanOperationFilterInput>;
  productMediaTypeLockDates?: InputMaybe<ListFilterInputTypeOfProductMediaTypeLockDateFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  vendorServiceMediaTypes?: InputMaybe<ListFilterInputTypeOfVendorServiceMediaTypeFilterInput>;
};

export type MediaType_ForBudgetAllocation = {
  __typename?: 'MediaType_ForBudgetAllocation';
  accountingLocks?: Maybe<Array<Maybe<AccountingLock>>>;
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserRoles?: Maybe<Array<Maybe<AppUserRole>>>;
  axcode?: Maybe<Scalars['String']>;
  axdepartment?: Maybe<Scalars['String']>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  commissionSlidingScaleMediaTypes?: Maybe<Array<Maybe<CommissionSlidingScaleMediaType>>>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  contactTypes?: Maybe<Array<Maybe<ContactType>>>;
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  digitalSpendImportChannelMappings?: Maybe<Array<Maybe<DigitalSpendImportChannelMapping>>>;
  display?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inactive: Scalars['Boolean'];
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  inverseMediaTypeParent?: Maybe<Array<Maybe<MediaType>>>;
  isRotationBased: Scalars['Boolean'];
  isTimeBased: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  localMarketCostPers?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletTypes?: Maybe<Array<Maybe<MediaOutletType>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTypeId: Scalars['Int'];
  mediaTypeParent?: Maybe<MediaType>;
  mediaTypeParentId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  oldid?: Maybe<Scalars['Int']>;
  placeholder: Scalars['Boolean'];
  productMediaTypeLockDates?: Maybe<Array<Maybe<ProductMediaTypeLockDate>>>;
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  trafficSheetDetails?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  vendorServiceMediaTypes?: Maybe<Array<Maybe<VendorServiceMediaType>>>;
};

export type MediaType_ForBudgetAllocationFilterInput = {
  accountingLocks?: InputMaybe<ListFilterInputTypeOfAccountingLockFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<MediaType_ForBudgetAllocationFilterInput>>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  appUserRoles?: InputMaybe<ListFilterInputTypeOfAppUserRoleFilterInput>;
  axcode?: InputMaybe<StringOperationFilterInput>;
  axdepartment?: InputMaybe<StringOperationFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  commissionSlidingScaleMediaTypes?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleMediaTypeFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  contactTypes?: InputMaybe<ListFilterInputTypeOfContactTypeFilterInput>;
  contractTerms?: InputMaybe<ListFilterInputTypeOfContractTermFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  digitalSpendImportChannelMappings?: InputMaybe<ListFilterInputTypeOfDigitalSpendImportChannelMappingFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrderDetails?: InputMaybe<ListFilterInputTypeOfInsertionOrderDetailFilterInput>;
  inverseMediaTypeParent?: InputMaybe<ListFilterInputTypeOfMediaTypeFilterInput>;
  isRotationBased?: InputMaybe<BooleanOperationFilterInput>;
  isTimeBased?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  localMarketCostPers?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletTypeFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaTypeParent?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeParentId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nielsenDemoPopulations?: InputMaybe<ListFilterInputTypeOfNielsenDemoPopulationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MediaType_ForBudgetAllocationFilterInput>>;
  placeholder?: InputMaybe<BooleanOperationFilterInput>;
  productMediaTypeLockDates?: InputMaybe<ListFilterInputTypeOfProductMediaTypeLockDateFilterInput>;
  tacticInfos?: InputMaybe<ListFilterInputTypeOfTacticInfoFilterInput>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  vendorServiceMediaTypes?: InputMaybe<ListFilterInputTypeOfVendorServiceMediaTypeFilterInput>;
};

export type MetricHeader = {
  __typename?: 'MetricHeader';
  calculateSize: Scalars['Int'];
  clone?: Maybe<MetricHeader>;
  name?: Maybe<Scalars['String']>;
  type: MetricType;
};

export type MetricMetadata = {
  __typename?: 'MetricMetadata';
  apiName?: Maybe<Scalars['String']>;
  blockedReasons?: Maybe<Array<BlockedReason>>;
  calculateSize: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  clone?: Maybe<MetricMetadata>;
  customDefinition: Scalars['Boolean'];
  deprecatedApiNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  type: MetricType;
  uiName?: Maybe<Scalars['String']>;
};

export enum MetricType {
  TypeCurrency = 'TYPE_CURRENCY',
  TypeFeet = 'TYPE_FEET',
  TypeFloat = 'TYPE_FLOAT',
  TypeHours = 'TYPE_HOURS',
  TypeInteger = 'TYPE_INTEGER',
  TypeKilometers = 'TYPE_KILOMETERS',
  TypeMeters = 'TYPE_METERS',
  TypeMiles = 'TYPE_MILES',
  TypeMilliseconds = 'TYPE_MILLISECONDS',
  TypeMinutes = 'TYPE_MINUTES',
  TypeSeconds = 'TYPE_SECONDS',
  TypeStandard = 'TYPE_STANDARD',
  Unspecified = 'UNSPECIFIED'
}

export type MissingLedgerItem = {
  __typename?: 'MissingLedgerItem';
  accountingDate: Scalars['DateTime'];
  dataAreaId: Scalars['String'];
  isPreviousMonth: Scalars['Boolean'];
  journalBatchNumber: Scalars['String'];
  journalDescription: Scalars['String'];
  journalNumber: Scalars['String'];
  monthDescription: Scalars['String'];
};

export enum MonthDefinition {
  Broadcast = 'BROADCAST',
  Calendar = 'CALENDAR'
}

export type Mriindex = {
  __typename?: 'Mriindex';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  daypart?: Maybe<Daypart>;
  daypartId?: Maybe<Scalars['Int']>;
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mridataStabilityLookup?: Maybe<Lookup>;
  mridataStabilityLookupId?: Maybe<Scalars['Int']>;
  mridemoLookup?: Maybe<Lookup>;
  mridemoLookupId: Scalars['Int'];
  mriindexId: Scalars['Int'];
  mriindexTypeLookup?: Maybe<Lookup>;
  mriindexTypeLookupId: Scalars['Int'];
  percentParticipation?: Maybe<Scalars['Decimal']>;
  reportDate?: Maybe<Scalars['Date']>;
  responseIndex: Scalars['Decimal'];
  roviProgramId?: Maybe<Scalars['Int']>;
};

export type MriindexFilterInput = {
  and?: InputMaybe<Array<MriindexFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  daypart?: InputMaybe<DaypartFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mridataStabilityLookup?: InputMaybe<LookupFilterInput>;
  mridataStabilityLookupId?: InputMaybe<IntOperationFilterInput>;
  mridemoLookup?: InputMaybe<LookupFilterInput>;
  mridemoLookupId?: InputMaybe<IntOperationFilterInput>;
  mriindexId?: InputMaybe<IntOperationFilterInput>;
  mriindexTypeLookup?: InputMaybe<LookupFilterInput>;
  mriindexTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MriindexFilterInput>>;
  percentParticipation?: InputMaybe<DecimalOperationFilterInput>;
  reportDate?: InputMaybe<DateOperationFilterInput>;
  responseIndex?: InputMaybe<DecimalOperationFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAdminEmail: AppOffline;
  addCatchPhrase?: Maybe<CatchPhrase>;
  addClientRole: AddClientRolePayload;
  addClientRoleFunctionalRole: AddClientRoleFunctionalRolePayload;
  addClientRolePermission: AddClientRolePermissionPayload;
  addDepartmentGroup: AddDepartmentGroupPayload;
  addDepartmentGroupFunctionalRole: AddDepartmentGroupFunctionalRolePayload;
  addDepartmentGroupPermission: AddDepartmentGroupPermissionPayload;
  addDepartmentToDepartmentGroup: AddDepartmentToDepartmentGroupPayload;
  addFavorite: Favorite;
  addFunctionalRoleToPerson: AddFunctionalRoleToPersonPayload;
  addOnlineApp: AppOffline;
  addOrEditTimeSheetLine: TimeSheetLine;
  addOrEditTimeSheetLineEntry?: Maybe<TimeEntry>;
  addOrUpdateCategoryTermGroupTypeMapping: AddOrUpdateCategoryTermGroupTypeMappingPayload;
  addOrUpdateCbsReportDefinition: CbsReportDefinition_ForDisplay;
  addOrUpdateCbsReportDefinitionDepartment: CbsReportDefinitionDepartment;
  addOrUpdateCbsReportDefinitionDepartmentGroup: CbsReportDefinitionDepartmentGroup;
  addOrUpdateClientAdjustment: ClientAdjustment_ForDisplay;
  addOrUpdateDigitalSpendImportFieldMapping: DigitalSpendImportFieldMapping;
  addOrUpdateNote: AddOrUpdateNotePayload;
  addPageFavorite: PageFavorite;
  addPermissionToRole: RainOneRole_RainPermission_ForDisplay;
  addPerson_Client: AddPerson_ClientPayload;
  addProjectEstimateDepartment: AddProjectEstimateDepartmentPayload;
  addProjectEstimateVersionFile: AddProjectEstimateVersionFilePayload;
  addRoleDepartment: AddRoleDepartmentPayload;
  addRoleToPerson: RainOnePerson_RainOneRole;
  addTimeValidationProjCategoryOverride: AddTimeValidationProjCategoryOverridePayload;
  addTimesheetDelegate: AddTimesheetDelegatePayload;
  addTimesheetValidationAdditionalCompany: AddTimesheetValidationAdditionalCompanyPayload;
  addTimesheetValidationAdditionalDepartmentGroup: AddTimesheetValidationAdditionalDepartmentGroupPayload;
  addTimesheetValidationPersonAdditionalCategory: AddTimesheetValidationPersonAdditionalCategoryPayload;
  addTimesheetValidationProjResourceOverride: AddTimesheetValidationProjResourceOverridePayload;
  addTrackerLfCleareds: Array<TrackerLfCleared>;
  addTrackerLfPrepaids: Array<TrackerLfPrepayment>;
  addTrackerSFRevRecs: Array<TrackerSfRevenueRec>;
  addTrackerSfInvoices: Array<TrackerSfInvoice>;
  announceActionItemInstanceCreated: Scalars['Boolean'];
  approveInvoice: Array<InvoiceForApproval>;
  approveTimesheet: TimeSheet;
  approveTimesheetAsAnAdmin: TimesheetWeekSummary;
  cancelLockOnAllMyProjectEstimateDepartments: ProjectEstimate_ForEditing;
  cancelLockOnProjectEstimateDepartment: ProjectEstimate_ForEditing;
  cancelProjectEstimateApprovals: CancelProjectEstimateApprovalsPayload;
  cancelTimeSheetUnlock: TimeSheet;
  clearActionItemInstance?: Maybe<UserActionItem>;
  clearCacheGroup: Scalars['Boolean'];
  clearPeopleAndValidationCaches: Scalars['Boolean'];
  /**
   * Completes an action item for a given ID
   *
   *
   * **Returns:**
   * Nreturns true if an action item was found and it was marked as completed.
   */
  completeActionItem: Scalars['Boolean'];
  completeEmailPictureReviewTask: Scalars['Boolean'];
  completeMediaAuthorizationApprovedTask: Scalars['Boolean'];
  createInsertionOrderDocument: Scalars['String'];
  csiExport: Scalars['String'];
  deactivatePerson_Client: Person_Client_ForDisplay;
  deleteActivity: Scalars['Boolean'];
  deleteActivityCategory: Scalars['Boolean'];
  deleteAppUserClient: Scalars['Boolean'];
  deleteCampaignCrossChannelCampaign: Scalars['Boolean'];
  deleteCbsReportDefinition: Scalars['Boolean'];
  deleteCbsReportDefinitionDepartment: Scalars['Boolean'];
  deleteCbsReportDefinitionDepartmentGroup: Scalars['Boolean'];
  deleteClientAdjustment: Scalars['Boolean'];
  /** Deletes a ClientDatoramaKey for a given Id */
  deleteClientDatoramaKey: Scalars['Boolean'];
  deleteClientRole: Scalars['Boolean'];
  deleteClientRoleFunctionalRole: Scalars['Boolean'];
  deleteClientRolePermission: Scalars['Boolean'];
  deleteContact: DeletionResponse;
  /** Deletes a CommissionSlidingScale for a given Id */
  deleteContract: Scalars['Boolean'];
  /** Deletes a ContractTerm for a given Id */
  deleteContractTerm: Scalars['Boolean'];
  /** Deletes a CrossChannelCampaign and it's child versions and allocation. */
  deleteCrossChannelCampaign: DeletionResponse;
  /** Deletes a CrossChannelCampaignGroup for a given Id */
  deleteCrossChannelCampaignGroup: Scalars['Boolean'];
  /** Deletes a DatoramaImportJob for a given Id */
  deleteDatoramaImportJob: Scalars['Boolean'];
  deleteDepartmentGroup: Scalars['Boolean'];
  deleteDepartmentGroupFunctionalRole: Scalars['Boolean'];
  deleteDepartmentGroupPermission: Scalars['Boolean'];
  /** Deletes a DigitalResultType for a given Id */
  deleteDigitalResultType: Scalars['Boolean'];
  deleteDigitalSpendImportFieldMapping: Scalars['Boolean'];
  /**
   * Why both String) AND this method?
   * This one adheres better to our "simpleItemDelete" paradigm, and thus plays better with the Apollo cache.
   */
  deleteFavorite: Scalars['Boolean'];
  /**
   * Deletes a InsertionOrderPlacementResult by a given ID.
   *
   *
   * **Returns:**
   * true if the delete succeeded
   */
  deleteInsertionOrderPlacementResult: Scalars['Boolean'];
  deleteMediaAuthorization: Scalars['Boolean'];
  deleteMediaOutletContact: Scalars['Boolean'];
  deleteMediaOutletContacts: Scalars['Boolean'];
  /** Deletes a MediaOutletOffering for a given Id */
  deleteMediaOutletOffering: Scalars['Boolean'];
  deleteNote: Scalars['Boolean'];
  /** Deletes a Package for a given Id */
  deletePackage: Scalars['Boolean'];
  deletePageFavorite: Scalars['Boolean'];
  deletePerson_Client: Scalars['Boolean'];
  deleteProduct: Scalars['Boolean'];
  deleteProjectEstimate: Scalars['Boolean'];
  deleteProjectEstimateDepartment: ProjectEstimate_ForEditing;
  deleteProjectEstimateSubType: Scalars['Boolean'];
  deleteProjectEstimateVersion: ProjectEstimate_ForEditing;
  deleteProjectEstimateVersionFile: Scalars['Boolean'];
  deleteRainOnePerson: Scalars['Boolean'];
  deleteRainOneRole: Scalars['Boolean'];
  deleteRate: Scalars['Boolean'];
  deleteRateCard: Scalars['Boolean'];
  deleteRole: Scalars['Boolean'];
  deleteRoleDepartment: Scalars['Boolean'];
  deleteRowFromCsiFile: DeleteRowFromCsiFilePayload;
  deleteTimeSheetLine: Scalars['Boolean'];
  deleteTimeValidationCatDepartment: Scalars['Boolean'];
  deleteTimeValidationCatProjectGroup: Scalars['Boolean'];
  deleteTimeValidationProjCategoryOverride: Scalars['Boolean'];
  deleteTimesheetDelegate: Scalars['Boolean'];
  deleteTimesheetValidationAdditionalCompany: Scalars['Boolean'];
  deleteTimesheetValidationAdditionalDepartment: Scalars['Boolean'];
  deleteTimesheetValidationPersonAdditionalCategory: Scalars['Boolean'];
  deleteTimesheetValidationProjResourceOverride: Scalars['Boolean'];
  /** Deletes a TrafficSheet for a given Id */
  deleteTrafficSheet: Scalars['Boolean'];
  denyTimesheet: TimeSheet;
  editInvoiceInfoForCSIFile: EditInvoiceInfoForCsiFilePayload;
  generateFinalCostSummary: GenerateFinalCostSummaryPayload;
  generatePreviewCostSummary: Scalars['String'];
  insertCrewCentralPersonUpdate: CrewCentralPersonUpdate_ForDisplay;
  kickOffFunctionalRoleUpdate: Scalars['Boolean'];
  lockProjectEstimateDepartment: LockProjectEstimateDepartmentPayload;
  longRunningTaskUpdate: Scalars['Boolean'];
  markVendorAsNeverExport: Scalars['Boolean'];
  moveTimesheetToNewManagerAsAnAdmin: TimesheetWeekSummary;
  previewMediaAuthorization: Scalars['String'];
  processProofOfPaymentIntoTracker: RainResult;
  reassignInvoice: InvoiceForApproval;
  recallTimesheet: TimeSheet;
  refreshAllUserDnInfo: Scalars['Boolean'];
  refreshUserDnInfo: Scalars['Boolean'];
  refreshVendorCache: Scalars['Boolean'];
  regenEmailPicture: Scalars['Boolean'];
  removeAdminEmail: AppOffline;
  removeCachePhrase: Scalars['Boolean'];
  removeDataGridPreset: Scalars['Boolean'];
  removeDepartmentFromDepartmentGroup: Scalars['Boolean'];
  removeFavorite: Scalars['Boolean'];
  removeFunctionalRoleFromPerson: Scalars['Boolean'];
  removeOnlineApp: AppOffline;
  removePermissionFromRole: Scalars['Boolean'];
  removeRoleFromPerson: Scalars['Boolean'];
  requestPermissionChange: RequestPermissionChangePayload;
  requestTimeSheetUnlock: TimeSheet;
  revertFile: RevertFilePayload;
  saveActivity: SaveActivityPayload;
  saveActivityCategory: SaveActivityCategoryPayload;
  saveAppUserClient: AppUserClient_ForMaintenance;
  /** Saves a given CrossChannelCampaignInput to the database, returning the resultant CrossChannelCampaign. */
  saveCampaignBudgetAllocations: CampaignForBudgetAllocation;
  saveCampaignCrossChannelCampaign: CampaignCrossChannelCampaign;
  saveClient: Client_ForMaintenance;
  saveClientDatoramaKey: ClientDatoramaKey_ForMaintenance;
  saveClientDatoramaKeys: Array<ClientDatoramaKey_ForMaintenance>;
  saveClientLookupAliases: Array<ClientLookupAlias>;
  saveContact: Contact;
  saveContract: Array<CommissionSlidingScale>;
  saveContractTerm: ContractTerm;
  /** Saves a given CrossChannelCampaignInput to the database, returning the resultant CrossChannelCampaign. */
  saveCrossChannelCampaign: CrossChannelCampaignForLookup;
  /** Inserts or updates a given CrossChannelCampaignGroup. */
  saveCrossChannelCampaignGroup: CrossChannelCampaignGroup;
  saveDataGridPresets: DataGridPreset;
  saveDatoramaImportJob?: Maybe<DigitalSpendInputFileForLookup>;
  /** Inserts or updates a given DigitalResultType. */
  saveDigitalResultType: DigitalResultType;
  /**
   * Saves a generic FileReference, gets the ID of that newly-saved record and then re-saved the record with the ID in the path name.  For use with uploading file to AzureBlob storage.
   *
   *
   * **Returns:**
   * The InsertionOrderFileReference that we created
   */
  saveFileReference?: Maybe<FileReference>;
  saveGaReport: Array<Gareport>;
  saveGeneralLedger_HardCost: SaveGeneralLedger_HardCostPayload;
  saveInsertionOrder: InsertionOrder;
  /**
   * Saves a "confirmation" type InsertionOrderFileReference record to the Tracker database.
   *
   *
   * **Returns:**
   * The InsertionOrderFileReference that we created
   */
  saveInsertionOrderConfirmation?: Maybe<InsertionOrderFileReference>;
  /**
   * Saves a given InsertionOrderPlacementResultInput as a new / updated record in the database.
   *
   *
   * **Returns:**
   * The saved InsertionOrderPlacementResult entity
   */
  saveInsertionOrderPlacementResult?: Maybe<InsertionOrderPlacementResult_ForLookup>;
  saveLookup: Lookup;
  saveMediaAuthorization: MediaAuthorization_ForBudgetAllocation;
  /**
   * Saves a FileReference record to the Tracker database and pins it on a MediaAuthorization record.
   *
   *
   * **Returns:**
   * The FileReference that we created
   */
  saveMediaAuthorizationConfirmation?: Maybe<MediaAuthorization_ForBudgetAllocation>;
  saveMediaOutlet: MediaOutlet;
  saveMediaOutletOffering: MediaOutletOffering;
  savePackage: Package;
  saveProduct: Product_ForMaintenance;
  saveProductAudience: ProductAudience;
  saveProjectEstimate: ProjectEstimate_ForEditing;
  saveProjectEstimateApproval: SaveProjectEstimateApprovalPayload;
  saveProjectEstimateDepartment: ProjectEstimate_ForEditing;
  saveProjectEstimateSubType: SaveProjectEstimateSubTypePayload;
  saveProjectHardCost: ProjectHardCost_ForDisplay;
  saveRate: SaveRatePayload;
  saveRateCard: SaveRateCardPayload;
  saveRole: SaveRolePayload;
  saveTimeValidationCatDepartment: SaveTimeValidationCatDepartmentPayload;
  saveTimeValidationCatProjectGroup: SaveTimeValidationCatProjectGroupPayload;
  saveTrafficSheet: TrafficSheet_ForLookup;
  sendFileToCSI: RainResult;
  sendLedgerJournalToD365: Array<LedgerJournalLine>;
  sendTimesheetBackToUserAsAnAdmin: TimesheetWeekSummary;
  setAllAppOfflineStatus: AppOffline;
  setClientBackgroundRefreshAsCompleted: Scalars['Boolean'];
  setClientBackgroundRefreshAsStarted: Scalars['Boolean'];
  startLongRunningTesterService: Scalars['String'];
  stateOfHangfireJob: Scalars['String'];
  submitTimesheet: TimeSheet;
  submitTimesheetAsAnAdmin: TimesheetWeekSummary;
  unapproveInvoice: InvoiceForApproval;
  updateAdminEmails: AppOffline;
  updateAllCaches: Scalars['Boolean'];
  updateAllOfTheCabs: Scalars['Decimal'];
  updateCabsForClient: Scalars['Boolean'];
  updateClientReconciliationStatus: ClientReconciliationMonthStatus;
  updateClientRoleSortOrder: Array<ClientRole_ForDisplay>;
  updateClientTransactionTermGroupOverride: UpdateClientTransactionTermGroupOverridePayload;
  updateDepartmentGroupsSortOrder: Array<DepartmentGroup_ForDisplay>;
  updateDnPersonInfo: Scalars['Boolean'];
  /** Updates the status of an InsertionOrder for a given ID. */
  updateInsertionOrderStatus: InsertionOrderForLookup;
  updateLocalCSIInfoWithoutSendingToCSI: Scalars['Boolean'];
  updateOnlineApps: AppOffline;
  updateProjectEstimateDepartmentDescription: UpdateProjectEstimateDepartmentDescriptionPayload;
  updateProjectEstimateDepartmentStatus: UpdateProjectEstimateDepartmentStatusPayload;
  updateRainOneClient: RainOneClient;
  updateRainOnePerson: UpdateRainOnePersonPayload;
  updateRainOneRole: RainOneRole_ForDisplay;
  updateSourceDateForCbsReport: Scalars['Boolean'];
  updateUserSettings: UserSettings;
  updateUserSettingsForPerson: UserSettings;
};


export type MutationAddAdminEmailArgs = {
  adminEmailToAdd: Scalars['String'];
};


export type MutationAddCatchPhraseArgs = {
  phrase: Scalars['String'];
};


export type MutationAddClientRoleArgs = {
  input: AddClientRoleInput;
};


export type MutationAddClientRoleFunctionalRoleArgs = {
  input: AddClientRoleFunctionalRoleInput;
};


export type MutationAddClientRolePermissionArgs = {
  input: AddClientRolePermissionInput;
};


export type MutationAddDepartmentGroupArgs = {
  input: AddDepartmentGroupInput;
};


export type MutationAddDepartmentGroupFunctionalRoleArgs = {
  input: AddDepartmentGroupFunctionalRoleInput;
};


export type MutationAddDepartmentGroupPermissionArgs = {
  input: AddDepartmentGroupPermissionInput;
};


export type MutationAddDepartmentToDepartmentGroupArgs = {
  input: AddDepartmentToDepartmentGroupInput;
};


export type MutationAddFavoriteArgs = {
  impersonateUser?: Scalars['String'];
  timeSheetLine: TimeSheetLineInput;
};


export type MutationAddFunctionalRoleToPersonArgs = {
  input: AddFunctionalRoleToPersonInput;
};


export type MutationAddOnlineAppArgs = {
  appToAdd: RainOneApp;
};


export type MutationAddOrEditTimeSheetLineArgs = {
  id: Scalars['String'];
  impersonateUser?: Scalars['String'];
  timeSheetLine: TimeSheetLineInput;
};


export type MutationAddOrEditTimeSheetLineEntryArgs = {
  id: Scalars['String'];
  impersonateUser?: Scalars['String'];
  timeSheetLine: TimeSheetLineInput;
};


export type MutationAddOrUpdateCategoryTermGroupTypeMappingArgs = {
  input: AddOrUpdateCategoryTermGroupTypeMappingInput;
};


export type MutationAddOrUpdateCbsReportDefinitionArgs = {
  cbsReportDefinitionInput: CbsReportDefinitionInput;
};


export type MutationAddOrUpdateCbsReportDefinitionDepartmentArgs = {
  cbsReportDefinitionDepartment: CbsReportDefinitionDepartmentInput;
};


export type MutationAddOrUpdateCbsReportDefinitionDepartmentGroupArgs = {
  cbsReportDefinitionDepartmentGroupInpt: CbsReportDefinitionDepartmentGroupInput;
};


export type MutationAddOrUpdateClientAdjustmentArgs = {
  clientAdjustment: ClientAdjustmentInput;
};


export type MutationAddOrUpdateDigitalSpendImportFieldMappingArgs = {
  digitalSpendImportFieldMappingInput: DigitalSpendImportFieldMappingInput;
};


export type MutationAddOrUpdateNoteArgs = {
  input: AddOrUpdateNoteInput;
};


export type MutationAddPageFavoriteArgs = {
  pageFavoriteInput: PageFavoriteInput;
};


export type MutationAddPermissionToRoleArgs = {
  rainOnePermissionId: Scalars['Int'];
  rainOneRoleId: Scalars['Int'];
};


export type MutationAddPerson_ClientArgs = {
  input: AddPerson_ClientInput;
};


export type MutationAddProjectEstimateDepartmentArgs = {
  input: AddProjectEstimateDepartmentInput;
};


export type MutationAddProjectEstimateVersionFileArgs = {
  input: AddProjectEstimateVersionFileInput;
};


export type MutationAddRoleDepartmentArgs = {
  input: AddRoleDepartmentInput;
};


export type MutationAddRoleToPersonArgs = {
  rainOnePersonId: Scalars['Int'];
  rainOneRoleId: Scalars['Int'];
};


export type MutationAddTimeValidationProjCategoryOverrideArgs = {
  input: AddTimeValidationProjCategoryOverrideInput;
};


export type MutationAddTimesheetDelegateArgs = {
  input: AddTimesheetDelegateInput;
};


export type MutationAddTimesheetValidationAdditionalCompanyArgs = {
  input: AddTimesheetValidationAdditionalCompanyInput;
};


export type MutationAddTimesheetValidationAdditionalDepartmentGroupArgs = {
  input: AddTimesheetValidationAdditionalDepartmentGroupInput;
};


export type MutationAddTimesheetValidationPersonAdditionalCategoryArgs = {
  input: AddTimesheetValidationPersonAdditionalCategoryInput;
};


export type MutationAddTimesheetValidationProjResourceOverrideArgs = {
  input: AddTimesheetValidationProjResourceOverrideInput;
};


export type MutationAddTrackerLfClearedsArgs = {
  trackerLFCleareds: Array<TrackerLfClearedInput>;
};


export type MutationAddTrackerLfPrepaidsArgs = {
  trackerLFPrepayments: Array<TrackerLfPrepaymentInput>;
};


export type MutationAddTrackerSfRevRecsArgs = {
  trackerSFRevenueRecs: Array<TrackerSfRevenueRecInput>;
};


export type MutationAddTrackerSfInvoicesArgs = {
  trackerSFInvoices: Array<TrackerSfInvoiceInput>;
};


export type MutationAnnounceActionItemInstanceCreatedArgs = {
  actionItemInstances: Array<Scalars['Int']>;
};


export type MutationApproveInvoiceArgs = {
  invoiceID: Scalars['Int'];
};


export type MutationApproveTimesheetArgs = {
  comment?: InputMaybe<Scalars['String']>;
  timeSheetNbr: Scalars['String'];
};


export type MutationApproveTimesheetAsAnAdminArgs = {
  timesheetNbr: Scalars['String'];
};


export type MutationCancelLockOnAllMyProjectEstimateDepartmentsArgs = {
  projectEstimateId: Scalars['Int'];
};


export type MutationCancelLockOnProjectEstimateDepartmentArgs = {
  projectEstimateDepartmentId: Scalars['Int'];
};


export type MutationCancelProjectEstimateApprovalsArgs = {
  input: CancelProjectEstimateApprovalsInput;
};


export type MutationCancelTimeSheetUnlockArgs = {
  comment?: Scalars['String'];
  impersonateUser?: Scalars['String'];
  timeSheetNbr: Scalars['String'];
};


export type MutationClearActionItemInstanceArgs = {
  actionItemInstanceId: Scalars['Int'];
};


export type MutationClearCacheGroupArgs = {
  cacheGroup: CacheGroupType;
};


export type MutationCompleteActionItemArgs = {
  actionItemId: Scalars['Int'];
};


export type MutationCompleteMediaAuthorizationApprovedTaskArgs = {
  crossChannelCampaignId: Scalars['Int'];
  mediaAuthorizationId: Scalars['Int'];
};


export type MutationCreateInsertionOrderDocumentArgs = {
  insertionOrderID: Scalars['Int'];
};


export type MutationCsiExportArgs = {
  vendorsToUpdate: Array<Scalars['String']>;
};


export type MutationDeactivatePerson_ClientArgs = {
  deactiveAsOfDateUTC: Scalars['DateTime'];
  personClientId: Scalars['Int'];
};


export type MutationDeleteActivityArgs = {
  activityId: Scalars['Int'];
};


export type MutationDeleteActivityCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteAppUserClientArgs = {
  appUserClientId: Scalars['Int'];
};


export type MutationDeleteCampaignCrossChannelCampaignArgs = {
  campaignCrossChannelCampaignId: Scalars['Int'];
};


export type MutationDeleteCbsReportDefinitionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCbsReportDefinitionDepartmentArgs = {
  cbsReportDefinitionDepartmentId: Scalars['Int'];
};


export type MutationDeleteCbsReportDefinitionDepartmentGroupArgs = {
  cbsReportDefinitionDepartmentGroupId: Scalars['Int'];
};


export type MutationDeleteClientAdjustmentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteClientDatoramaKeyArgs = {
  clientDatoramaKeyId: Scalars['Int'];
};


export type MutationDeleteClientRoleArgs = {
  clientRoleId: Scalars['Int'];
};


export type MutationDeleteClientRoleFunctionalRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteClientRolePermissionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteContactArgs = {
  contactID: Scalars['Int'];
};


export type MutationDeleteContractArgs = {
  commissionSlidingScaleId: Scalars['Int'];
};


export type MutationDeleteContractTermArgs = {
  contractTermId: Scalars['Int'];
};


export type MutationDeleteCrossChannelCampaignArgs = {
  crossChannelCampaignID: Scalars['Int'];
};


export type MutationDeleteCrossChannelCampaignGroupArgs = {
  crossChannelCampaignGroupId: Scalars['Int'];
};


export type MutationDeleteDatoramaImportJobArgs = {
  datoramaImportJobId: Scalars['Int'];
};


export type MutationDeleteDepartmentGroupArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDepartmentGroupFunctionalRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDepartmentGroupPermissionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDigitalResultTypeArgs = {
  digitalResultTypeId: Scalars['Int'];
};


export type MutationDeleteDigitalSpendImportFieldMappingArgs = {
  digitalSpendImportFieldMappingID: Scalars['Int'];
};


export type MutationDeleteFavoriteArgs = {
  id: Scalars['Int'];
  impersonateUser?: Scalars['String'];
};


export type MutationDeleteInsertionOrderPlacementResultArgs = {
  insertionOrderPlacementResultId: Scalars['Int'];
};


export type MutationDeleteMediaAuthorizationArgs = {
  mediaAuthorizationId: Scalars['Int'];
};


export type MutationDeleteMediaOutletContactArgs = {
  mediaOutletContactID: Scalars['Int'];
};


export type MutationDeleteMediaOutletContactsArgs = {
  mediaOutletContactIDs: Array<Scalars['Int']>;
};


export type MutationDeleteMediaOutletOfferingArgs = {
  mediaOutletOfferingId: Scalars['Int'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePackageArgs = {
  packageId: Scalars['Int'];
};


export type MutationDeletePageFavoriteArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePerson_ClientArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['Int'];
};


export type MutationDeleteProjectEstimateArgs = {
  projectEstimateId: Scalars['Int'];
};


export type MutationDeleteProjectEstimateDepartmentArgs = {
  projectEstimateDepartmentId: Scalars['Int'];
};


export type MutationDeleteProjectEstimateSubTypeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProjectEstimateVersionArgs = {
  projectEstimateVersionId: Scalars['Int'];
};


export type MutationDeleteProjectEstimateVersionFileArgs = {
  projectEstimateVersionFileId: Scalars['Int'];
};


export type MutationDeleteRainOnePersonArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRainOneRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRateCardArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRoleDepartmentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRowFromCsiFileArgs = {
  input: DeleteRowFromCsiFileInput;
};


export type MutationDeleteTimeSheetLineArgs = {
  impersonateUser?: Scalars['String'];
  recId: Scalars['String'];
  timeSheetNbr: Scalars['String'];
};


export type MutationDeleteTimeValidationCatDepartmentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTimeValidationCatProjectGroupArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTimeValidationProjCategoryOverrideArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTimesheetDelegateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTimesheetValidationAdditionalCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTimesheetValidationAdditionalDepartmentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTimesheetValidationPersonAdditionalCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTimesheetValidationProjResourceOverrideArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTrafficSheetArgs = {
  trafficSheetId: Scalars['Int'];
};


export type MutationDenyTimesheetArgs = {
  comment?: Scalars['String'];
  timeSheetNbr: Scalars['String'];
};


export type MutationEditInvoiceInfoForCsiFileArgs = {
  input: EditInvoiceInfoForCsiFileInput;
};


export type MutationGenerateFinalCostSummaryArgs = {
  input: GenerateFinalCostSummaryInput;
};


export type MutationGeneratePreviewCostSummaryArgs = {
  projectEstimateVersionId: Scalars['Int'];
};


export type MutationInsertCrewCentralPersonUpdateArgs = {
  crewCentralPersonUpdate: CrewCentralPersonUpdateInput;
};


export type MutationLockProjectEstimateDepartmentArgs = {
  input: LockProjectEstimateDepartmentInput;
};


export type MutationLongRunningTaskUpdateArgs = {
  progressStatus: ProgressStatusInput;
  uniqueTaskId: Scalars['String'];
};


export type MutationMarkVendorAsNeverExportArgs = {
  accountNumber: Scalars['String'];
  neverExport?: Scalars['Boolean'];
};


export type MutationMoveTimesheetToNewManagerAsAnAdminArgs = {
  timesheetNbr: Scalars['String'];
};


export type MutationPreviewMediaAuthorizationArgs = {
  mediaAuthorizationInput: MediaAuthorizationInput;
};


export type MutationProcessProofOfPaymentIntoTrackerArgs = {
  fileName: Scalars['String'];
  runOnHangfire?: Scalars['Boolean'];
};


export type MutationReassignInvoiceArgs = {
  appUserID: Scalars['Int'];
  invoiceID: Scalars['Int'];
};


export type MutationRecallTimesheetArgs = {
  comment?: Scalars['String'];
  impersonateUser?: Scalars['String'];
  timeSheetNbr: Scalars['String'];
};


export type MutationRefreshUserDnInfoArgs = {
  finopsPersonId: Scalars['Int'];
};


export type MutationRegenEmailPictureArgs = {
  dayToRegen: Scalars['DateTime'];
  picturePreference: PicturePreference;
};


export type MutationRemoveAdminEmailArgs = {
  adminEmailToRemove: Scalars['String'];
};


export type MutationRemoveCachePhraseArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveDataGridPresetArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveDepartmentFromDepartmentGroupArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFavoriteArgs = {
  impersonateUser?: Scalars['String'];
  timeSheetLine: TimeSheetLineInput;
};


export type MutationRemoveFunctionalRoleFromPersonArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveOnlineAppArgs = {
  appToRemove: RainOneApp;
};


export type MutationRemovePermissionFromRoleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveRoleFromPersonArgs = {
  id: Scalars['Int'];
};


export type MutationRequestPermissionChangeArgs = {
  input: RequestPermissionChangeInput;
};


export type MutationRequestTimeSheetUnlockArgs = {
  comment?: Scalars['String'];
  impersonateUser?: Scalars['String'];
  timeSheetNbr: Scalars['String'];
};


export type MutationRevertFileArgs = {
  input: RevertFileInput;
};


export type MutationSaveActivityArgs = {
  input: SaveActivityInput;
};


export type MutationSaveActivityCategoryArgs = {
  input: SaveActivityCategoryInput;
};


export type MutationSaveAppUserClientArgs = {
  appUserClientInput: AppUserClientInput;
};


export type MutationSaveCampaignBudgetAllocationsArgs = {
  crossChannelCampaignInput: CrossChannelCampaignInput;
};


export type MutationSaveCampaignCrossChannelCampaignArgs = {
  input: CampaignCrossChannelCampaignInput;
};


export type MutationSaveClientArgs = {
  clientInput: ClientInput;
};


export type MutationSaveClientDatoramaKeyArgs = {
  clientDatoramaKeyInput: ClientDatoramaKeyInput;
};


export type MutationSaveClientDatoramaKeysArgs = {
  clientDatoramaKeyInputs: Array<ClientDatoramaKeyInput>;
};


export type MutationSaveClientLookupAliasesArgs = {
  clientID: Scalars['Int'];
  clientLookupAliasInputs: Array<ClientLookupAliasInput>;
  lookupTypeEnum: LookupTypeEnum;
};


export type MutationSaveContactArgs = {
  contactInput: ContactInput;
};


export type MutationSaveContractArgs = {
  contractInput: CommissionSlidingScaleInput;
};


export type MutationSaveContractTermArgs = {
  contractTermInput: ContractTermInput;
};


export type MutationSaveCrossChannelCampaignArgs = {
  crossChannelCampaignInput: CrossChannelCampaignInput;
};


export type MutationSaveCrossChannelCampaignGroupArgs = {
  crossChannelCampaignGroupInput: CrossChannelCampaignGroupInput;
};


export type MutationSaveDataGridPresetsArgs = {
  input: DataGridPresetInput;
};


export type MutationSaveDatoramaImportJobArgs = {
  input: DatoramaImportJobInput;
};


export type MutationSaveDigitalResultTypeArgs = {
  digitalResultTypeInput: DigitalResultTypeInput;
};


export type MutationSaveFileReferenceArgs = {
  fileReferenceInput: FileReferenceInput;
};


export type MutationSaveGaReportArgs = {
  gaReportInput: GareportInput;
};


export type MutationSaveGeneralLedger_HardCostArgs = {
  input: SaveGeneralLedger_HardCostInput;
};


export type MutationSaveInsertionOrderArgs = {
  insertionOrderInput: InsertionOrderInput;
};


export type MutationSaveInsertionOrderConfirmationArgs = {
  insertionOrderFileReferenceInput: InsertionOrderFileReferenceInput;
};


export type MutationSaveInsertionOrderPlacementResultArgs = {
  insertionOrderPlacementResultInput: InsertionOrderPlacementResultInput;
};


export type MutationSaveLookupArgs = {
  lookupInput: LookupInput;
};


export type MutationSaveMediaAuthorizationArgs = {
  mediaAuthorizationInput: MediaAuthorizationInput;
};


export type MutationSaveMediaAuthorizationConfirmationArgs = {
  mediaAuthorizationConfirmationInput: MediaAuthorizationConfirmationInput;
};


export type MutationSaveMediaOutletArgs = {
  mediaOutletInput: MediaOutletInput;
};


export type MutationSaveMediaOutletOfferingArgs = {
  mediaOutletOfferingInput: MediaOutletOfferingInput;
};


export type MutationSavePackageArgs = {
  packageInput: PackageInput;
};


export type MutationSaveProductArgs = {
  productInput: ProductInput;
};


export type MutationSaveProductAudienceArgs = {
  productAudienceInput: ProductAudienceInput;
};


export type MutationSaveProjectEstimateArgs = {
  projectEstimateInput: ProjectEstimateInput;
};


export type MutationSaveProjectEstimateApprovalArgs = {
  input: SaveProjectEstimateApprovalInput;
};


export type MutationSaveProjectEstimateDepartmentArgs = {
  projectEstimateDepartmentInput: ProjectEstimateDepartmentInput;
  unlockOnSave?: Scalars['Boolean'];
};


export type MutationSaveProjectEstimateSubTypeArgs = {
  input: SaveProjectEstimateSubTypeInput;
};


export type MutationSaveProjectHardCostArgs = {
  projectHardCostInput: ProjectHardCostInput;
};


export type MutationSaveRateArgs = {
  input: SaveRateInput;
};


export type MutationSaveRateCardArgs = {
  input: SaveRateCardInput;
};


export type MutationSaveRoleArgs = {
  input: SaveRoleInput;
};


export type MutationSaveTimeValidationCatDepartmentArgs = {
  input: SaveTimeValidationCatDepartmentInput;
};


export type MutationSaveTimeValidationCatProjectGroupArgs = {
  input: SaveTimeValidationCatProjectGroupInput;
};


export type MutationSaveTrafficSheetArgs = {
  trafficSheetInput: TrafficSheetInput;
};


export type MutationSendFileToCsiArgs = {
  fileName: Scalars['String'];
};


export type MutationSendLedgerJournalToD365Args = {
  groupIntercoTransactions: Scalars['Boolean'];
  ledgerJournalHeaderId: Scalars['Int'];
};


export type MutationSendTimesheetBackToUserAsAnAdminArgs = {
  timesheetNbr: Scalars['String'];
};


export type MutationSetAllAppOfflineStatusArgs = {
  areAnyAppsOffline: Scalars['Boolean'];
};


export type MutationSetClientBackgroundRefreshAsCompletedArgs = {
  clientCode: Scalars['String'];
};


export type MutationSetClientBackgroundRefreshAsStartedArgs = {
  clientCode: Scalars['String'];
};


export type MutationStartLongRunningTesterServiceArgs = {
  uniqueTaskId: Scalars['String'];
};


export type MutationStateOfHangfireJobArgs = {
  jobId: Scalars['String'];
};


export type MutationSubmitTimesheetArgs = {
  comment?: Scalars['String'];
  impersonateUser?: Scalars['String'];
  timeSheetNbr: Scalars['String'];
};


export type MutationSubmitTimesheetAsAnAdminArgs = {
  timesheetNbr: Scalars['String'];
};


export type MutationUnapproveInvoiceArgs = {
  invoiceID: Scalars['Int'];
};


export type MutationUpdateAdminEmailsArgs = {
  allAdminEmails: Array<Scalars['String']>;
};


export type MutationUpdateCabsForClientArgs = {
  clearCachesFirst?: Scalars['Boolean'];
  clientCode: Scalars['String'];
};


export type MutationUpdateClientReconciliationStatusArgs = {
  input: ClientReconciliationStatusInput;
};


export type MutationUpdateClientRoleSortOrderArgs = {
  clientRoleSorts: Array<ClientRoleSortOrderInput>;
};


export type MutationUpdateClientTransactionTermGroupOverrideArgs = {
  input: UpdateClientTransactionTermGroupOverrideInput;
};


export type MutationUpdateDepartmentGroupsSortOrderArgs = {
  departmentGroupSorts: Array<DepartmentGroupSortOrderInput>;
};


export type MutationUpdateDnPersonInfoArgs = {
  personId?: Scalars['Int'];
};


export type MutationUpdateInsertionOrderStatusArgs = {
  insertionOrderId: Scalars['Int'];
  status: InsertionOrderStatus;
};


export type MutationUpdateLocalCsiInfoWithoutSendingToCsiArgs = {
  accountNumber: Scalars['String'];
};


export type MutationUpdateOnlineAppsArgs = {
  onlineApps: Array<RainOneApp>;
};


export type MutationUpdateProjectEstimateDepartmentDescriptionArgs = {
  input: UpdateProjectEstimateDepartmentDescriptionInput;
};


export type MutationUpdateProjectEstimateDepartmentStatusArgs = {
  input: UpdateProjectEstimateDepartmentStatusInput;
};


export type MutationUpdateRainOneClientArgs = {
  rainOneClientInput: RainOneClientInput;
};


export type MutationUpdateRainOnePersonArgs = {
  input: UpdateRainOnePersonInput;
};


export type MutationUpdateRainOneRoleArgs = {
  rainOneRole: RainOneRoleInput;
};


export type MutationUpdateSourceDateForCbsReportArgs = {
  clientCode: Scalars['String'];
};


export type MutationUpdateUserSettingsArgs = {
  userSettings: UserSettingsInput;
};


export type MutationUpdateUserSettingsForPersonArgs = {
  userSettings: UserSettingsInput;
};

export type NewCampaignAnnouncement = {
  __typename?: 'NewCampaignAnnouncement';
  accountTeam?: Maybe<Scalars['String']>;
  accountingComment?: Maybe<Scalars['String']>;
  agencyOfRecordDocumentSent: Scalars['Boolean'];
  allowOvernightDubs: Scalars['Boolean'];
  assignmentAndScheduleRecipient?: Maybe<Scalars['String']>;
  buyersDirective?: Maybe<Scalars['String']>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  campaignTarget?: Maybe<Scalars['String']>;
  changingAllBookings?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientAorincluded: Scalars['Boolean'];
  clientComment?: Maybe<Scalars['String']>;
  clientId: Scalars['Int'];
  clientLogo?: Maybe<Scalars['String']>;
  clientLogoFile?: Maybe<File>;
  clientLogoFileId?: Maybe<Scalars['Int']>;
  clientPaid?: Maybe<Scalars['String']>;
  clientPaymentDueDate?: Maybe<Scalars['Date']>;
  clientPrebilled?: Maybe<Scalars['String']>;
  clientQty?: Maybe<Scalars['String']>;
  clientScheduleApproval?: Maybe<Scalars['String']>;
  cmediaComment?: Maybe<Scalars['String']>;
  cmediaQty?: Maybe<Scalars['String']>;
  cmediaTitleListings?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createDate?: Maybe<Scalars['DateTime']>;
  dubHouseVendor?: Maybe<Vendor>;
  dubHouseVendorId?: Maybe<Scalars['Int']>;
  duplicationMasterSetupSenderAppUser?: Maybe<AppUser>;
  duplicationMasterSetupSenderAppUserId?: Maybe<Scalars['Int']>;
  duplicationMasterSetupSentUtc?: Maybe<Scalars['DateTime']>;
  executiveSummary?: Maybe<Scalars['String']>;
  flightEndDate?: Maybe<Scalars['String']>;
  flightStartDate?: Maybe<Scalars['String']>;
  foxApprovalSent: Scalars['Boolean'];
  foxDisclaimers?: Maybe<Scalars['String']>;
  foxLottery?: Maybe<Scalars['String']>;
  foxMoneyBack?: Maybe<Scalars['String']>;
  foxOfferDetails?: Maybe<Scalars['String']>;
  foxProductDesc?: Maybe<Scalars['String']>;
  freeTitleListings?: Maybe<Scalars['String']>;
  hddubs: Scalars['Boolean'];
  hdnotes?: Maybe<Scalars['String']>;
  isClosedCaptionRequired: Scalars['Boolean'];
  isTsbopen24Hours: Scalars['Boolean'];
  isVoprovided?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  manager?: Maybe<Scalars['String']>;
  managerApproval?: Maybe<Scalars['String']>;
  managerNoTrackingApproval?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  newCampaignAnnouncementFileReferences?: Maybe<Array<Maybe<NewCampaignAnnouncementFileReference>>>;
  newCampaignAnnouncementId: Scalars['Int'];
  newCampaignAnnouncementPimediaOutlets?: Maybe<Array<Maybe<NewCampaignAnnouncementPimediaOutlet>>>;
  newCampaignAssociations?: Maybe<Array<Maybe<NewCampaignAssociation>>>;
  newTitleDescription?: Maybe<Scalars['String']>;
  newTitleDescriptionAtTimeOfLastSend?: Maybe<Scalars['String']>;
  newTitleName?: Maybe<Scalars['String']>;
  newTitleNameAtTimeOfLastSend?: Maybe<Scalars['String']>;
  nonCancellableBookingApproval?: Maybe<Scalars['String']>;
  offer?: Maybe<Scalars['String']>;
  oldId?: Maybe<Scalars['Int']>;
  paidTitleListings?: Maybe<Scalars['String']>;
  piairingLimitations?: Maybe<Scalars['String']>;
  picpl?: Maybe<Scalars['Decimal']>;
  piinstructions?: Maybe<Scalars['String']>;
  pimaxWeeklySpend?: Maybe<Scalars['Decimal']>;
  pireportingComment?: Maybe<Scalars['String']>;
  pitrackingCodeComment?: Maybe<Scalars['String']>;
  possibleCancellationDate?: Maybe<Scalars['Date']>;
  product?: Maybe<Product>;
  productCodeDesc?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  replacesOldTape?: Maybe<Scalars['String']>;
  requireTfnassignment?: Maybe<Scalars['String']>;
  responseType?: Maybe<Scalars['String']>;
  sendMediaSchedules?: Maybe<Scalars['String']>;
  sendSlatedViewLinks: Scalars['Boolean'];
  senderAppUser?: Maybe<AppUser>;
  senderAppUserId?: Maybe<Scalars['Int']>;
  sentUtc?: Maybe<Scalars['DateTime']>;
  shouldSendMediaSchedules: Scalars['Boolean'];
  shouldSendTrackingCodeAssignments: Scalars['Boolean'];
  tapeRevisionComment?: Maybe<Scalars['String']>;
  tapesArriveDate?: Maybe<Scalars['String']>;
  targetDemo?: Maybe<Scalars['String']>;
  targetMetric?: Maybe<Scalars['String']>;
  targetMetricLookup?: Maybe<Lookup>;
  targetMetricLookupId?: Maybe<Scalars['Int']>;
  targetMetricValue?: Maybe<Scalars['Decimal']>;
  testingBudget?: Maybe<Scalars['String']>;
  tfntrackingCodeAssignmentTypeLookup?: Maybe<Lookup>;
  tfntrackingCodeAssignmentTypeLookupId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleId?: Maybe<Scalars['Int']>;
  titleListingComments?: Maybe<Scalars['String']>;
  titleNavigation?: Maybe<Title>;
  trafficComment?: Maybe<Scalars['String']>;
  tsbcomment?: Maybe<Scalars['String']>;
  tsbcontact?: Maybe<Contact>;
  tsbcontactId?: Maybe<Scalars['Int']>;
  tsbholidayHours?: Maybe<Scalars['String']>;
  tsbqty?: Maybe<Scalars['String']>;
  tsbvendor?: Maybe<Vendor>;
  tsbvendorId?: Maybe<Scalars['Int']>;
  tsbweekdayHours?: Maybe<Scalars['String']>;
  tsbweekendHours?: Maybe<Scalars['String']>;
  urltrackingCodeAssignmentTypeLookup?: Maybe<Lookup>;
  urltrackingCodeAssignmentTypeLookupId?: Maybe<Scalars['Int']>;
  useExistingTitle: Scalars['Boolean'];
  vanityTfn?: Maybe<Scalars['String']>;
  vanityUrl?: Maybe<Scalars['String']>;
  veilEncodingLookup?: Maybe<Lookup>;
  veilEncodingLookupId?: Maybe<Scalars['Int']>;
  viewTape?: Maybe<Scalars['String']>;
  weeklyBudget?: Maybe<Scalars['String']>;
};

export type NewCampaignAnnouncementFileReference = {
  __typename?: 'NewCampaignAnnouncementFileReference';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  newCampaignAnnouncement?: Maybe<NewCampaignAnnouncement>;
  newCampaignAnnouncementFileReferenceId: Scalars['Int'];
  newCampaignAnnouncementFileTypeLookup?: Maybe<Lookup>;
  newCampaignAnnouncementFileTypeLookupId: Scalars['Int'];
  newCampaignAnnouncementId: Scalars['Int'];
};

export type NewCampaignAnnouncementFileReferenceFilterInput = {
  and?: InputMaybe<Array<NewCampaignAnnouncementFileReferenceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  newCampaignAnnouncement?: InputMaybe<NewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncementFileTypeLookup?: InputMaybe<LookupFilterInput>;
  newCampaignAnnouncementFileTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncementId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NewCampaignAnnouncementFileReferenceFilterInput>>;
};

export type NewCampaignAnnouncementFilterInput = {
  accountTeam?: InputMaybe<StringOperationFilterInput>;
  accountingComment?: InputMaybe<StringOperationFilterInput>;
  agencyOfRecordDocumentSent?: InputMaybe<BooleanOperationFilterInput>;
  allowOvernightDubs?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<NewCampaignAnnouncementFilterInput>>;
  assignmentAndScheduleRecipient?: InputMaybe<StringOperationFilterInput>;
  buyersDirective?: InputMaybe<StringOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  campaignTarget?: InputMaybe<StringOperationFilterInput>;
  changingAllBookings?: InputMaybe<StringOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientAorincluded?: InputMaybe<BooleanOperationFilterInput>;
  clientComment?: InputMaybe<StringOperationFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  clientLogo?: InputMaybe<StringOperationFilterInput>;
  clientLogoFile?: InputMaybe<FileFilterInput>;
  clientLogoFileId?: InputMaybe<IntOperationFilterInput>;
  clientPaid?: InputMaybe<StringOperationFilterInput>;
  clientPaymentDueDate?: InputMaybe<DateOperationFilterInput>;
  clientPrebilled?: InputMaybe<StringOperationFilterInput>;
  clientQty?: InputMaybe<StringOperationFilterInput>;
  clientScheduleApproval?: InputMaybe<StringOperationFilterInput>;
  cmediaComment?: InputMaybe<StringOperationFilterInput>;
  cmediaQty?: InputMaybe<StringOperationFilterInput>;
  cmediaTitleListings?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  dubHouseVendor?: InputMaybe<VendorFilterInput>;
  dubHouseVendorId?: InputMaybe<IntOperationFilterInput>;
  duplicationMasterSetupSenderAppUser?: InputMaybe<AppUserFilterInput>;
  duplicationMasterSetupSenderAppUserId?: InputMaybe<IntOperationFilterInput>;
  duplicationMasterSetupSentUtc?: InputMaybe<DateTimeOperationFilterInput>;
  executiveSummary?: InputMaybe<StringOperationFilterInput>;
  flightEndDate?: InputMaybe<StringOperationFilterInput>;
  flightStartDate?: InputMaybe<StringOperationFilterInput>;
  foxApprovalSent?: InputMaybe<BooleanOperationFilterInput>;
  foxDisclaimers?: InputMaybe<StringOperationFilterInput>;
  foxLottery?: InputMaybe<StringOperationFilterInput>;
  foxMoneyBack?: InputMaybe<StringOperationFilterInput>;
  foxOfferDetails?: InputMaybe<StringOperationFilterInput>;
  foxProductDesc?: InputMaybe<StringOperationFilterInput>;
  freeTitleListings?: InputMaybe<StringOperationFilterInput>;
  hddubs?: InputMaybe<BooleanOperationFilterInput>;
  hdnotes?: InputMaybe<StringOperationFilterInput>;
  isClosedCaptionRequired?: InputMaybe<BooleanOperationFilterInput>;
  isTsbopen24Hours?: InputMaybe<BooleanOperationFilterInput>;
  isVoprovided?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  manager?: InputMaybe<StringOperationFilterInput>;
  managerApproval?: InputMaybe<StringOperationFilterInput>;
  managerNoTrackingApproval?: InputMaybe<StringOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncementFileReferences?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFileReferenceFilterInput>;
  newCampaignAnnouncementId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncementPimediaOutlets?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPimediaOutletFilterInput>;
  newCampaignAssociations?: InputMaybe<ListFilterInputTypeOfNewCampaignAssociationFilterInput>;
  newTitleDescription?: InputMaybe<StringOperationFilterInput>;
  newTitleDescriptionAtTimeOfLastSend?: InputMaybe<StringOperationFilterInput>;
  newTitleName?: InputMaybe<StringOperationFilterInput>;
  newTitleNameAtTimeOfLastSend?: InputMaybe<StringOperationFilterInput>;
  nonCancellableBookingApproval?: InputMaybe<StringOperationFilterInput>;
  offer?: InputMaybe<StringOperationFilterInput>;
  oldId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NewCampaignAnnouncementFilterInput>>;
  paidTitleListings?: InputMaybe<StringOperationFilterInput>;
  piairingLimitations?: InputMaybe<StringOperationFilterInput>;
  picpl?: InputMaybe<DecimalOperationFilterInput>;
  piinstructions?: InputMaybe<StringOperationFilterInput>;
  pimaxWeeklySpend?: InputMaybe<DecimalOperationFilterInput>;
  pireportingComment?: InputMaybe<StringOperationFilterInput>;
  pitrackingCodeComment?: InputMaybe<StringOperationFilterInput>;
  possibleCancellationDate?: InputMaybe<DateOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productCodeDesc?: InputMaybe<StringOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  replacesOldTape?: InputMaybe<StringOperationFilterInput>;
  requireTfnassignment?: InputMaybe<StringOperationFilterInput>;
  responseType?: InputMaybe<StringOperationFilterInput>;
  sendMediaSchedules?: InputMaybe<StringOperationFilterInput>;
  sendSlatedViewLinks?: InputMaybe<BooleanOperationFilterInput>;
  senderAppUser?: InputMaybe<AppUserFilterInput>;
  senderAppUserId?: InputMaybe<IntOperationFilterInput>;
  sentUtc?: InputMaybe<DateTimeOperationFilterInput>;
  shouldSendMediaSchedules?: InputMaybe<BooleanOperationFilterInput>;
  shouldSendTrackingCodeAssignments?: InputMaybe<BooleanOperationFilterInput>;
  tapeRevisionComment?: InputMaybe<StringOperationFilterInput>;
  tapesArriveDate?: InputMaybe<StringOperationFilterInput>;
  targetDemo?: InputMaybe<StringOperationFilterInput>;
  targetMetric?: InputMaybe<StringOperationFilterInput>;
  targetMetricLookup?: InputMaybe<LookupFilterInput>;
  targetMetricLookupId?: InputMaybe<IntOperationFilterInput>;
  targetMetricValue?: InputMaybe<DecimalOperationFilterInput>;
  testingBudget?: InputMaybe<StringOperationFilterInput>;
  tfntrackingCodeAssignmentTypeLookup?: InputMaybe<LookupFilterInput>;
  tfntrackingCodeAssignmentTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  titleId?: InputMaybe<IntOperationFilterInput>;
  titleListingComments?: InputMaybe<StringOperationFilterInput>;
  titleNavigation?: InputMaybe<TitleFilterInput>;
  trafficComment?: InputMaybe<StringOperationFilterInput>;
  tsbcomment?: InputMaybe<StringOperationFilterInput>;
  tsbcontact?: InputMaybe<ContactFilterInput>;
  tsbcontactId?: InputMaybe<IntOperationFilterInput>;
  tsbholidayHours?: InputMaybe<StringOperationFilterInput>;
  tsbqty?: InputMaybe<StringOperationFilterInput>;
  tsbvendor?: InputMaybe<VendorFilterInput>;
  tsbvendorId?: InputMaybe<IntOperationFilterInput>;
  tsbweekdayHours?: InputMaybe<StringOperationFilterInput>;
  tsbweekendHours?: InputMaybe<StringOperationFilterInput>;
  urltrackingCodeAssignmentTypeLookup?: InputMaybe<LookupFilterInput>;
  urltrackingCodeAssignmentTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  useExistingTitle?: InputMaybe<BooleanOperationFilterInput>;
  vanityTfn?: InputMaybe<StringOperationFilterInput>;
  vanityUrl?: InputMaybe<StringOperationFilterInput>;
  veilEncodingLookup?: InputMaybe<LookupFilterInput>;
  veilEncodingLookupId?: InputMaybe<IntOperationFilterInput>;
  viewTape?: InputMaybe<StringOperationFilterInput>;
  weeklyBudget?: InputMaybe<StringOperationFilterInput>;
};

export type NewCampaignAnnouncementPi = {
  __typename?: 'NewCampaignAnnouncementPi';
  accountTeam?: Maybe<Scalars['String']>;
  airingLimits?: Maybe<Scalars['String']>;
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  availableInSpanish?: Maybe<Scalars['Boolean']>;
  buyerAppUser?: Maybe<AppUser>;
  buyerAppUserId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createUtc: Scalars['DateTime'];
  creativeRevisions?: Maybe<Scalars['String']>;
  currentPayoutOnPaidSchedule?: Maybe<Scalars['String']>;
  currentSpendOnPaidSchedule?: Maybe<Scalars['String']>;
  currentlyRunningPi?: Maybe<Scalars['Boolean']>;
  dubHouseContact?: Maybe<Contact>;
  dubHouseContactId?: Maybe<Scalars['Int']>;
  dubHouseVendor?: Maybe<Vendor>;
  dubHouseVendorId?: Maybe<Scalars['Int']>;
  dubRequestInstructions?: Maybe<Scalars['String']>;
  existingPicompanies?: Maybe<Scalars['String']>;
  instructionsForCurrentShow?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lfassistantAppUser?: Maybe<AppUser>;
  lfassistantAppUserId?: Maybe<Scalars['Int']>;
  newCampaignAnnouncementPiassociations?: Maybe<Array<Maybe<NewCampaignAnnouncementPiassociation>>>;
  newCampaignAnnouncementPiid: Scalars['Int'];
  pastPicompanies?: Maybe<Scalars['String']>;
  piamountToChargeClient?: Maybe<Scalars['Decimal']>;
  piamountToPayStation?: Maybe<Scalars['Decimal']>;
  picampaignFlightDates?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  productPrice?: Maybe<Scalars['String']>;
  reportingInstructions?: Maybe<Scalars['String']>;
  sfassistantAppUser?: Maybe<AppUser>;
  sfassistantAppUserId?: Maybe<Scalars['Int']>;
  spendCap?: Maybe<Scalars['Decimal']>;
  telecode?: Maybe<Scalars['String']>;
  tfninstructions?: Maybe<Scalars['String']>;
  tmhours?: Maybe<Scalars['String']>;
  tsbcontact?: Maybe<Contact>;
  tsbcontactId?: Maybe<Scalars['Int']>;
  tsbvendor?: Maybe<Vendor>;
  tsbvendorId?: Maybe<Scalars['Int']>;
};

export type NewCampaignAnnouncementPiFilterInput = {
  accountTeam?: InputMaybe<StringOperationFilterInput>;
  airingLimits?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<NewCampaignAnnouncementPiFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  availableInSpanish?: InputMaybe<BooleanOperationFilterInput>;
  buyerAppUser?: InputMaybe<AppUserFilterInput>;
  buyerAppUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  creativeRevisions?: InputMaybe<StringOperationFilterInput>;
  currentPayoutOnPaidSchedule?: InputMaybe<StringOperationFilterInput>;
  currentSpendOnPaidSchedule?: InputMaybe<StringOperationFilterInput>;
  currentlyRunningPi?: InputMaybe<BooleanOperationFilterInput>;
  dubHouseContact?: InputMaybe<ContactFilterInput>;
  dubHouseContactId?: InputMaybe<IntOperationFilterInput>;
  dubHouseVendor?: InputMaybe<VendorFilterInput>;
  dubHouseVendorId?: InputMaybe<IntOperationFilterInput>;
  dubRequestInstructions?: InputMaybe<StringOperationFilterInput>;
  existingPicompanies?: InputMaybe<StringOperationFilterInput>;
  instructionsForCurrentShow?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lfassistantAppUser?: InputMaybe<AppUserFilterInput>;
  lfassistantAppUserId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncementPiassociations?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiassociationFilterInput>;
  newCampaignAnnouncementPiid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NewCampaignAnnouncementPiFilterInput>>;
  pastPicompanies?: InputMaybe<StringOperationFilterInput>;
  piamountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  piamountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  picampaignFlightDates?: InputMaybe<StringOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productDescription?: InputMaybe<StringOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productPrice?: InputMaybe<StringOperationFilterInput>;
  reportingInstructions?: InputMaybe<StringOperationFilterInput>;
  sfassistantAppUser?: InputMaybe<AppUserFilterInput>;
  sfassistantAppUserId?: InputMaybe<IntOperationFilterInput>;
  spendCap?: InputMaybe<DecimalOperationFilterInput>;
  telecode?: InputMaybe<StringOperationFilterInput>;
  tfninstructions?: InputMaybe<StringOperationFilterInput>;
  tmhours?: InputMaybe<StringOperationFilterInput>;
  tsbcontact?: InputMaybe<ContactFilterInput>;
  tsbcontactId?: InputMaybe<IntOperationFilterInput>;
  tsbvendor?: InputMaybe<VendorFilterInput>;
  tsbvendorId?: InputMaybe<IntOperationFilterInput>;
};

export type NewCampaignAnnouncementPiassociation = {
  __typename?: 'NewCampaignAnnouncementPiassociation';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  newCampaignAnnouncementPi?: Maybe<NewCampaignAnnouncementPi>;
  newCampaignAnnouncementPiassociationId: Scalars['Int'];
  newCampaignAnnouncementPiid?: Maybe<Scalars['Int']>;
};

export type NewCampaignAnnouncementPiassociationFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<NewCampaignAnnouncementPiassociationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  newCampaignAnnouncementPi?: InputMaybe<NewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementPiassociationId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncementPiid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NewCampaignAnnouncementPiassociationFilterInput>>;
};

export type NewCampaignAnnouncementPimediaOutlet = {
  __typename?: 'NewCampaignAnnouncementPimediaOutlet';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  newCampaignAnnouncement?: Maybe<NewCampaignAnnouncement>;
  newCampaignAnnouncementId: Scalars['Int'];
  newCampaignAnnouncementPimediaOutletId: Scalars['Int'];
  payout: Scalars['Decimal'];
  productCode?: Maybe<Scalars['String']>;
};

export type NewCampaignAnnouncementPimediaOutletFilterInput = {
  and?: InputMaybe<Array<NewCampaignAnnouncementPimediaOutletFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncement?: InputMaybe<NewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAnnouncementPimediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NewCampaignAnnouncementPimediaOutletFilterInput>>;
  payout?: InputMaybe<DecimalOperationFilterInput>;
  productCode?: InputMaybe<StringOperationFilterInput>;
};

export type NewCampaignAssociation = {
  __typename?: 'NewCampaignAssociation';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  newCampaignAnnouncement?: Maybe<NewCampaignAnnouncement>;
  newCampaignAnnouncementId: Scalars['Int'];
  newCampaignAssociationId: Scalars['Int'];
  splits?: Maybe<Scalars['String']>;
};

export type NewCampaignAssociationFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<NewCampaignAssociationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  newCampaignAnnouncement?: InputMaybe<NewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementId?: InputMaybe<IntOperationFilterInput>;
  newCampaignAssociationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NewCampaignAssociationFilterInput>>;
  splits?: InputMaybe<StringOperationFilterInput>;
};

export type NielsenDemoPopulation = {
  __typename?: 'NielsenDemoPopulation';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dateModified: Scalars['Date'];
  dmainfo?: Maybe<Dmainfo>;
  dmainfoId?: Maybe<Scalars['Int']>;
  lastEditAppUser?: Maybe<AppUser>;
  lastEditAppUserId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId: Scalars['Int'];
  nielsenDemoPopulationId: Scalars['Int'];
  population: Scalars['Long'];
};

export type NielsenDemoPopulationFilterInput = {
  and?: InputMaybe<Array<NielsenDemoPopulationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dateModified?: InputMaybe<DateOperationFilterInput>;
  dmainfo?: InputMaybe<DmainfoFilterInput>;
  dmainfoId?: InputMaybe<IntOperationFilterInput>;
  lastEditAppUser?: InputMaybe<AppUserFilterInput>;
  lastEditAppUserId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoPopulationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NielsenDemoPopulationFilterInput>>;
  population?: InputMaybe<LongOperationFilterInput>;
};

export enum NoYes {
  No = 'NO',
  Yes = 'YES'
}

export type Note = {
  __typename?: 'Note';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  noteId: Scalars['Int'];
  noteTypeLookup?: Maybe<Lookup>;
  noteTypeLookupId: Scalars['Int'];
  noteValue?: Maybe<Scalars['String']>;
  recordId: Scalars['Int'];
  utc: Scalars['DateTime'];
};

export type NoteFilterInput = {
  and?: InputMaybe<Array<NoteFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  noteId?: InputMaybe<IntOperationFilterInput>;
  noteTypeLookup?: InputMaybe<LookupFilterInput>;
  noteTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  noteValue?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NoteFilterInput>>;
  recordId?: InputMaybe<IntOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
};

export enum NoteType {
  AgingClientNote = 'AGING_CLIENT_NOTE',
  AgingInvoiceNote = 'AGING_INVOICE_NOTE',
  TimeSheetLine = 'TIME_SHEET_LINE'
}

export type Note_ForDisplay = {
  __typename?: 'Note_ForDisplay';
  createdBy: Scalars['String'];
  createdByRainOneUserId: Scalars['Int'];
  createdDateTimeUTC: Scalars['DateTime'];
  id: Scalars['Int'];
  key: Scalars['String'];
  noteText: Scalars['String'];
  noteType: NoteType;
  parentKey?: Maybe<Scalars['String']>;
  profileImageUrl: Scalars['String'];
  updatedDateTimeUTC: Scalars['DateTime'];
};

export type NullableOfFlightDateGranularityOperationFilterInput = {
  eq?: InputMaybe<FlightDateGranularity>;
  in?: InputMaybe<Array<InputMaybe<FlightDateGranularity>>>;
  neq?: InputMaybe<FlightDateGranularity>;
  nin?: InputMaybe<Array<InputMaybe<FlightDateGranularity>>>;
};

export type NullableOfFunctionalRoleOperationFilterInput = {
  eq?: InputMaybe<FunctionalRole>;
  in?: InputMaybe<Array<InputMaybe<FunctionalRole>>>;
  neq?: InputMaybe<FunctionalRole>;
  nin?: InputMaybe<Array<InputMaybe<FunctionalRole>>>;
};

export type Office_ForDisplay = {
  __typename?: 'Office_ForDisplay';
  isActive: Scalars['Boolean'];
  officeId: Scalars['Int'];
  officeName: Scalars['String'];
  officeShortCode: Scalars['String'];
  stateId: Scalars['Int'];
  timeZoneName: Scalars['String'];
};

export type OnlineScheduleAttribute = {
  __typename?: 'OnlineScheduleAttribute';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  onlineContentCategoryLookup?: Maybe<Lookup>;
  onlineContentCategoryLookupId?: Maybe<Scalars['Int']>;
  onlineCreativeLookup?: Maybe<Lookup>;
  onlineCreativeLookupId?: Maybe<Scalars['Int']>;
  onlineCreativeSizeLookup?: Maybe<Lookup>;
  onlineCreativeSizeLookupId?: Maybe<Scalars['Int']>;
  onlinePlacementIdlookup?: Maybe<Lookup>;
  onlinePlacementIdlookupId?: Maybe<Scalars['Int']>;
  onlinePlacementLookup?: Maybe<Lookup>;
  onlinePlacementLookupId?: Maybe<Scalars['Int']>;
  uniqueAdScheduleId: Scalars['Int'];
};

export type OnlineScheduleAttributeFilterInput = {
  and?: InputMaybe<Array<OnlineScheduleAttributeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  onlineContentCategoryLookup?: InputMaybe<LookupFilterInput>;
  onlineContentCategoryLookupId?: InputMaybe<IntOperationFilterInput>;
  onlineCreativeLookup?: InputMaybe<LookupFilterInput>;
  onlineCreativeLookupId?: InputMaybe<IntOperationFilterInput>;
  onlineCreativeSizeLookup?: InputMaybe<LookupFilterInput>;
  onlineCreativeSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  onlinePlacementIdlookup?: InputMaybe<LookupFilterInput>;
  onlinePlacementIdlookupId?: InputMaybe<IntOperationFilterInput>;
  onlinePlacementLookup?: InputMaybe<LookupFilterInput>;
  onlinePlacementLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OnlineScheduleAttributeFilterInput>>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type Package = {
  __typename?: 'Package';
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  packageId: Scalars['Int'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
};

export type PackageFilterInput = {
  and?: InputMaybe<Array<PackageFilterInput>>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PackageFilterInput>>;
  packageId?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
};

export type PackageInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type PageFavorite = {
  __typename?: 'PageFavorite';
  caption: Scalars['String'];
  favoriteTitle: Scalars['String'];
  id: Scalars['Int'];
  inAppPath: Scalars['String'];
  pageType: Scalars['String'];
  rainOneApp: RainOneApp;
  rainOnePersonId: Scalars['Int'];
  title: Scalars['String'];
};

export type PageFavoriteInput = {
  caption: Scalars['String'];
  favoriteTitle: Scalars['String'];
  inAppPath: Scalars['String'];
  pageType: Scalars['String'];
  rainOneApp: RainOneApp;
  title: Scalars['String'];
};

export type ParentInvoice = {
  __typename?: 'ParentInvoice';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  parentInvoiceId: Scalars['Int'];
  tmpInvoices?: Maybe<Array<Maybe<TmpInvoice>>>;
  vendorInvoiceNumber?: Maybe<Scalars['String']>;
};

export type ParentInvoiceFilterInput = {
  and?: InputMaybe<Array<ParentInvoiceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  invoices?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ParentInvoiceFilterInput>>;
  parentInvoiceId?: InputMaybe<IntOperationFilterInput>;
  tmpInvoices?: InputMaybe<ListFilterInputTypeOfTmpInvoiceFilterInput>;
  vendorInvoiceNumber?: InputMaybe<StringOperationFilterInput>;
};

export type Parser = {
  __typename?: 'Parser';
  active: Scalars['Boolean'];
  documentType?: Maybe<Scalars['String']>;
  fileToProcesses?: Maybe<Array<Maybe<FileToProcess>>>;
  fileType?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletGroup?: Maybe<MediaOutletGroup>;
  mediaOutletGroupId?: Maybe<Scalars['Int']>;
  mediaOutletParsers?: Maybe<Array<Maybe<MediaOutletParser>>>;
  parserFileReferences?: Maybe<Array<Maybe<ParserFileReference>>>;
  parserId: Scalars['Int'];
  parserName?: Maybe<Scalars['String']>;
  sampleFilePath?: Maybe<Scalars['String']>;
  signifyingText?: Maybe<Scalars['String']>;
  useOcr: Scalars['Boolean'];
};

export type ParserFileReference = {
  __typename?: 'ParserFileReference';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  parser?: Maybe<Parser>;
  parserFileReferenceId: Scalars['Int'];
  parserId: Scalars['Int'];
};

export type ParserFileReferenceFilterInput = {
  and?: InputMaybe<Array<ParserFileReferenceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ParserFileReferenceFilterInput>>;
  parser?: InputMaybe<ParserFilterInput>;
  parserFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  parserId?: InputMaybe<IntOperationFilterInput>;
};

export type ParserFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<ParserFilterInput>>;
  documentType?: InputMaybe<StringOperationFilterInput>;
  fileToProcesses?: InputMaybe<ListFilterInputTypeOfFileToProcessFilterInput>;
  fileType?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletGroup?: InputMaybe<MediaOutletGroupFilterInput>;
  mediaOutletGroupId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletParsers?: InputMaybe<ListFilterInputTypeOfMediaOutletParserFilterInput>;
  or?: InputMaybe<Array<ParserFilterInput>>;
  parserFileReferences?: InputMaybe<ListFilterInputTypeOfParserFileReferenceFilterInput>;
  parserId?: InputMaybe<IntOperationFilterInput>;
  parserName?: InputMaybe<StringOperationFilterInput>;
  sampleFilePath?: InputMaybe<StringOperationFilterInput>;
  signifyingText?: InputMaybe<StringOperationFilterInput>;
  useOcr?: InputMaybe<BooleanOperationFilterInput>;
};

export type PaymentProofUpload = {
  __typename?: 'PaymentProofUpload';
  checkCount: Scalars['Int'];
  eftVoucherCount: Scalars['Int'];
  fileName?: Maybe<Scalars['String']>;
  isSuccess: Scalars['Boolean'];
  matchedCheckCount: Scalars['Int'];
  matchedEFTVoucherCount: Scalars['Int'];
  matchedVCardVoucherCount: Scalars['Int'];
  paymentProofUploadId: Scalars['Int'];
  uploadEndDateUTC: Scalars['DateTime'];
  uploadStartDateUTC: Scalars['DateTime'];
  vCardVoucherCount: Scalars['Int'];
};

export type PeopleMissingGoodCostInfo = {
  __typename?: 'PeopleMissingGoodCostInfo';
  billRateDescription: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
  companyName: Scalars['String'];
  costDescription: Scalars['String'];
  costSource: Scalars['Int'];
  costSourceName: Scalars['String'];
  departmentId?: Maybe<Scalars['Int']>;
  departmentName: Scalars['String'];
  employmentTypeId?: Maybe<Scalars['Int']>;
  employmentTypeName: Scalars['String'];
  endDate: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  personEmploymentId?: Maybe<Scalars['Int']>;
  personId: Scalars['Int'];
  rateCardId: Scalars['Int'];
  reason: Scalars['String'];
  roleId?: Maybe<Scalars['Int']>;
  roleName: Scalars['String'];
  startDate: Scalars['DateTime'];
  titleName: Scalars['String'];
  totalHours: Scalars['Decimal'];
};

export type PerInquiryCost = {
  __typename?: 'PerInquiryCost';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  autoSchedule?: Maybe<AutoSchedule>;
  autoScheduleId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createdUtc: Scalars['DateTime'];
  effectiveDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  perInquiryCostId: Scalars['Int'];
  perInquiryCostTypeLookup?: Maybe<Lookup>;
  perInquiryCostTypeLookupId: Scalars['Int'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
  telemarketingFee?: Maybe<Scalars['Decimal']>;
  value: Scalars['Decimal'];
};

export type PerInquiryCostFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<PerInquiryCostFilterInput>>;
  autoSchedule?: InputMaybe<AutoScheduleFilterInput>;
  autoScheduleId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createdUtc?: InputMaybe<DateTimeOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PerInquiryCostFilterInput>>;
  perInquiryCostId?: InputMaybe<IntOperationFilterInput>;
  perInquiryCostTypeLookup?: InputMaybe<LookupFilterInput>;
  perInquiryCostTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  telemarketingFee?: InputMaybe<DecimalOperationFilterInput>;
  value?: InputMaybe<DecimalOperationFilterInput>;
};

export type PersonFunctionalRole = {
  __typename?: 'PersonFunctionalRole';
  createdDate: Scalars['DateTime'];
  functionalRoleId: Scalars['Int'];
  id: Scalars['Int'];
  isAutoCreated: Scalars['Boolean'];
  rainOnePerson: RainOnePerson;
  rainOnePersonId: Scalars['Int'];
};

export type PersonFunctionalRoleFilterInput = {
  and?: InputMaybe<Array<PersonFunctionalRoleFilterInput>>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  functionalRoleId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isAutoCreated?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<PersonFunctionalRoleFilterInput>>;
  rainOnePerson?: InputMaybe<RainOnePersonFilterInput>;
  rainOnePersonId?: InputMaybe<IntOperationFilterInput>;
};

export type PersonFunctionalRole_ForDisplay = {
  __typename?: 'PersonFunctionalRole_ForDisplay';
  functionalRole: FunctionalRole;
  functionalRoleId: Scalars['Int'];
  id: Scalars['Int'];
  isAutoCreated: Scalars['Boolean'];
  isDirectlyAssigned: Scalars['Boolean'];
  rainOnePersonId: Scalars['Int'];
};

export enum PersonLinkType {
  B2CUniqueId = 'B2_C_UNIQUE_ID',
  FinOps = 'FIN_OPS',
  Tracker = 'TRACKER'
}

export type PersonLinkTypeOperationFilterInput = {
  eq?: InputMaybe<PersonLinkType>;
  in?: InputMaybe<Array<PersonLinkType>>;
  neq?: InputMaybe<PersonLinkType>;
  nin?: InputMaybe<Array<PersonLinkType>>;
};

export type Person_Client = {
  __typename?: 'Person_Client';
  addedBy: Scalars['String'];
  brandCode?: Maybe<Scalars['String']>;
  clientCode: Scalars['String'];
  clientRole: ClientRole;
  clientRoleId?: Maybe<Scalars['Int']>;
  dateAddedUTC: Scalars['DateTime'];
  dateDeactivedUTC?: Maybe<Scalars['DateTime']>;
  /** To be phased out and replaced with RainOnePersonId */
  finopsPersonId?: Maybe<Scalars['Int']>;
  person_ClientId: Scalars['Int'];
  rainOnePerson: RainOnePerson;
  /** Replaces FinopsPersonId as the user reference. */
  rainOnePersonId?: Maybe<Scalars['Int']>;
};

export type Person_ClientFilterInput = {
  addedBy?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Person_ClientFilterInput>>;
  brandCode?: InputMaybe<StringOperationFilterInput>;
  clientCode?: InputMaybe<StringOperationFilterInput>;
  clientRole?: InputMaybe<ClientRoleFilterInput>;
  clientRoleId?: InputMaybe<IntOperationFilterInput>;
  dateAddedUTC?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeactivedUTC?: InputMaybe<DateTimeOperationFilterInput>;
  /** To be phased out and replaced with RainOnePersonId */
  finopsPersonId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Person_ClientFilterInput>>;
  person_ClientId?: InputMaybe<IntOperationFilterInput>;
  rainOnePerson?: InputMaybe<RainOnePersonFilterInput>;
  /** Replaces FinopsPersonId as the user reference. */
  rainOnePersonId?: InputMaybe<IntOperationFilterInput>;
};

export type Person_ClientUpdateMessage = {
  __typename?: 'Person_ClientUpdateMessage';
  isDeleted: Scalars['Boolean'];
  personCLientId: Scalars['Int'];
  updatedByPersonName: Scalars['String'];
  updatedByRainOnePersonId: Scalars['Int'];
};

export type Person_Client_ForDisplay = {
  __typename?: 'Person_Client_ForDisplay';
  addedBy: Scalars['String'];
  brandCode?: Maybe<Scalars['String']>;
  clientCode: Scalars['String'];
  clientName: Scalars['String'];
  clientRole?: Maybe<ClientRole>;
  clientRoleId?: Maybe<Scalars['Int']>;
  clientRoleName: Scalars['String'];
  dateAddedUTC: Scalars['DateTime'];
  dateDeactivedUTC?: Maybe<Scalars['DateTime']>;
  departmentGroupName: Scalars['String'];
  finopsPersonId?: Maybe<Scalars['Int']>;
  person?: Maybe<RainOnePerson>;
  personName: Scalars['String'];
  person_ClientId: Scalars['Int'];
  rainOnePersonId?: Maybe<Scalars['Int']>;
};

export type Person_Client_Update = {
  __typename?: 'Person_Client_Update';
  person_Client?: Maybe<Person_Client_ForDisplay>;
  person_ClientUpdateMessage: Person_ClientUpdateMessage;
};

export type Person_ForDisplay = {
  __typename?: 'Person_ForDisplay';
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  personId: Scalars['Int'];
};

export enum PicturePreference {
  /** Cat of the Day */
  Cat = 'CAT',
  /** Dog of the Day */
  Dog = 'DOG',
  /** No Picture */
  NoPicture = 'NO_PICTURE',
  /** Panda of the Day */
  Panda = 'PANDA'
}

export type Prelog = {
  __typename?: 'Prelog';
  adInstanceDetails?: Maybe<Array<Maybe<AdInstanceDetail>>>;
  adInstances?: Maybe<Array<Maybe<AdInstance>>>;
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  bvsprogramName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  createUtc?: Maybe<Scalars['DateTime']>;
  date: Scalars['Date'];
  fileImport?: Maybe<FileImport>;
  fileImportId?: Maybe<Scalars['Int']>;
  isCreatedByProcessor: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  oldid?: Maybe<Scalars['Int']>;
  prelogEstimate?: Maybe<PrelogEstimate>;
  prelogEstimateId?: Maybe<Scalars['Int']>;
  prelogId: Scalars['Int'];
  prelogStatusLookup?: Maybe<Lookup>;
  prelogStatusLookupId: Scalars['Int'];
  roviProgramId?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['TimeSpan']>;
  uniqueAdSchedule?: Maybe<UniqueAdSchedule>;
  uniqueAdScheduleId: Scalars['Int'];
  utc?: Maybe<Scalars['DateTime']>;
};

export type PrelogEstimate = {
  __typename?: 'PrelogEstimate';
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dateEstimated?: Maybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  manualClearanceLogs?: Maybe<Array<Maybe<ManualClearanceLog>>>;
  prelogEstimateId: Scalars['Int'];
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  timeZoneInfo?: Maybe<TimeZoneInfo>;
  timeZoneInfoId?: Maybe<Scalars['Int']>;
  whereClause?: Maybe<Scalars['String']>;
};

export type PrelogEstimateFilterInput = {
  and?: InputMaybe<Array<PrelogEstimateFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dateEstimated?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  manualClearanceLogs?: InputMaybe<ListFilterInputTypeOfManualClearanceLogFilterInput>;
  or?: InputMaybe<Array<PrelogEstimateFilterInput>>;
  prelogEstimateId?: InputMaybe<IntOperationFilterInput>;
  prelogs?: InputMaybe<ListFilterInputTypeOfPrelogFilterInput>;
  timeZoneInfo?: InputMaybe<TimeZoneInfoFilterInput>;
  timeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
  whereClause?: InputMaybe<StringOperationFilterInput>;
};

export type PrelogFilterInput = {
  adInstanceDetails?: InputMaybe<ListFilterInputTypeOfAdInstanceDetailFilterInput>;
  adInstances?: InputMaybe<ListFilterInputTypeOfAdInstanceFilterInput>;
  and?: InputMaybe<Array<PrelogFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  bvsprogramName?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  isCreatedByProcessor?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PrelogFilterInput>>;
  prelogEstimate?: InputMaybe<PrelogEstimateFilterInput>;
  prelogEstimateId?: InputMaybe<IntOperationFilterInput>;
  prelogId?: InputMaybe<IntOperationFilterInput>;
  prelogStatusLookup?: InputMaybe<LookupFilterInput>;
  prelogStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
  time?: InputMaybe<TimeSpanOperationFilterInput>;
  uniqueAdSchedule?: InputMaybe<UniqueAdScheduleFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type PreviousBalanceDetail = {
  __typename?: 'PreviousBalanceDetail';
  balance: Scalars['Decimal'];
  cabReportDefinitionId: Scalars['Int'];
  cabReportName: Scalars['String'];
  id: Scalars['Int'];
  throughMediaMonthName: Scalars['String'];
  throughMediaMonthValue: Scalars['String'];
};

export type PrintAvail = {
  __typename?: 'PrintAvail';
  adSizeLookup?: Maybe<Lookup>;
  adSizeLookupId?: Maybe<Scalars['Int']>;
  circulation?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  editorial?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  frequency?: Maybe<Scalars['String']>;
  geography?: Maybe<Scalars['String']>;
  inHomeDate?: Maybe<Scalars['Date']>;
  issueDateLookup?: Maybe<Lookup>;
  issueDateLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  materialsDate?: Maybe<Scalars['Date']>;
  materialsExtensionDate?: Maybe<Scalars['Date']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  netRate?: Maybe<Scalars['Decimal']>;
  openRate?: Maybe<Scalars['Decimal']>;
  printAvailId: Scalars['Int'];
  spaceDate?: Maybe<Scalars['Date']>;
  spaceExtensionDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type PrintAvailFilterInput = {
  adSizeLookup?: InputMaybe<LookupFilterInput>;
  adSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<PrintAvailFilterInput>>;
  circulation?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  editorial?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  frequency?: InputMaybe<StringOperationFilterInput>;
  geography?: InputMaybe<StringOperationFilterInput>;
  inHomeDate?: InputMaybe<DateOperationFilterInput>;
  issueDateLookup?: InputMaybe<LookupFilterInput>;
  issueDateLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  materialsDate?: InputMaybe<DateOperationFilterInput>;
  materialsExtensionDate?: InputMaybe<DateOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  netRate?: InputMaybe<DecimalOperationFilterInput>;
  openRate?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<PrintAvailFilterInput>>;
  printAvailId?: InputMaybe<IntOperationFilterInput>;
  spaceDate?: InputMaybe<DateOperationFilterInput>;
  spaceExtensionDate?: InputMaybe<DateOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type PrintScheduleAttribute = {
  __typename?: 'PrintScheduleAttribute';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  printAdSizeLookup?: Maybe<Lookup>;
  printAdSizeLookupId: Scalars['Int'];
  printAdTypeLookup?: Maybe<Lookup>;
  printAdTypeLookupId?: Maybe<Scalars['Int']>;
  printLocationLookup?: Maybe<Lookup>;
  printLocationLookupId?: Maybe<Scalars['Int']>;
  printSectionLookup?: Maybe<Lookup>;
  printSectionLookupId?: Maybe<Scalars['Int']>;
  uniqueAdScheduleId: Scalars['Int'];
};

export type PrintScheduleAttributeFilterInput = {
  and?: InputMaybe<Array<PrintScheduleAttributeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PrintScheduleAttributeFilterInput>>;
  printAdSizeLookup?: InputMaybe<LookupFilterInput>;
  printAdSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  printAdTypeLookup?: InputMaybe<LookupFilterInput>;
  printAdTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  printLocationLookup?: InputMaybe<LookupFilterInput>;
  printLocationLookupId?: InputMaybe<IntOperationFilterInput>;
  printSectionLookup?: InputMaybe<LookupFilterInput>;
  printSectionLookupId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type PrintTerritory = {
  __typename?: 'PrintTerritory';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  dmainfo?: Maybe<Dmainfo>;
  dmainfoId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletPrintTerritories?: Maybe<Array<Maybe<MediaOutletPrintTerritory>>>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  oldid?: Maybe<Scalars['Int']>;
  printTerritoryId: Scalars['Int'];
  schedules?: Maybe<Array<Maybe<Schedule>>>;
};

export type PrintTerritoryFilterInput = {
  and?: InputMaybe<Array<PrintTerritoryFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dmainfo?: InputMaybe<DmainfoFilterInput>;
  dmainfoId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletPrintTerritories?: InputMaybe<ListFilterInputTypeOfMediaOutletPrintTerritoryFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PrintTerritoryFilterInput>>;
  printTerritoryId?: InputMaybe<IntOperationFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
};

export type Processor = {
  __typename?: 'Processor';
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  expectedInput?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  isLaunchableViaTracker: Scalars['Boolean'];
  jobs?: Maybe<Array<Maybe<Job>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notifyIfNotCompleteWithin?: Maybe<Scalars['TimeSpan']>;
  processorId: Scalars['Int'];
};

export type ProcessorFilterInput = {
  and?: InputMaybe<Array<ProcessorFilterInput>>;
  automatedFileProcessings?: InputMaybe<ListFilterInputTypeOfAutomatedFileProcessingFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  expectedInput?: InputMaybe<StringOperationFilterInput>;
  filePath?: InputMaybe<StringOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  isLaunchableViaTracker?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notifyIfNotCompleteWithin?: InputMaybe<TimeSpanOperationFilterInput>;
  or?: InputMaybe<Array<ProcessorFilterInput>>;
  processorId?: InputMaybe<IntOperationFilterInput>;
};

export type ProcessorSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  expectedInput?: InputMaybe<SortEnumType>;
  filePath?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  isLaunchableViaTracker?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notifyIfNotCompleteWithin?: InputMaybe<SortEnumType>;
  processorId?: InputMaybe<SortEnumType>;
};

export type Product = {
  __typename?: 'Product';
  abbreviation?: Maybe<Scalars['String']>;
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  axprojectCode?: Maybe<Scalars['String']>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createVerbals: Scalars['Boolean'];
  creatives?: Maybe<Array<Maybe<Creative>>>;
  crossChannelCampaigns?: Maybe<Array<Maybe<CrossChannelCampaign>>>;
  datoramaImportJobProducts?: Maybe<Array<Maybe<DatoramaImportJobProduct>>>;
  duplicationCostEstimates?: Maybe<Array<Maybe<DuplicationCostEstimate>>>;
  gareports?: Maybe<Array<Maybe<Gareport>>>;
  inverseNonR2cproduct?: Maybe<Array<Maybe<Product>>>;
  inverseParentProduct?: Maybe<Array<Maybe<Product>>>;
  isCrossCompany?: Maybe<Scalars['Boolean']>;
  isIngestible: Scalars['Boolean'];
  isLfminHoursSeparationIgnorable: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingLocks?: Maybe<Array<Maybe<LeadBillingLock>>>;
  leadBillingReportPreset?: Maybe<ReportPreset>;
  leadBillingReportPresetId?: Maybe<Scalars['Int']>;
  leadDedupingDays?: Maybe<Scalars['Int']>;
  lfminHoursSeparation?: Maybe<Scalars['Decimal']>;
  localMarketRatingEstimates?: Maybe<Array<Maybe<LocalMarketRatingEstimate>>>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  marketContributionAnalyses?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementPis?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nonR2cproduct?: Maybe<Product>;
  nonR2cproductId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  overrideClientName?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Maybe<Package>>>;
  parentProduct?: Maybe<Product>;
  parentProductId?: Maybe<Scalars['Int']>;
  processorConnectionStringName?: Maybe<Scalars['String']>;
  processorDbname?: Maybe<Scalars['String']>;
  processorViewName?: Maybe<Scalars['String']>;
  productAudiences?: Maybe<Array<Maybe<ProductAudience>>>;
  productCategoryLookup?: Maybe<Lookup>;
  productCategoryLookupId: Scalars['Int'];
  productDateBlackLists?: Maybe<Array<Maybe<ProductDateBlackList>>>;
  productDimensionTypes?: Maybe<Array<Maybe<ProductDimensionType>>>;
  productFulfillments?: Maybe<Array<Maybe<ProductFulfillment>>>;
  productHours?: Maybe<Array<Maybe<ProductHour>>>;
  productId: Scalars['Int'];
  productMediaOutletBlackLists?: Maybe<Array<Maybe<ProductMediaOutletBlackList>>>;
  productMediaOutletPirates?: Maybe<Array<Maybe<ProductMediaOutletPirate>>>;
  productMediaTargets?: Maybe<Array<Maybe<ProductMediaTarget>>>;
  productMediaTypeLockDates?: Maybe<Array<Maybe<ProductMediaTypeLockDate>>>;
  productOfferGroups?: Maybe<Array<Maybe<ProductOfferGroup>>>;
  productProgramBlackLists?: Maybe<Array<Maybe<ProductProgramBlackList>>>;
  productRegionBlackLists?: Maybe<Array<Maybe<ProductRegionBlackList>>>;
  productSubCategoryLookup?: Maybe<Lookup>;
  productSubCategoryLookupId?: Maybe<Scalars['Int']>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  revisionRequests?: Maybe<Array<Maybe<RevisionRequest>>>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  sfprogrammingNotes?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  surveys?: Maybe<Array<Maybe<Survey>>>;
  title?: Maybe<Title>;
  titleId?: Maybe<Scalars['Int']>;
  titles?: Maybe<Array<Maybe<Title>>>;
  trackingCodeAvailSetupTypeLookup?: Maybe<Lookup>;
  trackingCodeAvailSetupTypeLookupId: Scalars['Int'];
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  waveCastMappings?: Maybe<Array<Maybe<WaveCastMapping>>>;
  waveCastModels?: Maybe<Array<Maybe<WaveCastModel>>>;
};

export type ProductAudience = {
  __typename?: 'ProductAudience';
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productAudienceId: Scalars['Int'];
  productId: Scalars['Int'];
  trafficSheetDetails?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
};

export type ProductAudienceFilterInput = {
  and?: InputMaybe<Array<ProductAudienceFilterInput>>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductAudienceFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productAudienceId?: InputMaybe<IntOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
};

export type ProductAudienceInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  productAudienceId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type ProductAudienceSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productAudienceId?: InputMaybe<SortEnumType>;
  productId?: InputMaybe<SortEnumType>;
};

export type ProductAudience_ForBudgetAllocation = {
  __typename?: 'ProductAudience_ForBudgetAllocation';
  budgetAllocations?: Maybe<Array<Maybe<BudgetAllocation>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productAudienceId: Scalars['Int'];
  productId: Scalars['Int'];
  trafficSheetDetails?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
};

export type ProductAudience_ForBudgetAllocationFilterInput = {
  and?: InputMaybe<Array<ProductAudience_ForBudgetAllocationFilterInput>>;
  budgetAllocations?: InputMaybe<ListFilterInputTypeOfBudgetAllocationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductAudience_ForBudgetAllocationFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productAudienceId?: InputMaybe<IntOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
};

export type ProductCategoryHierarchy = {
  __typename?: 'ProductCategoryHierarchy';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  productCategoryHierarchyId: Scalars['Int'];
  productCategoryLookup?: Maybe<Lookup>;
  productCategoryLookupId: Scalars['Int'];
  productSubCategoryLookup?: Maybe<Lookup>;
  productSubCategoryLookupId: Scalars['Int'];
  productTypeLookup?: Maybe<Lookup>;
  productTypeLookupId: Scalars['Int'];
};

export type ProductCategoryHierarchyFilterInput = {
  and?: InputMaybe<Array<ProductCategoryHierarchyFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductCategoryHierarchyFilterInput>>;
  productCategoryHierarchyId?: InputMaybe<IntOperationFilterInput>;
  productCategoryLookup?: InputMaybe<LookupFilterInput>;
  productCategoryLookupId?: InputMaybe<IntOperationFilterInput>;
  productSubCategoryLookup?: InputMaybe<LookupFilterInput>;
  productSubCategoryLookupId?: InputMaybe<IntOperationFilterInput>;
  productTypeLookup?: InputMaybe<LookupFilterInput>;
  productTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductCategory_ForProductMaintenance = {
  __typename?: 'ProductCategory_ForProductMaintenance';
  accountingBatchLineLinks?: Maybe<Array<Maybe<AccountingBatchLineLink>>>;
  accountingBatches?: Maybe<Array<Maybe<AccountingBatch>>>;
  accountingLineBuyTypeNavigations?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLineMonthDefinitionLookups?: Maybe<Array<Maybe<AccountingLine>>>;
  accountingLineSubTypeLookups?: Maybe<Array<Maybe<AccountingLine>>>;
  adAgencyLookups?: Maybe<Array<Maybe<Ad>>>;
  adAxtypeLookups?: Maybe<Array<Maybe<Ad>>>;
  adCampaignStatusLookups?: Maybe<Array<Maybe<Ad>>>;
  adCreativeAgencyLookups?: Maybe<Array<Maybe<Ad>>>;
  adInstanceImportDateDefinitionLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportRateTypeLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportRatingImportModeLookups?: Maybe<Array<Maybe<AdInstanceImport>>>;
  adInstanceImportTargetDemos?: Maybe<Array<Maybe<AdInstanceImportTargetDemo>>>;
  adInstances?: Maybe<Array<Maybe<AdInstance>>>;
  adLanguageLookups?: Maybe<Array<Maybe<Ad>>>;
  adMailerFormatLookups?: Maybe<Array<Maybe<Ad>>>;
  adOfferTypeLookups?: Maybe<Array<Maybe<Ad>>>;
  adTapeLengthLookups?: Maybe<Array<Maybe<Ad>>>;
  adVeilEncodingLookups?: Maybe<Array<Maybe<Ad>>>;
  appFieldAggregateFormatLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldAggregateTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldDataTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldDefinitionTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldFormatLookups?: Maybe<Array<Maybe<AppField>>>;
  appFieldTempTableConsolidateTypeLookups?: Maybe<Array<Maybe<AppField>>>;
  appFilterOperators?: Maybe<Array<Maybe<AppFilterOperator>>>;
  appFilters?: Maybe<Array<Maybe<AppFilter>>>;
  appLogEntries?: Maybe<Array<Maybe<AppLogEntry>>>;
  appScreenInitialPositionLookups?: Maybe<Array<Maybe<AppScreen>>>;
  appScreenTabTypeLookups?: Maybe<Array<Maybe<AppScreen>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  appUserRoles?: Maybe<Array<Maybe<AppUserRole>>>;
  appUserSettings?: Maybe<Array<Maybe<AppUserSetting>>>;
  appViewFields?: Maybe<Array<Maybe<AppViewField>>>;
  appViews?: Maybe<Array<Maybe<AppView>>>;
  autoReportItems?: Maybe<Array<Maybe<AutoReportItem>>>;
  autoScheduleBuyTypeLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleInstanceCreationLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoSchedulePibasedOnLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleRateTypeLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleStatusLookups?: Maybe<Array<Maybe<AutoSchedule>>>;
  autoScheduleTrafficMediaChanges?: Maybe<Array<Maybe<AutoScheduleTrafficMediaChange>>>;
  autoScheduleTraffics?: Maybe<Array<Maybe<AutoScheduleTraffic>>>;
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  automatedFileProcessings?: Maybe<Array<Maybe<AutomatedFileProcessing>>>;
  availProposalDetailAttributes?: Maybe<Array<Maybe<AvailProposalDetailAttribute>>>;
  availProposalDetailRateTypeLookups?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  availProposalDetailTapeLengthLookups?: Maybe<Array<Maybe<AvailProposalDetail>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  budgetAllocationBuyTypeLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationCostMethodLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationFees?: Maybe<Array<Maybe<BudgetAllocationFee>>>;
  budgetAllocationFlights?: Maybe<Array<Maybe<BudgetAllocationFlight>>>;
  budgetAllocationProgrammaticExecutionLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationStrategyLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationTacticLookups?: Maybe<Array<Maybe<BudgetAllocation>>>;
  budgetAllocationTags?: Maybe<Array<Maybe<BudgetAllocationTag>>>;
  budgetBuyerSplits?: Maybe<Array<Maybe<BudgetBuyerSplit>>>;
  budgetRegionTypeSplits?: Maybe<Array<Maybe<BudgetRegionTypeSplit>>>;
  campaignAppliesToLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignAxtypeLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignBudgetEnforcementLookups?: Maybe<Array<Maybe<Campaign>>>;
  campaignClientApprovalRequirementLookups?: Maybe<Array<Maybe<Campaign>>>;
  clientBonusGroupLookups?: Maybe<Array<Maybe<Client>>>;
  clientFileReferences?: Maybe<Array<Maybe<ClientFileReference>>>;
  clientLookupAliases?: Maybe<Array<Maybe<ClientLookupAlias>>>;
  clientMediaOutletClientMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletClientReducedAudienceFeedMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMediaOutletReducedAudienceFeedMediaClassificationLookups?: Maybe<Array<Maybe<ClientMediaOutlet>>>;
  clientMonthDefinitionOfflineMonthDefinitionLookups?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientMonthDefinitionOnlineMonthDefinitionLookups?: Maybe<Array<Maybe<ClientMonthDefinition>>>;
  clientPaymentEnforcementLookups?: Maybe<Array<Maybe<Client>>>;
  commissionSlidingScaleCalendarTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleCommissionBasedOnRateTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleCommissionContingencyActionTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleContingencyDateUnitTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleResetConditionLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissionSlidingScaleThresholdBasedOnRateTypeLookups?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contactTypeContactTypeGroupLookups?: Maybe<Array<Maybe<ContactType>>>;
  contactTypeVendorTypeLookups?: Maybe<Array<Maybe<ContactType>>>;
  contractTermDayTypeLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsBillingTypeLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsCadenceLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTermTermsServiceLookups?: Maybe<Array<Maybe<ContractTerm>>>;
  crossChannelCampaignDimensions?: Maybe<Array<Maybe<CrossChannelCampaignDimension>>>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  crossChannelCampaignVersions?: Maybe<Array<Maybe<CrossChannelCampaignVersion>>>;
  curveCurveSchemaLookups?: Maybe<Array<Maybe<Curve>>>;
  curveTapeLengthLookups?: Maybe<Array<Maybe<Curve>>>;
  dayparts?: Maybe<Array<Maybe<Daypart>>>;
  digitalDeliveryServiceAttributes?: Maybe<Array<Maybe<DigitalDeliveryServiceAttribute>>>;
  digitalSpendImportFieldMappings?: Maybe<Array<Maybe<DigitalSpendImportFieldMapping>>>;
  digitalSpendImportValueMappings?: Maybe<Array<Maybe<DigitalSpendImportValueMapping>>>;
  digitalSpendInputLineDigitalBuyTypeLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  digitalSpendInputLineStrategyLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  digitalSpendInputLineTacticLookups?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  display?: Maybe<Scalars['String']>;
  duplicationPresetCodecLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetCodecProfileLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetCodecWrapperLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetFrameRateLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetInterlaceModeLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetVideoHeightLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  duplicationPresetVideoWidthLookups?: Maybe<Array<Maybe<DuplicationPreset>>>;
  fileImportMaps?: Maybe<Array<Maybe<FileImportMap>>>;
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
  fileReferences?: Maybe<Array<Maybe<FileReference>>>;
  files?: Maybe<Array<Maybe<File>>>;
  gareportSkudefinitions?: Maybe<Array<Maybe<GareportSkudefinition>>>;
  gareportTimeGranularityLookups?: Maybe<Array<Maybe<Gareport>>>;
  gareportTrackingCodeTypeLookups?: Maybe<Array<Maybe<Gareport>>>;
  glanceReportDimensions?: Maybe<Array<Maybe<GlanceReportDimension>>>;
  glanceReportElementBoxGlanceReportBoxElementDirectionLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementBoxGlanceReportBoxElementTotalsBasedOnLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementBoxGroupSortDirectionLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementElementTypeLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementHorizontalAlignmentLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportElementVerticalAlignmentLookups?: Maybe<Array<Maybe<GlanceReportElement>>>;
  glanceReportRanges?: Maybe<Array<Maybe<GlanceReportRange>>>;
  inputFieldMappingSchemes?: Maybe<Array<Maybe<InputFieldMappingScheme>>>;
  inputFieldMappings?: Maybe<Array<Maybe<InputFieldMapping>>>;
  insertionOrderDetails?: Maybe<Array<Maybe<InsertionOrderDetail>>>;
  insertionOrderFileReferences?: Maybe<Array<Maybe<InsertionOrderFileReference>>>;
  insertionOrderSpendDisplayUnitLookups?: Maybe<Array<Maybe<InsertionOrder>>>;
  insertionOrderStatusLookups?: Maybe<Array<Maybe<InsertionOrder>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  issueDateInfos?: Maybe<Array<Maybe<IssueDateInfo>>>;
  jobInstanceLogs?: Maybe<Array<Maybe<JobInstanceLog>>>;
  localMarketCostPerNielsenDemoLookups?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketCostPerSqadlevelLookups?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateAdLengths?: Maybe<Array<Maybe<LocalMarketRatingEstimateAdLength>>>;
  localMarketRatingEstimateDetails?: Maybe<Array<Maybe<LocalMarketRatingEstimateDetail>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  localMarketRatingEstimates?: Maybe<Array<Maybe<LocalMarketRatingEstimate>>>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  lookupId: Scalars['Int'];
  lookupType?: Maybe<LookupType>;
  lookupTypeId: Scalars['Int'];
  mediaAuthorizationDimensions?: Maybe<Array<Maybe<MediaAuthorizationDimension>>>;
  mediaAuthorizations?: Maybe<Array<Maybe<MediaAuthorization>>>;
  mediaAvailRates?: Maybe<Array<Maybe<MediaAvailRate>>>;
  mediaFormatAttributes?: Maybe<Array<Maybe<MediaFormatAttribute>>>;
  mediaOutletAddresses?: Maybe<Array<Maybe<MediaOutletAddress>>>;
  mediaOutletClientCreditLimits?: Maybe<Array<Maybe<MediaOutletClientCreditLimit>>>;
  mediaOutletDigitalDeliveryTypeToAddressTypeAddressTypeLookups?: Maybe<Array<Maybe<MediaOutletDigitalDeliveryTypeToAddressType>>>;
  mediaOutletDigitalDeliveryTypeToAddressTypeDigitalDeliveryServiceLookups?: Maybe<Array<Maybe<MediaOutletDigitalDeliveryTypeToAddressType>>>;
  mediaOutletFileReferences?: Maybe<Array<Maybe<MediaOutletFileReference>>>;
  mediaOutletFrameRateSchemeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletLanguageLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletLfviewTapeRequirementLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaClassificationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaOutletAffiliateLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaOutletGenreLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletMediaTypeAccountingNetworkLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeAxtypeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeDigitalDeliveryServices?: Maybe<Array<Maybe<MediaOutletMediaTypeDigitalDeliveryService>>>;
  mediaOutletMediaTypeInvoiceTypeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatHighDefLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeMediaFormatViewTapeLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeTermLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeTvhddubRequirementLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletMediaTypeVendorCodes?: Maybe<Array<Maybe<MediaOutletMediaTypeVendorCode>>>;
  mediaOutletMediaTypeViewTapeRequirementLookups?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletPartnerBuyTypeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPiinvoiceCadenceLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPrintFormats?: Maybe<Array<Maybe<MediaOutletPrintFormat>>>;
  mediaOutletPrtfrequencyLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletPrtpublicationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletRates?: Maybe<Array<Maybe<MediaOutletRate>>>;
  mediaOutletRdradioFormatLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletRoles?: Maybe<Array<Maybe<MediaOutletRole>>>;
  mediaOutletRotations?: Maybe<Array<Maybe<MediaOutletRotation>>>;
  mediaOutletSfprelogStatusLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTalentFeeClassificationLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvclosedCaptionStatusLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvhddubRequirementLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaOutletTvsupportedMediaTypeLookups?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaPlanExportExcelOptions?: Maybe<Array<Maybe<MediaPlanExportExcelOption>>>;
  mediaPlanNielsenDemos?: Maybe<Array<Maybe<MediaPlanNielsenDemo>>>;
  mediaPlanTapeLengths?: Maybe<Array<Maybe<MediaPlanTapeLength>>>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  mediaScheduleDetailAdvancedTargetingDemoLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailAttributes?: Maybe<Array<Maybe<MediaScheduleDetailAttribute>>>;
  mediaScheduleDetailBuyTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPlacementPositionLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtadSizeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtadTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtcolorLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtissueDateLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtlayoutLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtlocationLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailPrtsectionLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailRateTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailReadTypeLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailStatusLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailTapeLengthLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailTrackingServicePaidByLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDetailWeekPartLookups?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaScheduleDisplayEstimatedRatingModeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaScheduleDisplayKpidateRangeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaScheduleDisplayRateTypeLookups?: Maybe<Array<Maybe<MediaScheduleDisplay>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  mediaTransmissionHdmediaFormatLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaFormatLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaTransmissionStatusLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionMediaTransmissionTypeLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionPrintAdSizeLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionPriorityLookups?: Maybe<Array<Maybe<MediaTransmission>>>;
  mriindexMridataStabilityLookups?: Maybe<Array<Maybe<Mriindex>>>;
  mriindexMridemoLookups?: Maybe<Array<Maybe<Mriindex>>>;
  mriindexMriindexTypeLookups?: Maybe<Array<Maybe<Mriindex>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementFileReferences?: Maybe<Array<Maybe<NewCampaignAnnouncementFileReference>>>;
  newCampaignAnnouncementTargetMetricLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementTfntrackingCodeAssignmentTypeLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementUrltrackingCodeAssignmentTypeLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementVeilEncodingLookups?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nielsenDemoPopulations?: Maybe<Array<Maybe<NielsenDemoPopulation>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  onlineScheduleAttributeOnlineContentCategoryLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlineCreativeLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlineCreativeSizeLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlinePlacementIdlookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  onlineScheduleAttributeOnlinePlacementLookups?: Maybe<Array<Maybe<OnlineScheduleAttribute>>>;
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  printAvailAdSizeLookups?: Maybe<Array<Maybe<PrintAvail>>>;
  printAvailIssueDateLookups?: Maybe<Array<Maybe<PrintAvail>>>;
  printScheduleAttributePrintAdSizeLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintAdTypeLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintLocationLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  printScheduleAttributePrintSectionLookups?: Maybe<Array<Maybe<PrintScheduleAttribute>>>;
  productCategoryHierarchyProductCategoryLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productCategoryHierarchyProductSubCategoryLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productCategoryHierarchyProductTypeLookups?: Maybe<Array<Maybe<ProductCategoryHierarchy>>>;
  productMediaTargets?: Maybe<Array<Maybe<ProductMediaTarget>>>;
  productProductCategoryLookups?: Maybe<Array<Maybe<Product>>>;
  productProductSubCategoryLookups?: Maybe<Array<Maybe<Product>>>;
  productTrackingCodeAvailSetupTypeLookups?: Maybe<Array<Maybe<Product>>>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  ratingC3ratingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingNielsenDemoLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingPlus3RatingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  ratingRatingTypeLookups?: Maybe<Array<Maybe<Rating>>>;
  regionCurrencyCodeLookups?: Maybe<Array<Maybe<Region>>>;
  regionRegionTypeLookups?: Maybe<Array<Maybe<Region>>>;
  rentrakNetworks?: Maybe<Array<Maybe<RentrakNetwork>>>;
  reportDefaultPresetVisibilityLookups?: Maybe<Array<Maybe<Report>>>;
  reportExportFormatFieldAggregateFormatLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldDataTypeLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFontLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFormatLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldFormulaTypeLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldHorizontalAlignmentLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFieldReportFieldValueModifierLookups?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatFontLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatMapBreakpointTypeLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatPageOrientationLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatReportExportFormatTypeLookups?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportExportFormatStyleEvaluationOperatorTypeLookups?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportExportFormatStyleReportStyleFormatTypeLookups?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportFormats?: Maybe<Array<Maybe<ReportFormat>>>;
  reportPresetFilterRecords?: Maybe<Array<Maybe<ReportPresetFilterRecord>>>;
  reportPresetGroupSorts?: Maybe<Array<Maybe<ReportPresetGroupSort>>>;
  reportTargetLookups?: Maybe<Array<Maybe<Report>>>;
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  scheduleAdvancedTargetingLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleAttributes?: Maybe<Array<Maybe<ScheduleAttribute>>>;
  scheduleBillingMetricLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleBuyTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleDigitalPlacementDescriptionLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleFileReferences?: Maybe<Array<Maybe<ScheduleFileReference>>>;
  scheduleInternalStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleLftribuneNameLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePibasedOnLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePlacementPositionLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtadSizeLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtadTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtcolorLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtissueDateLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtlayoutLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtlocationLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtplacedSectionLookups?: Maybe<Array<Maybe<Schedule>>>;
  schedulePrtsectionLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleRateTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleReadTypeLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSfschedulePrelogStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleSpendAllocationSchemaLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleStatusLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleStatusStateScheduleStateLookups?: Maybe<Array<Maybe<ScheduleStatusState>>>;
  scheduleStatusStateStatusLookups?: Maybe<Array<Maybe<ScheduleStatusState>>>;
  scheduleStrategyLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleTacticLookups?: Maybe<Array<Maybe<Schedule>>>;
  scheduleTrackingServicePaidByLookups?: Maybe<Array<Maybe<Schedule>>>;
  scriptAutosourcerAlgorithmLookups?: Maybe<Array<Maybe<Script>>>;
  scriptLiftTypeLookups?: Maybe<Array<Maybe<Script>>>;
  scriptOrderCallCreationLookups?: Maybe<Array<Maybe<Script>>>;
  scriptSignalLinkCurveSchemaLookups?: Maybe<Array<Maybe<Script>>>;
  scriptSpikeNielsenDemoLookups?: Maybe<Array<Maybe<Script>>>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  sourcingAlgorithmAudienceWeightLookups?: Maybe<Array<Maybe<SourcingAlgorithm>>>;
  sourcingAlgorithmSourcingAlgorithmTypeLookups?: Maybe<Array<Maybe<SourcingAlgorithm>>>;
  standardsAndPracticesApprovals?: Maybe<Array<Maybe<StandardsAndPracticesApproval>>>;
  subCategories: Array<Lookup>;
  surveyDateRangeLookups?: Maybe<Array<Maybe<Survey>>>;
  surveyNielsenDemoLookups?: Maybe<Array<Maybe<Survey>>>;
  surveyQuestions?: Maybe<Array<Maybe<SurveyQuestion>>>;
  surveySurveyTypeLookups?: Maybe<Array<Maybe<Survey>>>;
  tacticInfos?: Maybe<Array<Maybe<TacticInfo>>>;
  targetMridemoLookups?: Maybe<Array<Maybe<Target>>>;
  targetNielsenDemoLookups?: Maybe<Array<Maybe<Target>>>;
  targetTargetTypeLookups?: Maybe<Array<Maybe<Target>>>;
  taskHistories?: Maybe<Array<Maybe<TaskHistory>>>;
  taskPriorityLookups?: Maybe<Array<Maybe<Task>>>;
  taskRoleLookups?: Maybe<Array<Maybe<Task>>>;
  taskTaskSubTypeLookups?: Maybe<Array<Maybe<Task>>>;
  taskTypeStatusAvailTaskStateLookups?: Maybe<Array<Maybe<TaskTypeStatusAvail>>>;
  taskTypeStatusAvailTaskStatusLookups?: Maybe<Array<Maybe<TaskTypeStatusAvail>>>;
  tmpAccountingLineBuyTypeNavigations?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  tmpAccountingLineMonthDefinitionLookups?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  topLineMediaPlanDetailAttributes?: Maybe<Array<Maybe<TopLineMediaPlanDetailAttribute>>>;
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  topLineMediaPlanNielsenDemoLookupId1Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  topLineMediaPlanNielsenDemoLookupId2Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  topLineMediaPlanNielsenDemoLookupId3Navigations?: Maybe<Array<Maybe<TopLineMediaPlan>>>;
  trackingCodeAvailSetupTrackingCodeAvailSetupTypeLookups?: Maybe<Array<Maybe<TrackingCodeAvailSetup>>>;
  trackingCodeAvailSetupTrackingCodeTypeLookups?: Maybe<Array<Maybe<TrackingCodeAvailSetup>>>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodes?: Maybe<Array<Maybe<TrackingCode>>>;
  trafficSheetDetailCountryLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailDevices?: Maybe<Array<Maybe<TrafficSheetDetailDevice>>>;
  trafficSheetDetailDigitalRegionLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailOperatingSystemLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailProgrammaticExecutionLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailSizeLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailStrategyLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetDetailTacticLookups?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  uniqueAds?: Maybe<Array<Maybe<UniqueAd>>>;
  vendorAddressTypeLookups?: Maybe<Array<Maybe<Vendor>>>;
  vendorBrokerCurrencyCodeLookups?: Maybe<Array<Maybe<Vendor>>>;
  vendorLookups?: Maybe<Array<Maybe<VendorLookup>>>;
  vendorServices?: Maybe<Array<Maybe<VendorService>>>;
  vendorVendorTypeLookups?: Maybe<Array<Maybe<Vendor>>>;
  viewSources?: Maybe<Array<Maybe<ViewSource>>>;
  waveCastDetailActionTypeLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastDetailOverrideBubbleChartDirectiveLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastDetailWaveCastDetailTypeLookups?: Maybe<Array<Maybe<WaveCastDetail>>>;
};

export type ProductDateBlackList = {
  __typename?: 'ProductDateBlackList';
  blackoutDate: Scalars['Date'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productDateBlackListId: Scalars['Int'];
  productId: Scalars['Int'];
};

export type ProductDateBlackListFilterInput = {
  and?: InputMaybe<Array<ProductDateBlackListFilterInput>>;
  blackoutDate?: InputMaybe<DateOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductDateBlackListFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productDateBlackListId?: InputMaybe<IntOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductDimension = {
  __typename?: 'ProductDimension';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  orderBy: Scalars['Int'];
  productDimensionColumns?: Maybe<Array<Maybe<ProductDimensionColumn>>>;
  productDimensionId: Scalars['Int'];
  productDimensionType?: Maybe<ProductDimensionType>;
  productDimensionTypeId: Scalars['Int'];
  productDimensionTypes?: Maybe<Array<Maybe<ProductDimensionType>>>;
};

export type ProductDimensionColumn = {
  __typename?: 'ProductDimensionColumn';
  columnName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  productDimension?: Maybe<ProductDimension>;
  productDimensionColumnId: Scalars['Int'];
  productDimensionId?: Maybe<Scalars['Int']>;
  sku?: Maybe<Sku>;
  skuid?: Maybe<Scalars['Int']>;
};

export type ProductDimensionColumnFilterInput = {
  and?: InputMaybe<Array<ProductDimensionColumnFilterInput>>;
  columnName?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductDimensionColumnFilterInput>>;
  productDimension?: InputMaybe<ProductDimensionFilterInput>;
  productDimensionColumnId?: InputMaybe<IntOperationFilterInput>;
  productDimensionId?: InputMaybe<IntOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
};

export type ProductDimensionFilterInput = {
  and?: InputMaybe<Array<ProductDimensionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductDimensionFilterInput>>;
  orderBy?: InputMaybe<IntOperationFilterInput>;
  productDimensionColumns?: InputMaybe<ListFilterInputTypeOfProductDimensionColumnFilterInput>;
  productDimensionId?: InputMaybe<IntOperationFilterInput>;
  productDimensionType?: InputMaybe<ProductDimensionTypeFilterInput>;
  productDimensionTypeId?: InputMaybe<IntOperationFilterInput>;
  productDimensionTypes?: InputMaybe<ListFilterInputTypeOfProductDimensionTypeFilterInput>;
};

export type ProductDimensionType = {
  __typename?: 'ProductDimensionType';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  defaultProductDimension?: Maybe<ProductDimension>;
  defaultProductDimensionId?: Maybe<Scalars['Int']>;
  dimensionTypeName?: Maybe<Scalars['String']>;
  hasSum: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  orderBy: Scalars['Int'];
  product?: Maybe<Product>;
  productDimensionTypeId: Scalars['Int'];
  productDimensions?: Maybe<Array<Maybe<ProductDimension>>>;
  productId: Scalars['Int'];
  totalIsDefaultDimension: Scalars['Boolean'];
};

export type ProductDimensionTypeFilterInput = {
  and?: InputMaybe<Array<ProductDimensionTypeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  defaultProductDimension?: InputMaybe<ProductDimensionFilterInput>;
  defaultProductDimensionId?: InputMaybe<IntOperationFilterInput>;
  dimensionTypeName?: InputMaybe<StringOperationFilterInput>;
  hasSum?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductDimensionTypeFilterInput>>;
  orderBy?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productDimensionTypeId?: InputMaybe<IntOperationFilterInput>;
  productDimensions?: InputMaybe<ListFilterInputTypeOfProductDimensionFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  totalIsDefaultDimension?: InputMaybe<BooleanOperationFilterInput>;
};

export type ProductFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<ProductFilterInput>>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createVerbals?: InputMaybe<BooleanOperationFilterInput>;
  creatives?: InputMaybe<ListFilterInputTypeOfCreativeFilterInput>;
  crossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFilterInput>;
  datoramaImportJobProducts?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobProductFilterInput>;
  duplicationCostEstimates?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateFilterInput>;
  gareports?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  inverseNonR2cproduct?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  inverseParentProduct?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  isCrossCompany?: InputMaybe<BooleanOperationFilterInput>;
  isIngestible?: InputMaybe<BooleanOperationFilterInput>;
  isLfminHoursSeparationIgnorable?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingLocks?: InputMaybe<ListFilterInputTypeOfLeadBillingLockFilterInput>;
  leadBillingReportPreset?: InputMaybe<ReportPresetFilterInput>;
  leadBillingReportPresetId?: InputMaybe<IntOperationFilterInput>;
  leadDedupingDays?: InputMaybe<IntOperationFilterInput>;
  lfminHoursSeparation?: InputMaybe<DecimalOperationFilterInput>;
  localMarketRatingEstimates?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  marketContributionAnalyses?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  mediaPlans?: InputMaybe<ListFilterInputTypeOfMediaPlanFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementPis?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nonR2cproduct?: InputMaybe<ProductFilterInput>;
  nonR2cproductId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  overrideClientName?: InputMaybe<StringOperationFilterInput>;
  packages?: InputMaybe<ListFilterInputTypeOfPackageFilterInput>;
  parentProduct?: InputMaybe<ProductFilterInput>;
  parentProductId?: InputMaybe<IntOperationFilterInput>;
  processorConnectionStringName?: InputMaybe<StringOperationFilterInput>;
  processorDbname?: InputMaybe<StringOperationFilterInput>;
  processorViewName?: InputMaybe<StringOperationFilterInput>;
  productAudiences?: InputMaybe<ListFilterInputTypeOfProductAudienceFilterInput>;
  productCategoryLookup?: InputMaybe<LookupFilterInput>;
  productCategoryLookupId?: InputMaybe<IntOperationFilterInput>;
  productDateBlackLists?: InputMaybe<ListFilterInputTypeOfProductDateBlackListFilterInput>;
  productDimensionTypes?: InputMaybe<ListFilterInputTypeOfProductDimensionTypeFilterInput>;
  productFulfillments?: InputMaybe<ListFilterInputTypeOfProductFulfillmentFilterInput>;
  productHours?: InputMaybe<ListFilterInputTypeOfProductHourFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productMediaOutletBlackLists?: InputMaybe<ListFilterInputTypeOfProductMediaOutletBlackListFilterInput>;
  productMediaOutletPirates?: InputMaybe<ListFilterInputTypeOfProductMediaOutletPirateFilterInput>;
  productMediaTargets?: InputMaybe<ListFilterInputTypeOfProductMediaTargetFilterInput>;
  productMediaTypeLockDates?: InputMaybe<ListFilterInputTypeOfProductMediaTypeLockDateFilterInput>;
  productOfferGroups?: InputMaybe<ListFilterInputTypeOfProductOfferGroupFilterInput>;
  productProgramBlackLists?: InputMaybe<ListFilterInputTypeOfProductProgramBlackListFilterInput>;
  productRegionBlackLists?: InputMaybe<ListFilterInputTypeOfProductRegionBlackListFilterInput>;
  productSubCategoryLookup?: InputMaybe<LookupFilterInput>;
  productSubCategoryLookupId?: InputMaybe<IntOperationFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  revisionRequests?: InputMaybe<ListFilterInputTypeOfRevisionRequestFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  sfprogrammingNotes?: InputMaybe<StringOperationFilterInput>;
  skus?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  surveys?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  title?: InputMaybe<TitleFilterInput>;
  titleId?: InputMaybe<IntOperationFilterInput>;
  titles?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  trackingCodeAvailSetupTypeLookup?: InputMaybe<LookupFilterInput>;
  trackingCodeAvailSetupTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  waveCastMappings?: InputMaybe<ListFilterInputTypeOfWaveCastMappingFilterInput>;
  waveCastModels?: InputMaybe<ListFilterInputTypeOfWaveCastModelFilterInput>;
};

export type ProductFulfillment = {
  __typename?: 'ProductFulfillment';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fulfillmentVendor?: Maybe<Vendor>;
  fulfillmentVendorId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productFulfillmentId: Scalars['Int'];
  productId: Scalars['Int'];
};

export type ProductFulfillmentFilterInput = {
  and?: InputMaybe<Array<ProductFulfillmentFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fulfillmentVendor?: InputMaybe<VendorFilterInput>;
  fulfillmentVendorId?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductFulfillmentFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productFulfillmentId?: InputMaybe<IntOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductHour = {
  __typename?: 'ProductHour';
  closed: Scalars['Boolean'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['TimeSpan']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productHoursId: Scalars['Int'];
  productId: Scalars['Int'];
  startTime?: Maybe<Scalars['TimeSpan']>;
  weekday: Scalars['Int'];
};

export type ProductHourFilterInput = {
  and?: InputMaybe<Array<ProductHourFilterInput>>;
  closed?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductHourFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productHoursId?: InputMaybe<IntOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  weekday?: InputMaybe<IntOperationFilterInput>;
};

export type ProductInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createVerbals?: InputMaybe<Scalars['Boolean']>;
  isIngestible?: InputMaybe<Scalars['Boolean']>;
  isLFMinHoursSeparationIgnorable?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  productCategoryLookupId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  productSubCategoryLookupID?: InputMaybe<Scalars['Int']>;
  trackingCodeAvailSetupTypeLookupID?: InputMaybe<Scalars['Int']>;
};

export type ProductMediaOutletBlackList = {
  __typename?: 'ProductMediaOutletBlackList';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productMediaOutletBlackListId: Scalars['Int'];
};

export type ProductMediaOutletBlackListFilterInput = {
  and?: InputMaybe<Array<ProductMediaOutletBlackListFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductMediaOutletBlackListFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productMediaOutletBlackListId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductMediaOutletPirate = {
  __typename?: 'ProductMediaOutletPirate';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  amountToChargeClient: Scalars['Decimal'];
  amountToPayStation: Scalars['Decimal'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  oldid?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productMediaOutletPirateId: Scalars['Int'];
};

export type ProductMediaOutletPirateFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  amountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  amountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ProductMediaOutletPirateFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductMediaOutletPirateFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productMediaOutletPirateId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductMediaTarget = {
  __typename?: 'ProductMediaTarget';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaTargetTypeLookup?: Maybe<Lookup>;
  mediaTargetTypeLookupId: Scalars['Int'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productMediaTargetId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type ProductMediaTargetFilterInput = {
  and?: InputMaybe<Array<ProductMediaTargetFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaTargetTypeLookup?: InputMaybe<LookupFilterInput>;
  mediaTargetTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductMediaTargetFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productMediaTargetId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type ProductMediaTypeLockDate = {
  __typename?: 'ProductMediaTypeLockDate';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lockDate: Scalars['Date'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productMediaTypeLockDateId: Scalars['Int'];
};

export type ProductMediaTypeLockDateFilterInput = {
  and?: InputMaybe<Array<ProductMediaTypeLockDateFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lockDate?: InputMaybe<DateOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductMediaTypeLockDateFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productMediaTypeLockDateId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductOfferGroup = {
  __typename?: 'ProductOfferGroup';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  isDefault: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productOfferGroupId: Scalars['Int'];
  skugroups?: Maybe<Array<Maybe<Skugroup>>>;
};

export type ProductOfferGroupFilterInput = {
  and?: InputMaybe<Array<ProductOfferGroupFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductOfferGroupFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productOfferGroupId?: InputMaybe<IntOperationFilterInput>;
  skugroups?: InputMaybe<ListFilterInputTypeOfSkugroupFilterInput>;
};

export type ProductProgramBlackList = {
  __typename?: 'ProductProgramBlackList';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productProgramBlackListId: Scalars['Int'];
  roviProgramId: Scalars['Int'];
};

export type ProductProgramBlackListFilterInput = {
  and?: InputMaybe<Array<ProductProgramBlackListFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductProgramBlackListFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productProgramBlackListId?: InputMaybe<IntOperationFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductRegionBlackList = {
  __typename?: 'ProductRegionBlackList';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productRegionBlackListId: Scalars['Int'];
  region?: Maybe<Region>;
  regionId: Scalars['Int'];
};

export type ProductRegionBlackListFilterInput = {
  and?: InputMaybe<Array<ProductRegionBlackListFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ProductRegionBlackListFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productRegionBlackListId?: InputMaybe<IntOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
};

export type ProductSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  axprojectCode?: InputMaybe<SortEnumType>;
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createVerbals?: InputMaybe<SortEnumType>;
  isCrossCompany?: InputMaybe<SortEnumType>;
  isIngestible?: InputMaybe<SortEnumType>;
  isLfminHoursSeparationIgnorable?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  leadBillingReportPreset?: InputMaybe<ReportPresetSortInput>;
  leadBillingReportPresetId?: InputMaybe<SortEnumType>;
  leadDedupingDays?: InputMaybe<SortEnumType>;
  lfminHoursSeparation?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nonR2cproduct?: InputMaybe<ProductSortInput>;
  nonR2cproductId?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  overrideClientName?: InputMaybe<SortEnumType>;
  parentProduct?: InputMaybe<ProductSortInput>;
  parentProductId?: InputMaybe<SortEnumType>;
  processorConnectionStringName?: InputMaybe<SortEnumType>;
  processorDbname?: InputMaybe<SortEnumType>;
  processorViewName?: InputMaybe<SortEnumType>;
  productCategoryLookup?: InputMaybe<LookupSortInput>;
  productCategoryLookupId?: InputMaybe<SortEnumType>;
  productId?: InputMaybe<SortEnumType>;
  productSubCategoryLookup?: InputMaybe<LookupSortInput>;
  productSubCategoryLookupId?: InputMaybe<SortEnumType>;
  sfprogrammingNotes?: InputMaybe<SortEnumType>;
  title?: InputMaybe<TitleSortInput>;
  titleId?: InputMaybe<SortEnumType>;
  trackingCodeAvailSetupTypeLookup?: InputMaybe<LookupSortInput>;
  trackingCodeAvailSetupTypeLookupId?: InputMaybe<SortEnumType>;
};

export type ProductTsbcontactList = {
  __typename?: 'ProductTsbcontactList';
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  isPrimaryContact: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  productTsbcontactListId: Scalars['Int'];
  productTsbcontactListTypeLookup?: Maybe<Lookup>;
  productTsbcontactListTypeLookupId: Scalars['Int'];
  tsbvendor?: Maybe<Vendor>;
  tsbvendorId: Scalars['Int'];
};

export type ProductTsbcontactListFilterInput = {
  and?: InputMaybe<Array<ProductTsbcontactListFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  isPrimaryContact?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductTsbcontactListFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productTsbcontactListId?: InputMaybe<IntOperationFilterInput>;
  productTsbcontactListTypeLookup?: InputMaybe<LookupFilterInput>;
  productTsbcontactListTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  tsbvendor?: InputMaybe<VendorFilterInput>;
  tsbvendorId?: InputMaybe<IntOperationFilterInput>;
};

export type Product_ForMaintenance = {
  __typename?: 'Product_ForMaintenance';
  abbreviation?: Maybe<Scalars['String']>;
  adCount: Scalars['Int'];
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  appUserClients?: Maybe<Array<Maybe<AppUserClient>>>;
  axprojectCode?: Maybe<Scalars['String']>;
  campaignCount: Scalars['Int'];
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  commissions?: Maybe<Array<Maybe<Commission>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createVerbals: Scalars['Boolean'];
  creatives?: Maybe<Array<Maybe<Creative>>>;
  crossChannelCampaignCount: Scalars['Int'];
  crossChannelCampaigns?: Maybe<Array<Maybe<CrossChannelCampaign>>>;
  datoramaImportJobProducts?: Maybe<Array<Maybe<DatoramaImportJobProduct>>>;
  duplicationCostEstimates?: Maybe<Array<Maybe<DuplicationCostEstimate>>>;
  gareports?: Maybe<Array<Maybe<Gareport>>>;
  inverseNonR2cproduct?: Maybe<Array<Maybe<Product>>>;
  inverseParentProduct?: Maybe<Array<Maybe<Product>>>;
  isCrossCompany?: Maybe<Scalars['Boolean']>;
  isIngestible: Scalars['Boolean'];
  isLfminHoursSeparationIgnorable: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadBillingLocks?: Maybe<Array<Maybe<LeadBillingLock>>>;
  leadBillingReportPreset?: Maybe<ReportPreset>;
  leadBillingReportPresetId?: Maybe<Scalars['Int']>;
  leadDedupingDays?: Maybe<Scalars['Int']>;
  lfminHoursSeparation?: Maybe<Scalars['Decimal']>;
  localMarketRatingEstimates?: Maybe<Array<Maybe<LocalMarketRatingEstimate>>>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  marketContributionAnalyses?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  mediaPlans?: Maybe<Array<Maybe<MediaPlan>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementPis?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  nonR2cproduct?: Maybe<Product>;
  nonR2cproductId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  overrideClientName?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Maybe<Package>>>;
  parentProduct?: Maybe<Product>;
  parentProductId?: Maybe<Scalars['Int']>;
  processorConnectionStringName?: Maybe<Scalars['String']>;
  processorDbname?: Maybe<Scalars['String']>;
  processorViewName?: Maybe<Scalars['String']>;
  productAudiences?: Maybe<Array<Maybe<ProductAudience>>>;
  productCategoryLookup?: Maybe<Lookup>;
  productCategoryLookupDisplay?: Maybe<Scalars['String']>;
  productCategoryLookupId: Scalars['Int'];
  productDateBlackLists?: Maybe<Array<Maybe<ProductDateBlackList>>>;
  productDimensionTypes?: Maybe<Array<Maybe<ProductDimensionType>>>;
  productFulfillments?: Maybe<Array<Maybe<ProductFulfillment>>>;
  productHours?: Maybe<Array<Maybe<ProductHour>>>;
  productId: Scalars['Int'];
  productMediaOutletBlackLists?: Maybe<Array<Maybe<ProductMediaOutletBlackList>>>;
  productMediaOutletPirates?: Maybe<Array<Maybe<ProductMediaOutletPirate>>>;
  productMediaTargets?: Maybe<Array<Maybe<ProductMediaTarget>>>;
  productMediaTypeLockDates?: Maybe<Array<Maybe<ProductMediaTypeLockDate>>>;
  productOfferGroups?: Maybe<Array<Maybe<ProductOfferGroup>>>;
  productProgramBlackLists?: Maybe<Array<Maybe<ProductProgramBlackList>>>;
  productRegionBlackLists?: Maybe<Array<Maybe<ProductRegionBlackList>>>;
  productSubCategoryLookup?: Maybe<Lookup>;
  productSubCategoryLookupDisplay?: Maybe<Scalars['String']>;
  productSubCategoryLookupId?: Maybe<Scalars['Int']>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  revisionRequests?: Maybe<Array<Maybe<RevisionRequest>>>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  sfprogrammingNotes?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  surveys?: Maybe<Array<Maybe<Survey>>>;
  title?: Maybe<Title>;
  titleId?: Maybe<Scalars['Int']>;
  titles?: Maybe<Array<Maybe<Title>>>;
  trackingCodeAvailSetupTypeLookup?: Maybe<Lookup>;
  trackingCodeAvailSetupTypeLookupId: Scalars['Int'];
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  waveCastMappings?: Maybe<Array<Maybe<WaveCastMapping>>>;
  waveCastModels?: Maybe<Array<Maybe<WaveCastModel>>>;
};

export type Product_ForMaintenanceFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  adCount?: InputMaybe<IntOperationFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<Product_ForMaintenanceFilterInput>>;
  appUserClients?: InputMaybe<ListFilterInputTypeOfAppUserClientFilterInput>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  campaignCount?: InputMaybe<IntOperationFilterInput>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  commissions?: InputMaybe<ListFilterInputTypeOfCommissionFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createVerbals?: InputMaybe<BooleanOperationFilterInput>;
  creatives?: InputMaybe<ListFilterInputTypeOfCreativeFilterInput>;
  crossChannelCampaignCount?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaigns?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFilterInput>;
  datoramaImportJobProducts?: InputMaybe<ListFilterInputTypeOfDatoramaImportJobProductFilterInput>;
  duplicationCostEstimates?: InputMaybe<ListFilterInputTypeOfDuplicationCostEstimateFilterInput>;
  gareports?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  inverseNonR2cproduct?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  inverseParentProduct?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  isCrossCompany?: InputMaybe<BooleanOperationFilterInput>;
  isIngestible?: InputMaybe<BooleanOperationFilterInput>;
  isLfminHoursSeparationIgnorable?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadBillingLocks?: InputMaybe<ListFilterInputTypeOfLeadBillingLockFilterInput>;
  leadBillingReportPreset?: InputMaybe<ReportPresetFilterInput>;
  leadBillingReportPresetId?: InputMaybe<IntOperationFilterInput>;
  leadDedupingDays?: InputMaybe<IntOperationFilterInput>;
  lfminHoursSeparation?: InputMaybe<DecimalOperationFilterInput>;
  localMarketRatingEstimates?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  marketContributionAnalyses?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  mediaPlans?: InputMaybe<ListFilterInputTypeOfMediaPlanFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementPis?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  nonR2cproduct?: InputMaybe<ProductFilterInput>;
  nonR2cproductId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Product_ForMaintenanceFilterInput>>;
  overrideClientName?: InputMaybe<StringOperationFilterInput>;
  packages?: InputMaybe<ListFilterInputTypeOfPackageFilterInput>;
  parentProduct?: InputMaybe<ProductFilterInput>;
  parentProductId?: InputMaybe<IntOperationFilterInput>;
  processorConnectionStringName?: InputMaybe<StringOperationFilterInput>;
  processorDbname?: InputMaybe<StringOperationFilterInput>;
  processorViewName?: InputMaybe<StringOperationFilterInput>;
  productAudiences?: InputMaybe<ListFilterInputTypeOfProductAudienceFilterInput>;
  productCategoryLookup?: InputMaybe<LookupFilterInput>;
  productCategoryLookupDisplay?: InputMaybe<StringOperationFilterInput>;
  productCategoryLookupId?: InputMaybe<IntOperationFilterInput>;
  productDateBlackLists?: InputMaybe<ListFilterInputTypeOfProductDateBlackListFilterInput>;
  productDimensionTypes?: InputMaybe<ListFilterInputTypeOfProductDimensionTypeFilterInput>;
  productFulfillments?: InputMaybe<ListFilterInputTypeOfProductFulfillmentFilterInput>;
  productHours?: InputMaybe<ListFilterInputTypeOfProductHourFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productMediaOutletBlackLists?: InputMaybe<ListFilterInputTypeOfProductMediaOutletBlackListFilterInput>;
  productMediaOutletPirates?: InputMaybe<ListFilterInputTypeOfProductMediaOutletPirateFilterInput>;
  productMediaTargets?: InputMaybe<ListFilterInputTypeOfProductMediaTargetFilterInput>;
  productMediaTypeLockDates?: InputMaybe<ListFilterInputTypeOfProductMediaTypeLockDateFilterInput>;
  productOfferGroups?: InputMaybe<ListFilterInputTypeOfProductOfferGroupFilterInput>;
  productProgramBlackLists?: InputMaybe<ListFilterInputTypeOfProductProgramBlackListFilterInput>;
  productRegionBlackLists?: InputMaybe<ListFilterInputTypeOfProductRegionBlackListFilterInput>;
  productSubCategoryLookup?: InputMaybe<LookupFilterInput>;
  productSubCategoryLookupDisplay?: InputMaybe<StringOperationFilterInput>;
  productSubCategoryLookupId?: InputMaybe<IntOperationFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  revisionRequests?: InputMaybe<ListFilterInputTypeOfRevisionRequestFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  sfprogrammingNotes?: InputMaybe<StringOperationFilterInput>;
  skus?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  surveys?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  title?: InputMaybe<TitleFilterInput>;
  titleId?: InputMaybe<IntOperationFilterInput>;
  titles?: InputMaybe<ListFilterInputTypeOfTitleFilterInput>;
  trackingCodeAvailSetupTypeLookup?: InputMaybe<LookupFilterInput>;
  trackingCodeAvailSetupTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  waveCastMappings?: InputMaybe<ListFilterInputTypeOfWaveCastMappingFilterInput>;
  waveCastModels?: InputMaybe<ListFilterInputTypeOfWaveCastModelFilterInput>;
};

export type ProgressStatus = {
  __typename?: 'ProgressStatus';
  current: Scalars['Int'];
  currentMessage: Scalars['String'];
  currentStatus: Scalars['String'];
  messageFormatString: Scalars['String'];
  percent: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProgressStatusInput = {
  current: Scalars['Int'];
  currentMessage: Scalars['String'];
  currentStatus: Scalars['String'];
  messageFormatString: Scalars['String'];
  percent: Scalars['Int'];
  total: Scalars['Int'];
};

export type Project = {
  __typename?: 'Project';
  availableCategories: Array<Category>;
  clientCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  id: Scalars['String'];
  isProjectClosed: Scalars['Boolean'];
  key: Scalars['String'];
  projFullName: Scalars['String'];
  projName: Scalars['String'];
  statusName: Scalars['String'];
  validDates: Array<ValidDates>;
};

export enum ProjectActualizationType {
  Category = 'CATEGORY',
  Person = 'PERSON'
}

export type ProjectCodeMonthLockStatus = {
  __typename?: 'ProjectCodeMonthLockStatus';
  anyPropertyValuesChanged: Scalars['Boolean'];
  axMonth?: Maybe<Scalars['String']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  department?: Maybe<Scalars['String']>;
  hasChanged: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  projectCode?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
};


export type ProjectCodeMonthLockStatusPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type ProjectDepartment_ForDisplay = {
  __typename?: 'ProjectDepartment_ForDisplay';
  departmentId: Scalars['Int'];
  departmentName: Scalars['String'];
  id: Scalars['Int'];
};

export type ProjectEstimateActualHour = {
  __typename?: 'ProjectEstimateActualHour';
  activityId: Scalars['Int'];
  activityName: Scalars['String'];
  billingAmount: Scalars['Decimal'];
  categoryId: Scalars['String'];
  date: Scalars['DateTime'];
  employeeHomeDepartmentId: Scalars['Int'];
  employeeHomeDepartmentName: Scalars['String'];
  errorMessage: Scalars['String'];
  flightDay: FlightDay;
  hours: Scalars['Decimal'];
  personBaseRoleId: Scalars['Int'];
  personBaseRoleName: Scalars['String'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  rainOnePerson: RainOnePersonBasic_ForDisplay;
  rainOnePersonId: Scalars['Int'];
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
};

export type ProjectEstimateActuals = {
  __typename?: 'ProjectEstimateActuals';
  actualizationType: ProjectActualizationType;
  hardCosts: Array<ProjectEstimateHardCostActual>;
  hours: Array<ProjectEstimateActualHour>;
  projectEstimateId: Scalars['Int'];
  projectId: Scalars['String'];
  projectIds: Array<Scalars['String']>;
};

export enum ProjectEstimateChangeType {
  ApprovalAdded = 'APPROVAL_ADDED',
  Created = 'CREATED',
  DataUpdate = 'DATA_UPDATE',
  Deleted = 'DELETED',
  FileUploaded = 'FILE_UPLOADED',
  Lock = 'LOCK',
  StatusChange = 'STATUS_CHANGE',
  Unknown = 'UNKNOWN',
  Unlock = 'UNLOCK'
}

export type ProjectEstimateDepartmentDescriptionInput = {
  description: Scalars['String'];
  projectEstimateDepartmentId: Scalars['Int'];
};

export type ProjectEstimateDepartmentInput = {
  departmentId: Scalars['Int'];
  description: Scalars['String'];
  hardCostLines: Array<ProjectEstimateHardCostLineInput>;
  laborLines: Array<ProjectEstimateRoleLineInput>;
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateVersionId: Scalars['Int'];
};

export enum ProjectEstimateDepartmentStatus {
  Started = 'STARTED',
  Submitted = 'SUBMITTED'
}

export type ProjectEstimateDepartment_ForDisplay = {
  __typename?: 'ProjectEstimateDepartment_ForDisplay';
  createdBy: Scalars['String'];
  createdByRainOnePersonId: Scalars['Int'];
  createdDateUTC: Scalars['DateTime'];
  departmentId: Scalars['Int'];
  departmentName: Scalars['String'];
  description: Scalars['String'];
  editingByRainOnePerson: RainOnePersonBasic_ForDisplay;
  editingByRainOnePersonId: Scalars['Int'];
  editingStartedUTC: Scalars['DateTime'];
  hardCostLines: Array<ProjectEstimateHardCostLine_ForDisplay>;
  hardCosts: Array<ProjectHardCost_ForDisplay>;
  id: Scalars['Int'];
  isActivelyBeingEdited: Scalars['Boolean'];
  laborLines: Array<ProjectEstimateRoleLine_ForDisplay>;
  laborRoles: Array<Rate_ForDisplay>;
  lastApprovedBy: Scalars['String'];
  lastApprovedByRainOnePerson: RainOnePersonBasic_ForDisplay;
  lastApprovedByRainOnePersonId: Scalars['Int'];
  lastApprovedDateUTC?: Maybe<Scalars['DateTime']>;
  lastUpdatedBy: Scalars['String'];
  lastUpdatedByRainOnePerson: RainOnePersonBasic_ForDisplay;
  lastUpdatedByRainOnePersonId: Scalars['Int'];
  lastUpdatedDateUTC: Scalars['DateTime'];
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateVersionId: Scalars['Int'];
  status: ProjectEstimateDepartmentStatus;
};

export enum ProjectEstimateFileType {
  Cerf = 'CERF',
  CostAnalysisForSignature = 'COST_ANALYSIS_FOR_SIGNATURE',
  SignedCostAnalysis = 'SIGNED_COST_ANALYSIS',
  Unknown = 'UNKNOWN'
}

export type ProjectEstimateHardCostActual = {
  __typename?: 'ProjectEstimateHardCostActual';
  amount: Scalars['Decimal'];
  description: Scalars['String'];
  hardCostId: Scalars['Int'];
  id: Scalars['Long'];
  inputDate: Scalars['DateTime'];
  ledgerRecId: Scalars['Long'];
  /** @deprecated Use Ledger RecId Instead */
  projectEstimateHardCostActualId: Scalars['Int'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
};

export type ProjectEstimateHardCostLineInput = {
  description: Scalars['String'];
  hardCostId: Scalars['Int'];
  markup: Scalars['Decimal'];
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateLineId: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
};

export type ProjectEstimateHardCostLine_ForDisplay = {
  __typename?: 'ProjectEstimateHardCostLine_ForDisplay';
  description: Scalars['String'];
  feeDescription: Scalars['String'];
  feePercentage: Scalars['Decimal'];
  hardCostId: Scalars['Int'];
  id: Scalars['Int'];
  markup: Scalars['Decimal'];
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateLineId: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  total: Scalars['Decimal'];
};

export type ProjectEstimateInput = {
  clientCode: Scalars['String'];
  copyActuals?: InputMaybe<Scalars['Boolean']>;
  copyFromProjectEstimateId?: InputMaybe<Scalars['Int']>;
  csRainOnePersonIds?: InputMaybe<Array<Scalars['Int']>>;
  departmentsToAddOnCreation?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  estimatedStartDate?: InputMaybe<Scalars['DateTime']>;
  pmRainOnePersonIds?: InputMaybe<Array<Scalars['Int']>>;
  projectEstimateId: Scalars['Int'];
  projectEstimateName: Scalars['String'];
  projectEstimateSubTypeId?: InputMaybe<Scalars['Int']>;
  projectEstimateType?: InputMaybe<ProjectEstimateType>;
  projectEstimateVersion?: InputMaybe<ProjectEstimateVersionInput>;
  projectId?: InputMaybe<Scalars['String']>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  rateCardId?: InputMaybe<Scalars['Int']>;
};

export type ProjectEstimateRoleLineInput = {
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateLineId: Scalars['Int'];
  quantity: Scalars['Decimal'];
  roleId: Scalars['Int'];
};

export type ProjectEstimateRoleLine_ForDisplay = {
  __typename?: 'ProjectEstimateRoleLine_ForDisplay';
  activityId: Scalars['Int'];
  activityName: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  markup: Scalars['Decimal'];
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateLineId: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
  total: Scalars['Decimal'];
};

export enum ProjectEstimateStatus {
  Cancelled = 'CANCELLED',
  ClientApproved = 'CLIENT_APPROVED',
  Completed = 'COMPLETED',
  ReadyToSendToClient = 'READY_TO_SEND_TO_CLIENT',
  SentToClient = 'SENT_TO_CLIENT',
  Started = 'STARTED',
  Unknown = 'UNKNOWN',
  WaitingForInternalApprovals = 'WAITING_FOR_INTERNAL_APPROVALS',
  WorkInProgress = 'WORK_IN_PROGRESS'
}

export type ProjectEstimateStatusInfo = {
  __typename?: 'ProjectEstimateStatusInfo';
  createdDate: Scalars['DateTime'];
  isChangeOrder: Scalars['Boolean'];
  isPrimaryStatus: Scalars['Boolean'];
  projectEstimateVersionId: Scalars['Int'];
  status: ProjectEstimateStatus;
  statusName: Scalars['String'];
  versionName: Scalars['String'];
  versionNum: Scalars['Int'];
  versionNumber: Scalars['String'];
};

export type ProjectEstimateSubType_ForDisplay = {
  __typename?: 'ProjectEstimateSubType_ForDisplay';
  id: Scalars['Int'];
  isMultiProjectType: Scalars['Boolean'];
  projectEstimateSubTypeId: Scalars['Int'];
  projectEstimateSubTypeName?: Maybe<Scalars['String']>;
  projectEstimateType: ProjectEstimateType;
};

export enum ProjectEstimateType {
  Creative = 'CREATIVE',
  Retainer = 'RETAINER'
}

export type ProjectEstimateUpdate = {
  __typename?: 'ProjectEstimateUpdate';
  changeType: ProjectEstimateChangeType;
  projectEstimate: ProjectEstimate_ForEditing;
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateVersionId: Scalars['Int'];
  updateByRainOnePerson?: Maybe<RainOnePersonBasic_ForDisplay>;
};

export type ProjectEstimateVersionApproval_ForDisplay = {
  __typename?: 'ProjectEstimateVersionApproval_ForDisplay';
  approvedBy?: Maybe<Scalars['String']>;
  approvedByRainOnePerson: RainOnePersonBasic_ForDisplay;
  approvedDateTimeUTC?: Maybe<Scalars['DateTime']>;
  isApproved: Scalars['Boolean'];
  isStepCompleted: Scalars['Boolean'];
  isStepCurrentlyActive: Scalars['Boolean'];
  name: Scalars['String'];
  requiredPermission: RainOnePermission;
  stepNumber: Scalars['Int'];
};

export type ProjectEstimateVersionFile_ForDisplay = {
  __typename?: 'ProjectEstimateVersionFile_ForDisplay';
  approvedByRainOnePerson: RainOnePersonBasic_ForDisplay;
  fileLink: Scalars['String'];
  fileType: ProjectEstimateFileType;
  id: Scalars['Int'];
  projectEstimateVersionFileId: Scalars['Int'];
  projectEstimateVersionId: Scalars['Int'];
  uploadedBy: Scalars['String'];
  uploadedByRainOnePersonId: Scalars['Int'];
  uploadedDateUTC: Scalars['DateTime'];
};

export type ProjectEstimateVersionInput = {
  considerations?: InputMaybe<Scalars['String']>;
  deliverables?: InputMaybe<Scalars['String']>;
  departmentDescriptions?: InputMaybe<Array<ProjectEstimateDepartmentDescriptionInput>>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountPercentage?: InputMaybe<Scalars['Decimal']>;
  projectEstimateVersionId: Scalars['Int'];
  sharepointLink: Scalars['String'];
  timeLines?: InputMaybe<Scalars['String']>;
};

export type ProjectEstimateVersion_ForDisplay = {
  __typename?: 'ProjectEstimateVersion_ForDisplay';
  approvalWorkflowName: Scalars['String'];
  approvals: Array<ProjectEstimateVersionApproval_ForDisplay>;
  areDetailsEditable: Scalars['Boolean'];
  assumptions: Scalars['String'];
  considerations: Scalars['String'];
  createdBy: Scalars['String'];
  createdDateUTC: Scalars['DateTime'];
  deliverables: Scalars['String'];
  description: Scalars['String'];
  discountAmount: Scalars['Decimal'];
  discountPercentage: Scalars['Decimal'];
  files: Array<ProjectEstimateVersionFile_ForDisplay>;
  id: Scalars['Int'];
  isDeletable: Scalars['Boolean'];
  lastModifiedBy: Scalars['String'];
  lastModifiedDateTimeUTC: Scalars['DateTime'];
  projectEstimateDepartments: Array<ProjectEstimateDepartment_ForDisplay>;
  projectEstimateId: Scalars['Int'];
  projectEstimateVersionId: Scalars['Int'];
  sharepointLink: Scalars['String'];
  status: ProjectEstimateStatus;
  statusName: Scalars['String'];
  timeLines: Scalars['String'];
  versionName: Scalars['String'];
  versionNumber: Scalars['Int'];
  workflowSteps: Array<ProjectEstimateWorkflowStep>;
};

export type ProjectEstimateWorkflowStep = {
  __typename?: 'ProjectEstimateWorkflowStep';
  completedDate?: Maybe<Scalars['DateTime']>;
  isCompleted: Scalars['Boolean'];
  projectEstimateStatus: ProjectEstimateStatus;
  rainOnePersonBasic_ForDisplay?: Maybe<RainOnePersonBasic_ForDisplay>;
  requiredPermission: RainOnePermission;
  statusName: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  stepType: ProjectEstimateWorkflowStepType;
};

export enum ProjectEstimateWorkflowStepType {
  Approval = 'APPROVAL',
  AppAction = 'APP_ACTION',
  Creation = 'CREATION',
  FileUpload = 'FILE_UPLOAD',
  Unknown = 'UNKNOWN',
  WorkflowApproval = 'WORKFLOW_APPROVAL'
}

export type ProjectEstimate_ForDisplay = {
  __typename?: 'ProjectEstimate_ForDisplay';
  allVersionStatusInfo: Array<ProjectEstimateStatusInfo>;
  canBeDeleted: Scalars['Boolean'];
  clientApprovedAmount: Scalars['Decimal'];
  clientCode: Scalars['String'];
  clientName: Scalars['String'];
  createdBy: Scalars['String'];
  createdDateUTC: Scalars['DateTime'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  lastUpdatedDateUTC: Scalars['DateTime'];
  projectDescription: Scalars['String'];
  projectEstimateId: Scalars['Int'];
  projectEstimateName: Scalars['String'];
  projectEstimateSubTypeId: Scalars['Int'];
  projectEstimateSubTypeName: Scalars['String'];
  projectEstimateType: ProjectEstimateType;
  projectId?: Maybe<Scalars['String']>;
  projectIds: Array<Scalars['String']>;
  projectName: Scalars['String'];
  requestedByRainOnePersonId: Scalars['Int'];
  totalAmount: Scalars['Decimal'];
};

export type ProjectEstimate_ForEditing = {
  __typename?: 'ProjectEstimate_ForEditing';
  cabIncludedProjectDetails: CabIncludedProjectDetails;
  clientCode: Scalars['String'];
  clientName: Scalars['String'];
  createdBy: Scalars['String'];
  createdDateUTC: Scalars['DateTime'];
  csRainOnePersonIds: Array<Scalars['Int']>;
  defaultHardCostMarkup: Scalars['Decimal'];
  endDate?: Maybe<Scalars['DateTime']>;
  estimatedStartDate: Scalars['DateTime'];
  hardCostMarkupOverride?: Maybe<Scalars['Decimal']>;
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  lastUpdatedDateUTC: Scalars['DateTime'];
  pmRainOnePersonIds: Array<Scalars['Int']>;
  possibleDepartments: Array<ProjectDepartment_ForDisplay>;
  projectDescription: Scalars['String'];
  projectEstimateId: Scalars['Int'];
  projectEstimateName: Scalars['String'];
  projectEstimateSubTypeId: Scalars['Int'];
  projectEstimateSubTypeName: Scalars['String'];
  projectEstimateType: ProjectEstimateType;
  projectId?: Maybe<Scalars['String']>;
  projectIds: Array<Scalars['String']>;
  projectName: Scalars['String'];
  rateCardId: Scalars['Int'];
  ratecardName: Scalars['String'];
  requestedByRainOnePerson?: Maybe<RainOnePersonBasic_ForDisplay>;
  requestedByRainOnePersonId: Scalars['Int'];
  versions?: Maybe<Array<ProjectEstimateVersion_ForDisplay>>;
};

export type ProjectHardCostInput = {
  aicpCode: Scalars['String'];
  defaultAmount: Scalars['Decimal'];
  defaultMarkup: Scalars['Decimal'];
  departmentId: Scalars['Int'];
  description: Scalars['String'];
  feeDescription?: InputMaybe<Scalars['String']>;
  feePercentage?: InputMaybe<Scalars['Decimal']>;
  hasUniqueMarkup: Scalars['Boolean'];
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
};

export type ProjectHardCost_ForDisplay = {
  __typename?: 'ProjectHardCost_ForDisplay';
  aicpCode: Scalars['String'];
  defaultAmount: Scalars['Decimal'];
  defaultMarkup: Scalars['Decimal'];
  departmentId: Scalars['Int'];
  departmentName: Scalars['String'];
  description: Scalars['String'];
  feeDescription: Scalars['String'];
  feePercentage: Scalars['Decimal'];
  hasUniqueMarkup: Scalars['Boolean'];
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  projectHardCostId: Scalars['Int'];
};

export enum ProjectInvoiceInfoSourceType {
  CustTrans = 'CUST_TRANS',
  ProjInvoiceCostLine = 'PROJ_INVOICE_COST_LINE',
  ProjInvoiceEmplLine = 'PROJ_INVOICE_EMPL_LINE',
  ProjInvoiceItemLine = 'PROJ_INVOICE_ITEM_LINE',
  ProjInvoiceOnAccLine = 'PROJ_INVOICE_ON_ACC_LINE',
  ProjInvoiceRevenueLine = 'PROJ_INVOICE_REVENUE_LINE'
}

export type Query = {
  __typename?: 'Query';
  actionItems: Array<UserActionItem>;
  activeAxClientCodes: Array<Scalars['String']>;
  activeCompanies: Array<DataArea>;
  activeDates: Array<ValidDates>;
  activeUserCampaigns: Array<CrossChannelCampaignForLookup>;
  activeUserInsertionOrders: Array<InsertionOrderForLookup>;
  activities: Array<Activity_ForDisplay>;
  activityCategories: Array<ActivityCategory_ForDisplay>;
  actualsForProject: ProjectEstimateActuals;
  addCacheTest: Scalars['Boolean'];
  addCacheTestForNoDC: Scalars['Boolean'];
  alias: Array<Alias>;
  allActiveClients: Array<AxClientDim>;
  allActiveProjectsForClient: Array<Project>;
  allCompanyArAging: Array<ClientTransaction_ForDisplay>;
  allProjectHardCosts: Array<ProjectHardCost_ForDisplay>;
  appOffline: AppOffline;
  appUser: Array<AppUser>;
  apps: Array<RainOneApp>;
  arAging: Array<ClientTransaction_ForDisplay>;
  audiences: Array<Audience>;
  automatedFileProcessing: Array<AutomatedFileProcessing>;
  averageCpmsForFeeEstimation: Array<AverageCpmByPublisherByChannel>;
  axProjectGroupIds: Array<Scalars['String']>;
  axProjects: Array<AxProjectDetails>;
  azureSAS: Scalars['String'];
  bankAccounts: Array<BankAccount>;
  brandDims: Array<AxBrandDim>;
  budgetAllocationHistory: Array<BudgetAllocation>;
  budgetForClientBilling: Array<BudgetForClientBilling>;
  cabBalanceMismatchReport: CabBalanceMismatch;
  cabIncludedProjectDetails: CabIncludedProjectDetails;
  cabReconciliationReport: Array<CabReconciliationLine>;
  cacheTestCount: Scalars['Int'];
  cacheTestCountNoDC: Scalars['Int'];
  campaignBookedForInsertionOrders: Array<CampaignWithBookedTotal>;
  campaignCrossChannelCampaigns: Array<CampaignCrossChannelCampaign>;
  campaignDimensions: Array<CampaignDimension>;
  campaignVersionComparison: CampaignRevision;
  campaigns: Array<Campaign>;
  catchPhrases: Array<CatchPhrase>;
  categories: Array<D365Category>;
  categoryTermGroupMappings: Array<CategoryTermGroupTypeMapping_ForDisplay>;
  cbsReport: CbsReport;
  cbsReportClientExceptions: CbsReportException;
  cbsReportDefinitionDepartmentGroups: Array<CbsReportDefinitionDepartmentGroup>;
  cbsReportDefinitions: Array<CbsReportDefinition_ForDisplay>;
  cbsReportDefinitionsForClient: Array<CbsReportDefinition_ForDisplay>;
  ccpReport: Array<CcpReport>;
  childAliases?: Maybe<Array<Alias>>;
  clearanceForClientBilling: Array<ClearanceForClientBilling>;
  client?: Maybe<Client_ForMaintenance>;
  clientAdjustments: ClientAdjustmentsByClient;
  clientBackgroundDataUpdateStatus: ClientBackgroundDataUpdateStatus;
  clientCampaignGroups: Array<CrossChannelCampaignGroup>;
  clientContracts: Array<CommissionSlidingScale>;
  clientDatoramaKeys: Array<ClientDatoramaKey>;
  clientDetails: AxClientDim;
  clientDims: Array<AxClientDim>;
  clientMonthDefinitions: Array<ClientMonthDefinition_ForLookup>;
  clientOpportunityTypes: Array<ClientOpportunityType>;
  clientRainOnePeople: Array<RainOnePerson_ForDisplay>;
  clientReconciliationStatusesForClient: Array<ClientReconciliationMonthStatus>;
  clientRoles: Array<ClientRole_ForDisplay>;
  clients: Array<Client>;
  commisions: Array<Commission>;
  contacts: Array<Contact>;
  costMethodLookups: Array<Lookup>;
  createRainOnePersonFromFinopsPerson: RainOnePerson_ForDisplay;
  crossChannelCampaign: CampaignForBudgetAllocation;
  crossChannelCampaignSpendByDay: Array<CampaignSpendCollection>;
  crossChannelCampaigns: Array<CrossChannelCampaign>;
  crossChannelCampaignsForBudgets: Array<CampaignForBudgetAllocation>;
  crossChannelCampaignsForLookup: Array<CrossChannelCampaignForLookup>;
  csiSummaryDetails: Array<CsiFileDetails>;
  csiSummaryDetailsForFile: CsiFileDetails;
  dataGridPreset?: Maybe<DataGridPreset>;
  dataGridPresets: Array<DataGridPreset>;
  dateRangeDates: DateRange;
  datoramaImportJobs: Array<DatoramaImportJob>;
  demandSidePlatforms: Array<MediaOutlet>;
  departmentCompanies: Array<DepartmentCompany_ForDisplay>;
  departmentDepartmentGroups: Array<Department_DepartmentGroup_ForDisplay>;
  departmentDims: Array<AxDepartmentDim>;
  departmentDivisions: Array<DepartmentDivision>;
  departmentGroups: Array<DepartmentGroup_ForDisplay>;
  digitalBillingCountsSourceLookups: Array<Lookup>;
  digitalClientRoles: Array<Lookup>;
  digitalClients: Array<Client>;
  digitalInvoiceMonths: Array<DateRange>;
  digitalInvoiceYears: Array<DateRange>;
  digitalPublishers: Array<MediaOutlet>;
  digitalSpendImportChannelMappings: Array<DigitalSpendImportChannelMapping>;
  digitalSpendImportFieldMappings: Array<DigitalSpendImportFieldMapping>;
  digitalSpendImportsForLookup: Array<DigitalSpendInputFileForLookup>;
  digitalSpendInputFieldLookups: Array<Lookup>;
  dimensionsForClient: DimensionsForClient;
  divisions: Array<Division_ForDisplay>;
  enumDescriptions: Array<EnumDescription>;
  favorites: Array<Favorite>;
  feeVendors: Array<Vendor>;
  filteredProjectDetails: Array<AxProjectDetails>;
  finopsCompanies: Array<Company_ForDisplay>;
  finopsDepartment: Array<FinopsDepartment>;
  finopsPeople: Array<SharepointPerson>;
  flightDateGranularities: Array<Lookup>;
  flightDays: Array<FlightDay>;
  functionalRoles: Array<EnumDescription>;
  gaAccountSummaries: Array<GaAccountSummary>;
  gaPropertyMetadata: GaPropertyMetadata;
  gaReportResponse: GaReportResponse;
  gareports: Array<Gareport>;
  holidays: Array<Holiday>;
  impersonatableUsers: Array<UserDelgate>;
  initiativeDims: Array<AxInitiativeDim>;
  insertionOrder?: Maybe<Array<InsertionOrder>>;
  insertionOrderPlacementResultForLookup?: Maybe<InsertionOrderPlacementResult_ForLookup>;
  insertionOrderPlacementResultsForLookup: Array<InsertionOrderPlacementResult_ForLookup>;
  insertionOrderStatuses: Array<Lookup>;
  insertionOrdersForLookup: Array<InsertionOrderForLookup>;
  invoiceApprovalStateLookups: Array<Lookup>;
  invoicesForApproval: Array<InvoiceForApproval>;
  isTimesheetUserSetupCorrectly: TimesheetUserSetup;
  jobInstances: Array<JobInstance_ForDisplay>;
  jobs: Array<Job>;
  journalsMissingFromTheCube: Array<MissingLedgerItem>;
  laborRates: Array<Rate_ForDisplay>;
  linearCampaignsForMapping: Array<CampaignForLinearSpendMapping>;
  lookupsWithClientAlias: Array<Lookup>;
  mainAccountCategories: Array<Scalars['String']>;
  mainAccounts: Array<MainAccount>;
  mediaMonthDims: Array<AxMediaMonth>;
  mediaOutlet: MediaOutlet;
  mediaOutletTypes: Array<MediaOutletType>;
  mediaOutlets: Array<MediaOutlet>;
  mediaOutletsForBudgetAllocation: Array<MediaOutlet_ForBudgetAllocation>;
  mediaTargetTypeLookups: Array<Lookup>;
  mediaTypes: Array<MediaType>;
  myFunctionalRoles: Array<FunctionalRole>;
  notes: Array<Note_ForDisplay>;
  offices: Array<Office_ForDisplay>;
  outstandingUserInvoices: Array<InvoiceForApproval>;
  packages?: Maybe<Array<Package>>;
  pageFavorites: Array<PageFavorite>;
  partnerBuyTypeLookups: Array<Lookup>;
  peopleMissingGoodCostInfo: Array<PeopleMissingGoodCostInfo>;
  people_Clients: Array<Person_Client_ForDisplay>;
  /**
   * /Gets the current users R1 Permissions
   *
   *
   * **Returns:**
   * A list of RainOnePermissions
   */
  permissions: Array<RainOnePermission>;
  personClients: Array<Person_Client_ForDisplay>;
  personFunctionalRoles: Array<RainOnePerson>;
  primaryCompanies: Array<AxCompany>;
  productAudiences: Array<ProductAudience>;
  productCategories: Array<ProductCategory_ForProductMaintenance>;
  productDims: Array<AxProductDim>;
  productionWestClearanceForClientBilling: Array<ClearanceForClientBilling>;
  products: Array<Product>;
  programmaticExecutionLookups: Array<Lookup>;
  projectCodeMonthLockStatuses: Array<ProjectCodeMonthLockStatus>;
  projectCodesForClient: Array<AxProjectDetails>;
  projectDims: Array<AxProjectDim>;
  projectEstimate: ProjectEstimate_ForEditing;
  projectEstimateForDisplay: ProjectEstimate_ForDisplay;
  projectEstimatePossibleDepartments: Array<ProjectDepartment_ForDisplay>;
  projectEstimateSubTypes: Array<ProjectEstimateSubType_ForDisplay>;
  projectEstimates: Array<ProjectEstimate_ForDisplay>;
  projectGroups: Array<Scalars['String']>;
  projectHardCosts: Array<ProjectHardCost_ForDisplay>;
  projects: Array<Project>;
  projectsForProjectFinanceTool: Array<AxProjectDetails>;
  projectsWithEstimates: Array<Scalars['String']>;
  purposeDims: Array<AxPurposeDim>;
  r1People: Array<R1Person_ForDisplay>;
  rainOneClients: Array<RainOneClient>;
  rainOnePeople: Array<RainOnePerson>;
  rainOnePeopleWithPermission: Array<RainOnePersonBasic_ForDisplay>;
  rainOnePeople_ForDisplay: Array<RainOnePerson_ForDisplay>;
  rainOneRoles: Array<RainOneRole_ForDisplay>;
  rateCard: RateCard_ForDisplay;
  rateCards: Array<RateCard_ForDisplay>;
  regionsByType: Array<Region>;
  regionsByTypes: Array<Region>;
  reportPresets: Array<ReportPreset>;
  resultTypes: Array<DigitalResultType>;
  roleDepartments: Array<RoleDepartment_ForDisplay>;
  roles: Array<Role_ForDisplay>;
  rolesForClient: Array<Person_Client_ForDisplay>;
  scripts: Array<Script>;
  searchAds360Rates?: Maybe<Array<SearchAds360Rate>>;
  spendDisplayDateUnitLookups: Array<Lookup>;
  strategyLookups: Array<Lookup>;
  subordinateManagers: Array<Person_ForDisplay>;
  tacticLookups: Array<Lookup>;
  tax1099BasicInfo: Array<Tax1099BasicInfo>;
  tax1099Info: Array<Tax1099Form>;
  timeCategories: Array<TimeCategory>;
  timeCategoryDepartments: Array<Department_ForDisplay>;
  timeClients: Array<AxClientDim>;
  timeCompanies: Array<Company_ForDisplay>;
  timeData: Array<TimesheetLine_ForReporting>;
  timeDivisions: Array<Division_ForDisplay>;
  timePeople: Array<Person_ForDisplay>;
  timeProjects: Array<AxProjectDetails>;
  timeSheet: TimeSheet;
  timeSheetList: Array<TimeSheet>;
  timeSheetsNotSubmittedCount: Scalars['Int'];
  timeSheetsWaitingForApproval: Array<TimeSheet>;
  timeSheetsWaitingForApprovalCount: Scalars['Int'];
  timeValidationAdditionalCompanies: Array<TimesheetValidationAdditionalCompany_ForDisplay>;
  timeValidationAdditionalDepartmentGroups: Array<TimesheetValidationAdditionalDepartmentGroup_ForDisplay>;
  timeValidationCatDepartments: Array<TimesheetValidationCatDepartmentGroup_ForDisplay>;
  timeValidationCatProjectGroups: Array<TimesheetValidationCatProjectGroup_ForDisplay>;
  timeValidationPersonAdditionalCategories: Array<TimesheetValidationPersonAdditionalCategory_ForDisplay>;
  timeValidationProjCategoryOverrides: Array<TimesheetValidationProjCategoryOverride_ForDisplay>;
  timeValidationProjResourceOverrides: Array<TimesheetValidationProjResourceOverride_ForDisplay>;
  timeWorkerDepartments: Array<Department_ForDisplay>;
  timesheetChangeComparison: TimesheetChange;
  timesheetDelegates: Array<TimesheetDelegate_ForDisplay>;
  timesheetValidationAnalysis: TimeSheetValidationPersonCategoryAnalysis;
  timesheetWeekSummaries: Array<TimesheetWeekSummary>;
  timesheetsHomeCardCount: Scalars['Int'];
  timesheetsInPurgatoryCount: Scalars['Int'];
  trackerBatchDetails: TrackerBatchWithTransIdDetails;
  trackerBatchesByStatus: Array<TrackerBatches>;
  trackerFileReferenceSAS: Scalars['String'];
  trafficSheets: Array<TrafficSheet_ForLookup>;
  uniquePersonDepartments: Array<UniquePersonDepartment>;
  uploadInfo: UploadInfo;
  userInfo: RainOneUserDetails;
  userRainOnePeople_ForDisplay: Array<RainOnePerson_ForDisplay>;
  userSettings?: Maybe<UserSettings>;
  userSettingsForPerson?: Maybe<UserSettings>;
  validateInvoiceForApproval: Array<ValidationMessage>;
  validateInvoiceForUnApproval: Array<ValidationMessage>;
  vendTrans: Array<VendTrans>;
  vendors: Array<VendorV2>;
  vendorsMissingFromCSI: Array<CsiVendorInfo>;
  verificationVendors: Array<Vendor>;
};


export type QueryActiveAxClientCodesArgs = {
  companyCode?: Scalars['String'];
};


export type QueryActiveDatesArgs = {
  impersonateUser?: Scalars['String'];
  isViewOnly?: Scalars['Boolean'];
};


export type QueryActualsForProjectArgs = {
  projectEstimateId?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['String'];
};


export type QueryAliasArgs = {
  order?: InputMaybe<Array<AliasSortInput>>;
  where?: InputMaybe<AliasFilterInput>;
};


export type QueryAllActiveProjectsForClientArgs = {
  clientCode: Scalars['String'];
};


export type QueryAllCompanyArAgingArgs = {
  effectiveDate?: InputMaybe<Scalars['Date']>;
  unpaidOnly?: Scalars['Boolean'];
};


export type QueryAppUserArgs = {
  order?: InputMaybe<Array<AppUserSortInput>>;
  where?: InputMaybe<AppUserFilterInput>;
};


export type QueryArAgingArgs = {
  clientCode: Scalars['String'];
  effectiveDate?: InputMaybe<Scalars['Date']>;
  unpaidOnly?: Scalars['Boolean'];
};


export type QueryAudiencesArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AudienceSortInput>>;
  where?: InputMaybe<AudienceFilterInput>;
};


export type QueryAutomatedFileProcessingArgs = {
  order?: InputMaybe<Array<AutomatedFileProcessingSortInput>>;
  where?: InputMaybe<AutomatedFileProcessingFilterInput>;
};


export type QueryAverageCpmsForFeeEstimationArgs = {
  productId: Scalars['Int'];
};


export type QueryAxProjectGroupIdsArgs = {
  companyCode?: Scalars['String'];
  justActive?: Scalars['Boolean'];
};


export type QueryAxProjectsArgs = {
  clientCode?: Scalars['String'];
  companyCode?: Scalars['String'];
  justActive?: Scalars['Boolean'];
  projectId?: Scalars['String'];
};


export type QueryAzureSasArgs = {
  blobContainer: BlobContainer;
  hours?: Scalars['Int'];
  readOnly?: Scalars['Boolean'];
  useProduction?: Scalars['Boolean'];
};


export type QueryBrandDimsArgs = {
  clientCode?: Scalars['String'];
};


export type QueryBudgetAllocationHistoryArgs = {
  budgetAllocationId: Scalars['Int'];
};


export type QueryBudgetForClientBillingArgs = {
  clientCode?: InputMaybe<Scalars['String']>;
  companyID?: Scalars['Int'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryCabBalanceMismatchReportArgs = {
  clientCode: Scalars['String'];
};


export type QueryCabIncludedProjectDetailsArgs = {
  brandFilters: Array<Scalars['String']>;
  clientCodes: Array<Scalars['String']>;
  companies: Array<Scalars['String']>;
  projectFilters: Array<Scalars['String']>;
  projectGroupFilters: Array<Scalars['String']>;
};


export type QueryCabReconciliationReportArgs = {
  endDate: Scalars['DateTime'];
  endingMediaMonth: Scalars['String'];
  startDate: Scalars['DateTime'];
  startingMediaMonth: Scalars['String'];
};


export type QueryCampaignBookedForInsertionOrdersArgs = {
  crossChannelCampaignIds: Array<Scalars['Int']>;
  insertionOrderDetailIdsToExclude: Array<Scalars['Int']>;
};


export type QueryCampaignCrossChannelCampaignsArgs = {
  crossChannelCampaignId: Scalars['Int'];
};


export type QueryCampaignVersionComparisonArgs = {
  crossChannelCampaignVersionId1: Scalars['Int'];
  crossChannelCampaignVersionId2: Scalars['Int'];
};


export type QueryCampaignsArgs = {
  order?: InputMaybe<Array<CampaignSortInput>>;
  where?: InputMaybe<CampaignFilterInput>;
};


export type QueryCbsReportArgs = {
  cbsReportDefinitionId: Scalars['Int'];
  skipCache?: Scalars['Boolean'];
};


export type QueryCbsReportClientExceptionsArgs = {
  clientCode: Scalars['String'];
  skipCache?: Scalars['Boolean'];
};


export type QueryCbsReportDefinitionsForClientArgs = {
  clientCode: Scalars['String'];
};


export type QueryCcpReportArgs = {
  clientCode?: InputMaybe<Scalars['String']>;
};


export type QueryChildAliasesArgs = {
  appViewId: Scalars['Int'];
  order?: InputMaybe<Array<AliasSortInput>>;
  parentRecordId: Scalars['Int'];
  where?: InputMaybe<AliasFilterInput>;
};


export type QueryClearanceForClientBillingArgs = {
  clientCode?: InputMaybe<Scalars['String']>;
  companyID?: Scalars['Int'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryClientArgs = {
  clientId: Scalars['Int'];
  order?: InputMaybe<Array<Client_ForMaintenanceSortInput>>;
  where?: InputMaybe<Client_ForMaintenanceFilterInput>;
};


export type QueryClientAdjustmentsArgs = {
  clientCode: Scalars['String'];
};


export type QueryClientBackgroundDataUpdateStatusArgs = {
  clientCode: Scalars['String'];
};


export type QueryClientCampaignGroupsArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
};


export type QueryClientContractsArgs = {
  clientCode: Scalars['String'];
};


export type QueryClientDatoramaKeysArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
};


export type QueryClientDetailsArgs = {
  clientCode: Scalars['String'];
};


export type QueryClientMonthDefinitionsArgs = {
  d365ClientCode?: InputMaybe<Scalars['String']>;
};


export type QueryClientOpportunityTypesArgs = {
  where?: InputMaybe<ClientOpportunityTypeFilterInput>;
};


export type QueryClientRainOnePeopleArgs = {
  clientCode?: InputMaybe<Scalars['String']>;
  rainOnePersonId?: InputMaybe<Scalars['Int']>;
};


export type QueryClientReconciliationStatusesForClientArgs = {
  clientCode: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryClientsArgs = {
  d365ClientCode?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<ClientSortInput>>;
  where?: InputMaybe<ClientFilterInput>;
};


export type QueryCommisionsArgs = {
  order?: InputMaybe<Array<CommissionSortInput>>;
  where?: InputMaybe<CommissionFilterInput>;
};


export type QueryContactsArgs = {
  order?: InputMaybe<Array<ContactSortInput>>;
  where?: InputMaybe<ContactFilterInput>;
};


export type QueryCostMethodLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryCreateRainOnePersonFromFinopsPersonArgs = {
  finopsPersonId: Scalars['Int'];
};


export type QueryCrossChannelCampaignArgs = {
  crossChannelCampaignId: Scalars['Int'];
  crossChannelCampaignVersionId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CampaignForBudgetAllocationSortInput>>;
  where?: InputMaybe<CampaignForBudgetAllocationFilterInput>;
};


export type QueryCrossChannelCampaignSpendByDayArgs = {
  crossChannelCampaignIds: Array<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  skipCache?: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCrossChannelCampaignsArgs = {
  order?: InputMaybe<Array<CrossChannelCampaignSortInput>>;
  where?: InputMaybe<CrossChannelCampaignFilterInput>;
};


export type QueryCrossChannelCampaignsForBudgetsArgs = {
  approvedOnly?: Scalars['Boolean'];
  clientIds: Array<Scalars['Int']>;
  crossChannelCampaignIds: Array<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCrossChannelCampaignsForLookupArgs = {
  approvedOnly?: Scalars['Boolean'];
  clientId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  myClientsOnly?: Scalars['Boolean'];
  productId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCsiSummaryDetailsArgs = {
  showArchive?: Scalars['Boolean'];
};


export type QueryCsiSummaryDetailsForFileArgs = {
  fileName: Scalars['String'];
};


export type QueryDataGridPresetArgs = {
  dataGridKey: Scalars['String'];
};


export type QueryDataGridPresetsArgs = {
  order?: InputMaybe<Array<DataGridPresetSortInput>>;
};


export type QueryDateRangeDatesArgs = {
  dateRangeName: Scalars['String'];
};


export type QueryDatoramaImportJobsArgs = {
  order?: InputMaybe<Array<DatoramaImportJobSortInput>>;
  where?: InputMaybe<DatoramaImportJobFilterInput>;
};


export type QueryDemandSidePlatformsArgs = {
  order?: InputMaybe<Array<MediaOutletSortInput>>;
  where?: InputMaybe<MediaOutletFilterInput>;
};


export type QueryDepartmentDepartmentGroupsArgs = {
  departmentGroupType: DepartmentGroupType;
};


export type QueryDepartmentDivisionsArgs = {
  onlyPrimaryCompanies?: Scalars['Boolean'];
};


export type QueryDepartmentGroupsArgs = {
  departmentGroupType: DepartmentGroupType;
};


export type QueryDigitalBillingCountsSourceLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryDigitalClientRolesArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryDigitalClientsArgs = {
  activeOnly?: Scalars['Boolean'];
};


export type QueryDigitalPublishersArgs = {
  includeOttMediaOutlets?: Scalars['Boolean'];
  includePlaceHolders?: Scalars['Boolean'];
  order?: InputMaybe<Array<MediaOutletSortInput>>;
  where?: InputMaybe<MediaOutletFilterInput>;
};


export type QueryDigitalSpendImportChannelMappingsArgs = {
  order?: InputMaybe<Array<DigitalSpendImportChannelMappingSortInput>>;
  where?: InputMaybe<DigitalSpendImportChannelMappingFilterInput>;
};


export type QueryDigitalSpendImportFieldMappingsArgs = {
  order?: InputMaybe<Array<DigitalSpendImportFieldMappingSortInput>>;
  where?: InputMaybe<DigitalSpendImportFieldMappingFilterInput>;
};


export type QueryDigitalSpendImportsForLookupArgs = {
  activeClientsOnly?: Scalars['Boolean'];
  clientID?: InputMaybe<Scalars['Int']>;
  digitalAnalystAppUserID?: InputMaybe<Scalars['Int']>;
  digitalSpendInputFileID?: InputMaybe<Scalars['Int']>;
  filesWithErrorsOnly?: Scalars['Boolean'];
  importEndDate?: InputMaybe<Scalars['DateTime']>;
  importStartDate?: InputMaybe<Scalars['DateTime']>;
  mediaEndDate?: InputMaybe<Scalars['DateTime']>;
  mediaStartDate?: InputMaybe<Scalars['DateTime']>;
  mostRecentImportOnly?: Scalars['Boolean'];
};


export type QueryDigitalSpendInputFieldLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryDimensionsForClientArgs = {
  clientCode: Scalars['String'];
};


export type QueryEnumDescriptionsArgs = {
  enumDescriptionType: EnumDescriptionType;
};


export type QueryFavoritesArgs = {
  impersonateUser?: Scalars['String'];
  isViewOnly?: Scalars['Boolean'];
};


export type QueryFeeVendorsArgs = {
  order?: InputMaybe<Array<VendorSortInput>>;
  where?: InputMaybe<VendorFilterInput>;
};


export type QueryFilteredProjectDetailsArgs = {
  clientCode?: Scalars['String'];
  companyCode?: Scalars['String'];
  justActive?: Scalars['Boolean'];
  projectId?: Scalars['String'];
};


export type QueryFinopsCompaniesArgs = {
  onlyPrimaryCompanies?: Scalars['Boolean'];
};


export type QueryFinopsPeopleArgs = {
  onlyActive?: Scalars['Boolean'];
};


export type QueryFlightDateGranularitiesArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryFlightDaysArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryGaPropertyMetadataArgs = {
  propertyId: Scalars['String'];
};


export type QueryGaReportResponseArgs = {
  reportJson: Scalars['String'];
};


export type QueryGareportsArgs = {
  ga4?: Scalars['Boolean'];
  order?: InputMaybe<Array<GareportSortInput>>;
  where?: InputMaybe<GareportFilterInput>;
};


export type QueryInsertionOrderArgs = {
  insertionOrderID: Scalars['Int'];
  order?: InputMaybe<Array<InsertionOrderSortInput>>;
  where?: InputMaybe<InsertionOrderFilterInput>;
};


export type QueryInsertionOrderPlacementResultForLookupArgs = {
  insertionOrderPlacementResultId: Scalars['Int'];
  where?: InputMaybe<InsertionOrderPlacementResult_ForLookupFilterInput>;
};


export type QueryInsertionOrderPlacementResultsForLookupArgs = {
  insertionOrderId: Scalars['Int'];
  where?: InputMaybe<InsertionOrderPlacementResult_ForLookupFilterInput>;
};


export type QueryInsertionOrderStatusesArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryInsertionOrdersForLookupArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  insertionOrderId?: InputMaybe<Scalars['Int']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  myClientsOnly?: Scalars['Boolean'];
  productId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<InsertionOrderStatus>;
  unconfirmedOnly?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<InsertionOrderForLookupFilterInput>;
};


export type QueryInvoiceApprovalStateLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
};


export type QueryInvoicesForApprovalArgs = {
  approverAppUserID?: InputMaybe<Scalars['Int']>;
  clientID?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  invoiceIDs?: InputMaybe<Array<Scalars['Int']>>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  mediaOutletID?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryJobInstancesArgs = {
  appUserId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  days?: InputMaybe<Scalars['Int']>;
  startUTC?: InputMaybe<Scalars['DateTime']>;
};


export type QueryJobsArgs = {
  order?: InputMaybe<Array<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};


export type QueryLaborRatesArgs = {
  departmentId: Scalars['Int'];
  rateCardId: Scalars['Int'];
};


export type QueryLinearCampaignsForMappingArgs = {
  productId: Scalars['Int'];
};


export type QueryLookupsWithClientAliasArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
  lookupType: LookupTypeEnum;
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryMediaMonthDimsArgs = {
  addOldStyleMediaMonths?: Scalars['Boolean'];
  just2020AndBeyond?: Scalars['Boolean'];
};


export type QueryMediaOutletArgs = {
  mediaOutletId: Scalars['Int'];
};


export type QueryMediaOutletTypesArgs = {
  order?: InputMaybe<Array<MediaOutletTypeSortInput>>;
  where?: InputMaybe<MediaOutletTypeFilterInput>;
};


export type QueryMediaOutletsArgs = {
  order?: InputMaybe<Array<MediaOutletSortInput>>;
  where?: InputMaybe<MediaOutletFilterInput>;
};


export type QueryMediaTargetTypeLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryMediaTypesArgs = {
  activeOnly?: Scalars['Boolean'];
  excludeParentTypes?: Scalars['Boolean'];
  excludePlaceholders?: Scalars['Boolean'];
  order?: InputMaybe<Array<MediaTypeSortInput>>;
  where?: InputMaybe<MediaTypeFilterInput>;
};


export type QueryNotesArgs = {
  noteTypes: Array<NoteType>;
  parentKeys?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryPackagesArgs = {
  productId: Scalars['Int'];
};


export type QueryPageFavoritesArgs = {
  rainOneApp: RainOneApp;
};


export type QueryPartnerBuyTypeLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryPeopleMissingGoodCostInfoArgs = {
  includeZeroHourContractors?: Scalars['Boolean'];
};


export type QueryPeople_ClientsArgs = {
  clientCode?: InputMaybe<Scalars['String']>;
  rainOnePersonId?: InputMaybe<Scalars['Int']>;
};


export type QueryPermissionsArgs = {
  rainOneApp?: InputMaybe<RainOneApp>;
};


export type QueryPersonClientsArgs = {
  rainOnePersonId: Scalars['Int'];
};


export type QueryProductAudiencesArgs = {
  order?: InputMaybe<Array<ProductAudienceSortInput>>;
  productId: Scalars['Int'];
  where?: InputMaybe<ProductAudienceFilterInput>;
};


export type QueryProductionWestClearanceForClientBillingArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryProductsArgs = {
  activeOnly?: Scalars['Boolean'];
  order?: InputMaybe<Array<ProductSortInput>>;
  where?: InputMaybe<ProductFilterInput>;
};


export type QueryProgrammaticExecutionLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryProjectCodeMonthLockStatusesArgs = {
  axClientCode: Scalars['String'];
};


export type QueryProjectCodesForClientArgs = {
  clientId: Scalars['Int'];
};


export type QueryProjectEstimateArgs = {
  projectEstimateId: Scalars['Int'];
};


export type QueryProjectEstimateForDisplayArgs = {
  projectEstimateId: Scalars['Int'];
};


export type QueryProjectEstimatePossibleDepartmentsArgs = {
  projectEstimateType?: InputMaybe<ProjectEstimateType>;
};


export type QueryProjectEstimatesArgs = {
  clientCode?: InputMaybe<Scalars['String']>;
  justActive?: Scalars['Boolean'];
  justMyClients?: Scalars['Boolean'];
  projectId?: InputMaybe<Scalars['String']>;
};


export type QueryProjectHardCostsArgs = {
  departmentId: Scalars['Int'];
};


export type QueryProjectsArgs = {
  impersonateUser?: Scalars['String'];
  isViewOnly?: Scalars['Boolean'];
};


export type QueryProjectsForProjectFinanceToolArgs = {
  clientCode?: Scalars['String'];
};


export type QueryProjectsWithEstimatesArgs = {
  clientCode: Scalars['String'];
};


export type QueryR1PeopleArgs = {
  activeOnly?: Scalars['Boolean'];
};


export type QueryRainOneClientsArgs = {
  justActive?: Scalars['Boolean'];
};


export type QueryRainOnePeopleArgs = {
  order?: InputMaybe<Array<RainOnePersonSortInput>>;
  where?: InputMaybe<RainOnePersonFilterInput>;
};


export type QueryRainOnePeopleWithPermissionArgs = {
  rainOnePermission: RainOnePermission;
};


export type QueryRainOnePeople_ForDisplayArgs = {
  rainOnePersonIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryRainOneRolesArgs = {
  includeAutoManagedRoles?: Scalars['Boolean'];
};


export type QueryRateCardArgs = {
  rateCardId: Scalars['Int'];
};


export type QueryRegionsByTypeArgs = {
  regionType: RegionType;
};


export type QueryRegionsByTypesArgs = {
  regionTypes: Array<RegionType>;
};


export type QueryReportPresetsArgs = {
  order?: InputMaybe<Array<ReportPresetSortInput>>;
  reportEnum: ReportEnum;
  where?: InputMaybe<ReportPresetFilterInput>;
};


export type QueryResultTypesArgs = {
  order?: InputMaybe<Array<DigitalResultTypeSortInput>>;
  where?: InputMaybe<DigitalResultTypeFilterInput>;
};


export type QueryRolesForClientArgs = {
  clientCode?: InputMaybe<Scalars['String']>;
};


export type QueryScriptsArgs = {
  order?: InputMaybe<Array<ScriptSortInput>>;
  where?: InputMaybe<ScriptFilterInput>;
};


export type QuerySpendDisplayDateUnitLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryStrategyLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryTacticLookupsArgs = {
  order?: InputMaybe<Array<LookupSortInput>>;
  where?: InputMaybe<LookupFilterInput>;
};


export type QueryTax1099BasicInfoArgs = {
  year: Scalars['Int'];
};


export type QueryTax1099InfoArgs = {
  year: Scalars['Int'];
};


export type QueryTimeCategoryDepartmentsArgs = {
  timeDataAccessType?: InputMaybe<TimeDataAccessType>;
};


export type QueryTimeClientsArgs = {
  timeDataAccessType?: InputMaybe<TimeDataAccessType>;
};


export type QueryTimeDataArgs = {
  categoryDepartmentIds?: InputMaybe<Array<Scalars['Int']>>;
  clientCodes?: InputMaybe<Array<Scalars['String']>>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  divisionIds?: InputMaybe<Array<Scalars['Int']>>;
  endDate: Scalars['DateTime'];
  personIds?: InputMaybe<Array<Scalars['Int']>>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  startDate: Scalars['DateTime'];
  timeDataAccessType?: InputMaybe<TimeDataAccessType>;
  workerDepartmentIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryTimePeopleArgs = {
  timeDataAccessType?: InputMaybe<TimeDataAccessType>;
};


export type QueryTimeProjectsArgs = {
  timeDataAccessType?: InputMaybe<TimeDataAccessType>;
};


export type QueryTimeSheetArgs = {
  impersonateUser?: Scalars['String'];
  isViewOnly?: Scalars['Boolean'];
  startDateOfWeek: Scalars['DateTime'];
  timeSheetNbr: Scalars['String'];
};


export type QueryTimeSheetListArgs = {
  endDate: Scalars['DateTime'];
  impersonateUser?: Scalars['String'];
  numberOfPeriods: Scalars['Int'];
};


export type QueryTimeWorkerDepartmentsArgs = {
  timeDataAccessType?: InputMaybe<TimeDataAccessType>;
};


export type QueryTimesheetChangeComparisonArgs = {
  timeSheetNbr: Scalars['String'];
};


export type QueryTimesheetDelegatesArgs = {
  delegateType: DelegateType;
};


export type QueryTimesheetValidationAnalysisArgs = {
  personId: Scalars['Int'];
  projectDataAreaId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryTimesheetWeekSummariesArgs = {
  endDate: Scalars['DateTime'];
  includeAllSubordinates?: Scalars['Boolean'];
  personId?: Scalars['Int'];
  startDate: Scalars['DateTime'];
  timesheetStatuses?: InputMaybe<Array<TimesheetStatus>>;
};


export type QueryTimesheetsHomeCardCountArgs = {
  timeSheetHomeCardCountType: TimeSheetHomeCardCountType;
};


export type QueryTrackerBatchDetailsArgs = {
  trackerBatchId: Scalars['String'];
};


export type QueryTrackerBatchesByStatusArgs = {
  trackerBatchStatus: TrackerBatchStatus;
};


export type QueryTrackerFileReferenceSasArgs = {
  blobContainer: BlobContainer;
  hours?: Scalars['Int'];
  readOnly?: Scalars['Boolean'];
  useProduction?: Scalars['Boolean'];
};


export type QueryTrafficSheetsArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  trafficSheetId?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrafficSheet_ForLookupFilterInput>;
};


export type QueryUploadInfoArgs = {
  blobContainer: BlobContainer;
};


export type QueryUserSettingsForPersonArgs = {
  rainOnePersonId: Scalars['Int'];
};


export type QueryValidateInvoiceForApprovalArgs = {
  invoiceID: Scalars['Int'];
};


export type QueryValidateInvoiceForUnApprovalArgs = {
  invoiceID: Scalars['Int'];
};


export type QueryVendTransArgs = {
  accountNumsFilter?: InputMaybe<Array<Scalars['String']>>;
  checkNumbers?: InputMaybe<Array<Scalars['String']>>;
  dataAreaId: Scalars['String'];
  invoiceNumbers?: InputMaybe<Array<Scalars['String']>>;
  paymentRefNumbers?: InputMaybe<Array<Scalars['String']>>;
  paymentType?: InputMaybe<VendTransPaymentType>;
  voucherNumbers?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryVendorsArgs = {
  dataAreaId: Scalars['String'];
  justActive?: Scalars['Boolean'];
  vendorNames?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryVendorsMissingFromCsiArgs = {
  includeChecks?: Scalars['Boolean'];
};


export type QueryVerificationVendorsArgs = {
  order?: InputMaybe<Array<VendorSortInput>>;
  where?: InputMaybe<VendorFilterInput>;
};

export type R1Person_ForDisplay = {
  __typename?: 'R1Person_ForDisplay';
  currentDepartmentId: Scalars['Int'];
  currentDepartmentName: Scalars['String'];
  currentHomeCompany: Scalars['String'];
  currentRoleId: Scalars['Int'];
  currentRoleName: Scalars['String'];
  currentTitleName: Scalars['String'];
  displayName: Scalars['String'];
  finopsPersonId: Scalars['Int'];
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  primaryEmailAddress: Scalars['String'];
};

export type RainError = Error & {
  __typename?: 'RainError';
  errorType: RainExErrorType;
  message: Scalars['String'];
  reloadForAccurateData: Scalars['Boolean'];
};

export type RainErrorMessage = {
  __typename?: 'RainErrorMessage';
  errorLevel: ErrorLevel;
  message: Scalars['String'];
};

export enum RainExErrorType {
  AuthenticationError = 'AUTHENTICATION_ERROR',
  GeneralError = 'GENERAL_ERROR',
  PermissionError = 'PERMISSION_ERROR',
  RecordNotFoundError = 'RECORD_NOT_FOUND_ERROR'
}

export enum RainOneApp {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  Data = 'DATA',
  Finops = 'FINOPS',
  General = 'GENERAL',
  Home = 'HOME',
  Labs = 'LABS',
  Media = 'MEDIA',
  Time = 'TIME',
  Unknown = 'UNKNOWN'
}

export type RainOneClient = {
  __typename?: 'RainOneClient';
  billingCompanyId?: Maybe<Scalars['Int']>;
  clientCompanies: Array<RainOneClientCompany>;
  clientName: Scalars['String'];
  clientOpportunityType?: Maybe<ClientOpportunityType>;
  creativePaymentTermsDescription: Scalars['String'];
  finopsClientId: Scalars['Int'];
  hideFromMainReporting: Scalars['Boolean'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isInternal: Scalars['Boolean'];
  msaDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  updatedBy: Scalars['String'];
  updatedDateUTC: Scalars['DateTime'];
  usePIClientFloatBudgets: Scalars['Boolean'];
};

export type RainOneClientCompany = {
  __typename?: 'RainOneClientCompany';
  cLientId: Scalars['Int'];
  clientCompanyInfoId: Scalars['Int'];
  dataAreaId: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  updatedBy: Scalars['String'];
  updatedDateUTC: Scalars['DateTime'];
};

export type RainOneClientCompanyInput = {
  clientCompanyInfoId: Scalars['Int'];
  dataAreaId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type RainOneClientInput = {
  billingCompanyId?: InputMaybe<Scalars['Int']>;
  clientName: Scalars['String'];
  clientOpportunityTypeId?: InputMaybe<Scalars['Int']>;
  creativePaymentTermsDescription?: InputMaybe<Scalars['String']>;
  hideFromMainReporting: Scalars['Boolean'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isInternal: Scalars['Boolean'];
  msaDate?: InputMaybe<Scalars['DateTime']>;
  rainOneClientCompanyInputs: Array<RainOneClientCompanyInput>;
  usePIClientFloatBudgets?: InputMaybe<Scalars['Boolean']>;
};

export enum RainOnePermission {
  Access1099Info = 'ACCESS1099_INFO',
  AccessAdmin = 'ACCESS_ADMIN',
  AccessFinops = 'ACCESS_FINOPS',
  AccessRainOneData = 'ACCESS_RAIN_ONE_DATA',
  AccessRainOneLabs = 'ACCESS_RAIN_ONE_LABS',
  AccessTimesheet = 'ACCESS_TIMESHEET',
  BasicClientApp = 'BASIC_CLIENT_APP',
  CampaignEdit = 'CAMPAIGN_EDIT',
  CampaignReadOnly = 'CAMPAIGN_READ_ONLY',
  ClearTimeSheetCaches = 'CLEAR_TIME_SHEET_CACHES',
  ClientPersonAdmin = 'CLIENT_PERSON_ADMIN',
  ClientProjectManager = 'CLIENT_PROJECT_MANAGER',
  EditClientFinanceInfo = 'EDIT_CLIENT_FINANCE_INFO',
  EditEmailPictures = 'EDIT_EMAIL_PICTURES',
  EditPeople = 'EDIT_PEOPLE',
  EditProjectBudgets = 'EDIT_PROJECT_BUDGETS',
  EditProjectBudgetDiscount = 'EDIT_PROJECT_BUDGET_DISCOUNT',
  EditPublisherTerms = 'EDIT_PUBLISHER_TERMS',
  EditRateCards = 'EDIT_RATE_CARDS',
  EditRolesAndPermission = 'EDIT_ROLES_AND_PERMISSION',
  FeatureFlag1 = 'FEATURE_FLAG1',
  FeatureFlag2 = 'FEATURE_FLAG2',
  FeatureFlag3 = 'FEATURE_FLAG3',
  FeatureFlag4 = 'FEATURE_FLAG4',
  FeatureFlag5 = 'FEATURE_FLAG5',
  FeatureFlag6 = 'FEATURE_FLAG6',
  FeatureFlag7 = 'FEATURE_FLAG7',
  FeatureFlag8 = 'FEATURE_FLAG8',
  FeatureFlag9 = 'FEATURE_FLAG9',
  FeatureFlag10 = 'FEATURE_FLAG10',
  GoogleUser = 'GOOGLE_USER',
  ImpersonateAnyUser = 'IMPERSONATE_ANY_USER',
  InsertionOrderEdit = 'INSERTION_ORDER_EDIT',
  InsertionOrderReadOnly = 'INSERTION_ORDER_READ_ONLY',
  InvalidAccess = 'INVALID_ACCESS',
  ManageRateCardInfo = 'MANAGE_RATE_CARD_INFO',
  PftClientServices = 'PFT_CLIENT_SERVICES',
  PftProjectManager = 'PFT_PROJECT_MANAGER',
  ProjectBudgetAdmin = 'PROJECT_BUDGET_ADMIN',
  PublisherEdit = 'PUBLISHER_EDIT',
  PublisherReadOnly = 'PUBLISHER_READ_ONLY',
  RainOneMediaAdmin = 'RAIN_ONE_MEDIA_ADMIN',
  RoleAdmin = 'ROLE_ADMIN',
  SendCsiPaymentFile = 'SEND_CSI_PAYMENT_FILE',
  SuperAdminOnly = 'SUPER_ADMIN_ONLY',
  TimesheetDelegates = 'TIMESHEET_DELEGATES',
  TimesheetInputer = 'TIMESHEET_INPUTER',
  TimesheetManager = 'TIMESHEET_MANAGER',
  TimesheetValidation = 'TIMESHEET_VALIDATION',
  TimesheetWorkflowAdmin = 'TIMESHEET_WORKFLOW_ADMIN',
  TimeTransferApprover = 'TIME_TRANSFER_APPROVER',
  TrackerDatoramaFieldMappings = 'TRACKER_DATORAMA_FIELD_MAPPINGS',
  TrackerDigitalInvoicesApprover = 'TRACKER_DIGITAL_INVOICES_APPROVER',
  TrackerDigitalInvoicesViewer = 'TRACKER_DIGITAL_INVOICES_VIEWER',
  TrafficSheetsEdit = 'TRAFFIC_SHEETS_EDIT',
  TrafficSheetsReadOnly = 'TRAFFIC_SHEETS_READ_ONLY',
  ViewAllCompanyArAging = 'VIEW_ALL_COMPANY_AR_AGING',
  ViewAllCompanyCcp = 'VIEW_ALL_COMPANY_CCP',
  ViewAllTimesheets = 'VIEW_ALL_TIMESHEETS',
  ViewArAgingForMyCompany = 'VIEW_AR_AGING_FOR_MY_COMPANY',
  /** @deprecated This is to basic of a permission so lots of other permission relied on it. So we removed it. */
  ViewAxDimensions = 'VIEW_AX_DIMENSIONS',
  ViewCcpForMyCompany = 'VIEW_CCP_FOR_MY_COMPANY',
  ViewClientFinanceInfo = 'VIEW_CLIENT_FINANCE_INFO',
  ViewCsiSummaryDetails = 'VIEW_CSI_SUMMARY_DETAILS',
  ViewJobDashboard = 'VIEW_JOB_DASHBOARD',
  ViewMyClientsAllCompanyCcp = 'VIEW_MY_CLIENTS_ALL_COMPANY_CCP',
  ViewMyClientArAging = 'VIEW_MY_CLIENT_AR_AGING',
  ViewProjectBudget = 'VIEW_PROJECT_BUDGET'
}

export type RainOnePermissionOperationFilterInput = {
  eq?: InputMaybe<RainOnePermission>;
  in?: InputMaybe<Array<RainOnePermission>>;
  neq?: InputMaybe<RainOnePermission>;
  nin?: InputMaybe<Array<RainOnePermission>>;
};

export type RainOnePerson = {
  __typename?: 'RainOnePerson';
  currentTitle: Scalars['String'];
  displayName: Scalars['String'];
  firstName: Scalars['String'];
  fullImageHash: Scalars['String'];
  id: Scalars['Int'];
  initials: Scalars['String'];
  lastName: Scalars['String'];
  personFunctionalRoles: Array<PersonFunctionalRole>;
  person_Clients: Array<Person_Client>;
  primaryEmailAddress: Scalars['String'];
  profileImage: Scalars['String'];
  profileImageUrl: Scalars['String'];
  rainOnePersonLinks: Array<RainOnePersonLink>;
  rainOnePerson_RainOneRoles: Array<RainOnePerson_RainOneRole>;
};

export type RainOnePersonBasic_ForDisplay = {
  __typename?: 'RainOnePersonBasic_ForDisplay';
  currentTitle: Scalars['String'];
  displayName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  initials: Scalars['String'];
  lastName: Scalars['String'];
  primaryEmailAddress: Scalars['String'];
  profileImageUrl: Scalars['String'];
};

export type RainOnePersonFilterInput = {
  and?: InputMaybe<Array<RainOnePersonFilterInput>>;
  currentTitle?: InputMaybe<StringOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullImageHash?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RainOnePersonFilterInput>>;
  personFunctionalRoles?: InputMaybe<ListFilterInputTypeOfPersonFunctionalRoleFilterInput>;
  person_Clients?: InputMaybe<ListFilterInputTypeOfPerson_ClientFilterInput>;
  primaryEmailAddress?: InputMaybe<StringOperationFilterInput>;
  profileImage?: InputMaybe<StringOperationFilterInput>;
  profileImageUrl?: InputMaybe<StringOperationFilterInput>;
  rainOnePersonLinks?: InputMaybe<ListFilterInputTypeOfRainOnePersonLinkFilterInput>;
  rainOnePerson_RainOneRoles?: InputMaybe<ListFilterInputTypeOfRainOnePerson_RainOneRoleFilterInput>;
};

export type RainOnePersonLink = {
  __typename?: 'RainOnePersonLink';
  displayName: Scalars['String'];
  emailAddress: Scalars['String'];
  id: Scalars['Int'];
  personLinkType: PersonLinkType;
  rainOnePerson: RainOnePerson;
  rainOnePersonId: Scalars['Int'];
  uniqueIdentifier: Scalars['String'];
};

export type RainOnePersonLinkFilterInput = {
  and?: InputMaybe<Array<RainOnePersonLinkFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RainOnePersonLinkFilterInput>>;
  personLinkType?: InputMaybe<PersonLinkTypeOperationFilterInput>;
  rainOnePerson?: InputMaybe<RainOnePersonFilterInput>;
  rainOnePersonId?: InputMaybe<IntOperationFilterInput>;
  uniqueIdentifier?: InputMaybe<StringOperationFilterInput>;
};

export type RainOnePersonSortInput = {
  currentTitle?: InputMaybe<SortEnumType>;
  displayName?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullImageHash?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  primaryEmailAddress?: InputMaybe<SortEnumType>;
  profileImage?: InputMaybe<SortEnumType>;
  profileImageUrl?: InputMaybe<SortEnumType>;
};

export type RainOnePerson_ForDisplay = {
  __typename?: 'RainOnePerson_ForDisplay';
  accountSetupErrorMessages: Array<Scalars['String']>;
  currentTitle: Scalars['String'];
  displayName: Scalars['String'];
  finopsPersonId: Scalars['Int'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  initials: Scalars['String'];
  lastName: Scalars['String'];
  personFunctionalRoles: Array<PersonFunctionalRole_ForDisplay>;
  person_Clients: Array<Person_Client_ForDisplay>;
  primaryEmailAddress: Scalars['String'];
  profileImage: Scalars['String'];
  profileImageUrl: Scalars['String'];
  rainOnePersonLinks: Array<RainOnePersonLink>;
  rainOneRoles: Array<RainOnePerson_RainOneRole_ForDisplay>;
};

export type RainOnePerson_RainOneRole = {
  __typename?: 'RainOnePerson_RainOneRole';
  id: Scalars['Int'];
  rainOnePerson: RainOnePerson;
  rainOnePersonId: Scalars['Int'];
  rainOneRole: RainOneRole;
  rainOneRoleId: Scalars['Int'];
};

export type RainOnePerson_RainOneRoleFilterInput = {
  and?: InputMaybe<Array<RainOnePerson_RainOneRoleFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RainOnePerson_RainOneRoleFilterInput>>;
  rainOnePerson?: InputMaybe<RainOnePersonFilterInput>;
  rainOnePersonId?: InputMaybe<IntOperationFilterInput>;
  rainOneRole?: InputMaybe<RainOneRoleFilterInput>;
  rainOneRoleId?: InputMaybe<IntOperationFilterInput>;
};

export type RainOnePerson_RainOneRole_ForDisplay = {
  __typename?: 'RainOnePerson_RainOneRole_ForDisplay';
  functionalRole?: Maybe<FunctionalRole>;
  id: Scalars['Int'];
  isAutoManagedRole: Scalars['Boolean'];
  rainOnePersonId: Scalars['Int'];
  rainOneRoleId: Scalars['Int'];
  roleName: Scalars['String'];
};

export type RainOneRole = {
  __typename?: 'RainOneRole';
  functionalRole?: Maybe<FunctionalRole>;
  id: Scalars['Int'];
  rainOnePerson_RainOneRole: Array<RainOnePerson_RainOneRole>;
  rainOneRole_RainPermissions: Array<RainOneRole_RainPermission>;
  roleName: Scalars['String'];
};

export type RainOneRoleFilterInput = {
  and?: InputMaybe<Array<RainOneRoleFilterInput>>;
  functionalRole?: InputMaybe<NullableOfFunctionalRoleOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RainOneRoleFilterInput>>;
  rainOnePerson_RainOneRole?: InputMaybe<ListFilterInputTypeOfRainOnePerson_RainOneRoleFilterInput>;
  rainOneRole_RainPermissions?: InputMaybe<ListFilterInputTypeOfRainOneRole_RainPermissionFilterInput>;
  roleName?: InputMaybe<StringOperationFilterInput>;
};

export type RainOneRoleInput = {
  id: Scalars['Int'];
  roleName: Scalars['String'];
};

export type RainOneRole_ForDisplay = {
  __typename?: 'RainOneRole_ForDisplay';
  id: Scalars['Int'];
  isAutoManagedRole: Scalars['Boolean'];
  rainOnePerson_RainOneRole: Array<RainOnePerson_RainOneRole>;
  rainOneRole_RainPermissions: Array<RainOneRole_RainPermission_ForDisplay>;
  roleName: Scalars['String'];
};

export type RainOneRole_RainPermission = {
  __typename?: 'RainOneRole_RainPermission';
  autoCreated: Scalars['Boolean'];
  id: Scalars['Int'];
  rainOneRole: RainOneRole;
  rainOneRoleId: Scalars['Int'];
  rainPermission: RainOnePermission;
};

export type RainOneRole_RainPermissionFilterInput = {
  and?: InputMaybe<Array<RainOneRole_RainPermissionFilterInput>>;
  autoCreated?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RainOneRole_RainPermissionFilterInput>>;
  rainOneRole?: InputMaybe<RainOneRoleFilterInput>;
  rainOneRoleId?: InputMaybe<IntOperationFilterInput>;
  rainPermission?: InputMaybe<RainOnePermissionOperationFilterInput>;
};

export type RainOneRole_RainPermission_ForDisplay = {
  __typename?: 'RainOneRole_RainPermission_ForDisplay';
  id: Scalars['Int'];
  permissionDisplayName: Scalars['String'];
  rainOneRoleId: Scalars['Int'];
  rainPermissionId: Scalars['Int'];
};

export type RainOneUserDetails = {
  __typename?: 'RainOneUserDetails';
  accountEmailAddress: Scalars['String'];
  b2CUniqueId: Scalars['String'];
  clients: Array<ClientInfo>;
  currentHomeCompany: Scalars['String'];
  displayName: Scalars['String'];
  /** @deprecated Use ProfileImageUrl instead */
  encodedImageString: Scalars['String'];
  finopsPersonId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  primaryEmailAddress: Scalars['String'];
  profileImageUrl: Scalars['String'];
  rainOnePersonId: Scalars['Int'];
  title: Scalars['String'];
  trackerAppUserId?: Maybe<Scalars['Int']>;
  trackerAppUserRoles?: Maybe<Array<TrackerAppUserRole>>;
  userInitials: Scalars['String'];
};

export type RainResult = {
  __typename?: 'RainResult';
  errors: Array<RainErrorMessage>;
  isSuccess: Scalars['Boolean'];
  primaryMessage: Scalars['String'];
};

export type RateCard = {
  __typename?: 'RateCard';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Decimal']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaOutletRotations?: Maybe<Array<Maybe<MediaOutletRotation>>>;
  quarter: Scalars['Int'];
  rateCardId: Scalars['Int'];
  year: Scalars['Int'];
};

export type RateCardFilterInput = {
  and?: InputMaybe<Array<RateCardFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  discountRate?: InputMaybe<DecimalOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletRotations?: InputMaybe<ListFilterInputTypeOfMediaOutletRotationFilterInput>;
  or?: InputMaybe<Array<RateCardFilterInput>>;
  quarter?: InputMaybe<IntOperationFilterInput>;
  rateCardId?: InputMaybe<IntOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type RateCard_ForDisplay = {
  __typename?: 'RateCard_ForDisplay';
  clientCode: Scalars['String'];
  hardCostMarkupOverride?: Maybe<Scalars['Decimal']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isCompanyDefault: Scalars['Boolean'];
  rateCardId: Scalars['Int'];
  rateCardName: Scalars['String'];
  rateCardStartDate: Scalars['DateTime'];
  rates: Array<Rate_ForDisplay>;
};

export enum RateType {
  Adj = 'ADJ',
  Bump = 'BUMP',
  Comp = 'COMP',
  Cust = 'CUST',
  Fire = 'FIRE',
  Gen = 'GEN',
  Guaranteed = 'GUARANTEED',
  Hol = 'HOL',
  Nc = 'NC',
  Norm = 'NORM',
  Remnant = 'REMNANT',
  Rr = 'RR'
}

export type Rate_ForDisplay = {
  __typename?: 'Rate_ForDisplay';
  activityId: Scalars['Int'];
  activityName: Scalars['String'];
  amount: Scalars['Decimal'];
  id: Scalars['Int'];
  rateCardId: Scalars['Int'];
  rateCardName: Scalars['String'];
  rateId: Scalars['Int'];
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
};

export type Rating = {
  __typename?: 'Rating';
  c3ratingTypeLookup?: Maybe<Lookup>;
  c3ratingTypeLookupId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaAvailRatings?: Maybe<Array<Maybe<MediaAvailRating>>>;
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  plus3RatingTypeLookup?: Maybe<Lookup>;
  plus3RatingTypeLookupId?: Maybe<Scalars['Int']>;
  rating1?: Maybe<Scalars['Decimal']>;
  ratingAdInstances?: Maybe<Array<Maybe<RatingAdInstance>>>;
  ratingAvailProposalDetails?: Maybe<Array<Maybe<RatingAvailProposalDetail>>>;
  ratingC3?: Maybe<Scalars['Decimal']>;
  ratingId: Scalars['Int'];
  ratingMediaScheduleDetails?: Maybe<Array<Maybe<RatingMediaScheduleDetail>>>;
  ratingPlus3?: Maybe<Scalars['Decimal']>;
  ratingSchedules?: Maybe<Array<Maybe<RatingSchedule>>>;
  ratingTypeLookup?: Maybe<Lookup>;
  ratingTypeLookupId: Scalars['Int'];
  rentrakDemographic?: Maybe<RentrakDemographic>;
  rentrakDemographicId?: Maybe<Scalars['Int']>;
  rentrakRatingAdInstances?: Maybe<Array<Maybe<RentrakRatingAdInstance>>>;
  rentrakRatingSchedules?: Maybe<Array<Maybe<RentrakRatingSchedule>>>;
  viewers?: Maybe<Scalars['Decimal']>;
  viewersC3?: Maybe<Scalars['Decimal']>;
  viewersPlus3?: Maybe<Scalars['Decimal']>;
};

export type RatingAdInstance = {
  __typename?: 'RatingAdInstance';
  adInstance?: Maybe<AdInstance>;
  adInstanceId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Rating>;
  ratingAdInstanceId: Scalars['Int'];
  ratingId: Scalars['Int'];
};

export type RatingAdInstanceFilterInput = {
  adInstance?: InputMaybe<AdInstanceFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<RatingAdInstanceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RatingAdInstanceFilterInput>>;
  rating?: InputMaybe<RatingFilterInput>;
  ratingAdInstanceId?: InputMaybe<IntOperationFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
};

export type RatingAvailProposalDetail = {
  __typename?: 'RatingAvailProposalDetail';
  availProposalDetail?: Maybe<AvailProposalDetail>;
  availProposalDetailId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Rating>;
  ratingAvailProposalDetailId: Scalars['Int'];
  ratingId: Scalars['Int'];
};

export type RatingAvailProposalDetailFilterInput = {
  and?: InputMaybe<Array<RatingAvailProposalDetailFilterInput>>;
  availProposalDetail?: InputMaybe<AvailProposalDetailFilterInput>;
  availProposalDetailId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RatingAvailProposalDetailFilterInput>>;
  rating?: InputMaybe<RatingFilterInput>;
  ratingAvailProposalDetailId?: InputMaybe<IntOperationFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
};

export type RatingFilterInput = {
  and?: InputMaybe<Array<RatingFilterInput>>;
  c3ratingTypeLookup?: InputMaybe<LookupFilterInput>;
  c3ratingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaAvailRatings?: InputMaybe<ListFilterInputTypeOfMediaAvailRatingFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RatingFilterInput>>;
  plus3RatingTypeLookup?: InputMaybe<LookupFilterInput>;
  plus3RatingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  rating1?: InputMaybe<DecimalOperationFilterInput>;
  ratingAdInstances?: InputMaybe<ListFilterInputTypeOfRatingAdInstanceFilterInput>;
  ratingAvailProposalDetails?: InputMaybe<ListFilterInputTypeOfRatingAvailProposalDetailFilterInput>;
  ratingC3?: InputMaybe<DecimalOperationFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
  ratingMediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfRatingMediaScheduleDetailFilterInput>;
  ratingPlus3?: InputMaybe<DecimalOperationFilterInput>;
  ratingSchedules?: InputMaybe<ListFilterInputTypeOfRatingScheduleFilterInput>;
  ratingTypeLookup?: InputMaybe<LookupFilterInput>;
  ratingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  rentrakDemographic?: InputMaybe<RentrakDemographicFilterInput>;
  rentrakDemographicId?: InputMaybe<IntOperationFilterInput>;
  rentrakRatingAdInstances?: InputMaybe<ListFilterInputTypeOfRentrakRatingAdInstanceFilterInput>;
  rentrakRatingSchedules?: InputMaybe<ListFilterInputTypeOfRentrakRatingScheduleFilterInput>;
  viewers?: InputMaybe<DecimalOperationFilterInput>;
  viewersC3?: InputMaybe<DecimalOperationFilterInput>;
  viewersPlus3?: InputMaybe<DecimalOperationFilterInput>;
};

export type RatingMediaScheduleDetail = {
  __typename?: 'RatingMediaScheduleDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaScheduleDetail?: Maybe<MediaScheduleDetail>;
  mediaScheduleDetailId: Scalars['Int'];
  rating?: Maybe<Rating>;
  ratingId: Scalars['Int'];
  ratingMediaScheduleDetailId: Scalars['Int'];
};

export type RatingMediaScheduleDetailFilterInput = {
  and?: InputMaybe<Array<RatingMediaScheduleDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaScheduleDetail?: InputMaybe<MediaScheduleDetailFilterInput>;
  mediaScheduleDetailId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RatingMediaScheduleDetailFilterInput>>;
  rating?: InputMaybe<RatingFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
  ratingMediaScheduleDetailId?: InputMaybe<IntOperationFilterInput>;
};

export type RatingSchedule = {
  __typename?: 'RatingSchedule';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Rating>;
  ratingId: Scalars['Int'];
  ratingScheduleId: Scalars['Int'];
  schedule?: Maybe<Schedule>;
  scheduleId: Scalars['Int'];
};

export type RatingScheduleFilterInput = {
  and?: InputMaybe<Array<RatingScheduleFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RatingScheduleFilterInput>>;
  rating?: InputMaybe<RatingFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
  ratingScheduleId?: InputMaybe<IntOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export enum ReconciliationStatus {
  FullyReconciled = 'FULLY_RECONCILED',
  Na = 'NA',
  NotReconciled = 'NOT_RECONCILED',
  PartiallyReconciled = 'PARTIALLY_RECONCILED'
}

export type Region = {
  __typename?: 'Region';
  ads?: Maybe<Array<Maybe<Ad>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  crossChannelCampaignVersions?: Maybe<Array<Maybe<CrossChannelCampaignVersion>>>;
  currencyCodeLookup?: Maybe<Lookup>;
  currencyCodeLookupId?: Maybe<Scalars['Int']>;
  dmainfoCountryRegions?: Maybe<Array<Maybe<Dmainfo>>>;
  dmainfoRegions?: Maybe<Array<Maybe<Dmainfo>>>;
  dmainfoStateRegions?: Maybe<Array<Maybe<Dmainfo>>>;
  isClosedCaptionRequired: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Decimal']>;
  localMarketCostPers?: Maybe<Array<Maybe<LocalMarketCostPer>>>;
  localMarketRatingEstimateMarkets?: Maybe<Array<Maybe<LocalMarketRatingEstimateMarket>>>;
  longitude?: Maybe<Scalars['Decimal']>;
  mediaOutletRegions?: Maybe<Array<Maybe<MediaOutletRegion>>>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  productRegionBlackLists?: Maybe<Array<Maybe<ProductRegionBlackList>>>;
  regionId: Scalars['Int'];
  regionLinkRegionChildren?: Maybe<Array<Maybe<RegionLink>>>;
  regionLinkRegionParents?: Maybe<Array<Maybe<RegionLink>>>;
  regionTypeLookup?: Maybe<Lookup>;
  regionTypeLookupId: Scalars['Int'];
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  rvAdInstances?: Maybe<Array<Maybe<RvAdInstance>>>;
  rvresults?: Maybe<Array<Maybe<Rvresult>>>;
  showRatesAsLocalCurrencyNet: Scalars['Boolean'];
};

export type RegionFilterInput = {
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<RegionFilterInput>>;
  campaigns?: InputMaybe<ListFilterInputTypeOfCampaignFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  county?: InputMaybe<StringOperationFilterInput>;
  crossChannelCampaignVersions?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignVersionFilterInput>;
  currencyCodeLookup?: InputMaybe<LookupFilterInput>;
  currencyCodeLookupId?: InputMaybe<IntOperationFilterInput>;
  dmainfoCountryRegions?: InputMaybe<ListFilterInputTypeOfDmainfoFilterInput>;
  dmainfoRegions?: InputMaybe<ListFilterInputTypeOfDmainfoFilterInput>;
  dmainfoStateRegions?: InputMaybe<ListFilterInputTypeOfDmainfoFilterInput>;
  isClosedCaptionRequired?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  latitude?: InputMaybe<DecimalOperationFilterInput>;
  localMarketCostPers?: InputMaybe<ListFilterInputTypeOfLocalMarketCostPerFilterInput>;
  localMarketRatingEstimateMarkets?: InputMaybe<ListFilterInputTypeOfLocalMarketRatingEstimateMarketFilterInput>;
  longitude?: InputMaybe<DecimalOperationFilterInput>;
  mediaOutletRegions?: InputMaybe<ListFilterInputTypeOfMediaOutletRegionFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RegionFilterInput>>;
  productRegionBlackLists?: InputMaybe<ListFilterInputTypeOfProductRegionBlackListFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  regionLinkRegionChildren?: InputMaybe<ListFilterInputTypeOfRegionLinkFilterInput>;
  regionLinkRegionParents?: InputMaybe<ListFilterInputTypeOfRegionLinkFilterInput>;
  regionTypeLookup?: InputMaybe<LookupFilterInput>;
  regionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  resultStagings?: InputMaybe<ListFilterInputTypeOfResultStagingFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  rvAdInstances?: InputMaybe<ListFilterInputTypeOfRvAdInstanceFilterInput>;
  rvresults?: InputMaybe<ListFilterInputTypeOfRvresultFilterInput>;
  showRatesAsLocalCurrencyNet?: InputMaybe<BooleanOperationFilterInput>;
};

export type RegionLink = {
  __typename?: 'RegionLink';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  regionChild?: Maybe<Region>;
  regionChildId: Scalars['Int'];
  regionLinkId: Scalars['Int'];
  regionParent?: Maybe<Region>;
  regionParentId: Scalars['Int'];
};

export type RegionLinkFilterInput = {
  and?: InputMaybe<Array<RegionLinkFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RegionLinkFilterInput>>;
  regionChild?: InputMaybe<RegionFilterInput>;
  regionChildId?: InputMaybe<IntOperationFilterInput>;
  regionLinkId?: InputMaybe<IntOperationFilterInput>;
  regionParent?: InputMaybe<RegionFilterInput>;
  regionParentId?: InputMaybe<IntOperationFilterInput>;
};

export type RegionSortInput = {
  city?: InputMaybe<SortEnumType>;
  county?: InputMaybe<SortEnumType>;
  currencyCodeLookup?: InputMaybe<LookupSortInput>;
  currencyCodeLookupId?: InputMaybe<SortEnumType>;
  isClosedCaptionRequired?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  oldtableName?: InputMaybe<SortEnumType>;
  regionId?: InputMaybe<SortEnumType>;
  regionTypeLookup?: InputMaybe<LookupSortInput>;
  regionTypeLookupId?: InputMaybe<SortEnumType>;
  showRatesAsLocalCurrencyNet?: InputMaybe<SortEnumType>;
};

export enum RegionType {
  Ac = 'AC',
  Acexc = 'ACEXC',
  AwRegion = 'AW_REGION',
  Country = 'COUNTRY',
  Dma = 'DMA',
  DmaCa = 'DMA_CA',
  DmaEngland = 'DMA_ENGLAND',
  GaMetro = 'GA_METRO',
  Msa = 'MSA',
  NielsenAudioMarket = 'NIELSEN_AUDIO_MARKET',
  Other = 'OTHER',
  Pmsa = 'PMSA',
  PostalCode = 'POSTAL_CODE',
  PrintTerr = 'PRINT_TERR',
  RainOneRegion = 'RAIN_ONE_REGION',
  Region = 'REGION',
  SqadRadioMarket = 'SQAD_RADIO_MARKET',
  State = 'STATE',
  UsRegion = 'US_REGION',
  Zip = 'ZIP'
}

export type RentrakDemographic = {
  __typename?: 'RentrakDemographic';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaPlanRentrakDemographics?: Maybe<Array<Maybe<MediaPlanRentrakDemographic>>>;
  name?: Maybe<Scalars['String']>;
  ratings?: Maybe<Array<Maybe<Rating>>>;
  rentrakDemographicId: Scalars['Int'];
  targets?: Maybe<Array<Maybe<Target>>>;
  universe: Scalars['Int'];
};

export type RentrakDemographicFilterInput = {
  and?: InputMaybe<Array<RentrakDemographicFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaPlanRentrakDemographics?: InputMaybe<ListFilterInputTypeOfMediaPlanRentrakDemographicFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RentrakDemographicFilterInput>>;
  ratings?: InputMaybe<ListFilterInputTypeOfRatingFilterInput>;
  rentrakDemographicId?: InputMaybe<IntOperationFilterInput>;
  targets?: InputMaybe<ListFilterInputTypeOfTargetFilterInput>;
  universe?: InputMaybe<IntOperationFilterInput>;
};

export type RentrakNetwork = {
  __typename?: 'RentrakNetwork';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  multiplier: Scalars['Decimal'];
  name?: Maybe<Scalars['String']>;
  rentrakNetworkId: Scalars['Int'];
  viewSourceTypeLookup?: Maybe<Lookup>;
  viewSourceTypeLookupId: Scalars['Int'];
};

export type RentrakNetworkFilterInput = {
  and?: InputMaybe<Array<RentrakNetworkFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  multiplier?: InputMaybe<DecimalOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RentrakNetworkFilterInput>>;
  rentrakNetworkId?: InputMaybe<IntOperationFilterInput>;
  viewSourceTypeLookup?: InputMaybe<LookupFilterInput>;
  viewSourceTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type RentrakNetworkSortInput = {
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  multiplier?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rentrakNetworkId?: InputMaybe<SortEnumType>;
  viewSourceTypeLookup?: InputMaybe<LookupSortInput>;
  viewSourceTypeLookupId?: InputMaybe<SortEnumType>;
};

export type RentrakRatingAdInstance = {
  __typename?: 'RentrakRatingAdInstance';
  adInstance?: Maybe<AdInstance>;
  adInstanceId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Rating>;
  ratingId: Scalars['Int'];
  rentrakRatingAdInstanceId: Scalars['Int'];
};

export type RentrakRatingAdInstanceFilterInput = {
  adInstance?: InputMaybe<AdInstanceFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<RentrakRatingAdInstanceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RentrakRatingAdInstanceFilterInput>>;
  rating?: InputMaybe<RatingFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
  rentrakRatingAdInstanceId?: InputMaybe<IntOperationFilterInput>;
};

export type RentrakRatingSchedule = {
  __typename?: 'RentrakRatingSchedule';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Rating>;
  ratingId: Scalars['Int'];
  rentrakRatingScheduleId: Scalars['Int'];
  schedule?: Maybe<Schedule>;
  scheduleId: Scalars['Int'];
};

export type RentrakRatingScheduleFilterInput = {
  and?: InputMaybe<Array<RentrakRatingScheduleFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RentrakRatingScheduleFilterInput>>;
  rating?: InputMaybe<RatingFilterInput>;
  ratingId?: InputMaybe<IntOperationFilterInput>;
  rentrakRatingScheduleId?: InputMaybe<IntOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type RentrakTelecastDetail = {
  __typename?: 'RentrakTelecastDetail';
  audience: Scalars['Decimal'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  easternDateTime: Scalars['DateTime'];
  lastUpdatedAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  rentrakTelecastDetailId: Scalars['Int'];
  schedule?: Maybe<Schedule>;
  scheduleId: Scalars['Int'];
};

export type RentrakTelecastDetailFilterInput = {
  and?: InputMaybe<Array<RentrakTelecastDetailFilterInput>>;
  audience?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  easternDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RentrakTelecastDetailFilterInput>>;
  rentrakTelecastDetailId?: InputMaybe<IntOperationFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type Report = {
  __typename?: 'Report';
  appUserFavoriteReportPresets?: Maybe<Array<Maybe<AppUserFavoriteReportPreset>>>;
  appUserReportPresets?: Maybe<Array<Maybe<AppUserReportPreset>>>;
  appView?: Maybe<AppView>;
  appViewId: Scalars['Int'];
  defaultPresetVisibilityLookup?: Maybe<Lookup>;
  defaultPresetVisibilityLookupId: Scalars['Int'];
  displayName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  reportEditFields?: Maybe<Array<Maybe<ReportEditField>>>;
  reportExportFormats?: Maybe<Array<Maybe<ReportExportFormat>>>;
  reportFormats?: Maybe<Array<Maybe<ReportFormat>>>;
  reportId: Scalars['Int'];
  reportOptions?: Maybe<Array<Maybe<ReportOption>>>;
  reportPresets?: Maybe<Array<Maybe<ReportPreset>>>;
  targetLookup?: Maybe<Lookup>;
  targetLookupId: Scalars['Int'];
};

export type ReportControl = {
  __typename?: 'ReportControl';
  control?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  reportControlId: Scalars['Int'];
  reportControlSettings?: Maybe<Array<Maybe<ReportControlSetting>>>;
  reportOptions?: Maybe<Array<Maybe<ReportOption>>>;
};

export type ReportControlFilterInput = {
  and?: InputMaybe<Array<ReportControlFilterInput>>;
  control?: InputMaybe<StringOperationFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReportControlFilterInput>>;
  reportControlId?: InputMaybe<IntOperationFilterInput>;
  reportControlSettings?: InputMaybe<ListFilterInputTypeOfReportControlSettingFilterInput>;
  reportOptions?: InputMaybe<ListFilterInputTypeOfReportOptionFilterInput>;
};

export type ReportControlSetting = {
  __typename?: 'ReportControlSetting';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  propertyName?: Maybe<Scalars['String']>;
  reportControl?: Maybe<ReportControl>;
  reportControlId: Scalars['Int'];
  reportControlSettingId: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type ReportControlSettingFilterInput = {
  and?: InputMaybe<Array<ReportControlSettingFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ReportControlSettingFilterInput>>;
  propertyName?: InputMaybe<StringOperationFilterInput>;
  reportControl?: InputMaybe<ReportControlFilterInput>;
  reportControlId?: InputMaybe<IntOperationFilterInput>;
  reportControlSettingId?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type ReportEditField = {
  __typename?: 'ReportEditField';
  appViewField?: Maybe<AppViewField>;
  appViewFieldId: Scalars['Int'];
  control?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mode?: Maybe<Scalars['String']>;
  report?: Maybe<Report>;
  reportEditFieldId: Scalars['Int'];
  reportId: Scalars['Int'];
};

export type ReportEditFieldFilterInput = {
  and?: InputMaybe<Array<ReportEditFieldFilterInput>>;
  appViewField?: InputMaybe<AppViewFieldFilterInput>;
  appViewFieldId?: InputMaybe<IntOperationFilterInput>;
  control?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReportEditFieldFilterInput>>;
  report?: InputMaybe<ReportFilterInput>;
  reportEditFieldId?: InputMaybe<IntOperationFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
};

export enum ReportEnum {
  AccountingBasedReports = 'ACCOUNTING_BASED_REPORTS',
  AccountingClientSummary = 'ACCOUNTING_CLIENT_SUMMARY',
  AccountingClientSummary2012 = 'ACCOUNTING_CLIENT_SUMMARY2012',
  AccountsPayablePi = 'ACCOUNTS_PAYABLE_PI',
  AccruedMediaReport = 'ACCRUED_MEDIA_REPORT',
  AccruedMediaReport2 = 'ACCRUED_MEDIA_REPORT2',
  ActiveTrackingCodes = 'ACTIVE_TRACKING_CODES',
  AdsByCreative = 'ADS_BY_CREATIVE',
  AdInstanceLevel = 'AD_INSTANCE_LEVEL',
  AdInstanceWithDetail = 'AD_INSTANCE_WITH_DETAIL',
  AffidavitReports = 'AFFIDAVIT_REPORTS',
  Autosourcer = 'AUTOSOURCER',
  AutoScheduleMaintenance = 'AUTO_SCHEDULE_MAINTENANCE',
  AvgGrp = 'AVG_GRP',
  AxpiInvoiceExport = 'AXPI_INVOICE_EXPORT',
  AxCleared = 'AX_CLEARED',
  AxClientSummary = 'AX_CLIENT_SUMMARY',
  AxClientTieOut = 'AX_CLIENT_TIE_OUT',
  AxClientTieOut2012 = 'AX_CLIENT_TIE_OUT2012',
  AxDuplicationInvoiceExport = 'AX_DUPLICATION_INVOICE_EXPORT',
  AxExportDetails = 'AX_EXPORT_DETAILS',
  AxExportPrepay = 'AX_EXPORT_PREPAY',
  AxExportPrepayCleared = 'AX_EXPORT_PREPAY_CLEARED',
  AxNonPrepayInvoice = 'AX_NON_PREPAY_INVOICE',
  AxNonPrepayInvoiceHistory = 'AX_NON_PREPAY_INVOICE_HISTORY',
  AxPrepayInvoice = 'AX_PREPAY_INVOICE',
  AxPrePay = 'AX_PRE_PAY',
  AxRevenueRecognition = 'AX_REVENUE_RECOGNITION',
  AxRevenueRecognitionTest = 'AX_REVENUE_RECOGNITION_TEST',
  AxRevRecDuplication = 'AX_REV_REC_DUPLICATION',
  AxTimeSheets2012V2 = 'AX_TIME_SHEETS2012V2',
  BareMineralsTfn = 'BARE_MINERALS_TFN',
  Bookings = 'BOOKINGS',
  BookingList = 'BOOKING_LIST',
  BookingLookup = 'BOOKING_LOOKUP',
  BookingPaperwork = 'BOOKING_PAPERWORK',
  Budgets = 'BUDGETS',
  BudgetVersusActuals = 'BUDGET_VERSUS_ACTUALS',
  BuyersNumbers = 'BUYERS_NUMBERS',
  BuyerBudgets = 'BUYER_BUDGETS',
  ByStationByClient = 'BY_STATION_BY_CLIENT',
  Campaigns = 'CAMPAIGNS',
  CampaignList = 'CAMPAIGN_LIST',
  CampaignListForBuyerBudgetMaintenance = 'CAMPAIGN_LIST_FOR_BUYER_BUDGET_MAINTENANCE',
  CampaignLocalBudgets = 'CAMPAIGN_LOCAL_BUDGETS',
  CannellaResultsCsv = 'CANNELLA_RESULTS_CSV',
  CbsReportX = 'CBS_REPORT_X',
  ClientsAndAgencies = 'CLIENTS_AND_AGENCIES',
  ClientApprovals = 'CLIENT_APPROVALS',
  ClientCreditLimits = 'CLIENT_CREDIT_LIMITS',
  ClientInvoices = 'CLIENT_INVOICES',
  ClosedCaptioning = 'CLOSED_CAPTIONING',
  CommissionsCurrent = 'COMMISSIONS_CURRENT',
  CommissionByDayByMediaType = 'COMMISSION_BY_DAY_BY_MEDIA_TYPE',
  ContactLookups = 'CONTACT_LOOKUPS',
  CostAndRevenue = 'COST_AND_REVENUE',
  Customizations = 'CUSTOMIZATIONS',
  DailyBudgetReport = 'DAILY_BUDGET_REPORT',
  DailyCallProjections = 'DAILY_CALL_PROJECTIONS',
  DailyCallProjectionsCustom = 'DAILY_CALL_PROJECTIONS_CUSTOM',
  DailyCallProjectionsWithPrelogs = 'DAILY_CALL_PROJECTIONS_WITH_PRELOGS',
  DailyClearanceEstimates = 'DAILY_CLEARANCE_ESTIMATES',
  DailyClearanceEstimatesBroadcast = 'DAILY_CLEARANCE_ESTIMATES_BROADCAST',
  DailyTfnAssignments = 'DAILY_TFN_ASSIGNMENTS',
  DashboardBudget = 'DASHBOARD_BUDGET',
  DayPartEstimatedAndActual = 'DAY_PART_ESTIMATED_AND_ACTUAL',
  Detections = 'DETECTIONS',
  Discreps = 'DISCREPS',
  DubsSentReport = 'DUBS_SENT_REPORT',
  EstimatedClearance = 'ESTIMATED_CLEARANCE',
  EstimatedClearanceSf = 'ESTIMATED_CLEARANCE_SF',
  ExpenseByMedia = 'EXPENSE_BY_MEDIA',
  ExportResultsFile = 'EXPORT_RESULTS_FILE',
  ExRegionLookupZips = 'EX_REGION_LOOKUP_ZIPS',
  FileImports = 'FILE_IMPORTS',
  HistoricalComparisonSnapshot = 'HISTORICAL_COMPARISON_SNAPSHOT',
  HumanaSpendExport = 'HUMANA_SPEND_EXPORT',
  ImsDetectionLookups = 'IMS_DETECTION_LOOKUPS',
  Invoices = 'INVOICES',
  InvoiceLogFileLines = 'INVOICE_LOG_FILE_LINES',
  InvoiceLookupDuplication = 'INVOICE_LOOKUP_DUPLICATION',
  JobInstances = 'JOB_INSTANCES',
  LeadsByWeekday = 'LEADS_BY_WEEKDAY',
  LeadsForMediaAndCpl = 'LEADS_FOR_MEDIA_AND_CPL',
  LfAudienceByMinute = 'LF_AUDIENCE_BY_MINUTE',
  ListingTitles = 'LISTING_TITLES',
  LoggerMapping = 'LOGGER_MAPPING',
  LogEntries = 'LOG_ENTRIES',
  LogStatus = 'LOG_STATUS',
  LookupInvoices = 'LOOKUP_INVOICES',
  MarketPenetration = 'MARKET_PENETRATION',
  MasterAndSubmasterInventory = 'MASTER_AND_SUBMASTER_INVENTORY',
  MaterialConsumption = 'MATERIAL_CONSUMPTION',
  MediaAvails = 'MEDIA_AVAILS',
  MediaClearanceForCommission = 'MEDIA_CLEARANCE_FOR_COMMISSION',
  MediaIndex = 'MEDIA_INDEX',
  MediaOutlet = 'MEDIA_OUTLET',
  MediaOutletContacts = 'MEDIA_OUTLET_CONTACTS',
  MediaPlanDuplicationCostEstimateDetail = 'MEDIA_PLAN_DUPLICATION_COST_ESTIMATE_DETAIL',
  MediaPlanExportStyle = 'MEDIA_PLAN_EXPORT_STYLE',
  MediaRequests = 'MEDIA_REQUESTS',
  MediaRequestLookup = 'MEDIA_REQUEST_LOOKUP',
  MercuryMediaExport = 'MERCURY_MEDIA_EXPORT',
  MissingLogs2 = 'MISSING_LOGS2',
  MissingLogsOld = 'MISSING_LOGS_OLD',
  MissingProjectCodes = 'MISSING_PROJECT_CODES',
  MoveOrders = 'MOVE_ORDERS',
  NielsenManualEntry = 'NIELSEN_MANUAL_ENTRY',
  NielsenRatingLookup = 'NIELSEN_RATING_LOOKUP',
  OrderSummary = 'ORDER_SUMMARY',
  PaidBlocksByMediaOutlet = 'PAID_BLOCKS_BY_MEDIA_OUTLET',
  PiLeadsByWeek = 'PI_LEADS_BY_WEEK',
  PodAnalysis = 'POD_ANALYSIS',
  PositecCore = 'POSITEC_CORE',
  PositecResponse = 'POSITEC_RESPONSE',
  Prelogs = 'PRELOGS',
  PrelogsForEstimation = 'PRELOGS_FOR_ESTIMATION',
  PrelogGrid = 'PRELOG_GRID',
  PrintProductionSpecs = 'PRINT_PRODUCTION_SPECS',
  ProcessMonitor = 'PROCESS_MONITOR',
  ProductPartnersCatalystExport = 'PRODUCT_PARTNERS_CATALYST_EXPORT',
  QuarterlyExtension = 'QUARTERLY_EXTENSION',
  RateComparison = 'RATE_COMPARISON',
  RecordedNonPrepaySchedules = 'RECORDED_NON_PREPAY_SCHEDULES',
  RegionLookupMediaOutlets = 'REGION_LOOKUP_MEDIA_OUTLETS',
  RegionLookupZips = 'REGION_LOOKUP_ZIPS',
  RegionLookupZipsMap = 'REGION_LOOKUP_ZIPS_MAP',
  RejectedResults = 'REJECTED_RESULTS',
  RemitTo = 'REMIT_TO',
  RentrakRatings = 'RENTRAK_RATINGS',
  ReportPresetManagement = 'REPORT_PRESET_MANAGEMENT',
  ResourcingRequired = 'RESOURCING_REQUIRED',
  Results = 'RESULTS',
  ResultsSourcedOrUnsourced = 'RESULTS_SOURCED_OR_UNSOURCED',
  ResultAdInstanceProbability = 'RESULT_AD_INSTANCE_PROBABILITY',
  ResultAttributeAdInstance = 'RESULT_ATTRIBUTE_AD_INSTANCE',
  ResultLookup = 'RESULT_LOOKUP',
  RevisionRequests = 'REVISION_REQUESTS',
  RiskBuy = 'RISK_BUY',
  RiskExposure = 'RISK_EXPOSURE',
  ScheduleForRevisionRequests = 'SCHEDULE_FOR_REVISION_REQUESTS',
  ScheduleLevel = 'SCHEDULE_LEVEL',
  ServiceInstances = 'SERVICE_INSTANCES',
  SlidingScaleCommissions = 'SLIDING_SCALE_COMMISSIONS',
  SourcingAudit = 'SOURCING_AUDIT',
  SourcingList = 'SOURCING_LIST',
  SurveyNorms = 'SURVEY_NORMS',
  Tasks = 'TASKS',
  Temp = 'TEMP',
  TitleMaintenance = 'TITLE_MAINTENANCE',
  TollFreeNumberLookup = 'TOLL_FREE_NUMBER_LOOKUP',
  TommieCopperExport = 'TOMMIE_COPPER_EXPORT',
  TrackingCodeTest = 'TRACKING_CODE_TEST',
  TrackingServiceLines = 'TRACKING_SERVICE_LINES',
  TrafficRequests = 'TRAFFIC_REQUESTS',
  TribuneListings = 'TRIBUNE_LISTINGS',
  TribuneTrending = 'TRIBUNE_TRENDING',
  TvSchedules = 'TV_SCHEDULES',
  Unknown = 'UNKNOWN',
  VideoDistributionSummaryOfServices = 'VIDEO_DISTRIBUTION_SUMMARY_OF_SERVICES',
  WeeklyBudget = 'WEEKLY_BUDGET',
  WeeklyOrders = 'WEEKLY_ORDERS',
  WishLists = 'WISH_LISTS',
  YearlyBudget = 'YEARLY_BUDGET',
  ZeroOrders = 'ZERO_ORDERS'
}

export type ReportExportFormat = {
  __typename?: 'ReportExportFormat';
  appGridColumnUserSettings?: Maybe<Array<Maybe<AppGridColumnUserSetting>>>;
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  boxBackgroundColor?: Maybe<Scalars['String']>;
  boxDisplayName?: Maybe<Scalars['String']>;
  boxForegroundColor?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate: Scalars['DateTime'];
  fontLookup?: Maybe<Lookup>;
  fontLookupId?: Maybe<Scalars['Int']>;
  fontSize?: Maybe<Scalars['Int']>;
  glanceReportElements?: Maybe<Array<Maybe<GlanceReportElement>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mapBackgroundColor?: Maybe<Scalars['String']>;
  mapBreakpointTypeLookup?: Maybe<Lookup>;
  mapBreakpointTypeLookupId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pageOrientationLookup?: Maybe<Lookup>;
  pageOrientationLookupId?: Maybe<Scalars['Int']>;
  report?: Maybe<Report>;
  reportExportFormatBreakpoints?: Maybe<Array<Maybe<ReportExportFormatBreakpoint>>>;
  reportExportFormatFields?: Maybe<Array<Maybe<ReportExportFormatField>>>;
  reportExportFormatId: Scalars['Int'];
  reportExportFormatStyles?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportExportFormatTypeLookup?: Maybe<Lookup>;
  reportExportFormatTypeLookupId: Scalars['Int'];
  reportId: Scalars['Int'];
  reportPresets?: Maybe<Array<Maybe<ReportPreset>>>;
};

export type ReportExportFormatBreakpoint = {
  __typename?: 'ReportExportFormatBreakpoint';
  color?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  reportExportFormat?: Maybe<ReportExportFormat>;
  reportExportFormatBreakpointId: Scalars['Int'];
  reportExportFormatId: Scalars['Int'];
  value: Scalars['Decimal'];
};

export type ReportExportFormatBreakpointFilterInput = {
  and?: InputMaybe<Array<ReportExportFormatBreakpointFilterInput>>;
  color?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ReportExportFormatBreakpointFilterInput>>;
  reportExportFormat?: InputMaybe<ReportExportFormatFilterInput>;
  reportExportFormatBreakpointId?: InputMaybe<IntOperationFilterInput>;
  reportExportFormatId?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<DecimalOperationFilterInput>;
};

export type ReportExportFormatField = {
  __typename?: 'ReportExportFormatField';
  aggregateDecimalPlaces?: Maybe<Scalars['Int']>;
  aggregateFormatLookup?: Maybe<Lookup>;
  aggregateFormatLookupId?: Maybe<Scalars['Int']>;
  backgroundColor?: Maybe<Scalars['String']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId?: Maybe<Scalars['Int']>;
  createDate: Scalars['DateTime'];
  dataTypeLookup?: Maybe<Lookup>;
  dataTypeLookupId?: Maybe<Scalars['Int']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  denominatorAppField?: Maybe<AppField>;
  denominatorAppFieldId?: Maybe<Scalars['Int']>;
  displayAsPercentOfGrandTotal: Scalars['Boolean'];
  displayName?: Maybe<Scalars['String']>;
  fontBold: Scalars['Boolean'];
  fontLookup?: Maybe<Lookup>;
  fontLookupId?: Maybe<Scalars['Int']>;
  fontSize?: Maybe<Scalars['Int']>;
  fontUnderline: Scalars['Boolean'];
  foregroundColor?: Maybe<Scalars['String']>;
  formatLookup?: Maybe<Lookup>;
  formatLookupId?: Maybe<Scalars['Int']>;
  formula?: Maybe<Scalars['String']>;
  formulaTypeLookup?: Maybe<Lookup>;
  formulaTypeLookupId?: Maybe<Scalars['Int']>;
  horizontalAlignmentLookup?: Maybe<Lookup>;
  horizontalAlignmentLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  marketContributionAnalysisDateReportExportFormatFields?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  marketContributionAnalysisMediaValueReportExportFormatFields?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  name?: Maybe<Scalars['String']>;
  numeratorAppField?: Maybe<AppField>;
  numeratorAppFieldId?: Maybe<Scalars['Int']>;
  reportExportFormat?: Maybe<ReportExportFormat>;
  reportExportFormatFieldId: Scalars['Int'];
  reportExportFormatId: Scalars['Int'];
  reportExportFormatStyles?: Maybe<Array<Maybe<ReportExportFormatStyle>>>;
  reportFieldValueModifierLookup?: Maybe<Lookup>;
  reportFieldValueModifierLookupId?: Maybe<Scalars['Int']>;
  showNullAggregate: Scalars['Boolean'];
  sort: Scalars['Int'];
  subtotal: Scalars['Boolean'];
  total: Scalars['Boolean'];
  width?: Maybe<Scalars['Int']>;
};

export type ReportExportFormatFieldFilterInput = {
  aggregateDecimalPlaces?: InputMaybe<IntOperationFilterInput>;
  aggregateFormatLookup?: InputMaybe<LookupFilterInput>;
  aggregateFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<ReportExportFormatFieldFilterInput>>;
  backgroundColor?: InputMaybe<StringOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  dataTypeLookup?: InputMaybe<LookupFilterInput>;
  dataTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  decimalPlaces?: InputMaybe<IntOperationFilterInput>;
  denominatorAppField?: InputMaybe<AppFieldFilterInput>;
  denominatorAppFieldId?: InputMaybe<IntOperationFilterInput>;
  displayAsPercentOfGrandTotal?: InputMaybe<BooleanOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  fontBold?: InputMaybe<BooleanOperationFilterInput>;
  fontLookup?: InputMaybe<LookupFilterInput>;
  fontLookupId?: InputMaybe<IntOperationFilterInput>;
  fontSize?: InputMaybe<IntOperationFilterInput>;
  fontUnderline?: InputMaybe<BooleanOperationFilterInput>;
  foregroundColor?: InputMaybe<StringOperationFilterInput>;
  formatLookup?: InputMaybe<LookupFilterInput>;
  formatLookupId?: InputMaybe<IntOperationFilterInput>;
  formula?: InputMaybe<StringOperationFilterInput>;
  formulaTypeLookup?: InputMaybe<LookupFilterInput>;
  formulaTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  horizontalAlignmentLookup?: InputMaybe<LookupFilterInput>;
  horizontalAlignmentLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  marketContributionAnalysisDateReportExportFormatFields?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  marketContributionAnalysisMediaValueReportExportFormatFields?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numeratorAppField?: InputMaybe<AppFieldFilterInput>;
  numeratorAppFieldId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ReportExportFormatFieldFilterInput>>;
  reportExportFormat?: InputMaybe<ReportExportFormatFilterInput>;
  reportExportFormatFieldId?: InputMaybe<IntOperationFilterInput>;
  reportExportFormatId?: InputMaybe<IntOperationFilterInput>;
  reportExportFormatStyles?: InputMaybe<ListFilterInputTypeOfReportExportFormatStyleFilterInput>;
  reportFieldValueModifierLookup?: InputMaybe<LookupFilterInput>;
  reportFieldValueModifierLookupId?: InputMaybe<IntOperationFilterInput>;
  showNullAggregate?: InputMaybe<BooleanOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  subtotal?: InputMaybe<BooleanOperationFilterInput>;
  total?: InputMaybe<BooleanOperationFilterInput>;
  width?: InputMaybe<IntOperationFilterInput>;
};

export type ReportExportFormatFilterInput = {
  and?: InputMaybe<Array<ReportExportFormatFilterInput>>;
  appGridColumnUserSettings?: InputMaybe<ListFilterInputTypeOfAppGridColumnUserSettingFilterInput>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  boxBackgroundColor?: InputMaybe<StringOperationFilterInput>;
  boxDisplayName?: InputMaybe<StringOperationFilterInput>;
  boxForegroundColor?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  fontLookup?: InputMaybe<LookupFilterInput>;
  fontLookupId?: InputMaybe<IntOperationFilterInput>;
  fontSize?: InputMaybe<IntOperationFilterInput>;
  glanceReportElements?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mapBackgroundColor?: InputMaybe<StringOperationFilterInput>;
  mapBreakpointTypeLookup?: InputMaybe<LookupFilterInput>;
  mapBreakpointTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReportExportFormatFilterInput>>;
  pageOrientationLookup?: InputMaybe<LookupFilterInput>;
  pageOrientationLookupId?: InputMaybe<IntOperationFilterInput>;
  report?: InputMaybe<ReportFilterInput>;
  reportExportFormatBreakpoints?: InputMaybe<ListFilterInputTypeOfReportExportFormatBreakpointFilterInput>;
  reportExportFormatFields?: InputMaybe<ListFilterInputTypeOfReportExportFormatFieldFilterInput>;
  reportExportFormatId?: InputMaybe<IntOperationFilterInput>;
  reportExportFormatStyles?: InputMaybe<ListFilterInputTypeOfReportExportFormatStyleFilterInput>;
  reportExportFormatTypeLookup?: InputMaybe<LookupFilterInput>;
  reportExportFormatTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
  reportPresets?: InputMaybe<ListFilterInputTypeOfReportPresetFilterInput>;
};

export type ReportExportFormatSortInput = {
  appUser?: InputMaybe<AppUserSortInput>;
  appUserId?: InputMaybe<SortEnumType>;
  boxBackgroundColor?: InputMaybe<SortEnumType>;
  boxDisplayName?: InputMaybe<SortEnumType>;
  boxForegroundColor?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createDate?: InputMaybe<SortEnumType>;
  fontLookup?: InputMaybe<LookupSortInput>;
  fontLookupId?: InputMaybe<SortEnumType>;
  fontSize?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mapBackgroundColor?: InputMaybe<SortEnumType>;
  mapBreakpointTypeLookup?: InputMaybe<LookupSortInput>;
  mapBreakpointTypeLookupId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  pageOrientationLookup?: InputMaybe<LookupSortInput>;
  pageOrientationLookupId?: InputMaybe<SortEnumType>;
  report?: InputMaybe<ReportSortInput>;
  reportExportFormatId?: InputMaybe<SortEnumType>;
  reportExportFormatTypeLookup?: InputMaybe<LookupSortInput>;
  reportExportFormatTypeLookupId?: InputMaybe<SortEnumType>;
  reportId?: InputMaybe<SortEnumType>;
};

export type ReportExportFormatStyle = {
  __typename?: 'ReportExportFormatStyle';
  evaluationAppField?: Maybe<AppField>;
  evaluationAppFieldId?: Maybe<Scalars['Int']>;
  evaluationMatchValue?: Maybe<Scalars['String']>;
  evaluationOperatorTypeLookup?: Maybe<Lookup>;
  evaluationOperatorTypeLookupId?: Maybe<Scalars['Int']>;
  formatValue?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  reportExportFormat?: Maybe<ReportExportFormat>;
  reportExportFormatId: Scalars['Int'];
  reportExportFormatStyleId: Scalars['Int'];
  reportStyleFormatTypeLookup?: Maybe<Lookup>;
  reportStyleFormatTypeLookupId: Scalars['Int'];
  sort: Scalars['Int'];
  targetReportExportFormatField?: Maybe<ReportExportFormatField>;
  targetReportExportFormatFieldId?: Maybe<Scalars['Int']>;
};

export type ReportExportFormatStyleFilterInput = {
  and?: InputMaybe<Array<ReportExportFormatStyleFilterInput>>;
  evaluationAppField?: InputMaybe<AppFieldFilterInput>;
  evaluationAppFieldId?: InputMaybe<IntOperationFilterInput>;
  evaluationMatchValue?: InputMaybe<StringOperationFilterInput>;
  evaluationOperatorTypeLookup?: InputMaybe<LookupFilterInput>;
  evaluationOperatorTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  formatValue?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ReportExportFormatStyleFilterInput>>;
  reportExportFormat?: InputMaybe<ReportExportFormatFilterInput>;
  reportExportFormatId?: InputMaybe<IntOperationFilterInput>;
  reportExportFormatStyleId?: InputMaybe<IntOperationFilterInput>;
  reportStyleFormatTypeLookup?: InputMaybe<LookupFilterInput>;
  reportStyleFormatTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  targetReportExportFormatField?: InputMaybe<ReportExportFormatFieldFilterInput>;
  targetReportExportFormatFieldId?: InputMaybe<IntOperationFilterInput>;
};

export type ReportFilterInput = {
  and?: InputMaybe<Array<ReportFilterInput>>;
  appUserFavoriteReportPresets?: InputMaybe<ListFilterInputTypeOfAppUserFavoriteReportPresetFilterInput>;
  appUserReportPresets?: InputMaybe<ListFilterInputTypeOfAppUserReportPresetFilterInput>;
  appView?: InputMaybe<AppViewFilterInput>;
  appViewId?: InputMaybe<IntOperationFilterInput>;
  defaultPresetVisibilityLookup?: InputMaybe<LookupFilterInput>;
  defaultPresetVisibilityLookupId?: InputMaybe<IntOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReportFilterInput>>;
  reportEditFields?: InputMaybe<ListFilterInputTypeOfReportEditFieldFilterInput>;
  reportExportFormats?: InputMaybe<ListFilterInputTypeOfReportExportFormatFilterInput>;
  reportFormats?: InputMaybe<ListFilterInputTypeOfReportFormatFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
  reportOptions?: InputMaybe<ListFilterInputTypeOfReportOptionFilterInput>;
  reportPresets?: InputMaybe<ListFilterInputTypeOfReportPresetFilterInput>;
  targetLookup?: InputMaybe<LookupFilterInput>;
  targetLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type ReportFormat = {
  __typename?: 'ReportFormat';
  hidden: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  report?: Maybe<Report>;
  reportFormatId: Scalars['Int'];
  reportFormatLookup?: Maybe<Lookup>;
  reportFormatLookupId: Scalars['Int'];
  reportId: Scalars['Int'];
  reportPresets?: Maybe<Array<Maybe<ReportPreset>>>;
  sort: Scalars['Int'];
};

export type ReportFormatFilterInput = {
  and?: InputMaybe<Array<ReportFormatFilterInput>>;
  hidden?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ReportFormatFilterInput>>;
  report?: InputMaybe<ReportFilterInput>;
  reportFormatId?: InputMaybe<IntOperationFilterInput>;
  reportFormatLookup?: InputMaybe<LookupFilterInput>;
  reportFormatLookupId?: InputMaybe<IntOperationFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
  reportPresets?: InputMaybe<ListFilterInputTypeOfReportPresetFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type ReportFormatSortInput = {
  hidden?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  report?: InputMaybe<ReportSortInput>;
  reportFormatId?: InputMaybe<SortEnumType>;
  reportFormatLookup?: InputMaybe<LookupSortInput>;
  reportFormatLookupId?: InputMaybe<SortEnumType>;
  reportId?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
};

export type ReportOption = {
  __typename?: 'ReportOption';
  advanced: Scalars['Boolean'];
  defaultValue?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  overrideLabel?: Maybe<Scalars['String']>;
  processingPriority: Scalars['Int'];
  report?: Maybe<Report>;
  reportControl?: Maybe<ReportControl>;
  reportControlId: Scalars['Int'];
  reportId: Scalars['Int'];
  reportOptionId: Scalars['Int'];
  reportPresetOptions?: Maybe<Array<Maybe<ReportPresetOption>>>;
  sort: Scalars['Int'];
};

export type ReportOptionFilterInput = {
  advanced?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<ReportOptionFilterInput>>;
  defaultValue?: InputMaybe<StringOperationFilterInput>;
  hidden?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ReportOptionFilterInput>>;
  overrideLabel?: InputMaybe<StringOperationFilterInput>;
  processingPriority?: InputMaybe<IntOperationFilterInput>;
  report?: InputMaybe<ReportFilterInput>;
  reportControl?: InputMaybe<ReportControlFilterInput>;
  reportControlId?: InputMaybe<IntOperationFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
  reportOptionId?: InputMaybe<IntOperationFilterInput>;
  reportPresetOptions?: InputMaybe<ListFilterInputTypeOfReportPresetOptionFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type ReportPreset = {
  __typename?: 'ReportPreset';
  appUser?: Maybe<AppUser>;
  appUserFavoriteReportPresets?: Maybe<Array<Maybe<AppUserFavoriteReportPreset>>>;
  appUserId?: Maybe<Scalars['Int']>;
  appUserReportPresets?: Maybe<Array<Maybe<AppUserReportPreset>>>;
  autoReportItems?: Maybe<Array<Maybe<AutoReportItem>>>;
  clients?: Maybe<Array<Maybe<Client>>>;
  commissionSlidingScales?: Maybe<Array<Maybe<CommissionSlidingScale>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate: Scalars['DateTime'];
  glanceReportElements?: Maybe<Array<Maybe<GlanceReportElement>>>;
  inactive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  marketContributionAnalyses?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
  report?: Maybe<Report>;
  reportExportFormat?: Maybe<ReportExportFormat>;
  reportExportFormatId: Scalars['Int'];
  reportFormat?: Maybe<ReportFormat>;
  reportFormatId: Scalars['Int'];
  reportId: Scalars['Int'];
  reportPresetFilterRecords?: Maybe<Array<Maybe<ReportPresetFilterRecord>>>;
  reportPresetGroupSorts?: Maybe<Array<Maybe<ReportPresetGroupSort>>>;
  reportPresetId: Scalars['Int'];
  reportPresetOptions?: Maybe<Array<Maybe<ReportPresetOption>>>;
  snapshots?: Maybe<Array<Maybe<Snapshot>>>;
  useAdvancedFilterEditor: Scalars['Boolean'];
};

export type ReportPresetFilterInput = {
  and?: InputMaybe<Array<ReportPresetFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserFavoriteReportPresets?: InputMaybe<ListFilterInputTypeOfAppUserFavoriteReportPresetFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  appUserReportPresets?: InputMaybe<ListFilterInputTypeOfAppUserReportPresetFilterInput>;
  autoReportItems?: InputMaybe<ListFilterInputTypeOfAutoReportItemFilterInput>;
  clients?: InputMaybe<ListFilterInputTypeOfClientFilterInput>;
  commissionSlidingScales?: InputMaybe<ListFilterInputTypeOfCommissionSlidingScaleFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  glanceReportElements?: InputMaybe<ListFilterInputTypeOfGlanceReportElementFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  marketContributionAnalyses?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReportPresetFilterInput>>;
  products?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  report?: InputMaybe<ReportFilterInput>;
  reportExportFormat?: InputMaybe<ReportExportFormatFilterInput>;
  reportExportFormatId?: InputMaybe<IntOperationFilterInput>;
  reportFormat?: InputMaybe<ReportFormatFilterInput>;
  reportFormatId?: InputMaybe<IntOperationFilterInput>;
  reportId?: InputMaybe<IntOperationFilterInput>;
  reportPresetFilterRecords?: InputMaybe<ListFilterInputTypeOfReportPresetFilterRecordFilterInput>;
  reportPresetGroupSorts?: InputMaybe<ListFilterInputTypeOfReportPresetGroupSortFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
  reportPresetOptions?: InputMaybe<ListFilterInputTypeOfReportPresetOptionFilterInput>;
  snapshots?: InputMaybe<ListFilterInputTypeOfSnapshotFilterInput>;
  useAdvancedFilterEditor?: InputMaybe<BooleanOperationFilterInput>;
};

export type ReportPresetFilterRecord = {
  __typename?: 'ReportPresetFilterRecord';
  appFilter?: Maybe<AppFilter>;
  appFilterId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  operatorTypeLookup?: Maybe<Lookup>;
  operatorTypeLookupId?: Maybe<Scalars['Int']>;
  reportPreset?: Maybe<ReportPreset>;
  reportPresetFilterId: Scalars['Int'];
  reportPresetId: Scalars['Int'];
  sort: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type ReportPresetFilterRecordFilterInput = {
  and?: InputMaybe<Array<ReportPresetFilterRecordFilterInput>>;
  appFilter?: InputMaybe<AppFilterFilterInput>;
  appFilterId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  operatorTypeLookup?: InputMaybe<LookupFilterInput>;
  operatorTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ReportPresetFilterRecordFilterInput>>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetFilterId?: InputMaybe<IntOperationFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type ReportPresetGroupSort = {
  __typename?: 'ReportPresetGroupSort';
  appField?: Maybe<AppField>;
  appFieldId: Scalars['Int'];
  displayGroupHeaderRow: Scalars['Boolean'];
  isGroup: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  pageBreakOnGroup: Scalars['Boolean'];
  reportPreset?: Maybe<ReportPreset>;
  reportPresetGroupSortId: Scalars['Int'];
  reportPresetId: Scalars['Int'];
  sort: Scalars['Int'];
  sortDirectionLookup?: Maybe<Lookup>;
  sortDirectionLookupId: Scalars['Int'];
};

export type ReportPresetGroupSortFilterInput = {
  and?: InputMaybe<Array<ReportPresetGroupSortFilterInput>>;
  appField?: InputMaybe<AppFieldFilterInput>;
  appFieldId?: InputMaybe<IntOperationFilterInput>;
  displayGroupHeaderRow?: InputMaybe<BooleanOperationFilterInput>;
  isGroup?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ReportPresetGroupSortFilterInput>>;
  pageBreakOnGroup?: InputMaybe<BooleanOperationFilterInput>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetGroupSortId?: InputMaybe<IntOperationFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  sortDirectionLookup?: InputMaybe<LookupFilterInput>;
  sortDirectionLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type ReportPresetOption = {
  __typename?: 'ReportPresetOption';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  reportOption?: Maybe<ReportOption>;
  reportOptionId: Scalars['Int'];
  reportPreset?: Maybe<ReportPreset>;
  reportPresetId: Scalars['Int'];
  reportPresetOptionId: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type ReportPresetOptionFilterInput = {
  and?: InputMaybe<Array<ReportPresetOptionFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ReportPresetOptionFilterInput>>;
  reportOption?: InputMaybe<ReportOptionFilterInput>;
  reportOptionId?: InputMaybe<IntOperationFilterInput>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
  reportPresetOptionId?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type ReportPresetSortInput = {
  appUser?: InputMaybe<AppUserSortInput>;
  appUserId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createDate?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  report?: InputMaybe<ReportSortInput>;
  reportExportFormat?: InputMaybe<ReportExportFormatSortInput>;
  reportExportFormatId?: InputMaybe<SortEnumType>;
  reportFormat?: InputMaybe<ReportFormatSortInput>;
  reportFormatId?: InputMaybe<SortEnumType>;
  reportId?: InputMaybe<SortEnumType>;
  reportPresetId?: InputMaybe<SortEnumType>;
  useAdvancedFilterEditor?: InputMaybe<SortEnumType>;
};

export type ReportSortInput = {
  appView?: InputMaybe<AppViewSortInput>;
  appViewId?: InputMaybe<SortEnumType>;
  defaultPresetVisibilityLookup?: InputMaybe<LookupSortInput>;
  defaultPresetVisibilityLookupId?: InputMaybe<SortEnumType>;
  displayName?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  reportId?: InputMaybe<SortEnumType>;
  targetLookup?: InputMaybe<LookupSortInput>;
  targetLookupId?: InputMaybe<SortEnumType>;
};

export type RequestPermissionChangeError = ArgumentError | RainError;

export type RequestPermissionChangeInput = {
  message: Scalars['String'];
  requestedPermission: RainOnePermission;
};

export type RequestPermissionChangePayload = {
  __typename?: 'RequestPermissionChangePayload';
  boolean?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<RequestPermissionChangeError>>;
};

export type ResponseMetaData = {
  __typename?: 'ResponseMetaData';
  calculateSize: Scalars['Int'];
  clone?: Maybe<ResponseMetaData>;
  currencyCode?: Maybe<Scalars['String']>;
  dataLossFromOtherRow: Scalars['Boolean'];
  emptyReason?: Maybe<Scalars['String']>;
  hasCurrencyCode: Scalars['Boolean'];
  hasEmptyReason: Scalars['Boolean'];
  hasSubjectToThresholding: Scalars['Boolean'];
  hasTimeZone: Scalars['Boolean'];
  schemaRestrictionResponse?: Maybe<SchemaRestrictionResponse>;
  subjectToThresholding: Scalars['Boolean'];
  timeZone?: Maybe<Scalars['String']>;
};

export enum RestrictedMetricType {
  CostData = 'COST_DATA',
  RevenueData = 'REVENUE_DATA',
  Unspecified = 'UNSPECIFIED'
}

export type Result = {
  __typename?: 'Result';
  adInstance?: Maybe<AdInstance>;
  adInstanceDetail?: Maybe<AdInstanceDetail>;
  adInstanceDetailId?: Maybe<Scalars['Int']>;
  adInstanceId?: Maybe<Scalars['Int']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  ani?: Maybe<Scalars['String']>;
  autoSource?: Maybe<AutoSource>;
  autoSourceId?: Maybe<Scalars['Int']>;
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  billingTypeLookup?: Maybe<Lookup>;
  billingTypeLookupId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  deadRecord: Scalars['Boolean'];
  duration?: Maybe<Scalars['TimeSpan']>;
  email?: Maybe<Scalars['String']>;
  etdate?: Maybe<Scalars['Date']>;
  fileImport?: Maybe<FileImport>;
  fileImportId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  inverseParentResult?: Maybe<Array<Maybe<Result>>>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  manuallySourced: Scalars['Boolean'];
  oldid?: Maybe<Scalars['Int']>;
  originalProductCode?: Maybe<Scalars['String']>;
  originalTrackingCode?: Maybe<Scalars['String']>;
  parentResult?: Maybe<Result>;
  parentResultId?: Maybe<Scalars['Long']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']>;
  resultDetails?: Maybe<Array<Maybe<ResultDetail>>>;
  resultId: Scalars['Long'];
  resultStagingId?: Maybe<Scalars['Int']>;
  script?: Maybe<Script>;
  scriptId?: Maybe<Scalars['Int']>;
  shipping?: Maybe<Scalars['Decimal']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeId?: Maybe<Scalars['Int']>;
  uniqueId?: Maybe<Scalars['String']>;
  utc: Scalars['DateTime'];
  zip?: Maybe<Scalars['String']>;
};

export type ResultDetail = {
  __typename?: 'ResultDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  creditAppUser?: Maybe<AppUser>;
  creditAppUserId?: Maybe<Scalars['Int']>;
  creditReason?: Maybe<Scalars['String']>;
  creditedUtc?: Maybe<Scalars['DateTime']>;
  isCredit: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  originalQuantity?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  result?: Maybe<Result>;
  resultDetailId: Scalars['Long'];
  resultId: Scalars['Long'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
};

export type ResultDetailFilterInput = {
  and?: InputMaybe<Array<ResultDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  creditAppUser?: InputMaybe<AppUserFilterInput>;
  creditAppUserId?: InputMaybe<IntOperationFilterInput>;
  creditReason?: InputMaybe<StringOperationFilterInput>;
  creditedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  isCredit?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ResultDetailFilterInput>>;
  originalQuantity?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  result?: InputMaybe<ResultFilterInput>;
  resultDetailId?: InputMaybe<LongOperationFilterInput>;
  resultId?: InputMaybe<LongOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
};

export type ResultFilterInput = {
  adInstance?: InputMaybe<AdInstanceFilterInput>;
  adInstanceDetail?: InputMaybe<AdInstanceDetailFilterInput>;
  adInstanceDetailId?: InputMaybe<IntOperationFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ResultFilterInput>>;
  ani?: InputMaybe<StringOperationFilterInput>;
  autoSource?: InputMaybe<AutoSourceFilterInput>;
  autoSourceId?: InputMaybe<IntOperationFilterInput>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  billingTypeLookup?: InputMaybe<LookupFilterInput>;
  billingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  deadRecord?: InputMaybe<BooleanOperationFilterInput>;
  duration?: InputMaybe<TimeSpanOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  etdate?: InputMaybe<DateOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  inverseParentResult?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  latitude?: InputMaybe<DecimalOperationFilterInput>;
  longitude?: InputMaybe<DecimalOperationFilterInput>;
  manuallySourced?: InputMaybe<BooleanOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ResultFilterInput>>;
  originalProductCode?: InputMaybe<StringOperationFilterInput>;
  originalTrackingCode?: InputMaybe<StringOperationFilterInput>;
  parentResult?: InputMaybe<ResultFilterInput>;
  parentResultId?: InputMaybe<LongOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  resultDetails?: InputMaybe<ListFilterInputTypeOfResultDetailFilterInput>;
  resultId?: InputMaybe<LongOperationFilterInput>;
  resultStagingId?: InputMaybe<IntOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  shipping?: InputMaybe<DecimalOperationFilterInput>;
  source?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
  uniqueId?: InputMaybe<StringOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type ResultManualEntryDetail = {
  __typename?: 'ResultManualEntryDetail';
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  resultManualEntryDetailId: Scalars['Int'];
  resultManualEntryPreset?: Maybe<ResultManualEntryPreset>;
  resultManualEntryPresetId: Scalars['Int'];
  script?: Maybe<Script>;
  scriptId: Scalars['Int'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeId: Scalars['Int'];
};

export type ResultManualEntryDetailFilterInput = {
  and?: InputMaybe<Array<ResultManualEntryDetailFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  displayOrder?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ResultManualEntryDetailFilterInput>>;
  resultManualEntryDetailId?: InputMaybe<IntOperationFilterInput>;
  resultManualEntryPreset?: InputMaybe<ResultManualEntryPresetFilterInput>;
  resultManualEntryPresetId?: InputMaybe<IntOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
};

export type ResultManualEntryPreset = {
  __typename?: 'ResultManualEntryPreset';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  resultManualEntryDetails?: Maybe<Array<Maybe<ResultManualEntryDetail>>>;
  resultManualEntryPresetId: Scalars['Int'];
};

export type ResultManualEntryPresetFilterInput = {
  and?: InputMaybe<Array<ResultManualEntryPresetFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ResultManualEntryPresetFilterInput>>;
  resultManualEntryDetails?: InputMaybe<ListFilterInputTypeOfResultManualEntryDetailFilterInput>;
  resultManualEntryPresetId?: InputMaybe<IntOperationFilterInput>;
};

export type ResultStaging = {
  __typename?: 'ResultStaging';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  ani?: Maybe<Scalars['String']>;
  billingTypeLookup?: Maybe<Lookup>;
  billingTypeLookupId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  ignoreInactiveTrackingCode: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  productCode?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']>;
  resultStagingDetails?: Maybe<Array<Maybe<ResultStagingDetail>>>;
  resultStagingId: Scalars['Int'];
  script?: Maybe<Script>;
  scriptId?: Maybe<Scalars['Int']>;
  shipping?: Maybe<Scalars['Decimal']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Decimal']>;
  time?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Decimal']>;
  trackingCode?: Maybe<Scalars['String']>;
  trackingCodeId?: Maybe<Scalars['Int']>;
  trackingCodeNavigation?: Maybe<TrackingCode>;
  uniqueId?: Maybe<Scalars['String']>;
  utc?: Maybe<Scalars['DateTime']>;
  zip?: Maybe<Scalars['String']>;
};

export type ResultStagingDetail = {
  __typename?: 'ResultStagingDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileImportId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  resultStaging?: Maybe<ResultStaging>;
  resultStagingDetailId: Scalars['Int'];
  resultStagingId: Scalars['Int'];
  sku?: Maybe<Scalars['String']>;
  skuNavigation?: Maybe<Sku>;
  skuid?: Maybe<Scalars['Int']>;
};

export type ResultStagingDetailFilterInput = {
  and?: InputMaybe<Array<ResultStagingDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ResultStagingDetailFilterInput>>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  resultStaging?: InputMaybe<ResultStagingFilterInput>;
  resultStagingDetailId?: InputMaybe<IntOperationFilterInput>;
  resultStagingId?: InputMaybe<IntOperationFilterInput>;
  sku?: InputMaybe<StringOperationFilterInput>;
  skuNavigation?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
};

export type ResultStagingFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ResultStagingFilterInput>>;
  ani?: InputMaybe<StringOperationFilterInput>;
  billingTypeLookup?: InputMaybe<LookupFilterInput>;
  billingTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<StringOperationFilterInput>;
  duration?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  ignoreInactiveTrackingCode?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  latitude?: InputMaybe<DecimalOperationFilterInput>;
  longitude?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<ResultStagingFilterInput>>;
  productCode?: InputMaybe<StringOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  resultStagingDetails?: InputMaybe<ListFilterInputTypeOfResultStagingDetailFilterInput>;
  resultStagingId?: InputMaybe<IntOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  shipping?: InputMaybe<DecimalOperationFilterInput>;
  source?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  time?: InputMaybe<StringOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  trackingCode?: InputMaybe<StringOperationFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeNavigation?: InputMaybe<TrackingCodeFilterInput>;
  uniqueId?: InputMaybe<StringOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type RevertFileError = ArgumentError | RainError;

export type RevertFileInput = {
  fileName: Scalars['String'];
};

export type RevertFilePayload = {
  __typename?: 'RevertFilePayload';
  csiFileDetails?: Maybe<Array<CsiFileDetails>>;
  errors?: Maybe<Array<RevertFileError>>;
};

export type Revision = {
  __typename?: 'Revision';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  revisionId: Scalars['Int'];
  revisionRecord?: Maybe<RevisionRecord>;
  revisionRecordId?: Maybe<Scalars['Int']>;
};

export type RevisionFilterInput = {
  and?: InputMaybe<Array<RevisionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  field?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  newValue?: InputMaybe<StringOperationFilterInput>;
  oldValue?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RevisionFilterInput>>;
  revisionId?: InputMaybe<IntOperationFilterInput>;
  revisionRecord?: InputMaybe<RevisionRecordFilterInput>;
  revisionRecordId?: InputMaybe<IntOperationFilterInput>;
};

export type RevisionGroup = {
  __typename?: 'RevisionGroup';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  confirmationFileReference?: Maybe<FileReference>;
  confirmationFileReferenceId?: Maybe<Scalars['Int']>;
  externalComment?: Maybe<Scalars['String']>;
  internalComment?: Maybe<Scalars['String']>;
  isCreatedByProcessor: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  originalFileReference?: Maybe<FileReference>;
  originalFileReferenceId?: Maybe<Scalars['Int']>;
  revisionDate: Scalars['DateTime'];
  revisionGroupId: Scalars['Int'];
  revisionRecords?: Maybe<Array<Maybe<RevisionRecord>>>;
  revisionUtc?: Maybe<Scalars['DateTime']>;
};

export type RevisionGroupFilterInput = {
  and?: InputMaybe<Array<RevisionGroupFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  confirmationFileReference?: InputMaybe<FileReferenceFilterInput>;
  confirmationFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  externalComment?: InputMaybe<StringOperationFilterInput>;
  internalComment?: InputMaybe<StringOperationFilterInput>;
  isCreatedByProcessor?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RevisionGroupFilterInput>>;
  originalFileReference?: InputMaybe<FileReferenceFilterInput>;
  originalFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  revisionDate?: InputMaybe<DateTimeOperationFilterInput>;
  revisionGroupId?: InputMaybe<IntOperationFilterInput>;
  revisionRecords?: InputMaybe<ListFilterInputTypeOfRevisionRecordFilterInput>;
  revisionUtc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RevisionRecord = {
  __typename?: 'RevisionRecord';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  recordId: Scalars['Long'];
  revisionGroup?: Maybe<RevisionGroup>;
  revisionGroupId: Scalars['Int'];
  revisionRecordId: Scalars['Int'];
  revisionType?: Maybe<RevisionType>;
  revisionTypeId: Scalars['Int'];
  revisions?: Maybe<Array<Maybe<Revision>>>;
  startDate?: Maybe<Scalars['Date']>;
};

export type RevisionRecordFilterInput = {
  and?: InputMaybe<Array<RevisionRecordFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RevisionRecordFilterInput>>;
  recordId?: InputMaybe<LongOperationFilterInput>;
  revisionGroup?: InputMaybe<RevisionGroupFilterInput>;
  revisionGroupId?: InputMaybe<IntOperationFilterInput>;
  revisionRecordId?: InputMaybe<IntOperationFilterInput>;
  revisionType?: InputMaybe<RevisionTypeFilterInput>;
  revisionTypeId?: InputMaybe<IntOperationFilterInput>;
  revisions?: InputMaybe<ListFilterInputTypeOfRevisionFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
};

export type RevisionRequest = {
  __typename?: 'RevisionRequest';
  cancelAiring: Scalars['Boolean'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc: Scalars['DateTime'];
  logRequest: Scalars['Boolean'];
  newAd?: Maybe<Ad>;
  newAdId?: Maybe<Scalars['Int']>;
  newGrossRate?: Maybe<Scalars['Decimal']>;
  newNetRate?: Maybe<Scalars['Decimal']>;
  newProduct?: Maybe<Product>;
  newProductId?: Maybe<Scalars['Int']>;
  revisionRequestId: Scalars['Int'];
  task?: Maybe<Task>;
  taskId: Scalars['Int'];
};

export type RevisionRequestFilterInput = {
  and?: InputMaybe<Array<RevisionRequestFilterInput>>;
  cancelAiring?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logRequest?: InputMaybe<BooleanOperationFilterInput>;
  newAd?: InputMaybe<AdFilterInput>;
  newAdId?: InputMaybe<IntOperationFilterInput>;
  newGrossRate?: InputMaybe<DecimalOperationFilterInput>;
  newNetRate?: InputMaybe<DecimalOperationFilterInput>;
  newProduct?: InputMaybe<ProductFilterInput>;
  newProductId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RevisionRequestFilterInput>>;
  revisionRequestId?: InputMaybe<IntOperationFilterInput>;
  task?: InputMaybe<TaskFilterInput>;
  taskId?: InputMaybe<IntOperationFilterInput>;
};

export type RevisionType = {
  __typename?: 'RevisionType';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  revisionRecords?: Maybe<Array<Maybe<RevisionRecord>>>;
  revisionTypeId: Scalars['Int'];
};

export type RevisionTypeFilterInput = {
  and?: InputMaybe<Array<RevisionTypeFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RevisionTypeFilterInput>>;
  revisionRecords?: InputMaybe<ListFilterInputTypeOfRevisionRecordFilterInput>;
  revisionTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type RoleDepartment_ForDisplay = {
  __typename?: 'RoleDepartment_ForDisplay';
  departmentId: Scalars['Int'];
  departmentName: Scalars['String'];
  id: Scalars['Int'];
  roleDepartmentId: Scalars['Int'];
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
};

export type Role_ForDisplay = {
  __typename?: 'Role_ForDisplay';
  activityId?: Maybe<Scalars['Int']>;
  activityName: Scalars['String'];
  aicpCode: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  primaryDepartmentId?: Maybe<Scalars['Int']>;
  primaryDepartmentName: Scalars['String'];
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
};

export type RvAdInstance = {
  __typename?: 'RvAdInstance';
  adInstanceId: Scalars['Int'];
  adName?: Maybe<Scalars['String']>;
  clientNet?: Maybe<Scalars['Decimal']>;
  feed?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<Scalars['Decimal']>;
  isHighProfile?: Maybe<Scalars['Boolean']>;
  isReducedAudienceFeed?: Maybe<Scalars['Boolean']>;
  isSiblingProductAiring?: Maybe<Scalars['Boolean']>;
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaValue?: Maybe<Scalars['Decimal']>;
  parentAdId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']>;
  roviProgramTitle?: Maybe<Scalars['String']>;
  rvadInstanceId: Scalars['Int'];
  splitFeedPercent?: Maybe<Scalars['Decimal']>;
  tapeLength?: Maybe<Scalars['String']>;
  utc?: Maybe<Scalars['DateTime']>;
  viewersValue?: Maybe<Scalars['Decimal']>;
};

export type RvAdInstanceFilterInput = {
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  adName?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<RvAdInstanceFilterInput>>;
  clientNet?: InputMaybe<DecimalOperationFilterInput>;
  feed?: InputMaybe<StringOperationFilterInput>;
  grossAmount?: InputMaybe<DecimalOperationFilterInput>;
  isHighProfile?: InputMaybe<BooleanOperationFilterInput>;
  isReducedAudienceFeed?: InputMaybe<BooleanOperationFilterInput>;
  isSiblingProductAiring?: InputMaybe<BooleanOperationFilterInput>;
  mediaOutletName?: InputMaybe<StringOperationFilterInput>;
  mediaValue?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<RvAdInstanceFilterInput>>;
  parentAdId?: InputMaybe<IntOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  roviProgramTitle?: InputMaybe<StringOperationFilterInput>;
  rvadInstanceId?: InputMaybe<IntOperationFilterInput>;
  splitFeedPercent?: InputMaybe<DecimalOperationFilterInput>;
  tapeLength?: InputMaybe<StringOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
  viewersValue?: InputMaybe<DecimalOperationFilterInput>;
};

export type Rvresult = {
  __typename?: 'Rvresult';
  adInstanceDetailId?: Maybe<Scalars['Int']>;
  adInstanceId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  inverseParentResult?: Maybe<Array<Maybe<Rvresult>>>;
  parentResult?: Maybe<Rvresult>;
  parentResultId?: Maybe<Scalars['Long']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']>;
  rvresultDetails?: Maybe<Array<Maybe<RvresultDetail>>>;
  rvresultId: Scalars['Long'];
  script?: Maybe<Script>;
  scriptId?: Maybe<Scalars['Int']>;
  utc: Scalars['DateTime'];
};

export type RvresultDetail = {
  __typename?: 'RvresultDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  rvresult?: Maybe<Rvresult>;
  rvresultDetailId: Scalars['Long'];
  rvresultId: Scalars['Long'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
};

export type RvresultDetailFilterInput = {
  and?: InputMaybe<Array<RvresultDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RvresultDetailFilterInput>>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  rvresult?: InputMaybe<RvresultFilterInput>;
  rvresultDetailId?: InputMaybe<LongOperationFilterInput>;
  rvresultId?: InputMaybe<LongOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
};

export type RvresultFilterInput = {
  adInstanceDetailId?: InputMaybe<IntOperationFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<RvresultFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  inverseParentResult?: InputMaybe<ListFilterInputTypeOfRvresultFilterInput>;
  or?: InputMaybe<Array<RvresultFilterInput>>;
  parentResult?: InputMaybe<RvresultFilterInput>;
  parentResultId?: InputMaybe<LongOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<IntOperationFilterInput>;
  rvresultDetails?: InputMaybe<ListFilterInputTypeOfRvresultDetailFilterInput>;
  rvresultId?: InputMaybe<LongOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type SaveActivityCategoryError = ArgumentError | RainError;

export type SaveActivityCategoryInput = {
  activityCategoryId: Scalars['Int'];
  activityId: Scalars['Int'];
  categoryId: Scalars['String'];
};

export type SaveActivityCategoryPayload = {
  __typename?: 'SaveActivityCategoryPayload';
  activityCategory_ForDisplay?: Maybe<ActivityCategory_ForDisplay>;
  errors?: Maybe<Array<SaveActivityCategoryError>>;
};

export type SaveActivityError = ArgumentError | RainError;

export type SaveActivityInput = {
  activityId: Scalars['Int'];
  activityName: Scalars['String'];
};

export type SaveActivityPayload = {
  __typename?: 'SaveActivityPayload';
  activity_ForDisplay?: Maybe<Activity_ForDisplay>;
  errors?: Maybe<Array<SaveActivityError>>;
};

export type SaveGeneralLedger_HardCostError = ArgumentError | RainError;

export type SaveGeneralLedger_HardCostInput = {
  glRecId: Scalars['Long'];
  hardCostId: Scalars['Int'];
  projId: Scalars['String'];
};

export type SaveGeneralLedger_HardCostPayload = {
  __typename?: 'SaveGeneralLedger_HardCostPayload';
  errors?: Maybe<Array<SaveGeneralLedger_HardCostError>>;
  projectEstimateHardCostActual?: Maybe<ProjectEstimateHardCostActual>;
};

export type SaveProjectEstimateApprovalError = ArgumentError | RainError;

export type SaveProjectEstimateApprovalInput = {
  isApproved: Scalars['Boolean'];
  projectEstimateVersionId: Scalars['Int'];
  rainOnePermission: RainOnePermission;
};

export type SaveProjectEstimateApprovalPayload = {
  __typename?: 'SaveProjectEstimateApprovalPayload';
  errors?: Maybe<Array<SaveProjectEstimateApprovalError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type SaveProjectEstimateSubTypeError = ArgumentError | RainError;

export type SaveProjectEstimateSubTypeInput = {
  isMultiProjectType?: InputMaybe<Scalars['Boolean']>;
  projectEstimateSubTypeId: Scalars['Int'];
  projectEstimateSubTypeName: Scalars['String'];
  projectEstimateType: ProjectEstimateType;
};

export type SaveProjectEstimateSubTypePayload = {
  __typename?: 'SaveProjectEstimateSubTypePayload';
  errors?: Maybe<Array<SaveProjectEstimateSubTypeError>>;
  projectEstimateSubType_ForDisplay?: Maybe<ProjectEstimateSubType_ForDisplay>;
};

export type SaveRateCardError = ArgumentError | RainError;

export type SaveRateCardInput = {
  clientCode?: InputMaybe<Scalars['String']>;
  hardCostMarkupOverride?: InputMaybe<Scalars['Decimal']>;
  isActive: Scalars['Boolean'];
  isCompanyDefault: Scalars['Boolean'];
  rateCardId: Scalars['Int'];
  rateCardName: Scalars['String'];
  rateCardStartDate: Scalars['DateTime'];
};

export type SaveRateCardPayload = {
  __typename?: 'SaveRateCardPayload';
  errors?: Maybe<Array<SaveRateCardError>>;
  rateCard_ForDisplay?: Maybe<RateCard_ForDisplay>;
};

export type SaveRateError = ArgumentError | RainError;

export type SaveRateInput = {
  amount: Scalars['Decimal'];
  rateCardId: Scalars['Int'];
  rateId: Scalars['Int'];
  roleId: Scalars['Int'];
};

export type SaveRatePayload = {
  __typename?: 'SaveRatePayload';
  errors?: Maybe<Array<SaveRateError>>;
  rate_ForDisplay?: Maybe<Rate_ForDisplay>;
};

export type SaveRoleError = ArgumentError | RainError;

export type SaveRoleInput = {
  activityId: Scalars['Int'];
  aicpCode?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  primaryDepartmentId?: InputMaybe<Scalars['Int']>;
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
};

export type SaveRolePayload = {
  __typename?: 'SaveRolePayload';
  errors?: Maybe<Array<SaveRoleError>>;
  role_ForDisplay?: Maybe<Role_ForDisplay>;
};

export type SaveTimeValidationCatDepartmentError = ArgumentError | RainError;

export type SaveTimeValidationCatDepartmentInput = {
  categoryId: Scalars['String'];
  departmentGroupId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SaveTimeValidationCatDepartmentPayload = {
  __typename?: 'SaveTimeValidationCatDepartmentPayload';
  errors?: Maybe<Array<SaveTimeValidationCatDepartmentError>>;
  timesheetValidationCatDepartmentGroup_ForDisplay?: Maybe<TimesheetValidationCatDepartmentGroup_ForDisplay>;
};

export type SaveTimeValidationCatProjectGroupError = ArgumentError | RainError;

export type SaveTimeValidationCatProjectGroupInput = {
  categoryId: Scalars['String'];
  dataAreaId: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  projectGroup: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SaveTimeValidationCatProjectGroupPayload = {
  __typename?: 'SaveTimeValidationCatProjectGroupPayload';
  errors?: Maybe<Array<SaveTimeValidationCatProjectGroupError>>;
  timesheetValidationCatProjectGroup_ForDisplay?: Maybe<TimesheetValidationCatProjectGroup_ForDisplay>;
};

export type Schedule = {
  __typename?: 'Schedule';
  accountingLineNonPrepaySchedules?: Maybe<Array<Maybe<AccountingLineNonPrepaySchedule>>>;
  accountingLines?: Maybe<Array<Maybe<AccountingLine>>>;
  advancedTargetingLookup?: Maybe<Lookup>;
  advancedTargetingLookupId?: Maybe<Scalars['Int']>;
  affidavitReceivedDate?: Maybe<Scalars['DateTime']>;
  allowableDays?: Maybe<Scalars['String']>;
  amountPaid?: Maybe<Scalars['Decimal']>;
  amountToChargeClient?: Maybe<Scalars['Decimal']>;
  amountToPayStation?: Maybe<Scalars['Decimal']>;
  autoSchedule?: Maybe<AutoSchedule>;
  autoScheduleId?: Maybe<Scalars['Int']>;
  billingMetricLookup?: Maybe<Lookup>;
  billingMetricLookupId?: Maybe<Scalars['Int']>;
  booking?: Maybe<Booking>;
  bookingId: Scalars['Int'];
  buyTypeLookup?: Maybe<Lookup>;
  buyTypeLookupId: Scalars['Int'];
  buyerAppUser?: Maybe<AppUser>;
  buyerAppUserId: Scalars['Int'];
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  considerProgramSpecific?: Maybe<Scalars['Boolean']>;
  cpmamount?: Maybe<Scalars['Decimal']>;
  creditMemoFileReference?: Maybe<FileReference>;
  creditMemoFileReferenceId?: Maybe<Scalars['Int']>;
  datePaid?: Maybe<Scalars['DateTime']>;
  datoramaKey?: Maybe<Scalars['String']>;
  daypart?: Maybe<Daypart>;
  daypartId?: Maybe<Scalars['Int']>;
  deliveredImpressions?: Maybe<Scalars['Int']>;
  digitalPlacementDescriptionLookup?: Maybe<Lookup>;
  digitalPlacementDescriptionLookupId?: Maybe<Scalars['Int']>;
  digitalSpendInputLines?: Maybe<Array<Maybe<DigitalSpendInputLine>>>;
  dmlquantity?: Maybe<Scalars['Int']>;
  endDate: Scalars['Date'];
  endTime?: Maybe<Scalars['TimeSpan']>;
  endUtc: Scalars['DateTime'];
  estimatedValue?: Maybe<Scalars['Decimal']>;
  exchangeRate?: Maybe<Scalars['Decimal']>;
  externalComment?: Maybe<Scalars['String']>;
  ignoreDatoramaDiscrep?: Maybe<Scalars['Boolean']>;
  instancesBooked?: Maybe<Scalars['Int']>;
  internalComment?: Maybe<Scalars['String']>;
  internalStatusLookup?: Maybe<Lookup>;
  internalStatusLookupId?: Maybe<Scalars['Int']>;
  inverseProgenitorSchedule?: Maybe<Array<Maybe<Schedule>>>;
  invoiceSchedules?: Maybe<Array<Maybe<InvoiceSchedule>>>;
  isLockedByAccounting: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  leadCountsFileReference?: Maybe<FileReference>;
  leadCountsFileReferenceId?: Maybe<Scalars['Int']>;
  lfprogramInId?: Maybe<Scalars['Int']>;
  lfprogramOutId?: Maybe<Scalars['Int']>;
  lftribuneNameLookup?: Maybe<Lookup>;
  lftribuneNameLookupId?: Maybe<Scalars['Int']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  makeGoodFileReference?: Maybe<FileReference>;
  makeGoodFileReferenceId?: Maybe<Scalars['Int']>;
  mediaOutletOffering?: Maybe<MediaOutletOffering>;
  mediaOutletOfferingId?: Maybe<Scalars['Int']>;
  mediaScheduleDetailInstances?: Maybe<Array<Maybe<MediaScheduleDetailInstance>>>;
  mediaScheduleDetailInstancesId?: Maybe<Scalars['Int']>;
  mediaScheduleDetailInstancesNavigation?: Maybe<MediaScheduleDetailInstance>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  networkMediaOutlet?: Maybe<MediaOutlet>;
  networkMediaOutletId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['Int']>;
  paperworkSent?: Maybe<Scalars['Boolean']>;
  paymentReference?: Maybe<Scalars['String']>;
  piamountToChargeClient?: Maybe<Scalars['Decimal']>;
  piamountToPayStation?: Maybe<Scalars['Decimal']>;
  pibasedOnLookup?: Maybe<Lookup>;
  pibasedOnLookupId?: Maybe<Scalars['Int']>;
  placementPositionLookup?: Maybe<Lookup>;
  placementPositionLookupId?: Maybe<Scalars['Int']>;
  progenitorSchedule?: Maybe<Schedule>;
  progenitorScheduleId?: Maybe<Scalars['Int']>;
  prtadSizeLookup?: Maybe<Lookup>;
  prtadSizeLookupId?: Maybe<Scalars['Int']>;
  prtadTypeLookup?: Maybe<Lookup>;
  prtadTypeLookupId?: Maybe<Scalars['Int']>;
  prtartFileReference?: Maybe<FileReference>;
  prtartFileReferenceId?: Maybe<Scalars['Int']>;
  prtcirculation?: Maybe<Scalars['Int']>;
  prtcolor: Scalars['Boolean'];
  prtcolorLookup?: Maybe<Lookup>;
  prtcolorLookupId?: Maybe<Scalars['Int']>;
  prtissueDateLookup?: Maybe<Lookup>;
  prtissueDateLookupId?: Maybe<Scalars['Int']>;
  prtlayoutLookup?: Maybe<Lookup>;
  prtlayoutLookupId?: Maybe<Scalars['Int']>;
  prtlocationLookup?: Maybe<Lookup>;
  prtlocationLookupId?: Maybe<Scalars['Int']>;
  prtmaterialsDueDate?: Maybe<Scalars['Date']>;
  prtonSaleDate?: Maybe<Scalars['Date']>;
  prtpageNumber?: Maybe<Scalars['String']>;
  prtplacedSectionLookup?: Maybe<Lookup>;
  prtplacedSectionLookupId?: Maybe<Scalars['Int']>;
  prtsectionLookup?: Maybe<Lookup>;
  prtsectionLookupId?: Maybe<Scalars['Int']>;
  prtspaceDate?: Maybe<Scalars['Date']>;
  prtterritory?: Maybe<PrintTerritory>;
  prtterritoryId?: Maybe<Scalars['Int']>;
  rateTypeLookup?: Maybe<Lookup>;
  rateTypeLookupId: Scalars['Int'];
  ratingSchedules?: Maybe<Array<Maybe<RatingSchedule>>>;
  readTypeLookup?: Maybe<Lookup>;
  readTypeLookupId?: Maybe<Scalars['Int']>;
  receivedFinalExchangeRateAdjustment?: Maybe<Scalars['Boolean']>;
  rentrakRatingSchedules?: Maybe<Array<Maybe<RentrakRatingSchedule>>>;
  rentrakTelecastDetails?: Maybe<Array<Maybe<RentrakTelecastDetail>>>;
  scheduleAttributes?: Maybe<Array<Maybe<ScheduleAttribute>>>;
  scheduleCostAdjustments?: Maybe<Array<Maybe<ScheduleCostAdjustment>>>;
  scheduleFileImports?: Maybe<Array<Maybe<ScheduleFileImport>>>;
  scheduleFileReferences?: Maybe<Array<Maybe<ScheduleFileReference>>>;
  scheduleId: Scalars['Int'];
  sfestimatedInstances?: Maybe<Scalars['Int']>;
  sfpostLogFileReference?: Maybe<FileReference>;
  sfpostLogFileReferenceId?: Maybe<Scalars['Int']>;
  sfpreLogFileReference?: Maybe<FileReference>;
  sfpreLogFileReferenceId?: Maybe<Scalars['Int']>;
  sfprogramId?: Maybe<Scalars['Int']>;
  sfschedulePrelogStatusLookup?: Maybe<Lookup>;
  sfschedulePrelogStatusLookupId?: Maybe<Scalars['Int']>;
  spendAllocationSchemaLookup?: Maybe<Lookup>;
  spendAllocationSchemaLookupId?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  startTime?: Maybe<Scalars['TimeSpan']>;
  startUtc: Scalars['DateTime'];
  statusLookup?: Maybe<Lookup>;
  statusLookupId: Scalars['Int'];
  strategyLookup?: Maybe<Lookup>;
  strategyLookupId?: Maybe<Scalars['Int']>;
  tacticLookup?: Maybe<Lookup>;
  tacticLookupId?: Maybe<Scalars['Int']>;
  theoreticalGrossAmount?: Maybe<Scalars['Decimal']>;
  title?: Maybe<Title>;
  titleId?: Maybe<Scalars['Int']>;
  totalDeliveredImpressions?: Maybe<Scalars['Int']>;
  trackClicks: Scalars['Boolean'];
  trackingServicePaidByLookup?: Maybe<Lookup>;
  trackingServicePaidByLookupId?: Maybe<Scalars['Int']>;
  trackingServiceVendor?: Maybe<Vendor>;
  trackingServiceVendorId?: Maybe<Scalars['Int']>;
  trafficSentUtc?: Maybe<Scalars['DateTime']>;
  uniqueAdSchedules?: Maybe<Array<Maybe<UniqueAdSchedule>>>;
  waveCastOverrideProgramId?: Maybe<Scalars['Int']>;
  zzzAffidavitDisputed: Scalars['Boolean'];
};

export type ScheduleAttribute = {
  __typename?: 'ScheduleAttribute';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  schedule?: Maybe<Schedule>;
  scheduleAttributeId: Scalars['Int'];
  scheduleAttributeLookup?: Maybe<Lookup>;
  scheduleAttributeLookupId: Scalars['Int'];
  scheduleId: Scalars['Int'];
};

export type ScheduleAttributeFilterInput = {
  and?: InputMaybe<Array<ScheduleAttributeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleAttributeFilterInput>>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleAttributeId?: InputMaybe<IntOperationFilterInput>;
  scheduleAttributeLookup?: InputMaybe<LookupFilterInput>;
  scheduleAttributeLookupId?: InputMaybe<IntOperationFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type ScheduleCostAdjustment = {
  __typename?: 'ScheduleCostAdjustment';
  amountToChargeClient: Scalars['Decimal'];
  amountToPayStation: Scalars['Decimal'];
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costAdjustmentType?: Maybe<CostAdjustmentType>;
  costAdjustmentTypeId: Scalars['Int'];
  createDate: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  schedule?: Maybe<Schedule>;
  scheduleCostAdjustmentId: Scalars['Int'];
  scheduleId: Scalars['Int'];
};

export type ScheduleCostAdjustmentFilterInput = {
  amountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  amountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ScheduleCostAdjustmentFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costAdjustmentType?: InputMaybe<CostAdjustmentTypeFilterInput>;
  costAdjustmentTypeId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleCostAdjustmentFilterInput>>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleCostAdjustmentId?: InputMaybe<IntOperationFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type ScheduleEntity = {
  __typename?: 'ScheduleEntity';
  advancedTargetingLookupID?: Maybe<Scalars['Int']>;
  affidavitReceivedDate?: Maybe<Scalars['DateTime']>;
  allowableDays?: Maybe<Scalars['String']>;
  amountPaid?: Maybe<Scalars['Decimal']>;
  amountToChargeClient?: Maybe<Scalars['Decimal']>;
  amountToPayStation?: Maybe<Scalars['Decimal']>;
  anyPropertyValuesChanged: Scalars['Boolean'];
  autoScheduleID?: Maybe<Scalars['Int']>;
  bookingID: Scalars['Int'];
  buyTypeLookupID: Scalars['Int'];
  buyerAppUserID: Scalars['Int'];
  campaignID?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  considerProgramSpecific?: Maybe<Scalars['Boolean']>;
  cpmAmount?: Maybe<Scalars['Decimal']>;
  creditMemoFileReferenceID?: Maybe<Scalars['Int']>;
  datePaid?: Maybe<Scalars['DateTime']>;
  datoramaKey?: Maybe<Scalars['String']>;
  daypartID?: Maybe<Scalars['Int']>;
  deliveredImpressions?: Maybe<Scalars['Int']>;
  digitalPlacementDescriptionLookupID?: Maybe<Scalars['Int']>;
  dmlQuantity?: Maybe<Scalars['Int']>;
  endDate: Scalars['DateTime'];
  endTime?: Maybe<Scalars['TimeSpan']>;
  endUTC: Scalars['DateTime'];
  estimatedValue?: Maybe<Scalars['Decimal']>;
  exchangeRate?: Maybe<Scalars['Decimal']>;
  externalComment?: Maybe<Scalars['String']>;
  hasChanged: Scalars['Boolean'];
  ignoreDatoramaDiscrep?: Maybe<Scalars['Boolean']>;
  instancesBooked?: Maybe<Scalars['Int']>;
  internalStatusLookupID?: Maybe<Scalars['Int']>;
  isChangeTrackingEnabled: Scalars['Boolean'];
  isLockedByAccounting: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  leadCountsFileReferenceID?: Maybe<Scalars['Int']>;
  lfProgramInID?: Maybe<Scalars['Int']>;
  lfProgramOutID?: Maybe<Scalars['Int']>;
  lfTribuneNameLookupID?: Maybe<Scalars['Int']>;
  makeGoodFileReferenceID?: Maybe<Scalars['Int']>;
  mediaOutletOfferingID?: Maybe<Scalars['Int']>;
  networkMediaOutletID?: Maybe<Scalars['Int']>;
  oldTableName?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  packageID?: Maybe<Scalars['Int']>;
  paperworkSent?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<EntityBase>;
  paymentReference?: Maybe<Scalars['String']>;
  piAmountToChargeClient?: Maybe<Scalars['Decimal']>;
  piAmountToPayStation?: Maybe<Scalars['Decimal']>;
  piBasedOnLookupID?: Maybe<Scalars['Int']>;
  placementPositionLookupID?: Maybe<Scalars['Int']>;
  progenitorScheduleID?: Maybe<Scalars['Int']>;
  propertyValueHasChanged: Scalars['Boolean'];
  prtAdSizeLookupID?: Maybe<Scalars['Int']>;
  prtAdTypeLookupID?: Maybe<Scalars['Int']>;
  prtArtFileReferenceID?: Maybe<Scalars['Int']>;
  prtCirculation?: Maybe<Scalars['Int']>;
  prtColor: Scalars['Boolean'];
  prtColorLookupID?: Maybe<Scalars['Int']>;
  prtIssueDateLookupID?: Maybe<Scalars['Int']>;
  prtLayoutLookupID?: Maybe<Scalars['Int']>;
  prtLocationLookupID?: Maybe<Scalars['Int']>;
  prtMaterialsDueDate?: Maybe<Scalars['DateTime']>;
  prtOnSaleDate?: Maybe<Scalars['DateTime']>;
  prtPageNumber?: Maybe<Scalars['String']>;
  prtPlacedSectionLookupID?: Maybe<Scalars['Int']>;
  prtSectionLookupID?: Maybe<Scalars['Int']>;
  prtSpaceDate?: Maybe<Scalars['DateTime']>;
  prtTerritoryID?: Maybe<Scalars['Int']>;
  rateTypeLookupID: Scalars['Int'];
  readTypeLookupID?: Maybe<Scalars['Int']>;
  receivedFinalExchangeRateAdjustment?: Maybe<Scalars['Boolean']>;
  scheduleID: Scalars['Int'];
  sfEstimatedInstances?: Maybe<Scalars['Int']>;
  sfPostLogFileReferenceID?: Maybe<Scalars['Int']>;
  sfPreLogFileReferenceID?: Maybe<Scalars['Int']>;
  sfProgramID?: Maybe<Scalars['Int']>;
  sfSchedulePrelogStatusLookupID?: Maybe<Scalars['Int']>;
  spendAllocationSchemaLookupID?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  startTime?: Maybe<Scalars['TimeSpan']>;
  startUTC: Scalars['DateTime'];
  statusLookupID: Scalars['Int'];
  strategyLookupID?: Maybe<Scalars['Int']>;
  tacticLookupID?: Maybe<Scalars['Int']>;
  theoreticalGrossAmount?: Maybe<Scalars['Decimal']>;
  titleID?: Maybe<Scalars['Int']>;
  totalDeliveredImpressions?: Maybe<Scalars['Int']>;
  trackClicks: Scalars['Boolean'];
  trackingServicePaidByLookupID?: Maybe<Scalars['Int']>;
  trackingServiceVendorID?: Maybe<Scalars['Int']>;
  waveCastOverrideProgramID?: Maybe<Scalars['Int']>;
  zzzAffidavitDisputed: Scalars['Boolean'];
};


export type ScheduleEntityPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type ScheduleFileImport = {
  __typename?: 'ScheduleFileImport';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  schedule?: Maybe<Schedule>;
  scheduleFileImportId: Scalars['Int'];
  scheduleId: Scalars['Int'];
};

export type ScheduleFileImportFilterInput = {
  and?: InputMaybe<Array<ScheduleFileImportFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleFileImportFilterInput>>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleFileImportId?: InputMaybe<IntOperationFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type ScheduleFileReference = {
  __typename?: 'ScheduleFileReference';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  schedule?: Maybe<Schedule>;
  scheduleDocumentTypeLookup?: Maybe<Lookup>;
  scheduleDocumentTypeLookupId: Scalars['Int'];
  scheduleFileReferenceId: Scalars['Int'];
  scheduleId: Scalars['Int'];
};

export type ScheduleFileReferenceFilterInput = {
  and?: InputMaybe<Array<ScheduleFileReferenceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleFileReferenceFilterInput>>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleDocumentTypeLookup?: InputMaybe<LookupFilterInput>;
  scheduleDocumentTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  scheduleFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
};

export type ScheduleFilterInput = {
  accountingLineNonPrepaySchedules?: InputMaybe<ListFilterInputTypeOfAccountingLineNonPrepayScheduleFilterInput>;
  accountingLines?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  advancedTargetingLookup?: InputMaybe<LookupFilterInput>;
  advancedTargetingLookupId?: InputMaybe<IntOperationFilterInput>;
  affidavitReceivedDate?: InputMaybe<DateTimeOperationFilterInput>;
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  amountPaid?: InputMaybe<DecimalOperationFilterInput>;
  amountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  amountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ScheduleFilterInput>>;
  autoSchedule?: InputMaybe<AutoScheduleFilterInput>;
  autoScheduleId?: InputMaybe<IntOperationFilterInput>;
  billingMetricLookup?: InputMaybe<LookupFilterInput>;
  billingMetricLookupId?: InputMaybe<IntOperationFilterInput>;
  booking?: InputMaybe<BookingFilterInput>;
  bookingId?: InputMaybe<IntOperationFilterInput>;
  buyTypeLookup?: InputMaybe<LookupFilterInput>;
  buyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  buyerAppUser?: InputMaybe<AppUserFilterInput>;
  buyerAppUserId?: InputMaybe<IntOperationFilterInput>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  clicks?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  considerProgramSpecific?: InputMaybe<BooleanOperationFilterInput>;
  cpmamount?: InputMaybe<DecimalOperationFilterInput>;
  creditMemoFileReference?: InputMaybe<FileReferenceFilterInput>;
  creditMemoFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  datePaid?: InputMaybe<DateTimeOperationFilterInput>;
  datoramaKey?: InputMaybe<StringOperationFilterInput>;
  daypart?: InputMaybe<DaypartFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  deliveredImpressions?: InputMaybe<IntOperationFilterInput>;
  digitalPlacementDescriptionLookup?: InputMaybe<LookupFilterInput>;
  digitalPlacementDescriptionLookupId?: InputMaybe<IntOperationFilterInput>;
  digitalSpendInputLines?: InputMaybe<ListFilterInputTypeOfDigitalSpendInputLineFilterInput>;
  dmlquantity?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  endUtc?: InputMaybe<DateTimeOperationFilterInput>;
  estimatedValue?: InputMaybe<DecimalOperationFilterInput>;
  exchangeRate?: InputMaybe<DecimalOperationFilterInput>;
  externalComment?: InputMaybe<StringOperationFilterInput>;
  ignoreDatoramaDiscrep?: InputMaybe<BooleanOperationFilterInput>;
  instancesBooked?: InputMaybe<IntOperationFilterInput>;
  internalComment?: InputMaybe<StringOperationFilterInput>;
  internalStatusLookup?: InputMaybe<LookupFilterInput>;
  internalStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  inverseProgenitorSchedule?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  invoiceSchedules?: InputMaybe<ListFilterInputTypeOfInvoiceScheduleFilterInput>;
  isLockedByAccounting?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  leadCountsFileReference?: InputMaybe<FileReferenceFilterInput>;
  leadCountsFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lfprogramInId?: InputMaybe<IntOperationFilterInput>;
  lfprogramOutId?: InputMaybe<IntOperationFilterInput>;
  lftribuneNameLookup?: InputMaybe<LookupFilterInput>;
  lftribuneNameLookupId?: InputMaybe<IntOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  makeGoodFileReference?: InputMaybe<FileReferenceFilterInput>;
  makeGoodFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletOffering?: InputMaybe<MediaOutletOfferingFilterInput>;
  mediaOutletOfferingId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailInstances?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailInstanceFilterInput>;
  mediaScheduleDetailInstancesId?: InputMaybe<IntOperationFilterInput>;
  mediaScheduleDetailInstancesNavigation?: InputMaybe<MediaScheduleDetailInstanceFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  networkMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  networkMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleFilterInput>>;
  package?: InputMaybe<PackageFilterInput>;
  packageId?: InputMaybe<IntOperationFilterInput>;
  paperworkSent?: InputMaybe<BooleanOperationFilterInput>;
  paymentReference?: InputMaybe<StringOperationFilterInput>;
  piamountToChargeClient?: InputMaybe<DecimalOperationFilterInput>;
  piamountToPayStation?: InputMaybe<DecimalOperationFilterInput>;
  pibasedOnLookup?: InputMaybe<LookupFilterInput>;
  pibasedOnLookupId?: InputMaybe<IntOperationFilterInput>;
  placementPositionLookup?: InputMaybe<LookupFilterInput>;
  placementPositionLookupId?: InputMaybe<IntOperationFilterInput>;
  progenitorSchedule?: InputMaybe<ScheduleFilterInput>;
  progenitorScheduleId?: InputMaybe<IntOperationFilterInput>;
  prtadSizeLookup?: InputMaybe<LookupFilterInput>;
  prtadSizeLookupId?: InputMaybe<IntOperationFilterInput>;
  prtadTypeLookup?: InputMaybe<LookupFilterInput>;
  prtadTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  prtartFileReference?: InputMaybe<FileReferenceFilterInput>;
  prtartFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  prtcirculation?: InputMaybe<IntOperationFilterInput>;
  prtcolor?: InputMaybe<BooleanOperationFilterInput>;
  prtcolorLookup?: InputMaybe<LookupFilterInput>;
  prtcolorLookupId?: InputMaybe<IntOperationFilterInput>;
  prtissueDateLookup?: InputMaybe<LookupFilterInput>;
  prtissueDateLookupId?: InputMaybe<IntOperationFilterInput>;
  prtlayoutLookup?: InputMaybe<LookupFilterInput>;
  prtlayoutLookupId?: InputMaybe<IntOperationFilterInput>;
  prtlocationLookup?: InputMaybe<LookupFilterInput>;
  prtlocationLookupId?: InputMaybe<IntOperationFilterInput>;
  prtmaterialsDueDate?: InputMaybe<DateOperationFilterInput>;
  prtonSaleDate?: InputMaybe<DateOperationFilterInput>;
  prtpageNumber?: InputMaybe<StringOperationFilterInput>;
  prtplacedSectionLookup?: InputMaybe<LookupFilterInput>;
  prtplacedSectionLookupId?: InputMaybe<IntOperationFilterInput>;
  prtsectionLookup?: InputMaybe<LookupFilterInput>;
  prtsectionLookupId?: InputMaybe<IntOperationFilterInput>;
  prtspaceDate?: InputMaybe<DateOperationFilterInput>;
  prtterritory?: InputMaybe<PrintTerritoryFilterInput>;
  prtterritoryId?: InputMaybe<IntOperationFilterInput>;
  rateTypeLookup?: InputMaybe<LookupFilterInput>;
  rateTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  ratingSchedules?: InputMaybe<ListFilterInputTypeOfRatingScheduleFilterInput>;
  readTypeLookup?: InputMaybe<LookupFilterInput>;
  readTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  receivedFinalExchangeRateAdjustment?: InputMaybe<BooleanOperationFilterInput>;
  rentrakRatingSchedules?: InputMaybe<ListFilterInputTypeOfRentrakRatingScheduleFilterInput>;
  rentrakTelecastDetails?: InputMaybe<ListFilterInputTypeOfRentrakTelecastDetailFilterInput>;
  scheduleAttributes?: InputMaybe<ListFilterInputTypeOfScheduleAttributeFilterInput>;
  scheduleCostAdjustments?: InputMaybe<ListFilterInputTypeOfScheduleCostAdjustmentFilterInput>;
  scheduleFileImports?: InputMaybe<ListFilterInputTypeOfScheduleFileImportFilterInput>;
  scheduleFileReferences?: InputMaybe<ListFilterInputTypeOfScheduleFileReferenceFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  sfestimatedInstances?: InputMaybe<IntOperationFilterInput>;
  sfpostLogFileReference?: InputMaybe<FileReferenceFilterInput>;
  sfpostLogFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  sfpreLogFileReference?: InputMaybe<FileReferenceFilterInput>;
  sfpreLogFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  sfprogramId?: InputMaybe<IntOperationFilterInput>;
  sfschedulePrelogStatusLookup?: InputMaybe<LookupFilterInput>;
  sfschedulePrelogStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  spendAllocationSchemaLookup?: InputMaybe<LookupFilterInput>;
  spendAllocationSchemaLookupId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  startUtc?: InputMaybe<DateTimeOperationFilterInput>;
  statusLookup?: InputMaybe<LookupFilterInput>;
  statusLookupId?: InputMaybe<IntOperationFilterInput>;
  strategyLookup?: InputMaybe<LookupFilterInput>;
  strategyLookupId?: InputMaybe<IntOperationFilterInput>;
  tacticLookup?: InputMaybe<LookupFilterInput>;
  tacticLookupId?: InputMaybe<IntOperationFilterInput>;
  theoreticalGrossAmount?: InputMaybe<DecimalOperationFilterInput>;
  title?: InputMaybe<TitleFilterInput>;
  titleId?: InputMaybe<IntOperationFilterInput>;
  totalDeliveredImpressions?: InputMaybe<IntOperationFilterInput>;
  trackClicks?: InputMaybe<BooleanOperationFilterInput>;
  trackingServicePaidByLookup?: InputMaybe<LookupFilterInput>;
  trackingServicePaidByLookupId?: InputMaybe<IntOperationFilterInput>;
  trackingServiceVendor?: InputMaybe<VendorFilterInput>;
  trackingServiceVendorId?: InputMaybe<IntOperationFilterInput>;
  trafficSentUtc?: InputMaybe<DateTimeOperationFilterInput>;
  uniqueAdSchedules?: InputMaybe<ListFilterInputTypeOfUniqueAdScheduleFilterInput>;
  waveCastOverrideProgramId?: InputMaybe<IntOperationFilterInput>;
  zzzAffidavitDisputed?: InputMaybe<BooleanOperationFilterInput>;
};

export type ScheduleForInvoiceApproval = {
  __typename?: 'ScheduleForInvoiceApproval';
  amountToPayStation?: Maybe<Scalars['Decimal']>;
  anyPropertyValuesChanged: Scalars['Boolean'];
  approverAppUserDescription?: Maybe<Scalars['String']>;
  approverAppUserID: Scalars['Int'];
  approverEmail?: Maybe<Scalars['String']>;
  axProjectCode?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissionRate?: Maybe<Scalars['Decimal']>;
  datoramaFilePath?: Maybe<Scalars['String']>;
  datoramaKey?: Maybe<Scalars['String']>;
  digitalAnalystAppUserDescription?: Maybe<Scalars['String']>;
  digitalAnalystEmail?: Maybe<Scalars['String']>;
  hasChanged: Scalars['Boolean'];
  invoiceID: Scalars['Int'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isLockedByAccounting: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  mediaOutletID: Scalars['Int'];
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaSupervisorAppUserDescription?: Maybe<Scalars['String']>;
  mediaSupervisorAppUserID?: Maybe<Scalars['Int']>;
  mediaSupervisorEmail?: Maybe<Scalars['String']>;
  netAdjustment?: Maybe<Scalars['Decimal']>;
  netWithAdjustment?: Maybe<Scalars['Decimal']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  productName?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
  scheduleID: Scalars['Int'];
  scheduleStatus: ScheduleStatus;
  startDate: Scalars['DateTime'];
  statusLookupID: Scalars['Int'];
};


export type ScheduleForInvoiceApprovalPropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export enum ScheduleStatus {
  Can = 'CAN',
  Db = 'DB',
  Del = 'DEL',
  Dna = 'DNA',
  Err = 'ERR',
  Inco = 'INCO',
  Jip = 'JIP',
  Na = 'NA',
  Nit = 'NIT',
  Norm = 'NORM',
  NoActg = 'NO_ACTG',
  Pcan = 'PCAN',
  Pdna = 'PDNA',
  Pip = 'PIP',
  Pre = 'PRE',
  Prov = 'PROV',
  Provl = 'PROVL',
  Rej = 'REJ',
  Rie = 'RIE',
  Rif = 'RIF',
  Rifl = 'RIFL',
  Rosp = 'ROSP'
}

export type ScheduleStatusState = {
  __typename?: 'ScheduleStatusState';
  isValidForNewBooking: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  scheduleStateLookup?: Maybe<Lookup>;
  scheduleStateLookupId: Scalars['Int'];
  scheduleStatusStateId: Scalars['Int'];
  statusLookup?: Maybe<Lookup>;
  statusLookupId: Scalars['Int'];
};

export type ScheduleStatusStateFilterInput = {
  and?: InputMaybe<Array<ScheduleStatusStateFilterInput>>;
  isValidForNewBooking?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleStatusStateFilterInput>>;
  scheduleStateLookup?: InputMaybe<LookupFilterInput>;
  scheduleStateLookupId?: InputMaybe<IntOperationFilterInput>;
  scheduleStatusStateId?: InputMaybe<IntOperationFilterInput>;
  statusLookup?: InputMaybe<LookupFilterInput>;
  statusLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type SchemaRestrictionResponse = {
  __typename?: 'SchemaRestrictionResponse';
  activeMetricRestrictions?: Maybe<Array<Maybe<ActiveMetricRestriction>>>;
  calculateSize: Scalars['Int'];
  clone?: Maybe<SchemaRestrictionResponse>;
};

export type Script = {
  __typename?: 'Script';
  attributionScript?: Maybe<Script>;
  attributionScriptId?: Maybe<Scalars['Int']>;
  autoSourceResults?: Maybe<Array<Maybe<AutoSourceResult>>>;
  autosourcerAlgorithmLookup?: Maybe<Lookup>;
  autosourcerAlgorithmLookupId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createBaselineResults: Scalars['Boolean'];
  gareports?: Maybe<Array<Maybe<Gareport>>>;
  groupByRegion?: Maybe<Scalars['Boolean']>;
  inactive?: Maybe<Scalars['Boolean']>;
  inputFieldMappingSchemes?: Maybe<Array<Maybe<InputFieldMappingScheme>>>;
  inverseAttributionScript?: Maybe<Array<Maybe<Script>>>;
  inverseLiftScript?: Maybe<Array<Maybe<Script>>>;
  inverseLtvsourceScript?: Maybe<Array<Maybe<Script>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  liftBaselineEndDate?: Maybe<Scalars['DateTime']>;
  liftBaselineStartDate?: Maybe<Scalars['DateTime']>;
  liftScript?: Maybe<Script>;
  liftScriptId?: Maybe<Scalars['Int']>;
  liftTypeLookup?: Maybe<Lookup>;
  liftTypeLookupId?: Maybe<Scalars['Int']>;
  ltvsourceScript?: Maybe<Script>;
  ltvsourceScriptId?: Maybe<Scalars['Int']>;
  ltvsourceSku?: Maybe<Sku>;
  ltvsourceSkuid?: Maybe<Scalars['Int']>;
  marketContributionAnalyses?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  onlyMatchUniqueId: Scalars['Boolean'];
  orderCallCreationLookup?: Maybe<Lookup>;
  orderCallCreationLookupId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productCode?: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
  resultManualEntryDetails?: Maybe<Array<Maybe<ResultManualEntryDetail>>>;
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  rvresults?: Maybe<Array<Maybe<Rvresult>>>;
  scriptAds?: Maybe<Array<Maybe<ScriptAd>>>;
  scriptGroupScripts?: Maybe<Array<Maybe<ScriptGroupScript>>>;
  scriptId: Scalars['Int'];
  scriptSkus?: Maybe<Array<Maybe<ScriptSku>>>;
  signalLinkCurveSchemaLookup?: Maybe<Lookup>;
  signalLinkCurveSchemaLookupId?: Maybe<Scalars['Int']>;
  sourcingAlgorithm?: Maybe<SourcingAlgorithm>;
  sourcingAlgorithmId?: Maybe<Scalars['Int']>;
  spikeDelayMinutes?: Maybe<Scalars['Int']>;
  spikeNielsenDemoLookup?: Maybe<Lookup>;
  spikeNielsenDemoLookupId?: Maybe<Scalars['Int']>;
  spikeSoftPeak: Scalars['Boolean'];
  spikeZscoreTolerance?: Maybe<Scalars['Decimal']>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  tsbvendor?: Maybe<Vendor>;
  tsbvendorId?: Maybe<Scalars['Int']>;
};

export type ScriptAd = {
  __typename?: 'ScriptAd';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  script?: Maybe<Script>;
  scriptAdId: Scalars['Int'];
  scriptId: Scalars['Int'];
};

export type ScriptAdFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<ScriptAdFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScriptAdFilterInput>>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptAdId?: InputMaybe<IntOperationFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
};

export type ScriptFilterInput = {
  and?: InputMaybe<Array<ScriptFilterInput>>;
  attributionScript?: InputMaybe<ScriptFilterInput>;
  attributionScriptId?: InputMaybe<IntOperationFilterInput>;
  autoSourceResults?: InputMaybe<ListFilterInputTypeOfAutoSourceResultFilterInput>;
  autosourcerAlgorithmLookup?: InputMaybe<LookupFilterInput>;
  autosourcerAlgorithmLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createBaselineResults?: InputMaybe<BooleanOperationFilterInput>;
  gareports?: InputMaybe<ListFilterInputTypeOfGareportFilterInput>;
  groupByRegion?: InputMaybe<BooleanOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  inputFieldMappingSchemes?: InputMaybe<ListFilterInputTypeOfInputFieldMappingSchemeFilterInput>;
  inverseAttributionScript?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  inverseLiftScript?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  inverseLtvsourceScript?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  liftBaselineEndDate?: InputMaybe<DateTimeOperationFilterInput>;
  liftBaselineStartDate?: InputMaybe<DateTimeOperationFilterInput>;
  liftScript?: InputMaybe<ScriptFilterInput>;
  liftScriptId?: InputMaybe<IntOperationFilterInput>;
  liftTypeLookup?: InputMaybe<LookupFilterInput>;
  liftTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  ltvsourceScript?: InputMaybe<ScriptFilterInput>;
  ltvsourceScriptId?: InputMaybe<IntOperationFilterInput>;
  ltvsourceSku?: InputMaybe<SkuFilterInput>;
  ltvsourceSkuid?: InputMaybe<IntOperationFilterInput>;
  marketContributionAnalyses?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  onlyMatchUniqueId?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ScriptFilterInput>>;
  orderCallCreationLookup?: InputMaybe<LookupFilterInput>;
  orderCallCreationLookupId?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productCode?: InputMaybe<StringOperationFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  resultManualEntryDetails?: InputMaybe<ListFilterInputTypeOfResultManualEntryDetailFilterInput>;
  resultStagings?: InputMaybe<ListFilterInputTypeOfResultStagingFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  rvresults?: InputMaybe<ListFilterInputTypeOfRvresultFilterInput>;
  scriptAds?: InputMaybe<ListFilterInputTypeOfScriptAdFilterInput>;
  scriptGroupScripts?: InputMaybe<ListFilterInputTypeOfScriptGroupScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  scriptSkus?: InputMaybe<ListFilterInputTypeOfScriptSkuFilterInput>;
  signalLinkCurveSchemaLookup?: InputMaybe<LookupFilterInput>;
  signalLinkCurveSchemaLookupId?: InputMaybe<IntOperationFilterInput>;
  sourcingAlgorithm?: InputMaybe<SourcingAlgorithmFilterInput>;
  sourcingAlgorithmId?: InputMaybe<IntOperationFilterInput>;
  spikeDelayMinutes?: InputMaybe<IntOperationFilterInput>;
  spikeNielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  spikeNielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  spikeSoftPeak?: InputMaybe<BooleanOperationFilterInput>;
  spikeZscoreTolerance?: InputMaybe<DecimalOperationFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  tsbvendor?: InputMaybe<VendorFilterInput>;
  tsbvendorId?: InputMaybe<IntOperationFilterInput>;
};

export type ScriptGroup = {
  __typename?: 'ScriptGroup';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scriptGroupId: Scalars['Int'];
};

export type ScriptGroupFilterInput = {
  and?: InputMaybe<Array<ScriptGroupFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScriptGroupFilterInput>>;
  scriptGroupId?: InputMaybe<IntOperationFilterInput>;
};

export type ScriptGroupScript = {
  __typename?: 'ScriptGroupScript';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  script?: Maybe<Script>;
  scriptGroup?: Maybe<Skugroup>;
  scriptGroupId: Scalars['Int'];
  scriptGroupScriptId: Scalars['Int'];
  scriptId: Scalars['Int'];
};

export type ScriptGroupScriptFilterInput = {
  and?: InputMaybe<Array<ScriptGroupScriptFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScriptGroupScriptFilterInput>>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptGroup?: InputMaybe<SkugroupFilterInput>;
  scriptGroupId?: InputMaybe<IntOperationFilterInput>;
  scriptGroupScriptId?: InputMaybe<IntOperationFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
};

export type ScriptSku = {
  __typename?: 'ScriptSku';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  orderBy: Scalars['Int'];
  printOrderBy?: Maybe<Scalars['Int']>;
  script?: Maybe<Script>;
  scriptId: Scalars['Int'];
  scriptSkuid: Scalars['Int'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
};

export type ScriptSkuFilterInput = {
  and?: InputMaybe<Array<ScriptSkuFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScriptSkuFilterInput>>;
  orderBy?: InputMaybe<IntOperationFilterInput>;
  printOrderBy?: InputMaybe<IntOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  scriptSkuid?: InputMaybe<IntOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
};

export type ScriptSortInput = {
  attributionScript?: InputMaybe<ScriptSortInput>;
  attributionScriptId?: InputMaybe<SortEnumType>;
  autosourcerAlgorithmLookup?: InputMaybe<LookupSortInput>;
  autosourcerAlgorithmLookupId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createBaselineResults?: InputMaybe<SortEnumType>;
  groupByRegion?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  liftBaselineEndDate?: InputMaybe<SortEnumType>;
  liftBaselineStartDate?: InputMaybe<SortEnumType>;
  liftScript?: InputMaybe<ScriptSortInput>;
  liftScriptId?: InputMaybe<SortEnumType>;
  liftTypeLookup?: InputMaybe<LookupSortInput>;
  liftTypeLookupId?: InputMaybe<SortEnumType>;
  ltvsourceScript?: InputMaybe<ScriptSortInput>;
  ltvsourceScriptId?: InputMaybe<SortEnumType>;
  ltvsourceSku?: InputMaybe<SkuSortInput>;
  ltvsourceSkuid?: InputMaybe<SortEnumType>;
  mediaOutlet?: InputMaybe<MediaOutletSortInput>;
  mediaOutletId?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  onlyMatchUniqueId?: InputMaybe<SortEnumType>;
  orderCallCreationLookup?: InputMaybe<LookupSortInput>;
  orderCallCreationLookupId?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productCode?: InputMaybe<SortEnumType>;
  productId?: InputMaybe<SortEnumType>;
  scriptId?: InputMaybe<SortEnumType>;
  signalLinkCurveSchemaLookup?: InputMaybe<LookupSortInput>;
  signalLinkCurveSchemaLookupId?: InputMaybe<SortEnumType>;
  sourcingAlgorithm?: InputMaybe<SourcingAlgorithmSortInput>;
  sourcingAlgorithmId?: InputMaybe<SortEnumType>;
  spikeDelayMinutes?: InputMaybe<SortEnumType>;
  spikeNielsenDemoLookup?: InputMaybe<LookupSortInput>;
  spikeNielsenDemoLookupId?: InputMaybe<SortEnumType>;
  spikeSoftPeak?: InputMaybe<SortEnumType>;
  spikeZscoreTolerance?: InputMaybe<SortEnumType>;
  tsbvendor?: InputMaybe<VendorSortInput>;
  tsbvendorId?: InputMaybe<SortEnumType>;
};

export type SearchAds360Rate = {
  __typename?: 'SearchAds360Rate';
  calendarId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  dateAsDate?: Maybe<Scalars['DateTime']>;
  rate?: Maybe<Scalars['Decimal']>;
};

export type SharepointPerson = {
  __typename?: 'SharepointPerson';
  companyId?: Maybe<Scalars['Int']>;
  companyName: Scalars['String'];
  dataAreaId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  departmentName: Scalars['String'];
  divisionId?: Maybe<Scalars['Int']>;
  emailAddress: Scalars['String'];
  employmentTypeId?: Maybe<Scalars['Int']>;
  employmentTypeName: Scalars['String'];
  firstName: Scalars['String'];
  isCurrentlyActive?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  managerPersonId?: Maybe<Scalars['Int']>;
  officeId?: Maybe<Scalars['Int']>;
  officeName: Scalars['String'];
  personId: Scalars['Int'];
  roleId?: Maybe<Scalars['Int']>;
  roleName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  stateName: Scalars['String'];
  timeZoneName?: Maybe<Scalars['String']>;
  titleName: Scalars['String'];
  ulmPersonId?: Maybe<Scalars['String']>;
};

export type Sku = {
  __typename?: 'Sku';
  accountingLines?: Maybe<Array<Maybe<AccountingLine>>>;
  allocationBasedOnSku?: Maybe<Sku>;
  allocationBasedOnSkuid?: Maybe<Scalars['Int']>;
  allocationBasedOnTypeLookup?: Maybe<Lookup>;
  allocationBasedOnTypeLookupId: Scalars['Int'];
  attributionSku?: Maybe<Sku>;
  attributionSkuid?: Maybe<Scalars['Int']>;
  baselineSku?: Maybe<Sku>;
  baselineSkuid?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costPerLeadRanges?: Maybe<Array<Maybe<CostPerLeadRange>>>;
  description?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  includeInSourcingAudit: Scalars['Boolean'];
  inverseAllocationBasedOnSku?: Maybe<Array<Maybe<Sku>>>;
  inverseAttributionSku?: Maybe<Array<Maybe<Sku>>>;
  inverseBaselineSku?: Maybe<Array<Maybe<Sku>>>;
  inverseLiftSku?: Maybe<Array<Maybe<Sku>>>;
  isBillable: Scalars['Boolean'];
  isPayable: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  liftSku?: Maybe<Sku>;
  liftSkuid?: Maybe<Scalars['Int']>;
  ltvdenominator?: Maybe<Scalars['Int']>;
  marketContributionAnalysisKpisourceSkus?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  marketContributionAnalysisSkus?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  name?: Maybe<Scalars['String']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  orderBy: Scalars['Int'];
  perInquiryCosts?: Maybe<Array<Maybe<PerInquiryCost>>>;
  product?: Maybe<Product>;
  productDimensionColumns?: Maybe<Array<Maybe<ProductDimensionColumn>>>;
  productId: Scalars['Int'];
  resultDetails?: Maybe<Array<Maybe<ResultDetail>>>;
  resultManualEntryDetails?: Maybe<Array<Maybe<ResultManualEntryDetail>>>;
  resultStagingDetails?: Maybe<Array<Maybe<ResultStagingDetail>>>;
  rvresultDetails?: Maybe<Array<Maybe<RvresultDetail>>>;
  scriptSkus?: Maybe<Array<Maybe<ScriptSku>>>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  sku1?: Maybe<Scalars['String']>;
  skugroupSkus?: Maybe<Array<Maybe<SkugroupSku>>>;
  skuid: Scalars['Int'];
  skuprices?: Maybe<Array<Maybe<Skuprice>>>;
  skutype?: Maybe<Skutype>;
  skutypeId: Scalars['Int'];
  spikeBaseLines?: Maybe<Array<Maybe<SpikeBaseLine>>>;
  tmpAccountingLines?: Maybe<Array<Maybe<TmpAccountingLine>>>;
  uniqueAdScheduleLeadAdjustments?: Maybe<Array<Maybe<UniqueAdScheduleLeadAdjustment>>>;
  useForEstimation: Scalars['Boolean'];
};

export type SkuFilterInput = {
  accountingLines?: InputMaybe<ListFilterInputTypeOfAccountingLineFilterInput>;
  allocationBasedOnSku?: InputMaybe<SkuFilterInput>;
  allocationBasedOnSkuid?: InputMaybe<IntOperationFilterInput>;
  allocationBasedOnTypeLookup?: InputMaybe<LookupFilterInput>;
  allocationBasedOnTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<SkuFilterInput>>;
  attributionSku?: InputMaybe<SkuFilterInput>;
  attributionSkuid?: InputMaybe<IntOperationFilterInput>;
  baselineSku?: InputMaybe<SkuFilterInput>;
  baselineSkuid?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costPerLeadRanges?: InputMaybe<ListFilterInputTypeOfCostPerLeadRangeFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  includeInSourcingAudit?: InputMaybe<BooleanOperationFilterInput>;
  inverseAllocationBasedOnSku?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  inverseAttributionSku?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  inverseBaselineSku?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  inverseLiftSku?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  isBillable?: InputMaybe<BooleanOperationFilterInput>;
  isPayable?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  liftSku?: InputMaybe<SkuFilterInput>;
  liftSkuid?: InputMaybe<IntOperationFilterInput>;
  ltvdenominator?: InputMaybe<IntOperationFilterInput>;
  marketContributionAnalysisKpisourceSkus?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  marketContributionAnalysisSkus?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SkuFilterInput>>;
  orderBy?: InputMaybe<IntOperationFilterInput>;
  perInquiryCosts?: InputMaybe<ListFilterInputTypeOfPerInquiryCostFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productDimensionColumns?: InputMaybe<ListFilterInputTypeOfProductDimensionColumnFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  resultDetails?: InputMaybe<ListFilterInputTypeOfResultDetailFilterInput>;
  resultManualEntryDetails?: InputMaybe<ListFilterInputTypeOfResultManualEntryDetailFilterInput>;
  resultStagingDetails?: InputMaybe<ListFilterInputTypeOfResultStagingDetailFilterInput>;
  rvresultDetails?: InputMaybe<ListFilterInputTypeOfRvresultDetailFilterInput>;
  scriptSkus?: InputMaybe<ListFilterInputTypeOfScriptSkuFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  sku1?: InputMaybe<StringOperationFilterInput>;
  skugroupSkus?: InputMaybe<ListFilterInputTypeOfSkugroupSkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  skuprices?: InputMaybe<ListFilterInputTypeOfSkupriceFilterInput>;
  skutype?: InputMaybe<SkutypeFilterInput>;
  skutypeId?: InputMaybe<IntOperationFilterInput>;
  spikeBaseLines?: InputMaybe<ListFilterInputTypeOfSpikeBaseLineFilterInput>;
  tmpAccountingLines?: InputMaybe<ListFilterInputTypeOfTmpAccountingLineFilterInput>;
  uniqueAdScheduleLeadAdjustments?: InputMaybe<ListFilterInputTypeOfUniqueAdScheduleLeadAdjustmentFilterInput>;
  useForEstimation?: InputMaybe<BooleanOperationFilterInput>;
};

export type SkuSortInput = {
  allocationBasedOnSku?: InputMaybe<SkuSortInput>;
  allocationBasedOnSkuid?: InputMaybe<SortEnumType>;
  allocationBasedOnTypeLookup?: InputMaybe<LookupSortInput>;
  allocationBasedOnTypeLookupId?: InputMaybe<SortEnumType>;
  attributionSku?: InputMaybe<SkuSortInput>;
  attributionSkuid?: InputMaybe<SortEnumType>;
  baselineSku?: InputMaybe<SkuSortInput>;
  baselineSkuid?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  includeInSourcingAudit?: InputMaybe<SortEnumType>;
  isBillable?: InputMaybe<SortEnumType>;
  isPayable?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  liftSku?: InputMaybe<SkuSortInput>;
  liftSkuid?: InputMaybe<SortEnumType>;
  ltvdenominator?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  oldnaturalKey?: InputMaybe<SortEnumType>;
  orderBy?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  sku1?: InputMaybe<SortEnumType>;
  skuid?: InputMaybe<SortEnumType>;
  skutype?: InputMaybe<SkutypeSortInput>;
  skutypeId?: InputMaybe<SortEnumType>;
  useForEstimation?: InputMaybe<SortEnumType>;
};

export type Skugroup = {
  __typename?: 'Skugroup';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  marketContributionAnalyses?: Maybe<Array<Maybe<MarketContributionAnalysis>>>;
  mediaSchedules?: Maybe<Array<Maybe<MediaSchedule>>>;
  name?: Maybe<Scalars['String']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  orderBy: Scalars['Int'];
  productOfferGroup?: Maybe<ProductOfferGroup>;
  productOfferGroupId: Scalars['Int'];
  scriptGroupScripts?: Maybe<Array<Maybe<ScriptGroupScript>>>;
  skugroupId: Scalars['Int'];
  skugroupSkus?: Maybe<Array<Maybe<SkugroupSku>>>;
  waveCastModelSkugroups?: Maybe<Array<Maybe<WaveCastModelSkugroup>>>;
};

export type SkugroupFilterInput = {
  and?: InputMaybe<Array<SkugroupFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  marketContributionAnalyses?: InputMaybe<ListFilterInputTypeOfMarketContributionAnalysisFilterInput>;
  mediaSchedules?: InputMaybe<ListFilterInputTypeOfMediaScheduleFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SkugroupFilterInput>>;
  orderBy?: InputMaybe<IntOperationFilterInput>;
  productOfferGroup?: InputMaybe<ProductOfferGroupFilterInput>;
  productOfferGroupId?: InputMaybe<IntOperationFilterInput>;
  scriptGroupScripts?: InputMaybe<ListFilterInputTypeOfScriptGroupScriptFilterInput>;
  skugroupId?: InputMaybe<IntOperationFilterInput>;
  skugroupSkus?: InputMaybe<ListFilterInputTypeOfSkugroupSkuFilterInput>;
  waveCastModelSkugroups?: InputMaybe<ListFilterInputTypeOfWaveCastModelSkugroupFilterInput>;
};

export type SkugroupSku = {
  __typename?: 'SkugroupSku';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  sku?: Maybe<Sku>;
  skugroup?: Maybe<Skugroup>;
  skugroupId: Scalars['Int'];
  skugroupSkuid: Scalars['Int'];
  skuid: Scalars['Int'];
};

export type SkugroupSkuFilterInput = {
  and?: InputMaybe<Array<SkugroupSkuFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SkugroupSkuFilterInput>>;
  sku?: InputMaybe<SkuFilterInput>;
  skugroup?: InputMaybe<SkugroupFilterInput>;
  skugroupId?: InputMaybe<IntOperationFilterInput>;
  skugroupSkuid?: InputMaybe<IntOperationFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
};

export type Skuprice = {
  __typename?: 'Skuprice';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costOfGoods: Scalars['Decimal'];
  effectiveDate: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  numberOfPayments: Scalars['Int'];
  price: Scalars['Decimal'];
  shippingAndHandling: Scalars['Decimal'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
  skupriceId: Scalars['Int'];
};

export type SkupriceFilterInput = {
  and?: InputMaybe<Array<SkupriceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costOfGoods?: InputMaybe<DecimalOperationFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  numberOfPayments?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SkupriceFilterInput>>;
  price?: InputMaybe<DecimalOperationFilterInput>;
  shippingAndHandling?: InputMaybe<DecimalOperationFilterInput>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  skupriceId?: InputMaybe<IntOperationFilterInput>;
};

export type Skutype = {
  __typename?: 'Skutype';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  skutypeId: Scalars['Int'];
};

export type SkutypeFilterInput = {
  and?: InputMaybe<Array<SkutypeFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SkutypeFilterInput>>;
  skus?: InputMaybe<ListFilterInputTypeOfSkuFilterInput>;
  skutypeId?: InputMaybe<IntOperationFilterInput>;
};

export type SkutypeSortInput = {
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  skutypeId?: InputMaybe<SortEnumType>;
};

export type Snapshot = {
  __typename?: 'Snapshot';
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  reportPreset?: Maybe<ReportPreset>;
  reportPresetId: Scalars['Int'];
  snapshotId: Scalars['Int'];
  snapshotScheduleWithResultsAndSales?: Maybe<Array<Maybe<SnapshotScheduleWithResultsAndSale>>>;
};

export type SnapshotFilterInput = {
  and?: InputMaybe<Array<SnapshotFilterInput>>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SnapshotFilterInput>>;
  reportPreset?: InputMaybe<ReportPresetFilterInput>;
  reportPresetId?: InputMaybe<IntOperationFilterInput>;
  snapshotId?: InputMaybe<IntOperationFilterInput>;
  snapshotScheduleWithResultsAndSales?: InputMaybe<ListFilterInputTypeOfSnapshotScheduleWithResultsAndSaleFilterInput>;
};

export type SnapshotScheduleWithResultsAndSale = {
  __typename?: 'SnapshotScheduleWithResultsAndSale';
  adName?: Maybe<Scalars['String']>;
  allowableDays?: Maybe<Scalars['String']>;
  clearedAds?: Maybe<Scalars['Decimal']>;
  clientNetBooked?: Maybe<Scalars['Decimal']>;
  endDate?: Maybe<Scalars['Date']>;
  endTime?: Maybe<Scalars['TimeSpan']>;
  endUtc?: Maybe<Scalars['DateTime']>;
  estimatedAds?: Maybe<Scalars['Decimal']>;
  grossCleared?: Maybe<Scalars['Decimal']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaCleared?: Maybe<Scalars['Decimal']>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaOutletName?: Maybe<Scalars['String']>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  mediaTypeName?: Maybe<Scalars['String']>;
  netAmount?: Maybe<Scalars['Decimal']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Snapshot>;
  snapshotId: Scalars['Int'];
  snapshotScheduleWithResultsAndSalesId: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  startTime?: Maybe<Scalars['TimeSpan']>;
  startUtc?: Maybe<Scalars['DateTime']>;
  uniqueAdScheduleId?: Maybe<Scalars['Int']>;
};

export type SnapshotScheduleWithResultsAndSaleFilterInput = {
  adName?: InputMaybe<StringOperationFilterInput>;
  allowableDays?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SnapshotScheduleWithResultsAndSaleFilterInput>>;
  clearedAds?: InputMaybe<DecimalOperationFilterInput>;
  clientNetBooked?: InputMaybe<DecimalOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  endUtc?: InputMaybe<DateTimeOperationFilterInput>;
  estimatedAds?: InputMaybe<DecimalOperationFilterInput>;
  grossCleared?: InputMaybe<DecimalOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaCleared?: InputMaybe<DecimalOperationFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletName?: InputMaybe<StringOperationFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  mediaTypeName?: InputMaybe<StringOperationFilterInput>;
  netAmount?: InputMaybe<DecimalOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SnapshotScheduleWithResultsAndSaleFilterInput>>;
  snapshot?: InputMaybe<SnapshotFilterInput>;
  snapshotId?: InputMaybe<IntOperationFilterInput>;
  snapshotScheduleWithResultsAndSalesId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  startUtc?: InputMaybe<DateTimeOperationFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SourcingAlgorithm = {
  __typename?: 'SourcingAlgorithm';
  audienceWeightLookup?: Maybe<Lookup>;
  audienceWeightLookupId?: Maybe<Scalars['Int']>;
  canSourceUnsourcedOnly: Scalars['Boolean'];
  considerRegions?: Maybe<Scalars['Boolean']>;
  considerSeconds: Scalars['Boolean'];
  considerTrackingCode: Scalars['Boolean'];
  display?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  minutesToGroupAdInstancesOn?: Maybe<Scalars['Int']>;
  minutesToGroupResultsOn?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numberOfExtraAdInstances?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  periodsToLookBack?: Maybe<Scalars['Int']>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  sourceToRegionals?: Maybe<Scalars['Boolean']>;
  sourcingAlgorithmId: Scalars['Int'];
  sourcingAlgorithmTypeLookup?: Maybe<Lookup>;
  sourcingAlgorithmTypeLookupId?: Maybe<Scalars['Int']>;
  treatNationalsAsLocals: Scalars['Boolean'];
};

export type SourcingAlgorithmFilterInput = {
  and?: InputMaybe<Array<SourcingAlgorithmFilterInput>>;
  audienceWeightLookup?: InputMaybe<LookupFilterInput>;
  audienceWeightLookupId?: InputMaybe<IntOperationFilterInput>;
  canSourceUnsourcedOnly?: InputMaybe<BooleanOperationFilterInput>;
  considerRegions?: InputMaybe<BooleanOperationFilterInput>;
  considerSeconds?: InputMaybe<BooleanOperationFilterInput>;
  considerTrackingCode?: InputMaybe<BooleanOperationFilterInput>;
  display?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  minutesToGroupAdInstancesOn?: InputMaybe<IntOperationFilterInput>;
  minutesToGroupResultsOn?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberOfExtraAdInstances?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SourcingAlgorithmFilterInput>>;
  periodsToLookBack?: InputMaybe<IntOperationFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  sourceToRegionals?: InputMaybe<BooleanOperationFilterInput>;
  sourcingAlgorithmId?: InputMaybe<IntOperationFilterInput>;
  sourcingAlgorithmTypeLookup?: InputMaybe<LookupFilterInput>;
  sourcingAlgorithmTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  treatNationalsAsLocals?: InputMaybe<BooleanOperationFilterInput>;
};

export type SourcingAlgorithmSortInput = {
  audienceWeightLookup?: InputMaybe<LookupSortInput>;
  audienceWeightLookupId?: InputMaybe<SortEnumType>;
  canSourceUnsourcedOnly?: InputMaybe<SortEnumType>;
  considerRegions?: InputMaybe<SortEnumType>;
  considerSeconds?: InputMaybe<SortEnumType>;
  considerTrackingCode?: InputMaybe<SortEnumType>;
  display?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  minutesToGroupAdInstancesOn?: InputMaybe<SortEnumType>;
  minutesToGroupResultsOn?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberOfExtraAdInstances?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  periodsToLookBack?: InputMaybe<SortEnumType>;
  sourceToRegionals?: InputMaybe<SortEnumType>;
  sourcingAlgorithmId?: InputMaybe<SortEnumType>;
  sourcingAlgorithmTypeLookup?: InputMaybe<LookupSortInput>;
  sourcingAlgorithmTypeLookupId?: InputMaybe<SortEnumType>;
  treatNationalsAsLocals?: InputMaybe<SortEnumType>;
};

export type SpikeBaseLine = {
  __typename?: 'SpikeBaseLine';
  autoSource?: Maybe<AutoSource>;
  autoSourceId: Scalars['Int'];
  baseLineValue: Scalars['Decimal'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  minute1: Scalars['DateTime'];
  minute2: Scalars['DateTime'];
  minute3: Scalars['DateTime'];
  minute4: Scalars['DateTime'];
  minute5: Scalars['DateTime'];
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
  spikeBaseLineId: Scalars['Long'];
  visitMinute: Scalars['DateTime'];
};

export type SpikeBaseLineFilterInput = {
  and?: InputMaybe<Array<SpikeBaseLineFilterInput>>;
  autoSource?: InputMaybe<AutoSourceFilterInput>;
  autoSourceId?: InputMaybe<IntOperationFilterInput>;
  baseLineValue?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  minute1?: InputMaybe<DateTimeOperationFilterInput>;
  minute2?: InputMaybe<DateTimeOperationFilterInput>;
  minute3?: InputMaybe<DateTimeOperationFilterInput>;
  minute4?: InputMaybe<DateTimeOperationFilterInput>;
  minute5?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<SpikeBaseLineFilterInput>>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  spikeBaseLineId?: InputMaybe<LongOperationFilterInput>;
  visitMinute?: InputMaybe<DateTimeOperationFilterInput>;
};

export type StandardsAndPracticesApproval = {
  __typename?: 'StandardsAndPracticesApproval';
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  approvalLookup?: Maybe<Lookup>;
  approvalLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  confirmationFileReference?: Maybe<FileReference>;
  confirmationFileReferenceId?: Maybe<Scalars['Int']>;
  createdByAppUser?: Maybe<AppUser>;
  createdByAppUserId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletGroup?: Maybe<MediaOutletGroup>;
  mediaOutletGroupId: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  standardsAndPracticesApprovalId: Scalars['Int'];
  utccreated?: Maybe<Scalars['DateTime']>;
};

export type StandardsAndPracticesApprovalFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<StandardsAndPracticesApprovalFilterInput>>;
  approvalLookup?: InputMaybe<LookupFilterInput>;
  approvalLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  confirmationFileReference?: InputMaybe<FileReferenceFilterInput>;
  confirmationFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  createdByAppUser?: InputMaybe<AppUserFilterInput>;
  createdByAppUserId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletGroup?: InputMaybe<MediaOutletGroupFilterInput>;
  mediaOutletGroupId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StandardsAndPracticesApprovalFilterInput>>;
  standardsAndPracticesApprovalId?: InputMaybe<IntOperationFilterInput>;
  utccreated?: InputMaybe<DateTimeOperationFilterInput>;
};

export enum Strategy {
  LowerFunnel = 'LOWER_FUNNEL',
  MidFunnel = 'MID_FUNNEL',
  Na = 'NA',
  UpperFunnel = 'UPPER_FUNNEL'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  longRunningTaskUpdates: Array<ProgressStatus>;
  onAccountBoardChange: Person_Client_Update;
  onActionItemInstanceCreated: UserActionItem;
  onClientBackgroundUpdateStatusChange: ClientBackgroundDataUpdateStatus;
  onProjectEstimateChange: ProjectEstimateUpdate;
  onProjectListUpdated: Scalars['String'];
};


export type SubscriptionLongRunningTaskUpdatesArgs = {
  uniqueTaskId: Scalars['String'];
};


export type SubscriptionOnClientBackgroundUpdateStatusChangeArgs = {
  clientCode: Scalars['String'];
};


export type SubscriptionOnProjectEstimateChangeArgs = {
  projectEstimateId: Scalars['Int'];
};

export type Survey = {
  __typename?: 'Survey';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  choiceTextFileImport?: Maybe<FileImport>;
  choiceTextFileImportId?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  codedValuesFileImport?: Maybe<FileImport>;
  codedValuesFileImportId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dateRangeLookup?: Maybe<Lookup>;
  dateRangeLookupId?: Maybe<Scalars['Int']>;
  demoComment?: Maybe<Scalars['String']>;
  inverseParentSurvey?: Maybe<Array<Maybe<Survey>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId?: Maybe<Scalars['Int']>;
  parentSurvey?: Maybe<Survey>;
  parentSurveyId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  surveyId: Scalars['Int'];
  surveyResponses?: Maybe<Array<Maybe<SurveyResponse>>>;
  surveySurveyQuestions?: Maybe<Array<Maybe<SurveySurveyQuestion>>>;
  surveyTypeLookup?: Maybe<Lookup>;
  surveyTypeLookupId: Scalars['Int'];
  wave?: Maybe<Scalars['Int']>;
};

export type SurveyFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<SurveyFilterInput>>;
  choiceTextFileImport?: InputMaybe<FileImportFilterInput>;
  choiceTextFileImportId?: InputMaybe<IntOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  codedValuesFileImport?: InputMaybe<FileImportFilterInput>;
  codedValuesFileImportId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dateRangeLookup?: InputMaybe<LookupFilterInput>;
  dateRangeLookupId?: InputMaybe<IntOperationFilterInput>;
  demoComment?: InputMaybe<StringOperationFilterInput>;
  inverseParentSurvey?: InputMaybe<ListFilterInputTypeOfSurveyFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SurveyFilterInput>>;
  parentSurvey?: InputMaybe<SurveyFilterInput>;
  parentSurveyId?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  surveyId?: InputMaybe<IntOperationFilterInput>;
  surveyResponses?: InputMaybe<ListFilterInputTypeOfSurveyResponseFilterInput>;
  surveySurveyQuestions?: InputMaybe<ListFilterInputTypeOfSurveySurveyQuestionFilterInput>;
  surveyTypeLookup?: InputMaybe<LookupFilterInput>;
  surveyTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  wave?: InputMaybe<IntOperationFilterInput>;
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  isFreeResponse: Scalars['Boolean'];
  isNorm: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  questionTypeLookup?: Maybe<Lookup>;
  questionTypeLookupId: Scalars['Int'];
  surveyQuestionAnswers?: Maybe<Array<Maybe<SurveyQuestionAnswer>>>;
  surveyQuestionId: Scalars['Int'];
  surveySurveyQuestions?: Maybe<Array<Maybe<SurveySurveyQuestion>>>;
};

export type SurveyQuestionAnswer = {
  __typename?: 'SurveyQuestionAnswer';
  choiceText?: Maybe<Scalars['String']>;
  codedValue?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  isFreeResponse: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  surveyQuestion?: Maybe<SurveyQuestion>;
  surveyQuestionAnswerId: Scalars['Int'];
  surveyQuestionId: Scalars['Int'];
  surveyResponseDetails?: Maybe<Array<Maybe<SurveyResponseDetail>>>;
};

export type SurveyQuestionAnswerFilterInput = {
  and?: InputMaybe<Array<SurveyQuestionAnswerFilterInput>>;
  choiceText?: InputMaybe<StringOperationFilterInput>;
  codedValue?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isFreeResponse?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<SurveyQuestionAnswerFilterInput>>;
  surveyQuestion?: InputMaybe<SurveyQuestionFilterInput>;
  surveyQuestionAnswerId?: InputMaybe<IntOperationFilterInput>;
  surveyQuestionId?: InputMaybe<IntOperationFilterInput>;
  surveyResponseDetails?: InputMaybe<ListFilterInputTypeOfSurveyResponseDetailFilterInput>;
};

export type SurveyQuestionFilterInput = {
  and?: InputMaybe<Array<SurveyQuestionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isFreeResponse?: InputMaybe<BooleanOperationFilterInput>;
  isNorm?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<SurveyQuestionFilterInput>>;
  question?: InputMaybe<StringOperationFilterInput>;
  questionTypeLookup?: InputMaybe<LookupFilterInput>;
  questionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  surveyQuestionAnswers?: InputMaybe<ListFilterInputTypeOfSurveyQuestionAnswerFilterInput>;
  surveyQuestionId?: InputMaybe<IntOperationFilterInput>;
  surveySurveyQuestions?: InputMaybe<ListFilterInputTypeOfSurveySurveyQuestionFilterInput>;
};

export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  ipaddress?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  locationLatitude?: Maybe<Scalars['Decimal']>;
  locationLongitude?: Maybe<Scalars['Decimal']>;
  responseId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  survey?: Maybe<Survey>;
  surveyId: Scalars['Int'];
  surveyResponseDetails?: Maybe<Array<Maybe<SurveyResponseDetail>>>;
  surveyResponseId: Scalars['Int'];
};

export type SurveyResponseDetail = {
  __typename?: 'SurveyResponseDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  freeResponse?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  surveyQuestionAnswer?: Maybe<SurveyQuestionAnswer>;
  surveyQuestionAnswerId: Scalars['Int'];
  surveyResponse?: Maybe<SurveyResponse>;
  surveyResponseDetailId: Scalars['Long'];
  surveyResponseId: Scalars['Int'];
};

export type SurveyResponseDetailFilterInput = {
  and?: InputMaybe<Array<SurveyResponseDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  freeResponse?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<SurveyResponseDetailFilterInput>>;
  surveyQuestionAnswer?: InputMaybe<SurveyQuestionAnswerFilterInput>;
  surveyQuestionAnswerId?: InputMaybe<IntOperationFilterInput>;
  surveyResponse?: InputMaybe<SurveyResponseFilterInput>;
  surveyResponseDetailId?: InputMaybe<LongOperationFilterInput>;
  surveyResponseId?: InputMaybe<IntOperationFilterInput>;
};

export type SurveyResponseFilterInput = {
  and?: InputMaybe<Array<SurveyResponseFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  ipaddress?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  locationLatitude?: InputMaybe<DecimalOperationFilterInput>;
  locationLongitude?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<SurveyResponseFilterInput>>;
  responseId?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  survey?: InputMaybe<SurveyFilterInput>;
  surveyId?: InputMaybe<IntOperationFilterInput>;
  surveyResponseDetails?: InputMaybe<ListFilterInputTypeOfSurveyResponseDetailFilterInput>;
  surveyResponseId?: InputMaybe<IntOperationFilterInput>;
};

export type SurveySurveyQuestion = {
  __typename?: 'SurveySurveyQuestion';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  questionNumber: Scalars['Int'];
  questionPart?: Maybe<Scalars['Int']>;
  questionSubPart?: Maybe<Scalars['Int']>;
  survey?: Maybe<Survey>;
  surveyId: Scalars['Int'];
  surveyQuestion?: Maybe<SurveyQuestion>;
  surveyQuestionId: Scalars['Int'];
  surveySurveyQuestionId: Scalars['Int'];
};

export type SurveySurveyQuestionFilterInput = {
  and?: InputMaybe<Array<SurveySurveyQuestionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<SurveySurveyQuestionFilterInput>>;
  questionNumber?: InputMaybe<IntOperationFilterInput>;
  questionPart?: InputMaybe<IntOperationFilterInput>;
  questionSubPart?: InputMaybe<IntOperationFilterInput>;
  survey?: InputMaybe<SurveyFilterInput>;
  surveyId?: InputMaybe<IntOperationFilterInput>;
  surveyQuestion?: InputMaybe<SurveyQuestionFilterInput>;
  surveyQuestionId?: InputMaybe<IntOperationFilterInput>;
  surveySurveyQuestionId?: InputMaybe<IntOperationFilterInput>;
};

export enum Tactic {
  AdvantagePlus = 'ADVANTAGE_PLUS',
  Brand = 'BRAND',
  Conquesting = 'CONQUESTING',
  CustomerList = 'CUSTOMER_LIST',
  Na = 'NA',
  NonBrand = 'NON_BRAND',
  Prospecting = 'PROSPECTING',
  PMax = 'P_MAX',
  Reservation = 'RESERVATION',
  Retargeting = 'RETARGETING',
  Shopping = 'SHOPPING'
}

export type TacticInfo = {
  __typename?: 'TacticInfo';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  tacticInfoId: Scalars['Int'];
  tacticLookup?: Maybe<Lookup>;
  tacticLookupId: Scalars['Int'];
};

export type TacticInfoFilterInput = {
  and?: InputMaybe<Array<TacticInfoFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TacticInfoFilterInput>>;
  tacticInfoId?: InputMaybe<IntOperationFilterInput>;
  tacticLookup?: InputMaybe<LookupFilterInput>;
  tacticLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type Target = {
  __typename?: 'Target';
  campaign?: Maybe<Campaign>;
  campaignId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  label?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mridemoLookup?: Maybe<Lookup>;
  mridemoLookupId?: Maybe<Scalars['Int']>;
  nielsenDemoLookup?: Maybe<Lookup>;
  nielsenDemoLookupId?: Maybe<Scalars['Int']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  rentrakDemographic?: Maybe<RentrakDemographic>;
  rentrakDemographicId?: Maybe<Scalars['Int']>;
  target1?: Maybe<Scalars['Decimal']>;
  targetId: Scalars['Int'];
  targetOrder?: Maybe<Scalars['Int']>;
  targetTypeLookup?: Maybe<Lookup>;
  targetTypeLookupId: Scalars['Int'];
};

export type TargetFilterInput = {
  and?: InputMaybe<Array<TargetFilterInput>>;
  campaign?: InputMaybe<CampaignFilterInput>;
  campaignId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mridemoLookup?: InputMaybe<LookupFilterInput>;
  mridemoLookupId?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoLookup?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId?: InputMaybe<IntOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TargetFilterInput>>;
  rentrakDemographic?: InputMaybe<RentrakDemographicFilterInput>;
  rentrakDemographicId?: InputMaybe<IntOperationFilterInput>;
  target1?: InputMaybe<DecimalOperationFilterInput>;
  targetId?: InputMaybe<IntOperationFilterInput>;
  targetOrder?: InputMaybe<IntOperationFilterInput>;
  targetTypeLookup?: InputMaybe<LookupFilterInput>;
  targetTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type Task = {
  __typename?: 'Task';
  assignedAppUser?: Maybe<AppUser>;
  assignedAppUserId?: Maybe<Scalars['Int']>;
  closed: Scalars['Boolean'];
  closedUtc?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc?: Maybe<Scalars['DateTime']>;
  isCreatedByProcessor: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mostRecentTaskHistory?: Maybe<TaskHistory>;
  mostRecentTaskHistoryId?: Maybe<Scalars['Int']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  priorityLookup?: Maybe<Lookup>;
  priorityLookupId: Scalars['Int'];
  revisionRequests?: Maybe<Array<Maybe<RevisionRequest>>>;
  roleLookup?: Maybe<Lookup>;
  roleLookupId: Scalars['Int'];
  taskAssociatedRecords?: Maybe<Array<Maybe<TaskAssociatedRecord>>>;
  taskHistories?: Maybe<Array<Maybe<TaskHistory>>>;
  taskId: Scalars['Int'];
  taskSubTypeLookup?: Maybe<Lookup>;
  taskSubTypeLookupId?: Maybe<Scalars['Int']>;
  taskType?: Maybe<TaskType>;
  taskTypeId: Scalars['Int'];
};

export type TaskAssociatedRecord = {
  __typename?: 'TaskAssociatedRecord';
  associatedId: Scalars['Long'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  tableName?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
  taskAssociatedRecordId: Scalars['Int'];
  taskId: Scalars['Int'];
};

export type TaskAssociatedRecordFilterInput = {
  and?: InputMaybe<Array<TaskAssociatedRecordFilterInput>>;
  associatedId?: InputMaybe<LongOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TaskAssociatedRecordFilterInput>>;
  tableName?: InputMaybe<StringOperationFilterInput>;
  task?: InputMaybe<TaskFilterInput>;
  taskAssociatedRecordId?: InputMaybe<IntOperationFilterInput>;
  taskId?: InputMaybe<IntOperationFilterInput>;
};

export type TaskFilterInput = {
  and?: InputMaybe<Array<TaskFilterInput>>;
  assignedAppUser?: InputMaybe<AppUserFilterInput>;
  assignedAppUserId?: InputMaybe<IntOperationFilterInput>;
  closed?: InputMaybe<BooleanOperationFilterInput>;
  closedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  isCreatedByProcessor?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mostRecentTaskHistory?: InputMaybe<TaskHistoryFilterInput>;
  mostRecentTaskHistoryId?: InputMaybe<IntOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TaskFilterInput>>;
  priorityLookup?: InputMaybe<LookupFilterInput>;
  priorityLookupId?: InputMaybe<IntOperationFilterInput>;
  revisionRequests?: InputMaybe<ListFilterInputTypeOfRevisionRequestFilterInput>;
  roleLookup?: InputMaybe<LookupFilterInput>;
  roleLookupId?: InputMaybe<IntOperationFilterInput>;
  taskAssociatedRecords?: InputMaybe<ListFilterInputTypeOfTaskAssociatedRecordFilterInput>;
  taskHistories?: InputMaybe<ListFilterInputTypeOfTaskHistoryFilterInput>;
  taskId?: InputMaybe<IntOperationFilterInput>;
  taskSubTypeLookup?: InputMaybe<LookupFilterInput>;
  taskSubTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  taskType?: InputMaybe<TaskTypeFilterInput>;
  taskTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type TaskHistory = {
  __typename?: 'TaskHistory';
  appUser?: Maybe<AppUser>;
  appUserId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  task?: Maybe<Task>;
  taskHistoryFileReferences?: Maybe<Array<Maybe<TaskHistoryFileReference>>>;
  taskHistoryId: Scalars['Int'];
  taskId: Scalars['Int'];
  taskStatusLookup?: Maybe<Lookup>;
  taskStatusLookupId: Scalars['Int'];
  tasks?: Maybe<Array<Maybe<Task>>>;
  utc: Scalars['DateTime'];
};

export type TaskHistoryFileReference = {
  __typename?: 'TaskHistoryFileReference';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  taskHistory?: Maybe<TaskHistory>;
  taskHistoryFileReferenceId: Scalars['Int'];
  taskHistoryId: Scalars['Int'];
};

export type TaskHistoryFileReferenceFilterInput = {
  and?: InputMaybe<Array<TaskHistoryFileReferenceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TaskHistoryFileReferenceFilterInput>>;
  taskHistory?: InputMaybe<TaskHistoryFilterInput>;
  taskHistoryFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  taskHistoryId?: InputMaybe<IntOperationFilterInput>;
};

export type TaskHistoryFilterInput = {
  and?: InputMaybe<Array<TaskHistoryFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TaskHistoryFilterInput>>;
  task?: InputMaybe<TaskFilterInput>;
  taskHistoryFileReferences?: InputMaybe<ListFilterInputTypeOfTaskHistoryFileReferenceFilterInput>;
  taskHistoryId?: InputMaybe<IntOperationFilterInput>;
  taskId?: InputMaybe<IntOperationFilterInput>;
  taskStatusLookup?: InputMaybe<LookupFilterInput>;
  taskStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  utc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type TaskType = {
  __typename?: 'TaskType';
  abbreviation?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  taskTypeId: Scalars['Int'];
  taskTypeStatusAvails?: Maybe<Array<Maybe<TaskTypeStatusAvail>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
};

export type TaskTypeFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<TaskTypeFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TaskTypeFilterInput>>;
  taskTypeId?: InputMaybe<IntOperationFilterInput>;
  taskTypeStatusAvails?: InputMaybe<ListFilterInputTypeOfTaskTypeStatusAvailFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
};

export type TaskTypeStatusAvail = {
  __typename?: 'TaskTypeStatusAvail';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  taskStateLookup?: Maybe<Lookup>;
  taskStateLookupId: Scalars['Int'];
  taskStatusLookup?: Maybe<Lookup>;
  taskStatusLookupId: Scalars['Int'];
  taskType?: Maybe<TaskType>;
  taskTypeId: Scalars['Int'];
  taskTypeStatusAvailId: Scalars['Int'];
};

export type TaskTypeStatusAvailFilterInput = {
  and?: InputMaybe<Array<TaskTypeStatusAvailFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TaskTypeStatusAvailFilterInput>>;
  taskStateLookup?: InputMaybe<LookupFilterInput>;
  taskStateLookupId?: InputMaybe<IntOperationFilterInput>;
  taskStatusLookup?: InputMaybe<LookupFilterInput>;
  taskStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  taskType?: InputMaybe<TaskTypeFilterInput>;
  taskTypeId?: InputMaybe<IntOperationFilterInput>;
  taskTypeStatusAvailId?: InputMaybe<IntOperationFilterInput>;
};

export type Tax1099BasicInfo = {
  __typename?: 'Tax1099BasicInfo';
  accountId: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  amount: Scalars['Decimal'];
  businessName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dataAreaId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  payerTin: Scalars['String'];
  payerTinType: TaxIdType;
  payerType: Scalars['String'];
  state: Scalars['String'];
  suffix: Scalars['String'];
  taxBoxId: Scalars['String'];
  transDate: Scalars['DateTime'];
  usedDefaultAddress: Scalars['Boolean'];
  zipcode: Scalars['String'];
};

export type Tax1099Form = {
  __typename?: 'Tax1099Form';
  accountId: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  box1: Scalars['Decimal'];
  box2: Scalars['Decimal'];
  box3: Scalars['Decimal'];
  box4: Scalars['Decimal'];
  box5: Scalars['Decimal'];
  box6: Scalars['Decimal'];
  box7: Scalars['Decimal'];
  box8: Scalars['Decimal'];
  box9: Scalars['Decimal'];
  box10: Scalars['String'];
  box13: Scalars['Decimal'];
  box14: Scalars['Decimal'];
  box15a: Scalars['Decimal'];
  box15b: Scalars['Decimal'];
  box16a: Scalars['Decimal'];
  box16b: Scalars['Decimal'];
  box17a: Scalars['String'];
  box17a2: Scalars['String'];
  box17b: Scalars['String'];
  box17b2: Scalars['String'];
  box18a: Scalars['Decimal'];
  box18b: Scalars['Decimal'];
  businessName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dataAreaId: Scalars['String'];
  facta: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  payerTin: Scalars['String'];
  payerTinType: TaxIdType;
  payerType: Scalars['String'];
  state: Scalars['String'];
  suffix: Scalars['String'];
  usedDefaultAddress: Scalars['Boolean'];
  zipcode: Scalars['String'];
};

export enum Tax1099NameChoice {
  Dba = 'DBA',
  VendorName = 'VENDOR_NAME'
}

export enum Tax1099Type {
  F1099Div = 'F1099DIV',
  F1099G = 'F1099G',
  F1099Int = 'F1099INT',
  F1099Misc = 'F1099MISC',
  F1099Nec = 'F1099NEC',
  F1099Oid = 'F1099OID',
  F1099S = 'F1099S'
}

export enum TaxIdType {
  Atin = 'ATIN',
  Ein = 'EIN',
  Itin = 'ITIN',
  Ssn = 'SSN',
  Unknown = 'UNKNOWN'
}

export type TermDetails = {
  __typename?: 'TermDetails';
  apTurnover: Scalars['Int'];
  approvedBy: Scalars['String'];
  approvedUtc?: Maybe<Scalars['DateTime']>;
  billingCadenceDisplayName: Scalars['String'];
  billingTypeDisplayName: Scalars['String'];
  ccpBillingCadence: CcpBillingCadence;
  ccpBillingType: CcpBillingType;
  contractId: Scalars['Int'];
  contractName: Scalars['String'];
  creditExceededPreBillDays: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  maxPostBillCredit: Scalars['Decimal'];
  notes: Scalars['String'];
  startDate: Scalars['DateTime'];
  termDays: Scalars['Int'];
  termId: Scalars['Int'];
};

export enum TermsServiceItemType {
  MediaType = 'MEDIA_TYPE',
  Service = 'SERVICE'
}

export type TimeApprover = {
  __typename?: 'TimeApprover';
  addedViaDelegate: Scalars['Boolean'];
  finopsPersonId: Scalars['Int'];
  personName: Scalars['String'];
};

export type TimeCategory = {
  __typename?: 'TimeCategory';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  id: Scalars['String'];
};

export enum TimeDataAccessType {
  Client = 'CLIENT',
  Manager = 'MANAGER',
  TimeSheetViewAll = 'TIME_SHEET_VIEW_ALL'
}

export type TimeEntry = {
  __typename?: 'TimeEntry';
  entryDate: Scalars['Date'];
  hours: Scalars['Decimal'];
  id: Scalars['String'];
};

export type TimeEntryInput = {
  entryDate: Scalars['Date'];
  hours: Scalars['Decimal'];
  id: Scalars['String'];
};

export type TimeSheet = {
  __typename?: 'TimeSheet';
  activeApprovers: Array<Scalars['String']>;
  approvedBy: Scalars['String'];
  approvedDateUTC?: Maybe<Scalars['DateTime']>;
  availableProjects: Array<Project>;
  company: Scalars['String'];
  firstDayOfWeek: Scalars['Date'];
  id: Scalars['String'];
  initialSubmissionDateUTC?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  lastActionBy: Scalars['String'];
  lastActionDate?: Maybe<Scalars['DateTime']>;
  lastComment: Scalars['String'];
  lines: Array<TimeSheetLine>;
  recId: Scalars['Long'];
  submittedDateUTC?: Maybe<Scalars['DateTime']>;
  submittedby: Scalars['String'];
  timeSheetNbr: Scalars['String'];
  totalHours: Scalars['Decimal'];
  userCanApprove: Scalars['Boolean'];
  userDisplayName: Scalars['String'];
  userNetworkAlias: Scalars['String'];
  workflowStatus: WorkflowStatus;
};

export enum TimeSheetHomeCardCountType {
  Errors = 'ERRORS',
  NotSubmitted = 'NOT_SUBMITTED',
  OutstandingApprovals = 'OUTSTANDING_APPROVALS'
}

export type TimeSheetLine = {
  __typename?: 'TimeSheetLine';
  categoryId: Scalars['String'];
  dataAreaId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invalidCategoryName?: Maybe<Scalars['String']>;
  invalidProjectName?: Maybe<Scalars['String']>;
  isProjectAndCategoryValid: Scalars['Boolean'];
  lineNum: Scalars['Decimal'];
  projId: Scalars['String'];
  projectDataAreaId: Scalars['String'];
  timeEntries: Array<TimeEntry>;
  timeSheetNbr?: Maybe<Scalars['String']>;
};

export type TimeSheetLineInput = {
  categoryId: Scalars['String'];
  dataAreaId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  invalidCategoryName?: InputMaybe<Scalars['String']>;
  invalidProjectName?: InputMaybe<Scalars['String']>;
  isProjectAndCategoryValid: Scalars['Boolean'];
  lineNum: Scalars['Decimal'];
  projId: Scalars['String'];
  projectDataAreaId: Scalars['String'];
  timeEntries: Array<TimeEntryInput>;
  timeSheetNbr?: InputMaybe<Scalars['String']>;
};

export type TimeSheetValidationPersonCategoryAnalysis = {
  __typename?: 'TimeSheetValidationPersonCategoryAnalysis';
  canPersonSeeProjectsInTheProjectCompany: Scalars['Boolean'];
  companyName: Scalars['String'];
  customCategoryListInPlaceOnProject: Scalars['Boolean'];
  customResourceListInPlaceOnProject: Scalars['Boolean'];
  departmentGroupDetails: Array<DepartmentGroupDetail>;
  isProjectActive: Scalars['Boolean'];
  isProjectVisibleAtAll: Scalars['Boolean'];
  peopleInCustomResourceList: Array<Scalars['String']>;
  personAllowedCategories: Array<CategoryValidationDetail>;
  personAllowedCompanies: Array<Scalars['String']>;
  personId: Scalars['Int'];
  personName: Scalars['String'];
  projectAllowedCategories: Array<CategoryValidationDetail>;
  projectDataAreaId: Scalars['String'];
  projectEndDate: Scalars['DateTime'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  projectStartDate: Scalars['DateTime'];
};

export type TimeSpanOperationFilterInput = {
  eq?: InputMaybe<Scalars['TimeSpan']>;
  gt?: InputMaybe<Scalars['TimeSpan']>;
  gte?: InputMaybe<Scalars['TimeSpan']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']>>>;
  lt?: InputMaybe<Scalars['TimeSpan']>;
  lte?: InputMaybe<Scalars['TimeSpan']>;
  neq?: InputMaybe<Scalars['TimeSpan']>;
  ngt?: InputMaybe<Scalars['TimeSpan']>;
  ngte?: InputMaybe<Scalars['TimeSpan']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']>>>;
  nlt?: InputMaybe<Scalars['TimeSpan']>;
  nlte?: InputMaybe<Scalars['TimeSpan']>;
};

export type TimeZoneInfo = {
  __typename?: 'TimeZoneInfo';
  abbreviation?: Maybe<Scalars['String']>;
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  appLocations?: Maybe<Array<Maybe<AppLocation>>>;
  description?: Maybe<Scalars['String']>;
  dmainfos?: Maybe<Array<Maybe<Dmainfo>>>;
  dotNetTimeZoneInfoId?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  prelogEstimates?: Maybe<Array<Maybe<PrelogEstimate>>>;
  showInList: Scalars['Boolean'];
  timeZoneInfoId: Scalars['Int'];
  utchoursOffset?: Maybe<Scalars['Decimal']>;
  vendors?: Maybe<Array<Maybe<Vendor>>>;
};

export type TimeZoneInfoFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  and?: InputMaybe<Array<TimeZoneInfoFilterInput>>;
  appLocations?: InputMaybe<ListFilterInputTypeOfAppLocationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dmainfos?: InputMaybe<ListFilterInputTypeOfDmainfoFilterInput>;
  dotNetTimeZoneInfoId?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  or?: InputMaybe<Array<TimeZoneInfoFilterInput>>;
  prelogEstimates?: InputMaybe<ListFilterInputTypeOfPrelogEstimateFilterInput>;
  showInList?: InputMaybe<BooleanOperationFilterInput>;
  timeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
  utchoursOffset?: InputMaybe<DecimalOperationFilterInput>;
  vendors?: InputMaybe<ListFilterInputTypeOfVendorFilterInput>;
};

export type TimeZoneInfoSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  dotNetTimeZoneInfoId?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  showInList?: InputMaybe<SortEnumType>;
  timeZoneInfoId?: InputMaybe<SortEnumType>;
  utchoursOffset?: InputMaybe<SortEnumType>;
};

export type TimesheetChange = {
  __typename?: 'TimesheetChange';
  onChangeSubmissionComment: Scalars['String'];
  onChangeSubmissionDateTimeUTC: Scalars['DateTime'];
  originalTimesheet: TimeSheet;
  submittedDateTimeUTC: Scalars['DateTime'];
  submitterComment: Scalars['String'];
  submitterFinopsPersonId: Scalars['Int'];
  submitterName: Scalars['String'];
  timeSheetNbr: Scalars['String'];
  updatedTimesheet: TimeSheet;
};

export type TimesheetDelegate_ForDisplay = {
  __typename?: 'TimesheetDelegate_ForDisplay';
  delegateType: DelegateType;
  delegatedToDisplayName: Scalars['String'];
  delegatedToPersonId: Scalars['Int'];
  forPersonDisplayName: Scalars['String'];
  forPersonId: Scalars['Int'];
  id: Scalars['Int'];
  isDelegatedToPersonActive: Scalars['Boolean'];
  isForPersonActive: Scalars['Boolean'];
  timesheetDelegateId: Scalars['Int'];
};

export type TimesheetLine_ForReporting = {
  __typename?: 'TimesheetLine_ForReporting';
  adminHours: Scalars['Decimal'];
  brandDim: AxBrandDim;
  brandKey: Scalars['Long'];
  brandName: Scalars['String'];
  brandValue: Scalars['String'];
  categoryDepartmentId: Scalars['Int'];
  categoryDepartmentName: Scalars['String'];
  categoryDepartmentValue: Scalars['String'];
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  categorySimpleDepartmentCode: Scalars['String'];
  categorySimpleDepartmentName: Scalars['String'];
  clientDim: AxClientDim;
  clientHours: Scalars['Decimal'];
  clientKey: Scalars['Long'];
  clientName: Scalars['String'];
  clientValue: Scalars['String'];
  date: Scalars['DateTime'];
  divisionId: Scalars['Int'];
  divisionName: Scalars['String'];
  firstDayOfWeek: Scalars['DateTime'];
  flightDay: FlightDay;
  initiativeDim: AxInitiativeDim;
  initiativeName: Scalars['String'];
  initiativeValue: Scalars['String'];
  intInvestHours: Scalars['Decimal'];
  lastModifiedDate: Scalars['DateTime'];
  note?: Maybe<Note_ForDisplay>;
  personId: Scalars['Int'];
  personName: Scalars['String'];
  productDim: AxProductDim;
  productName: Scalars['String'];
  productValue: Scalars['String'];
  projectDataAreaId: Scalars['String'];
  projectDim: AxProjectDim;
  projectGroup: Scalars['String'];
  projectId: Scalars['String'];
  projectKey: Scalars['Long'];
  projectName: Scalars['String'];
  ptoHours: Scalars['Decimal'];
  timeSheetLineId: Scalars['String'];
  timesheetNbr: Scalars['String'];
  timesheetStatus: TimesheetStatus;
  timesheetStatusName: Scalars['String'];
  totalHours: Scalars['Decimal'];
  workerDepartmentId: Scalars['Int'];
  workerDepartmentName: Scalars['String'];
  workerDepartmentValue: Scalars['String'];
  workerSimpleDepartmentCode: Scalars['String'];
  workerSimpleDepartmentName: Scalars['String'];
};

export enum TimesheetStatus {
  AwaitingChangeReview = 'AWAITING_CHANGE_REVIEW',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InApprovals = 'IN_APPROVALS',
  Unknown = 'UNKNOWN',
  Unlocked = 'UNLOCKED',
  UnlockRequested = 'UNLOCK_REQUESTED'
}

export type TimesheetUserSetup = {
  __typename?: 'TimesheetUserSetup';
  isSetupCorrectly: Scalars['Boolean'];
  message: Scalars['String'];
};

export type TimesheetValidationAdditionalCompany_ForDisplay = {
  __typename?: 'TimesheetValidationAdditionalCompany_ForDisplay';
  companyname: Scalars['String'];
  dataAreaId: Scalars['String'];
  id: Scalars['Int'];
  personDisplayName: Scalars['String'];
  personId: Scalars['Int'];
  timesheetValidationAdditionalCompanyId: Scalars['Int'];
};

export type TimesheetValidationAdditionalDepartmentGroup_ForDisplay = {
  __typename?: 'TimesheetValidationAdditionalDepartmentGroup_ForDisplay';
  departmentGroupId: Scalars['Int'];
  departmentGroupName: Scalars['String'];
  id: Scalars['Int'];
  personDisplayName: Scalars['String'];
  personId: Scalars['Int'];
  timesheetValidationAdditionalDepartmentGroupId: Scalars['Int'];
};

export type TimesheetValidationCatDepartmentGroup_ForDisplay = {
  __typename?: 'TimesheetValidationCatDepartmentGroup_ForDisplay';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  departmentGroupId: Scalars['Int'];
  departmentGroupName: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  timesheetValidationCatDepartmentGroupId: Scalars['Int'];
};

export type TimesheetValidationCatProjectGroup_ForDisplay = {
  __typename?: 'TimesheetValidationCatProjectGroup_ForDisplay';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  companyname: Scalars['String'];
  dataAreaId: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  projectGroup: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  timesheetValidationCatProjectGroupId: Scalars['Int'];
};

export type TimesheetValidationPersonAdditionalCategory_ForDisplay = {
  __typename?: 'TimesheetValidationPersonAdditionalCategory_ForDisplay';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  id: Scalars['Int'];
  personDisplayName: Scalars['String'];
  personId: Scalars['Int'];
  timesheetValidationPersonAdditionalCategoryId: Scalars['Int'];
};

export type TimesheetValidationProjCategoryOverride_ForDisplay = {
  __typename?: 'TimesheetValidationProjCategoryOverride_ForDisplay';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  id: Scalars['Int'];
  projDataAreaId: Scalars['String'];
  projId: Scalars['String'];
  projectName: Scalars['String'];
  timesheetValidationProjCategoryOverrideId: Scalars['Int'];
};

export type TimesheetValidationProjResourceOverride_ForDisplay = {
  __typename?: 'TimesheetValidationProjResourceOverride_ForDisplay';
  id: Scalars['Int'];
  personDisplayName: Scalars['String'];
  personId: Scalars['Int'];
  projDataAreaId: Scalars['String'];
  projId: Scalars['String'];
  projectName: Scalars['String'];
  timesheetValidationProjResourceOverrideId: Scalars['Int'];
};

export type TimesheetWeekSummary = {
  __typename?: 'TimesheetWeekSummary';
  approvedDateUTC?: Maybe<Scalars['DateTime']>;
  approvers: Array<TimeApprover>;
  company: Scalars['String'];
  departmentGroup: Scalars['String'];
  departmentName: Scalars['String'];
  employmentType: Scalars['String'];
  errors: Array<Scalars['String']>;
  firstDayOfWeek: Scalars['Date'];
  id: Scalars['String'];
  initialSubmissionDateUTC?: Maybe<Scalars['DateTime']>;
  isUserCurrentlyActive: Scalars['Boolean'];
  lineErrors: Array<Scalars['String']>;
  notAssignedtoCorrectManager: Scalars['Boolean'];
  statusName: Scalars['String'];
  submittedDateUTC?: Maybe<Scalars['DateTime']>;
  submitterComment: Scalars['String'];
  timeSheetNbr: Scalars['String'];
  timesheetPersonId: Scalars['Int'];
  timesheetStatus: TimesheetStatus;
  timesheetWeekId: Scalars['Int'];
  totalHours: Scalars['Decimal'];
  updatePossibleApproversForTimesheet: Scalars['Boolean'];
  userCanApprove: Scalars['Boolean'];
  userDisplayName: Scalars['String'];
};


export type TimesheetWeekSummaryUpdatePossibleApproversForTimesheetArgs = {
  currentApproverId: Scalars['Int'];
};

export type Title = {
  __typename?: 'Title';
  ads?: Maybe<Array<Maybe<Ad>>>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  listingTitle?: Maybe<Scalars['String']>;
  newCampaignAnnouncements?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  parentClient?: Maybe<Client>;
  parentClientId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<Product>>>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  titleCode?: Maybe<Scalars['String']>;
  titleId: Scalars['Int'];
};

export type TitleFilterInput = {
  ads?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  and?: InputMaybe<Array<TitleFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  listingTitle?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncements?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  or?: InputMaybe<Array<TitleFilterInput>>;
  parentClient?: InputMaybe<ClientFilterInput>;
  parentClientId?: InputMaybe<IntOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  titleCode?: InputMaybe<StringOperationFilterInput>;
  titleId?: InputMaybe<IntOperationFilterInput>;
};

export type TitleSortInput = {
  client?: InputMaybe<ClientSortInput>;
  clientId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createAppUser?: InputMaybe<AppUserSortInput>;
  createAppUserId?: InputMaybe<SortEnumType>;
  createUtc?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  listingTitle?: InputMaybe<SortEnumType>;
  parentClient?: InputMaybe<ClientSortInput>;
  parentClientId?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  titleCode?: InputMaybe<SortEnumType>;
  titleId?: InputMaybe<SortEnumType>;
};

export type TmpAccountingBatch = {
  __typename?: 'TmpAccountingBatch';
  accountingBatchId: Scalars['Int'];
  accountingBatchTypeLookupId: Scalars['Int'];
  appUserId?: Maybe<Scalars['Int']>;
  associatedAccountingBatchId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isFake?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  overrideExportDate?: Maybe<Scalars['Date']>;
};

export type TmpAccountingBatchFilterInput = {
  accountingBatchId?: InputMaybe<IntOperationFilterInput>;
  accountingBatchTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<TmpAccountingBatchFilterInput>>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  associatedAccountingBatchId?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isFake?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TmpAccountingBatchFilterInput>>;
  overrideExportDate?: InputMaybe<DateOperationFilterInput>;
};

export type TmpAccountingBatchLineLink = {
  __typename?: 'TmpAccountingBatchLineLink';
  accountingBatchId: Scalars['Int'];
  accountingBatchLineLinkId: Scalars['Int'];
  accountingLineId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  subTypeLookupId?: Maybe<Scalars['Int']>;
};

export type TmpAccountingBatchLineLinkFilterInput = {
  accountingBatchId?: InputMaybe<IntOperationFilterInput>;
  accountingBatchLineLinkId?: InputMaybe<IntOperationFilterInput>;
  accountingLineId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<TmpAccountingBatchLineLinkFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TmpAccountingBatchLineLinkFilterInput>>;
  subTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type TmpAccountingLine = {
  __typename?: 'TmpAccountingLine';
  accountingLineId: Scalars['Int'];
  appLocationId?: Maybe<Scalars['Int']>;
  axclientCode?: Maybe<Scalars['String']>;
  axdocumentDate?: Maybe<Scalars['String']>;
  axitemCode?: Maybe<Scalars['String']>;
  axmonth?: Maybe<Scalars['String']>;
  axprojectCode?: Maybe<Scalars['String']>;
  axvendorCode?: Maybe<Scalars['String']>;
  billableLeads?: Maybe<Scalars['Int']>;
  buyType?: Maybe<Scalars['String']>;
  buyTypeId?: Maybe<Scalars['Int']>;
  buyTypeNavigation?: Maybe<Lookup>;
  clientName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  costAmount?: Maybe<Scalars['Decimal']>;
  costDescription?: Maybe<Scalars['String']>;
  cpl?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['Int']>;
  isClientPaying?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isFee: Scalars['Boolean'];
  isPartnership: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  monthDefinitionLookup?: Maybe<Lookup>;
  monthDefinitionLookupId?: Maybe<Scalars['Int']>;
  payableLeads?: Maybe<Scalars['Int']>;
  paymentFileReferenceId?: Maybe<Scalars['Int']>;
  payout?: Maybe<Scalars['Decimal']>;
  refAccountingBatchId?: Maybe<Scalars['Int']>;
  refAccountingLineId?: Maybe<Scalars['Int']>;
  revenueAmount?: Maybe<Scalars['Decimal']>;
  revenueDescription?: Maybe<Scalars['String']>;
  scenario?: Maybe<Scalars['String']>;
  scheduleId?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  skuNavigation?: Maybe<Sku>;
  skuid?: Maybe<Scalars['Int']>;
  subTypeLookupId?: Maybe<Scalars['Int']>;
  weekOf?: Maybe<Scalars['Date']>;
};

export type TmpAccountingLineFilterInput = {
  accountingLineId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<TmpAccountingLineFilterInput>>;
  appLocationId?: InputMaybe<IntOperationFilterInput>;
  axclientCode?: InputMaybe<StringOperationFilterInput>;
  axdocumentDate?: InputMaybe<StringOperationFilterInput>;
  axitemCode?: InputMaybe<StringOperationFilterInput>;
  axmonth?: InputMaybe<StringOperationFilterInput>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  billableLeads?: InputMaybe<IntOperationFilterInput>;
  buyType?: InputMaybe<StringOperationFilterInput>;
  buyTypeId?: InputMaybe<IntOperationFilterInput>;
  buyTypeNavigation?: InputMaybe<LookupFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costAmount?: InputMaybe<DecimalOperationFilterInput>;
  costDescription?: InputMaybe<StringOperationFilterInput>;
  cpl?: InputMaybe<DecimalOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  isClientPaying?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isFee?: InputMaybe<BooleanOperationFilterInput>;
  isPartnership?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  monthDefinitionLookup?: InputMaybe<LookupFilterInput>;
  monthDefinitionLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TmpAccountingLineFilterInput>>;
  payableLeads?: InputMaybe<IntOperationFilterInput>;
  paymentFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  payout?: InputMaybe<DecimalOperationFilterInput>;
  refAccountingBatchId?: InputMaybe<IntOperationFilterInput>;
  refAccountingLineId?: InputMaybe<IntOperationFilterInput>;
  revenueAmount?: InputMaybe<DecimalOperationFilterInput>;
  revenueDescription?: InputMaybe<StringOperationFilterInput>;
  scenario?: InputMaybe<StringOperationFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  sku?: InputMaybe<StringOperationFilterInput>;
  skuNavigation?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  subTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  weekOf?: InputMaybe<DateOperationFilterInput>;
};

export type TmpInvoice = {
  __typename?: 'TmpInvoice';
  amount?: Maybe<Scalars['Decimal']>;
  amountInLocalCurrency?: Maybe<Scalars['Decimal']>;
  appUser?: Maybe<AppUser>;
  appUserId?: Maybe<Scalars['Int']>;
  axvendorCode?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  datePaid?: Maybe<Scalars['DateTime']>;
  dateReceived?: Maybe<Scalars['Date']>;
  fileName?: Maybe<Scalars['String']>;
  fileReference?: Maybe<FileReference>;
  fileReferenceId?: Maybe<Scalars['Int']>;
  internalComment?: Maybe<Scalars['String']>;
  invoiceFileReference?: Maybe<FileReference>;
  invoiceFileReferenceId?: Maybe<Scalars['Int']>;
  invoiceId: Scalars['Int'];
  isCreatedByProcessor: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  oldid?: Maybe<Scalars['Int']>;
  parentInvoice?: Maybe<ParentInvoice>;
  parentInvoiceId?: Maybe<Scalars['Int']>;
  paymentFileReference?: Maybe<FileReference>;
  paymentFileReferenceId?: Maybe<Scalars['Int']>;
  paymentReference?: Maybe<Scalars['String']>;
  skipSalesTax: Scalars['Boolean'];
  vendorInvoiceNumber?: Maybe<Scalars['String']>;
};

export type TmpInvoiceFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  amountInLocalCurrency?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<TmpInvoiceFilterInput>>;
  appUser?: InputMaybe<AppUserFilterInput>;
  appUserId?: InputMaybe<IntOperationFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  datePaid?: InputMaybe<DateTimeOperationFilterInput>;
  dateReceived?: InputMaybe<DateOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  fileReference?: InputMaybe<FileReferenceFilterInput>;
  fileReferenceId?: InputMaybe<IntOperationFilterInput>;
  internalComment?: InputMaybe<StringOperationFilterInput>;
  invoiceFileReference?: InputMaybe<FileReferenceFilterInput>;
  invoiceFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  invoiceId?: InputMaybe<IntOperationFilterInput>;
  isCreatedByProcessor?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TmpInvoiceFilterInput>>;
  parentInvoice?: InputMaybe<ParentInvoiceFilterInput>;
  parentInvoiceId?: InputMaybe<IntOperationFilterInput>;
  paymentFileReference?: InputMaybe<FileReferenceFilterInput>;
  paymentFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  paymentReference?: InputMaybe<StringOperationFilterInput>;
  skipSalesTax?: InputMaybe<BooleanOperationFilterInput>;
  vendorInvoiceNumber?: InputMaybe<StringOperationFilterInput>;
};

export type TopLineMediaPlan = {
  __typename?: 'TopLineMediaPlan';
  budget?: Maybe<Scalars['Decimal']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createDate: Scalars['DateTime'];
  flightEnd?: Maybe<Scalars['Date']>;
  flightStart?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  nielsenDemoLookupId1?: Maybe<Scalars['Int']>;
  nielsenDemoLookupId1Navigation?: Maybe<Lookup>;
  nielsenDemoLookupId2?: Maybe<Scalars['Int']>;
  nielsenDemoLookupId2Navigation?: Maybe<Lookup>;
  nielsenDemoLookupId3?: Maybe<Scalars['Int']>;
  nielsenDemoLookupId3Navigation?: Maybe<Lookup>;
  topLineMediaPlanDetails?: Maybe<Array<Maybe<TopLineMediaPlanDetail>>>;
  topLineMediaPlanId: Scalars['Int'];
};

export type TopLineMediaPlanDetail = {
  __typename?: 'TopLineMediaPlanDetail';
  avgGrossRate?: Maybe<Scalars['Decimal']>;
  avgRating1?: Maybe<Scalars['Decimal']>;
  avgRating2?: Maybe<Scalars['Decimal']>;
  avgRating3?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  daypart?: Maybe<Daypart>;
  daypartId?: Maybe<Scalars['Int']>;
  instances?: Maybe<Scalars['Decimal']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  percentageOfSpend?: Maybe<Scalars['Decimal']>;
  tapeLengthLookup?: Maybe<Lookup>;
  tapeLengthLookupId?: Maybe<Scalars['Int']>;
  topLineMediaPlan?: Maybe<TopLineMediaPlan>;
  topLineMediaPlanDetailAttributes?: Maybe<Array<Maybe<TopLineMediaPlanDetailAttribute>>>;
  topLineMediaPlanDetailId: Scalars['Int'];
  topLineMediaPlanId: Scalars['Int'];
};

export type TopLineMediaPlanDetailAttribute = {
  __typename?: 'TopLineMediaPlanDetailAttribute';
  attributeLookup?: Maybe<Lookup>;
  attributeLookupId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  topLineMediaPlanDetail?: Maybe<TopLineMediaPlanDetail>;
  topLineMediaPlanDetailAttributeId: Scalars['Int'];
  topLineMediaPlanDetailId: Scalars['Int'];
};

export type TopLineMediaPlanDetailAttributeFilterInput = {
  and?: InputMaybe<Array<TopLineMediaPlanDetailAttributeFilterInput>>;
  attributeLookup?: InputMaybe<LookupFilterInput>;
  attributeLookupId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TopLineMediaPlanDetailAttributeFilterInput>>;
  topLineMediaPlanDetail?: InputMaybe<TopLineMediaPlanDetailFilterInput>;
  topLineMediaPlanDetailAttributeId?: InputMaybe<IntOperationFilterInput>;
  topLineMediaPlanDetailId?: InputMaybe<IntOperationFilterInput>;
};

export type TopLineMediaPlanDetailFilterInput = {
  and?: InputMaybe<Array<TopLineMediaPlanDetailFilterInput>>;
  avgGrossRate?: InputMaybe<DecimalOperationFilterInput>;
  avgRating1?: InputMaybe<DecimalOperationFilterInput>;
  avgRating2?: InputMaybe<DecimalOperationFilterInput>;
  avgRating3?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  daypart?: InputMaybe<DaypartFilterInput>;
  daypartId?: InputMaybe<IntOperationFilterInput>;
  instances?: InputMaybe<DecimalOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TopLineMediaPlanDetailFilterInput>>;
  percentageOfSpend?: InputMaybe<DecimalOperationFilterInput>;
  tapeLengthLookup?: InputMaybe<LookupFilterInput>;
  tapeLengthLookupId?: InputMaybe<IntOperationFilterInput>;
  topLineMediaPlan?: InputMaybe<TopLineMediaPlanFilterInput>;
  topLineMediaPlanDetailAttributes?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailAttributeFilterInput>;
  topLineMediaPlanDetailId?: InputMaybe<IntOperationFilterInput>;
  topLineMediaPlanId?: InputMaybe<IntOperationFilterInput>;
};

export type TopLineMediaPlanFilterInput = {
  and?: InputMaybe<Array<TopLineMediaPlanFilterInput>>;
  budget?: InputMaybe<DecimalOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  flightEnd?: InputMaybe<DateOperationFilterInput>;
  flightStart?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nielsenDemoLookupId1?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoLookupId1Navigation?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId2?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoLookupId2Navigation?: InputMaybe<LookupFilterInput>;
  nielsenDemoLookupId3?: InputMaybe<IntOperationFilterInput>;
  nielsenDemoLookupId3Navigation?: InputMaybe<LookupFilterInput>;
  or?: InputMaybe<Array<TopLineMediaPlanFilterInput>>;
  topLineMediaPlanDetails?: InputMaybe<ListFilterInputTypeOfTopLineMediaPlanDetailFilterInput>;
  topLineMediaPlanId?: InputMaybe<IntOperationFilterInput>;
};

/** Duplicate of TrackerDB's AppUserRole class, because this project references a different .Net framework and can't reference that project. */
export type TrackerAppUserRole = {
  __typename?: 'TrackerAppUserRole';
  appUserId: Scalars['Int'];
  appUserRoleId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaTypeId?: Maybe<Scalars['Int']>;
  roleLookupId: Scalars['Int'];
};

export enum TrackerBatchStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Incomplete = 'INCOMPLETE',
  InProcess = 'IN_PROCESS',
  Ready = 'READY'
}

export enum TrackerBatchType {
  LfClear = 'LF_CLEAR',
  LfPrePay = 'LF_PRE_PAY',
  SfInvoice = 'SF_INVOICE',
  SfRevRec = 'SF_REV_REC'
}

export type TrackerBatchWithTransIdDetails = {
  __typename?: 'TrackerBatchWithTransIdDetails';
  trackerBatch?: Maybe<TrackerBatches>;
  transIds: Array<Scalars['String']>;
};

export type TrackerBatches = {
  __typename?: 'TrackerBatches';
  batchStatus: TrackerBatchStatus;
  batchType: TrackerBatchType;
  dataAreaId: Scalars['String'];
  importDate: Scalars['DateTime'];
  postedDateTime: Scalars['DateTime'];
  postedLines: Scalars['Int'];
  trackerBatchId: Scalars['String'];
};

export type TrackerClearance_ForDisplay = {
  __typename?: 'TrackerClearance_ForDisplay';
  brandCode: Scalars['String'];
  buyTypeCategory: Scalars['String'];
  clientCode: Scalars['String'];
  clientDim: AxClientDim;
  clientName: Scalars['String'];
  clientPayingAmount: Scalars['Decimal'];
  clientValue: Scalars['String'];
  contractTermId: Scalars['Int'];
  cost?: Maybe<Scalars['Decimal']>;
  dataAreaId?: Maybe<Scalars['String']>;
  departmentCode: Scalars['String'];
  departmentDim: AxDepartmentDim;
  departmentName: Scalars['String'];
  departmentValue: Scalars['String'];
  divisionId: Scalars['Int'];
  divisionName: Scalars['String'];
  feeAmount: Scalars['Decimal'];
  id: Scalars['String'];
  isClientPaying: Scalars['Boolean'];
  isDubsCost: Scalars['Boolean'];
  isFee: Scalars['Boolean'];
  mediaMonthCode: Scalars['String'];
  mediaMonthDim: AxMediaMonth;
  mediaMonthName: Scalars['String'];
  mediaMonthValue: Scalars['String'];
  monthDefinitionDescription: Scalars['String'];
  monthName: Scalars['String'];
  netSpend: Scalars['Decimal'];
  parentLineIds: Array<Scalars['String']>;
  projectCode: Scalars['String'];
  projectDim: AxProjectDim;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  revenue?: Maybe<Scalars['Decimal']>;
  simpleDepartmentCode: Scalars['String'];
  simpleDepartmentName: Scalars['String'];
  trackerClearanceId: Scalars['Int'];
  uniqueKey: Scalars['String'];
};

export type TrackerLfCleared = {
  __typename?: 'TrackerLFCleared';
  clientDim: Scalars['String'];
  costAmount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  departmentDim: Scalars['String'];
  description: Scalars['String'];
  detailDim: Scalars['String'];
  documentDate: Scalars['DateTime'];
  imported: NoYes;
  lfClearedRecId: Scalars['Long'];
  mediaMonth: Scalars['String'];
  mediaType: D365MediaType;
  projId: Scalars['String'];
  revenueAmount: Scalars['Decimal'];
  scenarioId: Scalars['String'];
  scheduleId: Scalars['String'];
  trackerBatchId: Scalars['String'];
  trackerRefBatchId: Scalars['String'];
  trackerRefTransId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  vendInvoiceID: Scalars['String'];
};

export type TrackerLfClearedInput = {
  clientDim: Scalars['String'];
  costAmount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  departmentDim: Scalars['String'];
  description: Scalars['String'];
  detailDim: Scalars['String'];
  documentDate: Scalars['DateTime'];
  imported: NoYes;
  lfClearedRecId: Scalars['Long'];
  mediaMonth: Scalars['String'];
  mediaType: D365MediaType;
  projId: Scalars['String'];
  revenueAmount: Scalars['Decimal'];
  scenarioId: Scalars['String'];
  scheduleId: Scalars['String'];
  trackerBatchId: Scalars['String'];
  trackerRefBatchId: Scalars['String'];
  trackerRefTransId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  vendInvoiceID: Scalars['String'];
};

export type TrackerLfPrepayment = {
  __typename?: 'TrackerLFPrepayment';
  amount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  imported: NoYes;
  lfPrepaymentRecId: Scalars['Long'];
  mediaType: D365MediaType;
  projId: Scalars['String'];
  scenarioId: Scalars['String'];
  scheduleId: Scalars['String'];
  trackerBatchId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  vendInvoiceID: Scalars['String'];
};

export type TrackerLfPrepaymentInput = {
  amount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  imported: NoYes;
  lfPrepaymentRecId: Scalars['Long'];
  mediaType: D365MediaType;
  projId: Scalars['String'];
  scenarioId: Scalars['String'];
  scheduleId: Scalars['String'];
  trackerBatchId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  vendInvoiceID: Scalars['String'];
};

export type TrackerSfInvoice = {
  __typename?: 'TrackerSFInvoice';
  amount: Scalars['Decimal'];
  buyType: D365TrackerBuyType;
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  departmentDim: Scalars['String'];
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  imported: NoYes;
  isFee: NoYes;
  leads: Scalars['Int'];
  mediaMonth: Scalars['String'];
  mediaType: D365MediaType;
  payout: Scalars['Decimal'];
  projId: Scalars['String'];
  sfInvoiceRecId: Scalars['Long'];
  trackerBatchId: Scalars['String'];
  trackerRefBatchId: Scalars['String'];
  trackerRefTransId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  vendInvoiceId: Scalars['String'];
};

export type TrackerSfInvoiceInput = {
  amount: Scalars['Decimal'];
  buyType: D365TrackerBuyType;
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  departmentDim: Scalars['String'];
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  imported: NoYes;
  isFee: NoYes;
  leads: Scalars['Int'];
  mediaMonth: Scalars['String'];
  mediaType: D365MediaType;
  payout: Scalars['Decimal'];
  projId: Scalars['String'];
  sfInvoiceRecId: Scalars['Long'];
  trackerBatchId: Scalars['String'];
  trackerRefBatchId: Scalars['String'];
  trackerRefTransId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  vendInvoiceId: Scalars['String'];
};

export type TrackerSfRevenueRec = {
  __typename?: 'TrackerSFRevenueRec';
  billableLeads: Scalars['Int'];
  buyType: D365TrackerBuyType;
  clientDim: Scalars['String'];
  clientName: Scalars['String'];
  costAmount: Scalars['Decimal'];
  costDescription: Scalars['String'];
  cpl: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  departmentDim: Scalars['String'];
  description: Scalars['String'];
  detailDim: Scalars['String'];
  imported: NoYes;
  isClientPaying: NoYes;
  isFee: NoYes;
  mediaMonth: Scalars['String'];
  mediaType: D365MediaType;
  payableLeads: Scalars['Int'];
  payout: Scalars['Decimal'];
  projId: Scalars['String'];
  revenueAmount: Scalars['Decimal'];
  revenueDescription: Scalars['String'];
  scheduleId: Scalars['Int'];
  sfRevenueRecId: Scalars['Long'];
  sku: Scalars['String'];
  trackerBatchId: Scalars['String'];
  trackerRefBatchId: Scalars['String'];
  trackerRefTransId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  weekOf: Scalars['String'];
};

export type TrackerSfRevenueRecInput = {
  billableLeads: Scalars['Int'];
  buyType: D365TrackerBuyType;
  clientDim: Scalars['String'];
  clientName: Scalars['String'];
  costAmount: Scalars['Decimal'];
  costDescription: Scalars['String'];
  cpl: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  departmentDim: Scalars['String'];
  description: Scalars['String'];
  detailDim: Scalars['String'];
  imported: NoYes;
  isClientPaying: NoYes;
  isFee: NoYes;
  mediaMonth: Scalars['String'];
  mediaType: D365MediaType;
  payableLeads: Scalars['Int'];
  payout: Scalars['Decimal'];
  projId: Scalars['String'];
  revenueAmount: Scalars['Decimal'];
  revenueDescription: Scalars['String'];
  scheduleId: Scalars['Int'];
  sfRevenueRecId: Scalars['Long'];
  sku: Scalars['String'];
  trackerBatchId: Scalars['String'];
  trackerRefBatchId: Scalars['String'];
  trackerRefTransId: Scalars['String'];
  trackerSubType: TrackerSubType;
  trackerTransId: Scalars['String'];
  transDate: Scalars['DateTime'];
  vendAccount: Scalars['String'];
  weekOf: Scalars['String'];
};

export enum TrackerSubType {
  LfmcClearedDidNotAir = 'LFMC_CLEARED_DID_NOT_AIR',
  LfmcNonPrepaidCleared = 'LFMC_NON_PREPAID_CLEARED',
  LfmcPrepaidCleared = 'LFMC_PREPAID_CLEARED',
  LfmcPrepaidDidNotAir = 'LFMC_PREPAID_DID_NOT_AIR',
  LfmcReversalDidAir = 'LFMC_REVERSAL_DID_AIR',
  LfpoiInvoiceUpdate = 'LFPOI_INVOICE_UPDATE',
  LfvpCredit = 'LFVP_CREDIT',
  LfvpPrepayment = 'LFVP_PREPAYMENT',
  SfmcNonPrepaidCleared = 'SFMC_NON_PREPAID_CLEARED',
  SfpoiInvoiceUpdate = 'SFPOI_INVOICE_UPDATE'
}

export type TrackingCode = {
  __typename?: 'TrackingCode';
  adInstanceImports?: Maybe<Array<Maybe<AdInstanceImport>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  inputFieldMappings?: Maybe<Array<Maybe<InputFieldMapping>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  oldid?: Maybe<Scalars['Int']>;
  resultManualEntryDetails?: Maybe<Array<Maybe<ResultManualEntryDetail>>>;
  resultStagings?: Maybe<Array<Maybe<ResultStaging>>>;
  results?: Maybe<Array<Maybe<Result>>>;
  trackingCode1?: Maybe<Scalars['String']>;
  trackingCodeAvailTrackingCodes?: Maybe<Array<Maybe<TrackingCodeAvailTrackingCode>>>;
  trackingCodeId: Scalars['Int'];
  trackingCodeMap?: Maybe<TrackingCodeMap>;
  trackingCodeTypeLookup?: Maybe<Lookup>;
  trackingCodeTypeLookupId: Scalars['Int'];
  uniqueAdTrackingCodes?: Maybe<Array<Maybe<UniqueAdTrackingCode>>>;
};

export type TrackingCodeAvail = {
  __typename?: 'TrackingCodeAvail';
  closedCaption: Scalars['Boolean'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  createDate: Scalars['DateTime'];
  customizationSent?: Maybe<Scalars['DateTime']>;
  customizeAd?: Maybe<Ad>;
  customizeAdId?: Maybe<Scalars['Int']>;
  customizeAppUser?: Maybe<AppUser>;
  customizeAppUserId?: Maybe<Scalars['Int']>;
  customizeDate?: Maybe<Scalars['DateTime']>;
  inactivationDate?: Maybe<Scalars['DateTime']>;
  inactive: Scalars['Boolean'];
  intendedAd?: Maybe<Ad>;
  intendedAdId?: Maybe<Scalars['Int']>;
  intendedMediaOutlet?: Maybe<MediaOutlet>;
  intendedMediaOutletId?: Maybe<Scalars['Int']>;
  isci?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  oldtableName?: Maybe<Scalars['String']>;
  pimediaCode?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  script?: Maybe<Script>;
  scriptId?: Maybe<Scalars['Int']>;
  senderAppUser?: Maybe<AppUser>;
  senderAppUserId?: Maybe<Scalars['Int']>;
  testAppUser?: Maybe<AppUser>;
  testAppUserId?: Maybe<Scalars['Int']>;
  testDate?: Maybe<Scalars['DateTime']>;
  testNotes?: Maybe<Scalars['String']>;
  testStatusLookup?: Maybe<Lookup>;
  testStatusLookupId?: Maybe<Scalars['Int']>;
  trackingCodeAvailGroup?: Maybe<TrackingCodeAvailGroup>;
  trackingCodeAvailGroupId?: Maybe<Scalars['Int']>;
  trackingCodeAvailId: Scalars['Int'];
  trackingCodeAvailTrackingCodes?: Maybe<Array<Maybe<TrackingCodeAvailTrackingCode>>>;
  trackingCodeImport?: Maybe<TrackingCodeImport>;
  trackingCodeImportId?: Maybe<Scalars['Int']>;
};

export type TrackingCodeAvailFilterInput = {
  and?: InputMaybe<Array<TrackingCodeAvailFilterInput>>;
  closedCaption?: InputMaybe<BooleanOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  customizationSent?: InputMaybe<DateTimeOperationFilterInput>;
  customizeAd?: InputMaybe<AdFilterInput>;
  customizeAdId?: InputMaybe<IntOperationFilterInput>;
  customizeAppUser?: InputMaybe<AppUserFilterInput>;
  customizeAppUserId?: InputMaybe<IntOperationFilterInput>;
  customizeDate?: InputMaybe<DateTimeOperationFilterInput>;
  inactivationDate?: InputMaybe<DateTimeOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  intendedAd?: InputMaybe<AdFilterInput>;
  intendedAdId?: InputMaybe<IntOperationFilterInput>;
  intendedMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  intendedMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  isci?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TrackingCodeAvailFilterInput>>;
  pimediaCode?: InputMaybe<StringOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  script?: InputMaybe<ScriptFilterInput>;
  scriptId?: InputMaybe<IntOperationFilterInput>;
  senderAppUser?: InputMaybe<AppUserFilterInput>;
  senderAppUserId?: InputMaybe<IntOperationFilterInput>;
  testAppUser?: InputMaybe<AppUserFilterInput>;
  testAppUserId?: InputMaybe<IntOperationFilterInput>;
  testDate?: InputMaybe<DateTimeOperationFilterInput>;
  testNotes?: InputMaybe<StringOperationFilterInput>;
  testStatusLookup?: InputMaybe<LookupFilterInput>;
  testStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvailGroup?: InputMaybe<TrackingCodeAvailGroupFilterInput>;
  trackingCodeAvailGroupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvailId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvailTrackingCodes?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailTrackingCodeFilterInput>;
  trackingCodeImport?: InputMaybe<TrackingCodeImportFilterInput>;
  trackingCodeImportId?: InputMaybe<IntOperationFilterInput>;
};

export type TrackingCodeAvailGroup = {
  __typename?: 'TrackingCodeAvailGroup';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  trackingCodeAvailGroupId: Scalars['Int'];
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
};

export type TrackingCodeAvailGroupFilterInput = {
  and?: InputMaybe<Array<TrackingCodeAvailGroupFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TrackingCodeAvailGroupFilterInput>>;
  trackingCodeAvailGroupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
};

export type TrackingCodeAvailSetup = {
  __typename?: 'TrackingCodeAvailSetup';
  displayName?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  priorityOrder: Scalars['Int'];
  trackingCodeAvailSetupId: Scalars['Int'];
  trackingCodeAvailSetupTypeLookup?: Maybe<Lookup>;
  trackingCodeAvailSetupTypeLookupId: Scalars['Int'];
  trackingCodeTypeLookup?: Maybe<Lookup>;
  trackingCodeTypeLookupId: Scalars['Int'];
};

export type TrackingCodeAvailSetupFilterInput = {
  and?: InputMaybe<Array<TrackingCodeAvailSetupFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TrackingCodeAvailSetupFilterInput>>;
  priorityOrder?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvailSetupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvailSetupTypeLookup?: InputMaybe<LookupFilterInput>;
  trackingCodeAvailSetupTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeTypeLookup?: InputMaybe<LookupFilterInput>;
  trackingCodeTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type TrackingCodeAvailTrackingCode = {
  __typename?: 'TrackingCodeAvailTrackingCode';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  priorityOrder: Scalars['Int'];
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeAvail?: Maybe<TrackingCodeAvail>;
  trackingCodeAvailId: Scalars['Int'];
  trackingCodeAvailTrackingCodeId: Scalars['Int'];
  trackingCodeId: Scalars['Int'];
};

export type TrackingCodeAvailTrackingCodeFilterInput = {
  and?: InputMaybe<Array<TrackingCodeAvailTrackingCodeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TrackingCodeAvailTrackingCodeFilterInput>>;
  priorityOrder?: InputMaybe<IntOperationFilterInput>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeAvail?: InputMaybe<TrackingCodeAvailFilterInput>;
  trackingCodeAvailId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeAvailTrackingCodeId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
};

export type TrackingCodeFilterInput = {
  adInstanceImports?: InputMaybe<ListFilterInputTypeOfAdInstanceImportFilterInput>;
  and?: InputMaybe<Array<TrackingCodeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  inputFieldMappings?: InputMaybe<ListFilterInputTypeOfInputFieldMappingFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TrackingCodeFilterInput>>;
  resultManualEntryDetails?: InputMaybe<ListFilterInputTypeOfResultManualEntryDetailFilterInput>;
  resultStagings?: InputMaybe<ListFilterInputTypeOfResultStagingFilterInput>;
  results?: InputMaybe<ListFilterInputTypeOfResultFilterInput>;
  trackingCode1?: InputMaybe<StringOperationFilterInput>;
  trackingCodeAvailTrackingCodes?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailTrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeMap?: InputMaybe<TrackingCodeMapFilterInput>;
  trackingCodeTypeLookup?: InputMaybe<LookupFilterInput>;
  trackingCodeTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdTrackingCodes?: InputMaybe<ListFilterInputTypeOfUniqueAdTrackingCodeFilterInput>;
};

export type TrackingCodeImport = {
  __typename?: 'TrackingCodeImport';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId: Scalars['Int'];
  dateReceived: Scalars['Date'];
  importAppUser?: Maybe<AppUser>;
  importAppUserId: Scalars['Int'];
  importDate: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  trackingCodeAvails?: Maybe<Array<Maybe<TrackingCodeAvail>>>;
  trackingCodeImportId: Scalars['Int'];
  tsbvendor?: Maybe<Vendor>;
  tsbvendorId?: Maybe<Scalars['Int']>;
};

export type TrackingCodeImportFilterInput = {
  and?: InputMaybe<Array<TrackingCodeImportFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  dateReceived?: InputMaybe<DateOperationFilterInput>;
  importAppUser?: InputMaybe<AppUserFilterInput>;
  importAppUserId?: InputMaybe<IntOperationFilterInput>;
  importDate?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TrackingCodeImportFilterInput>>;
  trackingCodeAvails?: InputMaybe<ListFilterInputTypeOfTrackingCodeAvailFilterInput>;
  trackingCodeImportId?: InputMaybe<IntOperationFilterInput>;
  tsbvendor?: InputMaybe<VendorFilterInput>;
  tsbvendorId?: InputMaybe<IntOperationFilterInput>;
};

export type TrackingCodeMap = {
  __typename?: 'TrackingCodeMap';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeId: Scalars['Int'];
  trackingCodeMapId: Scalars['Int'];
  trackingCodeToMap?: Maybe<Scalars['String']>;
};

export type TrackingCodeMapFilterInput = {
  and?: InputMaybe<Array<TrackingCodeMapFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TrackingCodeMapFilterInput>>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeMapId?: InputMaybe<IntOperationFilterInput>;
  trackingCodeToMap?: InputMaybe<StringOperationFilterInput>;
};

export type TrackingServiceLine = {
  __typename?: 'TrackingServiceLine';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  fileImport?: Maybe<FileImport>;
  fileImportId: Scalars['Int'];
  isci?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lineText?: Maybe<Scalars['String']>;
  trackingServiceLineId: Scalars['Int'];
};

export type TrackingServiceLineFilterInput = {
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<TrackingServiceLineFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  fileImport?: InputMaybe<FileImportFilterInput>;
  fileImportId?: InputMaybe<IntOperationFilterInput>;
  isci?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lineText?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TrackingServiceLineFilterInput>>;
  trackingServiceLineId?: InputMaybe<IntOperationFilterInput>;
};

export type TrafficSheet = {
  __typename?: 'TrafficSheet';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUser?: Maybe<AppUser>;
  createAppUserId: Scalars['Int'];
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  crossChannelCampaignId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  trafficSheetDetails?: Maybe<Array<Maybe<TrafficSheetDetail>>>;
  trafficSheetId: Scalars['Int'];
};

export type TrafficSheetDetail = {
  __typename?: 'TrafficSheetDetail';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  countryLookup?: Maybe<Lookup>;
  countryLookupId: Scalars['Int'];
  demandSidePlatformMediaOutlet?: Maybe<MediaOutlet>;
  demandSidePlatformMediaOutletId?: Maybe<Scalars['Int']>;
  digitalRegionLookup?: Maybe<Lookup>;
  digitalRegionLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  operatingSystemLookup?: Maybe<Lookup>;
  operatingSystemLookupId?: Maybe<Scalars['Int']>;
  placementName?: Maybe<Scalars['String']>;
  productAudience?: Maybe<ProductAudience>;
  productAudienceId?: Maybe<Scalars['Int']>;
  programmaticExecutionLookup?: Maybe<Lookup>;
  programmaticExecutionLookupId?: Maybe<Scalars['Int']>;
  sizeLookup?: Maybe<Lookup>;
  sizeLookupId?: Maybe<Scalars['Int']>;
  strategyLookup?: Maybe<Lookup>;
  strategyLookupId: Scalars['Int'];
  subAudienceId?: Maybe<Scalars['Int']>;
  tacticLookup?: Maybe<Lookup>;
  tacticLookupId: Scalars['Int'];
  trafficSheet?: Maybe<TrafficSheet>;
  trafficSheetDetailDevices?: Maybe<Array<Maybe<TrafficSheetDetailDevice>>>;
  trafficSheetDetailId: Scalars['Int'];
  trafficSheetId: Scalars['Int'];
};

export type TrafficSheetDetailDevice = {
  __typename?: 'TrafficSheetDetailDevice';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  deviceLookup?: Maybe<Lookup>;
  deviceLookupId: Scalars['Int'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  trafficSheetDetail?: Maybe<TrafficSheetDetail>;
  trafficSheetDetailDeviceId: Scalars['Int'];
  trafficSheetDetailId: Scalars['Int'];
};

export type TrafficSheetDetailDeviceFilterInput = {
  and?: InputMaybe<Array<TrafficSheetDetailDeviceFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  deviceLookup?: InputMaybe<LookupFilterInput>;
  deviceLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TrafficSheetDetailDeviceFilterInput>>;
  trafficSheetDetail?: InputMaybe<TrafficSheetDetailFilterInput>;
  trafficSheetDetailDeviceId?: InputMaybe<IntOperationFilterInput>;
  trafficSheetDetailId?: InputMaybe<IntOperationFilterInput>;
};

export type TrafficSheetDetailDeviceInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  deviceLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  trafficSheetDetailDeviceId?: InputMaybe<Scalars['Int']>;
  trafficSheetDetailId?: InputMaybe<Scalars['Int']>;
};

export type TrafficSheetDetailFilterInput = {
  and?: InputMaybe<Array<TrafficSheetDetailFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  countryLookup?: InputMaybe<LookupFilterInput>;
  countryLookupId?: InputMaybe<IntOperationFilterInput>;
  demandSidePlatformMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  demandSidePlatformMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  digitalRegionLookup?: InputMaybe<LookupFilterInput>;
  digitalRegionLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  operatingSystemLookup?: InputMaybe<LookupFilterInput>;
  operatingSystemLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TrafficSheetDetailFilterInput>>;
  placementName?: InputMaybe<StringOperationFilterInput>;
  productAudience?: InputMaybe<ProductAudienceFilterInput>;
  productAudienceId?: InputMaybe<IntOperationFilterInput>;
  programmaticExecutionLookup?: InputMaybe<LookupFilterInput>;
  programmaticExecutionLookupId?: InputMaybe<IntOperationFilterInput>;
  sizeLookup?: InputMaybe<LookupFilterInput>;
  sizeLookupId?: InputMaybe<IntOperationFilterInput>;
  strategyLookup?: InputMaybe<LookupFilterInput>;
  strategyLookupId?: InputMaybe<IntOperationFilterInput>;
  subAudienceId?: InputMaybe<IntOperationFilterInput>;
  tacticLookup?: InputMaybe<LookupFilterInput>;
  tacticLookupId?: InputMaybe<IntOperationFilterInput>;
  trafficSheet?: InputMaybe<TrafficSheetFilterInput>;
  trafficSheetDetailDevices?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailDeviceFilterInput>;
  trafficSheetDetailId?: InputMaybe<IntOperationFilterInput>;
  trafficSheetId?: InputMaybe<IntOperationFilterInput>;
};

export type TrafficSheetDetailInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  countryLookupId?: InputMaybe<Scalars['Int']>;
  demandSidePlatformMediaOutletId?: InputMaybe<Scalars['Int']>;
  digitalRegionLookupId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  mediaOutletId?: InputMaybe<Scalars['Int']>;
  mediaTypeId?: InputMaybe<Scalars['Int']>;
  operatingSystemLookupId?: InputMaybe<Scalars['Int']>;
  placementName?: InputMaybe<Scalars['String']>;
  productAudienceId?: InputMaybe<Scalars['Int']>;
  programmaticExecutionLookupId?: InputMaybe<Scalars['Int']>;
  sizeLookupId?: InputMaybe<Scalars['Int']>;
  strategyLookupId?: InputMaybe<Scalars['Int']>;
  subAudienceId?: InputMaybe<Scalars['Int']>;
  tacticLookupId?: InputMaybe<Scalars['Int']>;
  trafficSheetDetailDevices?: InputMaybe<Array<InputMaybe<TrafficSheetDetailDeviceInput>>>;
  trafficSheetDetailId?: InputMaybe<Scalars['Int']>;
  trafficSheetId?: InputMaybe<Scalars['Int']>;
};

export type TrafficSheetDetail_ForLookup = {
  __typename?: 'TrafficSheetDetail_ForLookup';
  companyId?: Maybe<Scalars['Int']>;
  countryLookup?: Maybe<Lookup>;
  countryLookupId: Scalars['Int'];
  demandSidePlatformMediaOutlet?: Maybe<MediaOutlet>;
  demandSidePlatformMediaOutletId?: Maybe<Scalars['Int']>;
  digitalRegionLookup?: Maybe<Lookup>;
  digitalRegionLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  operatingSystemLookup?: Maybe<Lookup>;
  operatingSystemLookupId?: Maybe<Scalars['Int']>;
  placementName?: Maybe<Scalars['String']>;
  productAudience?: Maybe<ProductAudience>;
  productAudienceId?: Maybe<Scalars['Int']>;
  programmaticExecutionLookup?: Maybe<Lookup>;
  programmaticExecutionLookupId?: Maybe<Scalars['Int']>;
  sizeLookup?: Maybe<Lookup>;
  sizeLookupId?: Maybe<Scalars['Int']>;
  strategyLookup?: Maybe<Lookup>;
  strategyLookupId: Scalars['Int'];
  subAudienceId?: Maybe<Scalars['Int']>;
  tacticLookup?: Maybe<Lookup>;
  tacticLookupId: Scalars['Int'];
  trafficSheetDetailDevices?: Maybe<Array<TrafficSheetDetailDevice>>;
  trafficSheetDetailId: Scalars['Int'];
  trafficSheetId: Scalars['Int'];
};

export type TrafficSheetDetail_ForLookupFilterInput = {
  and?: InputMaybe<Array<TrafficSheetDetail_ForLookupFilterInput>>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  countryLookup?: InputMaybe<LookupFilterInput>;
  countryLookupId?: InputMaybe<IntOperationFilterInput>;
  demandSidePlatformMediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  demandSidePlatformMediaOutletId?: InputMaybe<IntOperationFilterInput>;
  digitalRegionLookup?: InputMaybe<LookupFilterInput>;
  digitalRegionLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  operatingSystemLookup?: InputMaybe<LookupFilterInput>;
  operatingSystemLookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TrafficSheetDetail_ForLookupFilterInput>>;
  placementName?: InputMaybe<StringOperationFilterInput>;
  productAudience?: InputMaybe<ProductAudienceFilterInput>;
  productAudienceId?: InputMaybe<IntOperationFilterInput>;
  programmaticExecutionLookup?: InputMaybe<LookupFilterInput>;
  programmaticExecutionLookupId?: InputMaybe<IntOperationFilterInput>;
  sizeLookup?: InputMaybe<LookupFilterInput>;
  sizeLookupId?: InputMaybe<IntOperationFilterInput>;
  strategyLookup?: InputMaybe<LookupFilterInput>;
  strategyLookupId?: InputMaybe<IntOperationFilterInput>;
  subAudienceId?: InputMaybe<IntOperationFilterInput>;
  tacticLookup?: InputMaybe<LookupFilterInput>;
  tacticLookupId?: InputMaybe<IntOperationFilterInput>;
  trafficSheetDetailDevices?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailDeviceFilterInput>;
  trafficSheetDetailId?: InputMaybe<IntOperationFilterInput>;
  trafficSheetId?: InputMaybe<IntOperationFilterInput>;
};

export type TrafficSheetFilterInput = {
  and?: InputMaybe<Array<TrafficSheetFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUser?: InputMaybe<AppUserFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignFilterInput>;
  crossChannelCampaignId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TrafficSheetFilterInput>>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetailFilterInput>;
  trafficSheetId?: InputMaybe<IntOperationFilterInput>;
};

export type TrafficSheetInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createAppUserId?: InputMaybe<Scalars['Int']>;
  crossChannelCampaignId?: InputMaybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: InputMaybe<Scalars['String']>;
  lastUpdatedUTC?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  trafficSheetDetails?: InputMaybe<Array<InputMaybe<TrafficSheetDetailInput>>>;
  trafficSheetId?: InputMaybe<Scalars['Int']>;
};

export type TrafficSheet_ForLookup = {
  __typename?: 'TrafficSheet_ForLookup';
  client?: Maybe<Client>;
  companyId?: Maybe<Scalars['Int']>;
  createAppUserDescription?: Maybe<Scalars['String']>;
  createAppUserId?: Maybe<Scalars['Int']>;
  crossChannelCampaign?: Maybe<CrossChannelCampaign>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  trafficSheetDetails?: Maybe<Array<TrafficSheetDetail_ForLookup>>;
  trafficSheetId: Scalars['Int'];
};

export type TrafficSheet_ForLookupFilterInput = {
  and?: InputMaybe<Array<TrafficSheet_ForLookupFilterInput>>;
  client?: InputMaybe<ClientFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  createAppUserId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaign?: InputMaybe<CrossChannelCampaignFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TrafficSheet_ForLookupFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  trafficSheetDetails?: InputMaybe<ListFilterInputTypeOfTrafficSheetDetail_ForLookupFilterInput>;
  trafficSheetId?: InputMaybe<IntOperationFilterInput>;
};

export type UnReversedItemsWithNoMediaMonth = {
  __typename?: 'UnReversedItemsWithNoMediaMonth';
  amount: Scalars['Decimal'];
  clientCode: Scalars['String'];
  companyCode: Scalars['String'];
  departmentCode: Scalars['String'];
  description: Scalars['String'];
  itemType: Scalars['String'];
  projectCode: Scalars['String'];
  transactionDate: Scalars['DateTime'];
  uniqueKey: Scalars['String'];
};

export type UniqueAd = {
  __typename?: 'UniqueAd';
  active?: Maybe<Scalars['Boolean']>;
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']>;
  assignerAppUser?: Maybe<AppUser>;
  assignerAppUserId?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  autoScheduleTrafficDetails?: Maybe<Array<Maybe<AutoScheduleTrafficDetail>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  creative?: Maybe<Creative>;
  creativeId?: Maybe<Scalars['Int']>;
  deactivateAppUser?: Maybe<AppUser>;
  deactivateAppUserId?: Maybe<Scalars['Int']>;
  deactivateDate?: Maybe<Scalars['DateTime']>;
  externalComment?: Maybe<Scalars['String']>;
  inverseParentUniqueAd?: Maybe<Array<Maybe<UniqueAd>>>;
  isExclusive: Scalars['Boolean'];
  isci?: Maybe<Scalars['String']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  mediaTransmissions?: Maybe<Array<Maybe<MediaTransmission>>>;
  oldid?: Maybe<Scalars['Int']>;
  oldnaturalKey?: Maybe<Scalars['String']>;
  parentUniqueAd?: Maybe<UniqueAd>;
  parentUniqueAdId?: Maybe<Scalars['Int']>;
  requestorAppUser?: Maybe<AppUser>;
  requestorAppUserId?: Maybe<Scalars['Int']>;
  testAppUser?: Maybe<AppUser>;
  testAppUserId?: Maybe<Scalars['Int']>;
  testDate?: Maybe<Scalars['DateTime']>;
  testNotes?: Maybe<Scalars['String']>;
  testStatusLookup?: Maybe<Lookup>;
  testStatusLookupId?: Maybe<Scalars['Int']>;
  uniqueAdId: Scalars['Int'];
  uniqueAdSchedules?: Maybe<Array<Maybe<UniqueAdSchedule>>>;
  uniqueAdTrackingCodes?: Maybe<Array<Maybe<UniqueAdTrackingCode>>>;
};

export type UniqueAdFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  ad?: InputMaybe<AdFilterInput>;
  adId?: InputMaybe<IntOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<UniqueAdFilterInput>>;
  assignerAppUser?: InputMaybe<AppUserFilterInput>;
  assignerAppUserId?: InputMaybe<IntOperationFilterInput>;
  assignmentDate?: InputMaybe<DateTimeOperationFilterInput>;
  autoScheduleTrafficDetails?: InputMaybe<ListFilterInputTypeOfAutoScheduleTrafficDetailFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  creative?: InputMaybe<CreativeFilterInput>;
  creativeId?: InputMaybe<IntOperationFilterInput>;
  deactivateAppUser?: InputMaybe<AppUserFilterInput>;
  deactivateAppUserId?: InputMaybe<IntOperationFilterInput>;
  deactivateDate?: InputMaybe<DateTimeOperationFilterInput>;
  externalComment?: InputMaybe<StringOperationFilterInput>;
  inverseParentUniqueAd?: InputMaybe<ListFilterInputTypeOfUniqueAdFilterInput>;
  isExclusive?: InputMaybe<BooleanOperationFilterInput>;
  isci?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaTransmissions?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldnaturalKey?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UniqueAdFilterInput>>;
  parentUniqueAd?: InputMaybe<UniqueAdFilterInput>;
  parentUniqueAdId?: InputMaybe<IntOperationFilterInput>;
  requestorAppUser?: InputMaybe<AppUserFilterInput>;
  requestorAppUserId?: InputMaybe<IntOperationFilterInput>;
  testAppUser?: InputMaybe<AppUserFilterInput>;
  testAppUserId?: InputMaybe<IntOperationFilterInput>;
  testDate?: InputMaybe<DateTimeOperationFilterInput>;
  testNotes?: InputMaybe<StringOperationFilterInput>;
  testStatusLookup?: InputMaybe<LookupFilterInput>;
  testStatusLookupId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdSchedules?: InputMaybe<ListFilterInputTypeOfUniqueAdScheduleFilterInput>;
  uniqueAdTrackingCodes?: InputMaybe<ListFilterInputTypeOfUniqueAdTrackingCodeFilterInput>;
};

export type UniqueAdSchedule = {
  __typename?: 'UniqueAdSchedule';
  adInstances?: Maybe<Array<Maybe<AdInstance>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dnaflag: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  logFileLines?: Maybe<Array<Maybe<LogFileLine>>>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  perInquiryClientCostPercent?: Maybe<Scalars['Decimal']>;
  perInquiryMediaOutletCostPercent?: Maybe<Scalars['Decimal']>;
  percent?: Maybe<Scalars['Decimal']>;
  percentCleared?: Maybe<Scalars['Decimal']>;
  prelogs?: Maybe<Array<Maybe<Prelog>>>;
  schedule?: Maybe<Schedule>;
  scheduleId: Scalars['Int'];
  uniqueAd?: Maybe<UniqueAd>;
  uniqueAdId?: Maybe<Scalars['Int']>;
  uniqueAdScheduleId: Scalars['Int'];
  uniqueAdScheduleLeadAdjustments?: Maybe<Array<Maybe<UniqueAdScheduleLeadAdjustment>>>;
  waveCastAdInstances?: Maybe<Array<Maybe<WaveCastAdInstance>>>;
};

export type UniqueAdScheduleFilterInput = {
  adInstances?: InputMaybe<ListFilterInputTypeOfAdInstanceFilterInput>;
  and?: InputMaybe<Array<UniqueAdScheduleFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dnaflag?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  logFileLines?: InputMaybe<ListFilterInputTypeOfLogFileLineFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UniqueAdScheduleFilterInput>>;
  perInquiryClientCostPercent?: InputMaybe<DecimalOperationFilterInput>;
  perInquiryMediaOutletCostPercent?: InputMaybe<DecimalOperationFilterInput>;
  percent?: InputMaybe<DecimalOperationFilterInput>;
  percentCleared?: InputMaybe<DecimalOperationFilterInput>;
  prelogs?: InputMaybe<ListFilterInputTypeOfPrelogFilterInput>;
  schedule?: InputMaybe<ScheduleFilterInput>;
  scheduleId?: InputMaybe<IntOperationFilterInput>;
  uniqueAd?: InputMaybe<UniqueAdFilterInput>;
  uniqueAdId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdScheduleLeadAdjustments?: InputMaybe<ListFilterInputTypeOfUniqueAdScheduleLeadAdjustmentFilterInput>;
  waveCastAdInstances?: InputMaybe<ListFilterInputTypeOfWaveCastAdInstanceFilterInput>;
};

export type UniqueAdScheduleLeadAdjustment = {
  __typename?: 'UniqueAdScheduleLeadAdjustment';
  adjustmentValue: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  sku?: Maybe<Sku>;
  skuid: Scalars['Int'];
  uniqueAdSchedule?: Maybe<UniqueAdSchedule>;
  uniqueAdScheduleId: Scalars['Int'];
  uniqueAdScheduleLeadAdjustmentId: Scalars['Int'];
};

export type UniqueAdScheduleLeadAdjustmentFilterInput = {
  adjustmentValue?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<UniqueAdScheduleLeadAdjustmentFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<UniqueAdScheduleLeadAdjustmentFilterInput>>;
  sku?: InputMaybe<SkuFilterInput>;
  skuid?: InputMaybe<IntOperationFilterInput>;
  uniqueAdSchedule?: InputMaybe<UniqueAdScheduleFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdScheduleLeadAdjustmentId?: InputMaybe<IntOperationFilterInput>;
};

export type UniqueAdTrackingCode = {
  __typename?: 'UniqueAdTrackingCode';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  priorityOrder: Scalars['Int'];
  trackingCode?: Maybe<TrackingCode>;
  trackingCodeId: Scalars['Int'];
  uniqueAd?: Maybe<UniqueAd>;
  uniqueAdId: Scalars['Int'];
  uniqueAdTrackingCodeId: Scalars['Int'];
};

export type UniqueAdTrackingCodeFilterInput = {
  and?: InputMaybe<Array<UniqueAdTrackingCodeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<UniqueAdTrackingCodeFilterInput>>;
  priorityOrder?: InputMaybe<IntOperationFilterInput>;
  trackingCode?: InputMaybe<TrackingCodeFilterInput>;
  trackingCodeId?: InputMaybe<IntOperationFilterInput>;
  uniqueAd?: InputMaybe<UniqueAdFilterInput>;
  uniqueAdId?: InputMaybe<IntOperationFilterInput>;
  uniqueAdTrackingCodeId?: InputMaybe<IntOperationFilterInput>;
};

export type UniquePersonDepartment = {
  __typename?: 'UniquePersonDepartment';
  departmentId: Scalars['Int'];
  personId: Scalars['Int'];
};

export type UpdateClientTransactionTermGroupOverrideError = ArgumentError | RainError;

export type UpdateClientTransactionTermGroupOverrideInput = {
  clientTransactionTermGroupType: ClientTransactionTermGroupType;
  uniqueKey: Scalars['String'];
};

export type UpdateClientTransactionTermGroupOverridePayload = {
  __typename?: 'UpdateClientTransactionTermGroupOverridePayload';
  clientTransaction_ForDisplay?: Maybe<ClientTransaction_ForDisplay>;
  errors?: Maybe<Array<UpdateClientTransactionTermGroupOverrideError>>;
};

export type UpdateProjectEstimateDepartmentDescriptionError = ArgumentError | RainError;

export type UpdateProjectEstimateDepartmentDescriptionInput = {
  description: Scalars['String'];
  projectEstimateDepartmentId: Scalars['Int'];
};

export type UpdateProjectEstimateDepartmentDescriptionPayload = {
  __typename?: 'UpdateProjectEstimateDepartmentDescriptionPayload';
  errors?: Maybe<Array<UpdateProjectEstimateDepartmentDescriptionError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type UpdateProjectEstimateDepartmentStatusError = ArgumentError | RainError;

export type UpdateProjectEstimateDepartmentStatusInput = {
  projectEstimateDepartmentId: Scalars['Int'];
  projectEstimateStatus: ProjectEstimateDepartmentStatus;
};

export type UpdateProjectEstimateDepartmentStatusPayload = {
  __typename?: 'UpdateProjectEstimateDepartmentStatusPayload';
  errors?: Maybe<Array<UpdateProjectEstimateDepartmentStatusError>>;
  projectEstimate_ForEditing?: Maybe<ProjectEstimate_ForEditing>;
};

export type UpdateRainOnePersonError = ArgumentError | RainError;

export type UpdateRainOnePersonInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  primaryEmailAddress: Scalars['String'];
  profileImageUrl?: InputMaybe<Scalars['String']>;
  rainOnePersonId: Scalars['Int'];
};

export type UpdateRainOnePersonPayload = {
  __typename?: 'UpdateRainOnePersonPayload';
  errors?: Maybe<Array<UpdateRainOnePersonError>>;
  rainOnePerson_ForDisplay?: Maybe<RainOnePerson_ForDisplay>;
};

export type UploadInfo = {
  __typename?: 'UploadInfo';
  baseUploadUrl: Scalars['String'];
  containerName: Scalars['String'];
  sasToken: Scalars['String'];
  storageAccountName: Scalars['String'];
};

export type UserActionItem = {
  __typename?: 'UserActionItem';
  actionItemId: Scalars['Int'];
  actionItemSubscriptionId: Scalars['Int'];
  actionItemType: ActionItemType;
  actionUrl: Scalars['String'];
  createdDateUTC: Scalars['DateTime'];
  description: Scalars['String'];
  hideLink: Scalars['Boolean'];
  id: Scalars['Int'];
  inAppPath: Scalars['String'];
  isHighPriority: Scalars['Boolean'];
  isHumanClearable: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  linkName: Scalars['String'];
  rainOneApp: RainOneApp;
  rainOnePersonId: Scalars['Int'];
};

export type UserDelgate = {
  __typename?: 'UserDelgate';
  endDate: Scalars['DateTime'];
  isCurrentUser: Scalars['Boolean'];
  networkUserName: Scalars['String'];
  startDate: Scalars['DateTime'];
  userDisplayName: Scalars['String'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  actionItemEmailDaysOfTheWeek: ActionItemEmailPreference;
  id: Scalars['Int'];
  picturePreference: PicturePreference;
  rainOnePersonId: Scalars['Int'];
  shoudlEmailSendToday: Scalars['Boolean'];
};


export type UserSettingsShoudlEmailSendTodayArgs = {
  fullDayholidays: Array<Scalars['DateTime']>;
};

export type UserSettingsInput = {
  actionItemEmailDaysOfTheWeek: ActionItemEmailPreference;
  id: Scalars['Int'];
  picturePreference: PicturePreference;
  rainOnePersonId: Scalars['Int'];
};

export type ValidDates = {
  __typename?: 'ValidDates';
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export enum ValidationLevel {
  Error = 'ERROR',
  Information = 'INFORMATION',
  LessCriticalWarning = 'LESS_CRITICAL_WARNING',
  Warning = 'WARNING'
}

export type ValidationMessage = {
  __typename?: 'ValidationMessage';
  anyPropertyValuesChanged: Scalars['Boolean'];
  appliedLevel: ValidationLevel;
  columnNamesToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
  entity?: Maybe<EntityBase>;
  hasChanged: Scalars['Boolean'];
  ignore: Scalars['Boolean'];
  ignoreAllInGroup: Scalars['Boolean'];
  isChangeTrackingEnabled: Scalars['Boolean'];
  isError: Scalars['Boolean'];
  isGroupIgnorable: Scalars['Boolean'];
  isInformation: Scalars['Boolean'];
  isWarning: Scalars['Boolean'];
  itemEntity?: Maybe<EntityBase>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedDescription?: Maybe<Scalars['String']>;
  lastUpdatedUTC?: Maybe<Scalars['DateTime']>;
  level: ValidationLevel;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalPropertyValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  parent?: Maybe<EntityBase>;
  propertyName?: Maybe<Scalars['String']>;
  propertyValueHasChanged: Scalars['Boolean'];
  uniqueKey?: Maybe<Scalars['String']>;
};


export type ValidationMessagePropertyValueHasChangedArgs = {
  propertyName?: InputMaybe<Scalars['String']>;
};

export type VendTrans = {
  __typename?: 'VendTrans';
  bankStatementSettlementDate: Scalars['DateTime'];
  checkDate: Scalars['DateTime'];
  chequeStatus: D365CheckStatus;
  companyId: Scalars['String'];
  correct: NoYes;
  currencyCode: Scalars['String'];
  dataAreaId: Scalars['String'];
  dimensionDisplayValue: Scalars['String'];
  groupName: Scalars['String'];
  invoiceAmount: Scalars['Decimal'];
  invoiceDate: Scalars['DateTime'];
  invoiceNumber: Scalars['String'];
  journalNum: Scalars['String'];
  lastSettleDate: Scalars['DateTime'];
  paidAmount: Scalars['Decimal'];
  paymReference: Scalars['String'];
  paymentMode: Scalars['String'];
  paymentRefNumber: Scalars['String'];
  purchId: Scalars['String'];
  transType: D365LedgerTransType;
  txt: Scalars['String'];
  vendTransRecId: Scalars['Long'];
  vendorName: Scalars['String'];
  vendorNumber: Scalars['String'];
  voucher: Scalars['String'];
};

export enum VendTransPaymentType {
  Check = 'CHECK',
  Eft = 'EFT'
}

export type Vendor = {
  __typename?: 'Vendor';
  adBrokerVendors?: Maybe<Array<Maybe<Ad>>>;
  adDubHouseVendors?: Maybe<Array<Maybe<Ad>>>;
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']>;
  addressTypeLookup?: Maybe<Lookup>;
  addressTypeLookupId?: Maybe<Scalars['Int']>;
  availProposals?: Maybe<Array<Maybe<AvailProposal>>>;
  axvendorCode?: Maybe<Scalars['String']>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  brokerCurrencyCodeLookup?: Maybe<Lookup>;
  brokerCurrencyCodeLookupId?: Maybe<Scalars['Int']>;
  brokerMediaTypes?: Maybe<Array<Maybe<BrokerMediaType>>>;
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  creatives?: Maybe<Array<Maybe<Creative>>>;
  duplicationInvoiceLines?: Maybe<Array<Maybe<DuplicationInvoiceLine>>>;
  inactive: Scalars['Boolean'];
  insertionOrders?: Maybe<Array<Maybe<InsertionOrder>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  mediaOutletMediaTypes?: Maybe<Array<Maybe<MediaOutletMediaType>>>;
  mediaOutletRates?: Maybe<Array<Maybe<MediaOutletRate>>>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaScheduleDetails?: Maybe<Array<Maybe<MediaScheduleDetail>>>;
  mediaTransmissionCourierVendors?: Maybe<Array<Maybe<MediaTransmission>>>;
  mediaTransmissionVendors?: Maybe<Array<Maybe<MediaTransmission>>>;
  name?: Maybe<Scalars['String']>;
  newCampaignAnnouncementDubHouseVendors?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  newCampaignAnnouncementPiDubHouseVendors?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncementPiTsbvendors?: Maybe<Array<Maybe<NewCampaignAnnouncementPi>>>;
  newCampaignAnnouncementTsbvendors?: Maybe<Array<Maybe<NewCampaignAnnouncement>>>;
  notes?: Maybe<Scalars['String']>;
  oldid?: Maybe<Scalars['Int']>;
  oldtableName?: Maybe<Scalars['String']>;
  productFulfillments?: Maybe<Array<Maybe<ProductFulfillment>>>;
  productTsbcontactLists?: Maybe<Array<Maybe<ProductTsbcontactList>>>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  trackingCodeImports?: Maybe<Array<Maybe<TrackingCodeImport>>>;
  tsbminimumMilesSeparation?: Maybe<Scalars['Int']>;
  tsbseats?: Maybe<Scalars['Int']>;
  tsbtimeZoneInfo?: Maybe<TimeZoneInfo>;
  tsbtimeZoneInfoId?: Maybe<Scalars['Int']>;
  vendorBlackoutDates?: Maybe<Array<Maybe<VendorBlackoutDate>>>;
  vendorContactRoles?: Maybe<Array<Maybe<VendorContactRole>>>;
  vendorId: Scalars['Int'];
  vendorLookups?: Maybe<Array<Maybe<VendorLookup>>>;
  vendorServices?: Maybe<Array<Maybe<VendorService>>>;
  vendorTypeLookup?: Maybe<Lookup>;
  vendorTypeLookupId: Scalars['Int'];
};

export type VendorBlackoutDate = {
  __typename?: 'VendorBlackoutDate';
  blackoutDate: Scalars['DateTime'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<Vendor>;
  vendorBlackoutDateId: Scalars['Int'];
  vendorId: Scalars['Int'];
};

export type VendorBlackoutDateFilterInput = {
  and?: InputMaybe<Array<VendorBlackoutDateFilterInput>>;
  blackoutDate?: InputMaybe<DateTimeOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<VendorBlackoutDateFilterInput>>;
  vendor?: InputMaybe<VendorFilterInput>;
  vendorBlackoutDateId?: InputMaybe<IntOperationFilterInput>;
  vendorId?: InputMaybe<IntOperationFilterInput>;
};

export type VendorContactRole = {
  __typename?: 'VendorContactRole';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  contactId: Scalars['Int'];
  contactType?: Maybe<ContactType>;
  contactTypeId: Scalars['Int'];
  inactive: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<Vendor>;
  vendorContactRoleId: Scalars['Int'];
  vendorId: Scalars['Int'];
};

export type VendorContactRoleFilterInput = {
  and?: InputMaybe<Array<VendorContactRoleFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  contactType?: InputMaybe<ContactTypeFilterInput>;
  contactTypeId?: InputMaybe<IntOperationFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<VendorContactRoleFilterInput>>;
  vendor?: InputMaybe<VendorFilterInput>;
  vendorContactRoleId?: InputMaybe<IntOperationFilterInput>;
  vendorId?: InputMaybe<IntOperationFilterInput>;
};

export type VendorDirectPayment = {
  __typename?: 'VendorDirectPayment';
  axprojectCode?: Maybe<Scalars['String']>;
  axvendorCode?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  effectiveDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  vendorDirectPaymentId: Scalars['Int'];
};

export type VendorDirectPaymentFilterInput = {
  and?: InputMaybe<Array<VendorDirectPaymentFilterInput>>;
  axprojectCode?: InputMaybe<StringOperationFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  client?: InputMaybe<ClientFilterInput>;
  clientId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<VendorDirectPaymentFilterInput>>;
  vendorDirectPaymentId?: InputMaybe<IntOperationFilterInput>;
};

export type VendorFilterInput = {
  adBrokerVendors?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  adDubHouseVendors?: InputMaybe<ListFilterInputTypeOfAdFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  addressTypeLookup?: InputMaybe<LookupFilterInput>;
  addressTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<VendorFilterInput>>;
  availProposals?: InputMaybe<ListFilterInputTypeOfAvailProposalFilterInput>;
  axvendorCode?: InputMaybe<StringOperationFilterInput>;
  bookings?: InputMaybe<ListFilterInputTypeOfBookingFilterInput>;
  brokerCurrencyCodeLookup?: InputMaybe<LookupFilterInput>;
  brokerCurrencyCodeLookupId?: InputMaybe<IntOperationFilterInput>;
  brokerMediaTypes?: InputMaybe<ListFilterInputTypeOfBrokerMediaTypeFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  creatives?: InputMaybe<ListFilterInputTypeOfCreativeFilterInput>;
  duplicationInvoiceLines?: InputMaybe<ListFilterInputTypeOfDuplicationInvoiceLineFilterInput>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  insertionOrders?: InputMaybe<ListFilterInputTypeOfInsertionOrderFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  mediaOutletMediaTypes?: InputMaybe<ListFilterInputTypeOfMediaOutletMediaTypeFilterInput>;
  mediaOutletRates?: InputMaybe<ListFilterInputTypeOfMediaOutletRateFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaScheduleDetails?: InputMaybe<ListFilterInputTypeOfMediaScheduleDetailFilterInput>;
  mediaTransmissionCourierVendors?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  mediaTransmissionVendors?: InputMaybe<ListFilterInputTypeOfMediaTransmissionFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  newCampaignAnnouncementDubHouseVendors?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  newCampaignAnnouncementPiDubHouseVendors?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementPiTsbvendors?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementPiFilterInput>;
  newCampaignAnnouncementTsbvendors?: InputMaybe<ListFilterInputTypeOfNewCampaignAnnouncementFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oldid?: InputMaybe<IntOperationFilterInput>;
  oldtableName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VendorFilterInput>>;
  productFulfillments?: InputMaybe<ListFilterInputTypeOfProductFulfillmentFilterInput>;
  productTsbcontactLists?: InputMaybe<ListFilterInputTypeOfProductTsbcontactListFilterInput>;
  schedules?: InputMaybe<ListFilterInputTypeOfScheduleFilterInput>;
  scripts?: InputMaybe<ListFilterInputTypeOfScriptFilterInput>;
  trackingCodeImports?: InputMaybe<ListFilterInputTypeOfTrackingCodeImportFilterInput>;
  tsbminimumMilesSeparation?: InputMaybe<IntOperationFilterInput>;
  tsbseats?: InputMaybe<IntOperationFilterInput>;
  tsbtimeZoneInfo?: InputMaybe<TimeZoneInfoFilterInput>;
  tsbtimeZoneInfoId?: InputMaybe<IntOperationFilterInput>;
  vendorBlackoutDates?: InputMaybe<ListFilterInputTypeOfVendorBlackoutDateFilterInput>;
  vendorContactRoles?: InputMaybe<ListFilterInputTypeOfVendorContactRoleFilterInput>;
  vendorId?: InputMaybe<IntOperationFilterInput>;
  vendorLookups?: InputMaybe<ListFilterInputTypeOfVendorLookupFilterInput>;
  vendorServices?: InputMaybe<ListFilterInputTypeOfVendorServiceFilterInput>;
  vendorTypeLookup?: InputMaybe<LookupFilterInput>;
  vendorTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type VendorLookup = {
  __typename?: 'VendorLookup';
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  lookup?: Maybe<Lookup>;
  lookupId: Scalars['Int'];
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
  vendorLookupId: Scalars['Int'];
};

export type VendorLookupFilterInput = {
  and?: InputMaybe<Array<VendorLookupFilterInput>>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lookup?: InputMaybe<LookupFilterInput>;
  lookupId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<VendorLookupFilterInput>>;
  vendor?: InputMaybe<VendorFilterInput>;
  vendorId?: InputMaybe<IntOperationFilterInput>;
  vendorLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type VendorService = {
  __typename?: 'VendorService';
  clientVendorServiceMarkups?: Maybe<Array<Maybe<ClientVendorServiceMarkup>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  crossChannelCampaignFees?: Maybe<Array<Maybe<CrossChannelCampaignFee>>>;
  feeCostMethodLookup?: Maybe<Lookup>;
  feeCostMethodLookupId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletType?: Maybe<MediaOutletType>;
  mediaOutletTypeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
  vendorServiceCosts?: Maybe<Array<Maybe<VendorServiceCost>>>;
  vendorServiceId: Scalars['Int'];
  vendorServiceMediaTypes?: Maybe<Array<Maybe<VendorServiceMediaType>>>;
};

export type VendorServiceCost = {
  __typename?: 'VendorServiceCost';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  cost: Scalars['Decimal'];
  effectiveDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  vendorService?: Maybe<VendorService>;
  vendorServiceCostId: Scalars['Int'];
  vendorServiceId: Scalars['Int'];
};

export type VendorServiceCostFilterInput = {
  and?: InputMaybe<Array<VendorServiceCostFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  cost?: InputMaybe<DecimalOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<VendorServiceCostFilterInput>>;
  vendorService?: InputMaybe<VendorServiceFilterInput>;
  vendorServiceCostId?: InputMaybe<IntOperationFilterInput>;
  vendorServiceId?: InputMaybe<IntOperationFilterInput>;
};

export type VendorServiceFilterInput = {
  and?: InputMaybe<Array<VendorServiceFilterInput>>;
  clientVendorServiceMarkups?: InputMaybe<ListFilterInputTypeOfClientVendorServiceMarkupFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  crossChannelCampaignFees?: InputMaybe<ListFilterInputTypeOfCrossChannelCampaignFeeFilterInput>;
  feeCostMethodLookup?: InputMaybe<LookupFilterInput>;
  feeCostMethodLookupId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletType?: InputMaybe<MediaOutletTypeFilterInput>;
  mediaOutletTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VendorServiceFilterInput>>;
  vendor?: InputMaybe<VendorFilterInput>;
  vendorId?: InputMaybe<IntOperationFilterInput>;
  vendorServiceCosts?: InputMaybe<ListFilterInputTypeOfVendorServiceCostFilterInput>;
  vendorServiceId?: InputMaybe<IntOperationFilterInput>;
  vendorServiceMediaTypes?: InputMaybe<ListFilterInputTypeOfVendorServiceMediaTypeFilterInput>;
};

export type VendorServiceMediaType = {
  __typename?: 'VendorServiceMediaType';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
  vendorService?: Maybe<VendorService>;
  vendorServiceId: Scalars['Int'];
  vendorServiceMediaTypeId: Scalars['Int'];
};

export type VendorServiceMediaTypeFilterInput = {
  and?: InputMaybe<Array<VendorServiceMediaTypeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  mediaTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<VendorServiceMediaTypeFilterInput>>;
  vendorService?: InputMaybe<VendorServiceFilterInput>;
  vendorServiceId?: InputMaybe<IntOperationFilterInput>;
  vendorServiceMediaTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type VendorSortInput = {
  address?: InputMaybe<AddressSortInput>;
  addressId?: InputMaybe<SortEnumType>;
  addressTypeLookup?: InputMaybe<LookupSortInput>;
  addressTypeLookupId?: InputMaybe<SortEnumType>;
  axvendorCode?: InputMaybe<SortEnumType>;
  brokerCurrencyCodeLookup?: InputMaybe<LookupSortInput>;
  brokerCurrencyCodeLookupId?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaOutlet?: InputMaybe<MediaOutletSortInput>;
  mediaOutletId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  oldid?: InputMaybe<SortEnumType>;
  oldtableName?: InputMaybe<SortEnumType>;
  tsbminimumMilesSeparation?: InputMaybe<SortEnumType>;
  tsbseats?: InputMaybe<SortEnumType>;
  tsbtimeZoneInfo?: InputMaybe<TimeZoneInfoSortInput>;
  tsbtimeZoneInfoId?: InputMaybe<SortEnumType>;
  vendorId?: InputMaybe<SortEnumType>;
  vendorTypeLookup?: InputMaybe<LookupSortInput>;
  vendorTypeLookupId?: InputMaybe<SortEnumType>;
};

export type VendorV2 = {
  __typename?: 'VendorV2';
  accountId: Scalars['String'];
  addressCity: Scalars['String'];
  addressCountryRegionId: Scalars['String'];
  addressCountyId: Scalars['String'];
  addressDescription: Scalars['String'];
  addressLatitude: Scalars['Decimal'];
  addressLocationId: Scalars['String'];
  addressLongitude: Scalars['Decimal'];
  addressStateId: Scalars['String'];
  addressStreet: Scalars['String'];
  addressStreetNumber: Scalars['String'];
  addressValidFrom: Scalars['DateTime'];
  addressValidTo: Scalars['DateTime'];
  addressZipCode: Scalars['String'];
  bankAccountId: Scalars['String'];
  dataAreaId: Scalars['String'];
  defaultPaymentTermsName: Scalars['String'];
  defaultVendorPaymentMethodName: Scalars['String'];
  formattedPrimaryAddress: Scalars['String'];
  mainContactPersonnelNumber: Scalars['String'];
  notes: Scalars['String'];
  onHoldStatus: CustVendorBlocked;
  organizationNumber: Scalars['String'];
  ourAccountNumber: Scalars['String'];
  paymentId: Scalars['String'];
  primaryEmailAddress: Scalars['String'];
  primaryPhoneNumber: Scalars['String'];
  tax1099DoingBusinessAsName: Scalars['String'];
  tax1099FederalTaxId: Scalars['String'];
  tax1099IdType: TaxIdType;
  tax1099NameToUse: Tax1099NameChoice;
  tax1099Type: Tax1099Type;
  vendorAccountNumber: Scalars['String'];
  vendorGroupId: Scalars['String'];
  vendorOrganizationName: Scalars['String'];
  vendorPartyNumber: Scalars['String'];
};

export type ViewSource = {
  __typename?: 'ViewSource';
  inactive: Scalars['Boolean'];
  inversePredecessorViewSource?: Maybe<Array<Maybe<ViewSource>>>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutlets?: Maybe<Array<Maybe<MediaOutlet>>>;
  mediaViewName?: Maybe<Scalars['String']>;
  multiplier: Scalars['Decimal'];
  numerisStationId?: Maybe<Scalars['Int']>;
  predecessorViewSource?: Maybe<ViewSource>;
  predecessorViewSourceId?: Maybe<Scalars['Int']>;
  transitionDate?: Maybe<Scalars['Date']>;
  viewSourceId: Scalars['Int'];
  viewSourceTypeLookup?: Maybe<Lookup>;
  viewSourceTypeLookupId: Scalars['Int'];
};

export type ViewSourceFilterInput = {
  and?: InputMaybe<Array<ViewSourceFilterInput>>;
  inactive?: InputMaybe<BooleanOperationFilterInput>;
  inversePredecessorViewSource?: InputMaybe<ListFilterInputTypeOfViewSourceFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutlets?: InputMaybe<ListFilterInputTypeOfMediaOutletFilterInput>;
  mediaViewName?: InputMaybe<StringOperationFilterInput>;
  multiplier?: InputMaybe<DecimalOperationFilterInput>;
  numerisStationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ViewSourceFilterInput>>;
  predecessorViewSource?: InputMaybe<ViewSourceFilterInput>;
  predecessorViewSourceId?: InputMaybe<IntOperationFilterInput>;
  transitionDate?: InputMaybe<DateOperationFilterInput>;
  viewSourceId?: InputMaybe<IntOperationFilterInput>;
  viewSourceTypeLookup?: InputMaybe<LookupFilterInput>;
  viewSourceTypeLookupId?: InputMaybe<IntOperationFilterInput>;
};

export type ViewSourceSortInput = {
  inactive?: InputMaybe<SortEnumType>;
  lastUpdatedByAppUserDescription?: InputMaybe<SortEnumType>;
  lastUpdatedUtc?: InputMaybe<SortEnumType>;
  mediaViewName?: InputMaybe<SortEnumType>;
  multiplier?: InputMaybe<SortEnumType>;
  numerisStationId?: InputMaybe<SortEnumType>;
  predecessorViewSource?: InputMaybe<ViewSourceSortInput>;
  predecessorViewSourceId?: InputMaybe<SortEnumType>;
  transitionDate?: InputMaybe<SortEnumType>;
  viewSourceId?: InputMaybe<SortEnumType>;
  viewSourceTypeLookup?: InputMaybe<LookupSortInput>;
  viewSourceTypeLookupId?: InputMaybe<SortEnumType>;
};

export type WaveCastAdInstance = {
  __typename?: 'WaveCastAdInstance';
  adInstance?: Maybe<AdInstance>;
  adInstanceId?: Maybe<Scalars['Int']>;
  adInstanceUtc?: Maybe<Scalars['DateTime']>;
  clientNetCleared?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  daypartName?: Maybe<Scalars['String']>;
  impressions?: Maybe<Scalars['Decimal']>;
  isLocalCoverUp: Scalars['Boolean'];
  isProgramAnalysis: Scalars['Boolean'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaOutletAlias?: Maybe<Scalars['String']>;
  mediaOutletName?: Maybe<Scalars['String']>;
  placementType?: Maybe<Scalars['String']>;
  programWaveCastDetail?: Maybe<WaveCastDetail>;
  programWaveCastDetailId?: Maybe<Scalars['Int']>;
  roviProgramAlias?: Maybe<Scalars['String']>;
  roviProgramTitle?: Maybe<Scalars['String']>;
  uniqueAdSchedule?: Maybe<UniqueAdSchedule>;
  uniqueAdScheduleId?: Maybe<Scalars['Int']>;
  waveCastAdInstanceId: Scalars['Int'];
  waveCastDetail?: Maybe<WaveCastDetail>;
  waveCastDetailId?: Maybe<Scalars['Int']>;
  waveCastWave?: Maybe<WaveCastWave>;
  waveCastWaveId?: Maybe<Scalars['Int']>;
};

export type WaveCastAdInstanceFilterInput = {
  adInstance?: InputMaybe<AdInstanceFilterInput>;
  adInstanceId?: InputMaybe<IntOperationFilterInput>;
  adInstanceUtc?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<WaveCastAdInstanceFilterInput>>;
  clientNetCleared?: InputMaybe<DecimalOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  daypartName?: InputMaybe<StringOperationFilterInput>;
  impressions?: InputMaybe<DecimalOperationFilterInput>;
  isLocalCoverUp?: InputMaybe<BooleanOperationFilterInput>;
  isProgramAnalysis?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaOutletAlias?: InputMaybe<StringOperationFilterInput>;
  mediaOutletName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WaveCastAdInstanceFilterInput>>;
  placementType?: InputMaybe<StringOperationFilterInput>;
  programWaveCastDetail?: InputMaybe<WaveCastDetailFilterInput>;
  programWaveCastDetailId?: InputMaybe<IntOperationFilterInput>;
  roviProgramAlias?: InputMaybe<StringOperationFilterInput>;
  roviProgramTitle?: InputMaybe<StringOperationFilterInput>;
  uniqueAdSchedule?: InputMaybe<UniqueAdScheduleFilterInput>;
  uniqueAdScheduleId?: InputMaybe<IntOperationFilterInput>;
  waveCastAdInstanceId?: InputMaybe<IntOperationFilterInput>;
  waveCastDetail?: InputMaybe<WaveCastDetailFilterInput>;
  waveCastDetailId?: InputMaybe<IntOperationFilterInput>;
  waveCastWave?: InputMaybe<WaveCastWaveFilterInput>;
  waveCastWaveId?: InputMaybe<IntOperationFilterInput>;
};

export type WaveCastDetail = {
  __typename?: 'WaveCastDetail';
  actionTypeLookup?: Maybe<Lookup>;
  actionTypeLookupId: Scalars['Int'];
  beta?: Maybe<Scalars['Decimal']>;
  clientNetCleared?: Maybe<Scalars['Decimal']>;
  colinear?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  dayPart?: Maybe<Daypart>;
  dayPartId?: Maybe<Scalars['Int']>;
  impressions: Scalars['Decimal'];
  instances: Scalars['Int'];
  isFabricatedCollinearRecord: Scalars['Boolean'];
  isSignificant?: Maybe<Scalars['Boolean']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaName?: Maybe<Scalars['String']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId?: Maybe<Scalars['Int']>;
  overrideBubbleChartDirectiveComment?: Maybe<Scalars['String']>;
  overrideBubbleChartDirectiveLookup?: Maybe<Lookup>;
  overrideBubbleChartDirectiveLookupId?: Maybe<Scalars['Int']>;
  pvalue?: Maybe<Scalars['Decimal']>;
  responseIndex?: Maybe<Scalars['Decimal']>;
  roviProgramId?: Maybe<Scalars['Int']>;
  waveCastAdInstanceProgramWaveCastDetails?: Maybe<Array<Maybe<WaveCastAdInstance>>>;
  waveCastAdInstanceWaveCastDetails?: Maybe<Array<Maybe<WaveCastAdInstance>>>;
  waveCastDetailId: Scalars['Int'];
  waveCastDetailTypeLookup?: Maybe<Lookup>;
  waveCastDetailTypeLookupId: Scalars['Int'];
  waveCastWave?: Maybe<WaveCastWave>;
  waveCastWaveId: Scalars['Int'];
};

export type WaveCastDetailFilterInput = {
  actionTypeLookup?: InputMaybe<LookupFilterInput>;
  actionTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<WaveCastDetailFilterInput>>;
  beta?: InputMaybe<DecimalOperationFilterInput>;
  clientNetCleared?: InputMaybe<DecimalOperationFilterInput>;
  colinear?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  dayPart?: InputMaybe<DaypartFilterInput>;
  dayPartId?: InputMaybe<IntOperationFilterInput>;
  impressions?: InputMaybe<DecimalOperationFilterInput>;
  instances?: InputMaybe<IntOperationFilterInput>;
  isFabricatedCollinearRecord?: InputMaybe<BooleanOperationFilterInput>;
  isSignificant?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaName?: InputMaybe<StringOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WaveCastDetailFilterInput>>;
  overrideBubbleChartDirectiveComment?: InputMaybe<StringOperationFilterInput>;
  overrideBubbleChartDirectiveLookup?: InputMaybe<LookupFilterInput>;
  overrideBubbleChartDirectiveLookupId?: InputMaybe<IntOperationFilterInput>;
  pvalue?: InputMaybe<DecimalOperationFilterInput>;
  responseIndex?: InputMaybe<DecimalOperationFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
  waveCastAdInstanceProgramWaveCastDetails?: InputMaybe<ListFilterInputTypeOfWaveCastAdInstanceFilterInput>;
  waveCastAdInstanceWaveCastDetails?: InputMaybe<ListFilterInputTypeOfWaveCastAdInstanceFilterInput>;
  waveCastDetailId?: InputMaybe<IntOperationFilterInput>;
  waveCastDetailTypeLookup?: InputMaybe<LookupFilterInput>;
  waveCastDetailTypeLookupId?: InputMaybe<IntOperationFilterInput>;
  waveCastWave?: InputMaybe<WaveCastWaveFilterInput>;
  waveCastWaveId?: InputMaybe<IntOperationFilterInput>;
};

export type WaveCastMapping = {
  __typename?: 'WaveCastMapping';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  mediaName?: Maybe<Scalars['String']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletId: Scalars['Int'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  roviProgramId?: Maybe<Scalars['Int']>;
  stimulusFileName?: Maybe<Scalars['String']>;
  waveCastMappingId: Scalars['Int'];
};

export type WaveCastMappingFilterInput = {
  and?: InputMaybe<Array<WaveCastMappingFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  mediaName?: InputMaybe<StringOperationFilterInput>;
  mediaOutlet?: InputMaybe<MediaOutletFilterInput>;
  mediaOutletId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WaveCastMappingFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  roviProgramId?: InputMaybe<IntOperationFilterInput>;
  stimulusFileName?: InputMaybe<StringOperationFilterInput>;
  waveCastMappingId?: InputMaybe<IntOperationFilterInput>;
};

export type WaveCastModel = {
  __typename?: 'WaveCastModel';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createByAppUser?: Maybe<AppUser>;
  createByAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  waveCastModelId: Scalars['Int'];
  waveCastModelSkugroups?: Maybe<Array<Maybe<WaveCastModelSkugroup>>>;
  waveCastWaves?: Maybe<Array<Maybe<WaveCastWave>>>;
};

export type WaveCastModelFilterInput = {
  and?: InputMaybe<Array<WaveCastModelFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createByAppUser?: InputMaybe<AppUserFilterInput>;
  createByAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WaveCastModelFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  waveCastModelId?: InputMaybe<IntOperationFilterInput>;
  waveCastModelSkugroups?: InputMaybe<ListFilterInputTypeOfWaveCastModelSkugroupFilterInput>;
  waveCastWaves?: InputMaybe<ListFilterInputTypeOfWaveCastWaveFilterInput>;
};

export type WaveCastModelSkugroup = {
  __typename?: 'WaveCastModelSkugroup';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  skugroup?: Maybe<Skugroup>;
  skugroupId: Scalars['Int'];
  waveCastModel?: Maybe<WaveCastModel>;
  waveCastModelId: Scalars['Int'];
  waveCastModelSkugroupId: Scalars['Int'];
};

export type WaveCastModelSkugroupFilterInput = {
  and?: InputMaybe<Array<WaveCastModelSkugroupFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<WaveCastModelSkugroupFilterInput>>;
  skugroup?: InputMaybe<SkugroupFilterInput>;
  skugroupId?: InputMaybe<IntOperationFilterInput>;
  waveCastModel?: InputMaybe<WaveCastModelFilterInput>;
  waveCastModelId?: InputMaybe<IntOperationFilterInput>;
  waveCastModelSkugroupId?: InputMaybe<IntOperationFilterInput>;
};

export type WaveCastWave = {
  __typename?: 'WaveCastWave';
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createByAppUser?: Maybe<AppUser>;
  createByAppUserId: Scalars['Int'];
  createUtc: Scalars['DateTime'];
  endDate: Scalars['Date'];
  lastUpdatedByAppUserDescription?: Maybe<Scalars['String']>;
  lastUpdatedUtc?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  stimulusFileReference?: Maybe<FileReference>;
  stimulusFileReferenceId: Scalars['Int'];
  waveCastAdInstances?: Maybe<Array<Maybe<WaveCastAdInstance>>>;
  waveCastDetails?: Maybe<Array<Maybe<WaveCastDetail>>>;
  waveCastModel?: Maybe<WaveCastModel>;
  waveCastModelId: Scalars['Int'];
  waveCastOutputAggregatesFileReference?: Maybe<FileReference>;
  waveCastOutputAggregatesFileReferenceId?: Maybe<Scalars['Int']>;
  waveCastOutputColinearityFileReference?: Maybe<FileReference>;
  waveCastOutputColinearityFileReferenceId?: Maybe<Scalars['Int']>;
  waveCastOutputMediaOutletFileReference?: Maybe<FileReference>;
  waveCastOutputMediaOutletFileReferenceId?: Maybe<Scalars['Int']>;
  waveCastOutputProgramFileReference?: Maybe<FileReference>;
  waveCastOutputProgramFileReferenceId?: Maybe<Scalars['Int']>;
  waveCastWaveId: Scalars['Int'];
  waveNumber: Scalars['Int'];
};

export type WaveCastWaveFilterInput = {
  and?: InputMaybe<Array<WaveCastWaveFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createByAppUser?: InputMaybe<AppUserFilterInput>;
  createByAppUserId?: InputMaybe<IntOperationFilterInput>;
  createUtc?: InputMaybe<DateTimeOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  lastUpdatedByAppUserDescription?: InputMaybe<StringOperationFilterInput>;
  lastUpdatedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WaveCastWaveFilterInput>>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  stimulusFileReference?: InputMaybe<FileReferenceFilterInput>;
  stimulusFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  waveCastAdInstances?: InputMaybe<ListFilterInputTypeOfWaveCastAdInstanceFilterInput>;
  waveCastDetails?: InputMaybe<ListFilterInputTypeOfWaveCastDetailFilterInput>;
  waveCastModel?: InputMaybe<WaveCastModelFilterInput>;
  waveCastModelId?: InputMaybe<IntOperationFilterInput>;
  waveCastOutputAggregatesFileReference?: InputMaybe<FileReferenceFilterInput>;
  waveCastOutputAggregatesFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  waveCastOutputColinearityFileReference?: InputMaybe<FileReferenceFilterInput>;
  waveCastOutputColinearityFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  waveCastOutputMediaOutletFileReference?: InputMaybe<FileReferenceFilterInput>;
  waveCastOutputMediaOutletFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  waveCastOutputProgramFileReference?: InputMaybe<FileReferenceFilterInput>;
  waveCastOutputProgramFileReferenceId?: InputMaybe<IntOperationFilterInput>;
  waveCastWaveId?: InputMaybe<IntOperationFilterInput>;
  waveNumber?: InputMaybe<IntOperationFilterInput>;
};

export enum WorkflowStatus {
  Approved = 'APPROVED',
  AwaitingChangeReview = 'AWAITING_CHANGE_REVIEW',
  EditableByUser = 'EDITABLE_BY_USER',
  InApprovals = 'IN_APPROVALS',
  RecallInProgress = 'RECALL_IN_PROGRESS',
  Returned = 'RETURNED',
  Unknown = 'UNKNOWN',
  Unlocked = 'UNLOCKED',
  UnlockRequested = 'UNLOCK_REQUESTED'
}

export type TimeSheetQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeSheetQueryQuery = { __typename?: 'Query', timeSheetsWaitingForApproval: Array<{ __typename?: 'TimeSheet', userDisplayName: string, firstDayOfWeek: any }> };

export type CubeStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type CubeStatusQuery = { __typename?: 'Query', journalsMissingFromTheCube: Array<{ __typename?: 'MissingLedgerItem', monthDescription: string, accountingDate: any, journalDescription: string, journalBatchNumber: string, isPreviousMonth: boolean, journalNumber: string }> };

export type GetMediaCardCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMediaCardCountsQuery = { __typename?: 'Query', outstandingUserInvoices: Array<{ __typename?: 'InvoiceForApproval', invoiceID?: number | null }>, activeUserCampaigns: Array<{ __typename?: 'CrossChannelCampaignForLookup', crossChannelCampaignId: number }>, activeUserInsertionOrders: Array<{ __typename?: 'InsertionOrderForLookup', insertionOrderId: number }> };

export type PageFavoriteFieldsFragment = { __typename?: 'PageFavorite', id: number, inAppPath: string, rainOneApp: RainOneApp, title: string, caption: string, pageType: string, favoriteTitle: string, rainOnePersonId: number };

export type PageFavoritesQueryVariables = Exact<{
  rainOneApp: RainOneApp;
}>;


export type PageFavoritesQuery = { __typename?: 'Query', pageFavorites: Array<{ __typename?: 'PageFavorite', id: number, inAppPath: string, rainOneApp: RainOneApp, title: string, caption: string, pageType: string, favoriteTitle: string, rainOnePersonId: number }> };

export type ActionItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActionItemsQuery = { __typename?: 'Query', actionItems: Array<{ __typename: 'UserActionItem', actionItemSubscriptionId: number, actionItemType: ActionItemType, actionItemId: number, isHighPriority: boolean, isHumanClearable: boolean, description: string, isInactive: boolean, inAppPath: string, id: number, linkName: string, rainOnePersonId: number, actionUrl: string, rainOneApp: RainOneApp, hideLink: boolean, createdDateUTC: any }> };

export type CatchPhraseFieldsFragment = { __typename?: 'CatchPhrase', catchPhraseId: number, phrase: string, rainOnePersonId: number, createdUTC: any, rainOnePerson: { __typename?: 'RainOnePerson', displayName: string } };

export type GetCatchPhrasesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCatchPhrasesQuery = { __typename?: 'Query', catchPhrases: Array<{ __typename?: 'CatchPhrase', catchPhraseId: number, phrase: string, rainOnePersonId: number, createdUTC: any, rainOnePerson: { __typename?: 'RainOnePerson', displayName: string } }> };

export type AddCatchPhraseMutationVariables = Exact<{
  phrase: Scalars['String'];
}>;


export type AddCatchPhraseMutation = { __typename?: 'Mutation', addCatchPhrase?: { __typename?: 'CatchPhrase', catchPhraseId: number, phrase: string, rainOnePersonId: number, createdUTC: any, rainOnePerson: { __typename?: 'RainOnePerson', displayName: string } } | null };

export type DeleteCatchPhraseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCatchPhraseMutation = { __typename?: 'Mutation', removeCachePhrase: boolean };

export type DatoramaImportFieldsFragment = { __typename?: 'DigitalSpendInputFileForLookup', digitalSpendInputFileID?: number | null, fileReferenceID: number, filePath?: string | null, clientID?: number | null, trackerClientName?: string | null, dataGeneratedUTC?: any | null, importDateUTC?: any | null, importUserDescription?: string | null, mediaStartDate?: any | null, mediaEndDate?: any | null, isMostRecentFile?: boolean | null, hasErrors?: boolean | null, digitalAnalystAppUserDescription?: string | null, digitalSpendInputsCount: number, digitalSpendInputsScheduleCount: number, jobInstanceDetails?: Array<{ __typename?: 'JobInstanceDetail', message?: string | null } | null> | null };

export type GetDatoramaImportStatusesQueryVariables = Exact<{
  activeClientsOnly?: InputMaybe<Scalars['Boolean']>;
  clientId?: InputMaybe<Scalars['Int']>;
  mostRecentImportOnly?: InputMaybe<Scalars['Boolean']>;
  importStartDate?: InputMaybe<Scalars['DateTime']>;
  importEndDate?: InputMaybe<Scalars['DateTime']>;
  digitalAnalystAppUserID?: InputMaybe<Scalars['Int']>;
  filesWithErrorsOnly?: InputMaybe<Scalars['Boolean']>;
  digitalSpendInputFileID?: InputMaybe<Scalars['Int']>;
}>;


export type GetDatoramaImportStatusesQuery = { __typename?: 'Query', digitalSpendImportsForLookup: Array<{ __typename?: 'DigitalSpendInputFileForLookup', digitalSpendInputFileID?: number | null, fileReferenceID: number, filePath?: string | null, clientID?: number | null, trackerClientName?: string | null, dataGeneratedUTC?: any | null, importDateUTC?: any | null, importUserDescription?: string | null, mediaStartDate?: any | null, mediaEndDate?: any | null, isMostRecentFile?: boolean | null, hasErrors?: boolean | null, digitalAnalystAppUserDescription?: string | null, digitalSpendInputsCount: number, digitalSpendInputsScheduleCount: number, jobInstanceDetails?: Array<{ __typename?: 'JobInstanceDetail', message?: string | null } | null> | null }> };

export type GetRainOnePeopleForDisplayQueryVariables = Exact<{
  rainOnePersonIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetRainOnePeopleForDisplayQuery = { __typename?: 'Query', rainOnePeople_ForDisplay: Array<{ __typename?: 'RainOnePerson_ForDisplay', accountSetupErrorMessages: Array<string> }> };

export type CsiSummaryDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type CsiSummaryDetailsQuery = { __typename?: 'Query', csiSummaryDetails: Array<{ __typename?: 'CsiFileDetails', uploadDateTimeUTC: any, fileName: string, details: Array<{ __typename?: 'CsiSummaryDetails', invoiceCount: number, dataAreaId: string, amount: any, paymentType: string }> }> };

export type VendorsMissingFromCsiQueryVariables = Exact<{
  includeChecks?: InputMaybe<Scalars['Boolean']>;
}>;


export type VendorsMissingFromCsiQuery = { __typename?: 'Query', vendorsMissingFromCSI: Array<{ __typename?: 'CsiVendorInfo', achInfoExists: boolean, vendorChangeDescription: string, vendorId: string, vendorName: string, vendorGroup: string }> };

export type GetTimesheetsInPurgatoryCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimesheetsInPurgatoryCountQuery = { __typename?: 'Query', timesheetsInPurgatoryCount: number };

export type GetTimeSheetsWaitingForApprovalCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimeSheetsWaitingForApprovalCountQuery = { __typename?: 'Query', timeSheetsWaitingForApprovalCount: number };

export type GetTimesheetHomeCardCountQueryVariables = Exact<{
  timeSheetHomeCardCountType: TimeSheetHomeCardCountType;
}>;


export type GetTimesheetHomeCardCountQuery = { __typename?: 'Query', timesheetsHomeCardCount: number };

export const PageFavoriteFieldsFragmentDoc = gql`
    fragment pageFavoriteFields on PageFavorite {
  id
  inAppPath
  rainOneApp
  title
  caption
  pageType
  favoriteTitle
  rainOnePersonId
}
    `;
export const CatchPhraseFieldsFragmentDoc = gql`
    fragment catchPhraseFields on CatchPhrase {
  catchPhraseId
  phrase
  rainOnePersonId
  createdUTC
  rainOnePerson {
    displayName
  }
}
    `;
export const DatoramaImportFieldsFragmentDoc = gql`
    fragment datoramaImportFields on DigitalSpendInputFileForLookup {
  digitalSpendInputFileID
  fileReferenceID
  filePath
  clientID
  trackerClientName
  dataGeneratedUTC
  importDateUTC
  importUserDescription
  mediaStartDate
  mediaEndDate
  isMostRecentFile
  hasErrors
  digitalAnalystAppUserDescription
  digitalSpendInputsCount
  digitalSpendInputsScheduleCount
  jobInstanceDetails {
    message
  }
}
    `;
export const TimeSheetQueryDocument = gql`
    query timeSheetQuery {
  timeSheetsWaitingForApproval {
    userDisplayName
    firstDayOfWeek
  }
}
    `;

/**
 * __useTimeSheetQueryQuery__
 *
 * To run a query within a React component, call `useTimeSheetQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeSheetQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeSheetQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeSheetQueryQuery(baseOptions?: Apollo.QueryHookOptions<TimeSheetQueryQuery, TimeSheetQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeSheetQueryQuery, TimeSheetQueryQueryVariables>(TimeSheetQueryDocument, options);
      }
export function useTimeSheetQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeSheetQueryQuery, TimeSheetQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeSheetQueryQuery, TimeSheetQueryQueryVariables>(TimeSheetQueryDocument, options);
        }
export type TimeSheetQueryQueryHookResult = ReturnType<typeof useTimeSheetQueryQuery>;
export type TimeSheetQueryLazyQueryHookResult = ReturnType<typeof useTimeSheetQueryLazyQuery>;
export type TimeSheetQueryQueryResult = Apollo.QueryResult<TimeSheetQueryQuery, TimeSheetQueryQueryVariables>;
export const CubeStatusDocument = gql`
    query CubeStatus {
  journalsMissingFromTheCube {
    monthDescription
    accountingDate
    journalDescription
    journalBatchNumber
    isPreviousMonth
    journalNumber
  }
}
    `;

/**
 * __useCubeStatusQuery__
 *
 * To run a query within a React component, call `useCubeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCubeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCubeStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCubeStatusQuery(baseOptions?: Apollo.QueryHookOptions<CubeStatusQuery, CubeStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CubeStatusQuery, CubeStatusQueryVariables>(CubeStatusDocument, options);
      }
export function useCubeStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CubeStatusQuery, CubeStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CubeStatusQuery, CubeStatusQueryVariables>(CubeStatusDocument, options);
        }
export type CubeStatusQueryHookResult = ReturnType<typeof useCubeStatusQuery>;
export type CubeStatusLazyQueryHookResult = ReturnType<typeof useCubeStatusLazyQuery>;
export type CubeStatusQueryResult = Apollo.QueryResult<CubeStatusQuery, CubeStatusQueryVariables>;
export const GetMediaCardCountsDocument = gql`
    query getMediaCardCounts {
  outstandingUserInvoices {
    invoiceID
  }
  activeUserCampaigns {
    crossChannelCampaignId
  }
  activeUserInsertionOrders {
    insertionOrderId
  }
}
    `;

/**
 * __useGetMediaCardCountsQuery__
 *
 * To run a query within a React component, call `useGetMediaCardCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaCardCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaCardCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMediaCardCountsQuery(baseOptions?: Apollo.QueryHookOptions<GetMediaCardCountsQuery, GetMediaCardCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMediaCardCountsQuery, GetMediaCardCountsQueryVariables>(GetMediaCardCountsDocument, options);
      }
export function useGetMediaCardCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaCardCountsQuery, GetMediaCardCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMediaCardCountsQuery, GetMediaCardCountsQueryVariables>(GetMediaCardCountsDocument, options);
        }
export type GetMediaCardCountsQueryHookResult = ReturnType<typeof useGetMediaCardCountsQuery>;
export type GetMediaCardCountsLazyQueryHookResult = ReturnType<typeof useGetMediaCardCountsLazyQuery>;
export type GetMediaCardCountsQueryResult = Apollo.QueryResult<GetMediaCardCountsQuery, GetMediaCardCountsQueryVariables>;
export const PageFavoritesDocument = gql`
    query PageFavorites($rainOneApp: RainOneApp!) {
  pageFavorites(rainOneApp: $rainOneApp) {
    ...pageFavoriteFields
  }
}
    ${PageFavoriteFieldsFragmentDoc}`;

/**
 * __usePageFavoritesQuery__
 *
 * To run a query within a React component, call `usePageFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageFavoritesQuery({
 *   variables: {
 *      rainOneApp: // value for 'rainOneApp'
 *   },
 * });
 */
export function usePageFavoritesQuery(baseOptions: Apollo.QueryHookOptions<PageFavoritesQuery, PageFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageFavoritesQuery, PageFavoritesQueryVariables>(PageFavoritesDocument, options);
      }
export function usePageFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageFavoritesQuery, PageFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageFavoritesQuery, PageFavoritesQueryVariables>(PageFavoritesDocument, options);
        }
export type PageFavoritesQueryHookResult = ReturnType<typeof usePageFavoritesQuery>;
export type PageFavoritesLazyQueryHookResult = ReturnType<typeof usePageFavoritesLazyQuery>;
export type PageFavoritesQueryResult = Apollo.QueryResult<PageFavoritesQuery, PageFavoritesQueryVariables>;
export const ActionItemsDocument = gql`
    query ActionItems {
  actionItems {
    actionItemSubscriptionId
    actionItemType
    actionItemId
    isHighPriority
    isHumanClearable
    description
    isInactive
    inAppPath
    id
    linkName
    rainOnePersonId
    actionUrl
    rainOneApp
    hideLink
    createdDateUTC
    __typename
  }
}
    `;

/**
 * __useActionItemsQuery__
 *
 * To run a query within a React component, call `useActionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActionItemsQuery(baseOptions?: Apollo.QueryHookOptions<ActionItemsQuery, ActionItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionItemsQuery, ActionItemsQueryVariables>(ActionItemsDocument, options);
      }
export function useActionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionItemsQuery, ActionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionItemsQuery, ActionItemsQueryVariables>(ActionItemsDocument, options);
        }
export type ActionItemsQueryHookResult = ReturnType<typeof useActionItemsQuery>;
export type ActionItemsLazyQueryHookResult = ReturnType<typeof useActionItemsLazyQuery>;
export type ActionItemsQueryResult = Apollo.QueryResult<ActionItemsQuery, ActionItemsQueryVariables>;
export const GetCatchPhrasesDocument = gql`
    query getCatchPhrases {
  catchPhrases {
    ...catchPhraseFields
  }
}
    ${CatchPhraseFieldsFragmentDoc}`;

/**
 * __useGetCatchPhrasesQuery__
 *
 * To run a query within a React component, call `useGetCatchPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatchPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatchPhrasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCatchPhrasesQuery(baseOptions?: Apollo.QueryHookOptions<GetCatchPhrasesQuery, GetCatchPhrasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatchPhrasesQuery, GetCatchPhrasesQueryVariables>(GetCatchPhrasesDocument, options);
      }
export function useGetCatchPhrasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatchPhrasesQuery, GetCatchPhrasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatchPhrasesQuery, GetCatchPhrasesQueryVariables>(GetCatchPhrasesDocument, options);
        }
export type GetCatchPhrasesQueryHookResult = ReturnType<typeof useGetCatchPhrasesQuery>;
export type GetCatchPhrasesLazyQueryHookResult = ReturnType<typeof useGetCatchPhrasesLazyQuery>;
export type GetCatchPhrasesQueryResult = Apollo.QueryResult<GetCatchPhrasesQuery, GetCatchPhrasesQueryVariables>;
export const AddCatchPhraseDocument = gql`
    mutation addCatchPhrase($phrase: String!) {
  addCatchPhrase(phrase: $phrase) {
    ...catchPhraseFields
  }
}
    ${CatchPhraseFieldsFragmentDoc}`;
export type AddCatchPhraseMutationFn = Apollo.MutationFunction<AddCatchPhraseMutation, AddCatchPhraseMutationVariables>;

/**
 * __useAddCatchPhraseMutation__
 *
 * To run a mutation, you first call `useAddCatchPhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCatchPhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCatchPhraseMutation, { data, loading, error }] = useAddCatchPhraseMutation({
 *   variables: {
 *      phrase: // value for 'phrase'
 *   },
 * });
 */
export function useAddCatchPhraseMutation(baseOptions?: Apollo.MutationHookOptions<AddCatchPhraseMutation, AddCatchPhraseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCatchPhraseMutation, AddCatchPhraseMutationVariables>(AddCatchPhraseDocument, options);
      }
export type AddCatchPhraseMutationHookResult = ReturnType<typeof useAddCatchPhraseMutation>;
export type AddCatchPhraseMutationResult = Apollo.MutationResult<AddCatchPhraseMutation>;
export type AddCatchPhraseMutationOptions = Apollo.BaseMutationOptions<AddCatchPhraseMutation, AddCatchPhraseMutationVariables>;
export const DeleteCatchPhraseDocument = gql`
    mutation deleteCatchPhrase($id: Int!) {
  removeCachePhrase(id: $id)
}
    `;
export type DeleteCatchPhraseMutationFn = Apollo.MutationFunction<DeleteCatchPhraseMutation, DeleteCatchPhraseMutationVariables>;

/**
 * __useDeleteCatchPhraseMutation__
 *
 * To run a mutation, you first call `useDeleteCatchPhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatchPhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatchPhraseMutation, { data, loading, error }] = useDeleteCatchPhraseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatchPhraseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCatchPhraseMutation, DeleteCatchPhraseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCatchPhraseMutation, DeleteCatchPhraseMutationVariables>(DeleteCatchPhraseDocument, options);
      }
export type DeleteCatchPhraseMutationHookResult = ReturnType<typeof useDeleteCatchPhraseMutation>;
export type DeleteCatchPhraseMutationResult = Apollo.MutationResult<DeleteCatchPhraseMutation>;
export type DeleteCatchPhraseMutationOptions = Apollo.BaseMutationOptions<DeleteCatchPhraseMutation, DeleteCatchPhraseMutationVariables>;
export const GetDatoramaImportStatusesDocument = gql`
    query getDatoramaImportStatuses($activeClientsOnly: Boolean, $clientId: Int, $mostRecentImportOnly: Boolean, $importStartDate: DateTime, $importEndDate: DateTime, $digitalAnalystAppUserID: Int, $filesWithErrorsOnly: Boolean, $digitalSpendInputFileID: Int) {
  digitalSpendImportsForLookup(
    activeClientsOnly: $activeClientsOnly
    clientID: $clientId
    mostRecentImportOnly: $mostRecentImportOnly
    importStartDate: $importStartDate
    importEndDate: $importEndDate
    digitalAnalystAppUserID: $digitalAnalystAppUserID
    filesWithErrorsOnly: $filesWithErrorsOnly
    digitalSpendInputFileID: $digitalSpendInputFileID
  ) {
    ...datoramaImportFields
  }
}
    ${DatoramaImportFieldsFragmentDoc}`;

/**
 * __useGetDatoramaImportStatusesQuery__
 *
 * To run a query within a React component, call `useGetDatoramaImportStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatoramaImportStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatoramaImportStatusesQuery({
 *   variables: {
 *      activeClientsOnly: // value for 'activeClientsOnly'
 *      clientId: // value for 'clientId'
 *      mostRecentImportOnly: // value for 'mostRecentImportOnly'
 *      importStartDate: // value for 'importStartDate'
 *      importEndDate: // value for 'importEndDate'
 *      digitalAnalystAppUserID: // value for 'digitalAnalystAppUserID'
 *      filesWithErrorsOnly: // value for 'filesWithErrorsOnly'
 *      digitalSpendInputFileID: // value for 'digitalSpendInputFileID'
 *   },
 * });
 */
export function useGetDatoramaImportStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetDatoramaImportStatusesQuery, GetDatoramaImportStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatoramaImportStatusesQuery, GetDatoramaImportStatusesQueryVariables>(GetDatoramaImportStatusesDocument, options);
      }
export function useGetDatoramaImportStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatoramaImportStatusesQuery, GetDatoramaImportStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatoramaImportStatusesQuery, GetDatoramaImportStatusesQueryVariables>(GetDatoramaImportStatusesDocument, options);
        }
export type GetDatoramaImportStatusesQueryHookResult = ReturnType<typeof useGetDatoramaImportStatusesQuery>;
export type GetDatoramaImportStatusesLazyQueryHookResult = ReturnType<typeof useGetDatoramaImportStatusesLazyQuery>;
export type GetDatoramaImportStatusesQueryResult = Apollo.QueryResult<GetDatoramaImportStatusesQuery, GetDatoramaImportStatusesQueryVariables>;
export const GetRainOnePeopleForDisplayDocument = gql`
    query getRainOnePeopleForDisplay($rainOnePersonIds: [Int!]) {
  rainOnePeople_ForDisplay(rainOnePersonIds: $rainOnePersonIds) {
    accountSetupErrorMessages
  }
}
    `;

/**
 * __useGetRainOnePeopleForDisplayQuery__
 *
 * To run a query within a React component, call `useGetRainOnePeopleForDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRainOnePeopleForDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRainOnePeopleForDisplayQuery({
 *   variables: {
 *      rainOnePersonIds: // value for 'rainOnePersonIds'
 *   },
 * });
 */
export function useGetRainOnePeopleForDisplayQuery(baseOptions?: Apollo.QueryHookOptions<GetRainOnePeopleForDisplayQuery, GetRainOnePeopleForDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRainOnePeopleForDisplayQuery, GetRainOnePeopleForDisplayQueryVariables>(GetRainOnePeopleForDisplayDocument, options);
      }
export function useGetRainOnePeopleForDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRainOnePeopleForDisplayQuery, GetRainOnePeopleForDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRainOnePeopleForDisplayQuery, GetRainOnePeopleForDisplayQueryVariables>(GetRainOnePeopleForDisplayDocument, options);
        }
export type GetRainOnePeopleForDisplayQueryHookResult = ReturnType<typeof useGetRainOnePeopleForDisplayQuery>;
export type GetRainOnePeopleForDisplayLazyQueryHookResult = ReturnType<typeof useGetRainOnePeopleForDisplayLazyQuery>;
export type GetRainOnePeopleForDisplayQueryResult = Apollo.QueryResult<GetRainOnePeopleForDisplayQuery, GetRainOnePeopleForDisplayQueryVariables>;
export const CsiSummaryDetailsDocument = gql`
    query csiSummaryDetails {
  csiSummaryDetails {
    uploadDateTimeUTC
    fileName
    details {
      invoiceCount
      dataAreaId
      amount
      paymentType
    }
  }
}
    `;

/**
 * __useCsiSummaryDetailsQuery__
 *
 * To run a query within a React component, call `useCsiSummaryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsiSummaryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsiSummaryDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCsiSummaryDetailsQuery(baseOptions?: Apollo.QueryHookOptions<CsiSummaryDetailsQuery, CsiSummaryDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CsiSummaryDetailsQuery, CsiSummaryDetailsQueryVariables>(CsiSummaryDetailsDocument, options);
      }
export function useCsiSummaryDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CsiSummaryDetailsQuery, CsiSummaryDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CsiSummaryDetailsQuery, CsiSummaryDetailsQueryVariables>(CsiSummaryDetailsDocument, options);
        }
export type CsiSummaryDetailsQueryHookResult = ReturnType<typeof useCsiSummaryDetailsQuery>;
export type CsiSummaryDetailsLazyQueryHookResult = ReturnType<typeof useCsiSummaryDetailsLazyQuery>;
export type CsiSummaryDetailsQueryResult = Apollo.QueryResult<CsiSummaryDetailsQuery, CsiSummaryDetailsQueryVariables>;
export const VendorsMissingFromCsiDocument = gql`
    query vendorsMissingFromCSI($includeChecks: Boolean) {
  vendorsMissingFromCSI(includeChecks: $includeChecks) {
    achInfoExists
    vendorChangeDescription
    vendorId
    vendorName
    vendorGroup
  }
}
    `;

/**
 * __useVendorsMissingFromCsiQuery__
 *
 * To run a query within a React component, call `useVendorsMissingFromCsiQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsMissingFromCsiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsMissingFromCsiQuery({
 *   variables: {
 *      includeChecks: // value for 'includeChecks'
 *   },
 * });
 */
export function useVendorsMissingFromCsiQuery(baseOptions?: Apollo.QueryHookOptions<VendorsMissingFromCsiQuery, VendorsMissingFromCsiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorsMissingFromCsiQuery, VendorsMissingFromCsiQueryVariables>(VendorsMissingFromCsiDocument, options);
      }
export function useVendorsMissingFromCsiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsMissingFromCsiQuery, VendorsMissingFromCsiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorsMissingFromCsiQuery, VendorsMissingFromCsiQueryVariables>(VendorsMissingFromCsiDocument, options);
        }
export type VendorsMissingFromCsiQueryHookResult = ReturnType<typeof useVendorsMissingFromCsiQuery>;
export type VendorsMissingFromCsiLazyQueryHookResult = ReturnType<typeof useVendorsMissingFromCsiLazyQuery>;
export type VendorsMissingFromCsiQueryResult = Apollo.QueryResult<VendorsMissingFromCsiQuery, VendorsMissingFromCsiQueryVariables>;
export const GetTimesheetsInPurgatoryCountDocument = gql`
    query GetTimesheetsInPurgatoryCount {
  timesheetsInPurgatoryCount
}
    `;

/**
 * __useGetTimesheetsInPurgatoryCountQuery__
 *
 * To run a query within a React component, call `useGetTimesheetsInPurgatoryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimesheetsInPurgatoryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimesheetsInPurgatoryCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimesheetsInPurgatoryCountQuery(baseOptions?: Apollo.QueryHookOptions<GetTimesheetsInPurgatoryCountQuery, GetTimesheetsInPurgatoryCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimesheetsInPurgatoryCountQuery, GetTimesheetsInPurgatoryCountQueryVariables>(GetTimesheetsInPurgatoryCountDocument, options);
      }
export function useGetTimesheetsInPurgatoryCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimesheetsInPurgatoryCountQuery, GetTimesheetsInPurgatoryCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimesheetsInPurgatoryCountQuery, GetTimesheetsInPurgatoryCountQueryVariables>(GetTimesheetsInPurgatoryCountDocument, options);
        }
export type GetTimesheetsInPurgatoryCountQueryHookResult = ReturnType<typeof useGetTimesheetsInPurgatoryCountQuery>;
export type GetTimesheetsInPurgatoryCountLazyQueryHookResult = ReturnType<typeof useGetTimesheetsInPurgatoryCountLazyQuery>;
export type GetTimesheetsInPurgatoryCountQueryResult = Apollo.QueryResult<GetTimesheetsInPurgatoryCountQuery, GetTimesheetsInPurgatoryCountQueryVariables>;
export const GetTimeSheetsWaitingForApprovalCountDocument = gql`
    query GetTimeSheetsWaitingForApprovalCount {
  timeSheetsWaitingForApprovalCount
}
    `;

/**
 * __useGetTimeSheetsWaitingForApprovalCountQuery__
 *
 * To run a query within a React component, call `useGetTimeSheetsWaitingForApprovalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeSheetsWaitingForApprovalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeSheetsWaitingForApprovalCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeSheetsWaitingForApprovalCountQuery(baseOptions?: Apollo.QueryHookOptions<GetTimeSheetsWaitingForApprovalCountQuery, GetTimeSheetsWaitingForApprovalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimeSheetsWaitingForApprovalCountQuery, GetTimeSheetsWaitingForApprovalCountQueryVariables>(GetTimeSheetsWaitingForApprovalCountDocument, options);
      }
export function useGetTimeSheetsWaitingForApprovalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeSheetsWaitingForApprovalCountQuery, GetTimeSheetsWaitingForApprovalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimeSheetsWaitingForApprovalCountQuery, GetTimeSheetsWaitingForApprovalCountQueryVariables>(GetTimeSheetsWaitingForApprovalCountDocument, options);
        }
export type GetTimeSheetsWaitingForApprovalCountQueryHookResult = ReturnType<typeof useGetTimeSheetsWaitingForApprovalCountQuery>;
export type GetTimeSheetsWaitingForApprovalCountLazyQueryHookResult = ReturnType<typeof useGetTimeSheetsWaitingForApprovalCountLazyQuery>;
export type GetTimeSheetsWaitingForApprovalCountQueryResult = Apollo.QueryResult<GetTimeSheetsWaitingForApprovalCountQuery, GetTimeSheetsWaitingForApprovalCountQueryVariables>;
export const GetTimesheetHomeCardCountDocument = gql`
    query getTimesheetHomeCardCount($timeSheetHomeCardCountType: TimeSheetHomeCardCountType!) {
  timesheetsHomeCardCount(timeSheetHomeCardCountType: $timeSheetHomeCardCountType)
}
    `;

/**
 * __useGetTimesheetHomeCardCountQuery__
 *
 * To run a query within a React component, call `useGetTimesheetHomeCardCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimesheetHomeCardCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimesheetHomeCardCountQuery({
 *   variables: {
 *      timeSheetHomeCardCountType: // value for 'timeSheetHomeCardCountType'
 *   },
 * });
 */
export function useGetTimesheetHomeCardCountQuery(baseOptions: Apollo.QueryHookOptions<GetTimesheetHomeCardCountQuery, GetTimesheetHomeCardCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimesheetHomeCardCountQuery, GetTimesheetHomeCardCountQueryVariables>(GetTimesheetHomeCardCountDocument, options);
      }
export function useGetTimesheetHomeCardCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimesheetHomeCardCountQuery, GetTimesheetHomeCardCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimesheetHomeCardCountQuery, GetTimesheetHomeCardCountQueryVariables>(GetTimesheetHomeCardCountDocument, options);
        }
export type GetTimesheetHomeCardCountQueryHookResult = ReturnType<typeof useGetTimesheetHomeCardCountQuery>;
export type GetTimesheetHomeCardCountLazyQueryHookResult = ReturnType<typeof useGetTimesheetHomeCardCountLazyQuery>;
export type GetTimesheetHomeCardCountQueryResult = Apollo.QueryResult<GetTimesheetHomeCardCountQuery, GetTimesheetHomeCardCountQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddClientRoleError": [
      "ArgumentError",
      "RainError"
    ],
    "AddClientRoleFunctionalRoleError": [
      "ArgumentError",
      "RainError"
    ],
    "AddClientRolePermissionError": [
      "ArgumentError",
      "RainError"
    ],
    "AddDepartmentGroupError": [
      "ArgumentError",
      "RainError"
    ],
    "AddDepartmentGroupFunctionalRoleError": [
      "ArgumentError",
      "RainError"
    ],
    "AddDepartmentGroupPermissionError": [
      "ArgumentError",
      "RainError"
    ],
    "AddDepartmentToDepartmentGroupError": [
      "ArgumentError",
      "RainError"
    ],
    "AddFunctionalRoleToPersonError": [
      "ArgumentError",
      "RainError"
    ],
    "AddOrUpdateCategoryTermGroupTypeMappingError": [
      "ArgumentError",
      "RainError"
    ],
    "AddOrUpdateNoteError": [
      "ArgumentError",
      "RainError"
    ],
    "AddPerson_ClientError": [
      "ArgumentError",
      "RainError"
    ],
    "AddProjectEstimateDepartmentError": [
      "ArgumentError",
      "RainError"
    ],
    "AddProjectEstimateVersionFileError": [
      "ArgumentError",
      "RainError"
    ],
    "AddRoleDepartmentError": [
      "ArgumentError",
      "RainError"
    ],
    "AddTimeValidationProjCategoryOverrideError": [
      "ArgumentError",
      "RainError"
    ],
    "AddTimesheetDelegateError": [
      "ArgumentError",
      "RainError"
    ],
    "AddTimesheetValidationAdditionalCompanyError": [
      "ArgumentError",
      "RainError"
    ],
    "AddTimesheetValidationAdditionalDepartmentGroupError": [
      "ArgumentError",
      "RainError"
    ],
    "AddTimesheetValidationPersonAdditionalCategoryError": [
      "ArgumentError",
      "RainError"
    ],
    "AddTimesheetValidationProjResourceOverrideError": [
      "ArgumentError",
      "RainError"
    ],
    "CancelProjectEstimateApprovalsError": [
      "ArgumentError",
      "RainError"
    ],
    "DeleteRowFromCsiFileError": [
      "ArgumentError",
      "RainError"
    ],
    "EditInvoiceInfoForCSIFileError": [
      "ArgumentError",
      "RainError"
    ],
    "Error": [
      "ArgumentError",
      "RainError"
    ],
    "GenerateFinalCostSummaryError": [
      "ArgumentError",
      "RainError"
    ],
    "LockProjectEstimateDepartmentError": [
      "ArgumentError",
      "RainError"
    ],
    "RequestPermissionChangeError": [
      "ArgumentError",
      "RainError"
    ],
    "RevertFileError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveActivityCategoryError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveActivityError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveGeneralLedger_HardCostError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveProjectEstimateApprovalError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveProjectEstimateSubTypeError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveRateCardError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveRateError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveRoleError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveTimeValidationCatDepartmentError": [
      "ArgumentError",
      "RainError"
    ],
    "SaveTimeValidationCatProjectGroupError": [
      "ArgumentError",
      "RainError"
    ],
    "UpdateClientTransactionTermGroupOverrideError": [
      "ArgumentError",
      "RainError"
    ],
    "UpdateProjectEstimateDepartmentDescriptionError": [
      "ArgumentError",
      "RainError"
    ],
    "UpdateProjectEstimateDepartmentStatusError": [
      "ArgumentError",
      "RainError"
    ],
    "UpdateRainOnePersonError": [
      "ArgumentError",
      "RainError"
    ]
  }
};
      export default result;
    