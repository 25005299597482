import { Divider } from '@mui/material';
import HomeCard from './HomeCard';
import {
    RainOnePermission,
    RainOneApp,
    R1PermContext
} from '@rainagency/rain-one-core';
import { useStyles } from '../components/TimeCard.styles';
import CardItem, { CardItemProps } from './CardItem';
import {
    ActionItemType,
    UserActionItem,
    useGetTimesheetHomeCardCountQuery,
    TimeSheetHomeCardCountType
} from '../generated/graphql';
import GetCurrentConfig from '@rainagency/rain-one-core/dist/Config';
import { useActionItemsQuery } from '@rainagency/rain-one-core/dist/generated/graphql';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Fragment, useContext } from 'react';

export default function TimeCard() {
    const classes = useStyles();
    const { data, loading, error } = useActionItemsQuery();

    const isAdmin = useContext(R1PermContext).hasPermission(
        RainOnePermission.TimesheetValidation
    );
    const isManager = useContext(R1PermContext).hasPermission(
        RainOnePermission.TimesheetManager
    );
    const isViewAll = useContext(R1PermContext).hasPermission(
        RainOnePermission.ViewAllTimesheets
    );
    const isTimesheetInputer = useContext(R1PermContext).hasPermission(
        RainOnePermission.TimesheetInputer
    );

    const timeSheetHomeCardCountType =
        isAdmin && !isManager
            ? TimeSheetHomeCardCountType.Errors
            : isManager
                ? TimeSheetHomeCardCountType.OutstandingApprovals
                : isViewAll
                    ? TimeSheetHomeCardCountType.NotSubmitted
                    : TimeSheetHomeCardCountType.OutstandingApprovals;

    const {
        data: approvalsData,
        error: approvalsError,
        loading: approvalsLoading
    } = useGetTimesheetHomeCardCountQuery({
        variables: {
            timeSheetHomeCardCountType: timeSheetHomeCardCountType
        },
        fetchPolicy: 'network-only'
    });

    const config = GetCurrentConfig(false, RainOneApp.Time);
    const permContext = useContext(R1PermContext);

    function getItemsByType(itemType: ActionItemType): UserActionItem[] {
        return (
            data?.actionItems?.filter(
                (actionItem) =>
                    actionItem.rainOneApp === RainOneApp.Time &&
                    actionItem.actionItemType === itemType &&
                    actionItem.hideLink === false
            ) ?? []
        );
    }

    function getLateTimeSheetMessage(actionItem: UserActionItem): string {
        return actionItem.description.includes('rejected')
            ? `Rejected TimeSheet ${getDateFromInAppPath(actionItem)}`
            : `Missing TimeSheet ${getDateFromInAppPath(actionItem)}`;
    }

    function getDateFromInAppPath(actionItem: UserActionItem): string {
        return actionItem.inAppPath.substr(-10).replaceAll('-', '/');
    }

    function getCardItems(): CardItemProps[] {
        const cardItems: CardItemProps[] = [];

        if (isAdmin || isManager || isViewAll) {
            if (error) {
                cardItems.push({
                    message: 'Failed to load timesheets',
                    error: error,
                    path: config.RedirectUrl
                });
            } else if (loading) {
                cardItems.push({
                    message: '',
                    isLoading: true,
                    loadingMessage: 'Loading TimeSheet Info...',
                    path: config.RedirectUrl
                });
            } else {
                getItemsByType(ActionItemType.LateTimesheet).map((actionItem) =>
                    cardItems.push({
                        message: getLateTimeSheetMessage(actionItem),
                        path: `${config.RedirectUrl
                            }${actionItem.inAppPath.substring(1)}`
                    })
                );

                const timesheetCount: number =
                    approvalsData?.timesheetsHomeCardCount ?? 0;

                cardItems.push({
                    message: `Timesheet${timesheetCount === 1 ? '' : 's'} ${timeSheetHomeCardCountType ===
                            TimeSheetHomeCardCountType.Errors
                            ? 'with errors'
                            : timeSheetHomeCardCountType ===
                                TimeSheetHomeCardCountType.NotSubmitted
                                ? 'outstanding'
                                : 'to Approve'
                        }`,
                    path: 'time/employeetimesheets',
                    isLoading: approvalsLoading,
                    loadingMessage: 'Getting Timesheet Count...',
                    error: approvalsError,
                    count: timesheetCount
                });
            }
        }

        if (isTimesheetInputer) {
            cardItems.push({
                message: 'Update Your Timesheet',
                path: config.RedirectUrl
            });
        }

        if (
            permContext.hasAnyPermission([
                RainOnePermission.TimesheetManager,
                RainOnePermission.ViewAllTimesheets,
                RainOnePermission.ClientProjectManager
            ])
        ) {
            cardItems.push({
                message: 'View Time Reporting',
                path: 'time/reporting'
            });
        }

        return cardItems;
    }

    const cardItems = getCardItems();

    return (
        <HomeCard
            title="RainONE Time"
            className={classes.timeCard}
            rainOnePermissions={[
                RainOnePermission.TimesheetInputer,
                RainOnePermission.TimesheetManager,
                RainOnePermission.ViewAllTimesheets,
                RainOnePermission.ClientProjectManager
            ]}
            icon={AccessTimeIcon}
        >
            {cardItems.map((cardProps: CardItemProps, index) => (
                <Fragment key={index}>
                    <CardItem {...cardProps} />
                    {cardItems.indexOf(cardProps) !== cardItems.length - 1 && (
                        <Divider className={classes.divider} />
                    )}
                </Fragment>
            ))}
        </HomeCard>
    );
}
