import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import theme from '@rainagency/rain-one-core/dist/theme';

export const useStyles = makeStyles(() =>
    createStyles({
        header: {
            display: 'grid',
            gap: theme.spacing(1),
            gridTemplateColumns: 'auto 1fr',
            marginBottom: theme.spacing(3),
            alignItems: 'center'
        },
        title: {
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '160%'
        },

        actions: {
            float: 'right',
            margin: '0px 10px'
        },
        button: {
            width: 'fit-content',
            padding: '8px 15px'
        }
    })
);
