import HomeCard from './HomeCard';
import { RainOnePermission } from '@rainagency/rain-one-core';
import { useStyles } from './MediaCard.styles';
import CardItem from './CardItem';
import {
    RainOneApp,
    RainOnePerson_ForDisplay,
    useGetRainOnePeopleForDisplayQuery
} from '../generated/graphql';
import GetCurrentConfig, {
    Config
} from '@rainagency/rain-one-core/dist/Config';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import theme from '@rainagency/rain-one-core/dist/theme';
import { useMemo } from 'react';

export default function AdminCard() {
    const classes = useStyles();

    return (
        <HomeCard
            title="RainONE Admin"
            className={classes.mediaCard}
            rainOnePermission={RainOnePermission.AccessAdmin}
            icon={LaptopMacIcon}
        >
            <div style={{ paddingBottom: theme.spacing(2) }}>
                <SetUpErrorsCardItem />
            </div>
        </HomeCard>
    );
}

function SetUpErrorsCardItem() {
    const config = GetCurrentConfig(false, RainOneApp.Admin);
    const { data, loading, error } = useGetRainOnePeopleForDisplayQuery();

    const setupErrorCount: number | null = useMemo(
        () =>
            (
                data?.rainOnePeople_ForDisplay ??
                ([] as RainOnePerson_ForDisplay[])
            ).filter((person) => person.accountSetupErrorMessages?.length > 0)
                ?.length ?? null,
        [data]
    );
    return (
        <CardItem
            message={`User Setup Error${setupErrorCount === 1 ? '' : 's'}`}
            path={`${config.RedirectUrl}people`}
            isLoading={loading}
            error={error}
            loadingMessage={'Loading Setup Errors Count...'}
            count={setupErrorCount}
        />
    );
}
