import { Divider } from '@mui/material';
import HomeCard from './HomeCard';
import { R1PermContext, RainOnePermission } from '@rainagency/rain-one-core';
import { useStyles } from '../components/MediaCard.styles';
import CardItem from './CardItem';
import {
    RainOneApp,
    useGetDatoramaImportStatusesQuery,
    useGetMediaCardCountsQuery
} from '../generated/graphql';
import GetCurrentConfig, {
    Config
} from '@rainagency/rain-one-core/dist/Config';
import MovieIcon from '@mui/icons-material/Movie';
import theme from '@rainagency/rain-one-core/dist/theme';
import { useContext } from 'react';

export default function MediaCard() {
    const classes = useStyles();

    return (
        <HomeCard
            title="RainONE Media"
            className={classes.mediaCard}
            rainOnePermission={RainOnePermission.TrackerDigitalInvoicesViewer}
            icon={MovieIcon}
        >
            <MediaCardContent />
        </HomeCard>
    );
}
function MediaCardContent() {
    const classes = useStyles();
    const { data, loading, error } = useGetMediaCardCountsQuery();
    const config = GetCurrentConfig(false, RainOneApp.Media);
    const {
        data: datoramaData,
        loading: datoramaLoading,
        error: datoramaError
    } = useGetDatoramaImportStatusesQuery({
        variables: {
            activeClientsOnly: true,
            filesWithErrorsOnly: true
        },
        fetchPolicy: 'cache-and-network'
    });

    return (
        <div style={{ paddingBottom: theme.spacing(2) }}>
            <CardItem
                message={'Outstanding Invoices'}
                path={`${config.RedirectUrl}approveinvoices`}
                isLoading={loading}
                error={error}
                loadingMessage={'Loading Invoice Count...'}
                count={data?.outstandingUserInvoices?.length ?? null}
            />
            <Divider className={classes.divider} />
            <CardItem
                message={'Active Insertion Orders'}
                path={`${config.RedirectUrl}insertionorders`}
                isLoading={loading}
                error={error}
                loadingMessage={'Loading Insertion Orders...'}
                count={data?.activeUserInsertionOrders?.length ?? null}
            />
            <Divider className={classes.divider} />
            <CardItem
                message={'Datorama Import Errors'}
                path={`${config.RedirectUrl}datoramaimports`}
                isLoading={datoramaLoading}
                error={datoramaError}
                loadingMessage={'Loading Datorama Import Errors...'}
                count={
                    datoramaData?.digitalSpendImportsForLookup?.length ?? null
                }
            />
        </div>
    );
}
