import { createContext, useContext } from 'react';
import {
    R1ConfigContext,
    R1PermContext,
    RainOnePermission
} from '@rainagency/rain-one-core';

interface HomeContextTypes {
    people: any[];
    flags: HomeFlags;
}
export enum FeaturesList {
    ooo,
    bio,
    post,
    ff,
    timesheets
}
export type HomeFlags = {
    features: {
        [key in keyof typeof FeaturesList]: boolean;
    };
    config: {
        environment: string;
        apiUrl: string;
    };
};

export const HomeContext = createContext<HomeContextTypes>({
    people: [],
    flags: {} as HomeFlags
});

export function HomeProvider(children: any): JSX.Element {
    const config = useContext(R1ConfigContext);
    const permContext = useContext(R1PermContext);
    const isDev = config.IsDev;
    console.log(`IsDev? ${isDev}`);

    const flags: any = {
        features: {
            ooo:
                permContext.hasPermission(RainOnePermission.FeatureFlag1) ||
                isDev ||
                false,
            bio:
                permContext.hasPermission(RainOnePermission.FeatureFlag1) ||
                isDev ||
                false,
            post:
                permContext.hasPermission(RainOnePermission.FeatureFlag1) ||
                isDev ||
                false,
            ff:
                permContext.hasPermission(RainOnePermission.FeatureFlag1) ||
                isDev ||
                false,
            timesheets:
                permContext.hasPermission(RainOnePermission.FeatureFlag1) ||
                isDev ||
                false
        },
        config: {
            environment: config.EnvironmentName,
            apiUrl: isDev
                ? 'https://strapidev.rainforgrowth.com/graphql'
                : 'http://blah/graphql'
        }
    };

    return (
        <HomeContext.Provider value={{ people: [], flags: flags as HomeFlags }}>
            {children}
        </HomeContext.Provider>
    );
}

export default HomeProvider;
